[data-pages="career"]
  .page-header
    +pc()
      background-image url('../img/nyushi/index/header_bg.jpg')
    +sp()
      background-image url('../img/nyushi/index/header_bg@2x.jpg')




.ui-template .anchorlinkL-container
  justify-content: center
