[data-category="contact-list"]
  .page-header
    +pc()
      background-image url('../img/contact-list/header_bg.jpg')
    +sp()
      background-image url('../img/contact-list/header_bg@2x.jpg')

  // contact-list配下すべてに適用する場合はこちら

  // トップだけに適用する場合はこちら
  [data-contact-list="index"]
    .inquiry
      +pc()
        min-width 310px
      +sp()
        width 100% !important
