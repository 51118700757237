$thisPage = "error"
$bgPath = '../img/others/'


[data-pages={'"'+$thisPage+'"'}]

  .main
    h1
      color #3b4043
      +pc()
        margin-bottom 40px
      +sp()
        margin-bottom vw(20)
    p
      text-align center
      letter-spacing .1em
      +pc()
        font-size 20px
      +sp()
        font-size vw(14)
      & + p
        +pc()
          margin-top 20px
        +sp()
          margin-top vw(10)
