.header
  position fixed
  top 0
  left 0
  z-index 4
  +header-pc()
    width 180px
    height 100vh
    min-height 770px
    background-color $thema-text-black
    +pcLiquid()
      width pcvw(@width)
  +header-sp()
    width 100%
    background-color $thema-text-black
  &-container
    position relative
    +header-pc()
      display block !important
    +header-sp()
      height 100vh
      overflow-y scroll
  &-logo
    &-container
      display flex
      align-items center
      background-color $white
      +header-pc()
        width 180px
        height 184px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        &.l-sp
          display none
      +header-sp()
        width 100%
        height vw(120)
        padding-left vw(40)
        +pc()
          height 80px
          padding-left 40px
        &.l-pc
          display none
    &-inner
      +header-pc()
        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
        hover-opacity()
      +header-sp()
        display inline-block
      img
        position relative
        +header-pc()
          +pcLiquid()
            width pcvw(116)
            height pcvw(170)
        +header-sp()
          width vw(412)
          height auto
          +pc()
            width 274px
            height 48px

  &-main
    /**
    * ナビゲーション
    **/
    &-navigation
      position relative
    .header-main-parent
    .header-main-child-inner
    .header-main-sns-list
      +header-pc()
        height 46px
        border-bottom 1px solid alpha($white, .2)
        +pcLiquid()
          height pcvw(@height)
      +header-sp()
        border-bottom vw(2) solid alpha($white, .2)
      > a
        display flex
        align-items center
        width 100%
        +header-pc()
          height @width
        +header-sp()
          height vw(120)
    &-parent
      // position relative
      background-color $thema-text-black
      +header-pc()
        transition background-color .3s
        &:hover:not(.search-box)
          background-color #7741a0
          > .header-main-child
            display block
      +header-sp()
        position relative
      &-nav
        font-family $NotoSans
        color $white
        letter-spacing ls(50)
        +header-pc()
          font-size 1.4rem
          padding-left 30px
          +pcLiquid()
            font-size pcvw(14)
            padding-left pcvw(@padding-left)
        +header-sp()
          font-size vw(30)
          padding-left vw(60)
          padding-right vw(130)
          position relative
    .header-main-child-inner
      &._expand
        +pc()
          min-height 60px
      &.pdf
        .header-main-child-nav
          span
            &:after
              +header-pc()
                content ""
                display inline-block
                width 16px
                height 15px
                background url('../img/common/icon_pdf_white.png') no-repeat
                background-position center
                background-size contain
                margin-left 4px
                vertical-align middle
      &:hover
        &.pdf
          .header-main-child-nav
            span
              &:after
                background url('../img/common/icon_pdf.png') no-repeat
                background-position center
                background-size contain
    .navigation-arrow
      +header-pc()
        display none
      +header-sp()
        width vw(80) !important // click 判定用に大きめに
        height vw(120)
        background-repeat no-repeat
        background-size vw(30 16)
        background-position center 55% // ちょっと上にずれて見えるから55%
        background-image url('../img/common/arrow_white.png')
        position absolute
        top 0
        right vw(60)
        transform-origin center center
        transition transform .3s
      &.open
        transform scaleY(-1)
    &-child
      display none
      +header-pc()
        width 115%
        min-height 100%
        background-color alpha(#7741a0, .9)
        position absolute
        top 0
        right (@width * -1)
      +header-sp()
        width 100%
      &-inner
        +header-pc()
          background-color alpha(#7741a0, .1)
          transition background-color .3s
          &:hover
            background-color $white
            > .header-main-child-nav
              color #7741a0
            > .header-main-child
              display block
        +header-sp()
          background-color #7741a0
          border-top vw(2) solid alpha($white, .2)
          border-bottom 0 !important
          position relative
      &-nav
        font-family $NotoSans
        color $white
        letter-spacing ls(50)
        line-height 1.2
        +header-pc()
          font-size 1.3rem
          padding 0 20px
          transition color .3s
          +pcLiquid()
            font-size pcvw(13)
            padding pcvw(@padding)
        +header-sp()
          font-size vw(30)
          padding-left vw(60)


    /**
    * SNS
    **/
    &-sns
      display flex
      flex-wrap wrap
      position relative
      z-index 1
      +header-sp()
        background-color $thema-text-black
      &-list
        border-right 1px solid alpha($white, .2)
        background-repeat no-repeat
        background-position center center
        +header-pc()
          width 50%
          transition background-color .3s
          &:nth-of-type(n+4)
            width 50%
          &:hover
            background-color #7741a0
        +header-sp()
          width 25%
      // アイコン
      .is-facebook
        background-image url('../img/common/icon_facebook.svg')
        +header-pc()
          background-size 26px 26px
          +pcLiquid()
            background-size pcvw(@background-size)
        +header-sp()
          background-size vw(60 49)
      .is-twitter
        background-image url('../img/common/icon_x.svg')
        // background-image url('../img/common/icon_twitter.svg')
        +header-pc()
          // background-size 30px 24px
          background-size 24px 24px
          +pcLiquid()
            background-size pcvw(@background-size)
        +header-sp()
          background-size vw(52 52)
      .is-instagram
        background-image url('../img/common/icon_instagram.svg')
        +header-pc()
          background-size 26px 26px
          +pcLiquid()
            background-size pcvw(@background-size)
        +header-sp()
          background-size vw(52 52)
      .is-line
        background-image url('../img/common/icon_line.svg')
        +header-pc()
          background-size 26px 26px
          +pcLiquid()
            background-size pcvw(@background-size)
        +header-sp()
          background-size vw(52 52)
      .is-youtube
        background-image url('../img/common/icon_youtube.svg')
        +header-pc()
          background-size 32px 22px
          +pcLiquid()
            background-size pcvw(@background-size)
        +header-sp()
          background-size vw(63 44)


  &-sub
    +header-pc()
      width 'calc(100vw - %s)' % 180px
      position absolute
      top 0
      left 180px
      +pcLiquid()
        width 'calc(100vw - %s)' % pcvw(180)
        left pcvw(@left)
    +header-sp()
      padding-bottom vw(270)
    &-outer
      +header-pc()
        display flex
        background-color $white
    &-navigation
      display flex
      +header-pc()
        width percent(705, 1366 - 180)
        background-color alpha($thema-bg-purple, .7)
      +header-sp()
        flex-wrap wrap
        background-color $thema-text-black
      li
        background-color $thema-bg-purple
        +header-pc()
          // width 'calc(%s + 1px)' % pcvw(164)
          width 25%
          height 40px
          border-right 1px solid $white
          hover-opacity()
          +pcLiquid()
            height pcvw(@height)
          &:last-of-type
            border-right none
        +header-sp()
          width 50%
          height vw(120)
          border-right vw(2) solid alpha($white, .2)
          border-bottom vw(2) solid alpha($white, .2)
        a
          font-family $NotoSans
          color $white
          letter-spacing ls(50)
          display flex
          align-items center
          justify-content center
          width 100%
          height @width
          +header-pc()
            font-size 1.2rem
            +pcLiquid()
              font-size pcvw(12)
          +header-sp()
            font-size vw(28)
  &-other-navigation
    display flex
    +header-pc()
      width percent((1366 - 180) - 705, 1366 - 180) // デザイン幅 - 左のヘッダー幅 - サブナビ幅
    +header-sp()
      border-bottom vw(2) solid $thema-text-black
    li
      background-color $white
      background-repeat no-repeat
      +header-pc()
        // width 'calc(%s + 1px)' % pcvw(90)
        height 40px
        background-position center 7px
        &:not(.is-requestDocuments):not(.is-webApplication)
          width 16.6%
          padding-bottom 5px
        &:not(.is-requestDocuments):not(.is-webApplication):not(.is-contact)
          border-right 1px solid $thema-bg-purple
        &.is-requestDocuments,
        &.is-webApplication
          width 24.8%
        hover-opacity()
        +pcLiquid()
          height pcvw(@height)
          &:not(.is-requestDocuments):not(.is-webApplication)
            padding-bottom pcvw(5)
            // background-position pcvw(@background-position)
      +header-sp()
        width 33.3%
        height vw(120)
        padding-bottom vw(25)
        border-right vw(2) solid $thema-text-black
        background-position center vw(30)
      &:nth-of-type(3)
        border-right-color $white
      &:last-of-type
        border-right none
      a
        color $thema-text-purple
        letter-spacing ls(50)
        display flex
        align-items flex-end
        justify-content center
        width 100%
        height @width
    // ユニークスタイル
    .is-language
      background-image url('../img/common/icon_language.svg')
      +header-pc()
        background-size 17px 16px
        background-position-y 5px
        +pcLiquid()
          background-size pcvw(@background-size)
          background-position-y pcvw(@background-position-y)
      +header-sp()
        background-size vw(33 33)
      a
        font-family $Roboto
        +header-pc()
          font-size 1.2rem
          +pcLiquid()
            font-size pcvw(12)
        +header-sp()
          font-size vw(26)
    .is-access
      background-image url('../img/common/icon_access.svg')
      +header-pc()
        background-size 15px 18px
        background-position-y 5px
        +pcLiquid()
          background-size pcvw(@background-size)
          background-position-y pcvw(@background-position-y)
      +header-sp()
        background-size vw(29 35)
      a
        font-family $Roboto
        +header-pc()
          font-size 1.2rem
          +pcLiquid()
            font-size pcvw(12)
        +header-sp()
          font-size vw(26)
    .is-contact
      background-image url('../img/common/icon_contact.svg')
      +header-pc()
        background-size 17px 12px
        +pcLiquid()
          background-size pcvw(@background-size)
      +header-sp()
        background-size vw(35 25)
      a
        font-family $NotoSans
        +header-pc()
          font-size 1.1rem
          +pcLiquid()
            font-size pcvw(11)
        +header-sp()
          font-size vw(24)
    .is-requestDocuments
      background-color $lightGreen
    .is-webApplication
      background-color $blue
    .is-requestDocuments,
    .is-webApplication
      +header-sp()
        display none
      a
        align-items center
        font-family $NotoSans
        color $white
        +header-pc()
          font-size 1.5rem
          +pcLiquid()
            font-size pcvw(15)
        +header-sp()
          font-size vw(24)
        .icon
          display block
          margin-right 5px
        .txt
          display block
          margin-top -2px

  // ハンバーガーアイコン
  .hmb-icon
    +header-pc()
      display none
    +header-sp()
      width vw(120)
      height @width
      display flex
      align-items center
      justify-content center
      background-color $thema-text-black
      speak none
      position absolute
      top 0
      right 0
      z-index 2
      +pc()
        width 80px
        height @width
    &.open
      .line
        +header-sp()
          height vw(44)
          margin-left vw(-10)
          +pc()
            height 29px
            margin-left -10px
        span
          opacity 0
        &::before
          transform rotate(-45deg)
        &::after
          transform rotate(45deg)
    .line
      +header-sp()
        width vw(44)
        height vw(33)
        display flex
        align-items center
        flex-wrap wrap
        +pc()
          width 29px
          height 22px
        &::before
        &::after
        span
          width 100%
          height vw(2)
          background-color $white
          +pc()
            height 2px
        &::before
        &::after
          content ''
        &::before
          transform-origin right bottom
        &::after
          transform-origin right top


  // Google カスタム検索のスタイル上書き用
  .search-box
    // display flex
    // align-items center
    +header-pc()
      height auto !important
      padding 20px 14px
      +pcLiquid()
        // height pcvw(@height 66px)
        padding pcvw(@padding)
    +header-sp()
      padding vw(60)
    // > div
    //   width 100%
  form.gsc-search-box
    margin 0
  .gsc-control-cse
    background-color transparent
    padding 0
    border 0
  .gsc-input
    height 100%
    +header-pc()
      font-size 1.4rem
      width calc(100% - 25px)
      +pcLiquid()
        font-size pcvw(14)
        width 'calc(100% - %s)' % pcvw(25)
    +header-sp()
      font-size vw(30)
      width 'calc(100% - %s)' % vw(50)
  .gsc-input-box
    border 0
    padding 0
    background none
  table.gsc-search-box
    margin 0
    > tbody > tr
      display flex
      justify-content space-between
      background-color $white
      +header-pc()
        height 26px
        padding 0 10px
        border-radius 13px
        +pcLiquid()
          height pcvw(@height)
          padding pcvw(@padding)
      +header-sp()
        height vw(60)
        padding vw(0 20)
        border-radius vw(30)
    td.gsc-input
      padding-right 0
  .gsib_a
    padding 0
    input
      margin 0 !important
      height auto !important
  // キャンセルボタン
  .gscb_a
    line-height 1
    +header-pc()
      font-size 2.4rem
      +pcLiquid()
        font-size pcvw(24)
    +header-sp()
      font-size vw(40)
  .gsst_a
    padding 0
    .gscb_a
      color #a0a0a0
  // 虫眼鏡ボタン
  .gsc-search-button
    margin-left 0
    +header-pc()
      width 20px
      margin-left 5px
      +pcLiquid()
        width pcvw(@width)
        margin-left pcvw(@margin-left)
    +header-sp()
      width vw(50)
      margin vw(0 20)
    &-v2
      height 100%
      padding 0
      border 0
      border-radius 0
      background-color transparent
      background-repeat no-repeat
      background-position center center
      background-image url('../img/common/icon_search.png')
      +header-pc()
        margin-left 0 !important
        background-size 18px 18px
        transition opacity .3s
        +pcLiquid()
          background-size pcvw(@background-size)
      +header-sp()
        margin 0 !important
        background-size vw(34 34)
      &:hover
      &:focus
        border 0
        outline 0
        background-color transparent
        background-image url('../img/common/icon_search.png')
        opacity .6
      svg
        display none
  input.gsc-input, .gsc-input-box, .gsc-input-box-hover, .gsc-input-box-focus
    border 0
    background-color transparent
    height 100%


  .header-main-child
    .header-main-child
      .header-main-child-nav
        +header-sp()
          padding-left 'calc(%s + 1em)' % vw(60)


  .header-main-child
    +pc()
      &.dirlv_3
        width 160%
        top inherit
        bottom 0
        right -160%
        .header-main-child-inner
          height 37.6px
          font-size 13px
          +pcLiquid()
            height pcvw(37.6)
            font-size pcvw(13)
          +header-sp()
            height vw(120)
      &.dirlv_4
        .header-main-child-inner
          height 47px
      // 第3階層以降で調整用class
      &.height-adjust
        +header-pc()
          height 74px
          +pcLiquid()
            height pcvw(@height)
    +sp()
      &.dirlv_3
        .header-main-child-inner
          background-color $white
          border-color $theme-color
          .header-main-child-nav
            color $theme-color

  // SP メニュー開閉スタイル
  .jsHederNav
    // +header-pc()
    //   transform translate3d(0px, 0px, 0px) !important
    +header-sp()
      // transition transform .5s
      // transform translate3d(100%, 0px, 0px)
      display none
    // &.open
    //   transform translate3d(0px, 0px, 0px)
    //   display block
