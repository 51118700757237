$category = "professor"
$ImagePath = '../img/faculty/professor/'

[data-category={'"'+$category+'"'}]
  .page-header
    text-align center

    +pc()
      background-image url($ImagePath + 'header_bg-pc.jpg')
    +sp()
      background-image url($ImagePath + 'header_bg-sp.jpg')

  &.ui-template
    .list-withDot
      li
        &:before
          background-color $lightGreen

  .ui-template
    .tab-container
      +sp()
        flex-wrap wrap
      .tab
        text-align center
        padding 0
        +pc()
          flex-wrap wrap
        +sp()
          flex-direction column
          width 100%
          margin-top vw(52)
        span
          width 100%
          display block
          padding-right 0
          padding-left 0
        .faculty
          letter-spacing .05em
          line-height 1.2
          +pc()
            font-size 13px
            margin-bottom 8px
            +pcLiquid()
              font-size pcvw(@font-size)
              margin-bottom pcvw(@margin-bottom)
          +sp()
            font-size vw(24)
            margin-bottom vw(8)
        .department
          letter-spacing .05em
          line-height 1.2
          +pc()
            font-size 15px
            margin-bottom 16px
            +pcLiquid()
              font-size pcvw(@font-size)
              margin-bottom pcvw(@margin-bottom)
          +sp()
            font-size vw(28)
            margin-bottom vw(24)

    .block-teachers
      background-color $thema-bg-lightGrey
      +pc()
        padding 40px 0
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(80 40)
      .click_area
        +pc()
          width 100%
        +sp()
          width 50%

  .list-teachers
    li
      width 100%
      background-color $white
      +pc()
        margin-bottom 20px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(40)
      a
        width 100%
        color $thema-text-black
        +pc()
          display table
          &:hover
            opacity .6
        +sp()
          display flex
          padding vw(20)
      .area-image,
      .area-text,
      .area-name,
      .area-specialty
        +pc()
          display table-cell
          vertical-align middle
      .area-text
        +sp()
          width vw(390)
          margin-left vw(40)
      .area-image
        position relative
        overflow hidden
        +pc()
          width 120px
          height 120px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(200)
          height vw(200)
        img
          width auto
          height auto
          max-width 100%
          max-height 100%
          position absolute
          transform translate(-50%, -50%)
          top 50%
          left 50%
      .area-name
        +pc()
          padding-left 40px
          width 320px
          +pcLiquid()
            padding-left pcvw(@padding-left)
            width pcvw(@width)
         +sp()
           margin-bottom vw(26)
        span
          display block
        .professor
          line-height 1.2
          letter-spacing .05em
          +pc()
            margin-bottom 8px
            font-size 15px
            +pcLiquid()
              margin-bottom pcvw(@margin-bottom)
              font-size pcvw(@font-size)
          +sp()
            font-size vw(24)
        .en
          font-family $Roboto
          letter-spacing .05em
          line-height 1.2
          +pc()
            margin-top 8px
            font-size 13px
            +pcLiquid()
              margin-top pcvw(@margin-top)
              font-size pcvw(@font-size)
          +sp()
            font-size vw(24)
      .area-specialty
        span
          &:nth-child(n+2)
            padding-left .5em
        +pc()
          padding-left 40px
          width 460px
          +pcLiquid()
            padding-left pcvw(@padding-left)
            width pcvw(@width)

  .profile
    .profile-item
      display table
      width 100%
      .profile-heading,
      .profile-contents
        display table-cell
        vertical-align top
        letter-spacing .05em
        line-height 1.8
        +pc()
          font-size 15px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(28)
      .profile-heading
        color $thema-text-purple

  .main_profile
    display grid
    +pc()
      grid-template-rows 80px 80px 1fr
      grid-template-columns 160px 1fr
      +pcLiquid()
        grid-template-rows pcvw(80px 80px) 1fr
        grid-template-columns pcvw(160px) 1fr
    +sp()
      grid-template-columns vw(200) 1fr
    .area-image
      position relative
      overflow hidden
      +pc()
        grid-row 1 / 3
        grid-column 1 / 2
        width 100%
        height 160px
        +pcLiquid()
          height pcvw(@height)
      +sp()
        width 100%
        height vw(200)
        grid-row 1 / 2
        grid-column 1 / 2
      img
        width auto
        height auto
        max-width 100%
        max-height 100%
        position absolute
        transform translate(-50%, -50%)
        top 50%
        left 50%
    .area-name
      +pc()
        grid-row 1 / 2
        grid-column 2 / 3
        padding 10px 40px 0
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        grid-row 1 / 2
        grid-column 2 / 3
        padding vw(20 40 0)
      .ja
        +sp()
          display block
      .en
        letter-spacing .05em
        line-height 1.6
        +pc()
          margin-left 10px
          font-size 15px
          +pcLiquid()
            margin-left pcvw(@margin-left)
            font-size pcvw(@font-size)
        +sp()
          display block
          font-size vw(28)
    .area-specialty
      +pc()
        grid-row 2 / 4
        grid-column 2 / 3
        padding 0 40px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        margin-top vw(40)
        grid-row 2 / 3
        grid-column 1 / 3

      .profile-item
        +pc()
          margin-bottom 10px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)
        +sp()
          margin-bottom vw(10)

        .profile-heading
          position relative
          word-break break-all
          +pc()
            width 20%
            padding-right 6%
          +sp()
            width 40%
            padding-right 8%
          &::after
            position absolute
            right 4%
            display block
            content ""
            height 1px
            background $lightGray
            +pc()
              width 74%
              top 13px
              +pcLiquid()
                top pcvw(@top)
            +sp()
              width 70%
              top vw(26)

        .profile-contents
          word-break break-all
          +pc()
            width 80%
          +sp()
            width 60%

      .profile-item--address
        .profile-heading
          &::after
            +pc()
              width 24%
            +sp()
              width 16%

        .profile-contents
          span
            &:first-of-type
              margin-right .5em

      .profile-item--url
        .profile-heading
          &::after
            +pc()
              width 24%
            +sp()
              width 16%

  .sub_profile
    border-top 1px solid $lightGray
    .profile-item
      border-bottom 1px solid $lightGray
      +pc()
        padding 20px 40px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(40 20)
      .profile-heading
        +pc()
          width 20%
        +sp()
          display block
          margin-bottom vw(16)
      .profile-contents
        +pc()
          width 80%
        +sp()
          display block
    .profile-item--achievement
      .achievement_title
        &:nth-child(n+2)
          display block
          +pc()
            margin-top 20px
            +pcLiquid()
              margin-top pcvw(@margin-top)
          +sp()
            margin-top vw(40)
      .profile-contents--inner
        border-bottom 1px solid $lightGray
        +pc()
          width 100%
          display table
          padding-top 20px
          padding-bottom 20px
          +pcLiquid()
            padding-top pcvw(@padding-top)
            padding-bottom pcvw(@padding-bottom)
          .achievement_title
            display table-cell
            width 24%
          .achievement_contents
            display table-cell
            width 70%
        +sp()
          padding-top vw(40)
          padding-bottom vw(40)
          .achievement_contents
            padding-left 1em
        &:first-of-type
          padding-top 0
        &:last-of-type
          border none
          padding-bottom 0


    .profile-item
      .profile-contents--movie
        position relative
        height auto
        padding-top 56.25%
        +pc()
          display block
          width 540px
          +pcLiquid()
            width pcvw(@width)
        +sp()
          width 100%
        iframe
          position absolute
          top 0
          right 0
          width 100%
          height 100%

  .link-container--back
    text-align center
    .text-link
      cmn-arrow-link--back($thema-text-purple)

  .tab_link-container
    display flex
    text-align center
    .tab_link
      width 100%
      a,
      span
        display block
        width 100%
        height 100%
        letter-spacing .05em
        line-height 1
        transition .2s
        +pc()
          font-size 17px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(32)
      span
        color $thema-text-black
        border-bottom 2px solid $lightGray
        +pc()
          padding 20px 0
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          padding vw(40 0)
      &.active
        span
          color $theme-color
          border-bottom 2px solid $thema-text-purple
      &:hover
        a
          span
            border-bottom 2px solid $thema-text-purple
            color $theme-color
            opacity .6
    +sp()
      padding vw(0 40)

  // ページネーション
  .pagenation
    .disabled
      display none
    .page-number
      +pc()
        &:hover
          a
            color $white
      &.current
        a
          color $white
          font-family $Roboto
      a
        display block
        width 100%
        height 100%
        text-align center
        cursor pointer
        +pc()
          line-height 50px
          +pcLiquid()
            line-height pcvw(@line-height)
        +sp()
          line-height vw(90)
    .prev-btn,
    .next-btn
      background-image url('../img/common/pagenation_arrow.svg')
      a
        cursor pointer
        display block
        width 100%
        height 100%

$Page-professor_serch = "professor_serch"
[data-pages={'"'+$Page-professor_serch+'"'}]

  .ui-template
    +sp()
      padding-left 0
      padding-right 0

  .block-serch
    +sp()
      padding vw(0 40)
    input
      -webkit-appearance none
      -moz-appearance none
      appearance none
      border none
    .serch-item
      display table
      width 100%
      margin-bottom 30px
      .serch-heading,
      .serch-contents
        display table-cell
        vertical-align top
        letter-spacing .05em
        line-height 1.8
        +pc()
          font-size 15px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          display block
          font-size vw(28)
      .serch-heading
        +pc()
          width 260px
          padding 6px 18px 0 0
          text-align right
          +pcLiquid()
            width pcvw(@width)
            padding pcvw(@padding)
        +sp()
          margin-bottom: vw(16)
    .selects_wrap
      display block
      border 1px solid $lightGray
      +pc()
        width 350px
        +pcLiquid()
          width pcvw(@width)
      .nochecked
        text-overflow: ellipsis;
        white-space nowrap
        overflow hidden
        cursor pointer
        background-image url($ImagePath + 'arrow.svg')
        background-position right 10px center
        background-repeat no-repeat
        +pc()
          width 350px
          line-height 40px
          padding 0 30px 0 10px
          height 40px
          background-size 13px 8px
          +pcLiquid()
            width pcvw(@width)
            padding pcvw(@padding)
            height pcvw(@height)
            line-height pcvw(@line-height)
            background-size pcvw(@background-size)
        +sp()
          white-space initial
          height vw(80)
          line-height vw(80)
          padding vw(0 40 0 20)
          background-size vw(26 16)
    .input_area
      display block
      cursor pointer
      width 100%
      +pc()
        line-height 2.4
        padding 0 20px
        font-size 13px
        +pcLiquid()
          padding pcvw(@padding)
          font-size pcvw(@font-size)
        &:hover
          opacity .6
      +sp()
        line-height vw(80)
        padding vw(0 40)
        font-size vw(24)
    .input_area--text
      +pc()
        padding 0 10px
        height 40px
        +pcLiquid()
          padding pcvw(@padding)
          height pcvw(@height)
      +sp()
        padding vw(0 20)
        height vw(80)

    input[type=radio]
      display none
    input[type=radio].hide-radiobtn
      display none
    input[type=radio]::-ms-clear
      visibility hidden
    .answer
      max-height 200px
      overflow-y scroll
      display none
      background $thema-bg-lightGrey
    .tab
      cursor pointer
      transition .2s

    .notes
      letter-spacing .05em
      line-height 1.2
      +pc()
        margin-top 8px
        font-size 13px
        +pcLiquid()
          margin-top pcvw(@margin-top)
          font-size pcvw(@font-size)
      +sp()
        margin-top vw(16)
        font-size vw(24)

    .part_heading
      color #8C8C8C
      line-height 2.4
      +pc()
        padding 0 10px
        font-size 13px
        +pcLiquid()
          padding pcvw(@padding)
          font-size pcvw(@font-size)
      +sp()
        padding vw(0 20)
        font-size vw(26)

    .wrap-button
      text-align center
      .button
        text-align center
        color $white
        letter-spacing .05em
        +pc()
          border-radius 20px
          width 180px
          font-size 15px
          line-height 40px
          +pcLiquid()
            width pcvw(@width)
            font-size pcvw(@font-size)
            line-height pcvw(@line-height)
        +sp()
          border-radius vw(40)
          width vw(290)
          font-size vw(28)
          line-height vw(80)
      .button-serch
        background-color $thema-purple
        +pc()
          margin-right 40px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-right vw(40)
      .button-clear
        background-color $thema-text-black
        transition .2s
        &:hover
          opacity .7
