$thisCategory = "for-students"
$thisPage = "students-index"
$bgPath = '../img/' + $thisCategory + '/' +$thisPage + '/'

[data-pages={'"'+$thisPage+'"'}]
  &.content-for_menu
    background-color $thema-bg-lightGrey!important
  .page-header
    +pc()
      background-image url($bgPath + 'header_bg-pc.jpg')
    +sp()
      background-image url($bgPath + 'header_bg-sp.jpg')

  .content-for_menu-inner
    +pc()
      padding 78px 0 62px
    +sp()
      padding 40px 0 62px

  .list__for_menu
    .item__for_menu--1
      .wrap_outer
        &::after
          +pc()
            bottom 47%
            left 66%
            transform translate(-50%, 0)
      +pc()
        &:hover
          .wrap_outer
            &::after
              left 67%

      .wrap_inner
        background-image url($bgPath + 'icon-pc.svg')
        +pc()
          min-height 68px
          width 287px
          padding-left 72px
          padding-top 12px
          background-position center left
          background-size 78px 68px
          bottom 30%
        +sp()
          padding-top 68px
          background-size 53px 46px

    .item__for_menu--2
      .wrap_inner
        background-image url($bgPath + 'icon-note.svg')
        +pc()
          padding-top 98px
          background-size 50px 64px
        +sp()
          padding-top 68px
          background-size 38px 46px

    .item__for_menu--3
      .wrap_inner
        background-image url($bgPath + 'icon-money.svg')
        +pc()
          padding-top 90px
          background-size 79px auto
        +sp()
          padding-top 65px
          background-size 58px auto

    .item__for_menu--4
      .wrap_inner
        background-image url($bgPath + 'icon-note.svg')
        +pc()
          padding-top 98px
          background-size 50px auto
        +sp()
          padding-top 68px
          background-size 38px auto

    .item__for_menu--5
      .wrap_inner
        background-image url($bgPath + 'icon-money.svg')
        +pc()
          padding-top 90px
          background-size 79px auto
        +sp()
          padding-top 65px
          background-size 58px auto

    .item__for_menu--6
      .wrap_inner
        background-image url($bgPath + 'icon-information.svg')
        +pc()
          padding-top 90px
          background-size 64px auto
        +sp()
          padding-top 65px
          background-size 48px auto

    .item__for_menu--7
      .wrap_inner
        background-image url($bgPath + 'icon-webmail.svg')
        +pc()
          padding-top 90px
          background-size 116px auto
        +sp()
          padding-top 65px
          background-size 71px auto

  .list__for_menu_sab
    .item__for_menu_sab--1
      background-image url($bgPath + 'icon harassment.svg')
      +pc()
        margin-top 5px
        padding-top 88px
        background-size 73px
      +sp()
        margin-top 52px
        padding-top 71px
        background-size 55px

    .item__for_menu_sab--2
      background-image url($bgPath + 'icon bus.svg')
      +pc()
        margin-top 18px
        padding-top 74px
        background-size 76px auto
      +sp()
        margin-top 23px
        padding-top 52px
        background-size 57px auto

    // .item__for_menu_sab--3
    //   background-image url($bgPath + 'icon-multiculturalcenter.svg')
    //   +pc()
    //     margin-top 50px
    //     padding-top 88px
    //     background-size 73px
    //   +sp()
    //     margin-top 52px
    //     padding-top 71px
    //     background-size 55px

  .container-info
    margin-top 10px

  .link-content
    text-align right
    .link-container
      cmn-arrow-link($thema-text-purple)
