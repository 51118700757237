$thisCategory = "for-alumni"
$thisPage = "alumni-index"
$bgPath = '../img/' + $thisCategory + '/' +$thisPage + '/'

[data-pages={'"'+$thisPage+'"'}]
  .page-header
    +pc()
      background-image url($bgPath + 'header_bg-pc.jpg')
    +sp()
      background-image url($bgPath + 'header_bg-sp.jpg')

  .content-for_menu-inner
    +pc()
      padding 78px 0 42px
    +sp()
      padding 40px 0 62px

  .list__for_menu
    .item__for_menu--1
      .wrap_inner
        background-image url($bgPath + 'icon-1.svg')
        +pc()
          padding-top 100px
          background-size 78px auto
        +sp()
          padding-top 75px
          background-size 58px auto

    .item__for_menu--2
      .wrap_inner
        background-image url($bgPath + 'icon-2.svg')
        +pc()
          padding-top 94px
          background-size 88px auto
        +sp()
          padding-top 70px
          background-size 65px auto

    .item__for_menu--3
      .wrap_inner
        background-image url($bgPath + 'icon-3.svg')
        +pc()
          padding-top 111px
          background-size 78px auto
        +sp()
          padding-top 84px
          background-size 58px auto

    .item__for_menu--4
      .wrap_inner
        background-image url($bgPath + 'icon-2.svg')
        +pc()
          padding-top 94px
          background-size 88px auto
        +sp()
          padding-top 70px
          background-size 65px auto

    .item__for_menu--5
      +pc()
        height 280px
      +sp()
        height 218px
      .wrap_inner
        background-image url($bgPath + 'icon-4.svg')
        bottom 20%
        +pc()
          padding-top 98px
          background-size 80px auto
        +sp()
          padding-top 71px
          background-size 60px auto
        .for_menu_title_ja
          line-height 1.4
      .wrap_outer
        &::after
          bottom 13%

    .item__for_menu--6
      +pc()
        height 280px
      +sp()
        height 218px
      .wrap_inner
        background-image url($bgPath + 'icon-5.svg')
        bottom 20%
        +pc()
          padding-top 101px
          background-size 61px auto
        +sp()
          padding-top 74px
          background-size 45px auto
        .for_menu_title_ja
          line-height 1.4
      .wrap_outer
        &::after
          bottom 13%

  .container-info
    margin-top 10px

  .link-content
    text-align right
    .link-container
      cmn-arrow-link($thema-text-purple)
