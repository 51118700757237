// cmn-arrow-linkに追加
[data-category="faculty"]
  [data-faculty="bungakubu"]
  [data-faculty="eiyokagakubu"]
  [data-faculty="jc"]
    .text-link
      span
        font-family $Roboto
        +sp()
          padding-right vw(20)
          letter-spacing -0.01em
      &::after
        +sp()
          border-width vw(16 0 0 16)
      .icon-pdf
        margin-left .3em
        +pc()
          width 18px
          +pcLiquid()
            width pcvw(18)
        +sp()
          width vw(30)

[data-category="faculty"]
  .ui-template
    .table-container
      +sp()
        // overflow-y hidden
        overflow-x auto
        // スクロールバー表示
        // &::-webkit-scrollbar
        //   height 7px
        // &::-webkit-scrollbar-track
        //   margin 0 2px
        //   background #f5f6f8
        //   border-radius 3px
        // &::-webkit-scrollbar-thumb
        //   border-radius 3px
        //   background #999

  .ui-template
    // Accordion Q&A
    .accordion-container
      .accordion
        background $bg-grey
        padding 0
        &:not(:last-of-type)
          +pc()
            margin-bottom $mgn-lv2
            +pcLiquid()
              margin-bottom pcvw($mgn-lv2)
          +sp()
            margin-bottom vw($mgn-lv2)
        .question,
        .answer
          +pc()
            padding $mgn-lv2
            +pcLiquid()
              padding pcvw($mgn-lv2)
          +sp()
            padding vw($mgn-lv2)
          p
            position relative
            +pc()
              padding-left $mgn-lv2
              padding-right $mgn-lv3
              +pcLiquid()
                padding-left pcvw($mgn-lv2)
                padding-right pcvw($mgn-lv3)
            +sp()
              padding-left vw($mgn-lv3)
              padding-right vw($mgn-lv3)
        .question
          cursor pointer
          position relative
          transition all .2s
          &:hover
            opacity .6
          &:before,
          &:after
            margin-top 0
            content ""
            @extend .abs_centerY
            +pc()
              right $mgn-lv2
              width 20px
              height 3px
            +sp()
              right vw($mgn-lv2)
              width vw(20)
              height vw(4)
          &:after
            transform translateY(-50%) rotate(90deg)
            transition .5s
          &.opend
            &:after
              transform translateY(-50%) rotate(0)
          p
            line-height 1.6
            &:before
              content "Q"
              display inline-block
              font-family $Roboto
              font-size 40px
              font-weight bold
              line-height 1
              position absolute
              left 0
              +pc()
                top -0.15em
                +pcLiquid()
                  font-size pcvw(40)
              +sp()
                font-size vw(64)
                top -0.05em
        .answer
          display none
          border-top 1px solid $border-grey
          +pc()
            padding-bottom $mgn-lv2
            +pcLiquid()
              padding-bottom pcvw($mgn-lv2)
          +sp()
            padding-bottom vw($mgn-lv2)
          p
            margin-top 0
            line-height 1.9
            +sp()
              padding-right 0
            &:before
              content "A"
              display inline-block
              font-family $Roboto
              font-size 40px
              font-weight bold
              line-height 1
              position absolute
              left 0
              +pc()
                top -0.15em
                +pcLiquid()
                  font-size pcvw(40)
              +sp()
                font-size vw(64)
                top -0.05em

  a.block-other_link
    &:hover
      opacity .6
      .link-container
        .text-link
          &::after
            transform translate3d(10px, 0, 0)
    +sp()
      margin 0 vw(20)

  .only-link
    .text-link
      &:hover
        opacity .6

  .scroll_anime
    transition all 1s ease .2s
    &.fuwa-up
      transform translateY(15px)
      opacity 0
    &.fuwa-up.scroll__start
      transform translateY(0px)
      opacity 1

  .float-right
    +pc()
      float right
      margin-left 16px
      +pcLiquid()
        margin-left pcvw(@margin-left)

  .float-left
    +pc()
      float left
      margin-right 16px
      +pcLiquid()
        margin-right pcvw(@margin-right)

  .margin-bottom
    +pc()
      margin-bottom 52px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
    &.-center
      text-align center
    +sp()
      margin-bottom vw(52)

  .col2-pc_only
    +pc()
      display flex
      justify-content space-between
      &.return
        flex-direction row-reverse
      .box
        width 450px
        +pcLiquid()
          width pcvw(@width)
    img
      +pcLiquid()
        width pcvw(450px)
        height auto
      +sp()
        width 100%
        height auto

  .col3-pc_only
    +pc()
      display flex
      justify-content space-between
      .box
        width 340px
        +pcLiquid()
          width pcvw(@width)

  .text-indent
    text-indent 1em

  .highlight
    font-weight bold
    border-bottom 2px dotted $thema-text-purple

  .bg-table-early-childhood
    background-color #bee1f0

  .line
    +pc()
      display flex
      justify-content center
      align-items center
  .line-2
    +pc()
      height 80px
      +pcLiquid()
        height pcvw(@height)
  .line-3
    +pc()
      height 100px
      +pcLiquid()
        height pcvw(@height)
  .line-4
    +pc()
      height 120px
      +pcLiquid()
        height pcvw(@height)

  .content-faculty_top
    .container-inner
      +pc()
        padding 60px 0
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(100 0)

  .faculty_heading
    position relative
    text-align center
    padding-bottom 25px
    &::after
      position absolute
      bottom 0
      left 50%
      transform translateX(-50%)
      display block
      content ""
      width 50px
      height 1px

  .department-block
    position relative
    width 100%
    background-size cover
    background-position center
    +pc()
      height 460px
      +pcLiquid()
        height pcvw(@height)
    +sp()
      height 106.667vw
    .text
      color $thema-text-black
      +sp()
        font-size vw(24)
    .department-block-inner
      display flex
      flex-direction column
      justify-content space-between
      position absolute
      background-color $white
      +pc()
        width 460px
        height 340px
        +pcLiquid()
          width pcvw(460)
          height pcvw(340)
        &:hover
          background-color rgba(255, 255, 255, 0.85)
          .text-link
            &::after
              transform translate3d(10px, 0, 0)
      +sp()
        height vw(480)
        padding vw(40)
        transform translateX(-50%)
        left 50%
        width vw(580)
        bottom vw(60)
    &::before
      display block
      content ""
      background-size cover
      background-repeat no-repeat
      width 50%
      height 100%

  .department-block.position-left
    .department-block-inner
      +pc()
        top 50%
        transform translateY(-50%)
        left 80px
        padding 54px 50px 60px 58px
        +pcLiquid()
          left pcvw(@left)
          padding pcvw(40 48)
    &::before
      background-position center right

  .department-block.position-right
    .department-block-inner
      +pc()
        top 50%
        transform translateY(-50%)
        right 80px
        padding 54px 50px 40px 48px
        +pcLiquid()
          right pcvw(@right)
          padding pcvw(@padding)
    &::before
      margin 0 0 0 auto
      background-position center left

  .department-block
    .department-heading-ja
      font-weight 300
      letter-spacing .2em
      line-height 1
      color $thema-text-black
      +pc()
        margin-bottom 16px
        font-size 36px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
          font-size pcvw(@font-size)
      +sp()
        margin-bottom vw(22)
        font-size vw(47)
    .department-heading-en
      font-weight 300
      letter-spacing .2em
      line-height 1.6

  .message-container
    .withBorder
      +pc()
        margin-bottom 54px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)

  .link-container--back
    text-align center
    .text-link
      cmn-arrow-link--back($thema-text-purple)

      &.-bungakubu
        cmn-arrow-link--back($color-bungakubu)

      &.-eiyokagakubu
        cmn-arrow-link--back($color-eiyokagakubu)

      &.-jc
        cmn-arrow-link--back($color-jc)

  .link-container
    text-align right

  .c-dean_nameplate
    position absolute
    right -1px
    background-color $white
    span
      display block
      line-height 1.2
      letter-spacing 0.05em
    +pc()
      bottom 20px
      padding 12px 20px
      +pcLiquid()
        bottom pcvw(@bottom)
        padding pcvw(@padding)
    +sp()
      bottom vw(40)
      padding vw(20 32)
    .job
      +pc()
        font-size 15px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(28)
    .name
      +pc()
        font-size 20px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(36)

  .c-faculty_message
    +pc()
      display flex
      justify-content space-between
    .box-image
      +pc()
        position relative
        width 300px
        height 400px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
      +sp()
        position relative
        margin-bottom vw(40)
      img
        +pcLiquid()
          width pcvw(300px)
          height auto
        +sp()
          width 100%
          height auto
    .box-text
      +pc()
        width 600px
        +pcLiquid()
          width pcvw(@width)
      .heading
        +sp()
          text-align center
      .paragraph:not(:first-of-type)
        +pc()
          margin-top 20px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(40)

  .c-list-class_introduction
    .heading
      +pc()
        margin-bottom 10px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(34)
    .box-img
      +sp()
        margin-bottom vw(40)

  .c-department_top
    position relative
    background-size cover
    background-position center
    +pc()
      height 300px
      +pcLiquid()
        height pcvw(@height)
    +sp()
      height vw(862)
    .c-department_top-inner
      position absolute
      width 100%
      +pc()
        top 50%
        left 40px
        transform translateY(-50%)
      +sp()
        padding vw(0 26)
        bottom vw(90)
        left 50%
        transform translateX(-50%)
    .c-department_top-text
      color $white
      +pc()
        width 50%
      +sp()
        font-size vw(30)
        letter-spacing normal
    &::before
      display block
      content ""
      background-repeat no-repeat
      width 100%
      height 100%
      +pc()
        background-position center left
        background-size contain
      +sp()
        background-position bottom center
        background-size 110% 51%

    &.return
      &::before
        +pc()
          background-position center right
    &.return
      .c-department_top-text
        +pc()
          width 42%
          margin-left auto

  .block-department
    padding-top $mgn-lv3
    +sp()
      padding-right vw($mgn-lv2)
      padding-left vw($mgn-lv2)

  .withBlock_list
    +pc()
      margin-left 20px
      +pcLiquid()
        margin-left pcvw(@margin-left)
    +sp()
      margin-left vw(20)

  .wrap-list
    +pc()
      display flex
      justify-content space-between
      .wrap-list-inner
        width 46%

  .list-info
    +pc()
      display flex
      justify-content space-between
      flex-wrap wrap
      &::after
        content ""
        display block
        width 340px
        +pcLiquid()
          width pcvw(@width)
    .information-contnt-block
      +pc()
        margin-bottom 50px
        width 340px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
          width pcvw(@width)
      +sp()
        margin-bottom vw(84)
    .information-contnt-block-date
      color $thema-text-black
    .information-contnt-block-title
      color $thema-text-black

  .list-learning_power
    +pc()
      display flex
      justify-content space-between
    li
      background-color $thema-bg-lightGrey
      +pc()
        padding 30px 20px 26px
        width 300px
        +pcLiquid()
          padding pcvw(@padding)
          width pcvw(@width)
      +sp()
        padding vw(68 75 78)
        &:not(:first-of-type)
          margin-top vw(60)
    .heading
      text-align center
      +pc()
        min-height 70px
        margin-bottom 8px
        +pcLiquid()
          min-height pcvw(@min-height)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(32)
    .border
      &::before
        content ""
        display block
        height 1px
        background $thema-text-black
        +pc()
          width 20px
          margin 0 auto 20px
          +pcLiquid()
            width pcvw(@width)
            margin pcvw(@margin)
        +sp()
          width vw(40)
          margin vw(0 auto 40)

  .list-features
    background-color $thema-bg-lightGrey
    +sp()
      padding-bottom vw(40)
      width 100vw
      margin-left -5.333vw
    li
      position relative
    .area-vertical
      .text-Q
        display block
        font-family $Roboto
        +pc()
          margin-left .5em
          text-align center
          font-size 50px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(100)
      .text
        margin auto
        display block
        +pc()
          -ms-writing-mode tb-rl
          writing-mode vertical-rl
    .box-text
      position relative
      background-color $white
      +sp()
        width 94.667%
        padding vw(40)
        .headeing3
          margin-bottom vw(64)
      .area-answer
        +pc()
          width 666px
          +pcLiquid()
            width pcvw(@width)
        .answer-main
          display table
          margin-bottom $mgn-lv1_1
          .text-A
            display table-cell
            font-family $Roboto
            +pc()
              font-size 30px
              +pcLiquid()
                font-size pcvw(@font-size)
            +sp()
              display block
              font-size vw(60)
      .text
        +pc()
          margin-bottom 26px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)
        +sp()
          margin-bottom vw(40)
        &:last-of-type
          margin-bottom 0

    .item-1
      .box-img
        img
          +pcLiquid()
            width pcvw(940)
            height auto
          +sp()
            width 100%
            height auto
      .box-text
        +pc()
          display flex
          justify-content space-between
          width 860px
          margin -60px 0 auto auto
          padding 40px 0 14px 40px
          +pcLiquid()
            width pcvw(@width)
            margin pcvw(@margin)
            padding pcvw(@padding)
        +sp()
          margin vw(-290 0 0 auto)
      .area-vertical
        +sp()
          margin-bottom vw(36)

    .item-2
      .box-img
        img
          +pcLiquid()
            width pcvw(780)
          +sp()
            width 100%
            height auto
      .area-vertical
        +pc()
          position absolute
          top 0
          right 0
          width 160px
          +pcLiquid()
            width pcvw(@width)
        +sp()
          position relative
          margin vw(-290 0 0)
          width 94.667%
          padding vw(40 40 0 40)
          background-color $white

      .box-text
        +pc()
          margin-top -60px
          padding 35px 60px 38px 40px
          width 700px
          +pcLiquid()
            margin-top pcvw(@margin-top)
            padding pcvw(@padding)
            width pcvw(@width)

  .content-career
    .section-point
      .withBorder
        +pc()
          margin-bottom 56px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)
    .section-voice
      .withBorder
        +pc()
          margin-bottom 90px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)

  .wrap-other_link
    margin 0 auto
    +pc()
      width 932px
      display flex
      justify-content space-between
      flex-wrap wrap
      +pcLiquid()
        width pcvw(@width)

  .block-other_link
    background-color $thema-bg-lightGrey
    position relative
    display block
    +pc()
      padding 20px
      width 450px
      +pcLiquid()
        width pcvw(@width)
        padding pcvw(@padding)
    +sp()
      padding vw(24 24 24 36)
      &:nth-of-type(2)
        margin-top vw(40)

    //- 学部長メッセージリンク
    &.dean_message
      display flex
      .heading
        color $thema-text-black
        +pc()
          margin-left 25px
          +pcLiquid()
            margin-left pcvw(@margin-left)
        +sp()
          margin-left vw(20)
      img
        +pcLiquid()
          width pcvw(100)
          height pcvw(100)
        +sp()
          width vw(142)
          height vw(142)
    .list-policy
      li
        +sp()
          font-size vw(24)
    .link-container
      position absolute
      +pc()
        bottom 20px
        right 30px
        +pcLiquid()
          bottom pcvw(@bottom)
          right pcvw(@right)
      +sp()
        bottom vw(20)
        right vw(24)

  // 卒業生の声
  .list-voice
    li
      position relative
      background-color $thema-bg-lightGrey
      +pc()
        padding 36px 40px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(132 20 20)
        margin-top vw(104)

    .text-heading
      text-align center
      color $thema-text-purple
      +pc()
        margin-left 50px
        width 500px
        +pcLiquid()
          width pcvw(@width)
          margin-left pcvw(@margin-left)

    .wrap-number
      position absolute
      +pc()
        top -30px
        +pcLiquid()
          top pcvw(@top)
      +sp()
        top -40px
        left 50%
        transform translateX(-50%)

      .wrap-number-inner
        position relative
        +pc()
          width 120px
          height 120px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          background url('../img/faculty/voice-number_bg-under.svg') no-repeat center / contain
          width vw(160)
          height vw(182)

        .number
          position absolute
          left 50%
          transform translateX(-50%)
          font-family $Roboto
          color $white
          +pc()
            font-size 40px
            bottom 20px
            +pcLiquid()
              font-size pcvw(@font-size)
              bottom pcvw(@bottom)
          +sp()
            font-size vw(60)
            bottom vw(40)

    .box-img
      +pc()
        position absolute
        top -30px
        +pcLiquid()
          top pcvw(@top)
      +sp()
        margin-bottom vw(25)

    .box-img
      img
        +pc()
          margin-bottom 16px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)
            width pcvw(260px)
            height auto
        +sp()
          width 100%
          height auto
          margin-bottom vw(25)

    .text-voice
      border-radius 20px
      background-color $white
      +pc()
        padding 34px 40px
        width 580px
        +pcLiquid()
          padding pcvw(@padding)
          width pcvw(@width)
      +sp()
        padding vw(20 40)

    .youtube
      background-color $white
      +pc()
        padding 0 40px 0 160px
        margin-top -40px
        +pcLiquid()
          padding pcvw(@padding)
          margin-top pcvw(@margin-top)
      +sp()
        padding vw(132 0 20)
      .text-heading
        text-align left
        +pc()
          margin-left 0
          width 100%
      .box-youtube
        position relative
        +pc()
          padding-top 48.25%
          width 540px
          +pcLiquid()
            width pcvw(@width)
        +sp()
          width 100%
          padding-top 56.25%
        iframe
          position absolute
          top 0
          right 0
          width 100%
          height 100%
      .wrap-number
        +pc()
          top 0
          right auto
          left -20px
          +pcLiquid()
            left pcvw(@left)

  .list-voice
    li:nth-of-type(odd)
      .box-img
        +pc()
          right 20px
          +pcLiquid()
            right pcvw(@right)
      .wrap-number
        +pc()
          left -20px
          +pcLiquid()
            left pcvw(@left)
  .list-voice
    li:nth-of-type(even)
      .box-img
        +pc()
          left 20px
          +pcLiquid()
            left pcvw(@left)
      .text-heading
        +pc()
          margin-right 50px
          margin-left auto
          +pcLiquid()
            margin-right pcvw(@margin-right)
      .text-voice
        margin-left auto
      .wrap-number
        +pc()
          right -20px
          +pcLiquid()
            right pcvw(@right)

  .block-learning_support
    background-color $thema-bg-lightGrey
    +pc()
      padding 16px
      +pcLiquid()
        padding (@padding)
    +sp()
      padding vw(32)

  .c-info_item
    +pc()
      margin-bottom 50px
      width 340px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
        width pcvw(@width)
    +sp()
      margin-bottom vw(84)
    a
      display block
      +pc()
        &:hover
          .info_item--image
            img
              transform translate(-50%, -50%) scale(1.1)
    .info_item--image
      position relative
      text-align center
      overflow hidden
      width 100%
      background-color $white
      +pc()
        height 220px
        +pcLiquid()
          height pcvw(@height)
      img
        +pc()
          width auto
          height auto
          max-width 100%
          max-height 100%
          position absolute
          transform translate(-50%, -50%)
          top 50%
          left 50%
          transition transform .3s
        +sp()
          width 100%
          height 100%
    .info_item--text
      +pc()
        padding 15px 10px 0
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(40 20 0)
    .info_item--date
      color $thema-text-black
      font-family $Roboto
      letter-spacing ls(50)
      display inline-block
      +pc()
        font-size 1.6rem
        +pcLiquid()
          font-size pcvw(16)
      +sp()
        font-size vw(32)
    .info_item--category
      display inline-block
      +pc()
        margin-left 20px
        +pcLiquid()
          margin-left pcvw(@margin-left)
      +sp()
        margin-left vw(20)
      span
        display inline-block
        text-align center
        white-space nowrap
        letter-spacing ls(50)
        color $thema-text-purple
        border 1px solid $thema-text-purple
        +pc()
          font-size 1.2rem
          padding 3px 10px
          min-width 120px
          +pcLiquid()
            font-size pcvw(12)
            padding pcvw(@padding)
            min-width pcvw(120)
        +sp()
          font-size vw(24)
          padding vw(10)
          min-width vw(184)
    .info_item--title
      font-family $NotoSans
      font-weight 500
      letter-spacing ls(50)
      display -webkit-box
      overflow hidden
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      color $thema-text-black
      +pc()
        font-size 1.4rem
        line-height round(26 / 14, 2)
        margin-top 10px
        +pcLiquid()
          font-size pcvw(14)
          margin-top pcvw(@margin-top)
      +sp()
        font-size vw(28)
        line-height 2
        margin-top vw(30)

  .contents-field
    +pc()
      width 640px
      +pcLiquid()
          width pcvw(@width)
    +sp()
      min-width 100%

  .wrap-career-graph
    +pc()
      display flex
      align-self center
    .percentage
      font-family $Roboto
      font-weight 300

  .career-graph
    text-align center
    position relative
    +pc()
      width 600px
      +pcLiquid()
        width pcvw(@width)
    .big
      +pc()
        font-size 85px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(130)
    .sml
      letter-spacing -0.04em
      +pc()
        font-size 55px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(78)
    .number-box
      position absolute
      text-align center
      .number-box--inner
        margin 0 auto
        +pc()
          width 160px
          +pcLiquid()
            width pcvw(@width)
        +sp()
          width vw(220)
    img
      +pcLiquid()
        height auto
      +sp()
        width 100%
        height auto

  .wrap-career-detail
    +pc()
      display flex
      align-items center
  .career-detail
    +pc()
      display flex
      align-items flex-start
    .big
      +pc()
        font-size 56px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(90)
    .sml
      +pc()
        font-size 36px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
       font-size vw(54)
    .brackets
      white-space nowrap
      letter-spacing .05em
    .career-year
      .career-year--item
        &:not(:first-of-type)
          +pc()
            margin-top 20px
            +pcLiquid()
              margin-top pcvw(@margin-top)
        .year
          +pc()
            margin-bottom 8px
            +pcLiquid()
              margin-bottom pcvw(@margin-bottom)
          +sp()
            margin-bottom vw(16)
        +sp()
          text-align center
      +sp()
        display flex
        justify-content space-between
        align-items baseline
        margin-top vw(80)

  .career-area
    border 1px solid $lightGray
    +pc()
      max-width 186px
      padding 24px 30px
      margin-left 37px
      +pcLiquid()
        max-width pcvw(@max-width)
        padding pcvw(@padding)
        margin-left pcvw(@margin-left)
    +sp()
      width 100%
      padding vw(48 60)
      margin-top vw(44)
    .lead
      letter-spacing .05em
      +pc()
        margin-bottom .5em
      +sp()
        text-align center
        margin-bottom vw(40)
    .career-area--item
      .area,.percentage
        display inline-block
        vertical-align baseline
        line-height 2
        font-weight 400
        +pc()
          font-size 16px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(32)
      .area
        margin-right .2em
    .wrap-box_sp
      +sp()
        display flex
        justify-content space-between
        .box_sp
          width 40%

  .liquid_image-mid
    img
      +pcLiquid()
        width pcvw(940)
        height auto
      +sp()
        width 100%
        height auto


container-accordion--course($color)
  .accordion-button
    position relative
    background-color $thema-bg-lightGrey
    cursor pointer
    +pc()
      padding 16px 20px
      width 400px
      +pcLiquid()
        padding pcvw(@padding)
        width pcvw(@width)
      &:hover
        transition .2s
        opacity .6
    +sp()
      padding vw(32 40)
    &::before,
    &::after
      content ""
      background $color
      position absolute
      top 50%
      transform translateY(-50%)
      +pc()
        right 20px
        width 20px
        height 3px
        +pcLiquid()
          right pcvw(@right)
          width pcvw(@width)
          height pcvw(@height)
      +sp()
        right vw(20)
        width vw(20)
        height vw(4)
    &::after
      transform translateY(-50%) rotate(90deg)
      transition .5s
    &.opend
      &:after
        transform translateY(-50%) rotate(0)

  .accordion-inner
    display none
    li
      +pc()
        margin-bottom 32px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(64)
    .course_title
      color $color
      +pc()
        margin-bottom 8px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(16)

// 学びのポイント
list-learning_point($color)
  +pc()
    display flex
    justify-content space-between

  .list-learning_point--item
    position relative
    +pc()
      width 260px
      +pcLiquid()
        width pcvw(@width)
      +pcLiquid()
        width pcvw(@width)
    +sp()
      :not(:first-of-type)
        margin-top vw(100)

    .img_box
      position relative
      margin auto
      z-index 1
      +pc()
        width 220px
        height 220px
        margin-bottom 30px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        width 80%
        margin-bottom vw(60)

      img
        +pcLiquid()
          width pcvw(220)
          height auto
        +sp()
          width 100%
          height auto

      &::before
        position absolute
        top 6%
        left 2%
        display block
        content ""
        width 100%
        height 100%
        border-radius 50%
        background-color $color
        opacity .5
        z-index -1

    .number
      position absolute
      left 0
      color $color
      font-family $Roboto
      z-index 100
      opacity .8
      +pc()
        top 5%
        font-size 50px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(140)
    h3
      text-align center
      +sp()
        margin-bottom vw(32)

  .list-learning_point--item
    &.col4
      +pc()
        width 210px
        +pcLiquid()
          width pcvw(@width)
      .img_box
        +pc()
          width 200px
          height 200px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        img
          +pcLiquid()
            width pcvw(200)
            height auto
          +sp()
            width 100%
            height auto

block-page_index($color)
  background-color $thema-bg-lightGrey
  +pc()
    padding 54px 70px 57px
    +pcLiquid()
      padding pcvw(@padding)
  +sp()
    padding vw(72 40)
  .heading4
    color $color
    +pc()
      margin-bottom 20px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
    +sp()
      margin-bottom vw(40)

  .list-page_index
    li
      +pc()
        display inline-block
      &:not(:last-of-type)
        +pc()
          margin-right 40px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(20)
      a
        font-weight normal
        color $color
        &.current
          border-bottom 1px solid $color

[data-category="faculty"]
    .scroll-container
      overflow-x auto
      overflow-y hidden
      +pc()
        padding-bottom 20px
        margin-bottom 5px
        +pcLiquid()
          padding-bottom pcvw(@padding-bottom)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        padding-bottom vw(20)
        margin-bottom vw(10)
      .box-img
        +pc()
          width 1820px //画像サイズ
          height auto
          +pcLiquid()
            width pcvw(@width)
        +sp()
          width vw(3620)
          height vw(1150)
