[data-category="access"]
  .page-header
    +pc()
      background-image url('../img/access/header_bg.jpg')
    +sp()
      background-image url('../img/access/header_bg@2x.jpg')
  // access配下すべてに適用する場合はこちら
  //サンプルです
  .unique-wrap
    .sample-component
      +pc()
        margin-right 15px
        +pcLiquid()
          margin-right pcvw(@margin-right)
      +sp()
        margin-bottom vw(30)

  // トップだけに適用する場合はこちら
  [data-access="index"]
    //サンプルです
    .unique-wrap
      .sample-component
        +pc()
          margin-right 15px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(30)
    .box
      &.text
        +pc()
          width 60%
      &.img
        +pc()
          width 37%
    .iframe-map
      width 100%
    .c-info_item
      +pc()
        width 'calc(50% - %s)' % ($mgn-lv2 / 2)
        +pcLiquid()
          width 'calc(50% - %s)' % pcvw($mgn-lv2 / 2)
      +sp()
        margin-bottom vw(84)
      a
        display block
      .info_item--image
        position relative
        text-align center
        overflow hidden
        width 100%
        background-color $white
        +pc()
          height 280px
          +pcLiquid()
            height pcvw(@height)
      img
        width 100%
        height 100%
        +pc()
          transition transform .3s
      .info_item--text
        +pc()
          padding 15px 10px 0
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          padding vw(40 20 0)
      .info_item--title
        font-family $NotoSans
        font-weight 500
        letter-spacing ls(50)
        display -webkit-box
        overflow hidden
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        color $thema-text-black
        +pc()
          font-size 1.4rem
          line-height round(26 / 14, 2)
          +pcLiquid()
            font-size pcvw(14)
        +sp()
          font-size vw(28)
          line-height 2
         &:before
          content ""
          display inline-block
          background url('../img/common/textlink_arrow.png') no-repeat
          background-position center
          background-size contain
          +pc()
            width 8px
            height 12px
            margin-right 8px
            // +pcLiquid()
            //   width pcvw(@width)
            //   height pcvw(@height)
            //   margin-right pcvw(@margin-right)
          +sp()
            width vw(16)
            height vw(24)
            margin-right vw(10)
    .info
      &-link-container
        display flex
        justify-content flex-end
        +pc()
          margin-top 10px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(20)
      &-text-link
        cmn-arrow-link($thema-text-purple)
        pointer-events none
