.content-for_menu
  background-color $thema-bg-lightGrey!important

.content-for_menu-inner
  margin auto
  max-width 940px
  +pcLiquid()
    width 92%

.list__for_menu
  +pc()
    display flex
    flex-wrap wrap
    justify-content space-between

.item__for_menu
  background-color $white
  text-align center
  +pc()
    max-width 450px
    width 48%
    margin-bottom 40px
    height 260px
    &:hover
      .wrap_inner
        opacity .6
      .wrap_outer
        &::after
          left 53%
  +sp()
    max-width 100%
    width 100%
    height 193px
    margin-bottom 20px
  &.item__for_menu--wide
    max-width 100%
    width 100%
    +pc()
      height 180px
    +sp()
      height 193px

  .wrap_outer
    width 100%
    height 100%
    display block
    position relative

    &::after
      position absolute
      bottom 15%
      left 50.5%
      transform translateX(-50%)
      content ""
      background-image url('../img/for-commmon/arrow.svg')
      background-position center
      background-size contain
      background-repeat no-repeat
      transition all .2s ease-in-out
      +pc()
        width 26px
        height 7px
      +sp()
        width 20px
        height 5px

  .wrap_inner
    position absolute
    left 50%
    transform translateX(-50%)
    bottom 24%
    width 100%
    background-position top center
    background-repeat no-repeat
    transition all .2s ease-in-out

.list__for_menu_sab
  +pc()
    margin-top 20px
    display flex
    flex-wrap wrap
    justify-content space-between
    +pcLiquid()
      margin-top (@margin-top)

.item__for_menu_sab
  text-align center
  background-position top center
  background-repeat no-repeat
  +pc()
    width 48%
    max-width 450px
  +sp()
    max-width 100%
    width 100%

.for_menu_text_link
  margin-top 7%
  display inline-block
  font-family $NotoSans
  padding-left 10px
  color $thema-text-purple
  background-image url('../img/for-commmon/link_arrow.svg')
  background-position left center
  background-size 6px 12px
  background-repeat no-repeat
  letter-spacing 0.05em
  transition opacity .2s
  +pc()
    font-size 16px
    &:hover
      opacity  .6
  +sp()
    font-size 13px

.for_menu_wrap_link
  margin-top 8%
  +pc()
    margin-left 12%
    text-align left

  .for_menu_text_link
    margin-top 0
    +pc()
      margin-bottom 1.2em
    +sp()
      margin-bottom 2.2em

.for_menu_title_ja
  color $thema-text-black
  font-weight 400
  font-family $NotoSans
  +pc()
    font-size 20px
    letter-spacing 0.05em
    margin-bottom 0.6em
  +sp()
    font-size 19px
    margin-bottom .3em

.for_menu_title_en
  font-family $Roboto
  font-weight 300
  color $thema-text-purple
  letter-spacing 0.1em
  +pc()
    font-size 13px
  +sp()
    font-size 12px
