.section-heading
  font-family $Roboto
  font-weight lighter
  color $thema-text-purple
  text-align center
  overflow hidden
  +pc()
    margin-bottom 60px
    +pcLiquid()
      margin-bottom pcvw(@margin-bottom)
  +sp()
    margin-bottom vw(60)
  span
    display block
    letter-spacing ls(160)
    &:first-of-type
      +pc()
        font-size 4.6rem
        +pcLiquid()
          font-size pcvw(46)
      +sp()
        font-size vw(60)
    &:last-of-type
      font-family $NotoSans
      font-weight 400
      color $thema-text-purple
      letter-spacing ls(200)
      +pc()
        font-size 1.6rem
        padding-top 10px
        +pcLiquid()
          font-size pcvw(16)
          padding-top pcvw(@padding-top)
      +sp()
        font-size vw(26)
        padding-top vw(20)
