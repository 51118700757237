[data-category="for-alumni"]
  .page-header
    +pc()
      background-image url('../img/for-alumni/header_bg.jpg')
    +sp()
      background-image url('../img/for-alumni/header_bg@2x.jpg')

  // 盛岡大学聖陵同窓会だけに適用する場合はこちら
  [data-for-alumni="alumni-association"]
    .information-contnt-block
      a
        color $thema-text-black
    .information-contnt-block-image
      +pc()
        height 200px
        +pcLiquid()
          height pcvw(@height)
      +sp()
        height vw(428)
    // view more
    .content-link-container
      display flex
      justify-content flex-end
      +pc()
        margin-top 30px
      +sp()
        margin-top vw(60)
        margin-right vw(-60)
    .content-latest-container
      +pc()
        display flex
        margin-bottom 50px
      +sp()
        margin-bottom vw(80)
      li
        +pc()
          width 'calc(33.33% - %spx)' % (60 / 3)
          +pcLiquid()
            width 'calc(33.33% - %s)' % pcvw(60 / 3)
        & + li
          +pc()
            margin-left 30px
            +pcLiquid()
              margin-left pcvw(@margin-left)
            &:first-of-type
              margin-left 0
          +sp()
            margin-top vw(80)
        &.c-info_item
          +pc()
            margin-bottom 0
    // view more
    .information-link-container
      display flex
      justify-content flex-end
      position relative
      +pc()
        margin-top 30px
      // +sp()
      //   margin-top vw(60)
      //   margin-right vw(-60)

    .information-text-link
      cmn-arrow-link($thema-text-purple)
      +pc()
        position absolute
        right 0
        bottom 0
      // +sp()
      //   right vw($side-margin_sp)
      //   bottom vw(120)

  // 証明書関係 （盛岡大学短期大学部）
  [data-for-alumni="certificates-jc"]
    .anchorlink
      +pc()
        transition opacity .2s
        &:hover
          opacity .6
      &:after
        content ""
        display inline-block
        width 8px
        height 12px
        background url('../img/common/textlink_arrow-purple.svg') no-repeat
        background-position center
        background-size contain
        margin-left 8px
        transform rotate(90deg)
        transform-origin center center
        +sp()
          width vw(16)
          height vw(24)
          margin-left vw(12)
