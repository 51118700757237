$thisPage = "top"
$bgPath = '../img/' + $thisPage + '/'

// ボックスサイズの指定
$departmentBox-pc = {
  width: 90px
  height: 422px
}
$departmentBox-sp = {
  width: 335
  height: 150
}

[data-pages={'"'+$thisPage+'"'}]

  /**
  * common
  **/
  .main
    +pc()
      padding-bottom 130px
      +pcLiquid()
        padding-bottom pcvw(@padding-bottom)
    +header-sp()
      margin-top 0
    +sp()
      padding-bottom vw(260)
  // TOPだけオープンの時だけロゴが見えるようにする
  .header-logo-container
    &.l-sp
      +header-sp()
        display none
    &.l-pc
      +header-sp()
        display flex


  /**
  * intro
  **/
  // キービジュアル
  .keyVisual
    &-container
      position relative
      +header-pc()
        height pcvw(round(1186 / (16 / 9)))
        overflow hidden
        iframe
          height 50.83vw
      +header-sp()
        height pcvw(768)
      +sp()
        height vw(417)

      // スライドの時
      &.-is-slide
        height auto
        +sp()
          padding-top vw(120)
        // SP用横長ロゴ調整
        .keyVisual-logo
          +sp()
            position absolute
            top vw(23.58)
            left vw(40)
          img
            +sp()
              width vw(412)


    &-heading
      position absolute
      z-index 2
      +pc()
        top pcvw(333)
        left pcvw(90)
      +sp()
        bottom vw(190)
        left vw(40)
      span
        display none
      img
        +pc()
          width pcvw(343)
        +sp()
          width vw(441)
    &-bg-video
      opacity 0
      transition opacity 1s
      position relative
      width 100%
      height @width
      +pc()
        transform scale(1.033)
      &.ready
        opacity 10
      &::after
        content ''
        display block
        width 100%
        height @width
        position absolute
        top 0
        left 0
        z-index 2
        background-color alpha(#ccc, .2)
      // iframe
      #videoPlayer
        min-width 100%
        min-height 100%
        pointer-events none !important
        position absolute
        top 50%
        left 50%
        transform translate(-50%,-50%)
        iframe
          pointer-events none !important

    // KVSlide
    &-slider
      &-slide
        img
          width 100%
      &-button-prev
      &-button-next
        carousel-button()

    &-logo
      +pc()
        display none
      +sp()
        position absolute
        top vw(40)
        left vw(40)
        z-index 3
        img
          width vw(157)
    .playButton
      font-family $Roboto
      color $thema-text-purple
      letter-spacing ls(100)
      position absolute
      left 50%
      transform translateX(-50%)
      +pc()
        font-size pcvw(14)
        bottom pcvw(30)
        hover-opacity()
      +sp()
        font-size vw(22)
        bottom vw(50)
      .icon
        display flex
        align-items center
        justify-content center
        border-radius 50%
        border 1px solid $thema-text-purple
        margin-left auto
        margin-right auto
        +pc()
          width pcvw(30)
          height @width
          margin-bottom pcvw(12)
        +sp()
          width vw(56)
          height @width
          margin-bottom vw(20)
        &::after
          content ''
          display block
          width 0
          height 0
          border-style solid
          border-color transparent transparent transparent $thema-text-purple
          box-sizing border-box
          +pc()
            border-width pcvw(5 0 5 8)
          +sp()
            border-width vw(10.5 0 10.5 16)
  // 重要なお知らせ
  .notices
    &-container
      +pc()
        display flex
    &-inner
      position relative
      +pc()
        padding 25px
        +pcLiquid()
          padding pcvw(@padding)
      &:first-of-type
        display flex
        align-items center
        justify-content center
        position relative
        z-index 1
        background-color $thema-bg-purple
        +pc()
          width 266px
          flex-shrink 0
          +pcLiquid()
            width pcvw(@width)
        +sp()
          height vw(100)
        &::after
          content ''
          width 0
          height 0
          border-style solid
          position absolute
          +pc()
            border-width 12px 0 12px 13px
            border-color transparent transparent transparent $thema-bg-purple
            top 50%
            right -12px
            transform translateY(-50%)
            +pcLiquid()
              border-width pcvw(@border-width)
              right pcvw(@right)
          +sp()
            border-width vw(18 15 0 15)
            border-color $thema-bg-purple transparent transparent transparent
            bottom vw(-18)
            left 50%
            transform translateX(-50%)
      &:last-of-type
        width 100%
        background-color $white
        +pc()
          padding-right 150px
          padding-left 40px
          +pcLiquid()
            padding-right pcvw(@padding-right)
            padding-left pcvw(@padding-left)
        +sp()
          padding vw(45 $side-margin_sp 205)
    &-heading
      font-family $NotoSans
      font-weight bold
      color $white
      +pc()
        font-size 1.6rem
        letter-spacing ls(50)
      +sp()
        font-size vw(30)
        letter-spacing ls(100)
        display flex
        align-items center
      &::after
        content '!'
        font-weight lighter
        display flex
        align-items center
        justify-content center
        border-radius 50%
        border-style solid
        border-color $white
        margin-left auto
        margin-right auto
        +pc()
          font-size 1.6rem
          width 30px
          height @width
          border-width 2px
          margin-top 15px
          +pcLiquid()
            font-size pcvw(16)
            width pcvw(@width)
            height @width
            margin-top pcvw(@margin-top)
        +sp()
          font-size vw(26)
          width vw(40)
          height @width
          order -1
          border-width vw(2)
          margin-right vw(20)
    &-box
      display inline-block
      a
        color $thema-text-purple
        +pc()
          display flex
          line-height 1.5
          padding 5px 0
          &:hover
            .notices-caption
              text-decoration underline
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          display block
          padding vw(25 0)
    &-date
      font-family $Roboto
      +pc()
        font-size 1.4rem
        +pcLiquid()
          font-size pcvw(14)
      +sp()
        font-size vw(30)
        margin-bottom vw(15)
    &-caption
      font-family $NotoSans
      letter-spacing ls(50)
      +pc()
        font-size 1.3rem
        margin-left 20px
        +pcLiquid()
          font-size pcvw(13)
          margin-left pcvw(20)
      +sp()
        font-size vw(28)
        line-height round(45 / 28, 2)
    // view more
    &-text-link
      cmn-arrow-link($thema-text-purple)
      position absolute
      +pc()
        right 50px
        bottom 17px
        +pcLiquid()
          right pcvw(@right)
          bottom pcvw(@bottom)
      +sp()
        right vw($side-margin_sp)
        bottom vw(120)

  // バナー
  .banner
    &-container
      background-image url('../img/top/banner_bg.jpg')
      background-size cover
      background-position center center
      background-repeat no-repeat
      +pc()
        padding pcvw(40 0)
      +sp()
        padding vw(74 0)
    &-wrap
      text-align center
      +pc()
        padding pcvw(0 25)
      a
        display inline-block
        position relative
        +pc()
          box-shadow 10px 10px 10px alpha($black, .15)
          +pcLiquid()
            box-shadow pcvw(10px 10px 10px) alpha($black, .15)
          &:hover
            img
              opacity .7
        +sp()
          box-shadow vw(10px 10px 10px) alpha($black, .15)
        &::before
        &::after
          content ''
          display block
          width 100%
          height @width
          position absolute
          top 0
          left 0
          box-sizing border-box
          backface-visibility hidden
        &::before
          background-color $white
        &::after
          border-style solid
          border-color $white
          z-index 2
          +pc()
            border-width 6px
      img
        position relative
        z-index 1
        display block
        +pc()
          height pcvw(200)
          transition opacity .3s
        +sp()
          height vw(200)
    &-button-prev
    &-button-next
      carousel-button()
    &-button-prev
      +pc()
        left pcvw(245)
    &-button-next
      +pc()
        right pcvw(245)


  /**
  * content
  **/
  .content
    > section
      +pc()
        padding-top 100px
        +pcLiquid()
          padding-top pcvw(@padding-top)
      +sp()
        padding-top vw(120)

  /**
  * innformation
  **/
  .information
    &-container
      position relative
      overflow hidden
      +pc()
        maxWidth($width_pc01 + 100 px)
        padding 0 50px
        +pcLiquid()
          maxWidth(pcvw($width_pc01 + 100))
          padding pcvw(@padding)
      +sp()
        padding vw(0 100)
    // swiper button
    &-button-prev
    &-button-next
      carousel-button()
      top 40%
    &-button-prev
      +pc()
        left 25px
        +pcLiquid()
          left pcvw(@left)
      +sp()
        left 0
    &-button-next
      +pc()
        right 25px
        +pcLiquid()
          right pcvw(@right)
      +sp()
        right 0
    .information-contnt-block-image
      +pc()
        height 200px
        +pcLiquid()
          height pcvw(@height)
      +sp()
        height vw(428)
    // view more
    &-link-container
      display flex
      justify-content flex-end
      +pc()
        margin-top 30px
      +sp()
        margin-top vw(60)
        margin-right vw(-60)
    &-text-link
      cmn-arrow-link($thema-text-purple)

  /**
  * event
  **/
  .event
    &-container
      position relative
      overflow hidden
      +pc()
        maxWidth($width_pc01 + 100 px)
        padding 0 20px 0 50px
        +pcLiquid()
          maxWidth(pcvw($width_pc01 + 100))
          padding pcvw(@padding)
      +sp()
        padding vw(0 80 0 100)
    // &-outer
    //   +pc()
    // swiper slide
    .event-contnt-block-date
      &  + .event-contnt-block-date::before
        +sp()
          margin 0 .5rem
      .month
        +sp()
          font-size vw(38)
        &::after
          +sp()
            margin-left 0
            font-size vw(30)
      .day
        +sp()
          font-size vw(80)
      .week
        +sp()
          font-size vw(26)
    &-contnt-block
      height auto
      +pc()
        // width 33.33% !important
        padding-right 30px
        padding-bottom 20px
        +pcLiquid()
          padding-right pcvw(@padding-right)
          padding-bottom pcvw(@padding-bottom)
      +sp()
        padding-right vw(20)
        padding-bottom vw(20)
      a
        +pc()
          box-shadow 10px 10px 10px alpha($black, .07)
          +pcLiquid()
            box-shadow pcvw(10px 10px 10px) alpha($black, .07)
        +sp()
          box-shadow vw(10px 10px 10px) alpha($black, .07)
    // swiper button
    &-button-prev
    &-button-next
      carousel-button()
      top 40%
    &-button-prev
      +pc()
        left 25px
        +pcLiquid()
          left pcvw(@left)
    &-button-next
      +pc()
        right 25px
        +pcLiquid()
          right pcvw(@right)
    .event-contnt-block-image
      +sp()
        height vw(356)
    // view more
    &-link-container
      display flex
      justify-content flex-end
      +pc()
        margin-top 30px
      +sp()
        margin-top vw(60)
        margin-right vw(-60)
    &-text-link
      cmn-arrow-link($thema-text-purple)

  /**
  * about
  **/
  .about
    &-bg
      overflow hidden
      +pc()
        height 520px
        +pcLiquid()
          height pcvw(@height)
      +sp()
        height vw(520)
      &-inner
        width 100%
        height 100%
        display block
        position relative
        &::after
          content ''
          display block
          position absolute
          width 100%
          height 100%
          top 0
          left 0
          background linear-gradient(90deg, alpha($white, .7) 0, alpha($white, 0) 100%)
        img
          width 100%
          height 100%
          position absolute
          top 0
          left 0
    &-wrap
      background-color $white
      position relative
      z-index 1
      +pc()
        width 50%
        max-width 593px
        min-height 370px
        padding 60px 50px 60px 60px
        margin-left auto
        margin-top -140px
        +pcLiquid()
          min-height pcvw(@min-height)
          padding pcvw(@padding)
          margin-top pcvw(@margin-top)
      +sp()
        width vw(670)
        padding vw(60 60 120)
        margin vw(-100 auto 0)
      &-header
        font-family $NotoSans
        +pc()
          margin-bottom 20px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)
        +sp()
          margin-bottom vw(40)
        h3
          font-weight lighter
          letter-spacing ls(200)
          +pc()
            font-size 4rem
            +pcLiquid()
              font-size pcvw(40)
          +sp()
            font-size vw(48)
        h4
          font-weight lighter
          color $thema-text-purple
          letter-spacing ls(200)
          +pc()
            font-size 1.4rem
            margin-top 15px
            +pcLiquid()
              font-size pcvw(14)
              margin-top pcvw(@margin-top)
          +sp()
            font-size vw(26)
            line-height round(34 / 24, 2)
            margin-top vw(20)
      &-caption
        font-family $NotoSans
        line-height 2
        letter-spacing ls(50)
        +pc()
          font-size 1.6rem
          +pcLiquid()
            font-size pcvw(16)
        +sp()
          font-size vw(28)
      // view more
      &-link-container
        display flex
        justify-content flex-end
        +pc()
          margin-top 30px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(60)
      &-text-link
        cmn-arrow-link($thema-text-purple)


  /**
  * department
  **/
  .department
    &#department
      +sp()
        padding-top vw(40)
    .section-heading
      +pc()
        margin-bottom 40px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
    &-container
      +pc()
        display flex
        maxWidth($width_pc01 + 100 px)
        padding 0 50px
        +pcLiquid()
          maxWidth(pcvw($width_pc01 + 100))
          padding pcvw(@padding)
      +sp()
        padding vw(0 40)
    &-inner
      +pc()
        display flex
      +sp()
        & + .department-inner
          padding-top vw(20)
    &-wrap
      +pc()
        width 90px
        margin-right 30px
        +pcLiquid()
          width pcvw(@width)
          margin-right pcvw(@margin-right)
      +sp()
        margin-bottom vw(50)
      a
        +pc()
          display block
          hover-opacity()
          &:hover
            span::after
             transform translate3d(4px, 0, 0)
        +sp()
          display flex
          border-width 1px 0
          border-color #cccccc
          border-style solid
      &-bg
        background-repeat no-repeat
        background-size contain
        +pc()
          width $departmentBox-pc.width
          height $departmentBox-pc.height
          margin-bottom 20px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
            margin-bottom pcvw(@margin-bottom)
        +sp()
          width vw($departmentBox-sp.width)
          height vw($departmentBox-sp.height)
    h3
      font-family $NotoSans
      font-weight lighter
      color $white
      letter-spacing ls(300)
      display flex
      align-items center
      justify-content center
      position relative
      +pc()
        font-size 2.2rem
        writing-mode vertical-rl
        width $departmentBox-pc.width
        height $departmentBox-pc.height
        +pcLiquid()
          font-size pcvw(22)
          width pcvw(@width)
          height pcvw(@height)
      +sp()
        font-size vw(30)
        height vw(80)
      &::after
        content ''
        width 0
        height 0
        border-style solid
        position absolute
        +pc()
          border-width 7px 0 7px 7px
          top 50%
          right -7px
          transform translateY(-50%)
          +pcLiquid()
            border-width pcvw(@border-width)
            right pcvw(@right)
        +sp()
          border-width vw(13px 12.5px 0 12.5px)
          left 50%
          bottom vw(-12)
          transform translateX(-50%)
    h4
      font-family $NotoSans
      font-weight lighter
      letter-spacing ls(200)
      margin 0 auto
      text-align center
      +pc()
        font-size 2.8rem
        +pcLiquid()
          font-size pcvw(28)
      +sp()
        font-size vw(32)
        display flex
        align-items center
      span
        display inline-block
        position relative
        +pc()
          padding 0 5px 25px
          // border-bottom-style solid
          // border-bottom-width 1px
          writing-mode vertical-rl
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          padding-bottom vw(40)
        &::before
          content ''
          display block
          height 1px
          position absolute
          bottom 0
          +pc()
            width 100%
            left 0
          +sp()
            width vw(36)
            left 50%
            transform translateX(-50%)
        &::after
          content ''
          display block
          width 0
          height 0
          border-style solid
          margin-left auto
          position absolute
          bottom 0
          +pc()
            right 0
            border-width 9px 0 0 9px
            transition transform .3s
          +sp()
            left 'calc(50% + %s)' % vw(18)
            transform translateX(-50%)
            border-width vw(9 0 0 9)

    // カテゴリー毎のユニークスタイル
    [data-department="FacultyOfHumanities"]
      $thema-color = #5f1985
      h3
        background-color $thema-color
        +pc()
          margin-top 100px
        &::after
          +pc()
            border-color transparent transparent transparent $thema-color
          +sp()
            border-color $thema-color transparent transparent transparent
      h4
        color $thema-color
        span
          +pc()
            border-bottom-color $thema-color
          &::before
            background-color $thema-color
          &::after
            border-color transparent transparent transparent $thema-color
    [data-department="NutritionScience"]
      $thema-color = #9ec238
      h3
        background-color $thema-color
        &::after
          +pc()
            border-color transparent transparent transparent $thema-color
          +sp()
            border-color $thema-color transparent transparent transparent
      h4
        color $thema-color
        span
          +pc()
            border-bottom-color $thema-color
          &::before
            background-color $thema-color
          &::after
            border-color transparent transparent transparent $thema-color
    [data-department="PreschoolEducation"]
      $thema-color = #1886ba
      h3
        background-color $thema-color
        &::after
          +pc()
            border-color transparent transparent transparent $thema-color
          +sp()
            border-color $thema-color transparent transparent transparent
      h4
        color $thema-color
        span
          +pc()
            border-bottom-color $thema-color
          &::before
            background-color $thema-color
          &::after
            border-color transparent transparent transparent $thema-color
      .department-wrap
        &:last-of-type
          +sp()
            margin-bottom 0

    [data-department="english"]
      .department-wrap-bg
        +pc()
          background-image url('../img/top/department_bg_01.jpg')
        +sp()
          background-image url('../img/top/department_bg_01@2x.jpg')
    [data-department="japanese"]
      +pc()
        margin-top 115px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      .department-wrap-bg
        +pc()
          background-image url('../img/top/department_bg_02.jpg')
        +sp()
          background-image url('../img/top/department_bg_02@2x.jpg')
    [data-department="society"]
      +pc()
        margin-top 30px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      .department-wrap-bg
        +pc()
          background-image url('../img/top/department_bg_03.jpg')
        +sp()
          background-image url('../img/top/department_bg_03@2x.jpg')
    [data-department="childhood"]
      +pc()
        margin-top 120px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      .department-wrap-bg
        +pc()
          background-image url('../img/top/department_bg_04.jpg')
        +sp()
          background-image url('../img/top/department_bg_04@2x.jpg')
    [data-department="nutrition"]
      +pc()
        margin-top 40px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      .department-wrap-bg
        +pc()
          background-image url('../img/top/department_bg_05.jpg')
        +sp()
          background-image url('../img/top/department_bg_05@2x.jpg')
    [data-department="early-childhood"]
      +pc()
        margin-top 60px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      .department-wrap-bg
        +pc()
          background-image url('../img/top/department_bg_06.jpg')
        +sp()
          background-image url('../img/top/department_bg_06@2x.jpg')


  /**
   * インビューアニメーション
  **/
  // セッティング
  .section-heading
    span
      transition transform .5s .3s ease-in-out
      +pc()
        transform translateY(150%)
      +sp()
        transform translateY(170%)
      &:last-of-type
        transition-delay .5s

  .information-container
  .event-container
    transition transform .5s .9s ease-in-out, opacity .5s .9s ease-in-out
    transform translateY(40px)
    opacity 0

  .about-bg
    transition opacity .5s ease-in-out
    opacity 0
  .about-wrap
    transition transform .5s ease-in-out, opacity .5s ease-in-out
    transform translateY(40px)
    opacity 0

  .department-inner
    .department-wrap
      opacity 0
      transition transform .5s ease-in-out, opacity .5s ease-in-out
      +pc()
        &:nth-of-type(odd)
          transform translateY(40px)
          // transition-delay .9s
        &:nth-of-type(even)
          transform translateY(-40px)
          // transition-delay 1.1s
      +sp()
        transform translateY(40px)
  [data-department="FacultyOfHumanities"]
    .department-wrap
      for i in (1..5)
        &:nth-of-type({i})
          +pc()
            transition-delay (i/5)s
  [data-department="NutritionScience"]
    .department-wrap
      for i in (1..2)
        &:nth-of-type({i})
          +pc()
            transition-delay ((i/5)+.2)s
  [data-department="PreschoolEducation"]
    .department-wrap
      for i in (1..2)
        &:nth-of-type({i})
          +pc()
            // transition-delay ((i/5)+1.3)s
            transition-delay ((i/5))s

  // アニメーション
  .jsInview
    &.is-active
      .section-heading span
        transform translateY(0)
      .information-container
      .event-container
        transform translateY(0)
        opacity 1

  .about-bg.is-active
    opacity 1
  .about-wrap.is-active
    transform translateY(0)
    opacity 1

  // department セクションPC用
  .department.jsInview
    +pc()
      &.is-active
        .department-wrap
          transform translateY(0) !important
          opacity 1
  // department セクションSP用
  .department-wrap.jsInview
    +sp()
      &.is-active
        transform translateY(0)
        opacity 1
