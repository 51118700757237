@charset "utf-8";
* {
  box-sizing: border-box;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  background: transparent;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}
img {
  max-width: 100%;
  vertical-align: bottom;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
main {
  display: block;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3 {
  text-transform: initial;
}
button {
  font-size: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
button:focus {
  outline: 0;
}
.event-contnt-block a {
  display: block;
  height: 100%;
  color: #333;
  background-color: #fff;
}
@media (min-width: 768px) {
  .event-contnt-block a:hover .event-contnt-block-image img {
    transform: scale(1.1);
  }
}
@media (min-width: 768px) {
  .event-contnt-block-image {
    height: 220px;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-image {
    height: 57.33vw;
  }
}
.event-contnt-block-image img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .event-contnt-block-image img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-image {
    height: 16.11vw;
  }
}
@media (min-width: 768px) {
  .event-contnt-block-inner {
    padding: 30px 20px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-inner {
    padding: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-inner {
    padding: 2.2vw 1.46vw;
  }
}
.event-contnt-block-header-inner {
  display: flex;
}
.event-contnt-block-date,
[data-pages="event"] .content-archive-date {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.15em;
  position: relative;
}
.event-contnt-block-date + .event-contnt-block-date::before,
[data-pages="event"] .content-archive-date + .event-contnt-block-date::before {
  content: 'ー';
  display: inline-block;
  position: relative;
  top: -1rem;
}
@media (min-width: 768px) {
  .event-contnt-block-date + .event-contnt-block-date::before,
  [data-pages="event"] .content-archive-date + .event-contnt-block-date::before {
    font-size: 1.8rem;
    margin: 0 0.5rem;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-date + .event-contnt-block-date::before,
  [data-pages="event"] .content-archive-date + .event-contnt-block-date::before {
    font-size: 5.07vw;
    margin: 0 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-date + .event-contnt-block-date::before,
  [data-pages="event"] .content-archive-date + .event-contnt-block-date::before {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) {
  .event-contnt-block-date .month,
  [data-pages="event"] .content-archive-date .month {
    font-size: 2.6rem;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-date .month,
  [data-pages="event"] .content-archive-date .month {
    font-size: 6.13vw;
  }
}
.event-contnt-block-date .month::after,
[data-pages="event"] .content-archive-date .month::after {
  content: '/';
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  display: inline-block;
  margin-left: 0.5rem;
}
@media (min-width: 768px) {
  .event-contnt-block-date .month::after,
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-date .month::after,
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-date .month::after,
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-date .month,
  [data-pages="event"] .content-archive-date .month {
    font-size: 1.76vw;
  }
}
@media (min-width: 768px) {
  .event-contnt-block-date .day,
  [data-pages="event"] .content-archive-date .day {
    font-size: 5rem;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-date .day,
  [data-pages="event"] .content-archive-date .day {
    font-size: 13.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-date .day,
  [data-pages="event"] .content-archive-date .day {
    font-size: 3.37vw;
  }
}
.event-contnt-block-date .week,
[data-pages="event"] .content-archive-date .week {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .event-contnt-block-date .week,
  [data-pages="event"] .content-archive-date .week {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-date .week,
  [data-pages="event"] .content-archive-date .week {
    font-size: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-date .week,
  [data-pages="event"] .content-archive-date .week {
    font-size: 1.17vw;
  }
}
.event-contnt-block .-is-comingsoon {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .event-contnt-block .-is-comingsoon {
    font-size: 1.8rem;
    line-height: 2.78;
  }
}
@media (max-width: 767px) {
  .event-contnt-block .-is-comingsoon {
    font-size: 4.53vw;
    line-height: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block .-is-comingsoon {
    font-size: 1.17vw;
  }
}
.event-contnt-block-heading,
[data-pages="event"] .content-archive-title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .event-contnt-block-heading,
  [data-pages="event"] .content-archive-title {
    font-size: 1.4rem;
    line-height: 1.7;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-heading,
  [data-pages="event"] .content-archive-title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-heading,
  [data-pages="event"] .content-archive-title {
    font-size: 1.02vw;
    margin-top: 1.1vw;
  }
}
.event-contnt-block-category,
[data-pages="event"] .content-archive-category {
  display: inline-block;
}
@media (min-width: 768px) {
  .event-contnt-block-category,
  [data-pages="event"] .content-archive-category {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-category,
  [data-pages="event"] .content-archive-category {
    margin-top: 4vw;
  }
}
.event-contnt-block-category span,
[data-pages="event"] .content-archive-category span {
  color: #601986;
  letter-spacing: 0.05em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #601986;
}
@media (min-width: 768px) {
  .event-contnt-block-category span,
  [data-pages="event"] .content-archive-category span {
    font-size: 1.2rem;
    padding: 3px 10px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-category span,
  [data-pages="event"] .content-archive-category span {
    font-size: 3.2vw;
    padding: 1.33vw;
    min-width: 40.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-category span,
  [data-pages="event"] .content-archive-category span {
    font-size: 0.88vw;
    padding: 0.22vw 0.73vw;
    min-width: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-category,
  [data-pages="event"] .content-archive-category {
    margin-top: 3.29vw;
  }
}
.event-contnt-block-place,
.event-contnt-block-eventDate,
[data-pages="detail"] .content-header-place,
[data-pages="detail"] .content-header-eventDate,
[data-pages="event"] .content-archive-place {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .event-contnt-block-place,
  .event-contnt-block-eventDate,
  [data-pages="detail"] .content-header-place,
  [data-pages="detail"] .content-header-eventDate,
  [data-pages="event"] .content-archive-place {
    font-size: 1.4rem;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-place,
  .event-contnt-block-eventDate,
  [data-pages="detail"] .content-header-place,
  [data-pages="detail"] .content-header-eventDate,
  [data-pages="event"] .content-archive-place {
    font-size: 3.73vw;
    margin-top: 2.67vw;
  }
}
.event-contnt-block-place::before,
.event-contnt-block-eventDate::before,
[data-pages="detail"] .content-header-place::before,
[data-pages="detail"] .content-header-eventDate::before,
[data-pages="event"] .content-archive-place::before {
  content: '';
  display: block;
  background-image: url("../img/common/place_pin.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  .event-contnt-block-place::before,
  .event-contnt-block-eventDate::before,
  [data-pages="detail"] .content-header-place::before,
  [data-pages="detail"] .content-header-eventDate::before,
  [data-pages="event"] .content-archive-place::before {
    width: 20px;
    height: 24px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-place::before,
  .event-contnt-block-eventDate::before,
  [data-pages="detail"] .content-header-place::before,
  [data-pages="detail"] .content-header-eventDate::before,
  [data-pages="event"] .content-archive-place::before {
    width: 4.67vw;
    height: 5.47vw;
    margin-right: 1.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-place::before,
  .event-contnt-block-eventDate::before,
  [data-pages="detail"] .content-header-place::before,
  [data-pages="detail"] .content-header-eventDate::before,
  [data-pages="event"] .content-archive-place::before {
    width: 1.46vw;
    height: 1.76vw;
    margin-right: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-place,
  .event-contnt-block-eventDate,
  [data-pages="detail"] .content-header-place,
  [data-pages="detail"] .content-header-eventDate,
  [data-pages="event"] .content-archive-place {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
.event-contnt-block-eventDate,
[data-pages="detail"] .content-header-eventDate {
  margin-top: 0 !important;
}
.event-contnt-block-eventDate::before,
[data-pages="detail"] .content-header-eventDate::before {
  background-image: url("../img/common/icon_clock.svg");
}
@media (min-width: 768px) {
  .event-contnt-block-eventDate::before,
  [data-pages="detail"] .content-header-eventDate::before {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  .event-contnt-block-eventDate::before,
  [data-pages="detail"] .content-header-eventDate::before {
    width: 6.13vw;
    height: 6.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .event-contnt-block-eventDate::before,
  [data-pages="detail"] .content-header-eventDate::before {
    width: 1.76vw;
    height: 1.76vw;
  }
}
@media (min-width: 768px) {
  .filtering {
    padding: 40px 0 50px;
  }
}
@media (max-width: 767px) {
  .filtering {
    padding: 13.33vw 0vw;
  }
}
@media (min-width: 768px) {
  .filtering-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .filtering-section {
    padding: 0vw 5.33vw;
  }
}
.filtering-section > h2 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #333;
  letter-spacing: 0.05em;
  text-align: center;
}
@media (min-width: 768px) {
  .filtering-section > h2 {
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .filtering-section > h2 {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-section > h2 {
    font-size: 1.32vw;
    margin-bottom: 0.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  .filtering-container {
    max-width: 940px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-container {
    max-width: 68.81vw;
  }
}
@media (min-width: 768px) {
  .filtering-inner {
    display: flex;
    padding: 25px 20px 5px;
    border-bottom: 1px solid #ccc;
  }
}
@media (max-width: 767px) {
  .filtering-inner {
    padding: 8vw 0vw 2.67vw;
    border-bottom: 0.27vw solid #ccc;
  }
}
.filtering-inner:last-of-type {
  border-bottom: 0;
}
@media (min-width: 768px) {
  .filtering-inner:last-of-type {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .filtering-inner:last-of-type {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-inner:last-of-type {
    margin-bottom: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-inner {
    padding: 1.83vw 1.46vw 0.37vw;
  }
}
.filtering-type {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #333;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .filtering-type {
    font-size: 1.6rem;
    width: 146px;
    flex-shrink: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .filtering-type {
    font-size: 4vw;
    margin-bottom: 5.33vw;
  }
}
.filtering-type::before {
  content: '';
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  .filtering-type::before {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .filtering-type::before {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type::before {
    margin-right: 0.73vw;
  }
}
.filtering-type.-is-year::before {
  background-image: url("../img/common/icon_calendar.svg");
}
@media (min-width: 768px) {
  .filtering-type.-is-year::before {
    width: 23px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  .filtering-type.-is-year::before {
    width: 5.07vw;
    height: 5.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type.-is-year::before {
    width: 1.68vw;
    height: 1.76vw;
  }
}
.filtering-type.-is-person::before {
  background-image: url("../img/common/icon_person.svg");
}
@media (min-width: 768px) {
  .filtering-type.-is-person::before {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  .filtering-type.-is-person::before {
    width: 5.07vw;
    height: 5.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type.-is-person::before {
    width: 1.76vw;
    height: 1.76vw;
  }
}
.filtering-type.-is-place::before {
  background-image: url("../img/common/place_pin.svg");
}
@media (min-width: 768px) {
  .filtering-type.-is-place::before {
    width: 20px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  .filtering-type.-is-place::before {
    width: 4.93vw;
    height: 5.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type.-is-place::before {
    width: 1.46vw;
    height: 1.76vw;
  }
}
.filtering-type.-is-category::before {
  background-image: url("../img/common/icon_category.svg");
}
@media (min-width: 768px) {
  .filtering-type.-is-category::before {
    width: 21px;
    height: 24px;
  }
}
@media (max-width: 767px) {
  .filtering-type.-is-category::before {
    width: 5.2vw;
    height: 6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type.-is-category::before {
    width: 1.54vw;
    height: 1.76vw;
  }
}
.filtering-type.-is-keyword {
  margin-bottom: 0;
}
.filtering-type.-is-keyword::before {
  background-image: url("../img/common/icon_search.svg");
}
@media (min-width: 768px) {
  .filtering-type.-is-keyword::before {
    width: 23px;
    height: 23px;
  }
}
@media (max-width: 767px) {
  .filtering-type.-is-keyword::before {
    width: 5.6vw;
    height: 5.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type.-is-keyword::before {
    width: 1.68vw;
    height: 1.68vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-type {
    font-size: 1.17vw;
    width: 10.69vw;
  }
}
.filtering-select-container {
  display: flex;
  flex-wrap: wrap;
}
.filtering-select-container.-is-keyword {
  width: 100%;
  align-items: center;
}
.filtering-select-container.-is-keyword .filtering-input-box {
  width: 100%;
}
@media (min-width: 768px) {
  .filtering-select-container.-is-keyword .filtering-input-box {
    padding-right: 35px;
  }
}
.filtering-select-container.-is-keyword .filtering-input-box input {
  width: 100%;
  border-color: #ccc;
  border-style: solid;
  border-radius: 3px;
  padding: 0.2em 0.25em;
}
@media (max-width: 767px) {
  .filtering-select-container.-is-keyword .filtering-input-box input {
    font-size: 4vw;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-select-container.-is-keyword .filtering-input-box {
    padding-right: 2.56vw;
  }
}
.filtering-select-box {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .filtering-select-box {
    padding-right: 30px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .filtering-select-box {
    padding-right: 4vw;
    margin-bottom: 5.33vw;
  }
}
.filtering-select-box input[type=radio] {
  display: none;
}
.filtering-select-box input[type=radio] + label::before {
  border-radius: 50%;
}
.filtering-select-box input[type=radio]:checked + label::before {
  background-image: url("../img/common/icon_check_radio.png");
}
@media (min-width: 768px) {
  .filtering-select-box input[type=radio]:checked + label::before {
    background-size: 8px 8px;
  }
}
@media (max-width: 767px) {
  .filtering-select-box input[type=radio]:checked + label::before {
    background-size: 2.67vw 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-select-box input[type=radio]:checked + label::before {
    background-size: 0.59vw 0.59vw;
  }
}
.filtering-select-box input[type=checkbox] {
  display: none;
}
.filtering-select-box input[type=checkbox]:checked + label::after {
  content: '';
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../img/common/icon_check.png");
  position: absolute;
  top: 0;
}
@media (min-width: 768px) {
  .filtering-select-box input[type=checkbox]:checked + label::after {
    left: 1px;
    width: 17px;
    height: 11px;
  }
}
@media (max-width: 767px) {
  .filtering-select-box input[type=checkbox]:checked + label::after {
    left: 0.27vw;
    width: 5.2vw;
    height: 3.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-select-box input[type=checkbox]:checked + label::after {
    width: 1.24vw;
    height: 0.81vw;
  }
}
.filtering-select-box label {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #333;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  .filtering-select-box label {
    font-size: 1.4rem;
    cursor: pointer;
  }
}
@media (max-width: 767px) {
  .filtering-select-box label {
    font-size: 3.73vw;
  }
}
.filtering-select-box label::before {
  content: '';
  display: block;
  border: 1px solid #707070;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 768px) {
  .filtering-select-box label::before {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .filtering-select-box label::before {
    width: 4.8vw;
    height: 4.8vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-select-box label::before {
    width: 1.02vw;
    height: 1.02vw;
    margin-right: 0.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-select-box label {
    font-size: 1.02vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering-select-box {
    padding-right: 2.2vw;
    margin-bottom: 1.46vw;
  }
}
.filtering .submit-button {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #601986;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .filtering .submit-button {
    font-size: 1.5rem;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    margin-top: 10px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .filtering .submit-button:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  .filtering .submit-button {
    font-size: 4vw;
    width: 66.67vw;
    height: 13.33vw;
    border-radius: 6.67vw;
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering .submit-button {
    font-size: 1.1vw;
    width: 13.18vw;
    height: 2.93vw;
    border-radius: 1.46vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .filtering {
    padding: 2.93vw 0vw 3.66vw;
  }
}
.content-for_menu {
  background-color: #f5f6f8 !important;
}
.content-for_menu-inner {
  margin: auto;
  max-width: 940px;
}
@media (max-width: 1365px) {
  .content-for_menu-inner {
    width: 92%;
  }
}
@media (min-width: 768px) {
  .list__for_menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.item__for_menu {
  background-color: #fff;
  text-align: center;
}
@media (min-width: 768px) {
  .item__for_menu {
    max-width: 450px;
    width: 48%;
    margin-bottom: 40px;
    height: 260px;
  }
  .item__for_menu:hover .wrap_inner {
    opacity: 0.6;
  }
  .item__for_menu:hover .wrap_outer::after {
    left: 53%;
  }
}
@media (max-width: 767px) {
  .item__for_menu {
    max-width: 100%;
    width: 100%;
    height: 193px;
    margin-bottom: 20px;
  }
}
.item__for_menu.item__for_menu--wide {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .item__for_menu.item__for_menu--wide {
    height: 180px;
  }
}
@media (max-width: 767px) {
  .item__for_menu.item__for_menu--wide {
    height: 193px;
  }
}
.item__for_menu .wrap_outer {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.item__for_menu .wrap_outer::after {
  position: absolute;
  bottom: 15%;
  left: 50.5%;
  transform: translateX(-50%);
  content: "";
  background-image: url("../img/for-commmon/arrow.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .item__for_menu .wrap_outer::after {
    width: 26px;
    height: 7px;
  }
}
@media (max-width: 767px) {
  .item__for_menu .wrap_outer::after {
    width: 20px;
    height: 5px;
  }
}
.item__for_menu .wrap_inner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24%;
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .list__for_menu_sab {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .list__for_menu_sab {
    margin-top: 20px;
  }
}
.item__for_menu_sab {
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .item__for_menu_sab {
    width: 48%;
    max-width: 450px;
  }
}
@media (max-width: 767px) {
  .item__for_menu_sab {
    max-width: 100%;
    width: 100%;
  }
}
.for_menu_text_link {
  margin-top: 7%;
  display: inline-block;
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  padding-left: 10px;
  color: #601986;
  background-image: url("../img/for-commmon/link_arrow.svg");
  background-position: left center;
  background-size: 6px 12px;
  background-repeat: no-repeat;
  letter-spacing: 0.05em;
  transition: opacity 0.2s;
}
@media (min-width: 768px) {
  .for_menu_text_link {
    font-size: 16px;
  }
  .for_menu_text_link:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  .for_menu_text_link {
    font-size: 13px;
  }
}
.for_menu_wrap_link {
  margin-top: 8%;
}
@media (min-width: 768px) {
  .for_menu_wrap_link {
    margin-left: 12%;
    text-align: left;
  }
}
.for_menu_wrap_link .for_menu_text_link {
  margin-top: 0;
}
@media (min-width: 768px) {
  .for_menu_wrap_link .for_menu_text_link {
    margin-bottom: 1.2em;
  }
}
@media (max-width: 767px) {
  .for_menu_wrap_link .for_menu_text_link {
    margin-bottom: 2.2em;
  }
}
.for_menu_title_ja {
  color: #333;
  font-weight: 400;
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .for_menu_title_ja {
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 0.6em;
  }
}
@media (max-width: 767px) {
  .for_menu_title_ja {
    font-size: 19px;
    margin-bottom: 0.3em;
  }
}
.for_menu_title_en {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 300;
  color: #601986;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .for_menu_title_en {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .for_menu_title_en {
    font-size: 12px;
  }
}
.section-heading {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: lighter;
  color: #601986;
  text-align: center;
  overflow: hidden;
}
@media (min-width: 768px) {
  .section-heading {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section-heading {
    margin-bottom: 8vw;
  }
}
.section-heading span {
  display: block;
  letter-spacing: 0.16em;
}
@media (min-width: 768px) {
  .section-heading span:first-of-type {
    font-size: 4.6rem;
  }
}
@media (max-width: 767px) {
  .section-heading span:first-of-type {
    font-size: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .section-heading span:first-of-type {
    font-size: 3.37vw;
  }
}
.section-heading span:last-of-type {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #601986;
  letter-spacing: 0.2em;
}
@media (min-width: 768px) {
  .section-heading span:last-of-type {
    font-size: 1.6rem;
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .section-heading span:last-of-type {
    font-size: 3.47vw;
    padding-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .section-heading span:last-of-type {
    font-size: 1.17vw;
    padding-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .section-heading {
    margin-bottom: 4.39vw;
  }
}
.container-info .heading {
  text-align: center;
}
@media (min-width: 768px) {
  .list-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
@media (min-width: 768px) {
  .list-info .information-contnt-block {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  .list-info .information-contnt-block {
    margin-bottom: 11.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .list-info .information-contnt-block {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
.list-info .information-contnt-block-date {
  color: #333;
}
.list-info .information-contnt-block-title {
  color: #333;
}
@media (min-width: 768px) {
  .c-info_item {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  .c-info_item {
    margin-bottom: 11.2vw;
  }
}
.c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  .c-info_item a:hover .info_item--image img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
.c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  .c-info_item .info_item--image {
    height: 220px;
  }
}
@media (min-width: 768px) {
  .c-info_item .info_item--image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  .c-info_item .info_item--image img {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item .info_item--image {
    height: 16.11vw;
  }
}
@media (min-width: 768px) {
  .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
.c-info_item .info_item--date {
  color: #333;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
@media (min-width: 768px) {
  .c-info_item .info_item--date {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  .c-info_item .info_item--date {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item .info_item--date {
    font-size: 1.17vw;
  }
}
.c-info_item .info_item--category {
  display: inline-block;
}
@media (min-width: 768px) {
  .c-info_item .info_item--category {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .c-info_item .info_item--category {
    margin-left: 2.67vw;
  }
}
.c-info_item .info_item--category span {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
  color: #601986;
  border: 1px solid #601986;
}
@media (min-width: 768px) {
  .c-info_item .info_item--category span {
    font-size: 1.2rem;
    padding: 3px 10px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  .c-info_item .info_item--category span {
    font-size: 3.2vw;
    padding: 1.33vw;
    min-width: 24.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item .info_item--category span {
    font-size: 0.88vw;
    padding: 0.22vw 0.73vw;
    min-width: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item .info_item--category {
    margin-left: 1.46vw;
  }
}
.c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item .info_item--title {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-info_item {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
.information-contnt-block a {
  color: #333;
}
@media (min-width: 768px) {
  .information-contnt-block a:hover .information-contnt-block-image img {
    transform: scale(1.1);
  }
}
@media (min-width: 768px) {
  .information-contnt-block-image {
    height: 220px;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .information-contnt-block-image {
    height: 69.33vw;
  }
}
.information-contnt-block-image img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .information-contnt-block-image img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .information-contnt-block-image {
    height: 16.11vw;
  }
}
@media (min-width: 768px) {
  .information-contnt-block-inner {
    padding: 0 10px;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .information-contnt-block-inner {
    padding: 0vw 2.67vw;
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .information-contnt-block-inner {
    padding: 0vw 0.73vw;
    margin-top: 1.1vw;
  }
}
.information-contnt-block-date,
[data-pages="detail"] .content-header-date,
[data-pages="information"] .content-archive-date,
[data-category="about"] .content-archive-date {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
@media (min-width: 768px) {
  .information-contnt-block-date,
  [data-pages="detail"] .content-header-date,
  [data-pages="information"] .content-archive-date,
  [data-category="about"] .content-archive-date {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  .information-contnt-block-date,
  [data-pages="detail"] .content-header-date,
  [data-pages="information"] .content-archive-date,
  [data-category="about"] .content-archive-date {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .information-contnt-block-date,
  [data-pages="detail"] .content-header-date,
  [data-pages="information"] .content-archive-date,
  [data-category="about"] .content-archive-date {
    font-size: 1.17vw;
  }
}
.information-contnt-block-category,
[data-pages="detail"] .content-header-category,
[data-pages="information"] .content-archive-category,
[data-category="about"] .content-archive-category {
  display: inline-block;
}
@media (min-width: 768px) {
  .information-contnt-block-category,
  [data-pages="detail"] .content-header-category,
  [data-pages="information"] .content-archive-category,
  [data-category="about"] .content-archive-category {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .information-contnt-block-category,
  [data-pages="detail"] .content-header-category,
  [data-pages="information"] .content-archive-category,
  [data-category="about"] .content-archive-category {
    margin-left: 2.67vw;
  }
}
.information-contnt-block-category span,
[data-pages="detail"] .content-header-category span,
[data-pages="information"] .content-archive-category span,
[data-category="about"] .content-archive-category span {
  color: #601986;
  letter-spacing: 0.05em;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  border: 1px solid #601986;
}
@media (min-width: 768px) {
  .information-contnt-block-category span,
  [data-pages="detail"] .content-header-category span,
  [data-pages="information"] .content-archive-category span,
  [data-category="about"] .content-archive-category span {
    font-size: 1.2rem;
    padding: 3px 10px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  .information-contnt-block-category span,
  [data-pages="detail"] .content-header-category span,
  [data-pages="information"] .content-archive-category span,
  [data-category="about"] .content-archive-category span {
    font-size: 3.2vw;
    padding: 1.33vw;
    min-width: 24.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .information-contnt-block-category span,
  [data-pages="detail"] .content-header-category span,
  [data-pages="information"] .content-archive-category span,
  [data-category="about"] .content-archive-category span {
    font-size: 0.88vw;
    padding: 0.22vw 0.73vw;
    min-width: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .information-contnt-block-category,
  [data-pages="detail"] .content-header-category,
  [data-pages="information"] .content-archive-category,
  [data-category="about"] .content-archive-category {
    margin-left: 1.46vw;
  }
}
.information-contnt-block-title,
[data-pages="information"] .content-archive-title,
[data-category="about"] .content-archive-title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (min-width: 768px) {
  .information-contnt-block-title,
  [data-pages="information"] .content-archive-title,
  [data-category="about"] .content-archive-title {
    font-size: 1.4rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .information-contnt-block-title,
  [data-pages="information"] .content-archive-title,
  [data-category="about"] .content-archive-title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .information-contnt-block-title,
  [data-pages="information"] .content-archive-title,
  [data-category="about"] .content-archive-title {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
.ie11 [data-pages='top'] .keyVisual-bg-video video {
  width: auto;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.ie11 .event-contnt-block-date {
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .ie11 .event-contnt-block-date + .event-contnt-block-date::before {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ie11 .event-contnt-block-date + .event-contnt-block-date::before {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) {
  .ie11 .event-contnt-block-date .month {
    font-size: 2.4rem;
  }
}
@media (min-width: 768px) {
  .ie11 .event-contnt-block-date .month::after {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ie11 .event-contnt-block-date .month::after {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ie11 .event-contnt-block-date .month {
    font-size: 1.76vw;
  }
}
@media (min-width: 768px) {
  .ie11 .event-contnt-block-date .day {
    font-size: 4.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ie11 .event-contnt-block-date .day {
    font-size: 3.37vw;
  }
}
@media (min-width: 768px) {
  .ie11 .event-contnt-block-date .week {
    font-size: 1.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ie11 .event-contnt-block-date .week {
    font-size: 1.17vw;
  }
}
.ie11 .content-archive-date {
  writing-mode: initial;
}
.swiper-button-prev {
  transform: scaleX(-1) translateY(-50%);
}
.swiper-button-next {
  transform: translateY(-50%);
}
.link-point {
  display: block;
  border-bottom: 1px solid #ccc;
  position: relative;
  cursor: pointer;
  margin-top: 1em;
}
.link-point.theme_color-purple {
  border-bottom: 1px solid #601986;
}
.link-point.theme_color-purple::after {
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
}
@media (min-width: 768px) {
  .link-point {
    transition: border-bottom 0.2s;
  }
}
.link-point span {
  color: #333;
  font-weight: normal;
  padding-left: 0.5em;
}
@media (min-width: 768px) {
  .link-point span {
    transition: color 0.2s;
  }
}
.link-point span.theme_color-purple {
  color: #601986;
}
.link-point::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5em;
  content: '';
  background: url("../img/common/textlink_arrow-gray.svg") no-repeat;
}
@media (min-width: 768px) {
  .link-point::after {
    width: 8px;
    height: 12px;
    transition: background 0.2s;
    transition: top 0.25s;
  }
}
@media (max-width: 767px) {
  .link-point::after {
    width: 2.13vw;
    height: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .link-point::after {
    width: 0.59vw;
    height: 0.88vw;
  }
}
.link-point.under::after {
  transform: translateY(-50%) rotate(90deg);
}
@media (min-width: 768px) {
  .link-point.under:hover {
    opacity: 1 !important;
    border-bottom: 1px solid #601986;
  }
  .link-point.under:hover span {
    color: #601986;
  }
  .link-point.under:hover::after {
    top: 60%;
    background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
  }
}
.c-page_footer_nav {
  background-color: #fff;
  z-index: 1000;
  position: relative;
}
@media (min-width: 768px) {
  .c-page_footer_nav {
    padding: 100px 0 160px;
  }
}
@media (max-width: 767px) {
  .c-page_footer_nav {
    padding: 21.33vw 0vw 32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-page_footer_nav {
    padding: 7.32vw 0vw 11.71vw;
  }
}
.c-page_footer_nav--inner {
  max-width: 1080px;
  width: 100%;
  margin: auto;
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  .c-page_footer_nav--inner {
    padding: 54px 140px 40px 70px;
  }
}
@media (max-width: 767px) {
  .c-page_footer_nav--inner {
    width: 89%;
    padding: 10.67vw 2.67vw 5.33vw 5.33vw;
  }
}
.c-page_footer_nav--inner .c-page_footer_nav--heading {
  color: #601986;
  font-weight: 700;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .c-page_footer_nav--inner .c-page_footer_nav--heading {
    font-size: 20px;
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  .c-page_footer_nav--inner .c-page_footer_nav--heading {
    margin-bottom: 8vw;
    letter-spacing: 0.04em;
    font-size: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-page_footer_nav--inner .c-page_footer_nav--heading {
    margin-bottom: 2.34vw;
    font-size: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-page_footer_nav--inner {
    width: 90%;
    padding: 3.95vw 10.25vw 2.93vw 5.12vw;
  }
}
@media (min-width: 768px) {
  .c-list-page_footer_nav .c-item-page_footer_nav {
    margin: 0 36px 30px 0;
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .c-list-page_footer_nav .c-item-page_footer_nav {
    margin-bottom: 4.67vw;
  }
}
.c-list-page_footer_nav .c-item-page_footer_nav:before {
  margin-right: 5px;
  content: "";
  display: inline-block;
  background-image: url("../img/common/arrow-page_footer_nav.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .c-list-page_footer_nav .c-item-page_footer_nav:before {
    width: 8px;
    height: 13px;
  }
}
@media (max-width: 767px) {
  .c-list-page_footer_nav .c-item-page_footer_nav:before {
    width: 2.13vw;
    height: 3.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-list-page_footer_nav .c-item-page_footer_nav:before {
    width: 0.59vw;
    height: 0.95vw;
  }
}
.c-list-page_footer_nav .c-item-page_footer_nav a {
  letter-spacing: 0.05em;
  color: #601986;
}
@media (min-width: 768px) {
  .c-list-page_footer_nav .c-item-page_footer_nav a {
    font-size: 16px;
  }
  .c-list-page_footer_nav .c-item-page_footer_nav a:hover {
    text-decoration: underline;
  }
}
@media (max-width: 767px) {
  .c-list-page_footer_nav .c-item-page_footer_nav a {
    font-size: 4.53vw;
    letter-spacing: normal;
  }
}
.c-list-page_footer_nav .c-item-page_footer_nav a.current {
  border-bottom: 1px solid #601986;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-list-page_footer_nav .c-item-page_footer_nav a {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .c-list-page_footer_nav .c-item-page_footer_nav {
    margin: 0vw 2.64vw 2.2vw 0vw;
  }
}
@media (min-width: 768px) {
  .pagenation {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .pagenation {
    margin-top: 10.67vw;
  }
}
.pagenation-container {
  display: flex;
  justify-content: center;
}
.pagenation .page-number {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #5f1985;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
@media (min-width: 768px) {
  .pagenation .page-number {
    font-size: 2rem;
    width: 50px;
    height: 50px;
    transition: background-color 0.3s, color 0.3s;
    margin: 0 10px;
  }
  .pagenation .page-number:hover {
    color: #fff;
    background-color: #601986;
  }
}
@media (max-width: 767px) {
  .pagenation .page-number {
    font-size: 4.8vw;
    width: 12vw;
    height: 12vw;
    margin: 0vw 2.67vw;
  }
}
.pagenation .page-number > a,
.pagenation .page-number > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.pagenation .page-number.current {
  color: #fff;
  background-color: #601986;
}
.pagenation .page-number.current > a,
.pagenation .page-number.current > span {
  color: #fff;
  background-color: #601986;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .pagenation .page-number {
    font-size: 1.46vw;
    width: 3.66vw;
    height: 3.66vw;
    margin: 0vw 0.73vw;
  }
}
.pagenation .prev-btn,
.pagenation .next-btn {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../img/common/pagenation_arrow.png");
}
@media (min-width: 768px) {
  .pagenation .prev-btn,
  .pagenation .next-btn {
    width: 50px;
    height: 50px;
    background-size: 19px 17px;
    margin: 0 10px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .pagenation .prev-btn:hover,
  .pagenation .next-btn:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  .pagenation .prev-btn,
  .pagenation .next-btn {
    width: 12vw;
    height: 12vw;
    background-size: 4vw 3.6vw;
    margin: 0vw 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .pagenation .prev-btn,
  .pagenation .next-btn {
    width: 3.66vw;
    height: 3.66vw;
    background-size: 1.39vw 1.24vw;
    margin: 0vw 0.73vw;
  }
}
.pagenation .prev-btn {
  transform: scaleX(-1);
}
@media (min-width: 768px) and (max-width: 1365px) {
  .pagenation {
    margin-top: 4.39vw;
  }
}
.c-pagenav {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  .c-pagenav {
    padding: 10px 0;
    -webkit-position: sticky;
    position: sticky;
    top: 40px;
    z-index: 2;
  }
}
@media (min-width: 768px) {
  .c-pagenav .c-pagenav__wrap {
    display: flex !important;
    flex-wrap: wrap;
  }
}
.c-pagenav .c-pagenav__wrap a.link-text:before {
  display: none;
}
@media (min-width: 768px) {
  .c-pagenav .c-pagenav__wrap a.link-text[target="_blank"]:after {
    width: 1.1vw;
    height: 1.02vw;
    margin-right: 20px;
    vertical-align: sub;
  }
}
@media (max-width: 767px) {
  .c-pagenav .c-pagenav__wrap {
    display: none;
  }
}
@media (min-width: 768px) {
  .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list {
    position: relative;
    margin: 10px 0;
    z-index: 0;
  }
  .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list::before {
    content: '';
    display: block;
    width: calc(100% - 1px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    z-index: -1;
  }
}
@media (max-width: 767px) {
  .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list {
    padding: 4vw 5.33vw;
    border-top: 1px solid #ccc;
    position: relative;
  }
  .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list::before {
    content: '';
    width: 3.2vw;
    height: 1.6vw;
    display: block;
    position: absolute;
    top: 50%;
    right: 5.33vw;
    transform: translateY(-50%) rotate(90deg);
    background-size: 3.2vw 1.6vw;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%23601986' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  }
  [data-faculty="bungakubu"] .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%235f1985' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  }
  [data-faculty="eiyokagakubu"] .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%2385b401' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  }
  [data-faculty="jc"] .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%231886ba' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  }
  [data-category="about"] .c-pagenav .c-pagenav__wrap .c-pagenav__wrap__list::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%235f1985' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  }
}
.c-pagenav .c-pagenav--open-button {
  cursor: pointer;
  padding: 4vw 5.33vw;
  position: relative;
  color: #601986;
}
[data-faculty="bungakubu"] .c-pagenav .c-pagenav--open-button {
  color: #5f1985;
}
[data-faculty="eiyokagakubu"] .c-pagenav .c-pagenav--open-button {
  color: #85b401;
}
[data-faculty="jc"] .c-pagenav .c-pagenav--open-button {
  color: #1886ba;
}
[data-category="about"] .c-pagenav .c-pagenav--open-button {
  color: #5f1985;
}
@media (max-width: 767px) {
  .c-pagenav .c-pagenav--open-button .c-pagenav__text {
    font-size: 5.07vw;
  }
}
.c-pagenav .c-pagenav--open-button::before {
  content: '';
  width: 3.2vw;
  height: 1.6vw;
  display: block;
  position: absolute;
  top: 50%;
  right: 5.33vw;
  transform: translateY(-50%) scaleY(-1);
  background-size: 3.2vw 1.6vw;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%23601986' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  transition: transform 0.3s;
}
[data-faculty="bungakubu"] .c-pagenav .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%235f1985' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
[data-faculty="eiyokagakubu"] .c-pagenav .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%2385b401' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
[data-faculty="jc"] .c-pagenav .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%231886ba' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
[data-category="about"] .c-pagenav .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%235f1985' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
.c-pagenav .c-pagenav--open-button.opend::before {
  transform: scaleY(1);
}
.c-pagenav .c-pagenav__text {
  color: #601986;
}
[data-faculty="bungakubu"] .c-pagenav .c-pagenav__text {
  color: #5f1985;
}
[data-faculty="eiyokagakubu"] .c-pagenav .c-pagenav__text {
  color: #85b401;
}
[data-faculty="jc"] .c-pagenav .c-pagenav__text {
  color: #1886ba;
}
@media (min-width: 768px) {
  .c-pagenav .c-pagenav__text {
    font-size: 14px;
    padding: 0 20px;
    display: inline-block;
  }
}
@media (max-width: 1365px) {
  .c-pagenav .c-pagenav__text {
    font-size: 1.02vw;
  }
}
@media (max-width: 767px) {
  .c-pagenav .c-pagenav__text {
    font-size: 3.73vw;
  }
}
.c-pagenav .c-pagenav__text.-expand_0 {
  padding-right: 0;
}
[data-faculty="bungakubu"].pages,
[data-faculty="eiyokagakubu"].pages,
[data-faculty="jc"].pages {
  overflow: visible;
}
.block-page_index {
  background: #000;
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  .block-page_index {
    padding: 54px 70px 57px;
  }
}
@media (max-width: 767px) {
  .block-page_index {
    padding: 9.6vw 5.33vw;
  }
}
.block-page_index .heading4 {
  color: #601986;
}
@media (min-width: 768px) {
  .block-page_index .heading4 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .block-page_index .heading4 {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .block-page_index .heading4 {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  .block-page_index .list-page_index li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .block-page_index .list-page_index li:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
.block-page_index .list-page_index li a {
  font-weight: normal;
  color: $color;
}
.block-page_index .list-page_index li a.current {
  border-bottom: 1px solid #601986;
}
.block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .block-page_index {
    padding: 3.95vw 5.12vw 4.17vw;
  }
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
/* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
/* please don't change 'position' */
  position: absolute;
  opacity: 0.6;
}
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
/* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
/* please don't change 'position' */
  position: absolute;
  opacity: 0.6;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
/* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
/* please don't change 'position' */
  position: absolute;
}
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
/* there must be 'right' for ps__thumb-y */
  right: 2px;
/* please don't change 'position' */
  position: absolute;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
@media (max-width: 767px) {
  .js-scrollbar {
    position: relative;
    padding-bottom: 10px;
  }
}
.scroll_anime {
  transition: all 1s ease 0.2s;
}
.scroll_anime.fuwa {
  opacity: 0;
  transform: scale(0.95);
}
.scroll_anime.fuwa.scroll__start {
  opacity: 1;
  transform: scale(1);
}
.scroll_anime.fuwa-up {
  transform: translateY(15px);
  opacity: 0;
}
.scroll_anime.fuwa-up.scroll__start {
  transform: translateY(0px);
  opacity: 1;
}
.scroll_anime.fuwa-down {
  transform: translateY(-15px);
  opacity: 0;
}
.scroll_anime.fuwa-down.scroll__start {
  transform: translateY(0px);
  opacity: 1;
}
.scroll_anime.fuwa-right {
  transform: translateX(-15px);
  opacity: 0;
}
.scroll_anime.fuwa-right.scroll__start {
  transform: translateX(0px);
  opacity: 1;
}
.scroll_anime.fuwa-left {
  transform: translateX(15px);
  opacity: 0;
}
.scroll_anime.fuwa-left.scroll__start {
  transform: translateX(0px);
  opacity: 1;
}
[data-category="faculty"] .page-header {
  text-align: center;
}
[data-category="faculty"] .page-heading-faculty {
  color: #601986;
  letter-spacing: 0.1em;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .page-heading-faculty {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .page-heading-faculty {
    font-size: 4.27vw;
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .page-heading-faculty {
    margin-bottom: 1.46vw;
    font-size: 1.76vw;
  }
}
.ui-template {
  background: #fff;
}
@media (min-width: 768px) {
  .ui-template .pcOnly {
    display: block;
  }
}
@media (max-width: 767px) {
  .ui-template .pcOnly {
    display: none;
  }
}
@media (min-width: 768px) {
  .ui-template .spOnly {
    display: none;
  }
}
@media (max-width: 767px) {
  .ui-template .spOnly {
    display: block;
  }
}
.ui-template .bg-white {
  background: #fff;
}
.ui-template .bg-theme-thin {
  background: #f4eef8;
}
.ui-template .bg-grey {
  background: #f5f6f8;
}
.ui-template .bg-green {
  background: #9ec238;
}
.ui-template .bg-blue {
  background: #1a86b9;
}
.ui-template .color-theme {
  color: #5f1985;
}
.ui-template .bold {
  font-weight: bold;
}
.ui-template .abs_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ui-template .abs_centerX,
.ui-template .page-title:after,
.ui-template .anchorlinkL-container .anchorlink:before,
.ui-template .anchorlinkL-container .anchorlink:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ui-template .abs_centerY,
.ui-template [class^="heading"].withBlock:before,
.ui-template .accordion-container .accordion .question:before,
.ui-template .accordion-container .accordion .question:after,
.ui-template .accordion-container-basic .accordion .question:before,
.ui-template .accordion-container-basic .accordion .question:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.ui-template .flex {
  display: flex;
}
.ui-template .flex.-center {
  align-items: center;
  justify-content: center;
}
.ui-template .flex.-centerY {
  align-items: center;
}
.ui-template .flex.-centerX {
  justify-content: center;
}
.ui-template .flex.-wrap {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .ui-template .flex.-col4-to2 .box {
    width: calc(100% / 4 - 20px);
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .flex.-col4-to2 .box {
    width: calc(100% / 2 - 1.335vw);
  }
}
@media (min-width: 768px) {
  .ui-template .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .ui-template .flex.-col4-to2 .box:nth-child(odd) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .flex.-col4-to2 .box {
    width: calc(100% / 4 - 1.46vw);
    margin-right: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .flexPC {
    display: flex;
  }
  .ui-template .flexPC.-center {
    align-items: center;
    justify-content: center;
  }
  .ui-template .flexPC.-centerY {
    align-items: center;
  }
  .ui-template .flexPC.-centerX {
    justify-content: center;
  }
  .ui-template .flexPC.-between {
    justify-content: space-between;
  }
  .ui-template .flexPC.-col2 .box {
    width: 50%;
  }
  .ui-template .flexPC.-col2 .box[class*="per"]:first-child {
    margin-right: 60px;
  }
  .ui-template .flexPC[class^="-col"].per90 {
    width: 90%;
  }
  .ui-template .flexPC[class^="-col"].per80 {
    width: 80%;
  }
  .ui-template .flexPC[class^="-col"].per70 {
    width: 70%;
  }
  .ui-template .flexPC[class^="-col"].per60 {
    width: 60%;
  }
  .ui-template .flexPC[class^="-col"].per50 {
    width: 50%;
  }
  .ui-template .flexPC[class^="-col"].per40 {
    width: 40%;
  }
  .ui-template .flexPC[class^="-col"].per30 {
    width: 30%;
  }
  .ui-template .flexPC[class^="-col"].per20 {
    width: 20%;
  }
  .ui-template .flexPC[class^="-col"].per10 {
    width: 10%;
  }
  .ui-template .flexPC[class^="-col"]-center {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .flexPC:first-child {
    margin-right: 4.39vw;
  }
}
.ui-template .indentAdjust {
  padding-left: 1em;
  text-indent: -1em;
}
.ui-template .img img {
  width: 100%;
}
.ui-template .nowrap {
  white-space: nowrap;
}
.ui-template .js-accordionTable {
  cursor: pointer;
}
.ui-template .js-toggleTable {
  display: none;
}
.ui-template [data-modules="faculty"] [class*="u-professor_d-flex"] {
  display: flex;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_d-flex"][class*="__nosp"] {
    display: block;
  }
}
.ui-template [data-modules="faculty"] [class*="u-professor_d-block"] {
  display: block;
}
.ui-template [data-modules="faculty"] [class*="u-professor_d-inline-block"] {
  display: inline-block;
}
.ui-template [data-modules="faculty"] [class*="u-professor_align-items-center"] {
  align-items: center;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_align-items-center"][class*="__nosp"] {
    align-items: inherit;
  }
}
.ui-template [data-modules="faculty"] [class*="u-professor_pl-0_5"] {
  padding-left: 0.5em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_pl-4"] {
  padding-left: 4em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_pr-4"] {
  padding-right: 4em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_pt-1_6"] {
  padding-top: 1.6em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_pb-1_6"] {
  padding-bottom: 1.6em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_pt-custom"] {
  padding-top: 5.33vw !important;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pl"][class*="__nospPl"] {
    padding-left: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pl"][class*="__nopcPl"] {
    padding-left: initial;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pr"][class*="__nospPr"] {
    padding-right: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pr"][class*="__nopcPr"] {
    padding-right: initial;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pt"][class*="__nospPt"] {
    padding-top: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pt"][class*="__nopcPt"] {
    padding-top: initial;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pb"][class*="__nospPb"] {
    padding-bottom: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_pb"][class*="__nopcPb"] {
    padding-bottom: initial;
  }
}
.ui-template [data-modules="faculty"] [class*="u-professor_mt-08"] {
  margin-top: 0.8em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_mb-08"] {
  margin-bottom: 0.8em;
}
.ui-template [data-modules="faculty"] [class*="u-professor_mb-2"] {
  margin-bottom: 2em;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_mt"][class*="__nospMt"] {
    margin-top: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_mt"][class*="__nopcMt"] {
    margin-top: initial;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_mb"][class*="__nospMb"] {
    margin-bottom: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_mb"][class*="__nopcMb"] {
    margin-bottom: initial;
  }
}
.ui-template [data-modules="faculty"] [class*="u-professor_color-purple"] {
  color: #601986;
}
.ui-template [data-modules="faculty"] .u-professor_fs-md {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .u-professor_fs-md {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .u-professor_fs-md {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .u-professor_fs-md {
    font-size: 1.1vw;
  }
}
.ui-template [data-modules="faculty"] .u-professor_fs-mds {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .u-professor_fs-mds {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .u-professor_fs-mds {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .u-professor_fs-mds {
    font-size: 0.95vw;
  }
}
.ui-template [data-modules="faculty"] .u-professor_ff-roboto {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-10"] {
  width: 10%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-15"] {
  width: 15%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-20"] {
  width: 20%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-25"] {
  width: 25%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-30"] {
  width: 30%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-35"] {
  width: 35%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-40"] {
  width: 40%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-45"] {
  width: 45%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-50"] {
  width: 50%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-75"] {
  width: 75%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-95"] {
  width: 95%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_w-100"] {
  width: 100%;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_w-"][class*="__nospW"] {
    width: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_w-"][class*="__nopcW"] {
    width: initial;
  }
}
.ui-template [data-modules="faculty"] [class*="u-professor_h-100"] {
  height: 100%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_h-50"] {
  height: 50%;
}
.ui-template [data-modules="faculty"] [class*="u-professor_h-25"] {
  height: 25%;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_h-"][class*="__nospH"] {
    height: initial;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] [class*="u-professor_h-"][class*="__nopcH"] {
    height: initial;
  }
}
.ui-template [data-modules="faculty"] .u-professor_area-image-block {
  min-height: 120px;
}
@media (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .u-professor_area-image-block {
    min-height: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .u-professor_area-image-block {
    width: calc(100% - 26.67vw);
    min-height: 26.67vw;
    margin-left: 2.67vw;
  }
}
.ui-template [data-modules="faculty"] .area-image {
  vertical-align: top !important;
}
.ui-template [data-modules="faculty"] .area-image .area-image-block {
  width: 12rem !important;
  height: 12rem !important;
  position: relative;
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .area-image .area-image-block {
    width: 26.67vw !important;
    height: 26.67vw !important;
  }
}
.ui-template [data-modules="faculty"] .area-image .area-image-block img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.ui-template [data-modules="faculty"] .pagenation a:focus {
  outline: none;
}
.ui-template [data-modules="faculty"] .list-teachers li {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li {
    margin-bottom: 5.33vw;
  }
}
.ui-template [data-modules="faculty"] .list-teachers li a {
  width: 100%;
  color: #333;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li a {
    display: table;
  }
  .ui-template [data-modules="faculty"] .list-teachers li a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li a {
    display: flex;
    padding: 2.67vw;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-image,
  .ui-template [data-modules="faculty"] .list-teachers li .area-text,
  .ui-template [data-modules="faculty"] .list-teachers li .area-name,
  .ui-template [data-modules="faculty"] .list-teachers li .area-specialty {
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-text {
    width: 52vw;
    margin-left: 5.33vw;
  }
}
.ui-template [data-modules="faculty"] .list-teachers li .area-image {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-image {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-image {
    width: 26.67vw;
    height: 26.67vw;
  }
}
.ui-template [data-modules="faculty"] .list-teachers li .area-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-image {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name {
    padding-left: 40px;
    width: 320px;
  }
}
.ui-template [data-modules="faculty"] .list-teachers li .area-name span {
  display: block;
}
.ui-template [data-modules="faculty"] .list-teachers li .area-name .professor {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name .professor {
    margin-bottom: 8px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name .professor {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name .professor {
    margin-bottom: 0.59vw;
    font-size: 1.1vw;
  }
}
.ui-template [data-modules="faculty"] .list-teachers li .area-name .en {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name .en {
    margin-top: 8px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name .en {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name .en {
    margin-top: 0.59vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name {
    padding-left: 2.93vw;
    width: 23.43vw;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-name {
    margin-bottom: 3.47vw;
  }
}
.ui-template [data-modules="faculty"] .list-teachers li .area-specialty span:nth-child(n+2) {
  padding-left: 0.5em;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-specialty {
    padding-left: 40px;
    width: 460px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .list-teachers li .area-specialty {
    padding-left: 2.93vw;
    width: 33.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .list-teachers li {
    margin-bottom: 1.46vw;
  }
}
.ui-template [data-modules="faculty"] .block-teachers {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .block-teachers {
    padding: 40px 0;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .block-teachers {
    padding: 10.67vw 5.33vw;
  }
}
@media (min-width: 768px) {
  .ui-template [data-modules="faculty"] .block-teachers .click_area {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ui-template [data-modules="faculty"] .block-teachers .click_area {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [data-modules="faculty"] .block-teachers {
    padding: 2.93vw 0vw;
  }
}
.ui-template * {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .ui-template {
    padding-top: 60px;
    padding-bottom: 130px;
  }
}
@media (max-width: 767px) {
  .ui-template {
    padding-top: 9.33vw;
    padding-right: 5.33vw;
    padding-left: 5.33vw;
    padding-bottom: 34.67vw;
  }
}
.ui-template .margin-lv1 {
  margin-bottom: 20px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv1 {
    margin-bottom: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv1 {
    margin-bottom: 3.2vw;
  }
}
.ui-template .margin-lv1_1 {
  margin-bottom: 30px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv1_1 {
    margin-bottom: 2.2vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv1_1 {
    margin-bottom: 4.8vw;
  }
}
.ui-template .margin-lv2 {
  margin-bottom: 40px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv2 {
    margin-bottom: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv2 {
    margin-bottom: 6.4vw;
  }
}
.ui-template .margin-lv3 {
  margin-bottom: 60px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv3 {
    margin-bottom: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv3 {
    margin-bottom: 9.6vw;
  }
}
.ui-template .margin-lv3_1 {
  margin-bottom: 70px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv3_1 {
    margin-bottom: 5.12vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv3_1 {
    margin-bottom: 11.2vw;
  }
}
.ui-template .margin-lv4 {
  margin-bottom: 80px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv4 {
    margin-bottom: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv4 {
    margin-bottom: 12.8vw;
  }
}
.ui-template .margin-lv5 {
  margin-bottom: 100px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv5 {
    margin-bottom: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv5 {
    margin-bottom: 16vw;
  }
}
.ui-template .margin-lv6 {
  margin-bottom: 120px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv6 {
    margin-bottom: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv6 {
    margin-bottom: 19.2vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv1-pc {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv1-pc_1 {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv1-pc_1 {
    margin-bottom: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv1-pc {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv2-pc {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv2-pc {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv3-pc {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv3-pc_1 {
    margin-bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv3-pc_1 {
    margin-bottom: 5.12vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv3-pc {
    margin-bottom: 4.39vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv4-pc {
    margin-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv4-pc {
    margin-bottom: 5.86vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv5-pc {
    margin-bottom: 100px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv5-pc {
    margin-bottom: 7.32vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv6-pc {
    margin-bottom: 120px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv6-pc {
    margin-bottom: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-sp-lv1 {
    margin-bottom: 2.67vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-sp-lv2 {
    margin-bottom: 5.33vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-sp-lv3 {
    margin-bottom: 8vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-sp-lv4 {
    margin-bottom: 10.67vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-sp-lv5 {
    margin-bottom: 13.33vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-sp-lv6 {
    margin-bottom: 16vw;
  }
}
.ui-template .margin-lv1-right {
  margin-right: 20px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv1-right {
    margin-right: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv1-right {
    margin-right: 2.67vw;
  }
}
.ui-template .margin-lv2-right {
  margin-right: 40px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv2-right {
    margin-right: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv2-right {
    margin-right: 5.33vw;
  }
}
.ui-template .margin-lv3-right {
  margin-right: 60px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv3-right {
    margin-right: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv3-right {
    margin-right: 8vw;
  }
}
.ui-template .margin-lv4-right {
  margin-right: 80px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv4-right {
    margin-right: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv4-right {
    margin-right: 10.67vw;
  }
}
.ui-template .margin-lv5-right {
  margin-right: 100px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv5-right {
    margin-right: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv5-right {
    margin-right: 13.33vw;
  }
}
.ui-template .margin-lv6-right {
  margin-right: 120px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv6-right {
    margin-right: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv6-right {
    margin-right: 16vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv1-right-pc {
    margin-right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv1-right-pc {
    margin-right: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv2-right-pc {
    margin-right: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv2-right-pc {
    margin-right: 2.93vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv3-right-pc {
    margin-right: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv3-right-pc {
    margin-right: 4.39vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv4-right-pc {
    margin-right: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv4-right-pc {
    margin-right: 5.86vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv5-right-pc {
    margin-right: 100px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv5-right-pc {
    margin-right: 7.32vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv6-right-pc {
    margin-right: 120px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv6-right-pc {
    margin-right: 8.78vw;
  }
}
.ui-template .margin-lv1-left {
  margin-left: 20px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv1-left {
    margin-left: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv1-left {
    margin-left: 2.67vw;
  }
}
.ui-template .margin-lv2-left {
  margin-left: 40px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv2-left {
    margin-left: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv2-left {
    margin-left: 5.33vw;
  }
}
.ui-template .margin-lv3-left {
  margin-left: 60px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv3-left {
    margin-left: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv3-left {
    margin-left: 8vw;
  }
}
.ui-template .margin-lv4-left {
  margin-left: 80px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv4-left {
    margin-left: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv4-left {
    margin-left: 10.67vw;
  }
}
.ui-template .margin-lv5-left {
  margin-left: 100px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv5-left {
    margin-left: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv5-left {
    margin-left: 13.33vw;
  }
}
.ui-template .margin-lv6-left {
  margin-left: 120px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv6-left {
    margin-left: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv6-left {
    margin-left: 16vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv1-left-pc {
    margin-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv1-left-pc {
    margin-left: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv2-left-pc {
    margin-left: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv2-left-pc {
    margin-left: 2.93vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv3-left-pc {
    margin-left: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv3-left-pc {
    margin-left: 4.39vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv4-left-pc {
    margin-left: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv4-left-pc {
    margin-left: 5.86vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv5-left-pc {
    margin-left: 100px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv5-left-pc {
    margin-left: 7.32vw;
  }
}
@media (min-width: 768px) {
  .ui-template .margin-lv6-left-pc {
    margin-left: 120px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .margin-lv6-left-pc {
    margin-left: 8.78vw;
  }
}
.ui-template .margin-lv1-top {
  margin-top: 20px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv1-top {
    margin-top: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv1-top {
    margin-top: 2.67vw;
  }
}
.ui-template .margin-lv2-top {
  margin-top: 40px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv2-top {
    margin-top: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv2-top {
    margin-top: 5.33vw;
  }
}
.ui-template .margin-lv3-top {
  margin-top: 60px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv3-top {
    margin-top: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv3-top {
    margin-top: 8vw;
  }
}
.ui-template .margin-lv4-top {
  margin-top: 80px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv4-top {
    margin-top: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv4-top {
    margin-top: 10.67vw;
  }
}
.ui-template .margin-lv5-top {
  margin-top: 100px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv5-top {
    margin-top: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv5-top {
    margin-top: 13.33vw;
  }
}
.ui-template .margin-lv6-top {
  margin-top: 120px;
}
@media (max-width: 1365px) {
  .ui-template .margin-lv6-top {
    margin-top: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .margin-lv6-top {
    margin-top: 16vw;
  }
}
.ui-template .padding-lv1 {
  padding-bottom: 20px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv1 {
    padding-bottom: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv1 {
    padding-bottom: 3.2vw;
  }
}
.ui-template .padding-lv1_1 {
  padding-bottom: 30px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv1_1 {
    padding-bottom: 2.2vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv1_1 {
    padding-bottom: 4.8vw;
  }
}
.ui-template .padding-lv2 {
  padding-bottom: 40px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv2 {
    padding-bottom: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv2 {
    padding-bottom: 6.4vw;
  }
}
.ui-template .padding-lv3 {
  padding-bottom: 60px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv3 {
    padding-bottom: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv3 {
    padding-bottom: 9.6vw;
  }
}
.ui-template .padding-lv3_1 {
  padding-bottom: 70px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv3_1 {
    padding-bottom: 5.12vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv3_1 {
    padding-bottom: 11.2vw;
  }
}
.ui-template .padding-lv4 {
  padding-bottom: 80px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv4 {
    padding-bottom: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv4 {
    padding-bottom: 12.8vw;
  }
}
.ui-template .padding-lv5 {
  padding-bottom: 100px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv5 {
    padding-bottom: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv5 {
    padding-bottom: 16vw;
  }
}
.ui-template .padding-lv6 {
  padding-bottom: 120px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv6 {
    padding-bottom: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv6 {
    padding-bottom: 19.2vw;
  }
}
.ui-template .padding-lv1-right {
  padding-right: 20px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv1-right {
    padding-right: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv1-right {
    padding-right: 2.67vw;
  }
}
.ui-template .padding-lv2-right {
  padding-right: 40px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv2-right {
    padding-right: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv2-right {
    padding-right: 5.33vw;
  }
}
.ui-template .padding-lv3-right {
  padding-right: 60px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv3-right {
    padding-right: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv3-right {
    padding-right: 8vw;
  }
}
.ui-template .padding-lv4-right {
  padding-right: 80px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv4-right {
    padding-right: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv4-right {
    padding-right: 10.67vw;
  }
}
.ui-template .padding-lv5-right {
  padding-right: 100px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv5-right {
    padding-right: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv5-right {
    padding-right: 13.33vw;
  }
}
.ui-template .padding-lv6-right {
  padding-right: 120px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv6-right {
    padding-right: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv6-right {
    padding-right: 16vw;
  }
}
.ui-template .padding-lv1-top {
  padding-top: 20px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv1-top {
    padding-top: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv1-top {
    padding-top: 2.67vw;
  }
}
.ui-template .padding-lv2-top {
  padding-top: 40px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv2-top {
    padding-top: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv2-top {
    padding-top: 5.33vw;
  }
}
.ui-template .padding-lv3-top {
  padding-top: 60px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv3-top {
    padding-top: 4.39vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv3-top {
    padding-top: 8vw;
  }
}
.ui-template .padding-lv4-top {
  padding-top: 80px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv4-top {
    padding-top: 5.86vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv4-top {
    padding-top: 10.67vw;
  }
}
.ui-template .padding-lv5-top {
  padding-top: 100px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv5-top {
    padding-top: 7.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv5-top {
    padding-top: 13.33vw;
  }
}
.ui-template .padding-lv6-top {
  padding-top: 120px;
}
@media (max-width: 1365px) {
  .ui-template .padding-lv6-top {
    padding-top: 8.78vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-lv6-top {
    padding-top: 16vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv0 {
    padding-bottom: 0vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv1 {
    padding-bottom: 2.67vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv2 {
    padding-bottom: 5.33vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv3 {
    padding-bottom: 8vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv4 {
    padding-bottom: 10.67vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv5 {
    padding-bottom: 13.33vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv6 {
    padding-bottom: 16vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv1-top {
    padding-top: 2.67vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv2-top {
    padding-top: 5.33vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv3-top {
    padding-top: 8vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv4-top {
    padding-top: 10.67vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv5-top {
    padding-top: 13.33vw;
  }
}
@media (max-width: 767px) {
  .ui-template .padding-sp-lv6-top {
    padding-top: 16vw;
  }
}
.ui-template .container {
  margin-right: auto;
  margin-left: auto;
}
.ui-template .container.-limit {
  width: 100%;
}
@media (min-width: 768px) {
  .ui-template .container.-limit {
    max-width: 1186px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-limit {
    max-width: 86.82vw;
  }
}
.ui-template .container.-wide {
  width: 100%;
}
@media (min-width: 768px) {
  .ui-template .container.-wide {
    max-width: 1080px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-wide {
    max-width: 79.06vw;
  }
}
.ui-template .container.-mid {
  width: 100%;
}
@media (min-width: 768px) {
  .ui-template .container.-mid {
    max-width: 940px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-mid {
    max-width: 68.81vw;
  }
}
.ui-template .container.-sml {
  width: 100%;
}
@media (min-width: 768px) {
  .ui-template .container.-sml {
    max-width: 860px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-sml {
    max-width: 62.96vw;
  }
}
.ui-template .container.-col2,
.ui-template .container.-col3,
.ui-template .container.-col4,
.ui-template .container.-col6 {
  display: flex;
}
@media (min-width: 768px) {
  .ui-template .container.-col2 .box:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .container.-col2 .box:not(:last-of-type) {
    margin-right: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-col2 .box:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
.ui-template .container.-col2 .text {
  width: 70%;
}
.ui-template .container.-col2 .img {
  width: 30%;
}
@media (min-width: 768px) {
  .ui-template .container.-col3.-limit .box:not(:last-of-type) {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .ui-template .container.-col3.-limit .box:not(:last-of-type) {
    margin-right: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-col3.-limit .box:not(:last-of-type) {
    margin-right: 2.2vw;
  }
}
@media (min-width: 768px) {
  .ui-template .container.-col3.-wide .box:not(:last-of-type) {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .container.-col3.-wide .box:not(:last-of-type) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-col3.-wide .box:not(:last-of-type) {
    margin-right: 1.46vw;
  }
}
.ui-template .container.-col3 .box {
  width: calc(100% / 3);
}
@media (min-width: 768px) {
  .ui-template .container.-col4.-wide .box:not(:last-of-type) {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .container.-col4.-wide .box:not(:last-of-type) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .container.-col4.-wide .box:not(:last-of-type) {
    margin-right: 1.46vw;
  }
}
.ui-template .container.-col4 .box {
  width: calc(100% / 4);
}
.ui-template .container.-col6.-wide .box:not(:last-of-type) {
  margin-right: 20px;
}
.ui-template .container.-col6 .box {
  width: calc(100% / 6);
}
.ui-template .page-title {
  color: #5f1985;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .page-title {
    margin-bottom: 80px;
    padding-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .ui-template .page-title {
    margin-bottom: 11.33vw;
    padding-bottom: 5.33vw;
  }
}
.ui-template .page-title:after {
  content: "";
  display: block;
  background: #5f1985;
  bottom: 0;
}
@media (min-width: 768px) {
  .ui-template .page-title:after {
    width: 50px;
    height: 1px;
  }
}
@media (max-width: 767px) {
  .ui-template .page-title:after {
    width: 8vw;
    height: 2px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .page-title {
    margin-bottom: 5.86vw;
    padding-bottom: 1.83vw;
  }
}
.ui-template [class^="heading"]:not(.bold) {
  font-weight: normal;
}
.ui-template [class^="heading"].withBorder {
  position: relative;
}
@media (min-width: 768px) {
  .ui-template [class^="heading"].withBorder {
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template [class^="heading"].withBorder {
    margin-bottom: 5.33vw;
    padding-bottom: 2.67vw;
  }
}
.ui-template [class^="heading"].withBorder:before {
  content: "";
  display: block;
  height: 2px;
  background: #5f1985;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .ui-template [class^="heading"].withBorder:before {
    width: 200px;
  }
}
@media (max-width: 767px) {
  .ui-template [class^="heading"].withBorder:before {
    width: 28.67vw;
    height: 0.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [class^="heading"].withBorder:before {
    width: 14.64vw;
  }
}
.ui-template [class^="heading"].withBorder:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #ccc;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .ui-template [class^="heading"].withBorder:after {
    height: 0.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [class^="heading"].withBorder {
    margin-bottom: 2.93vw;
    padding-bottom: 1.46vw;
  }
}
.ui-template [class^="heading"].withBlock {
  position: relative;
}
@media (min-width: 768px) {
  .ui-template [class^="heading"].withBlock {
    padding-left: 16px;
  }
}
@media (max-width: 767px) {
  .ui-template [class^="heading"].withBlock {
    padding-left: 3.33vw;
  }
}
.ui-template [class^="heading"].withBlock:before {
  content: "";
  display: block;
  background: #5f1985;
  left: 0;
}
@media (min-width: 768px) {
  .ui-template [class^="heading"].withBlock:before {
    width: 4px;
    height: 22px;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .ui-template [class^="heading"].withBlock:before {
    width: 0.8vw;
    height: 4.8vw;
    margin-top: 0.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [class^="heading"].withBlock:before {
    width: 0.29vw;
    height: 1.61vw;
    margin-top: 0.15vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template [class^="heading"].withBlock {
    padding-left: 1.17vw;
  }
}
.ui-template .heading1 {
  line-height: 1.3;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .ui-template .heading1 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading1 {
    font-size: 6.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading1 {
    font-size: 2.93vw;
  }
}
.ui-template .heading2 {
  line-height: 1.4;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .ui-template .heading2 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading2 {
    font-size: 5.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading2 {
    font-size: 2.2vw;
  }
}
.ui-template .heading3 {
  line-height: 1.35;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .ui-template .heading3 {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading3 {
    font-size: 5.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading3 {
    font-size: 1.76vw;
  }
}
.ui-template .heading4 {
  line-height: 1.35;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .ui-template .heading4 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading4 {
    font-size: 4.27vw;
  }
}
.ui-template .heading4.withIcon_PC:before {
  content: "";
  display: inline-block;
  background: url("../img/common/icon_pc.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -0.1em;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .ui-template .heading4.withIcon_PC:before {
    width: 40px;
    height: 29px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading4.withIcon_PC:before {
    width: 7.47vw;
    height: 5.47vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading4.withIcon_PC:before {
    width: 2.93vw;
    height: 2.12vw;
    margin-right: pvw(10px);
  }
}
.ui-template .heading4.withIcon_phone:before {
  content: "";
  display: inline-block;
  background: url("../img/common/icon_phone.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -0.1em;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .ui-template .heading4.withIcon_phone:before {
    width: 26px;
    height: 30px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading4.withIcon_phone:before {
    width: 4.67vw;
    height: 5.47vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading4.withIcon_phone:before {
    width: 1.9vw;
    height: 2.2vw;
    margin-right: pvw(10px);
  }
}
.ui-template .heading4.withIcon_hat:before {
  content: "";
  display: inline-block;
  background: url("../img/common/icon_hat.png") no-repeat;
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .ui-template .heading4.withIcon_hat:before {
    width: 44px;
    height: 31px;
    margin-top: -0.1em;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading4.withIcon_hat:before {
    width: 7.73vw;
    height: 5.47vw;
    margin-top: -0.15em;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading4.withIcon_hat:before {
    width: 3.22vw;
    height: 2.27vw;
    margin-right: pvw(10px);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading4 {
    font-size: 1.46vw;
  }
}
.ui-template .heading5 {
  line-height: 1.37;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  .ui-template .heading5 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .ui-template .heading5 {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .heading5 {
    font-size: 1.17vw;
  }
}
.ui-template .base {
  font-size: 15px;
}
@media (max-width: 1365px) {
  .ui-template .base {
    font-size: 1.1vw;
  }
}
@media (max-width: 767px) {
  .ui-template .base {
    font-size: 3.73vw;
  }
}
.ui-template .base.-center {
  text-align: center;
}
.ui-template .base.-right {
  text-align: right;
}
.ui-template .lead {
  font-size: 18px;
}
@media (max-width: 1365px) {
  .ui-template .lead {
    font-size: 1.32vw;
  }
}
@media (max-width: 767px) {
  .ui-template .lead {
    font-size: 4.27vw;
  }
}
.ui-template .lead.-center {
  text-align: center;
}
.ui-template .lead.-right {
  text-align: right;
}
.ui-template .small {
  font-size: 14px;
}
@media (max-width: 1365px) {
  .ui-template .small {
    font-size: 1.02vw;
  }
}
@media (max-width: 767px) {
  .ui-template .small {
    font-size: 3.2vw;
  }
}
.ui-template .small.-center {
  text-align: center;
}
.ui-template .small.-right {
  text-align: right;
}
.ui-template .paragraph.base {
  line-height: 2;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .ui-template .paragraph.base {
    line-height: 1.85;
  }
}
.ui-template .paragraph.lead {
  line-height: 2;
  letter-spacing: 0.05em;
}
@media (max-width: 767px) {
  .ui-template .paragraph.lead {
    line-height: 1.87;
  }
}
.ui-template .paragraph.small {
  line-height: 1.7;
}
@media (max-width: 767px) {
  .ui-template .paragraph.small {
    line-height: 2;
  }
}
.ui-template a {
  color: #601986;
  transition: all 0.2s;
}
.ui-template a[class^="link-"] {
  font-weight: bold;
}
@media (min-width: 768px) {
  .ui-template a[class^="link-"]:hover {
    opacity: 0.6;
  }
}
.ui-template a.link-intext {
  border-bottom: 1px solid #601986;
}
@media (min-width: 768px) {
  .ui-template a.link-intext {
    padding-bottom: 2px;
  }
  .ui-template a.link-intext:hover {
    border-bottom: 1px solid transparent;
  }
}
@media (max-width: 767px) {
  .ui-template a.link-intext {
    border-bottom: 2px solid #601986;
    padding-bottom: 0.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template a.link-intext {
    padding-bottom: 0.15vw;
  }
}
@media (max-width: 767px) {
  .ui-template a.link-text {
    line-height: 1.4;
  }
}
.ui-template a.link-text:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .ui-template a.link-text:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
.ui-template a.link-text[target="_blank"]:after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 14px;
  background: url("../img/common/icon_tab.svg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
}
@media (max-width: 767px) {
  .ui-template a.link-text[target="_blank"]:after {
    width: 4vw;
    height: 3.87vw;
    margin-left: 1.33vw;
  }
}
.ui-template a.link-pdf span {
  display: inline-block;
  vertical-align: middle;
}
.ui-template a.link-pdf.indent-adjust {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
}
.ui-template a.link-pdf:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 8px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template a.link-pdf:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
.ui-template a.link-pdf:after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 18px;
  background: url("../img/common/icon_pdf.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template a.link-pdf:after {
    width: 5.47vw;
    height: 5.33vw;
    margin-left: 1.33vw;
  }
}
.ui-template a.link-word span {
  display: inline-block;
  vertical-align: middle;
}
.ui-template a.link-word.indent-adjust {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
}
.ui-template a.link-word:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 8px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template a.link-word:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
.ui-template a.link-word:after {
  content: "";
  display: inline-block;
  width: 22px;
  height: 18px;
  background: url("../img/common/icon_word.svg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template a.link-word:after {
    width: 6.53vw;
    height: 5.33vw;
    margin-left: 1.33vw;
  }
}
.ui-template a.link-excel span {
  display: inline-block;
  vertical-align: middle;
}
.ui-template a.link-excel.indent-adjust {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
}
.ui-template a.link-excel:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 8px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template a.link-excel:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
.ui-template a.link-excel:after {
  content: "";
  display: inline-block;
  width: 22px;
  height: 18px;
  background: url("../img/common/icon_excel.svg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template a.link-excel:after {
    width: 6.53vw;
    height: 5.33vw;
    margin-left: 1.33vw;
  }
}
.ui-template .anchorlinkL-container {
  display: flex;
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container._col6 .anchorlink {
    width: calc(100% / 6);
  }
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container._col5 .anchorlink {
    width: calc(100% / 5);
  }
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container._col4 .anchorlink {
    width: calc(100% / 4);
  }
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container._col3 .anchorlink {
    width: calc(100% / 3);
  }
}
.ui-template .anchorlinkL-container .anchorlink {
  color: #601986;
  line-height: 1.2;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container .anchorlink {
    height: 60px;
    font-size: 15px;
  }
  .ui-template .anchorlinkL-container .anchorlink:before {
    transition: background-color 0.3s;
  }
  .ui-template .anchorlinkL-container .anchorlink:after {
    transition: background 0.3s, transform 0.3s;
  }
  .ui-template .anchorlinkL-container .anchorlink:hover:before {
    background-color: #5f1985;
  }
  .ui-template .anchorlinkL-container .anchorlink:hover:after {
    background: url("../img/common/textlink_arrow_downward.png") no-repeat;
    background-position: center;
    background-size: contain;
    transform: translate(-50%, 30%);
  }
  .ui-template .anchorlinkL-container .anchorlink:hover span {
    color: #5f1985;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink {
    width: 46.6%;
    height: 13.33vw;
    font-size: 3.73vw;
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container .anchorlink:not(:last-of-type) {
    margin-right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .anchorlinkL-container .anchorlink:not(:last-of-type) {
    margin-right: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink:nth-of-type(odd) {
    margin-right: 5.33vw;
  }
}
.ui-template .anchorlinkL-container .anchorlink:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ccc;
  bottom: 0;
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink:before {
    height: 2px;
  }
}
.ui-template .anchorlinkL-container .anchorlink:after {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: url("../img/common/textlink_arrow_downward_grey.png") no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 10px;
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink:after {
    width: 3.2vw;
    height: 2.13vw;
    bottom: 2.67vw;
  }
}
.ui-template .anchorlinkL-container .anchorlink span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #333;
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container .anchorlink span {
    height: 35px;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink span {
    height: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .anchorlinkL-container .anchorlink span {
    height: 2.56vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .anchorlinkL-container .anchorlink {
    height: 4.39vw;
    min-height: 50px;
    font-size: 1.1vw;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink.line2-adjust {
    height: 15.33vw;
  }
}
.ui-template .anchorlinkS-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ui-template .anchorlinkS-container.noflex {
  display: block;
}
.ui-template .anchorlinkS-container .anchorlink {
  color: #333;
  line-height: 1.2;
  text-align: center;
}
@media (min-width: 768px) {
  .ui-template .anchorlinkS-container .anchorlink {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 4px;
  }
  .ui-template .anchorlinkS-container .anchorlink:after {
    content: "";
    display: inline-block;
    width: 12px;
    height: 8px;
    background: url("../img/common/textlink_arrow_downward_grey.png") no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 4px;
    vertical-align: middle;
  }
  .ui-template .anchorlinkS-container .anchorlink:hover {
    background: #5f1985;
    color: #fff;
  }
  .ui-template .anchorlinkS-container .anchorlink:hover:after {
    background: url("../img/common/textlink_arrow_downward_white.png") no-repeat;
    background-position: center;
    background-size: contain;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkS-container .anchorlink {
    font-size: 3.2vw;
    margin-bottom: 8vw;
  }
  .ui-template .anchorlinkS-container .anchorlink:after {
    content: "";
    display: inline-block;
    width: 3.2vw;
    height: 2.13vw;
    background: url("../img/common/textlink_arrow_downward_grey.png") no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 0.93vw;
    vertical-align: middle;
  }
}
.ui-template .anchorlinkS-container .anchorlink.-left {
  text-align: left;
}
@media (min-width: 768px) {
  .ui-template .anchorlinkS-container .anchorlink:not(:last-of-type) {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .anchorlinkS-container .anchorlink:not(:last-of-type) {
    margin-right: 0.73vw;
    margin-left: 0.73vw;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkS-container .anchorlink.mgnR-sp {
    margin-right: 5.33vw;
  }
}
@media (min-width: 768px) {
  .ui-template .anchorlinkS-container .anchorlink.nospace:after {
    margin-left: -6px;
  }
}
@media (max-width: 1365px) {
  .ui-template .anchorlinkS-container .anchorlink.nospace:after {
    margin-left: -0.44vw;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkS-container .anchorlink.nospace:after {
    margin-left: -0.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .anchorlinkS-container .anchorlink {
    font-size: 1.1vw;
    margin-bottom: 0.73vw;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink-box {
    visibility: hidden;
  }
  .ui-template .buttonlink-box.ready {
    visibility: visible;
  }
}
.ui-template .buttonlink {
  display: inline-block;
  background: #5f1985;
  border: 1px solid #5f1985;
}
@media (min-width: 768px) {
  .ui-template .buttonlink .pcOnly {
    display: block;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .pcOnly {
    display: none;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink .spOnly {
    display: none;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .spOnly {
    display: block;
  }
}
.ui-template .buttonlink .bg-white {
  background: #fff;
}
.ui-template .buttonlink .bg-theme-thin {
  background: #f4eef8;
}
.ui-template .buttonlink .bg-grey {
  background: #f5f6f8;
}
.ui-template .buttonlink .bg-green {
  background: #9ec238;
}
.ui-template .buttonlink .bg-blue {
  background: #1a86b9;
}
.ui-template .buttonlink .color-theme {
  color: #5f1985;
}
.ui-template .buttonlink .bold {
  font-weight: bold;
}
.ui-template .buttonlink .abs_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ui-template .buttonlink .abs_centerX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ui-template .buttonlink .abs_centerY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.ui-template .buttonlink .flex {
  display: flex;
}
.ui-template .buttonlink .flex.-center {
  align-items: center;
  justify-content: center;
}
.ui-template .buttonlink .flex.-centerY {
  align-items: center;
}
.ui-template .buttonlink .flex.-centerX {
  justify-content: center;
}
.ui-template .buttonlink .flex.-wrap {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .ui-template .buttonlink .flex.-col4-to2 .box {
    width: calc(100% / 4 - 20px);
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .flex.-col4-to2 .box {
    width: calc(100% / 2 - 1.335vw);
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .flex.-col4-to2 .box:nth-child(odd) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink .flex.-col4-to2 .box {
    width: calc(100% / 4 - 1.46vw);
    margin-right: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink .flexPC {
    display: flex;
  }
  .ui-template .buttonlink .flexPC.-center {
    align-items: center;
    justify-content: center;
  }
  .ui-template .buttonlink .flexPC.-centerY {
    align-items: center;
  }
  .ui-template .buttonlink .flexPC.-centerX {
    justify-content: center;
  }
  .ui-template .buttonlink .flexPC.-between {
    justify-content: space-between;
  }
  .ui-template .buttonlink .flexPC.-col2 .box {
    width: 50%;
  }
  .ui-template .buttonlink .flexPC.-col2 .box[class*="per"]:first-child {
    margin-right: 60px;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per90 {
    width: 90%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per80 {
    width: 80%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per70 {
    width: 70%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per60 {
    width: 60%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per50 {
    width: 50%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per40 {
    width: 40%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per30 {
    width: 30%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per20 {
    width: 20%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"].per10 {
    width: 10%;
  }
  .ui-template .buttonlink .flexPC[class^="-col"]-center {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink .flexPC:first-child {
    margin-right: 4.39vw;
  }
}
.ui-template .buttonlink .indentAdjust {
  padding-left: 1em;
  text-indent: -1em;
}
.ui-template .buttonlink .img img {
  width: 100%;
}
.ui-template .buttonlink .nowrap {
  white-space: nowrap;
}
.ui-template .buttonlink .js-accordionTable {
  cursor: pointer;
}
.ui-template .buttonlink .js-toggleTable {
  display: none;
}
@media (min-width: 768px) {
  .ui-template .buttonlink {
    height: 45px;
    padding: 0 30px;
    transition: background 0.3s;
  }
  .ui-template .buttonlink:hover {
    background: #fff;
  }
  .ui-template .buttonlink:hover .text,
  .ui-template .buttonlink:hover .inner .text {
    color: #5f1985;
  }
  .ui-template .buttonlink:hover .text::before,
  .ui-template .buttonlink:hover .inner .text::before {
    background-color: #5f1985;
  }
  .ui-template .buttonlink:hover .text::after,
  .ui-template .buttonlink:hover .inner .text::after {
    border-color: transparent transparent transparent #5f1985;
    transform: translate3d(6px, 0, 0);
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink {
    width: 100%;
    height: auto;
    padding: 4vw 5.33vw;
  }
}
.ui-template .buttonlink .inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ui-template .buttonlink .inner .text {
  display: block;
  width: 100%;
  color: #fff;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.07em;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .buttonlink .inner .text {
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .inner .text {
    display: inline-block;
    width: 100%;
    border-bottom-width: 0.27vw;
    line-height: 1.3;
    padding-right: 10.67vw;
    text-align: center;
  }
}
.ui-template .buttonlink .inner .text::before {
  content: '';
  display: block;
  background-color: #fff;
  position: absolute;
  right: 0;
}
@media (min-width: 768px) {
  .ui-template .buttonlink .inner .text::before {
    width: 20px;
    height: 1px;
    bottom: 4px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .inner .text::before {
    width: 5.73vw;
    height: 2px;
    top: calc(50% + 1.56vw / 2);
    transform: translateY(-50%);
  }
}
.ui-template .buttonlink .inner .text::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #fff;
  position: absolute;
}
@media (min-width: 768px) {
  .ui-template .buttonlink .inner .text::after {
    border-width: 7px 0 0 7px;
    transition: transform 0.3s;
    bottom: 4px;
    right: -1px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .inner .text::after {
    border-width: 1.87vw 0vw 0vw 2.13vw;
    top: 50%;
    transform: translateY(-50%);
    right: -0.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink .inner .text::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink .inner .text span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink .inner .text span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink .inner .text span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink .inner .text {
    padding-right: 2.93vw;
  }
}
.ui-template .buttonlink.green {
  display: inline-block;
  background: #9ec238;
  border: 1px solid #9ec238;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .pcOnly {
    display: block;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .pcOnly {
    display: none;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .spOnly {
    display: none;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .spOnly {
    display: block;
  }
}
.ui-template .buttonlink.green .bg-white {
  background: #fff;
}
.ui-template .buttonlink.green .bg-theme-thin {
  background: #f4eef8;
}
.ui-template .buttonlink.green .bg-grey {
  background: #f5f6f8;
}
.ui-template .buttonlink.green .bg-green {
  background: #9ec238;
}
.ui-template .buttonlink.green .bg-blue {
  background: #1a86b9;
}
.ui-template .buttonlink.green .color-theme {
  color: #5f1985;
}
.ui-template .buttonlink.green .bold {
  font-weight: bold;
}
.ui-template .buttonlink.green .abs_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ui-template .buttonlink.green .abs_centerX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ui-template .buttonlink.green .abs_centerY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.ui-template .buttonlink.green .flex {
  display: flex;
}
.ui-template .buttonlink.green .flex.-center {
  align-items: center;
  justify-content: center;
}
.ui-template .buttonlink.green .flex.-centerY {
  align-items: center;
}
.ui-template .buttonlink.green .flex.-centerX {
  justify-content: center;
}
.ui-template .buttonlink.green .flex.-wrap {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .flex.-col4-to2 .box {
    width: calc(100% / 4 - 20px);
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .flex.-col4-to2 .box {
    width: calc(100% / 2 - 1.335vw);
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .flex.-col4-to2 .box:nth-child(odd) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green .flex.-col4-to2 .box {
    width: calc(100% / 4 - 1.46vw);
    margin-right: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .flexPC {
    display: flex;
  }
  .ui-template .buttonlink.green .flexPC.-center {
    align-items: center;
    justify-content: center;
  }
  .ui-template .buttonlink.green .flexPC.-centerY {
    align-items: center;
  }
  .ui-template .buttonlink.green .flexPC.-centerX {
    justify-content: center;
  }
  .ui-template .buttonlink.green .flexPC.-between {
    justify-content: space-between;
  }
  .ui-template .buttonlink.green .flexPC.-col2 .box {
    width: 50%;
  }
  .ui-template .buttonlink.green .flexPC.-col2 .box[class*="per"]:first-child {
    margin-right: 60px;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per90 {
    width: 90%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per80 {
    width: 80%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per70 {
    width: 70%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per60 {
    width: 60%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per50 {
    width: 50%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per40 {
    width: 40%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per30 {
    width: 30%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per20 {
    width: 20%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"].per10 {
    width: 10%;
  }
  .ui-template .buttonlink.green .flexPC[class^="-col"]-center {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green .flexPC:first-child {
    margin-right: 4.39vw;
  }
}
.ui-template .buttonlink.green .indentAdjust {
  padding-left: 1em;
  text-indent: -1em;
}
.ui-template .buttonlink.green .img img {
  width: 100%;
}
.ui-template .buttonlink.green .nowrap {
  white-space: nowrap;
}
.ui-template .buttonlink.green .js-accordionTable {
  cursor: pointer;
}
.ui-template .buttonlink.green .js-toggleTable {
  display: none;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green {
    height: 45px;
    padding: 0 30px;
    transition: background 0.3s;
  }
  .ui-template .buttonlink.green:hover {
    background: #fff;
  }
  .ui-template .buttonlink.green:hover .text,
  .ui-template .buttonlink.green:hover .inner .text {
    color: #9ec238;
  }
  .ui-template .buttonlink.green:hover .text::before,
  .ui-template .buttonlink.green:hover .inner .text::before {
    background-color: #9ec238;
  }
  .ui-template .buttonlink.green:hover .text::after,
  .ui-template .buttonlink.green:hover .inner .text::after {
    border-color: transparent transparent transparent #9ec238;
    transform: translate3d(6px, 0, 0);
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green {
    width: 100%;
    height: auto;
    padding: 4vw 5.33vw;
  }
}
.ui-template .buttonlink.green .inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ui-template .buttonlink.green .inner .text {
  display: block;
  width: 100%;
  color: #fff;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.07em;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .inner .text {
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .inner .text {
    display: inline-block;
    width: 100%;
    border-bottom-width: 0.27vw;
    line-height: 1.3;
    padding-right: 10.67vw;
    text-align: center;
  }
}
.ui-template .buttonlink.green .inner .text::before {
  content: '';
  display: block;
  background-color: #fff;
  position: absolute;
  right: 0;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .inner .text::before {
    width: 20px;
    height: 1px;
    bottom: 4px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .inner .text::before {
    width: 5.73vw;
    height: 2px;
    top: calc(50% + 1.56vw / 2);
    transform: translateY(-50%);
  }
}
.ui-template .buttonlink.green .inner .text::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #fff;
  position: absolute;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .inner .text::after {
    border-width: 7px 0 0 7px;
    transition: transform 0.3s;
    bottom: 4px;
    right: -1px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .inner .text::after {
    border-width: 1.87vw 0vw 0vw 2.13vw;
    top: 50%;
    transform: translateY(-50%);
    right: -0.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green .inner .text::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.green .inner .text span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.green .inner .text span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green .inner .text span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green .inner .text {
    padding-right: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.green {
    height: 3.29vw;
    padding: 0 2.2vw;
  }
}
.ui-template .buttonlink.blue {
  display: inline-block;
  background: #1a86b9;
  border: 1px solid #1a86b9;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .pcOnly {
    display: block;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .pcOnly {
    display: none;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .spOnly {
    display: none;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .spOnly {
    display: block;
  }
}
.ui-template .buttonlink.blue .bg-white {
  background: #fff;
}
.ui-template .buttonlink.blue .bg-theme-thin {
  background: #f4eef8;
}
.ui-template .buttonlink.blue .bg-grey {
  background: #f5f6f8;
}
.ui-template .buttonlink.blue .bg-green {
  background: #9ec238;
}
.ui-template .buttonlink.blue .bg-blue {
  background: #1a86b9;
}
.ui-template .buttonlink.blue .color-theme {
  color: #5f1985;
}
.ui-template .buttonlink.blue .bold {
  font-weight: bold;
}
.ui-template .buttonlink.blue .abs_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ui-template .buttonlink.blue .abs_centerX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ui-template .buttonlink.blue .abs_centerY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.ui-template .buttonlink.blue .flex {
  display: flex;
}
.ui-template .buttonlink.blue .flex.-center {
  align-items: center;
  justify-content: center;
}
.ui-template .buttonlink.blue .flex.-centerY {
  align-items: center;
}
.ui-template .buttonlink.blue .flex.-centerX {
  justify-content: center;
}
.ui-template .buttonlink.blue .flex.-wrap {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .flex.-col4-to2 .box {
    width: calc(100% / 4 - 20px);
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .flex.-col4-to2 .box {
    width: calc(100% / 2 - 1.335vw);
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .flex.-col4-to2 .box:nth-child(odd) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue .flex.-col4-to2 .box {
    width: calc(100% / 4 - 1.46vw);
    margin-right: 1.46vw;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .flexPC {
    display: flex;
  }
  .ui-template .buttonlink.blue .flexPC.-center {
    align-items: center;
    justify-content: center;
  }
  .ui-template .buttonlink.blue .flexPC.-centerY {
    align-items: center;
  }
  .ui-template .buttonlink.blue .flexPC.-centerX {
    justify-content: center;
  }
  .ui-template .buttonlink.blue .flexPC.-between {
    justify-content: space-between;
  }
  .ui-template .buttonlink.blue .flexPC.-col2 .box {
    width: 50%;
  }
  .ui-template .buttonlink.blue .flexPC.-col2 .box[class*="per"]:first-child {
    margin-right: 60px;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per90 {
    width: 90%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per80 {
    width: 80%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per70 {
    width: 70%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per60 {
    width: 60%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per50 {
    width: 50%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per40 {
    width: 40%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per30 {
    width: 30%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per20 {
    width: 20%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"].per10 {
    width: 10%;
  }
  .ui-template .buttonlink.blue .flexPC[class^="-col"]-center {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue .flexPC:first-child {
    margin-right: 4.39vw;
  }
}
.ui-template .buttonlink.blue .indentAdjust {
  padding-left: 1em;
  text-indent: -1em;
}
.ui-template .buttonlink.blue .img img {
  width: 100%;
}
.ui-template .buttonlink.blue .nowrap {
  white-space: nowrap;
}
.ui-template .buttonlink.blue .js-accordionTable {
  cursor: pointer;
}
.ui-template .buttonlink.blue .js-toggleTable {
  display: none;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue {
    height: 45px;
    padding: 0 30px;
    transition: background 0.3s;
  }
  .ui-template .buttonlink.blue:hover {
    background: #fff;
  }
  .ui-template .buttonlink.blue:hover .text,
  .ui-template .buttonlink.blue:hover .inner .text {
    color: #1a86b9;
  }
  .ui-template .buttonlink.blue:hover .text::before,
  .ui-template .buttonlink.blue:hover .inner .text::before {
    background-color: #1a86b9;
  }
  .ui-template .buttonlink.blue:hover .text::after,
  .ui-template .buttonlink.blue:hover .inner .text::after {
    border-color: transparent transparent transparent #1a86b9;
    transform: translate3d(6px, 0, 0);
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue {
    width: 100%;
    height: auto;
    padding: 4vw 5.33vw;
  }
}
.ui-template .buttonlink.blue .inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ui-template .buttonlink.blue .inner .text {
  display: block;
  width: 100%;
  color: #fff;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.07em;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .inner .text {
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .inner .text {
    display: inline-block;
    width: 100%;
    border-bottom-width: 0.27vw;
    line-height: 1.3;
    padding-right: 10.67vw;
    text-align: center;
  }
}
.ui-template .buttonlink.blue .inner .text::before {
  content: '';
  display: block;
  background-color: #fff;
  position: absolute;
  right: 0;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .inner .text::before {
    width: 20px;
    height: 1px;
    bottom: 4px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .inner .text::before {
    width: 5.73vw;
    height: 2px;
    top: calc(50% + 1.56vw / 2);
    transform: translateY(-50%);
  }
}
.ui-template .buttonlink.blue .inner .text::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #fff;
  position: absolute;
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .inner .text::after {
    border-width: 7px 0 0 7px;
    transition: transform 0.3s;
    bottom: 4px;
    right: -1px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .inner .text::after {
    border-width: 1.87vw 0vw 0vw 2.13vw;
    top: 50%;
    transform: translateY(-50%);
    right: -0.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue .inner .text::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  .ui-template .buttonlink.blue .inner .text span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  .ui-template .buttonlink.blue .inner .text span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue .inner .text span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue .inner .text {
    padding-right: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink.blue {
    height: 3.29vw;
    padding: 0 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .buttonlink {
    height: 3.29vw;
    padding: 0 2.2vw;
  }
}
.ui-template .accordion-container .accordion {
  background: #f5f6f8;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion {
    padding-top: 5.33vw;
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 2.93vw;
  }
}
.ui-template .accordion-container .accordion .question p,
.ui-template .accordion-container .accordion .answer p {
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .question p,
  .ui-template .accordion-container .accordion .answer p {
    padding-left: 40px;
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .question p,
  .ui-template .accordion-container .accordion .answer p {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .question p,
  .ui-template .accordion-container .accordion .answer p {
    padding-left: 2.93vw;
    padding-right: 4.39vw;
  }
}
.ui-template .accordion-container .accordion .question {
  cursor: pointer;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .question {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .question {
    padding-bottom: 5.33vw;
  }
}
.ui-template .accordion-container .accordion .question:before,
.ui-template .accordion-container .accordion .question:after {
  content: "";
  background: #5f1985;
  right: 0;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .question:before,
  .ui-template .accordion-container .accordion .question:after {
    width: 20px;
    height: 3px;
    margin-top: -20px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .question:before,
  .ui-template .accordion-container .accordion .question:after {
    width: 2.67vw;
    height: 0.53vw;
    margin-top: -2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .question:before,
  .ui-template .accordion-container .accordion .question:after {
    margin-top: -1.46vw;
  }
}
.ui-template .accordion-container .accordion .question:after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
.ui-template .accordion-container .accordion .question.opend:after {
  transform: translateY(-50%) rotate(0);
}
.ui-template .accordion-container .accordion .question p {
  color: #5f1985;
  line-height: 1.6;
}
.ui-template .accordion-container .accordion .question p:before {
  content: "Q";
  display: inline-block;
  color: #5f1985;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .question p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .question p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .question p:before {
    font-size: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .question {
    padding-bottom: 2.93vw;
  }
}
.ui-template .accordion-container .accordion .answer {
  display: none;
  border-top: 1px solid #ccc;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .answer {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .answer {
    padding-bottom: 5.33vw;
  }
}
.ui-template .accordion-container .accordion .answer p {
  line-height: 1.9;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .answer p {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .answer p {
    margin-top: 5.33vw;
    padding-right: 0;
  }
}
.ui-template .accordion-container .accordion .answer p:before {
  content: "A";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  .ui-template .accordion-container .accordion .answer p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container .accordion .answer p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .answer p:before {
    font-size: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .answer p {
    margin-top: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion .answer {
    padding-bottom: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container .accordion {
    padding-top: 2.93vw;
    padding-right: 2.93vw;
    padding-left: 2.93vw;
  }
}
@media (min-width: 768px) {
  .ui-template .accordion-container-basic .accordion {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion {
    padding-top: 5.33vw;
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
.ui-template .accordion-container-basic .accordion .question p {
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .accordion-container-basic .accordion .question p {
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion .question p {
    padding-right: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container-basic .accordion .question p {
    padding-right: 4.39vw;
  }
}
.ui-template .accordion-container-basic .accordion .answer p {
  position: relative;
}
.ui-template .accordion-container-basic .accordion .question {
  cursor: pointer;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .accordion-container-basic .accordion .question {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion .question {
    padding-bottom: 5.33vw;
  }
}
.ui-template .accordion-container-basic .accordion .question:before,
.ui-template .accordion-container-basic .accordion .question:after {
  content: "";
  background: #5f1985;
  right: 0;
}
@media (min-width: 768px) {
  .ui-template .accordion-container-basic .accordion .question:before,
  .ui-template .accordion-container-basic .accordion .question:after {
    width: 20px;
    height: 3px;
    margin-top: -20px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion .question:before,
  .ui-template .accordion-container-basic .accordion .question:after {
    width: 2.67vw;
    height: 0.53vw;
    margin-top: -2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container-basic .accordion .question:before,
  .ui-template .accordion-container-basic .accordion .question:after {
    margin-top: -1.46vw;
  }
}
.ui-template .accordion-container-basic .accordion .question:after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
.ui-template .accordion-container-basic .accordion .question.opend:after {
  transform: translateY(-50%) rotate(0);
}
.ui-template .accordion-container-basic .accordion .question p {
  color: #5f1985;
  line-height: 1.6;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container-basic .accordion .question {
    padding-bottom: 2.93vw;
  }
}
.ui-template .accordion-container-basic .accordion .answer {
  display: none;
  border-top: 1px solid #ccc;
}
@media (min-width: 768px) {
  .ui-template .accordion-container-basic .accordion .answer {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion .answer {
    padding-bottom: 5.33vw;
  }
}
.ui-template .accordion-container-basic .accordion .answer p {
  line-height: 1.9;
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion .answer p {
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .ui-template .accordion-container-basic .accordion .answer p:first-of-type {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .accordion-container-basic .accordion .answer p:first-of-type {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container-basic .accordion .answer p:first-of-type {
    margin-top: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container-basic .accordion .answer {
    padding-bottom: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accordion-container-basic .accordion {
    padding-top: 2.93vw;
    padding-right: 2.93vw;
    padding-left: 2.93vw;
  }
}
.ui-template .tab-container {
  display: flex;
}
.ui-template .tab-container .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 2px solid #ccc;
}
.ui-template .tab-container .tab:not(.active) {
  cursor: pointer;
}
@media (min-width: 768px) {
  .ui-template .tab-container .tab {
    padding-bottom: 20px;
    transition: color 0.2s opacity 0.2s;
  }
  .ui-template .tab-container .tab:hover {
    border-bottom: 2px solid #5f1985;
    color: #5f1985;
    opacity: 0.8;
  }
}
@media (max-width: 767px) {
  .ui-template .tab-container .tab {
    padding-bottom: 2.67vw;
  }
}
.ui-template .tab-container .tab.active {
  color: #5f1985;
}
@media (min-width: 768px) {
  .ui-template .tab-container .tab.active {
    border-bottom: 2px solid #5f1985;
  }
  .ui-template .tab-container .tab.active:hover:not(.active) {
    border-bottom: 2px solid #5f1985;
    color: #5f1985;
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  .ui-template .tab-container .tab.active {
    border-bottom: 0.53vw solid #5f1985;
  }
}
.ui-template .tab-container .tab span {
  display: block;
}
@media (min-width: 768px) {
  .ui-template .tab-container .tab span {
    font-size: 16px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .tab-container .tab span {
    font-size: 3.73vw;
    padding-right: 2.67vw;
    padding-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .tab-container .tab span {
    font-size: 1.17vw;
    padding-right: 1.46vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .tab-container .tab {
    padding-bottom: 1.46vw;
  }
}
@media (max-width: 767px) {
  .ui-template .table-container:not(.col2) {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .ui-template .table-container:not(.col2) .table {
    max-width: 1280px;
    min-width: 170.67vw;
  }
  .ui-template .table-container:not(.col2) .table.expand {
    min-width: 1280px;
  }
  .ui-template .table-container.col2 table {
    width: 100%;
  }
}
.ui-template .table {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-collapse: collapse;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .table.size-maximum {
    width: 100%;
  }
}
.ui-template .table.border-additional-top {
  border-top: 1px solid #ccc;
}
.ui-template .table.border-additional-right {
  border-right: 1px solid #ccc;
}
.ui-template .table.border-additional-bottom {
  border-bottom: 1px solid #ccc;
}
.ui-template .table.border-additional-left {
  border-left: 1px solid #ccc;
}
.ui-template .table th,
.ui-template .table td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ui-template .table th,
  .ui-template .table td {
    max-width: 89.33vw;
  }
}
.ui-template .table td {
  text-align: left;
}
.ui-template .table tr {
  border: 1px solid #ccc;
}
.ui-template .table tr.border-thicker-bottom {
  border-bottom: 3px solid #ccc;
}
@media (min-width: 768px) {
  .ui-template .table tr:not(.subject-col) th,
  .ui-template .table tr:not(.subject-col) td {
    padding: 11px 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .table tr:not(.subject-col) th,
  .ui-template .table tr:not(.subject-col) td {
    padding: 4vw 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .table tr:not(.subject-col) th,
  .ui-template .table tr:not(.subject-col) td {
    padding: 0.81vw 1.46vw;
  }
}
.ui-template .table tr:not(.subject-col) th {
  border-right: 1px solid #ccc;
  letter-spacing: 0.07em;
  text-align: left;
}
.ui-template .table tr:not(.subject-col) th.-center {
  text-align: center;
}
.ui-template .table tr:not(.subject-col) td {
  border-right: 1px solid #ccc;
  text-align: left;
}
.ui-template .table tr:not(.subject-col) td.-center {
  text-align: center;
}
@media (max-width: 767px) {
  .ui-template .table tr:not(.subject-col) td.sp-minS {
    min-width: 150px;
  }
}
@media (max-width: 767px) {
  .ui-template .table tr:not(.subject-col) td.sp-minM {
    min-width: 200px;
  }
}
.ui-template .table .subject-col th,
.ui-template .table .subject-col td {
  letter-spacing: 0.07em;
}
@media (min-width: 768px) {
  .ui-template .table .subject-col th,
  .ui-template .table .subject-col td {
    padding: 20px 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .table .subject-col th,
  .ui-template .table .subject-col td {
    padding: 4vw 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .table .subject-col th,
  .ui-template .table .subject-col td {
    padding: 1.46vw 1.46vw;
  }
}
.ui-template .table .subject-col th {
  line-height: 1.2;
}
.ui-template .table .subject-col th:not(:last-child) {
  border-right: 1px solid #ccc;
}
.ui-template .tab-content-outer .tab-content:not(.active) {
  display: none;
}
.ui-template ol {
  margin-left: 1.5em;
}
.ui-template ol li::marker {
  margin: 0;
}
.ui-template ol li ol {
  margin-left: 1.5em;
}
.ui-template ol .small:not(.no-prefix) {
  text-indent: -1em;
  padding-left: 1em;
}
.ui-template ol .small:not(.no-prefix):before {
  content: "※";
}
.ui-template .list-num {
  list-style-type: decimal;
  margin-left: 2em;
}
.ui-template .list-num li {
  list-style: decimal;
}
.ui-template .list-upperRoman {
  list-style-type: upper-roman;
}
.ui-template .list-upperRoman li {
  list-style: upper-roman;
}
.ui-template .list-upperRoman ol.list-num li {
  list-style: decimal;
}
.ui-template .list-lowerRoman {
  list-style-type: lower-roman;
}
.ui-template .list-lowerRoman li {
  list-style: lower-roman;
}
.ui-template .list-annotation li {
  padding-left: 1em;
  text-indent: -1em;
}
.ui-template .list-annotation li:before {
  content: "※";
}
.ui-template .list-withDot li {
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .list-withDot li {
    padding-left: 17px;
  }
}
@media (max-width: 767px) {
  .ui-template .list-withDot li {
    padding-left: 3.6vw;
  }
}
.ui-template .list-withDot li:before {
  content: "";
  display: block;
  background-color: #9ec238;
  border-radius: 50%;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  .ui-template .list-withDot li:before {
    width: 6px;
    height: 6px;
    top: 0.8em;
  }
}
@media (max-width: 767px) {
  .ui-template .list-withDot li:before {
    width: 1.6vw;
    height: 1.6vw;
    background-color: #5f1985;
    top: 0.75em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .list-withDot li:before {
    width: 0.44vw;
    height: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .list-withDot li {
    padding-left: 1.24vw;
  }
}
.ui-template ol ul {
  list-style: none;
}
.ui-template ol ul li {
  list-style: none !important;
}
.ui-template .no-dot.no-padding li {
  padding-left: 0 !important;
}
.ui-template .no-dot li:before,
.ui-template .no-dot li:after {
  content: none;
}
@media (min-width: 768px) {
  .ui-template .process-conteiner {
    display: flex;
    flex-wrap: wrap;
  }
}
.ui-template .process-conteiner .process {
  background-color: #f5f6f8;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .ui-template .process-conteiner .process {
    width: 219.9px;
    height: 210px;
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .ui-template .process-conteiner .process {
    width: 100%;
    margin-right: 0;
    margin-bottom: 6.67vw;
    padding-top: 2.67vw;
    padding-bottom: 6.67vw;
  }
}
.ui-template .process-conteiner .process:not(:last-of-type):after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
@media (min-width: 768px) {
  .ui-template .process-conteiner .process:not(:last-of-type):after {
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #9ec238;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
  }
}
@media (max-width: 767px) {
  .ui-template .process-conteiner .process:not(:last-of-type):after {
    border-width: 5.33vw 5.33vw 0 5.33vw;
    border-color: #9ec238 transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .process-conteiner .process:not(:last-of-type):after {
    border-width: 1.46vw 0 1.46vw 1.46vw;
    right: -0.73vw;
  }
}
.ui-template .process-conteiner .process .index {
  line-height: 1.3;
  letter-spacing: 0.06em;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .ui-template .process-conteiner .process .index {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .process-conteiner .process .index {
    font-size: 11.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .process-conteiner .process .index {
    font-size: 2.93vw;
  }
}
.ui-template .process-conteiner .process .title {
  line-height: 1.3;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  .ui-template .process-conteiner .process .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .ui-template .process-conteiner .process .title {
    font-size: 5.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .process-conteiner .process .title {
    font-size: 1.76vw;
    margin-bottom: 0.73vw;
  }
}
.ui-template .process-conteiner .process .desc {
  line-height: 1.84;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  .ui-template .process-conteiner .process .desc {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .ui-template .process-conteiner .process .desc {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .process-conteiner .process .desc {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .process-conteiner .process {
    width: 16.1vw;
    height: 15.37vw;
    padding-top: 1.1vw;
  }
}
@media (min-width: 768px) {
  .ui-template .bannerArea {
    display: flex;
  }
}
@media (min-width: 768px) {
  .ui-template .bannerArea .banner {
    max-width: 380px;
    transition: opacity 0.2s;
  }
  .ui-template .bannerArea .banner:hover {
    opacity: 0.8;
  }
}
@media (max-width: 767px) {
  .ui-template .bannerArea .banner {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ui-template .bannerArea .banner:first-of-type {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .bannerArea .banner:first-of-type {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .bannerArea .banner:first-of-type {
    margin-right: 2.93vw;
  }
}
@media (min-width: 768px) {
  .ui-template .subpages-container {
    display: flex;
  }
}
@media (min-width: 768px) {
  .ui-template .subpages-container .subpage-button {
    width: calc(100% / 3);
    height: 60px;
  }
}
@media (min-width: 768px) {
  .ui-template .subpages-container .subpage-button:not(:last-of-type) {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .ui-template .subpages-container .subpage-button:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .subpages-container .subpage-button:not(:last-of-type) {
    margin-right: 1.46vw;
  }
}
.ui-template .subpages-container .subpage-button .buttonlink {
  width: 100%;
}
@media (min-width: 768px) {
  .ui-template .subpages-container .subpage-button .buttonlink {
    height: 60px;
  }
}
.ui-template .subpages-container .subpage-button .buttonlink.green {
  background-color: #9ec238;
  border-color: #9ec238;
}
@media (min-width: 768px) {
  .ui-template .subpages-container .subpage-button .buttonlink.green:hover {
    background-color: #fff;
  }
  .ui-template .subpages-container .subpage-button .buttonlink.green:hover span {
    color: #9ec238;
  }
}
.ui-template .subpages-container .subpage-button .buttonlink.green span {
  text-align: left;
}
.ui-template .subpages-container .subpage-button .buttonlink.blue {
  background-color: #1a86b9;
  border-color: #1a86b9;
}
@media (min-width: 768px) {
  .ui-template .subpages-container .subpage-button .buttonlink.blue:hover {
    background-color: #fff;
  }
  .ui-template .subpages-container .subpage-button .buttonlink.blue:hover span {
    color: #1a86b9;
  }
}
.ui-template .subpages-container .subpage-button .buttonlink.blue span {
  text-align: left;
}
.ui-template .subpages-container .subpage-button .buttonlink span {
  text-align: left;
}
@media (min-width: 768px) {
  .ui-template .subpages-container .subpage-button .buttonlink span {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .subpages-container .subpage-button .buttonlink span {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .subpages-container .subpage-button .buttonlink {
    height: 4.39vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .subpages-container .subpage-button {
    height: 4.39vw;
  }
}
.ui-template .anchorlinkL-container .anchorlink span {
  flex-direction: column;
}
.ui-template .anchorlinkL-container .anchorlink span .smaller {
  display: block;
}
@media (min-width: 768px) {
  .ui-template .anchorlinkL-container .anchorlink span .smaller {
    font-size: 13px;
    margin-top: 3px;
  }
}
@media (max-width: 767px) {
  .ui-template .anchorlinkL-container .anchorlink span .smaller {
    font-size: 3.2vw;
    margin-top: 0.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .anchorlinkL-container .anchorlink span .smaller {
    font-size: 0.95vw;
    margin-top: 0.22vw;
  }
}
@media (min-width: 768px) {
  .ui-template .nyushi-footer {
    display: flex;
  }
}
@media (min-width: 768px) {
  .ui-template .nyushi-footer .nyushi-footer-inner {
    width: 450px;
  }
}
@media (max-width: 767px) {
  .ui-template .nyushi-footer .nyushi-footer-inner {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ui-template .nyushi-footer .nyushi-footer-inner:first-of-type {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .nyushi-footer .nyushi-footer-inner:first-of-type {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .nyushi-footer .nyushi-footer-inner:first-of-type {
    margin-right: 2.93vw;
  }
}
@media (max-width: 767px) {
  .ui-template .nyushi-footer .nyushi-footer-inner .link-box {
    margin-bottom: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .nyushi-footer .nyushi-footer-inner {
    width: 32.94vw;
  }
}
.ui-template .inquiry {
  display: inline-block;
  padding: 20px;
}
@media (min-width: 768px) {
  .ui-template .inquiry.-expand {
    width: 42.5%;
  }
}
@media (max-width: 767px) {
  .ui-template .inquiry.-expand {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ui-template .inquiry .title {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .ui-template .inquiry .title {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .inquiry .title {
    margin-bottom: 0.73vw;
  }
}
@media (min-width: 768px) {
  .ui-template .accomodation-images:not(._case_single) {
    display: flex;
    margin-bottom: 50px;
  }
@media (min-width: 768px) and (min-width: 768px) and (max-width: 1365px) {
    .ui-template .accomodation-images._case_single .img {
      width: 32.94vw;
    }
}
}
@media (max-width: 767px) {
  .ui-template .accomodation-images .img {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .ui-template .accomodation-images .img:first-of-type {
    margin-right: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accomodation-images .img:first-of-type {
    margin-right: 2.93vw;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .ui-template .accomodation-images .img {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  .ui-template .accomodation-images .desc {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .ui-template .accomodation-images .desc {
    font-size: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accomodation-images .desc {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .accomodation-images:not(._case_single) {
    margin-bottom: 3.66vw;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .ui-template .accomodation-images .img {
    width: 450px;
  }
}
@media (min-width: 768px) {
  .ui-template .digital-panphlets_images {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .ui-template .digital-panphlets_images .img {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .ui-template .digital-panphlets_images .img {
    width: 100%;
    max-width: 61.33vw;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .ui-template .digital-panphlets_images .img:first-of-type {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .ui-template .digital-panphlets_images .img:first-of-type {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .digital-panphlets_images .img:first-of-type {
    margin-right: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template .digital-panphlets_images .img {
    width: 21.96vw;
  }
}
.ui-template .sample-layout-forContainers [class^="container"] {
  background-color: #bfa3ce;
  min-height: 120px;
}
.ui-template .sample-layout-forContainers .container.-col2,
.ui-template .sample-layout-forContainers .container.-col3,
.ui-template .sample-layout-forContainers .container.-col4,
.ui-template .sample-layout-forContainers .container.-col6 {
  background-color: #fff;
}
.ui-template .sample-layout-forContainers .container.-col2 .box,
.ui-template .sample-layout-forContainers .container.-col3 .box,
.ui-template .sample-layout-forContainers .container.-col4 .box,
.ui-template .sample-layout-forContainers .container.-col6 .box {
  background-color: #bfa3ce;
}
.ui-template .sample-layout-forContainers .container.-col2 .box.-half,
.ui-template .sample-layout-forContainers .container.-col3 .box.-half,
.ui-template .sample-layout-forContainers .container.-col4 .box.-half,
.ui-template .sample-layout-forContainers .container.-col6 .box.-half {
  width: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .ui-template {
    padding-top: 4.39vw;
    padding-bottom: 9.52vw;
  }
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .page-header {
    background-image: url("../img/nyushi/index/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .page-header {
    background-image: url("../img/nyushi/index/header_bg@2x.jpg");
  }
}
.page-heading:not(.longer) {
  text-align: center;
}
.page-heading.longer {
  line-height: 1.3;
  text-align: center;
}
@media (min-width: 768px) {
  .page-heading.longer {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .page-heading.longer {
    font-size: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .page-heading.longer {
    font-size: 2.2vw;
  }
}
.content-navigation-bottom {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  .content-navigation-bottom {
    padding: 54px 70px 57px;
  }
}
@media (max-width: 767px) {
  .content-navigation-bottom {
    padding: 9.6vw 5.33vw;
  }
}
.content-navigation-bottom .heading4 {
  color: $color;
}
@media (min-width: 768px) {
  .content-navigation-bottom .heading4 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .content-navigation-bottom .heading4 {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .content-navigation-bottom .heading4 {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  .content-navigation-bottom .list-page_index li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .content-navigation-bottom .list-page_index li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .content-navigation-bottom .list-page_index li:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .content-navigation-bottom .list-page_index li:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
.content-navigation-bottom .list-page_index li a {
  font-weight: normal;
  color: $color;
}
.content-navigation-bottom .list-page_index li a.current {
  border-bottom: 1px solid $color;
}
@media (min-width: 768px) and (max-width: 1365px) {
  .content-navigation-bottom {
    padding: 3.95vw 5.12vw 4.17vw;
  }
}
.add-back .link-container--back {
  text-align: center;
}
.add-back .link-container--back .text-link--back {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  .add-back .link-container--back .text-link--back {
    font-size: 1.6rem;
  }
  .add-back .link-container--back .text-link--back:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  .add-back .link-container--back .text-link--back {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
.add-back .link-container--back .text-link--back::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
.add-back .link-container--back .text-link--back::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #601986 transparent transparent;
}
@media (min-width: 768px) {
  .add-back .link-container--back .text-link--back::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  .add-back .link-container--back .text-link--back::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .add-back .link-container--back .text-link--back::after {
    border-width: $size 0vw 0vw $size;
  }
}
.add-back .link-container--back .text-link--back span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .add-back .link-container--back .text-link--back span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .add-back .link-container--back .text-link--back span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .add-back .link-container--back .text-link--back span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .add-back .link-container--back .text-link--back {
    font-size: 1.17vw;
  }
}
.add-next .link-container--next {
  text-align: center;
}
.add-next .link-container--next .text-link--next {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  .add-next .link-container--next .text-link--next {
    font-size: 1.3rem;
  }
  .add-next .link-container--next .text-link--next:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  .add-next .link-container--next .text-link--next {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
.add-next .link-container--next .text-link--next::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
.add-next .link-container--next .text-link--next::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  .add-next .link-container--next .text-link--next::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  .add-next .link-container--next .text-link--next::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .add-next .link-container--next .text-link--next::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  .add-next .link-container--next .text-link--next span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  .add-next .link-container--next .text-link--next span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .add-next .link-container--next .text-link--next span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .add-next .link-container--next .text-link--next {
    font-size: 0.95vw;
  }
}
[data-modules="faculty"] [class*="u-professor_d-flex"] {
  display: flex;
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_d-flex"][class*="__nosp"] {
    display: block;
  }
}
[data-modules="faculty"] [class*="u-professor_d-block"] {
  display: block;
}
[data-modules="faculty"] [class*="u-professor_d-inline-block"] {
  display: inline-block;
}
[data-modules="faculty"] [class*="u-professor_align-items-center"] {
  align-items: center;
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_align-items-center"][class*="__nosp"] {
    align-items: inherit;
  }
}
[data-modules="faculty"] [class*="u-professor_pl-0_5"] {
  padding-left: 0.5em;
}
[data-modules="faculty"] [class*="u-professor_pl-4"] {
  padding-left: 4em;
}
[data-modules="faculty"] [class*="u-professor_pr-4"] {
  padding-right: 4em;
}
[data-modules="faculty"] [class*="u-professor_pt-1_6"] {
  padding-top: 1.6em;
}
[data-modules="faculty"] [class*="u-professor_pb-1_6"] {
  padding-bottom: 1.6em;
}
[data-modules="faculty"] [class*="u-professor_pt-custom"] {
  padding-top: 5.33vw !important;
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_pl"][class*="__nospPl"] {
    padding-left: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_pl"][class*="__nopcPl"] {
    padding-left: initial;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_pr"][class*="__nospPr"] {
    padding-right: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_pr"][class*="__nopcPr"] {
    padding-right: initial;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_pt"][class*="__nospPt"] {
    padding-top: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_pt"][class*="__nopcPt"] {
    padding-top: initial;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_pb"][class*="__nospPb"] {
    padding-bottom: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_pb"][class*="__nopcPb"] {
    padding-bottom: initial;
  }
}
[data-modules="faculty"] [class*="u-professor_mt-08"] {
  margin-top: 0.8em;
}
[data-modules="faculty"] [class*="u-professor_mb-08"] {
  margin-bottom: 0.8em;
}
[data-modules="faculty"] [class*="u-professor_mb-2"] {
  margin-bottom: 2em;
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_mt"][class*="__nospMt"] {
    margin-top: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_mt"][class*="__nopcMt"] {
    margin-top: initial;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_mb"][class*="__nospMb"] {
    margin-bottom: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_mb"][class*="__nopcMb"] {
    margin-bottom: initial;
  }
}
[data-modules="faculty"] [class*="u-professor_color-purple"] {
  color: #601986;
}
[data-modules="faculty"] .u-professor_fs-md {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .u-professor_fs-md {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .u-professor_fs-md {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .u-professor_fs-md {
    font-size: 1.1vw;
  }
}
[data-modules="faculty"] .u-professor_fs-mds {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .u-professor_fs-mds {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .u-professor_fs-mds {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .u-professor_fs-mds {
    font-size: 0.95vw;
  }
}
[data-modules="faculty"] .u-professor_ff-roboto {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-modules="faculty"] [class*="u-professor_w-10"] {
  width: 10%;
}
[data-modules="faculty"] [class*="u-professor_w-15"] {
  width: 15%;
}
[data-modules="faculty"] [class*="u-professor_w-20"] {
  width: 20%;
}
[data-modules="faculty"] [class*="u-professor_w-25"] {
  width: 25%;
}
[data-modules="faculty"] [class*="u-professor_w-30"] {
  width: 30%;
}
[data-modules="faculty"] [class*="u-professor_w-35"] {
  width: 35%;
}
[data-modules="faculty"] [class*="u-professor_w-40"] {
  width: 40%;
}
[data-modules="faculty"] [class*="u-professor_w-45"] {
  width: 45%;
}
[data-modules="faculty"] [class*="u-professor_w-50"] {
  width: 50%;
}
[data-modules="faculty"] [class*="u-professor_w-75"] {
  width: 75%;
}
[data-modules="faculty"] [class*="u-professor_w-95"] {
  width: 95%;
}
[data-modules="faculty"] [class*="u-professor_w-100"] {
  width: 100%;
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_w-"][class*="__nospW"] {
    width: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_w-"][class*="__nopcW"] {
    width: initial;
  }
}
[data-modules="faculty"] [class*="u-professor_h-100"] {
  height: 100%;
}
[data-modules="faculty"] [class*="u-professor_h-50"] {
  height: 50%;
}
[data-modules="faculty"] [class*="u-professor_h-25"] {
  height: 25%;
}
@media (max-width: 767px) {
  [data-modules="faculty"] [class*="u-professor_h-"][class*="__nospH"] {
    height: initial;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] [class*="u-professor_h-"][class*="__nopcH"] {
    height: initial;
  }
}
[data-modules="faculty"] .u-professor_area-image-block {
  min-height: 120px;
}
@media (max-width: 1365px) {
  [data-modules="faculty"] .u-professor_area-image-block {
    min-height: 8.78vw;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .u-professor_area-image-block {
    width: calc(100% - 26.67vw);
    min-height: 26.67vw;
    margin-left: 2.67vw;
  }
}
[data-modules="faculty"] .area-image {
  vertical-align: top !important;
}
[data-modules="faculty"] .area-image .area-image-block {
  width: 12rem !important;
  height: 12rem !important;
  position: relative;
}
@media (max-width: 767px) {
  [data-modules="faculty"] .area-image .area-image-block {
    width: 26.67vw !important;
    height: 26.67vw !important;
  }
}
[data-modules="faculty"] .area-image .area-image-block img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
[data-modules="faculty"] .pagenation a:focus {
  outline: none;
}
[data-modules="faculty"] .list-teachers li {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li {
    margin-bottom: 5.33vw;
  }
}
[data-modules="faculty"] .list-teachers li a {
  width: 100%;
  color: #333;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li a {
    display: table;
  }
  [data-modules="faculty"] .list-teachers li a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li a {
    display: flex;
    padding: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li .area-image,
  [data-modules="faculty"] .list-teachers li .area-text,
  [data-modules="faculty"] .list-teachers li .area-name,
  [data-modules="faculty"] .list-teachers li .area-specialty {
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li .area-text {
    width: 52vw;
    margin-left: 5.33vw;
  }
}
[data-modules="faculty"] .list-teachers li .area-image {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li .area-image {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li .area-image {
    width: 26.67vw;
    height: 26.67vw;
  }
}
[data-modules="faculty"] .list-teachers li .area-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .list-teachers li .area-image {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li .area-name {
    padding-left: 40px;
    width: 320px;
  }
}
[data-modules="faculty"] .list-teachers li .area-name span {
  display: block;
}
[data-modules="faculty"] .list-teachers li .area-name .professor {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li .area-name .professor {
    margin-bottom: 8px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li .area-name .professor {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .list-teachers li .area-name .professor {
    margin-bottom: 0.59vw;
    font-size: 1.1vw;
  }
}
[data-modules="faculty"] .list-teachers li .area-name .en {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li .area-name .en {
    margin-top: 8px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li .area-name .en {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .list-teachers li .area-name .en {
    margin-top: 0.59vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .list-teachers li .area-name {
    padding-left: 2.93vw;
    width: 23.43vw;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  [data-modules="faculty"] .list-teachers li .area-name {
    margin-bottom: 3.47vw;
  }
}
[data-modules="faculty"] .list-teachers li .area-specialty span:nth-child(n+2) {
  padding-left: 0.5em;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .list-teachers li .area-specialty {
    padding-left: 40px;
    width: 460px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .list-teachers li .area-specialty {
    padding-left: 2.93vw;
    width: 33.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .list-teachers li {
    margin-bottom: 1.46vw;
  }
}
[data-modules="faculty"] .block-teachers {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-modules="faculty"] .block-teachers {
    padding: 40px 0;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .block-teachers {
    padding: 10.67vw 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-modules="faculty"] .block-teachers .click_area {
    width: 100%;
  }
}
@media (max-width: 767px) {
  [data-modules="faculty"] .block-teachers .click_area {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-modules="faculty"] .block-teachers {
    padding: 2.93vw 0vw;
  }
}
@media (min-width: 768px) {
  .pcOnly {
    display: block;
  }
}
@media (max-width: 767px) {
  .pcOnly {
    display: none;
  }
}
@media (min-width: 768px) {
  .spOnly {
    display: none;
  }
}
@media (max-width: 767px) {
  .spOnly {
    display: block;
  }
}
.bg-white {
  background: #fff;
}
.bg-theme-thin {
  background: #f4eef8;
}
.bg-grey {
  background: #f5f6f8;
}
.bg-green {
  background: #9ec238;
}
.bg-blue {
  background: #1a86b9;
}
.color-theme {
  color: #5f1985;
}
.bold {
  font-weight: bold;
}
.abs_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.abs_centerX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.abs_centerY,
[data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
[data-category="faculty"] .ui-template .accordion-container .accordion .question:after,
[data-category="about"] .ui-template .accordion-container .accordion .question:before,
[data-category="about"] .ui-template .accordion-container .accordion .question:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.flex {
  display: flex;
}
.flex.-center {
  align-items: center;
  justify-content: center;
}
.flex.-centerY {
  align-items: center;
}
.flex.-centerX {
  justify-content: center;
}
.flex.-wrap {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .flex.-col4-to2 .box {
    width: calc(100% / 4 - 20px);
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .flex.-col4-to2 .box {
    width: calc(100% / 2 - 1.335vw);
  }
}
@media (min-width: 768px) {
  .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .flex.-col4-to2 .box:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .flex.-col4-to2 .box:nth-child(odd) {
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .flex.-col4-to2 .box {
    width: calc(100% / 4 - 1.46vw);
    margin-right: 1.46vw;
  }
}
@media (min-width: 768px) {
  .flexPC {
    display: flex;
  }
  .flexPC.-center {
    align-items: center;
    justify-content: center;
  }
  .flexPC.-centerY {
    align-items: center;
  }
  .flexPC.-centerX {
    justify-content: center;
  }
  .flexPC.-between {
    justify-content: space-between;
  }
  .flexPC.-col2 .box {
    width: 50%;
  }
  .flexPC.-col2 .box[class*="per"]:first-child {
    margin-right: 60px;
  }
  .flexPC[class^="-col"].per90 {
    width: 90%;
  }
  .flexPC[class^="-col"].per80 {
    width: 80%;
  }
  .flexPC[class^="-col"].per70 {
    width: 70%;
  }
  .flexPC[class^="-col"].per60 {
    width: 60%;
  }
  .flexPC[class^="-col"].per50 {
    width: 50%;
  }
  .flexPC[class^="-col"].per40 {
    width: 40%;
  }
  .flexPC[class^="-col"].per30 {
    width: 30%;
  }
  .flexPC[class^="-col"].per20 {
    width: 20%;
  }
  .flexPC[class^="-col"].per10 {
    width: 10%;
  }
  .flexPC[class^="-col"]-center {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .flexPC:first-child {
    margin-right: 4.39vw;
  }
}
.indentAdjust {
  padding-left: 1em;
  text-indent: -1em;
}
.img img {
  width: 100%;
}
.nowrap {
  white-space: nowrap;
}
.js-accordionTable {
  cursor: pointer;
}
.js-toggleTable {
  display: none;
}
.modal-overlay {
  z-index: 9990;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0,0,0,0.6);
}
.modal-content {
  width: 80%;
  max-width: 960px;
  margin: 0;
  padding: 0;
  position: fixed;
  display: none;
  z-index: 9999;
}
.modal-inner {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}
#ytPlayer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.modal-close-btn {
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../img/common/close.svg");
  border: 0;
  padding: 0;
  position: absolute;
  cursor: pointer;
  color: #fff;
}
@media (min-width: 768px) {
  .modal-close-btn {
    width: 50px;
    height: 50px;
    top: -70px;
    right: -70px;
  }
}
@media (max-width: 767px) {
  .modal-close-btn {
    width: 4vw;
    height: 4vw;
    top: -5.33vw;
    right: -2.67vw;
  }
}
.modal-close-btn:focus {
  outline: 0;
}
@media (min-width: 768px) {
  .modal-close-btn img {
    width: 50px;
  }
}
@media (max-width: 767px) {
  .modal-close-btn img {
    width: 8vw;
  }
}
#ytPlayer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
html {
  font-size: 62.5%;
  font-family: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
}
body {
  position: relative;
}
.pages {
  position: relative;
}
.main {
  position: relative;
  z-index: 1;
}
@media (min-width: 1100px) {
  .main {
    margin-top: 40px;
    margin-left: 180px;
  }
}
@media (max-width: 1099px) {
  .main {
    margin-top: 16vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .main {
    margin-top: 2.93vw;
    margin-left: 13.18vw;
  }
}
@media (max-width: 1099px) and (min-width: 768px) {
  .main {
    margin-top: 80px;
  }
}
@media (min-width: 768px) {
  br.forPC,
  img.forPC {
    display: inline;
  }
}
@media (max-width: 767px) {
  br.forPC,
  img.forPC {
    display: none;
  }
}
@media (min-width: 768px) {
  br.forTAB,
  img.forTAB {
    display: none;
  }
}
@media (max-width: 1100px) {
  br.forTAB,
  img.forTAB {
    display: inline;
  }
}
@media (min-width: 768px) {
  br.forSP,
  img.forSP {
    display: none;
  }
}
@media (max-width: 767px) {
  br.forSP,
  img.forSP {
    display: inline;
  }
}
@media (min-width: 768px) {
  br.forSP_small,
  img.forSP_small {
    display: none;
  }
}
.halfIndent {
  display: inline-block;
  text-indent: -0.5em;
}
.indent {
  display: inline-block;
  text-indent: -1em;
}
.ojf-img {
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}
.footer {
  position: relative;
  z-index: 2;
  background-color: #f1f1f1;
  border-top-color: #fff;
  border-top-style: solid;
}
@media (min-width: 768px) {
  .footer {
    border-top-width: 5px;
  }
}
@media (max-width: 767px) {
  .footer {
    border-top-width: 1.07vw;
  }
}
@media (min-width: 1100px) {
  .footer {
    margin-left: 180px;
  }
}
@media (min-width: 768px) {
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 50px;
  }
}
@media (max-width: 767px) {
  .footer-container {
    padding: 10.67vw 5.33vw 9.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-container {
    padding: 2.93vw 3.66vw;
  }
}
@media (max-width: 767px) {
  .footer-inner + .footer-inner {
    margin-top: 9.33vw;
  }
}
@media (min-width: 768px) {
  .footer-logo {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footer-logo {
    margin-bottom: 4vw;
  }
}
@media (max-width: 767px) {
  .footer-logo img {
    width: 62.67vw;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-logo img {
    width: 23.06vw;
    height: 4.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-logo {
    margin-bottom: 2.2vw;
  }
}
.footer-siteInfo p {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #333;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .footer-siteInfo p {
    font-size: 1.4rem;
    line-height: 1.71;
  }
}
@media (max-width: 767px) {
  .footer-siteInfo p {
    font-size: 3.73vw;
    line-height: 1.71;
  }
}
.footer-siteInfo p::before {
  content: '';
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .footer-siteInfo p::before {
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .footer-siteInfo p::before {
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-siteInfo p::before {
    margin-right: 0.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-siteInfo p {
    font-size: 1.02vw;
  }
}
.footer-siteInfo a {
  color: #333;
}
@media (min-width: 768px) {
  .footer-siteInfo a {
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .footer-siteInfo a:hover {
    opacity: 0.6;
  }
}
.footer-address::before {
  background-image: url("../img/common/icon_address.svg");
}
@media (min-width: 768px) {
  .footer-address::before {
    width: 11px;
    height: 16px;
  }
}
@media (max-width: 767px) {
  .footer-address::before {
    width: 2.8vw;
    height: 3.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-address::before {
    width: 0.81vw;
    height: 1.17vw;
  }
}
.footer-tel::before {
  background-image: url("../img/common/icon_phone.svg");
}
@media (min-width: 768px) {
  .footer-tel::before {
    width: 14px;
    height: 16px;
  }
}
@media (max-width: 767px) {
  .footer-tel::before {
    width: 3.47vw;
    height: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-tel::before {
    width: 1.02vw;
    height: 1.17vw;
  }
}
.footer-navigation-list {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  .footer-navigation-list {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .footer-navigation-list {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) {
  .footer-navigation-list + .footer-navigation-list {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .footer-navigation-list + .footer-navigation-list {
    margin-top: 4.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-navigation-list + .footer-navigation-list {
    margin-top: 1.46vw;
  }
}
.footer-navigation-list::before {
  content: 'ー';
  display: inline-block;
  margin-right: 0.5em;
}
.footer-navigation-list a {
  color: #333;
}
@media (min-width: 768px) {
  .footer-navigation-list a {
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .footer-navigation-list a:hover {
    opacity: 0.6;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer-navigation-list {
    font-size: 1.02vw;
  }
}
.footer .copyright {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: lighter;
  color: #333;
  letter-spacing: 0.05em;
  width: 100%;
}
@media (min-width: 768px) {
  .footer .copyright {
    font-size: 1.4rem;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .footer .copyright {
    font-size: 3.2vw;
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer .copyright {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .footer {
    border-top-width: 0.37vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .footer {
    margin-left: 13.18vw;
  }
}
.toTop {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(95,25,133,0.8);
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../img/common/totop_arrow.png");
  position: absolute;
  opacity: 0;
}
@media (min-width: 768px) {
  .toTop {
    width: 60px;
    height: 60px;
    background-size: 12px 8px;
    top: -90px;
    right: 50px;
    transition: opacity 0.3s;
  }
  .toTop:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  .toTop {
    width: 13.33vw;
    height: 13.33vw;
    background-size: 3.47vw 2.27vw;
    top: -18.67vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
.toTop.show {
  opacity: 1;
}
@media (min-width: 768px) {
  .toTop.fixed {
    position: fixed;
    top: auto;
    bottom: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .toTop.fixed {
    bottom: 1.83vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .toTop {
    width: 4.39vw;
    height: 4.39vw;
    background-size: 0.88vw 0.59vw;
    top: -6.59vw;
    right: 3.66vw;
  }
}
@media (min-width: 1100px) {
  .fixed-navigation {
    display: none;
  }
}
@media (max-width: 1099px) {
  .fixed-navigation {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    transition: bottom 0.5s;
    z-index: 3;
  }
  .fixed-navigation.hidden {
    bottom: -100%;
  }
}
.fixed-navigation .inner {
  display: flex;
}
.fixed-navigation .inner .fixed-elm {
  width: 50%;
}
.fixed-navigation .inner .fixed-elm.is-requestDocuments {
  background-color: #9ec238;
}
@media (max-width: 1099px) {
  .fixed-navigation .inner .fixed-elm.is-requestDocuments .icon {
    width: 3.44vw;
    height: 2.49vw;
  }
}
@media (max-width: 767px) {
  .fixed-navigation .inner .fixed-elm.is-requestDocuments .icon {
    width: 6.27vw;
    height: 4.53vw;
  }
}
.fixed-navigation .inner .fixed-elm.is-webApplication {
  background-color: #1a86b9;
}
@media (max-width: 1099px) {
  .fixed-navigation .inner .fixed-elm.is-webApplication .icon {
    width: 3.22vw;
    height: 2.2vw;
  }
}
@media (max-width: 767px) {
  .fixed-navigation .inner .fixed-elm.is-webApplication .icon {
    width: 5.87vw;
    height: 4vw;
  }
}
.fixed-navigation .inner .fixed-elm a {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1099px) {
  .fixed-navigation .inner .fixed-elm a {
    padding: 2.56vw 0;
  }
}
@media (max-width: 767px) {
  .fixed-navigation .inner .fixed-elm a {
    padding: 4.67vw 0;
  }
}
.fixed-navigation .inner .fixed-elm a .icon {
  display: block;
}
@media (max-width: 1099px) {
  .fixed-navigation .inner .fixed-elm a .icon {
    margin-right: 1.1vw;
  }
}
@media (max-width: 767px) {
  .fixed-navigation .inner .fixed-elm a .icon {
    margin-right: 2vw;
  }
}
.fixed-navigation .inner .fixed-elm a .txt {
  display: block;
  color: #fff;
}
@media (max-width: 1099px) {
  .fixed-navigation .inner .fixed-elm a .txt {
    font-size: 2.64vw;
  }
}
@media (max-width: 767px) {
  .fixed-navigation .inner .fixed-elm a .txt {
    font-size: 4.27vw;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}
@media (min-width: 1100px) {
  .header {
    width: 180px;
    height: 100vh;
    min-height: 770px;
    background-color: #333;
  }
}
@media (max-width: 1099px) {
  .header {
    width: 100%;
    background-color: #333;
  }
}
.header-container {
  position: relative;
}
@media (min-width: 1100px) {
  .header-container {
    display: block !important;
  }
}
@media (max-width: 1099px) {
  .header-container {
    height: 100vh;
    overflow-y: scroll;
  }
}
.header-logo-container {
  display: flex;
  align-items: center;
  background-color: #fff;
}
@media (min-width: 1100px) {
  .header-logo-container {
    width: 180px;
    height: 184px;
  }
  .header-logo-container.l-sp {
    display: none;
  }
}
@media (max-width: 1099px) {
  .header-logo-container {
    width: 100%;
    height: 16vw;
    padding-left: 5.33vw;
  }
  .header-logo-container.l-pc {
    display: none;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-logo-container {
    width: 13.18vw;
    height: 13.47vw;
  }
}
@media (max-width: 1099px) and (min-width: 768px) {
  .header-logo-container {
    height: 80px;
    padding-left: 40px;
  }
}
@media (min-width: 1100px) {
  .header-logo-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .header-logo-inner:hover {
    opacity: 0.6;
  }
}
@media (max-width: 1099px) {
  .header-logo-inner {
    display: inline-block;
  }
}
.header-logo-inner img {
  position: relative;
}
@media (max-width: 1099px) {
  .header-logo-inner img {
    width: 54.93vw;
    height: auto;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-logo-inner img {
    width: 8.49vw;
    height: 12.45vw;
  }
}
@media (max-width: 1099px) and (min-width: 768px) {
  .header-logo-inner img {
    width: 274px;
    height: 48px;
  }
}
.header-main-navigation {
  position: relative;
}
@media (min-width: 1100px) {
  .header-main .header-main-parent,
  .header-main .header-main-child-inner,
  .header-main .header-main-sns-list {
    height: 46px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
}
@media (max-width: 1099px) {
  .header-main .header-main-parent,
  .header-main .header-main-child-inner,
  .header-main .header-main-sns-list {
    border-bottom: 0.27vw solid rgba(255,255,255,0.2);
  }
}
.header-main .header-main-parent > a,
.header-main .header-main-child-inner > a,
.header-main .header-main-sns-list > a {
  display: flex;
  align-items: center;
  width: 100%;
}
@media (min-width: 1100px) {
  .header-main .header-main-parent > a,
  .header-main .header-main-child-inner > a,
  .header-main .header-main-sns-list > a {
    height: 100%;
  }
}
@media (max-width: 1099px) {
  .header-main .header-main-parent > a,
  .header-main .header-main-child-inner > a,
  .header-main .header-main-sns-list > a {
    height: 16vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main .header-main-parent,
  .header-main .header-main-child-inner,
  .header-main .header-main-sns-list {
    height: 3.37vw;
  }
}
.header-main-parent {
  background-color: #333;
}
@media (min-width: 1100px) {
  .header-main-parent {
    transition: background-color 0.3s;
  }
  .header-main-parent:hover:not(.search-box) {
    background-color: #7741a0;
  }
  .header-main-parent:hover:not(.search-box) > .header-main-child {
    display: block;
  }
}
@media (max-width: 1099px) {
  .header-main-parent {
    position: relative;
  }
}
.header-main-parent-nav {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
  letter-spacing: 0.05em;
}
@media (min-width: 1100px) {
  .header-main-parent-nav {
    font-size: 1.4rem;
    padding-left: 30px;
  }
}
@media (max-width: 1099px) {
  .header-main-parent-nav {
    font-size: 4vw;
    padding-left: 8vw;
    padding-right: 17.33vw;
    position: relative;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-parent-nav {
    font-size: 1.02vw;
    padding-left: 2.2vw;
  }
}
@media (min-width: 768px) {
  .header-main .header-main-child-inner._expand {
    min-height: 60px;
  }
}
@media (min-width: 1100px) {
  .header-main .header-main-child-inner.pdf .header-main-child-nav span:after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 15px;
    background: url("../img/common/icon_pdf_white.png") no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 4px;
    vertical-align: middle;
  }
}
.header-main .header-main-child-inner:hover.pdf .header-main-child-nav span:after {
  background: url("../img/common/icon_pdf.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 1100px) {
  .header-main .navigation-arrow {
    display: none;
  }
}
@media (max-width: 1099px) {
  .header-main .navigation-arrow {
    width: 10.67vw !important;
    height: 16vw;
    background-repeat: no-repeat;
    background-size: 4vw 2.13vw;
    background-position: center 55%;
    background-image: url("../img/common/arrow_white.png");
    position: absolute;
    top: 0;
    right: 8vw;
    transform-origin: center center;
    transition: transform 0.3s;
  }
}
.header-main .navigation-arrow.open {
  transform: scaleY(-1);
}
.header-main-child {
  display: none;
}
@media (min-width: 1100px) {
  .header-main-child {
    width: 115%;
    min-height: 100%;
    background-color: rgba(119,65,160,0.9);
    position: absolute;
    top: 0;
    right: -115%;
  }
}
@media (max-width: 1099px) {
  .header-main-child {
    width: 100%;
  }
}
@media (min-width: 1100px) {
  .header-main-child-inner {
    background-color: rgba(119,65,160,0.1);
    transition: background-color 0.3s;
  }
  .header-main-child-inner:hover {
    background-color: #fff;
  }
  .header-main-child-inner:hover > .header-main-child-nav {
    color: #7741a0;
  }
  .header-main-child-inner:hover > .header-main-child {
    display: block;
  }
}
@media (max-width: 1099px) {
  .header-main-child-inner {
    background-color: #7741a0;
    border-top: 0.27vw solid rgba(255,255,255,0.2);
    border-bottom: 0 !important;
    position: relative;
  }
}
.header-main-child-nav {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 1100px) {
  .header-main-child-nav {
    font-size: 1.3rem;
    padding: 0 20px;
    transition: color 0.3s;
  }
}
@media (max-width: 1099px) {
  .header-main-child-nav {
    font-size: 4vw;
    padding-left: 8vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-child-nav {
    font-size: 0.95vw;
    padding: 0vw 1.46vw;
  }
}
.header-main-sns {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
@media (max-width: 1099px) {
  .header-main-sns {
    background-color: #333;
  }
}
.header-main-sns-list {
  border-right: 1px solid rgba(255,255,255,0.2);
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 1100px) {
  .header-main-sns-list {
    width: 50%;
    transition: background-color 0.3s;
  }
  .header-main-sns-list:nth-of-type(n+4) {
    width: 50%;
  }
  .header-main-sns-list:hover {
    background-color: #7741a0;
  }
}
@media (max-width: 1099px) {
  .header-main-sns-list {
    width: 25%;
  }
}
.header-main-sns .is-facebook {
  background-image: url("../img/common/icon_facebook.svg");
}
@media (min-width: 1100px) {
  .header-main-sns .is-facebook {
    background-size: 26px 26px;
  }
}
@media (max-width: 1099px) {
  .header-main-sns .is-facebook {
    background-size: 8vw 6.53vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-sns .is-facebook {
    background-size: 1.9vw 1.9vw;
  }
}
.header-main-sns .is-twitter {
  background-image: url("../img/common/icon_x.svg");
}
@media (min-width: 1100px) {
  .header-main-sns .is-twitter {
    background-size: 24px 24px;
  }
}
@media (max-width: 1099px) {
  .header-main-sns .is-twitter {
    background-size: 6.93vw 6.93vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-sns .is-twitter {
    background-size: 1.76vw 1.76vw;
  }
}
.header-main-sns .is-instagram {
  background-image: url("../img/common/icon_instagram.svg");
}
@media (min-width: 1100px) {
  .header-main-sns .is-instagram {
    background-size: 26px 26px;
  }
}
@media (max-width: 1099px) {
  .header-main-sns .is-instagram {
    background-size: 6.93vw 6.93vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-sns .is-instagram {
    background-size: 1.9vw 1.9vw;
  }
}
.header-main-sns .is-line {
  background-image: url("../img/common/icon_line.svg");
}
@media (min-width: 1100px) {
  .header-main-sns .is-line {
    background-size: 26px 26px;
  }
}
@media (max-width: 1099px) {
  .header-main-sns .is-line {
    background-size: 6.93vw 6.93vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-sns .is-line {
    background-size: 1.9vw 1.9vw;
  }
}
.header-main-sns .is-youtube {
  background-image: url("../img/common/icon_youtube.svg");
}
@media (min-width: 1100px) {
  .header-main-sns .is-youtube {
    background-size: 32px 22px;
  }
}
@media (max-width: 1099px) {
  .header-main-sns .is-youtube {
    background-size: 8.4vw 5.87vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-main-sns .is-youtube {
    background-size: 2.34vw 1.61vw;
  }
}
@media (min-width: 1100px) {
  .header-sub {
    width: calc(100vw - 180px);
    position: absolute;
    top: 0;
    left: 180px;
  }
}
@media (max-width: 1099px) {
  .header-sub {
    padding-bottom: 36vw;
  }
}
@media (min-width: 1100px) {
  .header-sub-outer {
    display: flex;
    background-color: #fff;
  }
}
.header-sub-navigation {
  display: flex;
}
@media (min-width: 1100px) {
  .header-sub-navigation {
    width: 59.44%;
    background-color: rgba(95,25,133,0.7);
  }
}
@media (max-width: 1099px) {
  .header-sub-navigation {
    flex-wrap: wrap;
    background-color: #333;
  }
}
.header-sub-navigation li {
  background-color: #5f1985;
}
@media (min-width: 1100px) {
  .header-sub-navigation li {
    width: 25%;
    height: 40px;
    border-right: 1px solid #fff;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .header-sub-navigation li:hover {
    opacity: 0.6;
  }
  .header-sub-navigation li:last-of-type {
    border-right: none;
  }
}
@media (max-width: 1099px) {
  .header-sub-navigation li {
    width: 50%;
    height: 16vw;
    border-right: 0.27vw solid rgba(255,255,255,0.2);
    border-bottom: 0.27vw solid rgba(255,255,255,0.2);
  }
}
.header-sub-navigation li a {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 1100px) {
  .header-sub-navigation li a {
    font-size: 1.2rem;
  }
}
@media (max-width: 1099px) {
  .header-sub-navigation li a {
    font-size: 3.73vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-sub-navigation li a {
    font-size: 0.88vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-sub-navigation li {
    height: 2.93vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-sub {
    width: calc(100vw - 13.18vw);
    left: 13.18vw;
  }
}
.header-other-navigation {
  display: flex;
}
@media (min-width: 1100px) {
  .header-other-navigation {
    width: 40.56%;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation {
    border-bottom: 0.27vw solid #333;
  }
}
.header-other-navigation li {
  background-color: #fff;
  background-repeat: no-repeat;
}
@media (min-width: 1100px) {
  .header-other-navigation li {
    height: 40px;
    background-position: center 7px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .header-other-navigation li:not(.is-requestDocuments):not(.is-webApplication) {
    width: 16.6%;
    padding-bottom: 5px;
  }
  .header-other-navigation li:not(.is-requestDocuments):not(.is-webApplication):not(.is-contact) {
    border-right: 1px solid #5f1985;
  }
  .header-other-navigation li.is-requestDocuments,
  .header-other-navigation li.is-webApplication {
    width: 24.8%;
  }
  .header-other-navigation li:hover {
    opacity: 0.6;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation li {
    width: 33.3%;
    height: 16vw;
    padding-bottom: 3.33vw;
    border-right: 0.27vw solid #333;
    background-position: center 4vw;
  }
}
.header-other-navigation li:nth-of-type(3) {
  border-right-color: #fff;
}
.header-other-navigation li:last-of-type {
  border-right: none;
}
.header-other-navigation li a {
  color: #601986;
  letter-spacing: 0.05em;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation li {
    height: 2.93vw;
  }
  .header-other-navigation li:not(.is-requestDocuments):not(.is-webApplication) {
    padding-bottom: 0.37vw;
  }
}
.header-other-navigation .is-language {
  background-image: url("../img/common/icon_language.svg");
}
@media (min-width: 1100px) {
  .header-other-navigation .is-language {
    background-size: 17px 16px;
    background-position-y: 5px;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-language {
    background-size: 4.4vw 4.4vw;
  }
}
.header-other-navigation .is-language a {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 1100px) {
  .header-other-navigation .is-language a {
    font-size: 1.2rem;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-language a {
    font-size: 3.47vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-language a {
    font-size: 0.88vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-language {
    background-size: 1.24vw 1.17vw;
    background-position-y: 0.37vw;
  }
}
.header-other-navigation .is-access {
  background-image: url("../img/common/icon_access.svg");
}
@media (min-width: 1100px) {
  .header-other-navigation .is-access {
    background-size: 15px 18px;
    background-position-y: 5px;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-access {
    background-size: 3.87vw 4.67vw;
  }
}
.header-other-navigation .is-access a {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 1100px) {
  .header-other-navigation .is-access a {
    font-size: 1.2rem;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-access a {
    font-size: 3.47vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-access a {
    font-size: 0.88vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-access {
    background-size: 1.1vw 1.32vw;
    background-position-y: 0.37vw;
  }
}
.header-other-navigation .is-contact {
  background-image: url("../img/common/icon_contact.svg");
}
@media (min-width: 1100px) {
  .header-other-navigation .is-contact {
    background-size: 17px 12px;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-contact {
    background-size: 4.67vw 3.33vw;
  }
}
.header-other-navigation .is-contact a {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 1100px) {
  .header-other-navigation .is-contact a {
    font-size: 1.1rem;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-contact a {
    font-size: 3.2vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-contact a {
    font-size: 0.81vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-contact {
    background-size: 1.24vw 0.88vw;
  }
}
.header-other-navigation .is-requestDocuments {
  background-color: #9ec238;
}
.header-other-navigation .is-webApplication {
  background-color: #1a86b9;
}
@media (max-width: 1099px) {
  .header-other-navigation .is-requestDocuments,
  .header-other-navigation .is-webApplication {
    display: none;
  }
}
.header-other-navigation .is-requestDocuments a,
.header-other-navigation .is-webApplication a {
  align-items: center;
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
}
@media (min-width: 1100px) {
  .header-other-navigation .is-requestDocuments a,
  .header-other-navigation .is-webApplication a {
    font-size: 1.5rem;
  }
}
@media (max-width: 1099px) {
  .header-other-navigation .is-requestDocuments a,
  .header-other-navigation .is-webApplication a {
    font-size: 3.2vw;
  }
}
.header-other-navigation .is-requestDocuments a .icon,
.header-other-navigation .is-webApplication a .icon {
  display: block;
  margin-right: 5px;
}
.header-other-navigation .is-requestDocuments a .txt,
.header-other-navigation .is-webApplication a .txt {
  display: block;
  margin-top: -2px;
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header-other-navigation .is-requestDocuments a,
  .header-other-navigation .is-webApplication a {
    font-size: 1.1vw;
  }
}
@media (min-width: 1100px) {
  .header .hmb-icon {
    display: none;
  }
}
@media (max-width: 1099px) {
  .header .hmb-icon {
    width: 16vw;
    height: 16vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    speak: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}
@media (max-width: 1099px) {
  .header .hmb-icon.open .line {
    height: 5.87vw;
    margin-left: -1.33vw;
  }
}
.header .hmb-icon.open .line span {
  opacity: 0;
}
.header .hmb-icon.open .line::before {
  transform: rotate(-45deg);
}
.header .hmb-icon.open .line::after {
  transform: rotate(45deg);
}
@media (max-width: 1099px) and (min-width: 768px) {
  .header .hmb-icon.open .line {
    height: 29px;
    margin-left: -10px;
  }
}
@media (max-width: 1099px) {
  .header .hmb-icon .line {
    width: 5.87vw;
    height: 4.4vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .header .hmb-icon .line::before,
  .header .hmb-icon .line::after,
  .header .hmb-icon .line span {
    width: 100%;
    height: 0.27vw;
    background-color: #fff;
  }
  .header .hmb-icon .line::before,
  .header .hmb-icon .line::after {
    content: '';
  }
  .header .hmb-icon .line::before {
    transform-origin: right bottom;
  }
  .header .hmb-icon .line::after {
    transform-origin: right top;
  }
}
@media (max-width: 1099px) and (min-width: 768px) {
  .header .hmb-icon .line {
    width: 29px;
    height: 22px;
  }
}
@media (max-width: 1099px) and (min-width: 768px) {
  .header .hmb-icon .line::before,
  .header .hmb-icon .line::after,
  .header .hmb-icon .line span {
    height: 2px;
  }
}
@media (max-width: 1099px) and (min-width: 768px) {
  .header .hmb-icon {
    width: 80px;
    height: 80px;
  }
}
@media (min-width: 1100px) {
  .header .search-box {
    height: auto !important;
    padding: 20px 14px;
  }
}
@media (max-width: 1099px) {
  .header .search-box {
    padding: 8vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header .search-box {
    padding: 1.46vw 1.02vw;
  }
}
.header form.gsc-search-box {
  margin: 0;
}
.header .gsc-control-cse {
  background-color: transparent;
  padding: 0;
  border: 0;
}
.header .gsc-input {
  height: 100%;
}
@media (min-width: 1100px) {
  .header .gsc-input {
    font-size: 1.4rem;
    width: calc(100% - 25px);
  }
}
@media (max-width: 1099px) {
  .header .gsc-input {
    font-size: 4vw;
    width: calc(100% - 6.67vw);
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header .gsc-input {
    font-size: 1.02vw;
    width: calc(100% - 1.83vw);
  }
}
.header .gsc-input-box {
  border: 0;
  padding: 0;
  background: none;
}
.header table.gsc-search-box {
  margin: 0;
}
.header table.gsc-search-box > tbody > tr {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
@media (min-width: 1100px) {
  .header table.gsc-search-box > tbody > tr {
    height: 26px;
    padding: 0 10px;
    border-radius: 13px;
  }
}
@media (max-width: 1099px) {
  .header table.gsc-search-box > tbody > tr {
    height: 8vw;
    padding: 0vw 2.67vw;
    border-radius: 4vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header table.gsc-search-box > tbody > tr {
    height: 1.9vw;
    padding: 0vw 0.73vw;
  }
}
.header table.gsc-search-box td.gsc-input {
  padding-right: 0;
}
.header .gsib_a {
  padding: 0;
}
.header .gsib_a input {
  margin: 0 !important;
  height: auto !important;
}
.header .gscb_a {
  line-height: 1;
}
@media (min-width: 1100px) {
  .header .gscb_a {
    font-size: 2.4rem;
  }
}
@media (max-width: 1099px) {
  .header .gscb_a {
    font-size: 5.33vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header .gscb_a {
    font-size: 1.76vw;
  }
}
.header .gsst_a {
  padding: 0;
}
.header .gsst_a .gscb_a {
  color: #a0a0a0;
}
.header .gsc-search-button {
  margin-left: 0;
}
@media (min-width: 1100px) {
  .header .gsc-search-button {
    width: 20px;
    margin-left: 5px;
  }
}
@media (max-width: 1099px) {
  .header .gsc-search-button {
    width: 6.67vw;
    margin: 0vw 2.67vw;
  }
}
.header .gsc-search-button-v2 {
  height: 100%;
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../img/common/icon_search.png");
}
@media (min-width: 1100px) {
  .header .gsc-search-button-v2 {
    margin-left: 0 !important;
    background-size: 18px 18px;
    transition: opacity 0.3s;
  }
}
@media (max-width: 1099px) {
  .header .gsc-search-button-v2 {
    margin: 0 !important;
    background-size: 4.53vw 4.53vw;
  }
}
.header .gsc-search-button-v2:hover,
.header .gsc-search-button-v2:focus {
  border: 0;
  outline: 0;
  background-color: transparent;
  background-image: url("../img/common/icon_search.png");
  opacity: 0.6;
}
.header .gsc-search-button-v2 svg {
  display: none;
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header .gsc-search-button-v2 {
    background-size: 1.32vw 1.32vw;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header .gsc-search-button {
    width: 1.46vw;
    margin-left: 0.37vw;
  }
}
.header input.gsc-input,
.header .gsc-input-box,
.header .gsc-input-box-hover,
.header .gsc-input-box-focus {
  border: 0;
  background-color: transparent;
  height: 100%;
}
@media (max-width: 1099px) {
  .header .header-main-child .header-main-child .header-main-child-nav {
    padding-left: calc(8vw + 1em);
  }
}
@media (min-width: 768px) {
  .header .header-main-child.dirlv_3 {
    width: 160%;
    top: inherit;
    bottom: 0;
    right: -160%;
  }
  .header .header-main-child.dirlv_3 .header-main-child-inner {
    height: 37.6px;
    font-size: 13px;
  }
  .header .header-main-child.dirlv_4 .header-main-child-inner {
    height: 47px;
  }
@media (min-width: 768px) and (min-width: 1100px) and (max-width: 1365px) {
    .header .header-main-child.height-adjust {
      height: 5.42vw;
    }
}
}
@media (max-width: 767px) {
  .header .header-main-child.dirlv_3 .header-main-child-inner {
    background-color: #fff;
    border-color: #5f1985;
  }
  .header .header-main-child.dirlv_3 .header-main-child-inner .header-main-child-nav {
    color: #5f1985;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .header .header-main-child .header-main-child-inner {
    height: 2.75vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1099px) {
  .header .header-main-child .header-main-child-inner {
    height: 16vw;
  }
}
@media (min-width: 768px) and (min-width: 1100px) {
  .header .header-main-child.height-adjust {
    height: 74px;
  }
}
@media (max-width: 1099px) {
  .header .jsHederNav {
    display: none;
  }
}
@media (min-width: 1100px) and (max-width: 1365px) {
  .header {
    width: 13.18vw;
  }
}
.breadcrumbs-container {
  display: flex;
  align-items: center;
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  .breadcrumbs-container {
    height: 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs-container {
    height: 8vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .breadcrumbs-container::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .breadcrumbs-container {
    height: 2.93vw;
  }
}
.breadcrumbs-inner {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .breadcrumbs-inner {
    padding: 0 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs-inner {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .breadcrumbs-inner {
    padding: 0vw 2.93vw;
  }
}
.breadcrumbs-list {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #333;
  line-height: 1.5;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .breadcrumbs-list {
    font-size: 1.2rem;
  }
}
@media (max-width: 767px) {
  .breadcrumbs-list {
    font-size: 3.2vw;
  }
}
.breadcrumbs-list:first-of-type a {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../img/common/icon_home.svg");
}
@media (min-width: 768px) {
  .breadcrumbs-list:first-of-type a {
    width: 17px;
    height: 16px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs-list:first-of-type a {
    width: 3.47vw;
    height: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .breadcrumbs-list:first-of-type a {
    width: 1.24vw;
    height: 1.17vw;
  }
}
.breadcrumbs-list + .breadcrumbs-list span,
.breadcrumbs-list + .breadcrumbs-list a {
  white-space: nowrap;
}
@media (min-width: 768px) {
  .breadcrumbs-list + .breadcrumbs-list span,
  .breadcrumbs-list + .breadcrumbs-list a {
    max-width: 40em;
  }
}
.breadcrumbs-list + .breadcrumbs-list::before {
  content: '';
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../img/common/breadcrumbs_arrow.png");
}
@media (min-width: 768px) {
  .breadcrumbs-list + .breadcrumbs-list::before {
    width: 8px;
    height: 12px;
    margin: 0 15px -1px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs-list + .breadcrumbs-list::before {
    width: 1.73vw;
    height: 3.07vw;
    margin: 0vw 4vw -0.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .breadcrumbs-list + .breadcrumbs-list::before {
    width: 0.59vw;
    height: 0.88vw;
    margin: 0vw 1.1vw -0.07vw;
  }
}
.breadcrumbs-list + .breadcrumbs-list a {
  border-bottom-color: #d7d7d9;
  border-bottom-style: solid;
}
@media (min-width: 768px) {
  .breadcrumbs-list + .breadcrumbs-list a {
    border-bottom-width: 2px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs-list + .breadcrumbs-list a {
    border-bottom-width: 0.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .breadcrumbs-list + .breadcrumbs-list a {
    border-bottom-width: 0.15vw;
  }
}
.breadcrumbs-list a {
  display: inline-block;
  color: #333;
}
@media (min-width: 768px) {
  .breadcrumbs-list a {
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .breadcrumbs-list a:hover {
    opacity: 0.6;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .breadcrumbs-list {
    font-size: 0.88vw;
  }
}
.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (min-width: 768px) {
  .page-header {
    height: 200px;
  }
}
@media (max-width: 767px) {
  .page-header {
    height: 33.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .page-header {
    height: 14.64vw;
  }
}
.page-heading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #601986;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  .page-heading {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .page-heading {
    font-size: 6.67vw;
  }
}
.page-heading-en {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.1em;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .page-heading-en {
    font-size: 1.6rem;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .page-heading-en {
    font-size: 3.47vw;
    margin-top: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .page-heading-en {
    font-size: 1.17vw;
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .page-heading {
    font-size: 2.93vw;
  }
}
.notFind {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-align: center;
}
@media (min-width: 768px) {
  .notFind {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  .notFind {
    font-size: 3.73vw;
    line-height: 2;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .notFind {
    font-size: 1.02vw;
  }
}
.modal-overlay {
  z-index: 9990;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0,0,0,0.6);
}
.modal-content {
  width: 80%;
  max-width: 960px;
  margin: 0;
  padding: 0;
  position: fixed;
  display: none;
  z-index: 9999;
}
.modal-inner {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}
#ytPlayer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.modal-close-btn {
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../img/common/close.svg");
  border: 0;
  padding: 0;
  position: absolute;
  cursor: pointer;
  color: #fff;
}
@media (min-width: 768px) {
  .modal-close-btn {
    width: 50px;
    height: 50px;
    top: -70px;
    right: -70px;
  }
}
@media (max-width: 767px) {
  .modal-close-btn {
    width: 4vw;
    height: 4vw;
    top: -5.33vw;
    right: -2.67vw;
  }
}
.modal-close-btn:focus {
  outline: 0;
}
@media (min-width: 768px) {
  .modal-close-btn img {
    width: 50px;
  }
}
@media (max-width: 767px) {
  .modal-close-btn img {
    width: 8vw;
  }
}
#ytPlayer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
[data-pages="error"] .main h1 {
  color: #3b4043;
}
@media (min-width: 768px) {
  [data-pages="error"] .main h1 {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="error"] .main h1 {
    margin-bottom: 2.67vw;
  }
}
[data-pages="error"] .main p {
  text-align: center;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="error"] .main p {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="error"] .main p {
    font-size: 1.87vw;
  }
}
@media (min-width: 768px) {
  [data-pages="error"] .main p + p {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="error"] .main p + p {
    margin-top: 1.33vw;
  }
}
[data-pages="history"].pages {
  overflow: visible;
}
[data-pages="history"] .footer {
  border: none;
}
[data-pages="history"] .breadcrumbs-container {
  position: relative;
}
[data-pages="history"] .page-header {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="history"] .page-header {
    background-image: url("../img/about/history/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .page-header {
    background-image: url("../img/about/history/header_bg-sp.jpg");
  }
}
[data-pages="history"] .ui-template {
  background: none;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="history"] .ui-template {
    padding-top: 0;
  }
}
[data-pages="history"] .container-history {
  background-color: rgba(255,255,255,0.9);
}
@media (min-width: 768px) {
  [data-pages="history"] .container-history {
    padding: 56px 76px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .container-history {
    padding-top: 9.6vw;
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .container-history {
    padding: 4.1vw 5.56vw;
  }
}
[data-pages="history"] .heading.wrap-box {
  max-width: 748px;
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box {
    display: flex;
    justify-content: space-between;
  }
}
[data-pages="history"] .heading.wrap-box .box-text .heading {
  font-feature-settings: "palt";
  line-height: 1.4;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-text .heading {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-text .heading {
    font-size: 5.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-text .heading {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-text .text {
    margin-top: 45px;
    line-height: 1.96;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-text .text {
    margin-top: 4vw;
    line-height: 1.9;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-text .text {
    margin-top: 3.29vw;
  }
}
[data-pages="history"] .heading.wrap-box .box-image {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-image img {
    margin-top: 8px;
    width: 194px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-image img {
    margin-top: 6.4vw;
    width: 63%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-image img {
    width: 14.2vw;
    height: auto;
  }
}
[data-pages="history"] .heading.wrap-box .box-image .figcaption {
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-image .figcaption {
    margin-top: 24px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-image .figcaption {
    margin-top: 5.07vw;
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-image .figcaption {
    margin-top: 1.76vw;
    font-size: 1.1vw;
  }
}
[data-pages="history"] .list-history {
  max-width: 770px;
}
@media (min-width: 768px) {
  [data-pages="history"] .list-history {
    margin: 100px auto 0;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .list-history {
    margin: 15.2vw auto 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .list-history {
    margin: 7.32vw auto 0vw;
  }
}
[data-pages="history"] .item-history {
  position: relative;
  width: 100%;
  height: 100%;
  border-left: 1px solid #601986;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history {
    padding: 20px 0 67px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history {
    padding: 4.8vw 0vw 9.33vw;
  }
}
[data-pages="history"] .item-history::before {
  position: absolute;
  content: "";
  background-color: #601986;
  border-radius: 50%;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history::before {
    top: -3px;
    left: -6px;
    width: 11px;
    height: 11px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history::before {
    top: -0.93vw;
    left: -1.6vw;
    width: 2.93vw;
    height: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history::before {
    top: -0.22vw;
    left: -0.44vw;
    width: 0.81vw;
    height: 0.81vw;
  }
}
[data-pages="history"] .item-history:last-of-type {
  border-left: none;
}
[data-pages="history"] .item-history .age {
  position: relative;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.1em;
  line-height: 1;
  color: #601986;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .age {
    top: -35px;
    padding: 0 0 0 42px;
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .age {
    top: -7.33vw;
    padding: 0vw 0vw 0vw 5.33vw;
    font-size: 6.4vw;
  }
}
[data-pages="history"] .item-history .age::before {
  position: absolute;
  content: "";
  width: 4.4%;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #601986;
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .age::before {
    width: 6%;
  }
}
[data-pages="history"] .item-history .age::after {
  position: absolute;
  content: "";
  width: 83%;
  height: 1px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #601986;
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .age::after {
    width: 71.5%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .age {
    top: -2.56vw;
    padding: 0vw 0vw 0vw 3.07vw;
    font-size: 2.64vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .wrap-box {
    display: flex;
    justify-content: space-between;
    padding: 0 31px 0 44px;
    margin-top: -15px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .wrap-box {
    padding: 0vw 1.07vw 0vw 6.4vw;
    margin-top: -4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .wrap-box {
    padding: 0vw 2.27vw 0vw 3.22vw;
    margin-top: -1.1vw;
  }
}
[data-pages="history"] .item-history .box-text {
  max-width: 472px;
}
[data-pages="history"] .item-history .box-text .date {
  line-height: 1;
  padding: 0 0 1px 6px;
  border-left: 4px solid #601986;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-text .date {
    margin-bottom: 11px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-text .date {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-text .date {
    margin-bottom: 0.81vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-text .date:nth-of-type(2),
  [data-pages="history"] .item-history .box-text .date:nth-of-type(3) {
    margin-top: 32px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-text .date:nth-of-type(2),
  [data-pages="history"] .item-history .box-text .date:nth-of-type(3) {
    margin-top: 6.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-text .date:nth-of-type(2),
  [data-pages="history"] .item-history .box-text .date:nth-of-type(3) {
    margin-top: 2.34vw;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-image {
    margin-top: 7.47vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-image img {
    width: 200px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-image img {
    width: 91%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-image img {
    width: 14.64vw;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-image.box-image--2col {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-image.box-image--2col .box-image--2col-item:last-of-type {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-image.box-image--2col .box-image--2col-item:last-of-type {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-image.box-image--2col .box-image--2col-item:last-of-type {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history {
    padding: 1.46vw 0vw 4.9vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image {
    padding: 28px 0 62px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image {
    padding: 5.6vw 0vw 8.8vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image::before {
    top: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image::before {
    top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image::before {
    top: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image .wrap-box {
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image .wrap-box {
    margin-top: -2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image .age {
    top: -46px;
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image .age {
    top: -6.93vw;
    font-size: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image .age::after {
    width: 75.4%;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image .age::after {
    width: 56%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image .age {
    top: -3.37vw;
    font-size: 4.39vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image {
    padding: 2.05vw 0vw 4.54vw;
  }
}
[data-pages="history"] .container-history_bg {
  position: fixed;
  height: calc(100vh - 40px);
  width: 3558px;
  background-image: url("../img/about/history/bg-history.jpg");
  background-size: cover;
  background-position: top left;
}
@media (min-width: 768px) {
  [data-pages="history"] .container-history_bg {
    left: 180px;
    top: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .container-history_bg {
    left: 0;
    top: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .container-history_bg {
    left: 0;
    top: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="access"] .page-header {
    background-image: url("../img/access/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="access"] .page-header {
    background-image: url("../img/access/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="access"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .box.text {
    width: 60%;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .box.img {
    width: 37%;
  }
}
[data-category="access"] [data-access="index"] .iframe-map {
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="access"] [data-access="index"] .c-info_item a {
  display: block;
}
[data-category="access"] [data-access="index"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--image {
    height: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--image {
    height: 20.5vw;
  }
}
[data-category="access"] [data-access="index"] .c-info_item img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="access"] [data-access="index"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
  }
}
[data-category="access"] [data-access="index"] .c-info_item .info_item--title:before {
  content: "";
  display: inline-block;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title:before {
    width: 8px;
    height: 12px;
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title {
    font-size: 1.02vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item {
    width: calc(50% - 1.46vw);
  }
}
[data-category="access"] [data-access="index"] .info-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-link-container {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-link-container {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-link-container {
    margin-top: 0.73vw;
  }
}
[data-category="access"] [data-access="index"] .info-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
  pointer-events: none;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-text-link {
    font-size: 1.3rem;
  }
  [data-category="access"] [data-access="index"] .info-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="access"] [data-access="index"] .info-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="access"] [data-access="index"] .info-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .page-header {
    background-image: url("../img/for-alumni/alumni-index/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .page-header {
    background-image: url("../img/for-alumni/alumni-index/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .content-for_menu-inner {
    padding: 78px 0 42px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .content-for_menu-inner {
    padding: 40px 0 62px;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-1.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 100px;
    background-size: 78px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 75px;
    background-size: 58px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-2.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 94px;
    background-size: 88px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 70px;
    background-size: 65px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-3.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 111px;
    background-size: 78px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 84px;
    background-size: 58px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-2.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 94px;
    background-size: 88px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 70px;
    background-size: 65px auto;
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 {
    height: 280px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 {
    height: 218px;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-4.svg");
  bottom: 20%;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 98px;
    background-size: 80px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 71px;
    background-size: 60px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner .for_menu_title_ja {
  line-height: 1.4;
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_outer::after {
  bottom: 13%;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 {
    height: 280px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 {
    height: 218px;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-5.svg");
  bottom: 20%;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 101px;
    background-size: 61px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 74px;
    background-size: 45px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner .for_menu_title_ja {
  line-height: 1.4;
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_outer::after {
  bottom: 13%;
}
[data-pages="alumni-index"] .container-info {
  margin-top: 10px;
}
[data-pages="alumni-index"] .link-content {
  text-align: right;
}
[data-pages="alumni-index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="alumni-index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="alumni-index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="alumni-index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="alumni-index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="alumni-index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="alumni-index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  [data-pages="circles"] .list-circles_link::after {
    display: block;
    content: "";
    width: 340px;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circle_data {
    width: 31.482%;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link {
    width: 31.482%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  [data-pages="circles"] .list-circles_link .item-circles_link:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link {
    margin-bottom: 8vw;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
  font-weight: 400;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
    font-size: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
    font-size: 2.34vw;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
  font-weight: 400;
  margin-top: 10px;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
    font-size: 4.27vw;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en:after {
  content: "";
  display: inline-block;
  background-image: url("$commonImgPatharrow.svg");
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en:after {
    padding-left: 10px;
    width: 6px;
    height: 12px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en:after {
    padding-left: 2.67vw;
    width: 1.6vw;
    height: 3.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] .list-circles_link::after {
    width: 24.89vw;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .ui-template .container.-limit.block-circles_slider {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    width: 100vw;
  }
  [data-pages="circles"] [data-campus_life="circles"] img {
    width: 100%;
    height: auto;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider {
  position: relative;
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text {
  z-index: 100;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
    font-size: 96px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
    font-size: 12.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
    font-size: 7.03vw;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
    font-size: 1.32vw;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider {
  position: relative;
  height: 420px;
  margin: 0 auto;
  padding: 0 auto;
  text-align: center;
}
@media (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider {
    height: 30.75vw;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider {
    height: 83vw;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider {
  margin: 0;
  padding: 0;
  list-style: none;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider img {
  width: 100%;
  margin: 0 auto;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-iteration-count: infinite;
  animation-duration: 21s;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider:nth-child(1) {
  animation-name: wrap-crossfade_slider;
  animation-delay: -1s;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider:nth-child(2) {
  animation-name: wrap-crossfade_slider;
  animation-delay: 6s;
  opacity: 0;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider:nth-child(3) {
  animation-name: wrap-crossfade_slider;
  animation-delay: 13s;
  opacity: 0;
}
@-moz-keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] img {
    width: 100%;
    height: auto;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab {
  padding-bottom: 0 !important;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab a {
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab a {
    height: auto;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab span {
  color: #333;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab.active {
  padding-bottom: 20px !important;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab.active span {
  color: #601986;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_open {
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_open:hover {
    opacity: 0.7;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents {
  z-index: 2001;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  transition: 0.3s opacity ease, 0s visibility 0s linear;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents {
    transform: translate(-40%, -50%);
  }
}
@media (max-width: 1100px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents {
    transform: translate(-50%, -50%);
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents.is-modalopen {
  opacity: 1;
  visibility: visible;
  transition: 0.3s opacity ease, 0s visibility 0s linear;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal_overlay {
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.55);
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: 0.3s opacity ease, 0s visibility 0s linear, backdrop-filter 0.2s;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal_overlay.is-modalopen {
  visibility: visible;
  opacity: 1;
  transition: 0.3s opacity ease, 0s visibility 0s linear;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close {
  position: absolute;
  color: #fff;
  background-image: url("../img/campus-life/circles/icon-close.svg");
  background-size: 50% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #333;
  border-radius: 50%;
  transition: background-color 0.2s;
  z-index: 1000;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close {
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
  }
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close:hover {
    background-color: #999;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close {
    top: -5.33vw;
    right: -5.33vw;
    width: 10.67vw;
    height: 10.67vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
  background-color: #fff;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
    max-height: 80vh;
    width: 860px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
    max-height: 68vh;
    width: 89vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
    width: 62.96vw;
    max-height: 66vh;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
  overflow-y: auto;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
    max-height: 73vh;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
    max-height: 60vh;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
    max-height: 57vh;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #b08cc3;
  z-index: 900;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
    padding: 24px 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
    padding: 3.2vw 5.33vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
    font-size: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
    font-size: 1.9vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
    font-size: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
    padding: 1.76vw 2.93vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 40px 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
    padding: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-img {
    width: 56.25%;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-img {
    margin-bottom: 5.33vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-img img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-text {
    width: 38%;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
    padding-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
    padding-bottom: 1.46vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content [class^="heading"].withBlock:before {
  height: 1em;
  margin-top: 1px;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
    padding: 2.93vw 2.93vw 2.93vw 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .circle_name {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .circle_name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .circle_name {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__title {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__title {
    font-size: 1.32vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__schedule {
  display: flex;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__schedule .dt {
  white-space: nowrap;
  margin-right: 0.3em;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
    padding: 0 0 40px 0;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
    padding: 0 0 2.67vw 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
    padding: 0vw 0vw 2.93vw 0vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns {
    margin-right: 35px;
  }
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns {
    margin-right: 4vw;
    width: 8vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns:last-of-type {
  margin-right: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns {
    margin-right: 2.56vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .flex-reverse {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-right: 0;
  }
  [data-pages="campus-life-committee"] .flex-reverse .box {
    width: 450px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .flex-reverse {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .flex-reverse .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .flex-campus-life {
    display: flex;
    justify-content: space-between;
    flex-direction: inherit;
    margin-right: 0;
  }
  [data-pages="campus-life-committee"] .flex-campus-life .box {
    width: 450px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .flex-campus-life {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .flex-campus-life .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .snsbuttons > li {
    margin-left: 100px;
  }
}
[data-pages="campus-life-committee"] .sns-wrap {
  text-align: right;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .sns-wrap {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .sns-wrap {
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .sns-wrap {
    right: 2.2vw;
    bottom: 2.2vw;
  }
}
[data-pages="campus-life-committee"] .heading-club {
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .heading-club {
    font-size: 21px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .heading-club {
    font-size: 5.07vw;
  }
}
[data-pages="campus-life-committee"] .heading-club:before {
  content: "";
  display: inline-block;
  background: url("../img/common/deco-club-heading.svg") no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .heading-club:before {
    width: 4px;
    height: 24px;
    margin-right: 12px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .heading-club:before {
    width: 0.8vw;
    height: 4.67vw;
    margin-right: 2.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .heading-club:before {
    width: 0.29vw;
    height: 1.76vw;
    margin-right: 0.88vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .heading-club {
    font-size: 1.54vw;
  }
}
[data-pages="campus-life-committee"] .club-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list {
    margin-top: 50px;
    padding-bottom: 20px;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list {
    margin-top: 7.33vw;
    justify-content: center;
  }
}
[data-pages="campus-life-committee"] .club-list li {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li {
    position: relative;
    background: #f5f6f8;
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list li {
    border-radius: 1.6vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #f5f6f8;
    z-index: -1;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(1) {
    margin-right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(1) {
    margin-right: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) {
    width: calc(100% / 2 - 30px / 2);
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) .club-list-content {
    height: 250px;
    min-height: 200px;
    padding: 30px;
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) .club-list-content {
    height: 18.3vw;
    padding: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) {
    width: calc(100% / 2 - 2.2vw / 2);
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(3),
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(4) {
    margin-right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(3),
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(4) {
    margin-right: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) {
    width: calc(100% / 3 - 60px / 3);
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) .club-list-content {
    height: 330px;
    min-height: 280px;
    padding: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) .club-list-content {
    height: 24.16vw;
    padding: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) {
    margin-top: 2.93vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list li:not(:first-of-type) {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li {
    border-radius: 0.44vw;
  }
}
[data-pages="campus-life-committee"] .club-list-thumb img {
  width: 100%;
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list-content {
    background: #f5f6f8;
    padding: 5.33vw 5.33vw 4.67vw;
  }
}
[data-pages="campus-life-committee"] .club-list-content-copy {
  font-weight: 400;
  line-height: 2;
  text-align: justify;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list-content-copy {
    font-size: 14px;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list-content-copy {
    font-size: 3.47vw;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list-content-copy {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list-content .btn-more {
    background: #f5f6f8;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list-content .btn-more {
    margin-top: 3.33vw;
    margin-left: auto;
    background: transparent;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list-content .btn-more {
    bottom: 2.2vw;
    right: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list {
    margin-top: 3.66vw;
    padding-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table {
    padding-top: 13.33vw;
  }
}
[data-pages="campus-life-committee"] .club-table-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table-list {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table-list li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list li {
    width: 100%;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table-list li:not(:nth-of-type(4n+1)) {
    margin-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table-list li:not(:nth-of-type(4n+1)) {
    margin-left: 1.46vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list li:not(:first-of-type) {
    margin-top: 5.33vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list li .cmn-btn {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table-list li {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table-list {
    margin-top: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table {
    padding-top: 4.39vw;
  }
}
[data-pages="campus-life-event-calendar"] .set-item-campus-life {
  width: 452px;
}
@media (max-width: 1365px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 33.09vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 452px;
  }
}
@media (max-width: 1100px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 33.09vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 100%;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life .wrap-campus {
    width: 68.81vw;
  }
}
[data-pages="campus-life-event-calendar"] .adjust-text {
  width: 171px;
}
@media (max-width: 1365px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 12.52vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 171px;
  }
}
@media (max-width: 1100px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 12.52vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 22.8vw;
  }
}
[data-pages="campus-life-event-calendar"] .img {
  width: 452px;
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .img {
    width: 33.09vw;
  }
}
@media (max-width: 1100px) {
  [data-pages="campus-life-event-calendar"] .img {
    width: 33.09vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-event-calendar"] .img {
    width: 60.27vw;
  }
}
[data-pages="campus-life-event-calendar"] .withBlock.-spring:before {
  background: #ff93b3;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-spring:before {
  background-color: #ff93b3;
}
[data-pages="campus-life-event-calendar"] .withBlock.-summer:before {
  background: #92c1ea;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-summer:before {
  background-color: #92c1ea;
}
[data-pages="campus-life-event-calendar"] .withBlock.-autumn:before {
  background: #f87433;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-autumn:before {
  background-color: #f87433;
}
[data-pages="campus-life-event-calendar"] .withBlock.-winter:before {
  background: #8081b8;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-winter:before {
  background-color: #8081b8;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-img {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-img {
    margin-top: 8vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-place {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-place {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-place {
    margin-top: 10.67vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list {
    width: 340px;
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list {
    width: 100%;
    border-radius: 1.6vw;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(n+4) {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list:not(:first-of-type) {
    margin-top: 4vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(1) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_1.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(2) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_2.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(3) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_3.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(4) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_4.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(5) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_5.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(6) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_6.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-thumb img {
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content {
    padding: 20px 10px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content {
    padding: 5.33vw 2.67vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title {
  color: $base-color-purple;
  font-weight: 700;
  position: relative;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title {
    font-size: 20px;
    padding-left: 52px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title {
    font-size: 5.33vw;
    padding-left: 14vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title:before {
    width: 50px;
    height: 62px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title:before {
    width: 13.07vw;
    height: 16.27vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-content-copy {
  font-weight: 400;
  line-height: 2;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-copy {
    font-size: 16px;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-copy {
    font-size: 3.47vw;
    margin-top: 4.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"]:not([data-pages="circles"]) .page-header {
    background-image: url("../img/campus-life/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"]:not([data-pages="circles"]) .page-header {
    background-image: url("../img/campus-life/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"][data-pages="campus-life-circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"][data-pages="campus-life-circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li.c-info_item {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li.c-info_item a {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
[data-category="campus-life"] [data-campus-life="index"] .content-latest-container .information-contnt-block a {
  color: #333;
}
[data-category="campus-life"] [data-campus-life="index"] .info-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-link-container {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-link-container {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-link-container {
    margin-top: 0.73vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .info-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link {
    font-size: 1.3rem;
  }
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .info-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap {
    flex-wrap: wrap;
  }
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap .c-info_item:nth-of-type(n+3) {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap .info-link-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap .c-info_item:nth-of-type(n+3) {
    margin-top: 2.93vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item a {
    height: 100%;
    padding-bottom: 35px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item a {
    padding-bottom: 3.66vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--image {
    height: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--image {
    height: 20.5vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title:before {
  content: "";
  display: inline-block;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title:before {
    width: 8px;
    height: 12px;
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
    font-size: 1.02vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
    font-size: 1.2rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
    font-size: 3.2vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
    font-size: 0.88vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item {
    width: calc(50% - 1.46vw);
  }
}
[data-category="campus-life"] [data-campus_life="sports"] .tab-container {
  justify-content: center;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-image,
  [data-category="campus-life"] [data-campus_life="sports"] .withBlock {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-image,
  [data-category="campus-life"] [data-campus_life="sports"] .withBlock {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-image,
  [data-category="campus-life"] [data-campus_life="sports"] .withBlock {
    margin-left: 1.46vw;
  }
}
[data-category="campus-life"] [data-campus_life="sports"] .content-latest-container {
  display: flex;
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus_life="sports"] .content-latest-container {
    flex-direction: column;
  }
}
[data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-inner {
  padding: 0;
}
.content-container-first {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .content-container-first {
    flex-direction: column;
  }
}
.content-container-second {
  display: flex;
  justify-content: space-between;
}
.content-container-second li {
  width: 45%;
}
@media (max-width: 767px) {
  .content-container-second li {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .content-container-second {
    flex-direction: column;
    width: 100%;
  }
}
.content-container-third {
  display: flex;
  justify-content: space-between;
}
.content-container-third li {
  width: 45%;
}
@media (max-width: 767px) {
  .content-container-third {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  [data-pages="career"] .page-header {
    background-image: url("../img/nyushi/index/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="career"] .page-header {
    background-image: url("../img/nyushi/index/header_bg@2x.jpg");
  }
}
.ui-template .anchorlinkL-container {
  justify-content: center;
}
[data-category="career"].pages {
  overflow: unset;
}
[data-category="career"] .c-pagenav--open-button {
  color: #5f1985;
}
[data-category="career"] .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%235f1985' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
@media (min-width: 768px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg-sp.jpg");
  }
}
[data-category="career"] .color_purple {
  color: #601986;
}
[data-category="career"] .color_green {
  color: #9ec238;
}
[data-category="career"] .color_blue {
  color: #1a86b9;
}
@media (min-width: 768px) {
  [data-category="career"] .flexPC .box-img {
    width: 47.874%;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .flexPC .box-text {
    width: 47.874%;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .item-where_work {
    display: flex;
  }
}
[data-category="career"] .list-where_work .heading4 {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .heading4 {
    width: 18%;
    max-width: 164px;
    line-height: 45px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .heading4 {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .heading4 {
    line-height: 3.29vw;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink {
    width: 34.1%;
    max-width: 320px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink {
    padding: 4vw 5.33vw 4vw 18vw;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) {
    margin-bottom: 5.33vw;
  }
}
[data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
  background-image: url("../img/career/icon-course.svg");
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
    background-size: 20px auto;
    background-position: center left;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
    background-position: center left 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
    background-size: 1.46vw auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) {
    margin-right: 2.93vw;
  }
}
[data-category="career"] .list-where_work .buttonlink:nth-of-type(2) .inner {
  background-image: url("../img/career/icon-graduates.svg");
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(2) .inner {
    background-size: 31px auto;
    background-position: center left;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(2) .inner {
    background-size: 2.27vw auto;
  }
}
[data-category="career"] .list-where_work .buttonlink .inner .text {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink .inner .text {
    padding-right: 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink .inner .text {
    padding-left: 10.67vw;
    text-align: left;
  }
}
[data-category="career"] .list-where_work .buttonlink.color_green {
  background: #9ec238;
  border: 1px solid #9ec238;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink.color_green:hover {
    background: #fff;
    border: 1px solid #9ec238;
  }
  [data-category="career"] .list-where_work .buttonlink.color_green:hover .inner .text {
    color: #9ec238;
  }
  [data-category="career"] .list-where_work .buttonlink.color_green:hover .inner .text::before {
    background-color: #9ec238;
  }
  [data-category="career"] .list-where_work .buttonlink.color_green:hover .inner .text::after {
    border-color: transparent transparent transparent #9ec238;
  }
}
[data-category="career"] .list-where_work .buttonlink.color_blue {
  background: #1a86b9;
  border: 1px solid #1a86b9;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover {
    background: #fff;
    border: 1px solid #1a86b9;
  }
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover .inner .text {
    color: #1a86b9;
  }
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover .inner .text::before {
    background-color: #1a86b9;
  }
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover .inner .text::after {
    border-color: transparent transparent transparent #1a86b9;
  }
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_purple:nth-of-type(1):hover .inner {
  background-image: url("../img/career/icon-course-purple.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_purple:nth-of-type(2):hover .inner {
  background-image: url("../img/career/icon-graduates-purple.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_green:nth-of-type(1):hover .inner {
  background-image: url("../img/career/icon-course-green.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_green:nth-of-type(2):hover .inner {
  background-image: url("../img/career/icon-graduates-green.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_blue:nth-of-type(1):hover .inner {
  background-image: url("../img/career/icon-course-blue.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_blue:nth-of-type(2):hover .inner {
  background-image: url("../img/career/icon-graduates-blue.svg");
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="employment-uv"] .box-img img,
  [data-category="career"] [data-career="employment-jc"] .box-img img {
    width: 100%;
    height: auto;
  }
}
[data-category="career"] [data-career="career-index"] .heading3 {
  color: #601986;
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
}
[data-category="career"] [data-career="career-index"] .link-content {
  text-align: right;
}
[data-category="career"] [data-career="career-index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-category="career"] [data-career="career-index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="career"] [data-career="career-index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="career"] [data-career="career-index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .box-img {
    overflow: hidden;
  }
  [data-category="career"] [data-career="career-index"] .box-img:hover img {
    transform: scale(1.1);
  }
  [data-category="career"] [data-career="career-index"] .box-img:hover +.box-text .link-container::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .box-img img {
    transition: transform 0.5s;
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .box-img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg@2x.jpg");
  }
}
[data-pages="career-employment-center"] .link-content {
  text-align: right;
}
[data-pages="career-employment-center"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="career-employment-center"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="career-employment-center"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="career-employment-center"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="career-employment-center"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="career-employment-center"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="career-employment-center"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="career-employment-center"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="career-employment-center"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="career-employment-center"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="career-employment-center"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="career-employment-center"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="career-employment-center"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="contact-list"] .page-header {
    background-image: url("../img/contact-list/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="contact-list"] .page-header {
    background-image: url("../img/contact-list/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="contact-list"] [data-contact-list="index"] .inquiry {
    min-width: 310px;
  }
}
@media (max-width: 767px) {
  [data-category="contact-list"] [data-contact-list="index"] .inquiry {
    width: 100% !important;
  }
}
[data-category="cooperation"] .box {
  width: auto;
}
@media (min-width: 768px) {
  [data-category="cooperation"] .page-header {
    background-image: url("../img/cooperation/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] .page-header {
    background-image: url("../img/cooperation/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .content-latest-container li.c-info_item {
  margin-bottom: 0;
}
[data-category="cooperation"] [data-cooperation="index"] .content-latest-container li.c-info_item a {
  padding-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
[data-category="cooperation"] [data-cooperation="index"] .content-latest-container .information-contnt-block a {
  color: #333;
}
[data-category="cooperation"] [data-cooperation="index"] .info-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-link-container {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-link-container {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-link-container {
    margin-top: 0.73vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .info-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link {
    font-size: 1.3rem;
  }
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .info-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .flexPC.-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .flexPC.-wrap .info-link-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 0;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item a {
    height: 100%;
    padding-bottom: 35px;
  }
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item a:hover .info-text-link::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item a {
    padding-bottom: 3.66vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title:before {
  content: "";
  display: inline-block;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title:before {
    width: 8px;
    height: 12px;
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
    font-size: 1.02vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
    font-size: 1.2rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
    font-size: 3.2vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
    font-size: 0.88vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item {
    width: calc(50% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .box-image {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-image {
    width: 300px;
    height: 400px;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .box-image img {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-image {
    width: 21.96vw;
    height: 29.28vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-text {
    width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-text {
    width: 43.92vw;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
  position: absolute;
  right: 0;
  background-color: #fff;
}
[data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate span {
  display: block;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
    bottom: 20px;
    padding: 12px 20px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
    bottom: 5.33vw;
    padding: 2.67vw 4.27vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .job {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .job {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .job {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .name {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .name {
    font-size: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
    bottom: 1.46vw;
    padding: 0.88vw 1.46vw;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .img-center {
  text-align: center;
}
[data-category="cooperation"] [data-cooperation="center"] .anchorlink:not(:last-of-type) {
  margin-right: 0;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="activities"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="activities"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="activities"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="collaborative"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="collaborative"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="collaborative"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content {
    padding: 70px 0 165px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content {
    padding: 8vw 0vw 34.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-container {
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-container {
    max-width: 79.06vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content {
    padding: 5.12vw 0vw 12.08vw;
  }
}
[data-pages="detail"] .content {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-section {
    padding: 0 120px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-container {
    max-width: 940px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-container {
    max-width: 68.81vw;
  }
}
[data-pages="detail"] .content-header {
  border-bottom-color: #601986;
  border-bottom-style: solid;
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header {
    padding-bottom: 25px;
    border-bottom-width: 4px;
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header {
    padding-bottom: 4.67vw;
    border-bottom-width: 0.8vw;
    margin-bottom: 7.33vw;
  }
}
[data-pages="detail"] .content-header h1 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
  line-height: 1.4;
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header h1 {
    font-size: 2.6rem;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header h1 {
    font-size: 5.6vw;
    margin-top: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header h1 {
    font-size: 1.9vw;
    margin-top: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header-inner.bottom {
    display: flex;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header-inner.bottom {
    margin-top: 4vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header-inner.bottom > p + p {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header-inner.bottom > p + p {
    margin-top: 2.67vw !important;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header-inner.bottom > p + p {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header-inner.bottom {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header-date {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header-date {
    font-size: 1.32vw;
  }
}
[data-pages="detail"] .content-header-place {
  margin-top: 0 !important;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header {
    padding-bottom: 1.83vw;
    border-bottom-width: 0.29vw;
    margin-bottom: 3.29vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container {
    padding: 3.33vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm2 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm2 > div,
  [data-pages="detail"] .l-container.clm2 > p {
    width: calc(50% - 10px);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container.clm2 > div + div,
  [data-pages="detail"] .l-container.clm2 > p + div,
  [data-pages="detail"] .l-container.clm2 > div + p,
  [data-pages="detail"] .l-container.clm2 > p + p {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm2 > div:nth-child(n+3),
  [data-pages="detail"] .l-container.clm2 > p:nth-child(n+3) {
    margin-top: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm2 > div:nth-child(n+3),
  [data-pages="detail"] .l-container.clm2 > p:nth-child(n+3) {
    margin-top: 1.83vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm2 > div,
  [data-pages="detail"] .l-container.clm2 > p {
    width: calc(50% - 0.73vw);
  }
}
[data-pages="detail"] .l-container.clm2.-is-reverse div:first-child,
[data-pages="detail"] .l-container.clm2.-is-reverse p:first-child {
  order: 1;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm3 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm3 > div,
  [data-pages="detail"] .l-container.clm3 > p {
    width: calc(33.33% - 13px);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container.clm3 > div + div,
  [data-pages="detail"] .l-container.clm3 > p + div,
  [data-pages="detail"] .l-container.clm3 > div + p,
  [data-pages="detail"] .l-container.clm3 > p + p {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm3 > div,
  [data-pages="detail"] .l-container.clm3 > p {
    width: calc(33.33% - 0.98vw);
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm4 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm4 > div,
  [data-pages="detail"] .l-container.clm4 > p {
    width: calc(25% - 11px);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container.clm4 > div + div,
  [data-pages="detail"] .l-container.clm4 > p + div,
  [data-pages="detail"] .l-container.clm4 > div + p,
  [data-pages="detail"] .l-container.clm4 > p + p {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm4 > div,
  [data-pages="detail"] .l-container.clm4 > p {
    width: calc(25% - 1.02vw);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container {
    padding: 1.1vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-inner {
    padding: 15px 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-inner {
    padding: 1.1vw 0vw;
  }
}
[data-pages="detail"] .l-header {
  border-bottom-color: #ccc;
  border-bottom-style: solid;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-header {
    padding-bottom: 18px;
    border-bottom-width: 2px;
    margin: 65px 0 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-header {
    padding-bottom: 4vw;
    border-bottom-width: 0.53vw;
    margin: 10vw 0vw 2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-header {
    padding-bottom: 1.32vw;
    border-bottom-width: 0.15vw;
  }
}
[data-pages="detail"] .l-heading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-heading {
    font-size: 2.2rem;
    line-height: 1.32;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-heading {
    font-size: 5.07vw;
    line-height: 1.39;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-heading {
    font-size: 1.61vw;
  }
}
[data-pages="detail"] .l-subheading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
  border-left-color: #601986;
  border-left-style: solid;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-subheading {
    font-size: 1.8rem;
    line-height: 18px;
    padding-bottom: 2px;
    padding-left: 10px;
    border-left-width: 4px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-subheading {
    font-size: 4.53vw;
    line-height: 4.8vw;
    padding-bottom: 0.53vw;
    padding-left: 2.67vw;
    border-left-width: 1.07vw;
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-subheading {
    font-size: 1.32vw;
    line-height: 1.32vw;
    padding-bottom: 0.15vw;
    padding-left: 0.73vw;
    border-left-width: 0.29vw;
    margin-bottom: 1.46vw;
  }
}
[data-pages="detail"] .l-image {
  width: 100%;
  text-align: center;
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-image img {
    width: 100%;
  }
}
[data-pages="detail"] .l-pragraph {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #333;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-pragraph {
    font-size: 1.5rem;
    line-height: 1.93;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-pragraph {
    font-size: 3.73vw;
    line-height: 1.86;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-pragraph {
    font-size: 1.1vw;
  }
}
[data-pages="detail"] .l-link {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.05em;
  background-repeat: no-repeat;
  background-image: url("../img/common/textlink_arrow.png");
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-link {
    font-size: 1.6rem;
    line-height: 1.81;
    background-size: 9px 15px;
    background-position: left 7px;
    padding-left: 15px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="detail"] .l-link:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-link {
    font-size: 3.73vw;
    line-height: 1.79;
    background-size: 2.13vw 3.33vw;
    background-position: left 1.33vw;
    padding-left: 3.33vw;
  }
}
[data-pages="detail"] .l-link[target=_blank]::after {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../img/common/icon_tab.svg");
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-link[target=_blank]::after {
    width: 17px;
    height: 16px;
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-link[target=_blank]::after {
    width: 4vw;
    height: 3.87vw;
    margin-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-link[target=_blank]::after {
    width: 1.24vw;
    height: 1.17vw;
    margin-left: 0.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-link {
    font-size: 1.17vw;
    background-size: 0.66vw 1.1vw;
    background-position: left 0.51vw;
    padding-left: 1.1vw;
  }
}
[data-pages="detail"] .back-link-container {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-container {
    margin-top: 55px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-container {
    margin-top: 10vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-container {
    margin-top: 4.03vw;
  }
}
[data-pages="detail"] .back-link-text {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text {
    font-size: 1.3rem;
  }
  [data-pages="detail"] .back-link-text:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="detail"] .back-link-text::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="detail"] .back-link-text::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text {
    font-size: 1.17vw;
  }
}
[data-pages="detail"] .images-withCaption {
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .images-withCaption {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption:not(._2col) .img {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption:not(._2col) .img {
    max-width: 43.92vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption._2col {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption._2col .img:nth-of-type(2) {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .images-withCaption._2col .img:nth-of-type(2) {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption._2col .img:nth-of-type(2) {
    margin-left: 1.46vw;
  }
}
[data-pages="detail"] .images-withCaption figcaption {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption figcaption {
    font-size: 15px;
    line-height: 2;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .images-withCaption figcaption {
    font-size: 3.73vw;
    line-height: 1.85;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption figcaption {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] .page-header {
    background-image: url("../img/english/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] .page-header {
    background-image: url("../img/english/header_bg-sp.jpg");
  }
}
[data-pages="english-lang"] .page-heading-en {
  display: none;
}
[data-pages="english-lang"] [data-english="index"].ui-template {
  padding-top: 0;
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"].ui-template {
    padding-left: 0;
    padding-right: 0;
  }
}
[data-pages="english-lang"] [data-english="index"].ui-template [class^="heading"].withBlock:before {
  height: 100%;
}
[data-pages="english-lang"] [data-english="index"] .paragraph {
  letter-spacing: normal;
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .container {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
  [data-pages="english-lang"] [data-english="index"] .container img {
    width: 100%;
    height: auto;
  }
}
[data-pages="english-lang"] [data-english="index"] .content-key_visual {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual .kv-img {
    margin-left: 23px;
    margin-bottom: 23px;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual .kv-img {
    margin-left: 6.4vw;
    margin-bottom: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual .kv-img {
    margin-left: 1.68vw;
    margin-bottom: 1.68vw;
  }
}
[data-pages="english-lang"] [data-english="index"] .heading1 {
  color: #5f1985;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 {
    font-size: 35px;
    margin-top: -28px;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 {
    font-size: 6.93vw;
  }
}
[data-pages="english-lang"] [data-english="index"] .heading1 .text {
  display: inline-block;
  background-color: #fff;
  padding: 0 5px;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 .text {
    margin-bottom: 18px;
    letter-spacing: 0.05em;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 .text {
    margin-bottom: 1.6vw;
    letter-spacing: -0.06em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 {
    font-size: 2.56vw;
    margin-top: -2.05vw;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .col_2 .text-box {
    width: 66%;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .col_2 .img-box {
    width: 31%;
  }
}
[data-pages="english-lang"] [data-english="index"] .flexPC:first-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .link-item .img {
    width: 450px;
    height: 300px;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .link-item .img img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="english-lang"] [data-english="index"] .link-item .img {
    width: 32.94vw;
    height: 21.96vw;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .link-item:hover img {
    transform: scale(1.1);
  }
  [data-pages="english-lang"] [data-english="index"] .link-item:hover .link-box {
    border-bottom: 2px solid #5f1985;
    opacity: 0.7;
  }
}
[data-pages="english-lang"] [data-english="index"] .link-item .link-box {
  border-bottom: 2px solid #ccc;
}
[data-pages="english-lang"] [data-english="index"] .link-item .link-box .text {
  display: block;
  color: #333;
  background-image: url("../img/english/arrow.svg");
  background-position: right bottom 20px;
  background-repeat: no-repeat;
  background-size: 21px 4px;
}
@media (min-width: 768px) {
  [data-category="english-lang"] .page-header {
    background-image: url("../img/english/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="english-lang"] .page-header {
    background-image: url("../img/english/header_bg@2x.jpg");
  }
}
[data-category="english-lang"] .page-heading-en {
  display: none;
}
@media (min-width: 768px) {
  [data-category="english-lang"] [data-english="career-support-center"] .flexPC.-col2.-reverse {
    flex-direction: row-reverse;
  }
}
[data-category="english-lang"] [data-english="career-support-center"] .withBorder.margin-lv1 {
  margin-bottom: 20px;
}
@media (max-width: 1365px) {
  [data-category="english-lang"] [data-english="career-support-center"] .withBorder.margin-lv1 {
    margin-bottom: 1.46vw;
  }
}
@media (max-width: 767px) {
  [data-category="english-lang"] [data-english="career-support-center"] .withBorder.margin-lv1 {
    margin-bottom: 3.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .page-header {
    background-image: url("../img/event/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .page-header {
    background-image: url("../img/event/header_bg@2x.jpg");
  }
}
[data-pages="event"] .content {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="event"] .content {
    padding: 70px 0 165px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content {
    padding: 13.33vw 0vw 33.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-container {
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-container {
    max-width: 79.06vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-pages="event"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-list + li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-list + li {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-list + li {
    margin-top: 1.46vw;
  }
}
[data-pages="event"] .content-archive-list a {
  color: #333;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-list a {
    display: flex;
    padding: 30px 20px 30px 10px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="event"] .content-archive-list a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-list a {
    display: block;
    padding: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-list a {
    padding: 2.2vw 1.46vw 2.2vw 0.73vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-header {
    width: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-right: 1px solid #ccc;
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-header {
    padding-bottom: 4vw;
    border-bottom: 0.53vw solid #ccc;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-header-inner {
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-header {
    width: 13.54vw;
    margin-right: 2.93vw;
  }
}
[data-pages="event"] .content-archive-date + .content-archive-date::before {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date + .content-archive-date::before {
    content: '|';
    font-size: 1.8rem;
    text-align: center;
    display: block;
    margin: 0.5rem auto;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date + .content-archive-date::before {
    content: 'ー';
    font-size: 5.07vw;
    display: inline-block;
    margin: 0 1rem;
    top: -1rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date + .content-archive-date::before {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .month {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .month {
    font-size: 6.13vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 1.76vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .month {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .day {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .day {
    font-size: 13.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .day {
    font-size: 2.93vw;
  }
}
[data-pages="event"] .content-archive-date .week {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .week {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .week {
    font-size: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .week {
    font-size: 1.32vw;
  }
}
[data-pages="event"] .content-archive-date .-is-comingsoon {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .-is-comingsoon {
    font-size: 1.8rem;
    line-height: 2.22;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .-is-comingsoon {
    font-size: 4.53vw;
    line-height: 3.06;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .-is-comingsoon {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-title {
    font-size: 1.6rem;
    line-height: 1.63;
    margin: 0 0 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-title {
    margin: 4vw 0 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-title {
    font-size: 1.17vw;
    margin: 0vw 0vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-data-inner {
    display: flex;
    align-items: center;
  }
}
[data-pages="event"] .content-archive-category {
  margin-top: 0 !important;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-place {
    margin-left: 30px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-place {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-place {
    margin-left: 2.2vw;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content {
    padding: 5.12vw 0vw 12.08vw;
  }
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .page-header {
    background-image: url("../img/for-examinees/examinees-index/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .page-header {
    background-image: url("../img/for-examinees/examinees-index/header_bg-sp.jpg");
  }
}
[data-pages="examinees-index"] .footer {
  border-top-width: 0;
}
[data-pages="examinees-index"] .content-for_menu-inner {
  padding: 80px 0 42px;
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .content-for_menu-inner {
    padding: 40px 0 100px;
  }
}
[data-pages="examinees-index"] .content-for_menu-inner .banner {
  display: block;
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .content-for_menu-inner .banner {
    margin-bottom: 40px;
    transition: opacity 0.2s ease-in-out;
  }
  [data-pages="examinees-index"] .content-for_menu-inner .banner:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .content-for_menu-inner .banner {
    margin-bottom: 18px;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-pen.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 105px;
    background-size: 78px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 73px;
    background-size: 54px auto;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-docx.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 115px;
    background-size: 58px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 76px;
    background-size: 47px auto;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-parents.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 104px;
    background-size: 76px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 76px;
    background-size: 57px auto;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-pc_sp.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 104px;
    background-size: 117px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 67px;
    background-size: 80px auto;
  }
}
.theme_color-purple {
  color: #5f1985;
}
.theme_color-lightgreen {
  color: #85b401;
}
.theme_color-lightblue {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-pages="faculty-top"] .page-header {
    background-image: url("../img/faculty/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="faculty-top"] .page-header {
    background-image: url("../img/faculty/header_bg-sp.jpg");
  }
}
[data-pages="faculty-top"] .ui-template {
  padding-top: 0;
}
@media (max-width: 767px) {
  [data-pages="faculty-top"] .ui-template {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="english"] .page-header {
    background-image: url("../img/faculty/english/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="english"] .page-header {
    background-image: url("../img/faculty/english/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="english"] .c-department_top {
    background-image: url("../img/faculty/english/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="english"] .c-department_top {
    background-image: url("../img/faculty/english/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="english"] .career-graph .number-box {
    top: 29%;
    left: 40%;
  }
}
@media (max-width: 767px) {
  [data-pages="english"] .career-graph .number-box {
    top: 28%;
    left: 36%;
  }
}
@media (max-width: 1365px) {
  [data-pages="english"] .career-graph img {
    width: 39.02vw;
  }
}
@media (min-width: 768px) {
  [data-pages="japanese"] .page-header {
    background-image: url("../img/faculty/japanese/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="japanese"] .page-header {
    background-image: url("../img/faculty/japanese/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="japanese"] .c-department_top {
    background-image: url("../img/faculty/japanese/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="japanese"] .c-department_top {
    background-image: url("../img/faculty/japanese/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="japanese"] .career-graph .number-box {
    top: 29%;
    left: 40%;
  }
}
@media (max-width: 767px) {
  [data-pages="japanese"] .career-graph .number-box {
    top: 26%;
    left: 37%;
  }
}
@media (max-width: 1365px) {
  [data-pages="japanese"] .career-graph img {
    width: 38.14vw;
  }
}
@media (min-width: 768px) {
  [data-pages="society"] .page-header {
    background-image: url("../img/faculty/society/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="society"] .page-header {
    background-image: url("../img/faculty/society/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="society"] .c-department_top {
    background-image: url("../img/faculty/society/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="society"] .c-department_top {
    background-image: url("../img/faculty/society/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="society"] .career-graph .number-box {
    top: 28.5%;
    left: 38%;
  }
}
@media (max-width: 767px) {
  [data-pages="society"] .career-graph .number-box {
    top: 27%;
    left: 36%;
  }
}
@media (max-width: 1365px) {
  [data-pages="society"] .career-graph img {
    width: 36.16vw;
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .page-header {
    background-image: url("../img/faculty/childhood/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .page-header {
    background-image: url("../img/faculty/childhood/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .c-department_top {
    background-image: url("../img/faculty/childhood/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .c-department_top {
    background-image: url("../img/faculty/childhood/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .career-graph-1 .number-box {
    top: 29%;
    left: 41%;
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .career-graph-1 .number-box {
    top: 27%;
    left: 38%;
  }
}
@media (max-width: 1365px) {
  [data-pages="childhood"] .career-graph-1 img {
    width: 37.04vw;
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .career-graph-2 .number-box {
    top: 29%;
    left: 39%;
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .career-graph-2 .number-box {
    top: 27%;
    left: 34%;
  }
}
@media (max-width: 1365px) {
  [data-pages="childhood"] .career-graph-2 img {
    width: 36.24vw;
  }
}
@media (min-width: 768px) {
  [data-pages="nutrition"] .page-header {
    background-image: url("../img/faculty/nutrition/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="nutrition"] .page-header {
    background-image: url("../img/faculty/nutrition/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="nutrition"] .c-department_top {
    background-image: url("../img/faculty/nutrition/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="nutrition"] .c-department_top {
    background-image: url("../img/faculty/nutrition/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="nutrition"] .career-graph .number-box {
    top: 30%;
    left: 39%;
  }
}
@media (max-width: 767px) {
  [data-pages="nutrition"] .career-graph .number-box {
    top: 27%;
    left: 35%;
  }
}
@media (max-width: 1365px) {
  [data-pages="nutrition"] .career-graph img {
    width: 36.68vw;
  }
}
@media (min-width: 768px) {
  [data-pages="early-childhood"] .page-header {
    background-image: url("../img/faculty/early-childhood/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="early-childhood"] .page-header {
    background-image: url("../img/faculty/early-childhood/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="early-childhood"] .c-department_top {
    background-image: url("../img/faculty/early-childhood/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="early-childhood"] .c-department_top {
    background-image: url("../img/faculty/early-childhood/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="early-childhood"] .career-graph .number-box {
    top: 37%;
    left: 38%;
  }
}
@media (max-width: 767px) {
  [data-pages="early-childhood"] .career-graph .number-box {
    top: 35%;
    left: 33%;
  }
}
@media (max-width: 1365px) {
  [data-pages="early-childhood"] .career-graph img {
    width: 39.82vw;
  }
}
[data-faculty="bungakubu"] .ui-template .list-withDot li::before {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-faculty="bungakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-purple-pc.svg");
  }
}
@media (max-width: 767px) {
  [data-faculty="bungakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-purple-sp.svg");
  }
}
@media (min-width: 768px) {
  [data-faculty="bungakubu"] .c-department_top.return::before {
    background-image: url("../img/faculty/bg_color-purple-re-pc.svg");
  }
}
[data-faculty="eiyokagakubu"] .page-heading {
  color: #85b401;
}
[data-faculty="eiyokagakubu"] .page-heading-en {
  color: #85b401;
}
[data-faculty="eiyokagakubu"] .page-heading-faculty {
  color: #85b401;
}
[data-faculty="eiyokagakubu"] .ui-template .list-withDot li::before {
  background-color: #85b401;
}
[data-faculty="eiyokagakubu"] .ui-template .withBlock::before {
  background-color: #85b401;
}
[data-faculty="eiyokagakubu"] .ui-template .withBorder:before {
  background: #85b401;
}
@media (min-width: 768px) {
  [data-faculty="eiyokagakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightgreen-pc.svg");
  }
}
@media (max-width: 767px) {
  [data-faculty="eiyokagakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightgreen-sp.svg");
  }
}
[data-faculty="jc"] .ui-template * {
  font-family: 'Kosugi Maru', sans-serif;
  font-weight: 400;
}
[data-faculty="jc"] .page-heading {
  font-family: 'Kosugi Maru', sans-serif;
  color: #1886ba;
}
[data-faculty="jc"] .page-heading-en {
  font-family: 'Kosugi Maru', sans-serif;
  color: #1886ba;
}
[data-faculty="jc"] .page-heading-faculty {
  font-family: 'Kosugi Maru', sans-serif;
  color: #1886ba;
}
[data-faculty="jc"] .ui-template .list-withDot li::before {
  background-color: #1886ba;
}
[data-faculty="jc"] .ui-template .withBlock::before {
  background-color: #1886ba;
}
[data-faculty="jc"] .ui-template .withBorder:before {
  background: #1886ba;
}
@media (min-width: 768px) {
  [data-faculty="jc"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightblue-pc.svg");
  }
}
@media (max-width: 767px) {
  [data-faculty="jc"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightblue-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #5f1985;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #5f1985;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .faculty_heading {
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .faculty_heading::after {
  background: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(1) {
    background-image: url("../img/faculty/faculty-top/img-english-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(1) {
    background-image: url("../img/faculty/faculty-top/img-english-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(2) {
    background-image: url("../img/faculty/faculty-top/img-japanese-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(2) {
    background-image: url("../img/faculty/faculty-top/img-japanese-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(3) {
    background-image: url("../img/faculty/faculty-top/img-society-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(3) {
    background-image: url("../img/faculty/faculty-top/img-society-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(4) {
    background-image: url("../img/faculty/faculty-top/img-childhood-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(4) {
    background-image: url("../img/faculty/faculty-top/img-childhood-sp.jpg");
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .department-heading-en {
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-other_link .list-policy li {
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-other_link .list-policy li::before {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-left::before {
    background-image: url("../img/faculty/faculty-top/color-purple.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-left::before {
    background-image: url("../img/faculty/faculty-top/color-purple-sp.svg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-right::before {
    background-image: url("../img/faculty/faculty-top/color-purple-re.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-right::before {
    background-image: url("../img/faculty/faculty-top/color-purple-re-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-voice .text-heading {
  color: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-voice li:nth-of-type(odd) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-purple.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-voice li:nth-of-type(even) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-purple-re.svg") no-repeat center/contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-purple-under.svg") no-repeat center/contain;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .withBorder::before {
  background: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .faculty_heading {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .faculty_heading::after {
  background: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-nutrition-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-nutrition-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-lightgreen.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-lightgreen-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block .department-heading-en {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #85b401;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #85b401;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-other_link .list-policy li {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-other_link .list-policy li::before {
  background-color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-lightgreen.svg") no-repeat;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice .text-heading {
  color: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice li:nth-of-type(odd) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightgreen.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice li:nth-of-type(even) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightgreen-re.svg") no-repeat center/contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightgreen-under.svg") no-repeat center/contain;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .c-info_item .info_item--category span {
  color: #739c00;
  border: 1px solid #739c00;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question p {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question p:before {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question:before,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question:after {
  background: #85b401;
}
[data-category="faculty"] [data-faculty="jc"] .withBorder::before {
  background: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .container-inner {
  background-color: #a3cfe3;
}
[data-category="faculty"] [data-faculty="jc"] .faculty_heading {
  color: #fff;
}
[data-category="faculty"] [data-faculty="jc"] .faculty_heading::after {
  background: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-jc-1-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-jc-1-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-blue.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-blue-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="jc"] .department-block .department-heading-en {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #1886ba;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="faculty"] [data-faculty="jc"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1886ba;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] [data-faculty="jc"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .block-other_link .list-policy li {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .block-other_link .list-policy li::before {
  background-color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-lightblue.svg") no-repeat;
}
[data-category="faculty"] [data-faculty="jc"] .list-voice .text-heading {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-voice li:nth-of-type(odd) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightblue.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-voice li:nth-of-type(even) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightblue-re.svg") no-repeat center/contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightblue-under.svg") no-repeat center/contain;
  }
}
[data-category="faculty"] [data-faculty="jc"] .c-info_item .info_item--category span {
  border: 1px solid #1886ba;
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question p {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question p:before {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question:before,
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question:after {
  background: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power li {
  background-color: #1886ba;
  border-radius: 24px;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power .heading {
  color: #fff;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power .paragraph {
  color: #fff;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power .border::before {
  background: #fff;
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link span,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span,
[data-category="faculty"] [data-faculty="jc"] .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span,
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 2.67vw;
    letter-spacing: -0.01em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after,
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: 2.13vw 0vw 0vw 2.13vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
[data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
  margin-left: 0.3em;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
    width: 18px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
    width: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
    width: 1.32vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .table-container {
    overflow-x: auto;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion {
  background: #f5f6f8;
  padding: 0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding: 5.33vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question p,
[data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 40px;
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 2.93vw;
    padding-right: 4.39vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding: 2.93vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question {
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question:hover {
  opacity: 0.6;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
[data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
  margin-top: 0;
  content: "";
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
    right: 40px;
    width: 20px;
    height: 3px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
    right: 5.33vw;
    width: 2.67vw;
    height: 0.53vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question.opend:after {
  transform: translateY(-50%) rotate(0);
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question p {
  line-height: 1.6;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
  content: "Q";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
    font-size: 2.93vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .answer {
  display: none;
  border-top: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 5.33vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
  margin-top: 0;
  line-height: 1.9;
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-right: 0;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
  content: "A";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
    font-size: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 2.93vw;
  }
}
[data-category="faculty"] a.block-other_link:hover {
  opacity: 0.6;
}
[data-category="faculty"] a.block-other_link:hover .link-container .text-link::after {
  transform: translate3d(10px, 0, 0);
}
@media (max-width: 767px) {
  [data-category="faculty"] a.block-other_link {
    margin: 0 2.67vw;
  }
}
[data-category="faculty"] .only-link .text-link:hover {
  opacity: 0.6;
}
[data-category="faculty"] .scroll_anime {
  transition: all 1s ease 0.2s;
}
[data-category="faculty"] .scroll_anime.fuwa-up {
  transform: translateY(15px);
  opacity: 0;
}
[data-category="faculty"] .scroll_anime.fuwa-up.scroll__start {
  transform: translateY(0px);
  opacity: 1;
}
@media (min-width: 768px) {
  [data-category="faculty"] .float-right {
    float: right;
    margin-left: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .float-right {
    margin-left: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .float-left {
    float: left;
    margin-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .float-left {
    margin-right: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .margin-bottom {
    margin-bottom: 52px;
  }
}
[data-category="faculty"] .margin-bottom.-center {
  text-align: center;
}
@media (max-width: 767px) {
  [data-category="faculty"] .margin-bottom {
    margin-bottom: 6.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .margin-bottom {
    margin-bottom: 3.81vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .col2-pc_only {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .col2-pc_only.return {
    flex-direction: row-reverse;
  }
  [data-category="faculty"] .col2-pc_only .box {
    width: 450px;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .col2-pc_only img {
    width: 32.94vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .col2-pc_only img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .col2-pc_only .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .col3-pc_only {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .col3-pc_only .box {
    width: 340px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .col3-pc_only .box {
    width: 24.89vw;
  }
}
[data-category="faculty"] .text-indent {
  text-indent: 1em;
}
[data-category="faculty"] .highlight {
  font-weight: bold;
  border-bottom: 2px dotted #601986;
}
[data-category="faculty"] .bg-table-early-childhood {
  background-color: #bee1f0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .line {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .line-2 {
    height: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .line-2 {
    height: 5.86vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .line-3 {
    height: 100px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .line-3 {
    height: 7.32vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .line-4 {
    height: 120px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .line-4 {
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .content-faculty_top .container-inner {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .content-faculty_top .container-inner {
    padding: 13.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .content-faculty_top .container-inner {
    padding: 4.39vw 0vw;
  }
}
[data-category="faculty"] .faculty_heading {
  position: relative;
  text-align: center;
  padding-bottom: 25px;
}
[data-category="faculty"] .faculty_heading::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  content: "";
  width: 50px;
  height: 1px;
}
[data-category="faculty"] .department-block {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block {
    height: 460px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block {
    height: 106.667vw;
  }
}
[data-category="faculty"] .department-block .text {
  color: #333;
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block .text {
    font-size: 3.2vw;
  }
}
[data-category="faculty"] .department-block .department-block-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block .department-block-inner {
    width: 460px;
    height: 340px;
  }
  [data-category="faculty"] .department-block .department-block-inner:hover {
    background-color: rgba(255,255,255,0.85);
  }
  [data-category="faculty"] .department-block .department-block-inner:hover .text-link::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block .department-block-inner {
    height: 64vw;
    padding: 5.33vw;
    transform: translateX(-50%);
    left: 50%;
    width: 77.33vw;
    bottom: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block .department-block-inner {
    width: 33.67vw;
    height: 24.89vw;
  }
}
[data-category="faculty"] .department-block::before {
  display: block;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block {
    height: 33.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block.position-left .department-block-inner {
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
    padding: 54px 50px 60px 58px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block.position-left .department-block-inner {
    left: 5.86vw;
    padding: 2.93vw 3.51vw;
  }
}
[data-category="faculty"] .department-block.position-left::before {
  background-position: center right;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block.position-right .department-block-inner {
    top: 50%;
    transform: translateY(-50%);
    right: 80px;
    padding: 54px 50px 40px 48px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block.position-right .department-block-inner {
    right: 5.86vw;
    padding: 3.95vw 3.66vw 2.93vw 3.51vw;
  }
}
[data-category="faculty"] .department-block.position-right::before {
  margin: 0 0 0 auto;
  background-position: center left;
}
[data-category="faculty"] .department-block .department-heading-ja {
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block .department-heading-ja {
    margin-bottom: 16px;
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block .department-heading-ja {
    margin-bottom: 2.93vw;
    font-size: 6.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block .department-heading-ja {
    margin-bottom: 1.17vw;
    font-size: 2.64vw;
  }
}
[data-category="faculty"] .department-block .department-heading-en {
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1.6;
}
@media (min-width: 768px) {
  [data-category="faculty"] .message-container .withBorder {
    margin-bottom: 54px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .message-container .withBorder {
    margin-bottom: 3.95vw;
  }
}
[data-category="faculty"] .link-container--back {
  text-align: center;
}
[data-category="faculty"] .link-container--back .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #601986 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #5f1985;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link.-bungakubu:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #5f1985;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #5f1985 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #85b401;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #85b401;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #85b401 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-jc {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #1886ba;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-jc {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link.-jc:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-jc {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-jc::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1886ba;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link.-jc::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #1886ba transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-jc::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-jc::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-jc::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link.-jc span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-jc span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-jc span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-jc span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-jc {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .link-container {
  text-align: right;
}
[data-category="faculty"] .c-dean_nameplate {
  position: absolute;
  right: -1px;
  background-color: #fff;
}
[data-category="faculty"] .c-dean_nameplate span {
  display: block;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-dean_nameplate {
    bottom: 20px;
    padding: 12px 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-dean_nameplate {
    bottom: 5.33vw;
    padding: 2.67vw 4.27vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-dean_nameplate .job {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-dean_nameplate .job {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-dean_nameplate .job {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-dean_nameplate .name {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-dean_nameplate .name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-dean_nameplate .name {
    font-size: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-dean_nameplate {
    bottom: 1.46vw;
    padding: 0.88vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message .box-image {
    position: relative;
    width: 300px;
    height: 400px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-image {
    position: relative;
    margin-bottom: 5.33vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-image img {
    width: 21.96vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-image img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-image {
    width: 21.96vw;
    height: 29.28vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message .box-text {
    width: 600px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-text .heading {
    text-align: center;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-text {
    width: 43.92vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-list-class_introduction .heading {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-list-class_introduction .heading {
    margin-bottom: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-list-class_introduction .heading {
    margin-bottom: 0.73vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-list-class_introduction .box-img {
    margin-bottom: 5.33vw;
  }
}
[data-category="faculty"] .c-department_top {
  position: relative;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top {
    height: 300px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top {
    height: 114.93vw;
  }
}
[data-category="faculty"] .c-department_top .c-department_top-inner {
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top .c-department_top-inner {
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top .c-department_top-inner {
    padding: 0vw 3.47vw;
    bottom: 12vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-category="faculty"] .c-department_top .c-department_top-text {
  color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top .c-department_top-text {
    width: 50%;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top .c-department_top-text {
    font-size: 4vw;
    letter-spacing: normal;
  }
}
[data-category="faculty"] .c-department_top::before {
  display: block;
  content: "";
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top::before {
    background-position: center left;
    background-size: contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top::before {
    background-position: bottom center;
    background-size: 110% 51%;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top.return::before {
    background-position: center right;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top.return .c-department_top-text {
    width: 42%;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-department_top {
    height: 21.96vw;
  }
}
[data-category="faculty"] .block-department {
  padding-top: 60px;
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-department {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .withBlock_list {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .withBlock_list {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .withBlock_list {
    margin-left: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-list {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .wrap-list .wrap-list-inner {
    width: 46%;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  [data-category="faculty"] .list-info::after {
    content: "";
    display: block;
    width: 340px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-info .information-contnt-block {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-info .information-contnt-block {
    margin-bottom: 11.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-info .information-contnt-block {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
[data-category="faculty"] .list-info .information-contnt-block-date {
  color: #333;
}
[data-category="faculty"] .list-info .information-contnt-block-title {
  color: #333;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-info::after {
    width: 24.89vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power {
    display: flex;
    justify-content: space-between;
  }
}
[data-category="faculty"] .list-learning_power li {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power li {
    padding: 30px 20px 26px;
    width: 300px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-learning_power li {
    padding: 9.07vw 10vw 10.4vw;
  }
  [data-category="faculty"] .list-learning_power li:not(:first-of-type) {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-learning_power li {
    padding: 2.2vw 1.46vw 1.9vw;
    width: 21.96vw;
  }
}
[data-category="faculty"] .list-learning_power .heading {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power .heading {
    min-height: 70px;
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-learning_power .heading {
    margin-bottom: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-learning_power .heading {
    min-height: 5.12vw;
    margin-bottom: 0.59vw;
  }
}
[data-category="faculty"] .list-learning_power .border::before {
  content: "";
  display: block;
  height: 1px;
  background: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power .border::before {
    width: 20px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-learning_power .border::before {
    width: 5.33vw;
    margin: 0vw auto 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-learning_power .border::before {
    width: 1.46vw;
    margin: 0vw auto 1.46vw;
  }
}
[data-category="faculty"] .list-features {
  background-color: #f5f6f8;
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features {
    padding-bottom: 5.33vw;
    width: 100vw;
    margin-left: -5.333vw;
  }
}
[data-category="faculty"] .list-features li {
  position: relative;
}
[data-category="faculty"] .list-features .area-vertical .text-Q {
  display: block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .area-vertical .text-Q {
    margin-left: 0.5em;
    text-align: center;
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .area-vertical .text-Q {
    font-size: 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .area-vertical .text-Q {
    font-size: 3.66vw;
  }
}
[data-category="faculty"] .list-features .area-vertical .text {
  margin: auto;
  display: block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .area-vertical .text {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}
[data-category="faculty"] .list-features .box-text {
  position: relative;
  background-color: #fff;
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .box-text {
    width: 94.667%;
    padding: 5.33vw;
  }
  [data-category="faculty"] .list-features .box-text .headeing3 {
    margin-bottom: 8.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .box-text .area-answer {
    width: 666px;
  }
}
[data-category="faculty"] .list-features .box-text .area-answer .answer-main {
  display: table;
  margin-bottom: 30px;
}
[data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
  display: table-cell;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
    display: block;
    font-size: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .box-text .area-answer {
    width: 48.76vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .box-text .text {
    margin-bottom: 26px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .box-text .text {
    margin-bottom: 5.33vw;
  }
}
[data-category="faculty"] .list-features .box-text .text:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .box-text .text {
    margin-bottom: 1.9vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-1 .box-img img {
    width: 68.81vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-1 .box-img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .item-1 .box-text {
    display: flex;
    justify-content: space-between;
    width: 860px;
    margin: -60px 0 auto auto;
    padding: 40px 0 14px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-1 .box-text {
    margin: -38.67vw 0vw 0vw auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-1 .box-text {
    width: 62.96vw;
    margin: -4.39vw 0vw auto auto;
    padding: 2.93vw 0vw 1.02vw 2.93vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-1 .area-vertical {
    margin-bottom: 4.8vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-2 .box-img img {
    width: 57.1vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-2 .box-img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .item-2 .area-vertical {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-2 .area-vertical {
    position: relative;
    margin: -38.67vw 0vw 0vw;
    width: 94.667%;
    padding: 5.33vw 5.33vw 0vw 5.33vw;
    background-color: #fff;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-2 .area-vertical {
    width: 11.71vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .item-2 .box-text {
    margin-top: -60px;
    padding: 35px 60px 38px 40px;
    width: 700px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-2 .box-text {
    margin-top: -4.39vw;
    padding: 2.56vw 4.39vw 2.78vw 2.93vw;
    width: 51.24vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .content-career .section-point .withBorder {
    margin-bottom: 56px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .content-career .section-point .withBorder {
    margin-bottom: 4.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .content-career .section-voice .withBorder {
    margin-bottom: 90px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .content-career .section-voice .withBorder {
    margin-bottom: 6.59vw;
  }
}
[data-category="faculty"] .wrap-other_link {
  margin: 0 auto;
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-other_link {
    width: 932px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .wrap-other_link {
    width: 68.23vw;
  }
}
[data-category="faculty"] .block-other_link {
  background-color: #f5f6f8;
  position: relative;
  display: block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-other_link {
    padding: 20px;
    width: 450px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link {
    padding: 3.2vw 3.2vw 3.2vw 4.8vw;
  }
  [data-category="faculty"] .block-other_link:nth-of-type(2) {
    margin-top: 5.33vw;
  }
}
[data-category="faculty"] .block-other_link.dean_message {
  display: flex;
}
[data-category="faculty"] .block-other_link.dean_message .heading {
  color: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-other_link.dean_message .heading {
    margin-left: 25px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link.dean_message .heading {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-other_link.dean_message .heading {
    margin-left: 1.83vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .block-other_link.dean_message img {
    width: 7.32vw;
    height: 7.32vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link.dean_message img {
    width: 18.93vw;
    height: 18.93vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link .list-policy li {
    font-size: 3.2vw;
  }
}
[data-category="faculty"] .block-other_link .link-container {
  position: absolute;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-other_link .link-container {
    bottom: 20px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link .link-container {
    bottom: 2.67vw;
    right: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-other_link .link-container {
    bottom: 1.46vw;
    right: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-other_link {
    width: 32.94vw;
    padding: 1.46vw;
  }
}
[data-category="faculty"] .list-voice li {
  position: relative;
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li {
    padding: 36px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice li {
    padding: 17.6vw 2.67vw 2.67vw;
    margin-top: 13.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li {
    padding: 2.64vw 2.93vw;
  }
}
[data-category="faculty"] .list-voice .text-heading {
  text-align: center;
  color: #601986;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .text-heading {
    margin-left: 50px;
    width: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .text-heading {
    width: 36.6vw;
    margin-left: 3.66vw;
  }
}
[data-category="faculty"] .list-voice .wrap-number {
  position: absolute;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .wrap-number {
    top: -30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .wrap-number {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number_bg-under.svg") no-repeat center/contain;
    width: 21.33vw;
    height: 24.27vw;
  }
}
[data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
    font-size: 40px;
    bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
    font-size: 8vw;
    bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
    font-size: 2.93vw;
    bottom: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .wrap-number {
    top: -2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .box-img {
    position: absolute;
    top: -30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .box-img {
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .box-img {
    top: -2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .box-img img {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .box-img img {
    width: 100%;
    height: auto;
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .box-img img {
    margin-bottom: 1.17vw;
    width: 19.03vw;
    height: auto;
  }
}
[data-category="faculty"] .list-voice .text-voice {
  border-radius: 20px;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .text-voice {
    padding: 34px 40px;
    width: 580px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .text-voice {
    padding: 2.67vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .text-voice {
    padding: 2.49vw 2.93vw;
    width: 42.46vw;
  }
}
[data-category="faculty"] .list-voice .youtube {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube {
    padding: 0 40px 0 160px;
    margin-top: -40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .youtube {
    padding: 17.6vw 0vw 2.67vw;
  }
}
[data-category="faculty"] .list-voice .youtube .text-heading {
  text-align: left;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube .text-heading {
    margin-left: 0;
    width: 100%;
  }
}
[data-category="faculty"] .list-voice .youtube .box-youtube {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube .box-youtube {
    padding-top: 48.25%;
    width: 540px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .youtube .box-youtube {
    width: 100%;
    padding-top: 56.25%;
  }
}
[data-category="faculty"] .list-voice .youtube .box-youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .youtube .box-youtube {
    width: 39.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube .wrap-number {
    top: 0;
    right: auto;
    left: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .youtube .wrap-number {
    left: -1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .youtube {
    padding: 0vw 2.93vw 0vw 11.71vw;
    margin-top: -2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .box-img {
    right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .box-img {
    right: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .wrap-number {
    left: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .wrap-number {
    left: -1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .box-img {
    left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .box-img {
    left: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .text-heading {
    margin-right: 50px;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .text-heading {
    margin-right: 3.66vw;
  }
}
[data-category="faculty"] .list-voice li:nth-of-type(even) .text-voice {
  margin-left: auto;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .wrap-number {
    right: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .wrap-number {
    right: -1.46vw;
  }
}
[data-category="faculty"] .block-learning_support {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-learning_support {
    padding: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-learning_support {
    padding: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-learning_support {
    padding: 16px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="faculty"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item a:hover .info_item--image img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
[data-category="faculty"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--image {
    height: 220px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--image img {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--image {
    height: 16.11vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--date {
  color: #333;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--date {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--date {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--date {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--category {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--category {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--category {
    margin-left: 2.67vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--category span {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
  color: #601986;
  border: 1px solid #601986;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--category span {
    font-size: 1.2rem;
    padding: 3px 10px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--category span {
    font-size: 3.2vw;
    padding: 1.33vw;
    min-width: 24.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--category span {
    font-size: 0.88vw;
    padding: 0.22vw 0.73vw;
    min-width: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--category {
    margin-left: 1.46vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--title {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .contents-field {
    width: 640px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .contents-field {
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .contents-field {
    width: 46.85vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-career-graph {
    display: flex;
    align-self: center;
  }
}
[data-category="faculty"] .wrap-career-graph .percentage {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 300;
}
[data-category="faculty"] .career-graph {
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph {
    width: 600px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph .big {
    font-size: 85px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph .big {
    font-size: 17.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph .big {
    font-size: 6.22vw;
  }
}
[data-category="faculty"] .career-graph .sml {
  letter-spacing: -0.04em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph .sml {
    font-size: 55px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph .sml {
    font-size: 10.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph .sml {
    font-size: 4.03vw;
  }
}
[data-category="faculty"] .career-graph .number-box {
  position: absolute;
  text-align: center;
}
[data-category="faculty"] .career-graph .number-box .number-box--inner {
  margin: 0 auto;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph .number-box .number-box--inner {
    width: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph .number-box .number-box--inner {
    width: 29.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph .number-box .number-box--inner {
    width: 11.71vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .career-graph img {
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph {
    width: 43.92vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-career-detail {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail {
    display: flex;
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .big {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .big {
    font-size: 12vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .big {
    font-size: 4.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .sml {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .sml {
    font-size: 7.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .sml {
    font-size: 2.64vw;
  }
}
[data-category="faculty"] .career-detail .brackets {
  white-space: nowrap;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item:not(:first-of-type) {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item:not(:first-of-type) {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item .year {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item .year {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item .year {
    margin-bottom: 0.59vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item {
    text-align: center;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .career-year {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 10.67vw;
  }
}
[data-category="faculty"] .career-area {
  border: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-area {
    max-width: 186px;
    padding: 24px 30px;
    margin-left: 37px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area {
    width: 100%;
    padding: 6.4vw 8vw;
    margin-top: 5.87vw;
  }
}
[data-category="faculty"] .career-area .lead {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-area .lead {
    margin-bottom: 0.5em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area .lead {
    text-align: center;
    margin-bottom: 5.33vw;
  }
}
[data-category="faculty"] .career-area .career-area--item .area,
[data-category="faculty"] .career-area .career-area--item .percentage {
  display: inline-block;
  vertical-align: baseline;
  line-height: 2;
  font-weight: 400;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-area .career-area--item .area,
  [data-category="faculty"] .career-area .career-area--item .percentage {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area .career-area--item .area,
  [data-category="faculty"] .career-area .career-area--item .percentage {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-area .career-area--item .area,
  [data-category="faculty"] .career-area .career-area--item .percentage {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .career-area .career-area--item .area {
  margin-right: 0.2em;
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area .wrap-box_sp {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .career-area .wrap-box_sp .box_sp {
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-area {
    max-width: 13.62vw;
    padding: 1.76vw 2.2vw;
    margin-left: 2.71vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .liquid_image-mid img {
    width: 68.81vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .liquid_image-mid img {
    width: 100%;
    height: auto;
  }
}
[data-category="faculty"] .scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
}
@media (min-width: 768px) {
  [data-category="faculty"] .scroll-container {
    padding-bottom: 20px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .scroll-container {
    padding-bottom: 2.67vw;
    margin-bottom: 1.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .scroll-container .box-img {
    width: 1820px;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .scroll-container .box-img {
    width: 482.67vw;
    height: 153.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .scroll-container .box-img {
    width: 133.24vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .scroll-container {
    padding-bottom: 1.46vw;
    margin-bottom: 0.37vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] .page-header {
    background-image: url("../img/for-alumni/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] .page-header {
    background-image: url("../img/for-alumni/header_bg@2x.jpg");
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block a {
  color: #333;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block-image {
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block-image {
    height: 57.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block-image {
    height: 14.64vw;
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-link-container {
    margin-top: 8vw;
    margin-right: -8vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li.c-info_item {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-link-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-link-container {
    margin-top: 30px;
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    font-size: 1.3rem;
  }
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink {
    transition: opacity 0.2s;
  }
  [data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink:hover {
    opacity: 0.6;
  }
}
[data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink:after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
  transform: rotate(90deg);
  transform-origin: center center;
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink:after {
    width: 2.13vw;
    height: 3.2vw;
    margin-left: 1.6vw;
  }
}
[data-category="for-examinees"] ._temp_adjustment {
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="for-examinees"] ._temp_adjustment {
    margin-top: -15px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-examinees"] ._temp_adjustment {
    margin-top: -1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-students"] .page-header {
    background-image: url("../img/for-students/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="for-students"] .page-header {
    background-image: url("../img/for-students/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="for-students"] .-half-wrap {
    margin-left: 50%;
  }
}
[data-category="for-students"] .-is-inline {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="for-students"] [data-for-students="student-dp-uv"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="for-students"] [data-for-students="student-dp-uv"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-students"] [data-for-students="student-dp-uv"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-students"] .course-number_table tr:nth-of-type(23) td:nth-of-type(2) {
    width: 40%;
  }
}
@media (max-width: 767px) {
  [data-category="for-students"] .course-number_table tr:nth-of-type(23) td:nth-of-type(2) {
    width: 50%;
  }
}
@media (min-width: 768px) {
  [data-category="home"] .page-header {
    background-image: url("../img/home/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="home"] .page-header {
    background-image: url("../img/home/header_bg@2x.jpg");
    background-position: right top;
  }
}
@media (min-width: 768px) {
  [data-category="houjin"] .page-header {
    background-image: url("../img/houjin/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="houjin"] .page-header {
    background-image: url("../img/houjin/header_bg@2x.jpg");
    background-position: right top;
  }
}
[data-pages="houjin-disclosure-172-2"] span.-is-ib {
  display: inline-block;
}
@media (max-width: 767px) {
  [data-pages="houjin-disclosure-172-2"] span.-is-ib {
    margin-top: 3.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .main {
    padding-bottom: 130px;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .main {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .main {
    padding-bottom: 34.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .main {
    padding-bottom: 9.52vw;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .header-logo-container.l-sp {
    display: none;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .header-logo-container.l-pc {
    display: flex;
  }
}
[data-pages="top"] .keyVisual-container {
  position: relative;
}
@media (min-width: 1100px) {
  [data-pages="top"] .keyVisual-container {
    height: 48.83vw;
    overflow: hidden;
  }
  [data-pages="top"] .keyVisual-container iframe {
    height: 50.83vw;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .keyVisual-container {
    height: 56.22vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container {
    height: 55.6vw;
  }
}
[data-pages="top"] .keyVisual-container.-is-slide {
  height: auto;
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container.-is-slide {
    padding-top: 16vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container.-is-slide .keyVisual-logo {
    position: absolute;
    top: 3.14vw;
    left: 5.33vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container.-is-slide .keyVisual-logo img {
    width: 54.93vw;
  }
}
[data-pages="top"] .keyVisual-heading {
  position: absolute;
  z-index: 2;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-heading {
    top: 24.38vw;
    left: 6.59vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-heading {
    bottom: 25.33vw;
    left: 5.33vw;
  }
}
[data-pages="top"] .keyVisual-heading span {
  display: none;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-heading img {
    width: 25.11vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-heading img {
    width: 58.8vw;
  }
}
[data-pages="top"] .keyVisual-bg-video {
  opacity: 0;
  transition: opacity 1s;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-bg-video {
    transform: scale(1.033);
  }
}
[data-pages="top"] .keyVisual-bg-video.ready {
  opacity: 10;
}
[data-pages="top"] .keyVisual-bg-video::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(204,204,204,0.2);
}
[data-pages="top"] .keyVisual-bg-video #videoPlayer {
  min-width: 100%;
  min-height: 100%;
  pointer-events: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
[data-pages="top"] .keyVisual-bg-video #videoPlayer iframe {
  pointer-events: none !important;
}
[data-pages="top"] .keyVisual-slider-slide img {
  width: 100%;
}
[data-pages="top"] .keyVisual-slider-button-prev,
[data-pages="top"] .keyVisual-slider-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-slider-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-slider-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-slider-button-prev.swiper-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-slider-button-prev.swiper-button-next,
  [data-pages="top"] .keyVisual-slider-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .keyVisual-slider-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-logo {
    position: absolute;
    top: 5.33vw;
    left: 5.33vw;
    z-index: 3;
  }
  [data-pages="top"] .keyVisual-logo img {
    width: 20.93vw;
  }
}
[data-pages="top"] .keyVisual .playButton {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.1em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual .playButton {
    font-size: 1.02vw;
    bottom: 2.2vw;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="top"] .keyVisual .playButton:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual .playButton {
    font-size: 2.93vw;
    bottom: 6.67vw;
  }
}
[data-pages="top"] .keyVisual .playButton .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #601986;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual .playButton .icon {
    width: 2.2vw;
    height: 2.2vw;
    margin-bottom: 0.88vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual .playButton .icon {
    width: 7.47vw;
    height: 7.47vw;
    margin-bottom: 2.67vw;
  }
}
[data-pages="top"] .keyVisual .playButton .icon::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #601986;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual .playButton .icon::after {
    border-width: 0.37vw 0vw 0.37vw 0.59vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual .playButton .icon::after {
    border-width: 1.4vw 0vw 1.4vw 2.13vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-container {
    display: flex;
  }
}
[data-pages="top"] .notices-inner {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner {
    padding: 25px;
  }
}
[data-pages="top"] .notices-inner:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner:first-of-type {
    width: 266px;
    flex-shrink: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-inner:first-of-type {
    height: 13.33vw;
  }
}
[data-pages="top"] .notices-inner:first-of-type::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner:first-of-type::after {
    border-width: 12px 0 12px 13px;
    border-color: transparent transparent transparent #5f1985;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-inner:first-of-type::after {
    border-width: 2.4vw 2vw 0vw 2vw;
    border-color: #5f1985 transparent transparent transparent;
    bottom: -2.4vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner:first-of-type::after {
    border-width: 0.88vw 0vw 0.88vw 0.95vw;
    right: -0.88vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner:first-of-type {
    width: 19.47vw;
  }
}
[data-pages="top"] .notices-inner:last-of-type {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner:last-of-type {
    padding-right: 150px;
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-inner:last-of-type {
    padding: 6vw 5.33vw 27.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner:last-of-type {
    padding-right: 10.98vw;
    padding-left: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner {
    padding: 1.83vw;
  }
}
[data-pages="top"] .notices-heading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: bold;
  color: #fff;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-heading {
    font-size: 1.6rem;
    letter-spacing: 0.05em;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-heading {
    font-size: 4vw;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
  }
}
[data-pages="top"] .notices-heading::after {
  content: '!';
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-style: solid;
  border-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-heading::after {
    font-size: 1.6rem;
    width: 30px;
    height: 30px;
    border-width: 2px;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-heading::after {
    font-size: 3.47vw;
    width: 5.33vw;
    height: 5.33vw;
    order: -1;
    border-width: 0.27vw;
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-heading::after {
    font-size: 1.17vw;
    width: 2.2vw;
    height: 2.2vw;
    margin-top: 1.1vw;
  }
}
[data-pages="top"] .notices-box {
  display: inline-block;
}
[data-pages="top"] .notices-box a {
  color: #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-box a {
    display: flex;
    line-height: 1.5;
    padding: 5px 0;
  }
  [data-pages="top"] .notices-box a:hover .notices-caption {
    text-decoration: underline;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-box a {
    display: block;
    padding: 3.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-box a {
    padding: 0.37vw 0vw;
  }
}
[data-pages="top"] .notices-date {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-date {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-date {
    font-size: 4vw;
    margin-bottom: 2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-date {
    font-size: 1.02vw;
  }
}
[data-pages="top"] .notices-caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-caption {
    font-size: 1.3rem;
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-caption {
    font-size: 3.73vw;
    line-height: 1.61;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-caption {
    font-size: 0.95vw;
    margin-left: 1.46vw;
  }
}
[data-pages="top"] .notices-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
  position: absolute;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .notices-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .notices-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .notices-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link {
    right: 50px;
    bottom: 17px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link {
    right: 5.33vw;
    bottom: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link {
    right: 3.66vw;
    bottom: 1.24vw;
  }
}
[data-pages="top"] .banner-container {
  background-image: url("../img/top/banner_bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-container {
    padding: 2.93vw 0vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-container {
    padding: 9.87vw 0vw;
  }
}
[data-pages="top"] .banner-wrap {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap {
    padding: 0vw 1.83vw;
  }
}
[data-pages="top"] .banner-wrap a {
  display: inline-block;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap a {
    box-shadow: 10px 10px 10px rgba(51,51,51,0.15);
  }
  [data-pages="top"] .banner-wrap a:hover img {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-wrap a {
    box-shadow: 1.33vw 1.33vw 1.33vw rgba(51,51,51,0.15);
  }
}
[data-pages="top"] .banner-wrap a::before,
[data-pages="top"] .banner-wrap a::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  backface-visibility: hidden;
}
[data-pages="top"] .banner-wrap a::before {
  background-color: #fff;
}
[data-pages="top"] .banner-wrap a::after {
  border-style: solid;
  border-color: #fff;
  z-index: 2;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap a::after {
    border-width: 6px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .banner-wrap a {
    box-shadow: 0.73vw 0.73vw 0.73vw rgba(51,51,51,0.15);
  }
}
[data-pages="top"] .banner-wrap img {
  position: relative;
  z-index: 1;
  display: block;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap img {
    height: 14.64vw;
    transition: opacity 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-wrap img {
    height: 26.67vw;
  }
}
[data-pages="top"] .banner-button-prev,
[data-pages="top"] .banner-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-button-prev,
  [data-pages="top"] .banner-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-button-prev,
  [data-pages="top"] .banner-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-button-prev.swiper-button-prev,
  [data-pages="top"] .banner-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-button-prev.swiper-button-next,
  [data-pages="top"] .banner-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .banner-button-prev,
  [data-pages="top"] .banner-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-button-prev {
    left: 17.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-button-next {
    right: 17.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .content > section {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .content > section {
    padding-top: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .content > section {
    padding-top: 7.32vw;
  }
}
[data-pages="top"] .information-container {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-container {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-container {
    padding: 0vw 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-container {
    max-width: 86.38vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0vw 3.66vw;
  }
}
[data-pages="top"] .information-button-prev,
[data-pages="top"] .information-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
  top: 40%;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-button-prev,
  [data-pages="top"] .information-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev,
  [data-pages="top"] .information-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev.swiper-button-prev,
  [data-pages="top"] .information-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev.swiper-button-next,
  [data-pages="top"] .information-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-button-prev,
  [data-pages="top"] .information-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information-button-prev {
    left: 25px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev {
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-button-prev {
    left: 1.83vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information-button-next {
    right: 25px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-next {
    right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-button-next {
    right: 1.83vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information .information-contnt-block-image {
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information .information-contnt-block-image {
    height: 57.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information .information-contnt-block-image {
    height: 14.64vw;
  }
}
[data-pages="top"] .information-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-link-container {
    margin-top: 8vw;
    margin-right: -8vw;
  }
}
[data-pages="top"] .information-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .information-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .information-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .information-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-text-link {
    font-size: 0.95vw;
  }
}
[data-pages="top"] .event-container {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-container {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-container {
    padding: 0vw 10.67vw 0vw 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-container {
    max-width: 86.38vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0vw 1.46vw 0vw 3.66vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date + .event-contnt-block-date::before {
    margin: 0 0.5rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .month {
    font-size: 5.07vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .month::after {
    margin-left: 0;
    font-size: 4vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .day {
    font-size: 10.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .week {
    font-size: 3.47vw;
  }
}
[data-pages="top"] .event-contnt-block {
  height: auto;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-contnt-block {
    padding-right: 30px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-contnt-block {
    padding-right: 2.67vw;
    padding-bottom: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-contnt-block a {
    box-shadow: 10px 10px 10px rgba(51,51,51,0.07);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-contnt-block a {
    box-shadow: 1.33vw 1.33vw 1.33vw rgba(51,51,51,0.07);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-contnt-block a {
    box-shadow: 0.73vw 0.73vw 0.73vw rgba(51,51,51,0.07);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-contnt-block {
    padding-right: 2.2vw;
    padding-bottom: 1.46vw;
  }
}
[data-pages="top"] .event-button-prev,
[data-pages="top"] .event-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
  top: 40%;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-button-prev,
  [data-pages="top"] .event-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-button-prev,
  [data-pages="top"] .event-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-button-prev.swiper-button-prev,
  [data-pages="top"] .event-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-button-prev.swiper-button-next,
  [data-pages="top"] .event-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-button-prev,
  [data-pages="top"] .event-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-button-prev {
    left: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-button-prev {
    left: 1.83vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-button-next {
    right: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-button-next {
    right: 1.83vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-image {
    height: 47.47vw;
  }
}
[data-pages="top"] .event-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-link-container {
    margin-top: 8vw;
    margin-right: -8vw;
  }
}
[data-pages="top"] .event-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .event-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .event-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .event-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-text-link {
    font-size: 0.95vw;
  }
}
[data-pages="top"] .about-bg {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-bg {
    height: 520px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-bg {
    height: 69.33vw;
  }
}
[data-pages="top"] .about-bg-inner {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
[data-pages="top"] .about-bg-inner::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0.7) 0, rgba(255,255,255,0) 100%);
}
[data-pages="top"] .about-bg-inner img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-bg {
    height: 38.07vw;
  }
}
[data-pages="top"] .about-wrap {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap {
    width: 50%;
    max-width: 593px;
    min-height: 370px;
    padding: 60px 50px 60px 60px;
    margin-left: auto;
    margin-top: -140px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap {
    width: 89.33vw;
    padding: 8vw 8vw 16vw;
    margin: -13.33vw auto 0vw;
  }
}
[data-pages="top"] .about-wrap-header {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-header {
    margin-bottom: 5.33vw;
  }
}
[data-pages="top"] .about-wrap-header h3 {
  font-weight: lighter;
  letter-spacing: 0.2em;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-header h3 {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-header h3 {
    font-size: 6.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-header h3 {
    font-size: 2.93vw;
  }
}
[data-pages="top"] .about-wrap-header h4 {
  font-weight: lighter;
  color: #601986;
  letter-spacing: 0.2em;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-header h4 {
    font-size: 1.4rem;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-header h4 {
    font-size: 3.47vw;
    line-height: 1.42;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-header h4 {
    font-size: 1.02vw;
    margin-top: 1.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-header {
    margin-bottom: 1.46vw;
  }
}
[data-pages="top"] .about-wrap-caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  line-height: 2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-caption {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-caption {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-caption {
    font-size: 1.17vw;
  }
}
[data-pages="top"] .about-wrap-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-link-container {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-link-container {
    margin-top: 2.2vw;
  }
}
[data-pages="top"] .about-wrap-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .about-wrap-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .about-wrap-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .about-wrap-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap {
    min-height: 27.09vw;
    padding: 4.39vw 3.66vw 4.39vw 4.39vw;
    margin-top: -10.25vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department#department {
    padding-top: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department .section-heading {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department .section-heading {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-container {
    display: flex;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-container {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department-container {
    max-width: 86.38vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-inner {
    display: flex;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-inner + .department-inner {
    padding-top: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-wrap {
    width: 90px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap {
    margin-bottom: 6.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-wrap a {
    display: block;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="top"] .department-wrap a:hover {
    opacity: 0.6;
  }
  [data-pages="top"] .department-wrap a:hover span::after {
    transform: translate3d(4px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap a {
    display: flex;
    border-width: 1px 0;
    border-color: #ccc;
    border-style: solid;
  }
}
[data-pages="top"] .department-wrap-bg {
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-pages="top"] .department-wrap-bg {
    width: 90px;
    height: 422px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap-bg {
    width: 44.67vw;
    height: 20vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department-wrap-bg {
    width: 6.59vw;
    height: 30.89vw;
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department-wrap {
    width: 6.59vw;
    margin-right: 2.2vw;
  }
}
[data-pages="top"] .department h3 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: lighter;
  color: #fff;
  letter-spacing: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h3 {
    font-size: 2.2rem;
    writing-mode: vertical-rl;
    width: 90px;
    height: 422px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h3 {
    font-size: 4vw;
    height: 10.67vw;
  }
}
[data-pages="top"] .department h3::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h3::after {
    border-width: 7px 0 7px 7px;
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h3::after {
    border-width: 1.73vw 1.67vw 0vw 1.67vw;
    left: 50%;
    bottom: -1.6vw;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h3::after {
    border-width: 0.51vw 0vw 0.51vw 0.51vw;
    right: -0.51vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h3 {
    font-size: 1.61vw;
    width: 6.59vw;
    height: 30.89vw;
  }
}
[data-pages="top"] .department h4 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: lighter;
  letter-spacing: 0.2em;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 {
    font-size: 2.8rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 {
    font-size: 4.27vw;
    display: flex;
    align-items: center;
  }
}
[data-pages="top"] .department h4 span {
  display: inline-block;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 span {
    padding: 0 5px 25px;
    writing-mode: vertical-rl;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 span {
    padding-bottom: 5.33vw;
  }
}
[data-pages="top"] .department h4 span::before {
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 span::before {
    width: 100%;
    left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 span::before {
    width: 4.8vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-pages="top"] .department h4 span::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 span::after {
    right: 0;
    border-width: 9px 0 0 9px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 span::after {
    left: calc(50% + 2.4vw);
    transform: translateX(-50%);
    border-width: 1.2vw 0vw 0vw 1.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h4 span {
    padding: 0vw 0.37vw 1.83vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h4 {
    font-size: 2.05vw;
  }
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h3 {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h3 {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h3::after {
    border-color: transparent transparent transparent #5f1985;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h3::after {
    border-color: #5f1985 transparent transparent transparent;
  }
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 {
  color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 span {
    border-bottom-color: #5f1985;
  }
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 span::before {
  background-color: #5f1985;
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 span::after {
  border-color: transparent transparent transparent #5f1985;
}
[data-pages="top"] .department [data-department="NutritionScience"] h3 {
  background-color: #9ec238;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="NutritionScience"] h3::after {
    border-color: transparent transparent transparent #9ec238;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="NutritionScience"] h3::after {
    border-color: #9ec238 transparent transparent transparent;
  }
}
[data-pages="top"] .department [data-department="NutritionScience"] h4 {
  color: #9ec238;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="NutritionScience"] h4 span {
    border-bottom-color: #9ec238;
  }
}
[data-pages="top"] .department [data-department="NutritionScience"] h4 span::before {
  background-color: #9ec238;
}
[data-pages="top"] .department [data-department="NutritionScience"] h4 span::after {
  border-color: transparent transparent transparent #9ec238;
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h3 {
  background-color: #1886ba;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] h3::after {
    border-color: transparent transparent transparent #1886ba;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] h3::after {
    border-color: #1886ba transparent transparent transparent;
  }
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h4 {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] h4 span {
    border-bottom-color: #1886ba;
  }
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h4 span::before {
  background-color: #1886ba;
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h4 span::after {
  border-color: transparent transparent transparent #1886ba;
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] .department-wrap:last-of-type {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="english"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_01.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="english"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_01@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="japanese"] {
    margin-top: 115px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="japanese"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_02.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="japanese"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_02@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="japanese"] {
    margin-top: 8.42vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="society"] {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="society"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_03.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="society"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_03@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="society"] {
    margin-top: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="childhood"] {
    margin-top: 120px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_04.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_04@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="childhood"] {
    margin-top: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="nutrition"] {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="nutrition"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_05.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="nutrition"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_05@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="nutrition"] {
    margin-top: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="early-childhood"] {
    margin-top: 60px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="early-childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_06.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="early-childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_06@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="early-childhood"] {
    margin-top: 4.39vw;
  }
}
[data-pages="top"] .section-heading span {
  transition: transform 0.5s 0.3s ease-in-out;
}
@media (min-width: 768px) {
  [data-pages="top"] .section-heading span {
    transform: translateY(150%);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .section-heading span {
    transform: translateY(170%);
  }
}
[data-pages="top"] .section-heading span:last-of-type {
  transition-delay: 0.5s;
}
[data-pages="top"] .information-container,
[data-pages="top"] .event-container {
  transition: transform 0.5s 0.9s ease-in-out, opacity 0.5s 0.9s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
}
[data-pages="top"] .about-bg {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
[data-pages="top"] .about-wrap {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
}
[data-pages="top"] .department-inner .department-wrap {
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
@media (min-width: 768px) {
  [data-pages="top"] .department-inner .department-wrap:nth-of-type(odd) {
    transform: translateY(40px);
  }
  [data-pages="top"] .department-inner .department-wrap:nth-of-type(even) {
    transform: translateY(-40px);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-inner .department-wrap {
    transform: translateY(40px);
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(1) {
    transition-delay: 0.2s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(2) {
    transition-delay: 0.4s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(3) {
    transition-delay: 0.6s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(4) {
    transition-delay: 0.8s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(5) {
    transition-delay: 1s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="NutritionScience"] .department-wrap:nth-of-type(1) {
    transition-delay: 0.4s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="NutritionScience"] .department-wrap:nth-of-type(2) {
    transition-delay: 0.6s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="PreschoolEducation"] .department-wrap:nth-of-type(1) {
    transition-delay: 0.2s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="PreschoolEducation"] .department-wrap:nth-of-type(2) {
    transition-delay: 0.4s;
  }
}
[data-pages="top"] .jsInview.is-active .section-heading span {
  transform: translateY(0);
}
[data-pages="top"] .jsInview.is-active .information-container,
[data-pages="top"] .jsInview.is-active .event-container {
  transform: translateY(0);
  opacity: 1;
}
[data-pages="top"] .about-bg.is-active {
  opacity: 1;
}
[data-pages="top"] .about-wrap.is-active {
  transform: translateY(0);
  opacity: 1;
}
@media (min-width: 768px) {
  [data-pages="top"] .department.jsInview.is-active .department-wrap {
    transform: translateY(0) !important;
    opacity: 1;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap.jsInview.is-active {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .page-header {
    background-image: url("../img/information/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .page-header {
    background-image: url("../img/information/header_bg@2x.jpg");
  }
}
[data-pages="information"] .pagenation .prev-btn a,
[data-pages="information"] .pagenation .next-btn a {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}
[data-pages="information"] .content {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="information"] .content {
    padding: 70px 0 165px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content {
    padding: 13.33vw 0vw 33.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-container {
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-container {
    max-width: 79.06vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-pages="information"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-list + li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-archive-list + li {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-list + li {
    margin-top: 1.46vw;
  }
}
[data-pages="information"] .content-archive-list a {
  color: #333;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-list a {
    display: flex;
    padding: 35px 40px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="information"] .content-archive-list a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-archive-list a {
    display: block;
    padding: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-list a {
    padding: 2.56vw 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-header {
    width: 24%;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-header {
    width: 30%;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-title {
    font-size: 1.6rem;
    line-height: 1.63;
    margin-top: -0.3em;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-archive-title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-title {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content {
    padding: 5.12vw 0vw 12.08vw;
  }
}
@media (min-width: 768px) {
  [data-category="jc"] .page-header {
    background-image: url("../img/jc/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="jc"] .page-header {
    background-image: url("../img/jc/header_bg@2x.jpg");
    background-position: right top;
  }
}
[data-category="professor"] .page-header {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="professor"] .page-header {
    background-image: url("../img/faculty/professor/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .page-header {
    background-image: url("../img/faculty/professor/header_bg-sp.jpg");
  }
}
[data-category="professor"].ui-template .list-withDot li:before {
  background-color: #9ec238;
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container {
    flex-wrap: wrap;
  }
}
[data-category="professor"] .ui-template .tab-container .tab {
  text-align: center;
  padding: 0;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .tab-container .tab {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container .tab {
    flex-direction: column;
    width: 100%;
    margin-top: 6.93vw;
  }
}
[data-category="professor"] .ui-template .tab-container .tab span {
  width: 100%;
  display: block;
  padding-right: 0;
  padding-left: 0;
}
[data-category="professor"] .ui-template .tab-container .tab .faculty {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .tab-container .tab .faculty {
    font-size: 13px;
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container .tab .faculty {
    font-size: 3.2vw;
    margin-bottom: 1.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .ui-template .tab-container .tab .faculty {
    font-size: 0.95vw;
    margin-bottom: 0.59vw;
  }
}
[data-category="professor"] .ui-template .tab-container .tab .department {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .tab-container .tab .department {
    font-size: 15px;
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container .tab .department {
    font-size: 3.73vw;
    margin-bottom: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .ui-template .tab-container .tab .department {
    font-size: 1.1vw;
    margin-bottom: 1.17vw;
  }
}
[data-category="professor"] .ui-template .block-teachers {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .block-teachers {
    padding: 40px 0;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .block-teachers {
    padding: 10.67vw 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .block-teachers .click_area {
    width: 100%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .block-teachers .click_area {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .ui-template .block-teachers {
    padding: 2.93vw 0vw;
  }
}
[data-category="professor"] .list-teachers li {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li {
    margin-bottom: 5.33vw;
  }
}
[data-category="professor"] .list-teachers li a {
  width: 100%;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li a {
    display: table;
  }
  [data-category="professor"] .list-teachers li a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li a {
    display: flex;
    padding: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-image,
  [data-category="professor"] .list-teachers li .area-text,
  [data-category="professor"] .list-teachers li .area-name,
  [data-category="professor"] .list-teachers li .area-specialty {
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-text {
    width: 52vw;
    margin-left: 5.33vw;
  }
}
[data-category="professor"] .list-teachers li .area-image {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-image {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-image {
    width: 26.67vw;
    height: 26.67vw;
  }
}
[data-category="professor"] .list-teachers li .area-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-image {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-name {
    padding-left: 40px;
    width: 320px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-name {
    margin-bottom: 3.47vw;
  }
}
[data-category="professor"] .list-teachers li .area-name span {
  display: block;
}
[data-category="professor"] .list-teachers li .area-name .professor {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-name .professor {
    margin-bottom: 8px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-name .professor {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-name .professor {
    margin-bottom: 0.59vw;
    font-size: 1.1vw;
  }
}
[data-category="professor"] .list-teachers li .area-name .en {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-name .en {
    margin-top: 8px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-name .en {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-name .en {
    margin-top: 0.59vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-name {
    padding-left: 2.93vw;
    width: 23.43vw;
  }
}
[data-category="professor"] .list-teachers li .area-specialty span:nth-child(n+2) {
  padding-left: 0.5em;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-specialty {
    padding-left: 40px;
    width: 460px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-specialty {
    padding-left: 2.93vw;
    width: 33.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li {
    margin-bottom: 1.46vw;
  }
}
[data-category="professor"] .profile .profile-item {
  display: table;
  width: 100%;
}
[data-category="professor"] .profile .profile-item .profile-heading,
[data-category="professor"] .profile .profile-item .profile-contents {
  display: table-cell;
  vertical-align: top;
  letter-spacing: 0.05em;
  line-height: 1.8;
}
@media (min-width: 768px) {
  [data-category="professor"] .profile .profile-item .profile-heading,
  [data-category="professor"] .profile .profile-item .profile-contents {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .profile .profile-item .profile-heading,
  [data-category="professor"] .profile .profile-item .profile-contents {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .profile .profile-item .profile-heading,
  [data-category="professor"] .profile .profile-item .profile-contents {
    font-size: 1.1vw;
  }
}
[data-category="professor"] .profile .profile-item .profile-heading {
  color: #601986;
}
[data-category="professor"] .main_profile {
  display: grid;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile {
    grid-template-rows: 80px 80px 1fr;
    grid-template-columns: 160px 1fr;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile {
    grid-template-columns: 26.67vw 1fr;
  }
}
[data-category="professor"] .main_profile .area-image {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-image {
    grid-row: 1/3;
    grid-column: 1/2;
    width: 100%;
    height: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-image {
    width: 100%;
    height: 26.67vw;
    grid-row: 1/2;
    grid-column: 1/2;
  }
}
[data-category="professor"] .main_profile .area-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-image {
    height: 11.71vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-name {
    grid-row: 1/2;
    grid-column: 2/3;
    padding: 10px 40px 0;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-name {
    grid-row: 1/2;
    grid-column: 2/3;
    padding: 2.67vw 5.33vw 0vw;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-name .ja {
    display: block;
  }
}
[data-category="professor"] .main_profile .area-name .en {
  letter-spacing: 0.05em;
  line-height: 1.6;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-name .en {
    margin-left: 10px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-name .en {
    display: block;
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-name .en {
    margin-left: 0.73vw;
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-name {
    padding: 0.73vw 2.93vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty {
    grid-row: 2/4;
    grid-column: 2/3;
    padding: 0 40px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty {
    margin-top: 5.33vw;
    grid-row: 2/3;
    grid-column: 1/3;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item {
    margin-bottom: 1.33vw;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading {
  position: relative;
  word-break: break-all;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading {
    width: 20%;
    padding-right: 6%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading {
    width: 40%;
    padding-right: 8%;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
  position: absolute;
  right: 4%;
  display: block;
  content: "";
  height: 1px;
  background: #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
    width: 74%;
    top: 13px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
    width: 70%;
    top: 3.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
    top: 0.95vw;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item .profile-contents {
  word-break: break-all;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-contents {
    width: 80%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-contents {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item {
    margin-bottom: 0.73vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--address .profile-heading::after {
    width: 24%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--address .profile-heading::after {
    width: 16%;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item--address .profile-contents span:first-of-type {
  margin-right: 0.5em;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--url .profile-heading::after {
    width: 24%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--url .profile-heading::after {
    width: 16%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-specialty {
    padding: 0vw 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile {
    grid-template-rows: 5.86vw 5.86vw 1fr;
    grid-template-columns: 11.71vw 1fr;
  }
}
[data-category="professor"] .sub_profile {
  border-top: 1px solid #ccc;
}
[data-category="professor"] .sub_profile .profile-item {
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item {
    padding: 20px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item {
    padding: 5.33vw 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item .profile-heading {
    width: 20%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item .profile-heading {
    display: block;
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents {
    width: 80%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item {
    padding: 1.46vw 2.93vw;
  }
}
[data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
  display: block;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
    margin-top: 1.46vw;
  }
}
[data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
    width: 100%;
    display: table;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner .achievement_title {
    display: table-cell;
    width: 24%;
  }
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner .achievement_contents {
    display: table-cell;
    width: 70%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
    padding-top: 5.33vw;
    padding-bottom: 5.33vw;
  }
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner .achievement_contents {
    padding-left: 1em;
  }
}
[data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner:first-of-type {
  padding-top: 0;
}
[data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner:last-of-type {
  border: none;
  padding-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
    padding-top: 1.46vw;
    padding-bottom: 1.46vw;
  }
}
[data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
  position: relative;
  height: auto;
  padding-top: 56.25%;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
    display: block;
    width: 540px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
    width: 100%;
  }
}
[data-category="professor"] .sub_profile .profile-item .profile-contents--movie iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
    width: 39.53vw;
  }
}
[data-category="professor"] .link-container--back {
  text-align: center;
}
[data-category="professor"] .link-container--back .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="professor"] .link-container--back .text-link {
    font-size: 1.6rem;
  }
  [data-category="professor"] .link-container--back .text-link:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .link-container--back .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="professor"] .link-container--back .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="professor"] .link-container--back .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #601986 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="professor"] .link-container--back .text-link::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .link-container--back .text-link::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .link-container--back .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="professor"] .link-container--back .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="professor"] .link-container--back .text-link span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .link-container--back .text-link span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .link-container--back .text-link span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .link-container--back .text-link {
    font-size: 1.17vw;
  }
}
[data-category="professor"] .tab_link-container {
  display: flex;
  text-align: center;
}
[data-category="professor"] .tab_link-container .tab_link {
  width: 100%;
}
[data-category="professor"] .tab_link-container .tab_link a,
[data-category="professor"] .tab_link-container .tab_link span {
  display: block;
  width: 100%;
  height: 100%;
  letter-spacing: 0.05em;
  line-height: 1;
  transition: 0.2s;
}
@media (min-width: 768px) {
  [data-category="professor"] .tab_link-container .tab_link a,
  [data-category="professor"] .tab_link-container .tab_link span {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .tab_link-container .tab_link a,
  [data-category="professor"] .tab_link-container .tab_link span {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .tab_link-container .tab_link a,
  [data-category="professor"] .tab_link-container .tab_link span {
    font-size: 1.24vw;
  }
}
[data-category="professor"] .tab_link-container .tab_link span {
  color: #333;
  border-bottom: 2px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .tab_link-container .tab_link span {
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .tab_link-container .tab_link span {
    padding: 5.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .tab_link-container .tab_link span {
    padding: 1.46vw 0vw;
  }
}
[data-category="professor"] .tab_link-container .tab_link.active span {
  color: #5f1985;
  border-bottom: 2px solid #601986;
}
[data-category="professor"] .tab_link-container .tab_link:hover a span {
  border-bottom: 2px solid #601986;
  color: #5f1985;
  opacity: 0.6;
}
@media (max-width: 767px) {
  [data-category="professor"] .tab_link-container {
    padding: 0vw 5.33vw;
  }
}
[data-category="professor"] .pagenation .disabled {
  display: none;
}
@media (min-width: 768px) {
  [data-category="professor"] .pagenation .page-number:hover a {
    color: #fff;
  }
}
[data-category="professor"] .pagenation .page-number.current a {
  color: #fff;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-category="professor"] .pagenation .page-number a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-category="professor"] .pagenation .page-number a {
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .pagenation .page-number a {
    line-height: 12vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .pagenation .page-number a {
    line-height: 3.66vw;
  }
}
[data-category="professor"] .pagenation .prev-btn,
[data-category="professor"] .pagenation .next-btn {
  background-image: url("../img/common/pagenation_arrow.svg");
}
[data-category="professor"] .pagenation .prev-btn a,
[data-category="professor"] .pagenation .next-btn a {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .ui-template {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch {
    padding: 0vw 5.33vw;
  }
}
[data-pages="professor_serch"] .block-serch input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}
[data-pages="professor_serch"] .block-serch .serch-item {
  display: table;
  width: 100%;
  margin-bottom: 30px;
}
[data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
[data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
  display: table-cell;
  vertical-align: top;
  letter-spacing: 0.05em;
  line-height: 1.8;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
  [data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
  [data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
    display: block;
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
  [data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading {
    width: 260px;
    padding: 6px 18px 0 0;
    text-align: right;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading {
    width: 19.03vw;
    padding: 0.44vw 1.32vw 0vw 0vw;
  }
}
[data-pages="professor_serch"] .block-serch .selects_wrap {
  display: block;
  border: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap {
    width: 350px;
  }
}
[data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  background-image: url("../img/faculty/professor/arrow.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
    width: 350px;
    line-height: 40px;
    padding: 0 30px 0 10px;
    height: 40px;
    background-size: 13px 8px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
    white-space: initial;
    height: 10.67vw;
    line-height: 10.67vw;
    padding: 0vw 5.33vw 0vw 2.67vw;
    background-size: 3.47vw 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
    width: 25.62vw;
    padding: 0vw 2.2vw 0vw 0.73vw;
    height: 2.93vw;
    line-height: 2.93vw;
    background-size: 0.95vw 0.59vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap {
    width: 25.62vw;
  }
}
[data-pages="professor_serch"] .block-serch .input_area {
  display: block;
  cursor: pointer;
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .input_area {
    line-height: 2.4;
    padding: 0 20px;
    font-size: 13px;
  }
  [data-pages="professor_serch"] .block-serch .input_area:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .input_area {
    line-height: 10.67vw;
    padding: 0vw 5.33vw;
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .input_area {
    padding: 0vw 1.46vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .input_area--text {
    padding: 0 10px;
    height: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .input_area--text {
    padding: 0vw 2.67vw;
    height: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .input_area--text {
    padding: 0vw 0.73vw;
    height: 2.93vw;
  }
}
[data-pages="professor_serch"] .block-serch input[type=radio] {
  display: none;
}
[data-pages="professor_serch"] .block-serch input[type=radio].hide-radiobtn {
  display: none;
}
[data-pages="professor_serch"] .block-serch input[type=radio]::-ms-clear {
  visibility: hidden;
}
[data-pages="professor_serch"] .block-serch .answer {
  max-height: 200px;
  overflow-y: scroll;
  display: none;
  background: #f5f6f8;
}
[data-pages="professor_serch"] .block-serch .tab {
  cursor: pointer;
  transition: 0.2s;
}
[data-pages="professor_serch"] .block-serch .notes {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .notes {
    margin-top: 8px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .notes {
    margin-top: 2.13vw;
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .notes {
    margin-top: 0.59vw;
    font-size: 0.95vw;
  }
}
[data-pages="professor_serch"] .block-serch .part_heading {
  color: #8c8c8c;
  line-height: 2.4;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .part_heading {
    padding: 0 10px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .part_heading {
    padding: 0vw 2.67vw;
    font-size: 3.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .part_heading {
    padding: 0vw 0.73vw;
    font-size: 0.95vw;
  }
}
[data-pages="professor_serch"] .block-serch .wrap-button {
  text-align: center;
}
[data-pages="professor_serch"] .block-serch .wrap-button .button {
  text-align: center;
  color: #fff;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button {
    border-radius: 20px;
    width: 180px;
    font-size: 15px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button {
    border-radius: 5.33vw;
    width: 38.67vw;
    font-size: 3.73vw;
    line-height: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button {
    width: 13.18vw;
    font-size: 1.1vw;
    line-height: 2.93vw;
  }
}
[data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
  background-color: $thema-purple;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
    margin-right: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
    margin-right: 2.93vw;
  }
}
[data-pages="professor_serch"] .block-serch .wrap-button .button-clear {
  background-color: #333;
  transition: 0.2s;
}
[data-pages="professor_serch"] .block-serch .wrap-button .button-clear:hover {
  opacity: 0.7;
}
[data-category="maintenance"] .header {
  z-index: -1;
  pointer-events: none;
}
[data-category="maintenance"] .footer {
  z-index: -1;
  pointer-events: none;
}
[data-category="maintenance"] .main {
  height: 70vh;
}
[data-category="maintenance"] article .mask {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: rgba(255,255,255,0.8);
  padding: 0;
  z-index: 99;
}
[data-category="maintenance"] article .container {
  position: relative;
  z-index: 999;
}
@media (min-width: 768px) {
  [data-category="maintenance"] article .container.-mid {
    max-width: 1000px;
  }
}
@media (max-width: 1365px) {
  [data-category="maintenance"] article .container.-mid {
    max-width: 73.21vw;
  }
}
[data-category="maintenance"] article .notice-wrap {
  border: 1px solid #000;
}
[data-category="maintenance"] article .-center {
  text-align: center;
}
[data-category="maintenance"] article .content-container .inquery-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
[data-category="maintenance"] article .content-container .inquery-content .margin-top-lv3 {
  margin-top: 29px;
}
@media (max-width: 1365px) {
  [data-category="maintenance"] article .content-container .inquery-content .margin-top-lv3 {
    margin-top: 2.12vw;
  }
}
[data-category="maintenance"] article .content-container .inquery-content .margin-top-little {
  margin-top: 2px;
}
@media (max-width: 1365px) {
  [data-category="maintenance"] article .content-container .inquery-content .margin-top-little {
    margin-top: 0.15vw;
  }
}
@media (min-width: 768px) {
  [data-category="maintenance"] [data-contact-list="index"] .inquiry {
    min-width: 310px;
  }
}
@media (max-width: 1365px) {
  [data-category="maintenance"] [data-contact-list="index"] .inquiry {
    min-width: 22.69vw;
  }
}
@media (max-width: 767px) {
  [data-category="maintenance"] [data-contact-list="index"] .inquiry {
    width: 100% !important;
  }
}
[data-pages="uitemplate nyushi_index"] .link-content {
  text-align: right;
}
[data-pages="uitemplate nyushi_index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="uitemplate nyushi_index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="uitemplate nyushi_index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .nyushi-footer-inner.adjustment-width .buttonlink {
    width: 270px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .nyushi-footer-inner.adjustment-width .buttonlink {
    width: 19.77vw;
  }
}
[data-pages="uitemplate sougousenbatsu_sengan"] .class {
  color: #00f;
}
@media (min-width: 768px) {
  [data-pages="uitemplate sougousenbatsu_sengan"] .ui-template .buttonlink {
    width: 540px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate sougousenbatsu_sengan"] .ui-template .buttonlink {
    width: 39.53vw;
  }
}
[data-pages="uitemplate nyushi-mondai"] .table-nyushi-mondai {
  text-align: left;
}
[data-pages="uitemplate nyushi-mondai"] .table-nyushi-mondai tr td {
  padding-left: 1em;
}
[data-pages="uitemplate nyushi-mondai"] .table-nyushi-mondai .-w6em {
  width: 6em;
}
@media (min-width: 768px) {
  [data-category="others"] .page-header {
    background-image: url("../img/others/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="others"] .page-header {
    background-image: url("../img/others/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="others"] [data-others="logo"] .per60 {
    width: 60%;
  }
}
@media (max-width: 767px) {
  [data-category="others"] [data-others="logo"] .img-logotype {
    width: 70%;
    margin: 0 auto;
  }
}
[data-category="professor"] [class*="u-professor_d-flex"] {
  display: flex;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_d-flex"][class*="__nosp"] {
    display: block;
  }
}
[data-category="professor"] [class*="u-professor_d-block"] {
  display: block;
}
[data-category="professor"] [class*="u-professor_d-inline-block"] {
  display: inline-block;
}
[data-category="professor"] [class*="u-professor_align-items-center"] {
  align-items: center;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_align-items-center"][class*="__nosp"] {
    align-items: inherit;
  }
}
[data-category="professor"] [class*="u-professor_pl-0_5"] {
  padding-left: 0.5em;
}
[data-category="professor"] [class*="u-professor_pl-4"] {
  padding-left: 4em;
}
[data-category="professor"] [class*="u-professor_pr-4"] {
  padding-right: 4em;
}
[data-category="professor"] [class*="u-professor_pt-1_6"] {
  padding-top: 1.6em;
}
[data-category="professor"] [class*="u-professor_pb-1_6"] {
  padding-bottom: 1.6em;
}
[data-category="professor"] [class*="u-professor_pt-custom"] {
  padding-top: 5.33vw !important;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pl"][class*="__nospPl"] {
    padding-left: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pl"][class*="__nopcPl"] {
    padding-left: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pr"][class*="__nospPr"] {
    padding-right: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pr"][class*="__nopcPr"] {
    padding-right: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pt"][class*="__nospPt"] {
    padding-top: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pt"][class*="__nopcPt"] {
    padding-top: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pb"][class*="__nospPb"] {
    padding-bottom: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pb"][class*="__nopcPb"] {
    padding-bottom: initial;
  }
}
[data-category="professor"] [class*="u-professor_mt-08"] {
  margin-top: 0.8em;
}
[data-category="professor"] [class*="u-professor_mb-08"] {
  margin-bottom: 0.8em;
}
[data-category="professor"] [class*="u-professor_mb-2"] {
  margin-bottom: 2em;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_mt"][class*="__nospMt"] {
    margin-top: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_mt"][class*="__nopcMt"] {
    margin-top: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_mb"][class*="__nospMb"] {
    margin-bottom: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_mb"][class*="__nopcMb"] {
    margin-bottom: initial;
  }
}
[data-category="professor"] [class*="u-professor_color-purple"] {
  color: #601986;
}
[data-category="professor"] .u-professor_fs-md {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="professor"] .u-professor_fs-md {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .u-professor_fs-md {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .u-professor_fs-md {
    font-size: 1.1vw;
  }
}
[data-category="professor"] .u-professor_fs-mds {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .u-professor_fs-mds {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .u-professor_fs-mds {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .u-professor_fs-mds {
    font-size: 0.95vw;
  }
}
[data-category="professor"] .u-professor_ff-roboto {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-category="professor"] [class*="u-professor_w-10"] {
  width: 10%;
}
[data-category="professor"] [class*="u-professor_w-15"] {
  width: 15%;
}
[data-category="professor"] [class*="u-professor_w-20"] {
  width: 20%;
}
[data-category="professor"] [class*="u-professor_w-25"] {
  width: 25%;
}
[data-category="professor"] [class*="u-professor_w-30"] {
  width: 30%;
}
[data-category="professor"] [class*="u-professor_w-35"] {
  width: 35%;
}
[data-category="professor"] [class*="u-professor_w-40"] {
  width: 40%;
}
[data-category="professor"] [class*="u-professor_w-45"] {
  width: 45%;
}
[data-category="professor"] [class*="u-professor_w-50"] {
  width: 50%;
}
[data-category="professor"] [class*="u-professor_w-75"] {
  width: 75%;
}
[data-category="professor"] [class*="u-professor_w-95"] {
  width: 95%;
}
[data-category="professor"] [class*="u-professor_w-100"] {
  width: 100%;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_w-"][class*="__nospW"] {
    width: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_w-"][class*="__nopcW"] {
    width: initial;
  }
}
[data-category="professor"] [class*="u-professor_h-100"] {
  height: 100%;
}
[data-category="professor"] [class*="u-professor_h-50"] {
  height: 50%;
}
[data-category="professor"] [class*="u-professor_h-25"] {
  height: 25%;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_h-"][class*="__nospH"] {
    height: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_h-"][class*="__nopcH"] {
    height: initial;
  }
}
[data-category="professor"] .u-professor_area-image-block {
  min-height: 120px;
}
@media (max-width: 1365px) {
  [data-category="professor"] .u-professor_area-image-block {
    min-height: 8.78vw;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .u-professor_area-image-block {
    width: calc(100% - 26.67vw);
    min-height: 26.67vw;
    margin-left: 2.67vw;
  }
}
[data-category="professor"] .area-image {
  vertical-align: top !important;
}
[data-category="professor"] .area-image .area-image-block {
  width: 12rem !important;
  height: 12rem !important;
  position: relative;
}
@media (max-width: 767px) {
  [data-category="professor"] .area-image .area-image-block {
    width: 26.67vw !important;
    height: 26.67vw !important;
  }
}
[data-category="professor"] .area-image .area-image-block img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
[data-category="professor"] .pagenation a:focus {
  outline: none;
}
[data-pages="students-index"].content-for_menu {
  background-color: #f5f6f8 !important;
}
@media (min-width: 768px) {
  [data-pages="students-index"] .page-header {
    background-image: url("../img/for-students/students-index/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .page-header {
    background-image: url("../img/for-students/students-index/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .content-for_menu-inner {
    padding: 78px 0 62px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .content-for_menu-inner {
    padding: 40px 0 62px;
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_outer::after {
    bottom: 47%;
    left: 66%;
    transform: translate(-50%, 0);
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1:hover .wrap_outer::after {
    left: 67%;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-pc.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    min-height: 68px;
    width: 287px;
    padding-left: 72px;
    padding-top: 12px;
    background-position: center left;
    background-size: 78px 68px;
    bottom: 30%;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 68px;
    background-size: 53px 46px;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-note.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 98px;
    background-size: 50px 64px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 68px;
    background-size: 38px 46px;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-money.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 90px;
    background-size: 79px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 65px;
    background-size: 58px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-note.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 98px;
    background-size: 50px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 68px;
    background-size: 38px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-money.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 90px;
    background-size: 79px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 65px;
    background-size: 58px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-information.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 90px;
    background-size: 64px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 65px;
    background-size: 48px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--7 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-webmail.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--7 .wrap_inner {
    padding-top: 90px;
    background-size: 116px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--7 .wrap_inner {
    padding-top: 65px;
    background-size: 71px auto;
  }
}
[data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--1 {
  background-image: url("../img/for-students/students-index/icon harassment.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--1 {
    margin-top: 5px;
    padding-top: 88px;
    background-size: 73px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--1 {
    margin-top: 52px;
    padding-top: 71px;
    background-size: 55px;
  }
}
[data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--2 {
  background-image: url("../img/for-students/students-index/icon bus.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--2 {
    margin-top: 18px;
    padding-top: 74px;
    background-size: 76px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--2 {
    margin-top: 23px;
    padding-top: 52px;
    background-size: 57px auto;
  }
}
[data-pages="students-index"] .container-info {
  margin-top: 10px;
}
[data-pages="students-index"] .link-content {
  text-align: right;
}
[data-pages="students-index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="students-index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="students-index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="students-index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="students-index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="students-index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="students-index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="students-index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="students-index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="uv"] .page-header {
    background-image: url("../img/uv/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="uv"] .page-header {
    background-image: url("../img/uv/header_bg@2x.jpg");
    background-position: right top;
  }
}
[data-pages="error"] .main h1 {
  color: #3b4043;
}
@media (min-width: 768px) {
  [data-pages="error"] .main h1 {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="error"] .main h1 {
    margin-bottom: 2.67vw;
  }
}
[data-pages="error"] .main p {
  text-align: center;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="error"] .main p {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="error"] .main p {
    font-size: 1.87vw;
  }
}
@media (min-width: 768px) {
  [data-pages="error"] .main p + p {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="error"] .main p + p {
    margin-top: 1.33vw;
  }
}
[data-pages="history"].pages {
  overflow: visible;
}
[data-pages="history"] .footer {
  border: none;
}
[data-pages="history"] .breadcrumbs-container {
  position: relative;
}
[data-pages="history"] .page-header {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="history"] .page-header {
    background-image: url("../img/about/history/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .page-header {
    background-image: url("../img/about/history/header_bg-sp.jpg");
  }
}
[data-pages="history"] .ui-template {
  background: none;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="history"] .ui-template {
    padding-top: 0;
  }
}
[data-pages="history"] .container-history {
  background-color: rgba(255,255,255,0.9);
}
@media (min-width: 768px) {
  [data-pages="history"] .container-history {
    padding: 56px 76px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .container-history {
    padding-top: 9.6vw;
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .container-history {
    padding: 4.1vw 5.56vw;
  }
}
[data-pages="history"] .heading.wrap-box {
  max-width: 748px;
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box {
    display: flex;
    justify-content: space-between;
  }
}
[data-pages="history"] .heading.wrap-box .box-text .heading {
  font-feature-settings: "palt";
  line-height: 1.4;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-text .heading {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-text .heading {
    font-size: 5.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-text .heading {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-text .text {
    margin-top: 45px;
    line-height: 1.96;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-text .text {
    margin-top: 4vw;
    line-height: 1.9;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-text .text {
    margin-top: 3.29vw;
  }
}
[data-pages="history"] .heading.wrap-box .box-image {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-image img {
    margin-top: 8px;
    width: 194px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-image img {
    margin-top: 6.4vw;
    width: 63%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-image img {
    width: 14.2vw;
    height: auto;
  }
}
[data-pages="history"] .heading.wrap-box .box-image .figcaption {
  font-weight: 700;
  letter-spacing: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="history"] .heading.wrap-box .box-image .figcaption {
    margin-top: 24px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .heading.wrap-box .box-image .figcaption {
    margin-top: 5.07vw;
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .heading.wrap-box .box-image .figcaption {
    margin-top: 1.76vw;
    font-size: 1.1vw;
  }
}
[data-pages="history"] .list-history {
  max-width: 770px;
}
@media (min-width: 768px) {
  [data-pages="history"] .list-history {
    margin: 100px auto 0;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .list-history {
    margin: 15.2vw auto 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .list-history {
    margin: 7.32vw auto 0vw;
  }
}
[data-pages="history"] .item-history {
  position: relative;
  width: 100%;
  height: 100%;
  border-left: 1px solid #601986;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history {
    padding: 20px 0 67px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history {
    padding: 4.8vw 0vw 9.33vw;
  }
}
[data-pages="history"] .item-history::before {
  position: absolute;
  content: "";
  background-color: #601986;
  border-radius: 50%;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history::before {
    top: -3px;
    left: -6px;
    width: 11px;
    height: 11px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history::before {
    top: -0.93vw;
    left: -1.6vw;
    width: 2.93vw;
    height: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history::before {
    top: -0.22vw;
    left: -0.44vw;
    width: 0.81vw;
    height: 0.81vw;
  }
}
[data-pages="history"] .item-history:last-of-type {
  border-left: none;
}
[data-pages="history"] .item-history .age {
  position: relative;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.1em;
  line-height: 1;
  color: #601986;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .age {
    top: -35px;
    padding: 0 0 0 42px;
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .age {
    top: -7.33vw;
    padding: 0vw 0vw 0vw 5.33vw;
    font-size: 6.4vw;
  }
}
[data-pages="history"] .item-history .age::before {
  position: absolute;
  content: "";
  width: 4.4%;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #601986;
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .age::before {
    width: 6%;
  }
}
[data-pages="history"] .item-history .age::after {
  position: absolute;
  content: "";
  width: 83%;
  height: 1px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #601986;
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .age::after {
    width: 71.5%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .age {
    top: -2.56vw;
    padding: 0vw 0vw 0vw 3.07vw;
    font-size: 2.64vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .wrap-box {
    display: flex;
    justify-content: space-between;
    padding: 0 31px 0 44px;
    margin-top: -15px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .wrap-box {
    padding: 0vw 1.07vw 0vw 6.4vw;
    margin-top: -4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .wrap-box {
    padding: 0vw 2.27vw 0vw 3.22vw;
    margin-top: -1.1vw;
  }
}
[data-pages="history"] .item-history .box-text {
  max-width: 472px;
}
[data-pages="history"] .item-history .box-text .date {
  line-height: 1;
  padding: 0 0 1px 6px;
  border-left: 4px solid #601986;
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-text .date {
    margin-bottom: 11px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-text .date {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-text .date {
    margin-bottom: 0.81vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-text .date:nth-of-type(2),
  [data-pages="history"] .item-history .box-text .date:nth-of-type(3) {
    margin-top: 32px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-text .date:nth-of-type(2),
  [data-pages="history"] .item-history .box-text .date:nth-of-type(3) {
    margin-top: 6.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-text .date:nth-of-type(2),
  [data-pages="history"] .item-history .box-text .date:nth-of-type(3) {
    margin-top: 2.34vw;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-image {
    margin-top: 7.47vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-image img {
    width: 200px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-image img {
    width: 91%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-image img {
    width: 14.64vw;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-image.box-image--2col {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history .box-image.box-image--2col .box-image--2col-item:last-of-type {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history .box-image.box-image--2col .box-image--2col-item:last-of-type {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history .box-image.box-image--2col .box-image--2col-item:last-of-type {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history {
    padding: 1.46vw 0vw 4.9vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image {
    padding: 28px 0 62px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image {
    padding: 5.6vw 0vw 8.8vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image::before {
    top: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image::before {
    top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image::before {
    top: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image .wrap-box {
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image .wrap-box {
    margin-top: -2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image .age {
    top: -46px;
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image .age {
    top: -6.93vw;
    font-size: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="history"] .item-history--image .age::after {
    width: 75.4%;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .item-history--image .age::after {
    width: 56%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image .age {
    top: -3.37vw;
    font-size: 4.39vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .item-history--image {
    padding: 2.05vw 0vw 4.54vw;
  }
}
[data-pages="history"] .container-history_bg {
  position: fixed;
  height: calc(100vh - 40px);
  width: 3558px;
  background-image: url("../img/about/history/bg-history.jpg");
  background-size: cover;
  background-position: top left;
}
@media (min-width: 768px) {
  [data-pages="history"] .container-history_bg {
    left: 180px;
    top: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="history"] .container-history_bg {
    left: 0;
    top: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="history"] .container-history_bg {
    left: 0;
    top: 2.93vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="sister_school"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="sister_school"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .nav-padding-sp {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
[data-category="about"] [data-about="sister_school"] .ui-template.only-template {
  padding: 0;
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .lr-padding {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-limit {
  max-width: 940px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-limit {
    max-width: 68.81vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-limit {
    max-width: 100%;
  }
}
[data-category="about"] [data-about="sister_school"] p {
  line-height: 1.93;
}
[data-category="about"] [data-about="sister_school"] .set-relative-camosun {
  position: relative;
  margin: 0 auto 5.12vw;
  height: 32.43vw;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-camosun {
    height: 32.43vw;
    margin-bottom: 5.12vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-camosun {
    width: 100vw;
    height: 58.67vw;
    margin: 0 auto 8vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-relative-camosun:before {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  height: 16.11vw;
  width: 43.56vw;
  background: #cebad9;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-camosun:before {
    height: 16.11vw;
    width: 55.27vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-camosun:before {
    height: 29.33vw;
    width: 64vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-item-camosun-left {
  position: absolute;
  z-index: 1;
  width: 30.75vw;
  top: 0;
  left: 4.39vw;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-item-camosun-left {
    width: 30.75vw;
    left: 16.11vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-item-camosun-left {
    left: 5.33vw;
    width: 49.33vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-item-camosun-right {
  position: absolute;
  width: 60.76vw;
  top: 6.81vw;
  right: 0;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-item-camosun-right {
    width: 60.76vw;
    top: 6.81vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-item-camosun-right {
    width: 52.53vw;
    top: 11.73vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-relative-ningbo {
  position: relative;
  margin: 0 auto;
  z-index: 1;
  height: 32.43vw;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-ningbo {
    height: 32.43vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-ningbo {
    height: 58.67vw;
    margin: 0 auto;
  }
}
[data-category="about"] [data-about="sister_school"] .set-relative-ningbo:before {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  height: 16.11vw;
  width: 43.56vw;
  background: #cebad9;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-ningbo:before {
    height: 16.11vw;
    width: 55.27vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-relative-ningbo:before {
    bottom: -8vw;
    height: 29.33vw;
    width: 64vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-item-ningbo-left {
  position: absolute;
  z-index: 1;
  width: 26.35vw;
  top: 0;
  left: 4.39vw;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-item-ningbo-left {
    width: 26.35vw;
    left: 16.11vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-item-ningbo-left {
    left: 8vw;
    width: 56vw;
  }
}
[data-category="about"] [data-about="sister_school"] .set-item-ningbo-right {
  position: absolute;
  top: 6.73vw;
  right: 0;
  width: 60.76vw;
}
@media (max-width: 1100px) {
  [data-category="about"] [data-about="sister_school"] .set-item-ningbo-right {
    top: 6.73vw;
    width: 60.76vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .set-item-ningbo-right {
    top: 20vw;
    width: 52.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="sister_school"] .sister-margin-top {
    margin-top: 3.66vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="sister_school"] .sister-margin-top {
    margin-top: 14vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] .page-header {
    background-image: url("../img/about/corporation/cathedral-tour/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] .page-header {
    background-image: url("../img/about/corporation/cathedral-tour/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] [data-about="corporation-cathedral-tour"] .flex {
    gap: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] [data-about="corporation-cathedral-tour"] .flex {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] [data-about="corporation-cathedral-tour"] .flex .box {
    width: calc(100% / 2 - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] [data-about="corporation-cathedral-tour"] .flex .box {
    width: calc(100% / 2 - 2vw);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] [data-about="corporation-cathedral-tour"] .flex .box {
    width: calc(100% / 2 - 1.46vw);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"][data-pages="corporation-cathedral-tour"] [data-about="corporation-cathedral-tour"] .flex {
    gap: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] .page-header {
    background-image: url("../img/about/founding-spirit/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] .page-header {
    background-image: url("../img/about/founding-spirit/header_bg@2x.jpg");
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .box-image {
  text-align: center;
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] [class^="heading"].withBorder:after {
  background: #5f1985;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] [class^="heading"].withBorder:after {
    width: 50px;
    height: 2px;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] [class^="heading"].withBorder:after {
    width: 6.67vw;
    height: 0.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] [class^="heading"].withBorder:after {
    width: 3.66vw;
    height: 0.15vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .base.paragraph:nth-of-type(1) {
  text-align: left;
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .per30 {
    width: 30%;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .per65 {
    width: 65%;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .flex {
  justify-content: space-between;
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .flex {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-1 {
    padding-bottom: 13.33vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .content-latest-container {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .content-latest-container {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .content-latest-container {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:first-of-type,
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:last-of-type {
    width: 34.97%;
    margin-top: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:first-of-type,
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:last-of-type {
    margin-top: 4.39vw;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:first-of-type {
    margin-top: 8vw;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:last-of-type {
    margin-top: 5.33vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:nth-of-type(2) {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:nth-of-type(2) {
    width: 21.21%;
    padding: 70px 0;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:nth-of-type(2) {
    width: 26.67vw;
    order: -1;
    padding: 8vw 0;
    margin: 0 auto;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:nth-of-type(2) .heading2 {
  writing-mode: vertical-rl;
  color: #fff;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .information-contnt-block:nth-of-type(2) {
    padding: 5.12vw 0vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 {
    margin-top: -50px;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 {
    width: calc(100% + 10.67vw);
    margin-left: -5.33vw;
    padding: 16vw 5.33vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 .heading2 {
  text-align: center;
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 .heading2 {
    font-size: 5.6vw;
    margin-bottom: 10.67vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 .heading2::before {
  display: none;
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 .block-department {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 .paragraph.margin-lv1 {
    margin-bottom: 6.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-2 {
    margin-top: -3.66vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading2 {
  text-align: center;
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading2 {
    font-size: 5.6vw;
    margin-bottom: 10.67vw;
  }
}
[data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading2::before {
  display: none;
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading3 {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading3 {
    margin-bottom: 6.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading3.withBlock:before {
    height: 14px;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading3.withBlock:before {
    height: 1.02vw;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading3 + .paragraph {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .heading3 {
    font-size: 1.1vw;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .sec-3 .paragraph:last-of-type {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .logo-universe img {
    width: 310px;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .logo-universe img {
    width: 56.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .logo-universe img {
    width: 22.69vw;
  }
}
@media (min-width: 768px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .logo-college img {
    width: 318px;
  }
}
@media (max-width: 767px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .logo-college img {
    width: 59.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="founding-spirit"] [data-about="founding-spirit"] .logo-college img {
    width: 23.28vw;
  }
}
[data-category="about"] [data-about="index"] .intro {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .intro {
    padding: 80px 0 170px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .intro {
    width: calc(100% + 10.67vw);
    margin-left: -5.33vw;
    padding: 10.67vw 0vw 32vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .intro + section {
    margin-top: -60px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .intro + section {
    margin-top: -16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="index"] .intro + section {
    margin-top: -4.39vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .intro .container {
    padding: 0vw 5.33vw;
  }
}
[data-category="about"] [data-about="index"] .intro-bg {
  background-image: url("../img/about/intro-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .intro-bg {
    width: 734px;
    height: 510px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .intro-bg {
    width: 82.67vw;
    height: 68vw;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="index"] .intro-bg {
    width: 53.73vw;
    height: 37.34vw;
  }
}
[data-category="about"] [data-about="index"] .intro .heading2 {
  line-height: 1;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .intro .heading2 {
    margin-top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="index"] .intro .heading2 {
    margin-top: -3.66vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="index"] .intro {
    padding: 5.86vw 0vw 12.45vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .flexPC.-col2 .box {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .flexPC.-col2 .box + .box {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .flexPC.-col4 .box {
    width: calc(25% - 30px);
  }
}
[data-category="about"] [data-about="index"] a.box {
  display: block;
}
[data-category="about"] [data-about="index"] a.box .info_item--image {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] a.box img {
    transition: transform 0.5s;
  }
  [data-category="about"] [data-about="index"] a.box:hover img {
    transform: scale(1.1);
  }
  [data-category="about"] [data-about="index"] a.box:hover .text-link::after {
    transform: translate3d(3px, 0, 0);
  }
}
[data-category="about"] [data-about="index"] [class^="heading"]::before {
  display: none;
}
[data-category="about"] [data-about="index"] .heading3 {
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .heading3 {
    font-size: 4.53vw;
    padding: 5.33vw 0vw;
  }
}
[data-category="about"] [data-about="index"] .heading3-inner > span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .text-link {
    width: 24px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .text-link {
    width: 5.6vw;
  }
}
[data-category="about"] [data-about="index"] .text-link::after {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .text-link::after {
    border-width: 7px 0 0 7px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .text-link::after {
    border-width: 1.87vw 0vw 0vw 2.13vw;
    right: -0.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="index"] .text-link::after {
    border-width: 0.51vw 0vw 0vw 0.51vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-about="index"] .text-link {
    width: 1.76vw;
  }
}
[data-category="about"] [data-about="index"] .heading5 {
  font-weight: 500;
  color: #601986;
  display: inline;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .heading5 {
    height: 100%;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .heading5 {
    padding: 5.33vw 0vw;
  }
}
[data-category="about"] [data-about="index"] .heading5-inner > span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-category="about"] [data-about="index"] .container:not(.block-page_index) .heading5 {
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="index"] .container:not(.block-page_index) .heading5 {
    height: 100%;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="index"] .container:not(.block-page_index) .heading5 {
    padding: 5.33vw 0vw;
  }
}
[data-category="about"] [data-about="index"] .container:not(.block-page_index) .heading5-inner > span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-category="about"] [data-subpage="about_president"] .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (max-width: 767px) {
  [data-category="about"] [data-subpage="about_president"] .text-link span {
    padding-right: 2.67vw;
    letter-spacing: -0.01em;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-subpage="about_president"] .text-link::after {
    border-width: 2.13vw 0vw 0vw 2.13vw;
  }
}
[data-category="about"] [data-subpage="about_president"] .text-link .icon-pdf {
  margin-left: 0.3em;
}
@media (min-width: 768px) {
  [data-category="about"] [data-subpage="about_president"] .text-link .icon-pdf {
    width: 18px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] [data-subpage="about_president"] .text-link .icon-pdf {
    width: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] [data-subpage="about_president"] .text-link .icon-pdf {
    width: 1.32vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .table-container {
    overflow-y: hidden;
    overflow-x: scroll;
  }
  [data-category="about"] .table-container::-webkit-scrollbar {
    height: 7px;
  }
  [data-category="about"] .table-container::-webkit-scrollbar-track {
    margin: 0 2px;
    background: #f5f6f8;
    border-radius: 3px;
  }
  [data-category="about"] .table-container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #999;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion {
  background: #f5f6f8;
  padding: 0;
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question,
  [data-category="about"] .ui-template .accordion-container .accordion .answer {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question,
  [data-category="about"] .ui-template .accordion-container .accordion .answer {
    padding: 5.33vw;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion .question p,
[data-category="about"] .ui-template .accordion-container .accordion .answer p {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question p,
  [data-category="about"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 40px;
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question p,
  [data-category="about"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question p,
  [data-category="about"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 2.93vw;
    padding-right: 4.39vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question,
  [data-category="about"] .ui-template .accordion-container .accordion .answer {
    padding: 2.93vw;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion .question {
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}
[data-category="about"] .ui-template .accordion-container .accordion .question:hover {
  opacity: 0.6;
}
[data-category="about"] .ui-template .accordion-container .accordion .question:before,
[data-category="about"] .ui-template .accordion-container .accordion .question:after {
  margin-top: 0;
  content: "";
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question:before,
  [data-category="about"] .ui-template .accordion-container .accordion .question:after {
    right: 40px;
    width: 20px;
    height: 3px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question:before,
  [data-category="about"] .ui-template .accordion-container .accordion .question:after {
    right: 5.33vw;
    width: 2.67vw;
    height: 0.53vw;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion .question:after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
[data-category="about"] .ui-template .accordion-container .accordion .question.opend:after {
  transform: translateY(-50%) rotate(0);
}
[data-category="about"] .ui-template .accordion-container .accordion .question p {
  line-height: 1.6;
}
[data-category="about"] .ui-template .accordion-container .accordion .question p:before {
  content: "Q";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .ui-template .accordion-container .accordion .question p:before {
    font-size: 2.93vw;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion .answer {
  display: none;
  border-top: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 5.33vw;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion .answer p {
  margin-top: 0;
  line-height: 1.9;
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer p {
    padding-right: 0;
  }
}
[data-category="about"] .ui-template .accordion-container .accordion .answer p:before {
  content: "A";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer p:before {
    font-size: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 2.93vw;
  }
}
[data-category="about"] a.block-other_link:hover {
  opacity: 0.6;
}
[data-category="about"] a.block-other_link:hover .link-container .text-link::after {
  transform: translate3d(10px, 0, 0);
}
@media (max-width: 767px) {
  [data-category="about"] a.block-other_link {
    margin: 0 2.67vw;
  }
}
[data-category="about"] .only-link .text-link:hover {
  opacity: 0.6;
}
@media (min-width: 768px) {
  [data-category="about"] .float-right {
    float: right;
    margin-left: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .float-right {
    margin-left: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .float-left {
    float: left;
    margin-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .float-left {
    margin-right: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .margin-bottom {
    margin-bottom: 52px;
  }
}
[data-category="about"] .margin-bottom.-center {
  text-align: center;
}
@media (max-width: 767px) {
  [data-category="about"] .margin-bottom {
    margin-bottom: 6.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .margin-bottom {
    margin-bottom: 3.81vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .col2-pc_only {
    display: flex;
    justify-content: space-between;
  }
  [data-category="about"] .col2-pc_only.return {
    flex-direction: row-reverse;
  }
  [data-category="about"] .col2-pc_only .box {
    width: 450px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .col2-pc_only .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .col3-pc_only {
    display: flex;
    justify-content: space-between;
  }
  [data-category="about"] .col3-pc_only .box {
    width: 340px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .col3-pc_only .box {
    width: 24.89vw;
  }
}
[data-category="about"] .highlight {
  font-weight: bold;
  border-bottom: 2px dotted #601986;
}
@media (min-width: 768px) {
  [data-category="about"] .content-faculty_top .container-inner {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .content-faculty_top .container-inner {
    padding: 13.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-faculty_top .container-inner {
    padding: 4.39vw 0vw;
  }
}
[data-category="about"] .faculty_heading {
  position: relative;
  text-align: center;
  padding-bottom: 25px;
}
[data-category="about"] .faculty_heading::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  content: "";
  width: 50px;
  height: 1px;
}
[data-category="about"] .department-block {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  [data-category="about"] .department-block {
    height: 460px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .department-block {
    height: 106.667vw;
  }
  [data-category="about"] .department-block p {
    font-size: 3.2vw;
  }
}
[data-category="about"] .department-block p {
  color: #333;
}
[data-category="about"] .department-block-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .department-block-inner {
    width: 460px;
    height: 340px;
  }
  [data-category="about"] .department-block-inner:hover {
    opacity: 0.85;
  }
  [data-category="about"] .department-block-inner:hover .text-link::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="about"] .department-block-inner {
    height: 64vw;
    padding: 5.33vw;
    transform: translateX(-50%);
    left: 50%;
    width: 77.33vw;
    bottom: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .department-block-inner {
    width: 33.67vw;
    height: 24.89vw;
  }
}
[data-category="about"] .department-block::before {
  display: block;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .department-block {
    height: 33.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .department-block.position-left .department-block-inner {
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
    padding: 54px 60px 60px 58px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .department-block.position-left .department-block-inner {
    left: 5.86vw;
    padding: 2.93vw 3.51vw;
  }
}
[data-category="about"] .department-block.position-left::before {
  background-position: center right;
}
@media (min-width: 768px) {
  [data-category="about"] .department-block.position-right .department-block-inner {
    top: 50%;
    transform: translateY(-50%);
    right: 80px;
    padding: 54px 60px 40px 48px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .department-block.position-right .department-block-inner {
    right: 5.86vw;
    padding: 3.95vw 4.39vw 2.93vw 3.51vw;
  }
}
[data-category="about"] .department-block.position-right::before {
  margin: 0 0 0 auto;
  background-position: center left;
}
[data-category="about"] .department-heading-ja {
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="about"] .department-heading-ja {
    margin-bottom: 16px;
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .department-heading-ja {
    margin-bottom: 2.93vw;
    font-size: 6.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .department-heading-ja {
    margin-bottom: 1.17vw;
    font-size: 2.64vw;
  }
}
[data-category="about"] .department-heading-en {
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1.6;
}
@media (min-width: 768px) {
  [data-category="about"] .message-container .withBorder {
    margin-bottom: 54px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .message-container .withBorder {
    margin-bottom: 3.95vw;
  }
}
[data-category="about"] .link-container--back {
  text-align: center;
}
[data-category="about"] .link-container--back .text-link--back {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="about"] .link-container--back .text-link--back {
    font-size: 1.6rem;
  }
  [data-category="about"] .link-container--back .text-link--back:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="about"] .link-container--back .text-link--back {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="about"] .link-container--back .text-link--back::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="about"] .link-container--back .text-link--back::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #601986 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="about"] .link-container--back .text-link--back::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .link-container--back .text-link--back::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .link-container--back .text-link--back::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="about"] .link-container--back .text-link--back span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="about"] .link-container--back .text-link--back span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .link-container--back .text-link--back span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .link-container--back .text-link--back span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .link-container--back .text-link--back {
    font-size: 1.17vw;
  }
}
[data-category="about"] .link-container {
  text-align: right;
}
[data-category="about"] .c-dean_nameplate {
  position: absolute;
  right: 0;
  background-color: #fff;
}
[data-category="about"] .c-dean_nameplate span {
  display: block;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="about"] .c-dean_nameplate {
    bottom: 20px;
    padding: 12px 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-dean_nameplate {
    bottom: 5.33vw;
    padding: 2.67vw 4.27vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-dean_nameplate .job {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-dean_nameplate .job {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-dean_nameplate .job {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-dean_nameplate .name {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-dean_nameplate .name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-dean_nameplate .name {
    font-size: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-dean_nameplate {
    bottom: 1.46vw;
    padding: 0.88vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-faculty_message {
    display: flex;
    justify-content: space-between;
  }
  [data-category="about"] .c-faculty_message .box-image {
    position: relative;
    width: 300px;
    height: 400px;
  }
  [data-category="about"] .c-faculty_message .box-text {
    width: 600px;
  }
  [data-category="about"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-faculty_message .box-image {
    position: relative;
    max-width: 300px;
    margin-bottom: 5.33vw;
    margin-left: auto;
    margin-right: auto;
  }
  [data-category="about"] .c-faculty_message .box-text .heading4 {
    text-align: center;
  }
  [data-category="about"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-faculty_message .box-image {
    width: 21.96vw;
    height: 29.28vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-faculty_message .box-text {
    width: 43.92vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-faculty_message .paragraph:not(:first-of-type) {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-list-class_introduction .heading4 {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-list-class_introduction .heading4 {
    margin-bottom: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-list-class_introduction .heading4 {
    margin-bottom: 0.73vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-list-class_introduction .box-img {
    margin-bottom: 5.33vw;
  }
}
[data-category="about"] .c-department_top {
  position: relative;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  [data-category="about"] .c-department_top {
    height: 300px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-department_top {
    height: 114.93vw;
  }
}
[data-category="about"] .c-department_top .c-department_top-inner {
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="about"] .c-department_top .c-department_top-inner {
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-department_top .c-department_top-inner {
    padding: 0vw 3.47vw;
    bottom: 12vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-category="about"] .c-department_top .c-department_top-text {
  color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .c-department_top .c-department_top-text {
    width: 50%;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-department_top .c-department_top-text {
    font-size: 4vw;
    letter-spacing: normal;
  }
}
[data-category="about"] .c-department_top::before {
  display: block;
  content: "";
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="about"] .c-department_top::before {
    background-position: center left;
    background-size: contain;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-department_top::before {
    background-position: bottom center;
    background-size: 110% 51%;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-department_top.return::before {
    background-position: center right;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-department_top.return .c-department_top-text {
    width: 42%;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-department_top {
    height: 21.96vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-department {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .withBlock_list {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .withBlock_list {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .withBlock_list {
    margin-left: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .wrap-list {
    display: flex;
    justify-content: space-between;
  }
  [data-category="about"] .wrap-list .wrap-list-inner {
    width: 46%;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-info .information-contnt-block {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-info .information-contnt-block {
    margin-bottom: 11.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-info .information-contnt-block {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
[data-category="about"] .list-info .information-contnt-block-date {
  color: #333;
}
[data-category="about"] .list-info .information-contnt-block-title {
  color: #333;
}
@media (min-width: 768px) {
  [data-category="about"] .list-learning_power {
    display: flex;
    justify-content: space-between;
  }
}
[data-category="about"] .list-learning_power li {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="about"] .list-learning_power li {
    padding: 38px 20px 26px;
    width: 300px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-learning_power li {
    padding: 9.07vw 10vw 10.4vw;
  }
  [data-category="about"] .list-learning_power li:not(:first-of-type) {
    margin-top: 8vw;
  }
}
[data-category="about"] .list-learning_power li h3 {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="about"] .list-learning_power li h3 {
    margin-bottom: 24px;
    min-height: 70px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-learning_power li h3 {
    margin-bottom: 7.47vw;
  }
}
[data-category="about"] .list-learning_power li h3::after {
  content: "";
  display: block;
  height: 1px;
  background: #333;
}
@media (min-width: 768px) {
  [data-category="about"] .list-learning_power li h3::after {
    width: 20px;
    margin: 15px auto 0;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-learning_power li h3::after {
    width: 5.33vw;
    margin: 4vw auto 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-learning_power li h3::after {
    width: 1.46vw;
    margin: 1.1vw auto 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-learning_power li h3 {
    margin-bottom: 1.76vw;
    min-height: 5.12vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-learning_power li {
    padding: 2.78vw 1.46vw 1.9vw;
    width: 21.96vw;
  }
}
[data-category="about"] .list-features {
  background-color: #f5f6f8;
}
@media (max-width: 767px) {
  [data-category="about"] .list-features {
    padding-bottom: 5.33vw;
    width: 100vw;
    margin-left: -5.333vw;
  }
}
[data-category="about"] .list-features li {
  position: relative;
}
[data-category="about"] .list-features .area-vertical .text-Q {
  display: block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .area-vertical .text-Q {
    margin-left: 0.5em;
    text-align: center;
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .area-vertical .text-Q {
    font-size: 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .area-vertical .text-Q {
    font-size: 3.66vw;
  }
}
[data-category="about"] .list-features .area-vertical .text {
  margin: auto;
  display: block;
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .area-vertical .text {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}
[data-category="about"] .list-features .box-text {
  position: relative;
  background-color: #fff;
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .box-text {
    width: 94.667%;
    padding: 5.33vw;
  }
  [data-category="about"] .list-features .box-text .headeing3 {
    margin-bottom: 8.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .box-text .area-answer {
    width: 666px;
  }
}
[data-category="about"] .list-features .box-text .area-answer .answer-main {
  display: table;
  margin-bottom: 30px;
}
[data-category="about"] .list-features .box-text .area-answer .answer-main .text-A {
  display: table-cell;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .box-text .area-answer .answer-main .text-A {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .box-text .area-answer .answer-main .text-A {
    display: block;
    font-size: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .box-text .area-answer .answer-main .text-A {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .box-text .area-answer {
    width: 48.76vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .box-text .text {
    margin-bottom: 26px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .box-text .text {
    margin-bottom: 5.33vw;
  }
}
[data-category="about"] .list-features .box-text .text:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .box-text .text {
    margin-bottom: 1.9vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .item-1 .box-text {
    display: flex;
    justify-content: space-between;
    width: 860px;
    margin: -60px 0 auto auto;
    padding: 40px 0 14px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .item-1 .box-text {
    margin: -38.67vw 0vw 0vw auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .item-1 .box-text {
    width: 62.96vw;
    margin: -4.39vw 0vw auto auto;
    padding: 2.93vw 0vw 1.02vw 2.93vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .item-1 .area-vertical {
    margin-bottom: 4.8vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .item-2 .box-img {
    width: 780px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .item-2 .box-img {
    width: 57.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .item-2 .area-vertical {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-features .item-2 .area-vertical {
    position: relative;
    margin: -38.67vw 0vw 0vw;
    width: 94.667%;
    padding: 5.33vw 5.33vw 0vw 5.33vw;
    background-color: #fff;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .item-2 .area-vertical {
    width: 11.71vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-features .item-2 .box-text {
    margin-top: -60px;
    padding: 35px 60px 38px 40px;
    width: 700px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-features .item-2 .box-text {
    margin-top: -4.39vw;
    padding: 2.56vw 4.39vw 2.78vw 2.93vw;
    width: 51.24vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-career .section-point .withBorder {
    margin-bottom: 56px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-career .section-point .withBorder {
    margin-bottom: 4.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-career .section-voice .withBorder {
    margin-bottom: 90px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-career .section-voice .withBorder {
    margin-bottom: 6.59vw;
  }
}
[data-category="about"] .wrap-other_link {
  margin: 0 auto;
}
@media (min-width: 768px) {
  [data-category="about"] .wrap-other_link {
    width: 932px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .wrap-other_link {
    width: 68.23vw;
  }
}
[data-category="about"] .block-other_link {
  background-color: #f5f6f8;
  position: relative;
  display: block;
}
@media (min-width: 768px) {
  [data-category="about"] .block-other_link {
    padding: 20px;
    width: 450px;
    min-height: 140px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-other_link {
    padding: 3.2vw 3.2vw 3.2vw 4.8vw;
  }
  [data-category="about"] .block-other_link:nth-of-type(2) {
    margin-top: 5.33vw;
  }
}
[data-category="about"] .block-other_link.dean_message {
  display: flex;
}
@media (min-width: 768px) {
  [data-category="about"] .block-other_link.dean_message .heading4 {
    margin-left: 25px;
  }
  [data-category="about"] .block-other_link.dean_message img {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-other_link.dean_message .heading4 {
    margin-left: 2.67vw;
  }
  [data-category="about"] .block-other_link.dean_message img {
    width: 18.933vw;
    height: 18.933vw;
  }
}
[data-category="about"] .block-other_link.dean_message p {
  color: #333;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-other_link.dean_message .heading4 {
    margin-left: 1.83vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-other_link.dean_message img {
    width: 7.32vw;
    height: 7.32vw;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-other_link li {
    font-size: 3.2vw;
  }
}
[data-category="about"] .block-other_link .link-container {
  position: absolute;
}
@media (min-width: 768px) {
  [data-category="about"] .block-other_link .link-container {
    bottom: 20px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-other_link .link-container {
    bottom: 2.67vw;
    right: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-other_link .link-container {
    bottom: 1.46vw;
    right: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-other_link {
    width: 32.94vw;
    padding: 1.46vw;
  }
}
[data-category="about"] .list-voice li {
  position: relative;
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li {
    padding: 36px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li {
    padding: 17.6vw 2.67vw 2.67vw;
    margin-top: 13.87vw;
  }
}
[data-category="about"] .list-voice li .text-heading {
  text-align: center;
  color: #601986;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li .text-heading {
    margin-left: 50px;
    width: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .text-heading {
    width: 36.6vw;
    margin-left: 3.66vw;
  }
}
[data-category="about"] .list-voice li .wrap-number {
  position: absolute;
}
[data-category="about"] .list-voice li .wrap-number .wrap-number-inner {
  position: relative;
}
[data-category="about"] .list-voice li .wrap-number .wrap-number-inner .number {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li .wrap-number {
    top: -30px;
  }
  [data-category="about"] .list-voice li .wrap-number .wrap-number-inner {
    width: 120px;
    height: 120px;
  }
  [data-category="about"] .list-voice li .wrap-number .number {
    font-size: 40px;
    bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li .wrap-number {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  [data-category="about"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number_bg-under.svg") no-repeat center/contain;
    width: 21.33vw;
    height: 24.27vw;
  }
  [data-category="about"] .list-voice li .wrap-number .wrap-number-inner .number {
    font-size: 8vw;
    bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .wrap-number {
    top: -2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .wrap-number .wrap-number-inner {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .wrap-number .number {
    font-size: 2.93vw;
    bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li .box-img img {
    margin-bottom: 16px;
    width: 260px;
    height: 330px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li .box-img img {
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .box-img img {
    margin-bottom: 1.17vw;
    width: 19.03vw;
    height: 24.16vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li .box-img {
    position: absolute;
    top: -30px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li .box-img {
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .box-img {
    top: -2.2vw;
  }
}
[data-category="about"] .list-voice li .text-voice {
  border-radius: 20px;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li .text-voice {
    padding: 34px 40px;
    width: 580px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li .text-voice {
    padding: 2.67vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li .text-voice {
    padding: 2.49vw 2.93vw;
    width: 42.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li:nth-of-type(odd) .box-img {
    right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li:nth-of-type(odd) .box-img {
    right: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li:nth-of-type(odd) .wrap-number {
    left: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li:nth-of-type(odd) .wrap-number {
    left: -1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li:nth-of-type(even) .box-img {
    left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li:nth-of-type(even) .box-img {
    left: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li:nth-of-type(even) .text-heading {
    margin-right: 50px;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li:nth-of-type(even) .text-heading {
    margin-right: 3.66vw;
  }
}
[data-category="about"] .list-voice li:nth-of-type(even) .text-voice {
  margin-left: auto;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li:nth-of-type(even) .wrap-number {
    right: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li:nth-of-type(even) .wrap-number {
    right: -1.46vw;
  }
}
[data-category="about"] .list-voice li.youtube {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li.youtube {
    padding: 0 40px 0 160px;
    margin-top: -40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li.youtube {
    padding: 17.6vw 0vw 2.67vw;
  }
}
[data-category="about"] .list-voice li.youtube .text-heading {
  text-align: left;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li.youtube .text-heading {
    margin-left: 0;
    width: 100%;
  }
}
[data-category="about"] .list-voice li.youtube .box-youtube {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li.youtube .box-youtube {
    padding-top: 48.25%;
    width: 540px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .list-voice li.youtube .box-youtube {
    width: 100%;
    padding-top: 56.25%;
  }
}
[data-category="about"] .list-voice li.youtube .box-youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li.youtube .box-youtube {
    width: 39.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .list-voice li.youtube .wrap-number {
    top: 0;
    right: auto;
    left: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li.youtube .wrap-number {
    left: -1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li.youtube {
    padding: 0vw 2.93vw 0vw 11.71vw;
    margin-top: -2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .list-voice li {
    padding: 2.64vw 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .exam_preparation img {
    width: 450px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .exam_preparation img {
    width: 32.94vw;
  }
}
[data-category="about"] .block-learning_support {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="about"] .block-learning_support {
    padding: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-learning_support {
    padding: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-learning_support {
    padding: 16px;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="about"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item a:hover .info_item--image img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
[data-category="about"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--image {
    height: 220px;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item .info_item--image img {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item .info_item--image {
    height: 16.11vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="about"] .c-info_item .info_item--date {
  color: #333;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--date {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item .info_item--date {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item .info_item--date {
    font-size: 1.17vw;
  }
}
[data-category="about"] .c-info_item .info_item--category {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--category {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item .info_item--category {
    margin-left: 2.67vw;
  }
}
[data-category="about"] .c-info_item .info_item--category span {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
  color: #601986;
  border: 1px solid #601986;
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--category span {
    font-size: 1.2rem;
    padding: 3px 10px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item .info_item--category span {
    font-size: 3.2vw;
    padding: 1.33vw;
    min-width: 24.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item .info_item--category span {
    font-size: 0.88vw;
    padding: 0.22vw 0.73vw;
    min-width: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item .info_item--category {
    margin-left: 1.46vw;
  }
}
[data-category="about"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="about"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item .info_item--title {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .c-info_item {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .contents-field {
    width: 640px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .contents-field {
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .contents-field {
    width: 46.85vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .page-header {
    background-image: url("../img/about/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .page-header {
    background-image: url("../img/about/header_bg@2x.jpg");
    background-position: right top;
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="sister_school"] .page-header,
  [data-category="about"][data-pages="camosun-2019"] .page-header,
  [data-category="about"][data-pages="camosun-2018"] .page-header,
  [data-category="about"][data-pages="nub-2018"] .page-header {
    background-image: url("../img/about/sister_school/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="sister_school"] .page-header,
  [data-category="about"][data-pages="camosun-2019"] .page-header,
  [data-category="about"][data-pages="camosun-2018"] .page-header,
  [data-category="about"][data-pages="nub-2018"] .page-header {
    background-image: url("../img/about/sister_school/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="founding-spirit"] .page-header {
    background-image: url("../img/about/founding-spirit/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="founding-spirit"] .page-header {
    background-image: url("../img/about/founding-spirit/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="about-corporation"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report-r1"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report-h30"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report-h29"] .page-header,
  [data-category="about"][data-pages="about-regulations"] .page-header,
  [data-category="about"][data-pages="about-regulations-action-plan"] .page-header,
  [data-category="about"][data-pages="about-recruit"] .page-header {
    background-image: url("../img/about/corporation/header_bg.jpg");
    background-position: left bottom;
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="about-corporation"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report-r1"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report-h30"] .page-header,
  [data-category="about"][data-pages="about-corporation-business-report-h29"] .page-header,
  [data-category="about"][data-pages="about-regulations"] .page-header,
  [data-category="about"][data-pages="about-regulations-action-plan"] .page-header,
  [data-category="about"][data-pages="about-recruit"] .page-header {
    background-image: url("../img/about/corporation/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="about"][data-pages="about-corporation"] .box div {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
}
@media (max-width: 767px) {
  [data-category="about"][data-pages="about-corporation"] .box div {
    padding-left: 2.5em;
    text-indent: -2.5em;
  }
}
[data-category="about"][data-pages="about-corporation"] .box div a {
  line-height: 1.4;
}
[data-category="about"][data-pages="university-policy"] .ui-template [class^="heading"].withBorder::before {
  background: #5f1985;
}
[data-category="about"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #5f1985;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="about"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="about"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="about"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="about"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #5f1985;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="about"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #5f1985;
}
@media (min-width: 768px) {
  [data-category="about"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="about"] .block-page_index {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="about"] .block-page_index {
    padding: 54px 70px 57px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-page_index {
    padding: 9.6vw 5.33vw;
  }
}
[data-category="about"] .block-page_index .heading4 {
  color: $themeColor;
}
@media (min-width: 768px) {
  [data-category="about"] .block-page_index .heading4 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-page_index .heading4 {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-page_index .heading4 {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .block-page_index .list-page_index li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
[data-category="about"] .block-page_index .list-page_index li a {
  font-weight: normal;
  color: $themeColor;
}
[data-category="about"] .block-page_index .list-page_index li a.current {
  border-bottom: 1px solid $themeColor;
}
[data-category="about"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .block-page_index {
    padding: 3.95vw 5.12vw 4.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-latest-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-latest-container li {
    width: calc(33.3% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="about"] .content-latest-container li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-latest-container li +li {
    margin-left: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-latest-container li +li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-latest-container li:nth-of-type(4n) {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-latest-container li {
    width: calc(33.3% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-archive-list + li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .content-archive-list + li {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-archive-list + li {
    margin-top: 1.46vw;
  }
}
[data-category="about"] .content-archive-list a {
  color: #333;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="about"] .content-archive-list a {
    display: flex;
    padding: 35px 40px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-category="about"] .content-archive-list a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .content-archive-list a {
    display: block;
    padding: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-archive-list a {
    padding: 2.56vw 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-archive-header {
    width: 24%;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-archive-header {
    width: 30%;
  }
}
@media (min-width: 768px) {
  [data-category="about"] .content-archive-title {
    font-size: 1.6rem;
    line-height: 1.63;
    margin-top: -0.3em;
  }
}
@media (max-width: 767px) {
  [data-category="about"] .content-archive-title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="about"] .content-archive-title {
    font-size: 1.17vw;
  }
}
[data-category="about"] .ui-template [class^="heading"].withBorder:before {
  background: #ccc;
}
[data-category="about"] .color-purple {
  color: #5f1985;
  font-size: 13px;
}
@media (min-width: 768px) {
  [data-category="about"] .link-container {
    margin-left: 68px;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="moridailog"] .heading2 a {
    transition: opacity 0.2s;
  }
  [data-category="about"] [data-about="moridailog"] .heading2 a:hover {
    opacity: 0.6;
  }
}
[data-category="about"] [data-about="moridailog"] .heading2 small {
  font-size: 0.8em;
}
[data-category="about"] [data-about="moridailog"] .pdf-icon {
  display: inline-block;
  width: 21px;
  height: 20px;
  background: url("../img/common/icon_pdf.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: -1px;
}
@media (max-width: 767px) {
  [data-category="about"] [data-about="moridailog"] .pdf-icon {
    width: 5.47vw;
    height: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="about"] [data-about="moridailog"] .img a {
    transition: opacity 0.2s;
  }
  [data-category="about"] [data-about="moridailog"] .img a:hover {
    opacity: 0.6;
  }
}
@media (min-width: 768px) {
  [data-category="access"] .page-header {
    background-image: url("../img/access/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="access"] .page-header {
    background-image: url("../img/access/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="access"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .box.text {
    width: 60%;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .box.img {
    width: 37%;
  }
}
[data-category="access"] [data-access="index"] .iframe-map {
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="access"] [data-access="index"] .c-info_item a {
  display: block;
}
[data-category="access"] [data-access="index"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--image {
    height: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--image {
    height: 20.5vw;
  }
}
[data-category="access"] [data-access="index"] .c-info_item img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="access"] [data-access="index"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
  }
}
[data-category="access"] [data-access="index"] .c-info_item .info_item--title:before {
  content: "";
  display: inline-block;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title:before {
    width: 8px;
    height: 12px;
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item .info_item--title {
    font-size: 1.02vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .c-info_item {
    width: calc(50% - 1.46vw);
  }
}
[data-category="access"] [data-access="index"] .info-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-link-container {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-link-container {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-link-container {
    margin-top: 0.73vw;
  }
}
[data-category="access"] [data-access="index"] .info-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
  pointer-events: none;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-text-link {
    font-size: 1.3rem;
  }
  [data-category="access"] [data-access="index"] .info-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="access"] [data-access="index"] .info-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="access"] [data-access="index"] .info-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="access"] [data-access="index"] .info-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="access"] [data-access="index"] .info-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="access"] [data-access="index"] .info-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .page-header {
    background-image: url("../img/for-alumni/alumni-index/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .page-header {
    background-image: url("../img/for-alumni/alumni-index/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .content-for_menu-inner {
    padding: 78px 0 42px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .content-for_menu-inner {
    padding: 40px 0 62px;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-1.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 100px;
    background-size: 78px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 75px;
    background-size: 58px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-2.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 94px;
    background-size: 88px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 70px;
    background-size: 65px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-3.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 111px;
    background-size: 78px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 84px;
    background-size: 58px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-2.svg");
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 94px;
    background-size: 88px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 70px;
    background-size: 65px auto;
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 {
    height: 280px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 {
    height: 218px;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-4.svg");
  bottom: 20%;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 98px;
    background-size: 80px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 71px;
    background-size: 60px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_inner .for_menu_title_ja {
  line-height: 1.4;
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--5 .wrap_outer::after {
  bottom: 13%;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 {
    height: 280px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 {
    height: 218px;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
  background-image: url("../img/for-alumni/alumni-index/icon-5.svg");
  bottom: 20%;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 101px;
    background-size: 61px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 74px;
    background-size: 45px auto;
  }
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_inner .for_menu_title_ja {
  line-height: 1.4;
}
[data-pages="alumni-index"] .list__for_menu .item__for_menu--6 .wrap_outer::after {
  bottom: 13%;
}
[data-pages="alumni-index"] .container-info {
  margin-top: 10px;
}
[data-pages="alumni-index"] .link-content {
  text-align: right;
}
[data-pages="alumni-index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="alumni-index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="alumni-index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="alumni-index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="alumni-index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="alumni-index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="alumni-index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="alumni-index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="alumni-index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  [data-pages="circles"] .list-circles_link::after {
    display: block;
    content: "";
    width: 340px;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circle_data {
    width: 31.482%;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link {
    width: 31.482%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  [data-pages="circles"] .list-circles_link .item-circles_link:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link {
    margin-bottom: 8vw;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
  font-weight: 400;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
    font-size: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category {
    font-size: 2.34vw;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
  font-weight: 400;
  margin-top: 10px;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
    font-size: 4.27vw;
  }
}
[data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en:after {
  content: "";
  display: inline-block;
  background-image: url("../img/career/arrow.svg");
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en:after {
    padding-left: 10px;
    width: 6px;
    height: 12px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en:after {
    padding-left: 2.67vw;
    width: 1.6vw;
    height: 3.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] .list-circles_link .item-circles_link .box-text .circles_category-en {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] .list-circles_link::after {
    width: 24.89vw;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .ui-template .container.-limit.block-circles_slider {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    width: 100vw;
  }
  [data-pages="circles"] [data-campus_life="circles"] img {
    width: 100%;
    height: auto;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider {
  position: relative;
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text {
  z-index: 100;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 0.1em;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
    font-size: 96px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
    font-size: 12.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_title {
    font-size: 7.03vw;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles"] .block-circles_slider .box-text .slider_sub_title {
    font-size: 1.32vw;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider {
  position: relative;
  height: 420px;
  margin: 0 auto;
  padding: 0 auto;
  text-align: center;
}
@media (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider {
    height: 30.75vw;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider {
    height: 83vw;
  }
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider {
  margin: 0;
  padding: 0;
  list-style: none;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider img {
  width: 100%;
  margin: 0 auto;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-iteration-count: infinite;
  animation-duration: 21s;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider:nth-child(1) {
  animation-name: wrap-crossfade_slider;
  animation-delay: -1s;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider:nth-child(2) {
  animation-name: wrap-crossfade_slider;
  animation-delay: 6s;
  opacity: 0;
}
[data-pages="circles"] [data-campus_life="circles"] .wrap-crossfade_slider .list-crossfade_slider .item-crossfade_slider:nth-child(3) {
  animation-name: wrap-crossfade_slider;
  animation-delay: 13s;
  opacity: 0;
}
@-moz-keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes wrap-crossfade_slider {
  0% {
    opacity: 0;
  }
  4.76% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  42.85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] img {
    width: 100%;
    height: auto;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab {
  padding-bottom: 0 !important;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab a {
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab a {
    height: auto;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab span {
  color: #333;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab.active {
  padding-bottom: 20px !important;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .tab-container .tab.active span {
  color: #601986;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_open {
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_open:hover {
    opacity: 0.7;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents {
  z-index: 2001;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  transition: 0.3s opacity ease, 0s visibility 0s linear;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents {
    transform: translate(-40%, -50%);
  }
}
@media (max-width: 1100px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents {
    transform: translate(-50%, -50%);
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .js-modal_contents.is-modalopen {
  opacity: 1;
  visibility: visible;
  transition: 0.3s opacity ease, 0s visibility 0s linear;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal_overlay {
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.55);
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: 0.3s opacity ease, 0s visibility 0s linear, backdrop-filter 0.2s;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal_overlay.is-modalopen {
  visibility: visible;
  opacity: 1;
  transition: 0.3s opacity ease, 0s visibility 0s linear;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close {
  position: absolute;
  color: #fff;
  background-image: url("../img/campus-life/circles/icon-close.svg");
  background-size: 50% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #333;
  border-radius: 50%;
  transition: background-color 0.2s;
  z-index: 1000;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close {
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
  }
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close:hover {
    background-color: #999;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal_close {
    top: -5.33vw;
    right: -5.33vw;
    width: 10.67vw;
    height: 10.67vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
  background-color: #fff;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
    max-height: 80vh;
    width: 860px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
    max-height: 68vh;
    width: 89vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__contents_wrap {
    width: 62.96vw;
    max-height: 66vh;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
  overflow-y: auto;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
    max-height: 73vh;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
    max-height: 60vh;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__container {
    max-height: 57vh;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #b08cc3;
  z-index: 900;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
    padding: 24px 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
    padding: 3.2vw 5.33vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
    font-size: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading {
    font-size: 1.9vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
    font-size: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading .heading-en {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__heading {
    padding: 1.76vw 2.93vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 40px 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
    padding: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-img {
    width: 56.25%;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-img {
    margin-bottom: 5.33vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-img img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .box-text {
    width: 38%;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
    padding-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content .with_Border {
    padding-bottom: 1.46vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content [class^="heading"].withBlock:before {
  height: 1em;
  margin-top: 1px;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__content {
    padding: 2.93vw 2.93vw 2.93vw 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .circle_name {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .circle_name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .circle_name {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__title {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__title {
    font-size: 1.32vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__schedule {
  display: flex;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__schedule .dt {
  white-space: nowrap;
  margin-right: 0.3em;
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
    padding: 0 0 40px 0;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
    padding: 0 0 2.67vw 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .modal__sns {
    padding: 0vw 0vw 2.93vw 0vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns {
    margin-right: 35px;
  }
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns {
    margin-right: 4vw;
    width: 8vw;
  }
}
[data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns:last-of-type {
  margin-right: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="circles"] [data-campus_life="circles_subdir"] .list-sns .item-sns {
    margin-right: 2.56vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .flex-reverse {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-right: 0;
  }
  [data-pages="campus-life-committee"] .flex-reverse .box {
    width: 450px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .flex-reverse {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .flex-reverse .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .flex-campus-life {
    display: flex;
    justify-content: space-between;
    flex-direction: inherit;
    margin-right: 0;
  }
  [data-pages="campus-life-committee"] .flex-campus-life .box {
    width: 450px;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .flex-campus-life {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .flex-campus-life .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .snsbuttons > li {
    margin-left: 100px;
  }
}
[data-pages="campus-life-committee"] .sns-wrap {
  text-align: right;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .sns-wrap {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .sns-wrap {
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .sns-wrap {
    right: 2.2vw;
    bottom: 2.2vw;
  }
}
[data-pages="campus-life-committee"] .heading-club {
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .heading-club {
    font-size: 21px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .heading-club {
    font-size: 5.07vw;
  }
}
[data-pages="campus-life-committee"] .heading-club:before {
  content: "";
  display: inline-block;
  background: url("../img/common/deco-club-heading.svg") no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .heading-club:before {
    width: 4px;
    height: 24px;
    margin-right: 12px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .heading-club:before {
    width: 0.8vw;
    height: 4.67vw;
    margin-right: 2.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .heading-club:before {
    width: 0.29vw;
    height: 1.76vw;
    margin-right: 0.88vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .heading-club {
    font-size: 1.54vw;
  }
}
[data-pages="campus-life-committee"] .club-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list {
    margin-top: 50px;
    padding-bottom: 20px;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list {
    margin-top: 7.33vw;
    justify-content: center;
  }
}
[data-pages="campus-life-committee"] .club-list li {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li {
    position: relative;
    background: #f5f6f8;
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list li {
    border-radius: 1.6vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #f5f6f8;
    z-index: -1;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(1) {
    margin-right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(1) {
    margin-right: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) {
    width: calc(100% / 2 - 30px / 2);
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) .club-list-content {
    height: 250px;
    min-height: 200px;
    padding: 30px;
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) .club-list-content {
    height: 18.3vw;
    padding: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(-n+2) {
    width: calc(100% / 2 - 2.2vw / 2);
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(3),
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(4) {
    margin-right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(3),
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(4) {
    margin-right: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) {
    width: calc(100% / 3 - 60px / 3);
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) .club-list-content {
    height: 330px;
    min-height: 280px;
    padding: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) .club-list-content {
    height: 24.16vw;
    padding: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li:nth-of-type(n+3) {
    margin-top: 2.93vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list li:not(:first-of-type) {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list li {
    border-radius: 0.44vw;
  }
}
[data-pages="campus-life-committee"] .club-list-thumb img {
  width: 100%;
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list-content {
    background: #f5f6f8;
    padding: 5.33vw 5.33vw 4.67vw;
  }
}
[data-pages="campus-life-committee"] .club-list-content-copy {
  font-weight: 400;
  line-height: 2;
  text-align: justify;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list-content-copy {
    font-size: 14px;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list-content-copy {
    font-size: 3.47vw;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list-content-copy {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-list-content .btn-more {
    background: #f5f6f8;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-list-content .btn-more {
    margin-top: 3.33vw;
    margin-left: auto;
    background: transparent;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list-content .btn-more {
    bottom: 2.2vw;
    right: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-list {
    margin-top: 3.66vw;
    padding-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table {
    padding-top: 13.33vw;
  }
}
[data-pages="campus-life-committee"] .club-table-list {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table-list {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table-list li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list li {
    width: 100%;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-committee"] .club-table-list li:not(:nth-of-type(4n+1)) {
    margin-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table-list li:not(:nth-of-type(4n+1)) {
    margin-left: 1.46vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list li:not(:first-of-type) {
    margin-top: 5.33vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-committee"] .club-table-list li .cmn-btn {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table-list li {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table-list {
    margin-top: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="campus-life-committee"] .club-table {
    padding-top: 4.39vw;
  }
}
[data-pages="campus-life-event-calendar"] .set-item-campus-life {
  width: 452px;
}
@media (max-width: 1365px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 33.09vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 452px;
  }
}
@media (max-width: 1100px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 33.09vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life {
    width: 100%;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .set-item-campus-life .wrap-campus {
    width: 68.81vw;
  }
}
[data-pages="campus-life-event-calendar"] .adjust-text {
  width: 171px;
}
@media (max-width: 1365px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 12.52vw;
  }
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 171px;
  }
}
@media (max-width: 1100px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 12.52vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-event-calendar"] .adjust-text {
    width: 22.8vw;
  }
}
[data-pages="campus-life-event-calendar"] .img {
  width: 452px;
}
@media (min-width: 768px) {
  [data-pages="campus-life-event-calendar"] .img {
    width: 33.09vw;
  }
}
@media (max-width: 1100px) {
  [data-pages="campus-life-event-calendar"] .img {
    width: 33.09vw;
  }
}
@media (max-width: 767px) {
  [data-pages="campus-life-event-calendar"] .img {
    width: 60.27vw;
  }
}
[data-pages="campus-life-event-calendar"] .withBlock.-spring:before {
  background: #ff93b3;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-spring:before {
  background-color: #ff93b3;
}
[data-pages="campus-life-event-calendar"] .withBlock.-summer:before {
  background: #92c1ea;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-summer:before {
  background-color: #92c1ea;
}
[data-pages="campus-life-event-calendar"] .withBlock.-autumn:before {
  background: #f87433;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-autumn:before {
  background-color: #f87433;
}
[data-pages="campus-life-event-calendar"] .withBlock.-winter:before {
  background: #8081b8;
}
[data-pages="campus-life-event-calendar"] .list-withDot li.-winter:before {
  background-color: #8081b8;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-img {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-img {
    margin-top: 8vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-place {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-place {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-place {
    margin-top: 10.67vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list {
    width: 340px;
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list {
    width: 100%;
    border-radius: 1.6vw;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(n+4) {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list:not(:first-of-type) {
    margin-top: 4vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(1) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_1.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(2) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_2.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(3) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_3.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(4) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_4.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(5) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_5.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list:nth-of-type(6) .campus-list-content-title:before {
  background: url("../img/campus-life/map/icon-campus-place_6.png") no-repeat;
  background-size: contain;
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-thumb img {
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content {
    padding: 20px 10px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content {
    padding: 5.33vw 2.67vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title {
  color: $base-color-purple;
  font-weight: 700;
  position: relative;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title {
    font-size: 20px;
    padding-left: 52px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title {
    font-size: 5.33vw;
    padding-left: 14vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title:before {
    width: 50px;
    height: 62px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-title:before {
    width: 13.07vw;
    height: 16.27vw;
  }
}
[data-category="campus-life"] [data-campus-life="map"] .campus-list-content-copy {
  font-weight: 400;
  line-height: 2;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-copy {
    font-size: 16px;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="map"] .campus-list-content-copy {
    font-size: 3.47vw;
    margin-top: 4.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"]:not([data-pages="circles"]) .page-header {
    background-image: url("../img/campus-life/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"]:not([data-pages="circles"]) .page-header {
    background-image: url("../img/campus-life/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"][data-pages="campus-life-circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"][data-pages="campus-life-circles"] .page-header {
    background-image: url("../img/campus-life/circles/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li.c-info_item {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li.c-info_item a {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
[data-category="campus-life"] [data-campus-life="index"] .content-latest-container .information-contnt-block a {
  color: #333;
}
[data-category="campus-life"] [data-campus-life="index"] .info-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-link-container {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-link-container {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-link-container {
    margin-top: 0.73vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .info-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link {
    font-size: 1.3rem;
  }
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .info-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .info-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap {
    flex-wrap: wrap;
  }
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap .c-info_item:nth-of-type(n+3) {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap .info-link-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .flexPC.-wrap .c-info_item:nth-of-type(n+3) {
    margin-top: 2.93vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item a {
    height: 100%;
    padding-bottom: 35px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item a {
    padding-bottom: 3.66vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--image {
    height: 280px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--image {
    height: 20.5vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title:before {
  content: "";
  display: inline-block;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title:before {
    width: 8px;
    height: 12px;
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--title {
    font-size: 1.02vw;
  }
}
[data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
    font-size: 1.2rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
    font-size: 3.2vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item .info_item--caption {
    font-size: 0.88vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus-life="index"] .c-info_item {
    width: calc(50% - 1.46vw);
  }
}
[data-category="campus-life"] [data-campus_life="sports"] .tab-container {
  justify-content: center;
}
@media (min-width: 768px) {
  [data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-image,
  [data-category="campus-life"] [data-campus_life="sports"] .withBlock {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-image,
  [data-category="campus-life"] [data-campus_life="sports"] .withBlock {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-image,
  [data-category="campus-life"] [data-campus_life="sports"] .withBlock {
    margin-left: 1.46vw;
  }
}
[data-category="campus-life"] [data-campus_life="sports"] .content-latest-container {
  display: flex;
}
@media (max-width: 767px) {
  [data-category="campus-life"] [data-campus_life="sports"] .content-latest-container {
    flex-direction: column;
  }
}
[data-category="campus-life"] [data-campus_life="sports"] .information-contnt-block-inner {
  padding: 0;
}
.content-container-first {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .content-container-first {
    flex-direction: column;
  }
}
.content-container-second {
  display: flex;
  justify-content: space-between;
}
.content-container-second li {
  width: 45%;
}
@media (max-width: 767px) {
  .content-container-second li {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .content-container-second {
    flex-direction: column;
    width: 100%;
  }
}
.content-container-third {
  display: flex;
  justify-content: space-between;
}
.content-container-third li {
  width: 45%;
}
@media (max-width: 767px) {
  .content-container-third {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  [data-pages="career"] .page-header {
    background-image: url("../img/nyushi/index/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="career"] .page-header {
    background-image: url("../img/nyushi/index/header_bg@2x.jpg");
  }
}
.ui-template .anchorlinkL-container {
  justify-content: center;
}
[data-category="career"].pages {
  overflow: unset;
}
[data-category="career"] .c-pagenav--open-button {
  color: #5f1985;
}
[data-category="career"] .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%235f1985' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
@media (min-width: 768px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg-sp.jpg");
  }
}
[data-category="career"] .color_purple {
  color: #601986;
}
[data-category="career"] .color_green {
  color: #9ec238;
}
[data-category="career"] .color_blue {
  color: #1a86b9;
}
@media (min-width: 768px) {
  [data-category="career"] .flexPC .box-img {
    width: 47.874%;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .flexPC .box-text {
    width: 47.874%;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .item-where_work {
    display: flex;
  }
}
[data-category="career"] .list-where_work .heading4 {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .heading4 {
    width: 18%;
    max-width: 164px;
    line-height: 45px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .heading4 {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .heading4 {
    line-height: 3.29vw;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink {
    width: 34.1%;
    max-width: 320px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink {
    padding: 4vw 5.33vw 4vw 18vw;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) {
    margin-bottom: 5.33vw;
  }
}
[data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
  background-image: url("../img/career/icon-course.svg");
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
    background-size: 20px auto;
    background-position: center left;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
    background-position: center left 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) .inner {
    background-size: 1.46vw auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(1) {
    margin-right: 2.93vw;
  }
}
[data-category="career"] .list-where_work .buttonlink:nth-of-type(2) .inner {
  background-image: url("../img/career/icon-graduates.svg");
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(2) .inner {
    background-size: 31px auto;
    background-position: center left;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] .list-where_work .buttonlink:nth-of-type(2) .inner {
    background-size: 2.27vw auto;
  }
}
[data-category="career"] .list-where_work .buttonlink .inner .text {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink .inner .text {
    padding-right: 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  [data-category="career"] .list-where_work .buttonlink .inner .text {
    padding-left: 10.67vw;
    text-align: left;
  }
}
[data-category="career"] .list-where_work .buttonlink.color_green {
  background: #9ec238;
  border: 1px solid #9ec238;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink.color_green:hover {
    background: #fff;
    border: 1px solid #9ec238;
  }
  [data-category="career"] .list-where_work .buttonlink.color_green:hover .inner .text {
    color: #9ec238;
  }
  [data-category="career"] .list-where_work .buttonlink.color_green:hover .inner .text::before {
    background-color: #9ec238;
  }
  [data-category="career"] .list-where_work .buttonlink.color_green:hover .inner .text::after {
    border-color: transparent transparent transparent #9ec238;
  }
}
[data-category="career"] .list-where_work .buttonlink.color_blue {
  background: #1a86b9;
  border: 1px solid #1a86b9;
}
@media (min-width: 768px) {
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover {
    background: #fff;
    border: 1px solid #1a86b9;
  }
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover .inner .text {
    color: #1a86b9;
  }
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover .inner .text::before {
    background-color: #1a86b9;
  }
  [data-category="career"] .list-where_work .buttonlink.color_blue:hover .inner .text::after {
    border-color: transparent transparent transparent #1a86b9;
  }
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_purple:nth-of-type(1):hover .inner {
  background-image: url("../img/career/icon-course-purple.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_purple:nth-of-type(2):hover .inner {
  background-image: url("../img/career/icon-graduates-purple.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_green:nth-of-type(1):hover .inner {
  background-image: url("../img/career/icon-course-green.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_green:nth-of-type(2):hover .inner {
  background-image: url("../img/career/icon-graduates-green.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_blue:nth-of-type(1):hover .inner {
  background-image: url("../img/career/icon-course-blue.svg");
}
[data-category="career"] .list-where_work .item-where_work .buttonlink.color_blue:nth-of-type(2):hover .inner {
  background-image: url("../img/career/icon-graduates-blue.svg");
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="employment-uv"] .box-img img,
  [data-category="career"] [data-career="employment-jc"] .box-img img {
    width: 100%;
    height: auto;
  }
}
[data-category="career"] [data-career="career-index"] .heading3 {
  color: #601986;
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
}
[data-category="career"] [data-career="career-index"] .link-content {
  text-align: right;
}
[data-category="career"] [data-career="career-index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-category="career"] [data-career="career-index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="career"] [data-career="career-index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="career"] [data-career="career-index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="career"] [data-career="career-index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .box-img {
    overflow: hidden;
  }
  [data-category="career"] [data-career="career-index"] .box-img:hover img {
    transform: scale(1.1);
  }
  [data-category="career"] [data-career="career-index"] .box-img:hover +.box-text .link-container::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (min-width: 768px) {
  [data-category="career"] [data-career="career-index"] .box-img img {
    transition: transform 0.5s;
  }
}
@media (max-width: 767px) {
  [data-category="career"] [data-career="career-index"] .box-img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="career"] .page-header {
    background-image: url("../img/career/header_bg@2x.jpg");
  }
}
[data-pages="career-employment-center"] .link-content {
  text-align: right;
}
[data-pages="career-employment-center"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="career-employment-center"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="career-employment-center"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="career-employment-center"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="career-employment-center"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="career-employment-center"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="career-employment-center"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="career-employment-center"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="career-employment-center"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="career-employment-center"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="career-employment-center"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="career-employment-center"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="career-employment-center"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="contact-list"] .page-header {
    background-image: url("../img/contact-list/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="contact-list"] .page-header {
    background-image: url("../img/contact-list/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="contact-list"] [data-contact-list="index"] .inquiry {
    min-width: 310px;
  }
}
@media (max-width: 767px) {
  [data-category="contact-list"] [data-contact-list="index"] .inquiry {
    width: 100% !important;
  }
}
[data-category="cooperation"] .box {
  width: auto;
}
@media (min-width: 768px) {
  [data-category="cooperation"] .page-header {
    background-image: url("../img/cooperation/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] .page-header {
    background-image: url("../img/cooperation/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .content-latest-container li.c-info_item {
  margin-bottom: 0;
}
[data-category="cooperation"] [data-cooperation="index"] .content-latest-container li.c-info_item a {
  padding-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
[data-category="cooperation"] [data-cooperation="index"] .content-latest-container .information-contnt-block a {
  color: #333;
}
[data-category="cooperation"] [data-cooperation="index"] .info-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-link-container {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-link-container {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-link-container {
    margin-top: 0.73vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .info-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link {
    font-size: 1.3rem;
  }
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .info-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .info-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .flexPC.-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .flexPC.-wrap .info-link-container {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 0;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item a {
    height: 100%;
    padding-bottom: 35px;
  }
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item a:hover .info-text-link::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item a {
    padding-bottom: 3.66vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title:before {
  content: "";
  display: inline-block;
  background: url("../img/common/textlink_arrow.png") no-repeat;
  background-position: center;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title:before {
    width: 8px;
    height: 12px;
    margin-right: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title:before {
    width: 2.13vw;
    height: 3.2vw;
    margin-right: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--title {
    font-size: 1.02vw;
  }
}
[data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
    font-size: 1.2rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
    font-size: 3.2vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item .info_item--caption {
    font-size: 0.88vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="index"] .c-info_item {
    width: calc(50% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .box-image {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-image {
    width: 300px;
    height: 400px;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .box-image img {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-image {
    width: 21.96vw;
    height: 29.28vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-text {
    width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .box-text {
    width: 43.92vw;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
  position: absolute;
  right: 0;
  background-color: #fff;
}
[data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate span {
  display: block;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
    bottom: 20px;
    padding: 12px 20px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
    bottom: 5.33vw;
    padding: 2.67vw 4.27vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .job {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .job {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .job {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .name {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate .name {
    font-size: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="center"] .c-dean_nameplate {
    bottom: 1.46vw;
    padding: 0.88vw 1.46vw;
  }
}
[data-category="cooperation"] [data-cooperation="center"] .img-center {
  text-align: center;
}
[data-category="cooperation"] [data-cooperation="center"] .anchorlink:not(:last-of-type) {
  margin-right: 0;
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="activities"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="activities"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="activities"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="cooperation"] [data-cooperation="collaborative"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="cooperation"] [data-cooperation="collaborative"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="cooperation"] [data-cooperation="collaborative"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content {
    padding: 70px 0 165px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content {
    padding: 8vw 0vw 34.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-container {
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-container {
    max-width: 79.06vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content {
    padding: 5.12vw 0vw 12.08vw;
  }
}
[data-pages="detail"] .content {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-section {
    padding: 0 120px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-section {
    padding: 0vw 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-container {
    max-width: 940px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-container {
    max-width: 68.81vw;
  }
}
[data-pages="detail"] .content-header {
  border-bottom-color: #601986;
  border-bottom-style: solid;
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header {
    padding-bottom: 25px;
    border-bottom-width: 4px;
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header {
    padding-bottom: 4.67vw;
    border-bottom-width: 0.8vw;
    margin-bottom: 7.33vw;
  }
}
[data-pages="detail"] .content-header h1 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
  line-height: 1.4;
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header h1 {
    font-size: 2.6rem;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header h1 {
    font-size: 5.6vw;
    margin-top: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header h1 {
    font-size: 1.9vw;
    margin-top: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header-inner.bottom {
    display: flex;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header-inner.bottom {
    margin-top: 4vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header-inner.bottom > p + p {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .content-header-inner.bottom > p + p {
    margin-top: 2.67vw !important;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header-inner.bottom > p + p {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header-inner.bottom {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .content-header-date {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header-date {
    font-size: 1.32vw;
  }
}
[data-pages="detail"] .content-header-place {
  margin-top: 0 !important;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .content-header {
    padding-bottom: 1.83vw;
    border-bottom-width: 0.29vw;
    margin-bottom: 3.29vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container {
    padding: 3.33vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm2 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm2 > div,
  [data-pages="detail"] .l-container.clm2 > p {
    width: calc(50% - 10px);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container.clm2 > div + div,
  [data-pages="detail"] .l-container.clm2 > p + div,
  [data-pages="detail"] .l-container.clm2 > div + p,
  [data-pages="detail"] .l-container.clm2 > p + p {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm2 > div:nth-child(n+3),
  [data-pages="detail"] .l-container.clm2 > p:nth-child(n+3) {
    margin-top: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm2 > div:nth-child(n+3),
  [data-pages="detail"] .l-container.clm2 > p:nth-child(n+3) {
    margin-top: 1.83vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm2 > div,
  [data-pages="detail"] .l-container.clm2 > p {
    width: calc(50% - 0.73vw);
  }
}
[data-pages="detail"] .l-container.clm2.-is-reverse div:first-child,
[data-pages="detail"] .l-container.clm2.-is-reverse p:first-child {
  order: 1;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm3 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm3 > div,
  [data-pages="detail"] .l-container.clm3 > p {
    width: calc(33.33% - 13px);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container.clm3 > div + div,
  [data-pages="detail"] .l-container.clm3 > p + div,
  [data-pages="detail"] .l-container.clm3 > div + p,
  [data-pages="detail"] .l-container.clm3 > p + p {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm3 > div,
  [data-pages="detail"] .l-container.clm3 > p {
    width: calc(33.33% - 0.98vw);
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm4 {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-container.clm4 > div,
  [data-pages="detail"] .l-container.clm4 > p {
    width: calc(25% - 11px);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-container.clm4 > div + div,
  [data-pages="detail"] .l-container.clm4 > p + div,
  [data-pages="detail"] .l-container.clm4 > div + p,
  [data-pages="detail"] .l-container.clm4 > p + p {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container.clm4 > div,
  [data-pages="detail"] .l-container.clm4 > p {
    width: calc(25% - 1.02vw);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-container {
    padding: 1.1vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-inner {
    padding: 15px 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-inner {
    padding: 1.1vw 0vw;
  }
}
[data-pages="detail"] .l-header {
  border-bottom-color: #ccc;
  border-bottom-style: solid;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-header {
    padding-bottom: 18px;
    border-bottom-width: 2px;
    margin: 65px 0 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-header {
    padding-bottom: 4vw;
    border-bottom-width: 0.53vw;
    margin: 10vw 0vw 2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-header {
    padding-bottom: 1.32vw;
    border-bottom-width: 0.15vw;
  }
}
[data-pages="detail"] .l-heading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-heading {
    font-size: 2.2rem;
    line-height: 1.32;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-heading {
    font-size: 5.07vw;
    line-height: 1.39;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-heading {
    font-size: 1.61vw;
  }
}
[data-pages="detail"] .l-subheading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 400;
  color: #333;
  letter-spacing: 0.05em;
  border-left-color: #601986;
  border-left-style: solid;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-subheading {
    font-size: 1.8rem;
    line-height: 18px;
    padding-bottom: 2px;
    padding-left: 10px;
    border-left-width: 4px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-subheading {
    font-size: 4.53vw;
    line-height: 4.8vw;
    padding-bottom: 0.53vw;
    padding-left: 2.67vw;
    border-left-width: 1.07vw;
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-subheading {
    font-size: 1.32vw;
    line-height: 1.32vw;
    padding-bottom: 0.15vw;
    padding-left: 0.73vw;
    border-left-width: 0.29vw;
    margin-bottom: 1.46vw;
  }
}
[data-pages="detail"] .l-image {
  width: 100%;
  text-align: center;
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-image img {
    width: 100%;
  }
}
[data-pages="detail"] .l-pragraph {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #333;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-pragraph {
    font-size: 1.5rem;
    line-height: 1.93;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-pragraph {
    font-size: 3.73vw;
    line-height: 1.86;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-pragraph {
    font-size: 1.1vw;
  }
}
[data-pages="detail"] .l-link {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.05em;
  background-repeat: no-repeat;
  background-image: url("../img/common/textlink_arrow.png");
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-link {
    font-size: 1.6rem;
    line-height: 1.81;
    background-size: 9px 15px;
    background-position: left 7px;
    padding-left: 15px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="detail"] .l-link:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-link {
    font-size: 3.73vw;
    line-height: 1.79;
    background-size: 2.13vw 3.33vw;
    background-position: left 1.33vw;
    padding-left: 3.33vw;
  }
}
[data-pages="detail"] .l-link[target=_blank]::after {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../img/common/icon_tab.svg");
}
@media (min-width: 768px) {
  [data-pages="detail"] .l-link[target=_blank]::after {
    width: 17px;
    height: 16px;
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .l-link[target=_blank]::after {
    width: 4vw;
    height: 3.87vw;
    margin-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-link[target=_blank]::after {
    width: 1.24vw;
    height: 1.17vw;
    margin-left: 0.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .l-link {
    font-size: 1.17vw;
    background-size: 0.66vw 1.1vw;
    background-position: left 0.51vw;
    padding-left: 1.1vw;
  }
}
[data-pages="detail"] .back-link-container {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-container {
    margin-top: 55px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-container {
    margin-top: 10vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-container {
    margin-top: 4.03vw;
  }
}
[data-pages="detail"] .back-link-text {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text {
    font-size: 1.3rem;
  }
  [data-pages="detail"] .back-link-text:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="detail"] .back-link-text::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="detail"] .back-link-text::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .back-link-text {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .back-link-text {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .back-link-text {
    font-size: 1.17vw;
  }
}
[data-pages="detail"] .images-withCaption {
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .images-withCaption {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption:not(._2col) .img {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption:not(._2col) .img {
    max-width: 43.92vw;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption._2col {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption._2col .img:nth-of-type(2) {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .images-withCaption._2col .img:nth-of-type(2) {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption._2col .img:nth-of-type(2) {
    margin-left: 1.46vw;
  }
}
[data-pages="detail"] .images-withCaption figcaption {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="detail"] .images-withCaption figcaption {
    font-size: 15px;
    line-height: 2;
  }
}
@media (max-width: 767px) {
  [data-pages="detail"] .images-withCaption figcaption {
    font-size: 3.73vw;
    line-height: 1.85;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption figcaption {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="detail"] .images-withCaption {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] .page-header {
    background-image: url("../img/english/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] .page-header {
    background-image: url("../img/english/header_bg-sp.jpg");
  }
}
[data-pages="english-lang"] .page-heading-en {
  display: none;
}
[data-pages="english-lang"] [data-english="index"].ui-template {
  padding-top: 0;
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"].ui-template {
    padding-left: 0;
    padding-right: 0;
  }
}
[data-pages="english-lang"] [data-english="index"].ui-template [class^="heading"].withBlock:before {
  height: 100%;
}
[data-pages="english-lang"] [data-english="index"] .paragraph {
  letter-spacing: normal;
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .container {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
  [data-pages="english-lang"] [data-english="index"] .container img {
    width: 100%;
    height: auto;
  }
}
[data-pages="english-lang"] [data-english="index"] .content-key_visual {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual .kv-img {
    margin-left: 23px;
    margin-bottom: 23px;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual .kv-img {
    margin-left: 6.4vw;
    margin-bottom: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="english-lang"] [data-english="index"] .content-key_visual .kv-img {
    margin-left: 1.68vw;
    margin-bottom: 1.68vw;
  }
}
[data-pages="english-lang"] [data-english="index"] .heading1 {
  color: #5f1985;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 {
    font-size: 35px;
    margin-top: -28px;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 {
    font-size: 6.93vw;
  }
}
[data-pages="english-lang"] [data-english="index"] .heading1 .text {
  display: inline-block;
  background-color: #fff;
  padding: 0 5px;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 .text {
    margin-bottom: 18px;
    letter-spacing: 0.05em;
  }
}
@media (max-width: 767px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 .text {
    margin-bottom: 1.6vw;
    letter-spacing: -0.06em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="english-lang"] [data-english="index"] .heading1 {
    font-size: 2.56vw;
    margin-top: -2.05vw;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .col_2 .text-box {
    width: 66%;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .col_2 .img-box {
    width: 31%;
  }
}
[data-pages="english-lang"] [data-english="index"] .flexPC:first-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .link-item .img {
    width: 450px;
    height: 300px;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .link-item .img img {
    transition: transform 0.3s;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="english-lang"] [data-english="index"] .link-item .img {
    width: 32.94vw;
    height: 21.96vw;
  }
}
@media (min-width: 768px) {
  [data-pages="english-lang"] [data-english="index"] .link-item:hover img {
    transform: scale(1.1);
  }
  [data-pages="english-lang"] [data-english="index"] .link-item:hover .link-box {
    border-bottom: 2px solid #5f1985;
    opacity: 0.7;
  }
}
[data-pages="english-lang"] [data-english="index"] .link-item .link-box {
  border-bottom: 2px solid #ccc;
}
[data-pages="english-lang"] [data-english="index"] .link-item .link-box .text {
  display: block;
  color: #333;
  background-image: url("../img/english/arrow.svg");
  background-position: right bottom 20px;
  background-repeat: no-repeat;
  background-size: 21px 4px;
}
@media (min-width: 768px) {
  [data-category="english-lang"] .page-header {
    background-image: url("../img/english/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="english-lang"] .page-header {
    background-image: url("../img/english/header_bg@2x.jpg");
  }
}
[data-category="english-lang"] .page-heading-en {
  display: none;
}
@media (min-width: 768px) {
  [data-category="english-lang"] [data-english="career-support-center"] .flexPC.-col2.-reverse {
    flex-direction: row-reverse;
  }
}
[data-category="english-lang"] [data-english="career-support-center"] .withBorder.margin-lv1 {
  margin-bottom: 20px;
}
@media (max-width: 1365px) {
  [data-category="english-lang"] [data-english="career-support-center"] .withBorder.margin-lv1 {
    margin-bottom: 1.46vw;
  }
}
@media (max-width: 767px) {
  [data-category="english-lang"] [data-english="career-support-center"] .withBorder.margin-lv1 {
    margin-bottom: 3.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .page-header {
    background-image: url("../img/event/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .page-header {
    background-image: url("../img/event/header_bg@2x.jpg");
  }
}
[data-pages="event"] .content {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="event"] .content {
    padding: 70px 0 165px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content {
    padding: 13.33vw 0vw 33.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-container {
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-container {
    max-width: 79.06vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-pages="event"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-list + li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-list + li {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-list + li {
    margin-top: 1.46vw;
  }
}
[data-pages="event"] .content-archive-list a {
  color: #333;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-list a {
    display: flex;
    padding: 30px 20px 30px 10px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="event"] .content-archive-list a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-list a {
    display: block;
    padding: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-list a {
    padding: 2.2vw 1.46vw 2.2vw 0.73vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-header {
    width: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-right: 1px solid #ccc;
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-header {
    padding-bottom: 4vw;
    border-bottom: 0.53vw solid #ccc;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-header-inner {
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-header {
    width: 13.54vw;
    margin-right: 2.93vw;
  }
}
[data-pages="event"] .content-archive-date + .content-archive-date::before {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date + .content-archive-date::before {
    content: '|';
    font-size: 1.8rem;
    text-align: center;
    display: block;
    margin: 0.5rem auto;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date + .content-archive-date::before {
    content: 'ー';
    font-size: 5.07vw;
    display: inline-block;
    margin: 0 1rem;
    top: -1rem;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date + .content-archive-date::before {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .month {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .month {
    font-size: 6.13vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .month::after {
    font-size: 1.76vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .month {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .day {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .day {
    font-size: 13.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .day {
    font-size: 2.93vw;
  }
}
[data-pages="event"] .content-archive-date .week {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .week {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .week {
    font-size: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .week {
    font-size: 1.32vw;
  }
}
[data-pages="event"] .content-archive-date .-is-comingsoon {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-date .-is-comingsoon {
    font-size: 1.8rem;
    line-height: 2.22;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-date .-is-comingsoon {
    font-size: 4.53vw;
    line-height: 3.06;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-date .-is-comingsoon {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-title {
    font-size: 1.6rem;
    line-height: 1.63;
    margin: 0 0 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-title {
    margin: 4vw 0 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-title {
    font-size: 1.17vw;
    margin: 0vw 0vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-data-inner {
    display: flex;
    align-items: center;
  }
}
[data-pages="event"] .content-archive-category {
  margin-top: 0 !important;
}
@media (min-width: 768px) {
  [data-pages="event"] .content-archive-place {
    margin-left: 30px;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="event"] .content-archive-place {
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content-archive-place {
    margin-left: 2.2vw;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="event"] .content {
    padding: 5.12vw 0vw 12.08vw;
  }
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .page-header {
    background-image: url("../img/for-examinees/examinees-index/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .page-header {
    background-image: url("../img/for-examinees/examinees-index/header_bg-sp.jpg");
  }
}
[data-pages="examinees-index"] .footer {
  border-top-width: 0;
}
[data-pages="examinees-index"] .content-for_menu-inner {
  padding: 80px 0 42px;
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .content-for_menu-inner {
    padding: 40px 0 100px;
  }
}
[data-pages="examinees-index"] .content-for_menu-inner .banner {
  display: block;
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .content-for_menu-inner .banner {
    margin-bottom: 40px;
    transition: opacity 0.2s ease-in-out;
  }
  [data-pages="examinees-index"] .content-for_menu-inner .banner:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .content-for_menu-inner .banner {
    margin-bottom: 18px;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-pen.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 105px;
    background-size: 78px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 73px;
    background-size: 54px auto;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-docx.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 115px;
    background-size: 58px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 76px;
    background-size: 47px auto;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-parents.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 104px;
    background-size: 76px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 76px;
    background-size: 57px auto;
  }
}
[data-pages="examinees-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
  background-image: url("../img/for-examinees/examinees-index/icon-pc_sp.svg");
}
@media (min-width: 768px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 104px;
    background-size: 117px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="examinees-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 67px;
    background-size: 80px auto;
  }
}
.theme_color-purple {
  color: #5f1985;
}
.theme_color-lightgreen {
  color: #85b401;
}
.theme_color-lightblue {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-pages="faculty-top"] .page-header {
    background-image: url("../img/faculty/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="faculty-top"] .page-header {
    background-image: url("../img/faculty/header_bg-sp.jpg");
  }
}
[data-pages="faculty-top"] .ui-template {
  padding-top: 0;
}
@media (max-width: 767px) {
  [data-pages="faculty-top"] .ui-template {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="english"] .page-header {
    background-image: url("../img/faculty/english/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="english"] .page-header {
    background-image: url("../img/faculty/english/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="english"] .c-department_top {
    background-image: url("../img/faculty/english/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="english"] .c-department_top {
    background-image: url("../img/faculty/english/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="english"] .career-graph .number-box {
    top: 29%;
    left: 40%;
  }
}
@media (max-width: 767px) {
  [data-pages="english"] .career-graph .number-box {
    top: 28%;
    left: 36%;
  }
}
@media (max-width: 1365px) {
  [data-pages="english"] .career-graph img {
    width: 39.02vw;
  }
}
@media (min-width: 768px) {
  [data-pages="japanese"] .page-header {
    background-image: url("../img/faculty/japanese/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="japanese"] .page-header {
    background-image: url("../img/faculty/japanese/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="japanese"] .c-department_top {
    background-image: url("../img/faculty/japanese/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="japanese"] .c-department_top {
    background-image: url("../img/faculty/japanese/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="japanese"] .career-graph .number-box {
    top: 29%;
    left: 40%;
  }
}
@media (max-width: 767px) {
  [data-pages="japanese"] .career-graph .number-box {
    top: 26%;
    left: 37%;
  }
}
@media (max-width: 1365px) {
  [data-pages="japanese"] .career-graph img {
    width: 38.14vw;
  }
}
@media (min-width: 768px) {
  [data-pages="society"] .page-header {
    background-image: url("../img/faculty/society/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="society"] .page-header {
    background-image: url("../img/faculty/society/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="society"] .c-department_top {
    background-image: url("../img/faculty/society/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="society"] .c-department_top {
    background-image: url("../img/faculty/society/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="society"] .career-graph .number-box {
    top: 28.5%;
    left: 38%;
  }
}
@media (max-width: 767px) {
  [data-pages="society"] .career-graph .number-box {
    top: 27%;
    left: 36%;
  }
}
@media (max-width: 1365px) {
  [data-pages="society"] .career-graph img {
    width: 36.16vw;
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .page-header {
    background-image: url("../img/faculty/childhood/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .page-header {
    background-image: url("../img/faculty/childhood/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .c-department_top {
    background-image: url("../img/faculty/childhood/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .c-department_top {
    background-image: url("../img/faculty/childhood/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .career-graph-1 .number-box {
    top: 29%;
    left: 41%;
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .career-graph-1 .number-box {
    top: 27%;
    left: 38%;
  }
}
@media (max-width: 1365px) {
  [data-pages="childhood"] .career-graph-1 img {
    width: 37.04vw;
  }
}
@media (min-width: 768px) {
  [data-pages="childhood"] .career-graph-2 .number-box {
    top: 29%;
    left: 39%;
  }
}
@media (max-width: 767px) {
  [data-pages="childhood"] .career-graph-2 .number-box {
    top: 27%;
    left: 34%;
  }
}
@media (max-width: 1365px) {
  [data-pages="childhood"] .career-graph-2 img {
    width: 36.24vw;
  }
}
@media (min-width: 768px) {
  [data-pages="nutrition"] .page-header {
    background-image: url("../img/faculty/nutrition/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="nutrition"] .page-header {
    background-image: url("../img/faculty/nutrition/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="nutrition"] .c-department_top {
    background-image: url("../img/faculty/nutrition/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="nutrition"] .c-department_top {
    background-image: url("../img/faculty/nutrition/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="nutrition"] .career-graph .number-box {
    top: 30%;
    left: 39%;
  }
}
@media (max-width: 767px) {
  [data-pages="nutrition"] .career-graph .number-box {
    top: 27%;
    left: 35%;
  }
}
@media (max-width: 1365px) {
  [data-pages="nutrition"] .career-graph img {
    width: 36.68vw;
  }
}
@media (min-width: 768px) {
  [data-pages="early-childhood"] .page-header {
    background-image: url("../img/faculty/early-childhood/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="early-childhood"] .page-header {
    background-image: url("../img/faculty/early-childhood/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="early-childhood"] .c-department_top {
    background-image: url("../img/faculty/early-childhood/top/main_image-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="early-childhood"] .c-department_top {
    background-image: url("../img/faculty/early-childhood/top/main_image-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="early-childhood"] .career-graph .number-box {
    top: 37%;
    left: 38%;
  }
}
@media (max-width: 767px) {
  [data-pages="early-childhood"] .career-graph .number-box {
    top: 35%;
    left: 33%;
  }
}
@media (max-width: 1365px) {
  [data-pages="early-childhood"] .career-graph img {
    width: 39.82vw;
  }
}
[data-faculty="bungakubu"] .ui-template .list-withDot li::before {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-faculty="bungakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-purple-pc.svg");
  }
}
@media (max-width: 767px) {
  [data-faculty="bungakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-purple-sp.svg");
  }
}
@media (min-width: 768px) {
  [data-faculty="bungakubu"] .c-department_top.return::before {
    background-image: url("../img/faculty/bg_color-purple-re-pc.svg");
  }
}
[data-faculty="eiyokagakubu"] .page-heading {
  color: #85b401;
}
[data-faculty="eiyokagakubu"] .page-heading-en {
  color: #85b401;
}
[data-faculty="eiyokagakubu"] .page-heading-faculty {
  color: #85b401;
}
[data-faculty="eiyokagakubu"] .ui-template .list-withDot li::before {
  background-color: #85b401;
}
[data-faculty="eiyokagakubu"] .ui-template .withBlock::before {
  background-color: #85b401;
}
[data-faculty="eiyokagakubu"] .ui-template .withBorder:before {
  background: #85b401;
}
@media (min-width: 768px) {
  [data-faculty="eiyokagakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightgreen-pc.svg");
  }
}
@media (max-width: 767px) {
  [data-faculty="eiyokagakubu"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightgreen-sp.svg");
  }
}
[data-faculty="jc"] .ui-template * {
  font-family: 'Kosugi Maru', sans-serif;
  font-weight: 400;
}
[data-faculty="jc"] .page-heading {
  font-family: 'Kosugi Maru', sans-serif;
  color: #1886ba;
}
[data-faculty="jc"] .page-heading-en {
  font-family: 'Kosugi Maru', sans-serif;
  color: #1886ba;
}
[data-faculty="jc"] .page-heading-faculty {
  font-family: 'Kosugi Maru', sans-serif;
  color: #1886ba;
}
[data-faculty="jc"] .ui-template .list-withDot li::before {
  background-color: #1886ba;
}
[data-faculty="jc"] .ui-template .withBlock::before {
  background-color: #1886ba;
}
[data-faculty="jc"] .ui-template .withBorder:before {
  background: #1886ba;
}
@media (min-width: 768px) {
  [data-faculty="jc"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightblue-pc.svg");
  }
}
@media (max-width: 767px) {
  [data-faculty="jc"] .c-department_top::before {
    background-image: url("../img/faculty/bg_color-lightblue-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #5f1985;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #5f1985;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .faculty_heading {
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .faculty_heading::after {
  background: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(1) {
    background-image: url("../img/faculty/faculty-top/img-english-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(1) {
    background-image: url("../img/faculty/faculty-top/img-english-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(2) {
    background-image: url("../img/faculty/faculty-top/img-japanese-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(2) {
    background-image: url("../img/faculty/faculty-top/img-japanese-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(3) {
    background-image: url("../img/faculty/faculty-top/img-society-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(3) {
    background-image: url("../img/faculty/faculty-top/img-society-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(4) {
    background-image: url("../img/faculty/faculty-top/img-childhood-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block:nth-of-type(4) {
    background-image: url("../img/faculty/faculty-top/img-childhood-sp.jpg");
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .department-heading-en {
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-other_link .list-policy li {
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-other_link .list-policy li::before {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-left::before {
    background-image: url("../img/faculty/faculty-top/color-purple.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-left::before {
    background-image: url("../img/faculty/faculty-top/color-purple-sp.svg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-right::before {
    background-image: url("../img/faculty/faculty-top/color-purple-re.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .department-block.position-right::before {
    background-image: url("../img/faculty/faculty-top/color-purple-re-sp.svg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point {
    display: flex;
    justify-content: space-between;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item {
    width: 260px;
  }
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item.col4 {
    width: 210px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item :not(:first-of-type) {
    margin-top: 13.33vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box {
  position: relative;
  margin: auto;
  z-index: 1;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box {
    width: 220px;
    height: 220px;
    margin-bottom: 30px;
  }
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box.col4 {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box {
    width: 60vw;
    margin-bottom: 8vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box::before {
  position: absolute;
  top: 6%;
  left: 2%;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #5f1985;
  opacity: 0.5;
  z-index: -1;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box {
    width: 16.11vw;
    height: 16.11vw;
    margin-bottom: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .img_box.col4 {
    width: 14.64vw;
    height: 14.64vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .number {
  position: absolute;
  left: 0;
  color: #5f1985;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  z-index: 100;
  opacity: 0.8;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .number {
    top: 5%;
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .number {
    font-size: 18.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item .number {
    font-size: 3.66vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item h3 {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 8px;
    min-height: 80px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 0.59vw;
    min-height: 5.86vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item {
    width: 19.03vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item.col4 {
    width: 15.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-learning_point .list-learning_point--item {
    width: 19.03vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-page_index {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index {
    padding: 54px 70px 57px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index {
    padding: 9.6vw 5.33vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .heading4 {
  color: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .heading4 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .heading4 {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .heading4 {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index li a {
  font-weight: normal;
  color: #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index li a.current {
  border-bottom: 1px solid #5f1985;
}
[data-category="faculty"] [data-faculty="bungakubu"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .block-page_index {
    padding: 3.95vw 5.12vw 4.17vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button {
  position: relative;
  background-color: #f5f6f8;
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button {
    padding: 16px 20px;
    width: 400px;
  }
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button:hover {
    transition: 0.2s;
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button {
    padding: 4.27vw 5.33vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::before,
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::after {
  content: "";
  background: #5f1985;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::after {
    right: 20px;
    width: 20px;
    height: 3px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::after {
    right: 2.67vw;
    width: 2.67vw;
    height: 0.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::after {
    right: 1.46vw;
    width: 1.46vw;
    height: 0.22vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button::after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button.opend:after {
  transform: translateY(-50%) rotate(0);
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-button {
    padding: 1.17vw 1.46vw;
    width: 29.28vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner {
  display: none;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner li {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner li {
    margin-bottom: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner li {
    margin-bottom: 2.34vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner .course_title {
  color: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 0.59vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .list-voice .text-heading {
  color: #5f1985;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-voice li:nth-of-type(odd) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-purple.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-voice li:nth-of-type(even) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-purple-re.svg") no-repeat center/contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-purple-under.svg") no-repeat center/contain;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .withBorder::before {
  background: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .faculty_heading {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .faculty_heading::after {
  background: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-nutrition-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-nutrition-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-lightgreen.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-lightgreen-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .department-block .department-heading-en {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #85b401;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #85b401;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-other_link .list-policy li {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-other_link .list-policy li::before {
  background-color: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point {
    display: flex;
    justify-content: space-between;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item {
    width: 260px;
  }
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item.col4 {
    width: 210px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item :not(:first-of-type) {
    margin-top: 13.33vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box {
  position: relative;
  margin: auto;
  z-index: 1;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box {
    width: 220px;
    height: 220px;
    margin-bottom: 30px;
  }
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box.col4 {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box {
    width: 60vw;
    margin-bottom: 8vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box::before {
  position: absolute;
  top: 6%;
  left: 2%;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #85b401;
  opacity: 0.5;
  z-index: -1;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box {
    width: 16.11vw;
    height: 16.11vw;
    margin-bottom: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .img_box.col4 {
    width: 14.64vw;
    height: 14.64vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .number {
  position: absolute;
  left: 0;
  color: #85b401;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  z-index: 100;
  opacity: 0.8;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .number {
    top: 5%;
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .number {
    font-size: 18.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item .number {
    font-size: 3.66vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item h3 {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 8px;
    min-height: 80px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 0.59vw;
    min-height: 5.86vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item {
    width: 19.03vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item.col4 {
    width: 15.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-learning_point .list-learning_point--item {
    width: 19.03vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index {
    padding: 54px 70px 57px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index {
    padding: 9.6vw 5.33vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .heading4 {
  color: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .heading4 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .heading4 {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .heading4 {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index li a {
  font-weight: normal;
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index li a.current {
  border-bottom: 1px solid #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-lightgreen.svg") no-repeat;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .block-page_index {
    padding: 3.95vw 5.12vw 4.17vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button {
  position: relative;
  background-color: #f5f6f8;
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button {
    padding: 16px 20px;
    width: 400px;
  }
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button:hover {
    transition: 0.2s;
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button {
    padding: 4.27vw 5.33vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::before,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::after {
  content: "";
  background: #85b401;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::after {
    right: 20px;
    width: 20px;
    height: 3px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::after {
    right: 2.67vw;
    width: 2.67vw;
    height: 0.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::after {
    right: 1.46vw;
    width: 1.46vw;
    height: 0.22vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button::after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button.opend:after {
  transform: translateY(-50%) rotate(0);
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-button {
    padding: 1.17vw 1.46vw;
    width: 29.28vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner {
  display: none;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner li {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner li {
    margin-bottom: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner li {
    margin-bottom: 2.34vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner .course_title {
  color: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 0.59vw;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice .text-heading {
  color: #85b401;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice li:nth-of-type(odd) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightgreen.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice li:nth-of-type(even) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightgreen-re.svg") no-repeat center/contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightgreen-under.svg") no-repeat center/contain;
  }
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .c-info_item .info_item--category span {
  color: #739c00;
  border: 1px solid #739c00;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question p {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question p:before {
  color: #85b401;
}
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question:before,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .accordion-container .accordion .question:after {
  background: #85b401;
}
[data-category="faculty"] [data-faculty="jc"] .withBorder::before {
  background: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .container-inner {
  background-color: #a3cfe3;
}
[data-category="faculty"] [data-faculty="jc"] .faculty_heading {
  color: #fff;
}
[data-category="faculty"] [data-faculty="jc"] .faculty_heading::after {
  background: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-jc-1-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block {
    background-image: url("../img/faculty/faculty-top/img-jc-1-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-blue.svg");
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .department-block::before {
    background-image: url("../img/faculty/faculty-top/color-blue-sp.svg");
  }
}
[data-category="faculty"] [data-faculty="jc"] .department-block .department-heading-en {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #1886ba;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link {
    font-size: 1.3rem;
  }
  [data-category="faculty"] [data-faculty="jc"] .text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1886ba;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] [data-faculty="jc"] .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .text-link {
    font-size: 0.95vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .block-other_link .list-policy li {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .block-other_link .list-policy li::before {
  background-color: #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point {
    display: flex;
    justify-content: space-between;
  }
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item {
    width: 260px;
  }
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item.col4 {
    width: 210px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item :not(:first-of-type) {
    margin-top: 13.33vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box {
  position: relative;
  margin: auto;
  z-index: 1;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box {
    width: 220px;
    height: 220px;
    margin-bottom: 30px;
  }
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box.col4 {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box {
    width: 60vw;
    margin-bottom: 8vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box::before {
  position: absolute;
  top: 6%;
  left: 2%;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1886ba;
  opacity: 0.5;
  z-index: -1;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box {
    width: 16.11vw;
    height: 16.11vw;
    margin-bottom: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .img_box.col4 {
    width: 14.64vw;
    height: 14.64vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .number {
  position: absolute;
  left: 0;
  color: #1886ba;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  z-index: 100;
  opacity: 0.8;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .number {
    top: 5%;
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .number {
    font-size: 18.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item .number {
    font-size: 3.66vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item h3 {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 8px;
    min-height: 80px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item h3 {
    margin-bottom: 0.59vw;
    min-height: 5.86vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item {
    width: 19.03vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item.col4 {
    width: 15.37vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .list-learning_point .list-learning_point--item {
    width: 19.03vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .block-page_index {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index {
    padding: 54px 70px 57px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index {
    padding: 9.6vw 5.33vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .block-page_index .heading4 {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .heading4 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .heading4 {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .heading4 {
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index li:not(:last-of-type) {
    margin-right: 2.93vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index li a {
  font-weight: normal;
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index li a.current {
  border-bottom: 1px solid #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .block-page_index .list-page_index a::before {
  background: url("../img/common/textlink_arrow-lightblue.svg") no-repeat;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .block-page_index {
    padding: 3.95vw 5.12vw 4.17vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button {
  position: relative;
  background-color: #f5f6f8;
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button {
    padding: 16px 20px;
    width: 400px;
  }
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button:hover {
    transition: 0.2s;
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button {
    padding: 4.27vw 5.33vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::before,
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::after {
  content: "";
  background: #1886ba;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::after {
    right: 20px;
    width: 20px;
    height: 3px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::after {
    right: 2.67vw;
    width: 2.67vw;
    height: 0.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::before,
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::after {
    right: 1.46vw;
    width: 1.46vw;
    height: 0.22vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button::after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button.opend:after {
  transform: translateY(-50%) rotate(0);
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-button {
    padding: 1.17vw 1.46vw;
    width: 29.28vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner {
  display: none;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner li {
    margin-bottom: 32px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner li {
    margin-bottom: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner li {
    margin-bottom: 2.34vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner .course_title {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="jc"] .container-accordion--course .accordion-inner .course_title {
    margin-bottom: 0.59vw;
  }
}
[data-category="faculty"] [data-faculty="jc"] .list-voice .text-heading {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-voice li:nth-of-type(odd) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightblue.svg") no-repeat center/contain;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="jc"] .list-voice li:nth-of-type(even) .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightblue-re.svg") no-repeat center/contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="jc"] .list-voice li .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number-lightblue-under.svg") no-repeat center/contain;
  }
}
[data-category="faculty"] [data-faculty="jc"] .c-info_item .info_item--category span {
  border: 1px solid #1886ba;
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question p {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question p:before {
  color: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question:before,
[data-category="faculty"] [data-faculty="jc"] .accordion-container .accordion .question:after {
  background: #1886ba;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power li {
  background-color: #1886ba;
  border-radius: 24px;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power .heading {
  color: #fff;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power .paragraph {
  color: #fff;
}
[data-category="faculty"] [data-faculty="jc"] .list-learning_power .border::before {
  background: #fff;
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link span,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span,
[data-category="faculty"] [data-faculty="jc"] .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link span,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link span,
  [data-category="faculty"] [data-faculty="jc"] .text-link span {
    padding-right: 2.67vw;
    letter-spacing: -0.01em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link::after,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link::after,
  [data-category="faculty"] [data-faculty="jc"] .text-link::after {
    border-width: 2.13vw 0vw 0vw 2.13vw;
  }
}
[data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
[data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
[data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
  margin-left: 0.3em;
}
@media (min-width: 768px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
    width: 18px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
    width: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] [data-faculty="bungakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="eiyokagakubu"] .text-link .icon-pdf,
  [data-category="faculty"] [data-faculty="jc"] .text-link .icon-pdf {
    width: 1.32vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .table-container {
    overflow-x: auto;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion {
  background: #f5f6f8;
  padding: 0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion:not(:last-of-type) {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding: 5.33vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question p,
[data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 40px;
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-left: 2.93vw;
    padding-right: 4.39vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question,
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding: 2.93vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question {
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question:hover {
  opacity: 0.6;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
[data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
  margin-top: 0;
  content: "";
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
    right: 40px;
    width: 20px;
    height: 3px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:before,
  [data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
    right: 5.33vw;
    width: 2.67vw;
    height: 0.53vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question:after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question.opend:after {
  transform: translateY(-50%) rotate(0);
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question p {
  line-height: 1.6;
}
[data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
  content: "Q";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .question p:before {
    font-size: 2.93vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .answer {
  display: none;
  border-top: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 5.33vw;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
  margin-top: 0;
  line-height: 1.9;
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p {
    padding-right: 0;
  }
}
[data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
  content: "A";
  display: inline-block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  left: 0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
    top: -0.15em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
    font-size: 8.53vw;
    top: -0.05em;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer p:before {
    font-size: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .ui-template .accordion-container .accordion .answer {
    padding-bottom: 2.93vw;
  }
}
[data-category="faculty"] a.block-other_link:hover {
  opacity: 0.6;
}
[data-category="faculty"] a.block-other_link:hover .link-container .text-link::after {
  transform: translate3d(10px, 0, 0);
}
@media (max-width: 767px) {
  [data-category="faculty"] a.block-other_link {
    margin: 0 2.67vw;
  }
}
[data-category="faculty"] .only-link .text-link:hover {
  opacity: 0.6;
}
[data-category="faculty"] .scroll_anime {
  transition: all 1s ease 0.2s;
}
[data-category="faculty"] .scroll_anime.fuwa-up {
  transform: translateY(15px);
  opacity: 0;
}
[data-category="faculty"] .scroll_anime.fuwa-up.scroll__start {
  transform: translateY(0px);
  opacity: 1;
}
@media (min-width: 768px) {
  [data-category="faculty"] .float-right {
    float: right;
    margin-left: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .float-right {
    margin-left: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .float-left {
    float: left;
    margin-right: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .float-left {
    margin-right: 1.17vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .margin-bottom {
    margin-bottom: 52px;
  }
}
[data-category="faculty"] .margin-bottom.-center {
  text-align: center;
}
@media (max-width: 767px) {
  [data-category="faculty"] .margin-bottom {
    margin-bottom: 6.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .margin-bottom {
    margin-bottom: 3.81vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .col2-pc_only {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .col2-pc_only.return {
    flex-direction: row-reverse;
  }
  [data-category="faculty"] .col2-pc_only .box {
    width: 450px;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .col2-pc_only img {
    width: 32.94vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .col2-pc_only img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .col2-pc_only .box {
    width: 32.94vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .col3-pc_only {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .col3-pc_only .box {
    width: 340px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .col3-pc_only .box {
    width: 24.89vw;
  }
}
[data-category="faculty"] .text-indent {
  text-indent: 1em;
}
[data-category="faculty"] .highlight {
  font-weight: bold;
  border-bottom: 2px dotted #601986;
}
[data-category="faculty"] .bg-table-early-childhood {
  background-color: #bee1f0;
}
@media (min-width: 768px) {
  [data-category="faculty"] .line {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .line-2 {
    height: 80px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .line-2 {
    height: 5.86vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .line-3 {
    height: 100px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .line-3 {
    height: 7.32vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .line-4 {
    height: 120px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .line-4 {
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .content-faculty_top .container-inner {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .content-faculty_top .container-inner {
    padding: 13.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .content-faculty_top .container-inner {
    padding: 4.39vw 0vw;
  }
}
[data-category="faculty"] .faculty_heading {
  position: relative;
  text-align: center;
  padding-bottom: 25px;
}
[data-category="faculty"] .faculty_heading::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  content: "";
  width: 50px;
  height: 1px;
}
[data-category="faculty"] .department-block {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block {
    height: 460px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block {
    height: 106.667vw;
  }
}
[data-category="faculty"] .department-block .text {
  color: #333;
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block .text {
    font-size: 3.2vw;
  }
}
[data-category="faculty"] .department-block .department-block-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block .department-block-inner {
    width: 460px;
    height: 340px;
  }
  [data-category="faculty"] .department-block .department-block-inner:hover {
    background-color: rgba(255,255,255,0.85);
  }
  [data-category="faculty"] .department-block .department-block-inner:hover .text-link::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block .department-block-inner {
    height: 64vw;
    padding: 5.33vw;
    transform: translateX(-50%);
    left: 50%;
    width: 77.33vw;
    bottom: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block .department-block-inner {
    width: 33.67vw;
    height: 24.89vw;
  }
}
[data-category="faculty"] .department-block::before {
  display: block;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block {
    height: 33.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block.position-left .department-block-inner {
    top: 50%;
    transform: translateY(-50%);
    left: 80px;
    padding: 54px 50px 60px 58px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block.position-left .department-block-inner {
    left: 5.86vw;
    padding: 2.93vw 3.51vw;
  }
}
[data-category="faculty"] .department-block.position-left::before {
  background-position: center right;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block.position-right .department-block-inner {
    top: 50%;
    transform: translateY(-50%);
    right: 80px;
    padding: 54px 50px 40px 48px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block.position-right .department-block-inner {
    right: 5.86vw;
    padding: 3.95vw 3.66vw 2.93vw 3.51vw;
  }
}
[data-category="faculty"] .department-block.position-right::before {
  margin: 0 0 0 auto;
  background-position: center left;
}
[data-category="faculty"] .department-block .department-heading-ja {
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .department-block .department-heading-ja {
    margin-bottom: 16px;
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .department-block .department-heading-ja {
    margin-bottom: 2.93vw;
    font-size: 6.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .department-block .department-heading-ja {
    margin-bottom: 1.17vw;
    font-size: 2.64vw;
  }
}
[data-category="faculty"] .department-block .department-heading-en {
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1.6;
}
@media (min-width: 768px) {
  [data-category="faculty"] .message-container .withBorder {
    margin-bottom: 54px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .message-container .withBorder {
    margin-bottom: 3.95vw;
  }
}
[data-category="faculty"] .link-container--back {
  text-align: center;
}
[data-category="faculty"] .link-container--back .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #601986 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #5f1985;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link.-bungakubu:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #5f1985;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #5f1985 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link.-bungakubu span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-bungakubu {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #85b401;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #85b401;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #85b401 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-eiyokagakubu {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-jc {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #1886ba;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-jc {
    font-size: 1.6rem;
  }
  [data-category="faculty"] .link-container--back .text-link.-jc:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-jc {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="faculty"] .link-container--back .text-link.-jc::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1886ba;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="faculty"] .link-container--back .text-link.-jc::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #1886ba transparent transparent;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-jc::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-jc::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-jc::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="faculty"] .link-container--back .text-link.-jc span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .link-container--back .text-link.-jc span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .link-container--back .text-link.-jc span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-jc span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link.-jc {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .link-container--back .text-link {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .link-container {
  text-align: right;
}
[data-category="faculty"] .c-dean_nameplate {
  position: absolute;
  right: -1px;
  background-color: #fff;
}
[data-category="faculty"] .c-dean_nameplate span {
  display: block;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-dean_nameplate {
    bottom: 20px;
    padding: 12px 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-dean_nameplate {
    bottom: 5.33vw;
    padding: 2.67vw 4.27vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-dean_nameplate .job {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-dean_nameplate .job {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-dean_nameplate .job {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-dean_nameplate .name {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-dean_nameplate .name {
    font-size: 4.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-dean_nameplate .name {
    font-size: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-dean_nameplate {
    bottom: 1.46vw;
    padding: 0.88vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message .box-image {
    position: relative;
    width: 300px;
    height: 400px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-image {
    position: relative;
    margin-bottom: 5.33vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-image img {
    width: 21.96vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-image img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-image {
    width: 21.96vw;
    height: 29.28vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message .box-text {
    width: 600px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-text .heading {
    text-align: center;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-text .paragraph:not(:first-of-type) {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-faculty_message .box-text {
    width: 43.92vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-list-class_introduction .heading {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-list-class_introduction .heading {
    margin-bottom: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-list-class_introduction .heading {
    margin-bottom: 0.73vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-list-class_introduction .box-img {
    margin-bottom: 5.33vw;
  }
}
[data-category="faculty"] .c-department_top {
  position: relative;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top {
    height: 300px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top {
    height: 114.93vw;
  }
}
[data-category="faculty"] .c-department_top .c-department_top-inner {
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top .c-department_top-inner {
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top .c-department_top-inner {
    padding: 0vw 3.47vw;
    bottom: 12vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-category="faculty"] .c-department_top .c-department_top-text {
  color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top .c-department_top-text {
    width: 50%;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top .c-department_top-text {
    font-size: 4vw;
    letter-spacing: normal;
  }
}
[data-category="faculty"] .c-department_top::before {
  display: block;
  content: "";
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top::before {
    background-position: center left;
    background-size: contain;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-department_top::before {
    background-position: bottom center;
    background-size: 110% 51%;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top.return::before {
    background-position: center right;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-department_top.return .c-department_top-text {
    width: 42%;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-department_top {
    height: 21.96vw;
  }
}
[data-category="faculty"] .block-department {
  padding-top: 60px;
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-department {
    padding-right: 5.33vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .withBlock_list {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .withBlock_list {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .withBlock_list {
    margin-left: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-list {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .wrap-list .wrap-list-inner {
    width: 46%;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  [data-category="faculty"] .list-info::after {
    content: "";
    display: block;
    width: 340px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-info .information-contnt-block {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-info .information-contnt-block {
    margin-bottom: 11.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-info .information-contnt-block {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
[data-category="faculty"] .list-info .information-contnt-block-date {
  color: #333;
}
[data-category="faculty"] .list-info .information-contnt-block-title {
  color: #333;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-info::after {
    width: 24.89vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power {
    display: flex;
    justify-content: space-between;
  }
}
[data-category="faculty"] .list-learning_power li {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power li {
    padding: 30px 20px 26px;
    width: 300px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-learning_power li {
    padding: 9.07vw 10vw 10.4vw;
  }
  [data-category="faculty"] .list-learning_power li:not(:first-of-type) {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-learning_power li {
    padding: 2.2vw 1.46vw 1.9vw;
    width: 21.96vw;
  }
}
[data-category="faculty"] .list-learning_power .heading {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power .heading {
    min-height: 70px;
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-learning_power .heading {
    margin-bottom: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-learning_power .heading {
    min-height: 5.12vw;
    margin-bottom: 0.59vw;
  }
}
[data-category="faculty"] .list-learning_power .border::before {
  content: "";
  display: block;
  height: 1px;
  background: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-learning_power .border::before {
    width: 20px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-learning_power .border::before {
    width: 5.33vw;
    margin: 0vw auto 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-learning_power .border::before {
    width: 1.46vw;
    margin: 0vw auto 1.46vw;
  }
}
[data-category="faculty"] .list-features {
  background-color: #f5f6f8;
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features {
    padding-bottom: 5.33vw;
    width: 100vw;
    margin-left: -5.333vw;
  }
}
[data-category="faculty"] .list-features li {
  position: relative;
}
[data-category="faculty"] .list-features .area-vertical .text-Q {
  display: block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .area-vertical .text-Q {
    margin-left: 0.5em;
    text-align: center;
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .area-vertical .text-Q {
    font-size: 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .area-vertical .text-Q {
    font-size: 3.66vw;
  }
}
[data-category="faculty"] .list-features .area-vertical .text {
  margin: auto;
  display: block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .area-vertical .text {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}
[data-category="faculty"] .list-features .box-text {
  position: relative;
  background-color: #fff;
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .box-text {
    width: 94.667%;
    padding: 5.33vw;
  }
  [data-category="faculty"] .list-features .box-text .headeing3 {
    margin-bottom: 8.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .box-text .area-answer {
    width: 666px;
  }
}
[data-category="faculty"] .list-features .box-text .area-answer .answer-main {
  display: table;
  margin-bottom: 30px;
}
[data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
  display: table-cell;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
    display: block;
    font-size: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .box-text .area-answer .answer-main .text-A {
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .box-text .area-answer {
    width: 48.76vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .box-text .text {
    margin-bottom: 26px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .box-text .text {
    margin-bottom: 5.33vw;
  }
}
[data-category="faculty"] .list-features .box-text .text:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .box-text .text {
    margin-bottom: 1.9vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-1 .box-img img {
    width: 68.81vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-1 .box-img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .item-1 .box-text {
    display: flex;
    justify-content: space-between;
    width: 860px;
    margin: -60px 0 auto auto;
    padding: 40px 0 14px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-1 .box-text {
    margin: -38.67vw 0vw 0vw auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-1 .box-text {
    width: 62.96vw;
    margin: -4.39vw 0vw auto auto;
    padding: 2.93vw 0vw 1.02vw 2.93vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-1 .area-vertical {
    margin-bottom: 4.8vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-2 .box-img img {
    width: 57.1vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-2 .box-img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .item-2 .area-vertical {
    position: absolute;
    top: 0;
    right: 0;
    width: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-features .item-2 .area-vertical {
    position: relative;
    margin: -38.67vw 0vw 0vw;
    width: 94.667%;
    padding: 5.33vw 5.33vw 0vw 5.33vw;
    background-color: #fff;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-2 .area-vertical {
    width: 11.71vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-features .item-2 .box-text {
    margin-top: -60px;
    padding: 35px 60px 38px 40px;
    width: 700px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-features .item-2 .box-text {
    margin-top: -4.39vw;
    padding: 2.56vw 4.39vw 2.78vw 2.93vw;
    width: 51.24vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .content-career .section-point .withBorder {
    margin-bottom: 56px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .content-career .section-point .withBorder {
    margin-bottom: 4.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .content-career .section-voice .withBorder {
    margin-bottom: 90px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .content-career .section-voice .withBorder {
    margin-bottom: 6.59vw;
  }
}
[data-category="faculty"] .wrap-other_link {
  margin: 0 auto;
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-other_link {
    width: 932px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .wrap-other_link {
    width: 68.23vw;
  }
}
[data-category="faculty"] .block-other_link {
  background-color: #f5f6f8;
  position: relative;
  display: block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-other_link {
    padding: 20px;
    width: 450px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link {
    padding: 3.2vw 3.2vw 3.2vw 4.8vw;
  }
  [data-category="faculty"] .block-other_link:nth-of-type(2) {
    margin-top: 5.33vw;
  }
}
[data-category="faculty"] .block-other_link.dean_message {
  display: flex;
}
[data-category="faculty"] .block-other_link.dean_message .heading {
  color: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-other_link.dean_message .heading {
    margin-left: 25px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link.dean_message .heading {
    margin-left: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-other_link.dean_message .heading {
    margin-left: 1.83vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .block-other_link.dean_message img {
    width: 7.32vw;
    height: 7.32vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link.dean_message img {
    width: 18.93vw;
    height: 18.93vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link .list-policy li {
    font-size: 3.2vw;
  }
}
[data-category="faculty"] .block-other_link .link-container {
  position: absolute;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-other_link .link-container {
    bottom: 20px;
    right: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-other_link .link-container {
    bottom: 2.67vw;
    right: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-other_link .link-container {
    bottom: 1.46vw;
    right: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-other_link {
    width: 32.94vw;
    padding: 1.46vw;
  }
}
[data-category="faculty"] .list-voice li {
  position: relative;
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li {
    padding: 36px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice li {
    padding: 17.6vw 2.67vw 2.67vw;
    margin-top: 13.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li {
    padding: 2.64vw 2.93vw;
  }
}
[data-category="faculty"] .list-voice .text-heading {
  text-align: center;
  color: #601986;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .text-heading {
    margin-left: 50px;
    width: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .text-heading {
    width: 36.6vw;
    margin-left: 3.66vw;
  }
}
[data-category="faculty"] .list-voice .wrap-number {
  position: absolute;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .wrap-number {
    top: -30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .wrap-number {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
    background: url("../img/faculty/voice-number_bg-under.svg") no-repeat center/contain;
    width: 21.33vw;
    height: 24.27vw;
  }
}
[data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
    font-size: 40px;
    bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
    font-size: 8vw;
    bottom: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner .number {
    font-size: 2.93vw;
    bottom: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .wrap-number .wrap-number-inner {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .wrap-number {
    top: -2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .box-img {
    position: absolute;
    top: -30px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .box-img {
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .box-img {
    top: -2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .box-img img {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .box-img img {
    width: 100%;
    height: auto;
    margin-bottom: 3.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .box-img img {
    margin-bottom: 1.17vw;
    width: 19.03vw;
    height: auto;
  }
}
[data-category="faculty"] .list-voice .text-voice {
  border-radius: 20px;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .text-voice {
    padding: 34px 40px;
    width: 580px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .text-voice {
    padding: 2.67vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .text-voice {
    padding: 2.49vw 2.93vw;
    width: 42.46vw;
  }
}
[data-category="faculty"] .list-voice .youtube {
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube {
    padding: 0 40px 0 160px;
    margin-top: -40px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .youtube {
    padding: 17.6vw 0vw 2.67vw;
  }
}
[data-category="faculty"] .list-voice .youtube .text-heading {
  text-align: left;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube .text-heading {
    margin-left: 0;
    width: 100%;
  }
}
[data-category="faculty"] .list-voice .youtube .box-youtube {
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube .box-youtube {
    padding-top: 48.25%;
    width: 540px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .list-voice .youtube .box-youtube {
    width: 100%;
    padding-top: 56.25%;
  }
}
[data-category="faculty"] .list-voice .youtube .box-youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .youtube .box-youtube {
    width: 39.53vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice .youtube .wrap-number {
    top: 0;
    right: auto;
    left: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .youtube .wrap-number {
    left: -1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice .youtube {
    padding: 0vw 2.93vw 0vw 11.71vw;
    margin-top: -2.93vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .box-img {
    right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .box-img {
    right: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .wrap-number {
    left: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(odd) .wrap-number {
    left: -1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .box-img {
    left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .box-img {
    left: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .text-heading {
    margin-right: 50px;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .text-heading {
    margin-right: 3.66vw;
  }
}
[data-category="faculty"] .list-voice li:nth-of-type(even) .text-voice {
  margin-left: auto;
}
@media (min-width: 768px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .wrap-number {
    right: -20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .list-voice li:nth-of-type(even) .wrap-number {
    right: -1.46vw;
  }
}
[data-category="faculty"] .block-learning_support {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="faculty"] .block-learning_support {
    padding: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .block-learning_support {
    padding: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .block-learning_support {
    padding: 16px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item {
    margin-bottom: 50px;
    width: 340px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item {
    margin-bottom: 11.2vw;
  }
}
[data-category="faculty"] .c-info_item a {
  display: block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item a:hover .info_item--image img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
[data-category="faculty"] .c-info_item .info_item--image {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--image {
    height: 220px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--image img {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--image {
    height: 16.11vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--text {
    padding: 15px 10px 0;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--text {
    padding: 5.33vw 2.67vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--text {
    padding: 1.1vw 0.73vw 0vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--date {
  color: #333;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--date {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--date {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--date {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--category {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--category {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--category {
    margin-left: 2.67vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--category span {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
  color: #601986;
  border: 1px solid #601986;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--category span {
    font-size: 1.2rem;
    padding: 3px 10px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--category span {
    font-size: 3.2vw;
    padding: 1.33vw;
    min-width: 24.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--category span {
    font-size: 0.88vw;
    padding: 0.22vw 0.73vw;
    min-width: 8.78vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--category {
    margin-left: 1.46vw;
  }
}
[data-category="faculty"] .c-info_item .info_item--title {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="faculty"] .c-info_item .info_item--title {
    font-size: 1.4rem;
    line-height: 1.86;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .c-info_item .info_item--title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item .info_item--title {
    font-size: 1.02vw;
    margin-top: 0.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .c-info_item {
    margin-bottom: 3.66vw;
    width: 24.89vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .contents-field {
    width: 640px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .contents-field {
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .contents-field {
    width: 46.85vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-career-graph {
    display: flex;
    align-self: center;
  }
}
[data-category="faculty"] .wrap-career-graph .percentage {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 300;
}
[data-category="faculty"] .career-graph {
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph {
    width: 600px;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph .big {
    font-size: 85px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph .big {
    font-size: 17.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph .big {
    font-size: 6.22vw;
  }
}
[data-category="faculty"] .career-graph .sml {
  letter-spacing: -0.04em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph .sml {
    font-size: 55px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph .sml {
    font-size: 10.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph .sml {
    font-size: 4.03vw;
  }
}
[data-category="faculty"] .career-graph .number-box {
  position: absolute;
  text-align: center;
}
[data-category="faculty"] .career-graph .number-box .number-box--inner {
  margin: 0 auto;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-graph .number-box .number-box--inner {
    width: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph .number-box .number-box--inner {
    width: 29.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph .number-box .number-box--inner {
    width: 11.71vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .career-graph img {
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-graph img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-graph {
    width: 43.92vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .wrap-career-detail {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail {
    display: flex;
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .big {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .big {
    font-size: 12vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .big {
    font-size: 4.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .sml {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .sml {
    font-size: 7.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .sml {
    font-size: 2.64vw;
  }
}
[data-category="faculty"] .career-detail .brackets {
  white-space: nowrap;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item:not(:first-of-type) {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item:not(:first-of-type) {
    margin-top: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item .year {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item .year {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item .year {
    margin-bottom: 0.59vw;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .career-year .career-year--item {
    text-align: center;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-detail .career-year {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 10.67vw;
  }
}
[data-category="faculty"] .career-area {
  border: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-area {
    max-width: 186px;
    padding: 24px 30px;
    margin-left: 37px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area {
    width: 100%;
    padding: 6.4vw 8vw;
    margin-top: 5.87vw;
  }
}
[data-category="faculty"] .career-area .lead {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-area .lead {
    margin-bottom: 0.5em;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area .lead {
    text-align: center;
    margin-bottom: 5.33vw;
  }
}
[data-category="faculty"] .career-area .career-area--item .area,
[data-category="faculty"] .career-area .career-area--item .percentage {
  display: inline-block;
  vertical-align: baseline;
  line-height: 2;
  font-weight: 400;
}
@media (min-width: 768px) {
  [data-category="faculty"] .career-area .career-area--item .area,
  [data-category="faculty"] .career-area .career-area--item .percentage {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area .career-area--item .area,
  [data-category="faculty"] .career-area .career-area--item .percentage {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-area .career-area--item .area,
  [data-category="faculty"] .career-area .career-area--item .percentage {
    font-size: 1.17vw;
  }
}
[data-category="faculty"] .career-area .career-area--item .area {
  margin-right: 0.2em;
}
@media (max-width: 767px) {
  [data-category="faculty"] .career-area .wrap-box_sp {
    display: flex;
    justify-content: space-between;
  }
  [data-category="faculty"] .career-area .wrap-box_sp .box_sp {
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .career-area {
    max-width: 13.62vw;
    padding: 1.76vw 2.2vw;
    margin-left: 2.71vw;
  }
}
@media (max-width: 1365px) {
  [data-category="faculty"] .liquid_image-mid img {
    width: 68.81vw;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .liquid_image-mid img {
    width: 100%;
    height: auto;
  }
}
[data-category="faculty"] .scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
}
@media (min-width: 768px) {
  [data-category="faculty"] .scroll-container {
    padding-bottom: 20px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .scroll-container {
    padding-bottom: 2.67vw;
    margin-bottom: 1.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="faculty"] .scroll-container .box-img {
    width: 1820px;
    height: auto;
  }
}
@media (max-width: 767px) {
  [data-category="faculty"] .scroll-container .box-img {
    width: 482.67vw;
    height: 153.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .scroll-container .box-img {
    width: 133.24vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="faculty"] .scroll-container {
    padding-bottom: 1.46vw;
    margin-bottom: 0.37vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] .page-header {
    background-image: url("../img/for-alumni/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] .page-header {
    background-image: url("../img/for-alumni/header_bg@2x.jpg");
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block a {
  color: #333;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block-image {
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block-image {
    height: 57.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-contnt-block-image {
    height: 14.64vw;
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-link-container {
    margin-top: 8vw;
    margin-right: -8vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li.c-info_item {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-link-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-link-container {
    margin-top: 30px;
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    font-size: 1.3rem;
  }
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-alumni"] [data-for-alumni="alumni-association"] .information-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink {
    transition: opacity 0.2s;
  }
  [data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink:hover {
    opacity: 0.6;
  }
}
[data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink:after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 12px;
  background: url("../img/common/textlink_arrow-purple.svg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
  transform: rotate(90deg);
  transform-origin: center center;
}
@media (max-width: 767px) {
  [data-category="for-alumni"] [data-for-alumni="certificates-jc"] .anchorlink:after {
    width: 2.13vw;
    height: 3.2vw;
    margin-left: 1.6vw;
  }
}
[data-category="for-examinees"] ._temp_adjustment {
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="for-examinees"] ._temp_adjustment {
    margin-top: -15px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-examinees"] ._temp_adjustment {
    margin-top: -1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-students"] .page-header {
    background-image: url("../img/for-students/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="for-students"] .page-header {
    background-image: url("../img/for-students/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="for-students"] .-half-wrap {
    margin-left: 50%;
  }
}
[data-category="for-students"] .-is-inline {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="for-students"] [data-for-students="student-dp-uv"] .unique-wrap .sample-component {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="for-students"] [data-for-students="student-dp-uv"] .unique-wrap .sample-component {
    margin-bottom: 4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="for-students"] [data-for-students="student-dp-uv"] .unique-wrap .sample-component {
    margin-right: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="for-students"] .course-number_table tr:nth-of-type(23) td:nth-of-type(2) {
    width: 40%;
  }
}
@media (max-width: 767px) {
  [data-category="for-students"] .course-number_table tr:nth-of-type(23) td:nth-of-type(2) {
    width: 50%;
  }
}
@media (min-width: 768px) {
  [data-category="home"] .page-header {
    background-image: url("../img/home/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="home"] .page-header {
    background-image: url("../img/home/header_bg@2x.jpg");
    background-position: right top;
  }
}
@media (min-width: 768px) {
  [data-category="houjin"] .page-header {
    background-image: url("../img/houjin/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="houjin"] .page-header {
    background-image: url("../img/houjin/header_bg@2x.jpg");
    background-position: right top;
  }
}
[data-pages="houjin-disclosure-172-2"] span.-is-ib {
  display: inline-block;
}
@media (max-width: 767px) {
  [data-pages="houjin-disclosure-172-2"] span.-is-ib {
    margin-top: 3.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .main {
    padding-bottom: 130px;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .main {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .main {
    padding-bottom: 34.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .main {
    padding-bottom: 9.52vw;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .header-logo-container.l-sp {
    display: none;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .header-logo-container.l-pc {
    display: flex;
  }
}
[data-pages="top"] .keyVisual-container {
  position: relative;
}
@media (min-width: 1100px) {
  [data-pages="top"] .keyVisual-container {
    height: 48.83vw;
    overflow: hidden;
  }
  [data-pages="top"] .keyVisual-container iframe {
    height: 50.83vw;
  }
}
@media (max-width: 1099px) {
  [data-pages="top"] .keyVisual-container {
    height: 56.22vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container {
    height: 55.6vw;
  }
}
[data-pages="top"] .keyVisual-container.-is-slide {
  height: auto;
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container.-is-slide {
    padding-top: 16vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container.-is-slide .keyVisual-logo {
    position: absolute;
    top: 3.14vw;
    left: 5.33vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-container.-is-slide .keyVisual-logo img {
    width: 54.93vw;
  }
}
[data-pages="top"] .keyVisual-heading {
  position: absolute;
  z-index: 2;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-heading {
    top: 24.38vw;
    left: 6.59vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-heading {
    bottom: 25.33vw;
    left: 5.33vw;
  }
}
[data-pages="top"] .keyVisual-heading span {
  display: none;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-heading img {
    width: 25.11vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-heading img {
    width: 58.8vw;
  }
}
[data-pages="top"] .keyVisual-bg-video {
  opacity: 0;
  transition: opacity 1s;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-bg-video {
    transform: scale(1.033);
  }
}
[data-pages="top"] .keyVisual-bg-video.ready {
  opacity: 10;
}
[data-pages="top"] .keyVisual-bg-video::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(204,204,204,0.2);
}
[data-pages="top"] .keyVisual-bg-video #videoPlayer {
  min-width: 100%;
  min-height: 100%;
  pointer-events: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
[data-pages="top"] .keyVisual-bg-video #videoPlayer iframe {
  pointer-events: none !important;
}
[data-pages="top"] .keyVisual-slider-slide img {
  width: 100%;
}
[data-pages="top"] .keyVisual-slider-button-prev,
[data-pages="top"] .keyVisual-slider-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-slider-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-slider-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-slider-button-prev.swiper-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-slider-button-prev.swiper-button-next,
  [data-pages="top"] .keyVisual-slider-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .keyVisual-slider-button-prev,
  [data-pages="top"] .keyVisual-slider-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual-logo {
    position: absolute;
    top: 5.33vw;
    left: 5.33vw;
    z-index: 3;
  }
  [data-pages="top"] .keyVisual-logo img {
    width: 20.93vw;
  }
}
[data-pages="top"] .keyVisual .playButton {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.1em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual .playButton {
    font-size: 1.02vw;
    bottom: 2.2vw;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="top"] .keyVisual .playButton:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual .playButton {
    font-size: 2.93vw;
    bottom: 6.67vw;
  }
}
[data-pages="top"] .keyVisual .playButton .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #601986;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual .playButton .icon {
    width: 2.2vw;
    height: 2.2vw;
    margin-bottom: 0.88vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual .playButton .icon {
    width: 7.47vw;
    height: 7.47vw;
    margin-bottom: 2.67vw;
  }
}
[data-pages="top"] .keyVisual .playButton .icon::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #601986;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  [data-pages="top"] .keyVisual .playButton .icon::after {
    border-width: 0.37vw 0vw 0.37vw 0.59vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .keyVisual .playButton .icon::after {
    border-width: 1.4vw 0vw 1.4vw 2.13vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-container {
    display: flex;
  }
}
[data-pages="top"] .notices-inner {
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner {
    padding: 25px;
  }
}
[data-pages="top"] .notices-inner:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner:first-of-type {
    width: 266px;
    flex-shrink: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-inner:first-of-type {
    height: 13.33vw;
  }
}
[data-pages="top"] .notices-inner:first-of-type::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner:first-of-type::after {
    border-width: 12px 0 12px 13px;
    border-color: transparent transparent transparent #5f1985;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-inner:first-of-type::after {
    border-width: 2.4vw 2vw 0vw 2vw;
    border-color: #5f1985 transparent transparent transparent;
    bottom: -2.4vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner:first-of-type::after {
    border-width: 0.88vw 0vw 0.88vw 0.95vw;
    right: -0.88vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner:first-of-type {
    width: 19.47vw;
  }
}
[data-pages="top"] .notices-inner:last-of-type {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-inner:last-of-type {
    padding-right: 150px;
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-inner:last-of-type {
    padding: 6vw 5.33vw 27.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner:last-of-type {
    padding-right: 10.98vw;
    padding-left: 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-inner {
    padding: 1.83vw;
  }
}
[data-pages="top"] .notices-heading {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: bold;
  color: #fff;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-heading {
    font-size: 1.6rem;
    letter-spacing: 0.05em;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-heading {
    font-size: 4vw;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
  }
}
[data-pages="top"] .notices-heading::after {
  content: '!';
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-style: solid;
  border-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-heading::after {
    font-size: 1.6rem;
    width: 30px;
    height: 30px;
    border-width: 2px;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-heading::after {
    font-size: 3.47vw;
    width: 5.33vw;
    height: 5.33vw;
    order: -1;
    border-width: 0.27vw;
    margin-right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-heading::after {
    font-size: 1.17vw;
    width: 2.2vw;
    height: 2.2vw;
    margin-top: 1.1vw;
  }
}
[data-pages="top"] .notices-box {
  display: inline-block;
}
[data-pages="top"] .notices-box a {
  color: #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-box a {
    display: flex;
    line-height: 1.5;
    padding: 5px 0;
  }
  [data-pages="top"] .notices-box a:hover .notices-caption {
    text-decoration: underline;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-box a {
    display: block;
    padding: 3.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-box a {
    padding: 0.37vw 0vw;
  }
}
[data-pages="top"] .notices-date {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-date {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-date {
    font-size: 4vw;
    margin-bottom: 2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-date {
    font-size: 1.02vw;
  }
}
[data-pages="top"] .notices-caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-caption {
    font-size: 1.3rem;
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-caption {
    font-size: 3.73vw;
    line-height: 1.61;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-caption {
    font-size: 0.95vw;
    margin-left: 1.46vw;
  }
}
[data-pages="top"] .notices-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
  position: absolute;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .notices-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .notices-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .notices-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .notices-text-link {
    right: 50px;
    bottom: 17px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .notices-text-link {
    right: 5.33vw;
    bottom: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .notices-text-link {
    right: 3.66vw;
    bottom: 1.24vw;
  }
}
[data-pages="top"] .banner-container {
  background-image: url("../img/top/banner_bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-container {
    padding: 2.93vw 0vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-container {
    padding: 9.87vw 0vw;
  }
}
[data-pages="top"] .banner-wrap {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap {
    padding: 0vw 1.83vw;
  }
}
[data-pages="top"] .banner-wrap a {
  display: inline-block;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap a {
    box-shadow: 10px 10px 10px rgba(51,51,51,0.15);
  }
  [data-pages="top"] .banner-wrap a:hover img {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-wrap a {
    box-shadow: 1.33vw 1.33vw 1.33vw rgba(51,51,51,0.15);
  }
}
[data-pages="top"] .banner-wrap a::before,
[data-pages="top"] .banner-wrap a::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  backface-visibility: hidden;
}
[data-pages="top"] .banner-wrap a::before {
  background-color: #fff;
}
[data-pages="top"] .banner-wrap a::after {
  border-style: solid;
  border-color: #fff;
  z-index: 2;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap a::after {
    border-width: 6px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .banner-wrap a {
    box-shadow: 0.73vw 0.73vw 0.73vw rgba(51,51,51,0.15);
  }
}
[data-pages="top"] .banner-wrap img {
  position: relative;
  z-index: 1;
  display: block;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-wrap img {
    height: 14.64vw;
    transition: opacity 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-wrap img {
    height: 26.67vw;
  }
}
[data-pages="top"] .banner-button-prev,
[data-pages="top"] .banner-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-button-prev,
  [data-pages="top"] .banner-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-button-prev,
  [data-pages="top"] .banner-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-button-prev.swiper-button-prev,
  [data-pages="top"] .banner-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .banner-button-prev.swiper-button-next,
  [data-pages="top"] .banner-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .banner-button-prev,
  [data-pages="top"] .banner-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-button-prev {
    left: 17.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .banner-button-next {
    right: 17.94vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .content > section {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .content > section {
    padding-top: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .content > section {
    padding-top: 7.32vw;
  }
}
[data-pages="top"] .information-container {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-container {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-container {
    padding: 0vw 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-container {
    max-width: 86.38vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0vw 3.66vw;
  }
}
[data-pages="top"] .information-button-prev,
[data-pages="top"] .information-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
  top: 40%;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-button-prev,
  [data-pages="top"] .information-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev,
  [data-pages="top"] .information-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev.swiper-button-prev,
  [data-pages="top"] .information-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev.swiper-button-next,
  [data-pages="top"] .information-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-button-prev,
  [data-pages="top"] .information-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information-button-prev {
    left: 25px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-prev {
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-button-prev {
    left: 1.83vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information-button-next {
    right: 25px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-button-next {
    right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-button-next {
    right: 1.83vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information .information-contnt-block-image {
    height: 200px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information .information-contnt-block-image {
    height: 57.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information .information-contnt-block-image {
    height: 14.64vw;
  }
}
[data-pages="top"] .information-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-link-container {
    margin-top: 8vw;
    margin-right: -8vw;
  }
}
[data-pages="top"] .information-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .information-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .information-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .information-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .information-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .information-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .information-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .information-text-link {
    font-size: 0.95vw;
  }
}
[data-pages="top"] .event-container {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-container {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-container {
    padding: 0vw 10.67vw 0vw 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-container {
    max-width: 86.38vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0vw 1.46vw 0vw 3.66vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date + .event-contnt-block-date::before {
    margin: 0 0.5rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .month {
    font-size: 5.07vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .month::after {
    margin-left: 0;
    font-size: 4vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .day {
    font-size: 10.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-date .week {
    font-size: 3.47vw;
  }
}
[data-pages="top"] .event-contnt-block {
  height: auto;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-contnt-block {
    padding-right: 30px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-contnt-block {
    padding-right: 2.67vw;
    padding-bottom: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-contnt-block a {
    box-shadow: 10px 10px 10px rgba(51,51,51,0.07);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-contnt-block a {
    box-shadow: 1.33vw 1.33vw 1.33vw rgba(51,51,51,0.07);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-contnt-block a {
    box-shadow: 0.73vw 0.73vw 0.73vw rgba(51,51,51,0.07);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-contnt-block {
    padding-right: 2.2vw;
    padding-bottom: 1.46vw;
  }
}
[data-pages="top"] .event-button-prev,
[data-pages="top"] .event-button-next {
  background-image: url("../img/common/carousel_btn.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  border-radius: 50%;
  top: 40%;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-button-prev,
  [data-pages="top"] .event-button-next {
    width: 50px;
    height: 50px;
    box-shadow: 0 10px 10px rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-button-prev,
  [data-pages="top"] .event-button-next {
    width: 8vw;
    height: 8vw;
    box-shadow: 0vw 1.33vw 1.33vw rgba(51,51,51,0.1);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-button-prev.swiper-button-prev,
  [data-pages="top"] .event-button-next.swiper-button-prev {
    left: 2.67vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-button-prev.swiper-button-next,
  [data-pages="top"] .event-button-next.swiper-button-next {
    right: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-button-prev,
  [data-pages="top"] .event-button-next {
    width: 3.66vw;
    height: 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-button-prev {
    left: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-button-prev {
    left: 1.83vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-button-next {
    right: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-button-next {
    right: 1.83vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event .event-contnt-block-image {
    height: 47.47vw;
  }
}
[data-pages="top"] .event-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-link-container {
    margin-top: 8vw;
    margin-right: -8vw;
  }
}
[data-pages="top"] .event-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .event-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .event-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .event-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .event-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .event-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .event-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .event-text-link {
    font-size: 0.95vw;
  }
}
[data-pages="top"] .about-bg {
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-bg {
    height: 520px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-bg {
    height: 69.33vw;
  }
}
[data-pages="top"] .about-bg-inner {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
[data-pages="top"] .about-bg-inner::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0.7) 0, rgba(255,255,255,0) 100%);
}
[data-pages="top"] .about-bg-inner img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-bg {
    height: 38.07vw;
  }
}
[data-pages="top"] .about-wrap {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap {
    width: 50%;
    max-width: 593px;
    min-height: 370px;
    padding: 60px 50px 60px 60px;
    margin-left: auto;
    margin-top: -140px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap {
    width: 89.33vw;
    padding: 8vw 8vw 16vw;
    margin: -13.33vw auto 0vw;
  }
}
[data-pages="top"] .about-wrap-header {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-header {
    margin-bottom: 5.33vw;
  }
}
[data-pages="top"] .about-wrap-header h3 {
  font-weight: lighter;
  letter-spacing: 0.2em;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-header h3 {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-header h3 {
    font-size: 6.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-header h3 {
    font-size: 2.93vw;
  }
}
[data-pages="top"] .about-wrap-header h4 {
  font-weight: lighter;
  color: #601986;
  letter-spacing: 0.2em;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-header h4 {
    font-size: 1.4rem;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-header h4 {
    font-size: 3.47vw;
    line-height: 1.42;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-header h4 {
    font-size: 1.02vw;
    margin-top: 1.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-header {
    margin-bottom: 1.46vw;
  }
}
[data-pages="top"] .about-wrap-caption {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  line-height: 2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-caption {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-caption {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-caption {
    font-size: 1.17vw;
  }
}
[data-pages="top"] .about-wrap-link-container {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-link-container {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-link-container {
    margin-top: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-link-container {
    margin-top: 2.2vw;
  }
}
[data-pages="top"] .about-wrap-text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-text-link {
    font-size: 1.3rem;
  }
  [data-pages="top"] .about-wrap-text-link:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="top"] .about-wrap-text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="top"] .about-wrap-text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-text-link::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-text-link::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .about-wrap-text-link span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .about-wrap-text-link span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-text-link span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap-text-link {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .about-wrap {
    min-height: 27.09vw;
    padding: 4.39vw 3.66vw 4.39vw 4.39vw;
    margin-top: -10.25vw;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department#department {
    padding-top: 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department .section-heading {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department .section-heading {
    margin-bottom: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-container {
    display: flex;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-container {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department-container {
    max-width: 86.38vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-inner {
    display: flex;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-inner + .department-inner {
    padding-top: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-wrap {
    width: 90px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap {
    margin-bottom: 6.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department-wrap a {
    display: block;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="top"] .department-wrap a:hover {
    opacity: 0.6;
  }
  [data-pages="top"] .department-wrap a:hover span::after {
    transform: translate3d(4px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap a {
    display: flex;
    border-width: 1px 0;
    border-color: #ccc;
    border-style: solid;
  }
}
[data-pages="top"] .department-wrap-bg {
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  [data-pages="top"] .department-wrap-bg {
    width: 90px;
    height: 422px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap-bg {
    width: 44.67vw;
    height: 20vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department-wrap-bg {
    width: 6.59vw;
    height: 30.89vw;
    margin-bottom: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department-wrap {
    width: 6.59vw;
    margin-right: 2.2vw;
  }
}
[data-pages="top"] .department h3 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: lighter;
  color: #fff;
  letter-spacing: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h3 {
    font-size: 2.2rem;
    writing-mode: vertical-rl;
    width: 90px;
    height: 422px;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h3 {
    font-size: 4vw;
    height: 10.67vw;
  }
}
[data-pages="top"] .department h3::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h3::after {
    border-width: 7px 0 7px 7px;
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h3::after {
    border-width: 1.73vw 1.67vw 0vw 1.67vw;
    left: 50%;
    bottom: -1.6vw;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h3::after {
    border-width: 0.51vw 0vw 0.51vw 0.51vw;
    right: -0.51vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h3 {
    font-size: 1.61vw;
    width: 6.59vw;
    height: 30.89vw;
  }
}
[data-pages="top"] .department h4 {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: lighter;
  letter-spacing: 0.2em;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 {
    font-size: 2.8rem;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 {
    font-size: 4.27vw;
    display: flex;
    align-items: center;
  }
}
[data-pages="top"] .department h4 span {
  display: inline-block;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 span {
    padding: 0 5px 25px;
    writing-mode: vertical-rl;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 span {
    padding-bottom: 5.33vw;
  }
}
[data-pages="top"] .department h4 span::before {
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 span::before {
    width: 100%;
    left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 span::before {
    width: 4.8vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
[data-pages="top"] .department h4 span::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  [data-pages="top"] .department h4 span::after {
    right: 0;
    border-width: 9px 0 0 9px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department h4 span::after {
    left: calc(50% + 2.4vw);
    transform: translateX(-50%);
    border-width: 1.2vw 0vw 0vw 1.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h4 span {
    padding: 0vw 0.37vw 1.83vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department h4 {
    font-size: 2.05vw;
  }
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h3 {
  background-color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h3 {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h3::after {
    border-color: transparent transparent transparent #5f1985;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h3::after {
    border-color: #5f1985 transparent transparent transparent;
  }
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 {
  color: #5f1985;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 span {
    border-bottom-color: #5f1985;
  }
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 span::before {
  background-color: #5f1985;
}
[data-pages="top"] .department [data-department="FacultyOfHumanities"] h4 span::after {
  border-color: transparent transparent transparent #5f1985;
}
[data-pages="top"] .department [data-department="NutritionScience"] h3 {
  background-color: #9ec238;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="NutritionScience"] h3::after {
    border-color: transparent transparent transparent #9ec238;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="NutritionScience"] h3::after {
    border-color: #9ec238 transparent transparent transparent;
  }
}
[data-pages="top"] .department [data-department="NutritionScience"] h4 {
  color: #9ec238;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="NutritionScience"] h4 span {
    border-bottom-color: #9ec238;
  }
}
[data-pages="top"] .department [data-department="NutritionScience"] h4 span::before {
  background-color: #9ec238;
}
[data-pages="top"] .department [data-department="NutritionScience"] h4 span::after {
  border-color: transparent transparent transparent #9ec238;
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h3 {
  background-color: #1886ba;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] h3::after {
    border-color: transparent transparent transparent #1886ba;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] h3::after {
    border-color: #1886ba transparent transparent transparent;
  }
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h4 {
  color: #1886ba;
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] h4 span {
    border-bottom-color: #1886ba;
  }
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h4 span::before {
  background-color: #1886ba;
}
[data-pages="top"] .department [data-department="PreschoolEducation"] h4 span::after {
  border-color: transparent transparent transparent #1886ba;
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="PreschoolEducation"] .department-wrap:last-of-type {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="english"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_01.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="english"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_01@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="japanese"] {
    margin-top: 115px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="japanese"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_02.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="japanese"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_02@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="japanese"] {
    margin-top: 8.42vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="society"] {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="society"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_03.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="society"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_03@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="society"] {
    margin-top: 2.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="childhood"] {
    margin-top: 120px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_04.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_04@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="childhood"] {
    margin-top: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="nutrition"] {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="nutrition"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_05.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="nutrition"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_05@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="nutrition"] {
    margin-top: 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="early-childhood"] {
    margin-top: 60px;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] .department [data-department="early-childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_06.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department [data-department="early-childhood"] .department-wrap-bg {
    background-image: url("../img/top/department_bg_06@2x.jpg");
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="top"] .department [data-department="early-childhood"] {
    margin-top: 4.39vw;
  }
}
[data-pages="top"] .section-heading span {
  transition: transform 0.5s 0.3s ease-in-out;
}
@media (min-width: 768px) {
  [data-pages="top"] .section-heading span {
    transform: translateY(150%);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .section-heading span {
    transform: translateY(170%);
  }
}
[data-pages="top"] .section-heading span:last-of-type {
  transition-delay: 0.5s;
}
[data-pages="top"] .information-container,
[data-pages="top"] .event-container {
  transition: transform 0.5s 0.9s ease-in-out, opacity 0.5s 0.9s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
}
[data-pages="top"] .about-bg {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
[data-pages="top"] .about-wrap {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
}
[data-pages="top"] .department-inner .department-wrap {
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
@media (min-width: 768px) {
  [data-pages="top"] .department-inner .department-wrap:nth-of-type(odd) {
    transform: translateY(40px);
  }
  [data-pages="top"] .department-inner .department-wrap:nth-of-type(even) {
    transform: translateY(-40px);
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-inner .department-wrap {
    transform: translateY(40px);
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(1) {
    transition-delay: 0.2s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(2) {
    transition-delay: 0.4s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(3) {
    transition-delay: 0.6s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(4) {
    transition-delay: 0.8s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="FacultyOfHumanities"] .department-wrap:nth-of-type(5) {
    transition-delay: 1s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="NutritionScience"] .department-wrap:nth-of-type(1) {
    transition-delay: 0.4s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="NutritionScience"] .department-wrap:nth-of-type(2) {
    transition-delay: 0.6s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="PreschoolEducation"] .department-wrap:nth-of-type(1) {
    transition-delay: 0.2s;
  }
}
@media (min-width: 768px) {
  [data-pages="top"] [data-department="PreschoolEducation"] .department-wrap:nth-of-type(2) {
    transition-delay: 0.4s;
  }
}
[data-pages="top"] .jsInview.is-active .section-heading span {
  transform: translateY(0);
}
[data-pages="top"] .jsInview.is-active .information-container,
[data-pages="top"] .jsInview.is-active .event-container {
  transform: translateY(0);
  opacity: 1;
}
[data-pages="top"] .about-bg.is-active {
  opacity: 1;
}
[data-pages="top"] .about-wrap.is-active {
  transform: translateY(0);
  opacity: 1;
}
@media (min-width: 768px) {
  [data-pages="top"] .department.jsInview.is-active .department-wrap {
    transform: translateY(0) !important;
    opacity: 1;
  }
}
@media (max-width: 767px) {
  [data-pages="top"] .department-wrap.jsInview.is-active {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .page-header {
    background-image: url("../img/information/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .page-header {
    background-image: url("../img/information/header_bg@2x.jpg");
  }
}
[data-pages="information"] .pagenation .prev-btn a,
[data-pages="information"] .pagenation .next-btn a {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}
[data-pages="information"] .content {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-pages="information"] .content {
    padding: 70px 0 165px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content {
    padding: 13.33vw 0vw 33.33vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-section {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-section {
    padding: 0vw 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-section {
    padding: 0vw 3.66vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-container {
    max-width: 1080px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-container {
    max-width: 79.06vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-latest-container {
    display: flex;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-latest-container {
    margin-bottom: 10.67vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-latest-container li {
    width: calc(33.33% - 20px);
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-latest-container li + li {
    margin-left: 30px;
  }
  [data-pages="information"] .content-latest-container li + li:first-of-type {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-latest-container li + li {
    margin-top: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-latest-container li + li {
    margin-left: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-latest-container li {
    width: calc(33.33% - 1.46vw);
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-list + li {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-archive-list + li {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-list + li {
    margin-top: 1.46vw;
  }
}
[data-pages="information"] .content-archive-list a {
  color: #333;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-list a {
    display: flex;
    padding: 35px 40px;
    opacity: 1;
    -ms-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  [data-pages="information"] .content-archive-list a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-archive-list a {
    display: block;
    padding: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-list a {
    padding: 2.56vw 2.93vw;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-header {
    width: 24%;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-header {
    width: 30%;
  }
}
@media (min-width: 768px) {
  [data-pages="information"] .content-archive-title {
    font-size: 1.6rem;
    line-height: 1.63;
    margin-top: -0.3em;
  }
}
@media (max-width: 767px) {
  [data-pages="information"] .content-archive-title {
    font-size: 3.73vw;
    line-height: 2;
    margin-top: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content-archive-title {
    font-size: 1.17vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="information"] .content {
    padding: 5.12vw 0vw 12.08vw;
  }
}
@media (min-width: 768px) {
  [data-category="jc"] .page-header {
    background-image: url("../img/jc/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="jc"] .page-header {
    background-image: url("../img/jc/header_bg@2x.jpg");
    background-position: right top;
  }
}
[data-category="professor"] .page-header {
  text-align: center;
}
@media (min-width: 768px) {
  [data-category="professor"] .page-header {
    background-image: url("../img/faculty/professor/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .page-header {
    background-image: url("../img/faculty/professor/header_bg-sp.jpg");
  }
}
[data-category="professor"].ui-template .list-withDot li:before {
  background-color: #9ec238;
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container {
    flex-wrap: wrap;
  }
}
[data-category="professor"] .ui-template .tab-container .tab {
  text-align: center;
  padding: 0;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .tab-container .tab {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container .tab {
    flex-direction: column;
    width: 100%;
    margin-top: 6.93vw;
  }
}
[data-category="professor"] .ui-template .tab-container .tab span {
  width: 100%;
  display: block;
  padding-right: 0;
  padding-left: 0;
}
[data-category="professor"] .ui-template .tab-container .tab .faculty {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .tab-container .tab .faculty {
    font-size: 13px;
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container .tab .faculty {
    font-size: 3.2vw;
    margin-bottom: 1.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .ui-template .tab-container .tab .faculty {
    font-size: 0.95vw;
    margin-bottom: 0.59vw;
  }
}
[data-category="professor"] .ui-template .tab-container .tab .department {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .tab-container .tab .department {
    font-size: 15px;
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .tab-container .tab .department {
    font-size: 3.73vw;
    margin-bottom: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .ui-template .tab-container .tab .department {
    font-size: 1.1vw;
    margin-bottom: 1.17vw;
  }
}
[data-category="professor"] .ui-template .block-teachers {
  background-color: #f5f6f8;
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .block-teachers {
    padding: 40px 0;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .block-teachers {
    padding: 10.67vw 5.33vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .ui-template .block-teachers .click_area {
    width: 100%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .ui-template .block-teachers .click_area {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .ui-template .block-teachers {
    padding: 2.93vw 0vw;
  }
}
[data-category="professor"] .list-teachers li {
  width: 100%;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li {
    margin-bottom: 5.33vw;
  }
}
[data-category="professor"] .list-teachers li a {
  width: 100%;
  color: #333;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li a {
    display: table;
  }
  [data-category="professor"] .list-teachers li a:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li a {
    display: flex;
    padding: 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-image,
  [data-category="professor"] .list-teachers li .area-text,
  [data-category="professor"] .list-teachers li .area-name,
  [data-category="professor"] .list-teachers li .area-specialty {
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-text {
    width: 52vw;
    margin-left: 5.33vw;
  }
}
[data-category="professor"] .list-teachers li .area-image {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-image {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-image {
    width: 26.67vw;
    height: 26.67vw;
  }
}
[data-category="professor"] .list-teachers li .area-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-image {
    width: 8.78vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-name {
    padding-left: 40px;
    width: 320px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-name {
    margin-bottom: 3.47vw;
  }
}
[data-category="professor"] .list-teachers li .area-name span {
  display: block;
}
[data-category="professor"] .list-teachers li .area-name .professor {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-name .professor {
    margin-bottom: 8px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-name .professor {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-name .professor {
    margin-bottom: 0.59vw;
    font-size: 1.1vw;
  }
}
[data-category="professor"] .list-teachers li .area-name .en {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-name .en {
    margin-top: 8px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .list-teachers li .area-name .en {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-name .en {
    margin-top: 0.59vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-name {
    padding-left: 2.93vw;
    width: 23.43vw;
  }
}
[data-category="professor"] .list-teachers li .area-specialty span:nth-child(n+2) {
  padding-left: 0.5em;
}
@media (min-width: 768px) {
  [data-category="professor"] .list-teachers li .area-specialty {
    padding-left: 40px;
    width: 460px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li .area-specialty {
    padding-left: 2.93vw;
    width: 33.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .list-teachers li {
    margin-bottom: 1.46vw;
  }
}
[data-category="professor"] .profile .profile-item {
  display: table;
  width: 100%;
}
[data-category="professor"] .profile .profile-item .profile-heading,
[data-category="professor"] .profile .profile-item .profile-contents {
  display: table-cell;
  vertical-align: top;
  letter-spacing: 0.05em;
  line-height: 1.8;
}
@media (min-width: 768px) {
  [data-category="professor"] .profile .profile-item .profile-heading,
  [data-category="professor"] .profile .profile-item .profile-contents {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .profile .profile-item .profile-heading,
  [data-category="professor"] .profile .profile-item .profile-contents {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .profile .profile-item .profile-heading,
  [data-category="professor"] .profile .profile-item .profile-contents {
    font-size: 1.1vw;
  }
}
[data-category="professor"] .profile .profile-item .profile-heading {
  color: #601986;
}
[data-category="professor"] .main_profile {
  display: grid;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile {
    grid-template-rows: 80px 80px 1fr;
    grid-template-columns: 160px 1fr;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile {
    grid-template-columns: 26.67vw 1fr;
  }
}
[data-category="professor"] .main_profile .area-image {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-image {
    grid-row: 1/3;
    grid-column: 1/2;
    width: 100%;
    height: 160px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-image {
    width: 100%;
    height: 26.67vw;
    grid-row: 1/2;
    grid-column: 1/2;
  }
}
[data-category="professor"] .main_profile .area-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-image {
    height: 11.71vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-name {
    grid-row: 1/2;
    grid-column: 2/3;
    padding: 10px 40px 0;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-name {
    grid-row: 1/2;
    grid-column: 2/3;
    padding: 2.67vw 5.33vw 0vw;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-name .ja {
    display: block;
  }
}
[data-category="professor"] .main_profile .area-name .en {
  letter-spacing: 0.05em;
  line-height: 1.6;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-name .en {
    margin-left: 10px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-name .en {
    display: block;
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-name .en {
    margin-left: 0.73vw;
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-name {
    padding: 0.73vw 2.93vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty {
    grid-row: 2/4;
    grid-column: 2/3;
    padding: 0 40px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty {
    margin-top: 5.33vw;
    grid-row: 2/3;
    grid-column: 1/3;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item {
    margin-bottom: 1.33vw;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading {
  position: relative;
  word-break: break-all;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading {
    width: 20%;
    padding-right: 6%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading {
    width: 40%;
    padding-right: 8%;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
  position: absolute;
  right: 4%;
  display: block;
  content: "";
  height: 1px;
  background: #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
    width: 74%;
    top: 13px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
    width: 70%;
    top: 3.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-heading::after {
    top: 0.95vw;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item .profile-contents {
  word-break: break-all;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-contents {
    width: 80%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item .profile-contents {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item {
    margin-bottom: 0.73vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--address .profile-heading::after {
    width: 24%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--address .profile-heading::after {
    width: 16%;
  }
}
[data-category="professor"] .main_profile .area-specialty .profile-item--address .profile-contents span:first-of-type {
  margin-right: 0.5em;
}
@media (min-width: 768px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--url .profile-heading::after {
    width: 24%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .main_profile .area-specialty .profile-item--url .profile-heading::after {
    width: 16%;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile .area-specialty {
    padding: 0vw 2.93vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .main_profile {
    grid-template-rows: 5.86vw 5.86vw 1fr;
    grid-template-columns: 11.71vw 1fr;
  }
}
[data-category="professor"] .sub_profile {
  border-top: 1px solid #ccc;
}
[data-category="professor"] .sub_profile .profile-item {
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item {
    padding: 20px 40px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item {
    padding: 5.33vw 2.67vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item .profile-heading {
    width: 20%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item .profile-heading {
    display: block;
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents {
    width: 80%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item {
    padding: 1.46vw 2.93vw;
  }
}
[data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
  display: block;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
    margin-top: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .achievement_title:nth-child(n+2) {
    margin-top: 1.46vw;
  }
}
[data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
  border-bottom: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
    width: 100%;
    display: table;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner .achievement_title {
    display: table-cell;
    width: 24%;
  }
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner .achievement_contents {
    display: table-cell;
    width: 70%;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
    padding-top: 5.33vw;
    padding-bottom: 5.33vw;
  }
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner .achievement_contents {
    padding-left: 1em;
  }
}
[data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner:first-of-type {
  padding-top: 0;
}
[data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner:last-of-type {
  border: none;
  padding-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item--achievement .profile-contents--inner {
    padding-top: 1.46vw;
    padding-bottom: 1.46vw;
  }
}
[data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
  position: relative;
  height: auto;
  padding-top: 56.25%;
}
@media (min-width: 768px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
    display: block;
    width: 540px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
    width: 100%;
  }
}
[data-category="professor"] .sub_profile .profile-item .profile-contents--movie iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .sub_profile .profile-item .profile-contents--movie {
    width: 39.53vw;
  }
}
[data-category="professor"] .link-container--back {
  text-align: center;
}
[data-category="professor"] .link-container--back .text-link {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-category="professor"] .link-container--back .text-link {
    font-size: 1.6rem;
  }
  [data-category="professor"] .link-container--back .text-link:hover::after {
    transform: translate3d(-10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .link-container--back .text-link {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-category="professor"] .link-container--back .text-link::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-category="professor"] .link-container--back .text-link::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #601986 transparent transparent;
}
@media (min-width: 768px) {
  [data-category="professor"] .link-container--back .text-link::after {
    border-width: 9px 9px 0 0;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .link-container--back .text-link::after {
    border-width: 2.67vw 2.67vw 0vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .link-container--back .text-link::after {
    border-width: $size 0vw 0vw $size;
  }
}
[data-category="professor"] .link-container--back .text-link span {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
@media (min-width: 768px) {
  [data-category="professor"] .link-container--back .text-link span {
    padding-right: 6px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .link-container--back .text-link span {
    padding-right: 1.6vw;
    padding-left: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .link-container--back .text-link span {
    padding-right: 0.44vw;
    padding-left: 1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .link-container--back .text-link {
    font-size: 1.17vw;
  }
}
[data-category="professor"] .tab_link-container {
  display: flex;
  text-align: center;
}
[data-category="professor"] .tab_link-container .tab_link {
  width: 100%;
}
[data-category="professor"] .tab_link-container .tab_link a,
[data-category="professor"] .tab_link-container .tab_link span {
  display: block;
  width: 100%;
  height: 100%;
  letter-spacing: 0.05em;
  line-height: 1;
  transition: 0.2s;
}
@media (min-width: 768px) {
  [data-category="professor"] .tab_link-container .tab_link a,
  [data-category="professor"] .tab_link-container .tab_link span {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .tab_link-container .tab_link a,
  [data-category="professor"] .tab_link-container .tab_link span {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .tab_link-container .tab_link a,
  [data-category="professor"] .tab_link-container .tab_link span {
    font-size: 1.24vw;
  }
}
[data-category="professor"] .tab_link-container .tab_link span {
  color: #333;
  border-bottom: 2px solid #ccc;
}
@media (min-width: 768px) {
  [data-category="professor"] .tab_link-container .tab_link span {
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .tab_link-container .tab_link span {
    padding: 5.33vw 0vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .tab_link-container .tab_link span {
    padding: 1.46vw 0vw;
  }
}
[data-category="professor"] .tab_link-container .tab_link.active span {
  color: #5f1985;
  border-bottom: 2px solid #601986;
}
[data-category="professor"] .tab_link-container .tab_link:hover a span {
  border-bottom: 2px solid #601986;
  color: #5f1985;
  opacity: 0.6;
}
@media (max-width: 767px) {
  [data-category="professor"] .tab_link-container {
    padding: 0vw 5.33vw;
  }
}
[data-category="professor"] .pagenation .disabled {
  display: none;
}
@media (min-width: 768px) {
  [data-category="professor"] .pagenation .page-number:hover a {
    color: #fff;
  }
}
[data-category="professor"] .pagenation .page-number.current a {
  color: #fff;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-category="professor"] .pagenation .page-number a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  [data-category="professor"] .pagenation .page-number a {
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .pagenation .page-number a {
    line-height: 12vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .pagenation .page-number a {
    line-height: 3.66vw;
  }
}
[data-category="professor"] .pagenation .prev-btn,
[data-category="professor"] .pagenation .next-btn {
  background-image: url("../img/common/pagenation_arrow.svg");
}
[data-category="professor"] .pagenation .prev-btn a,
[data-category="professor"] .pagenation .next-btn a {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .ui-template {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch {
    padding: 0vw 5.33vw;
  }
}
[data-pages="professor_serch"] .block-serch input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}
[data-pages="professor_serch"] .block-serch .serch-item {
  display: table;
  width: 100%;
  margin-bottom: 30px;
}
[data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
[data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
  display: table-cell;
  vertical-align: top;
  letter-spacing: 0.05em;
  line-height: 1.8;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
  [data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
  [data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
    display: block;
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading,
  [data-pages="professor_serch"] .block-serch .serch-item .serch-contents {
    font-size: 1.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading {
    width: 260px;
    padding: 6px 18px 0 0;
    text-align: right;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading {
    margin-bottom: 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .serch-item .serch-heading {
    width: 19.03vw;
    padding: 0.44vw 1.32vw 0vw 0vw;
  }
}
[data-pages="professor_serch"] .block-serch .selects_wrap {
  display: block;
  border: 1px solid #ccc;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap {
    width: 350px;
  }
}
[data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  background-image: url("../img/faculty/professor/arrow.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
    width: 350px;
    line-height: 40px;
    padding: 0 30px 0 10px;
    height: 40px;
    background-size: 13px 8px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
    white-space: initial;
    height: 10.67vw;
    line-height: 10.67vw;
    padding: 0vw 5.33vw 0vw 2.67vw;
    background-size: 3.47vw 2.13vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap .nochecked {
    width: 25.62vw;
    padding: 0vw 2.2vw 0vw 0.73vw;
    height: 2.93vw;
    line-height: 2.93vw;
    background-size: 0.95vw 0.59vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .selects_wrap {
    width: 25.62vw;
  }
}
[data-pages="professor_serch"] .block-serch .input_area {
  display: block;
  cursor: pointer;
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .input_area {
    line-height: 2.4;
    padding: 0 20px;
    font-size: 13px;
  }
  [data-pages="professor_serch"] .block-serch .input_area:hover {
    opacity: 0.6;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .input_area {
    line-height: 10.67vw;
    padding: 0vw 5.33vw;
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .input_area {
    padding: 0vw 1.46vw;
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .input_area--text {
    padding: 0 10px;
    height: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .input_area--text {
    padding: 0vw 2.67vw;
    height: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .input_area--text {
    padding: 0vw 0.73vw;
    height: 2.93vw;
  }
}
[data-pages="professor_serch"] .block-serch input[type=radio] {
  display: none;
}
[data-pages="professor_serch"] .block-serch input[type=radio].hide-radiobtn {
  display: none;
}
[data-pages="professor_serch"] .block-serch input[type=radio]::-ms-clear {
  visibility: hidden;
}
[data-pages="professor_serch"] .block-serch .answer {
  max-height: 200px;
  overflow-y: scroll;
  display: none;
  background: #f5f6f8;
}
[data-pages="professor_serch"] .block-serch .tab {
  cursor: pointer;
  transition: 0.2s;
}
[data-pages="professor_serch"] .block-serch .notes {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .notes {
    margin-top: 8px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .notes {
    margin-top: 2.13vw;
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .notes {
    margin-top: 0.59vw;
    font-size: 0.95vw;
  }
}
[data-pages="professor_serch"] .block-serch .part_heading {
  color: #8c8c8c;
  line-height: 2.4;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .part_heading {
    padding: 0 10px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .part_heading {
    padding: 0vw 2.67vw;
    font-size: 3.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .part_heading {
    padding: 0vw 0.73vw;
    font-size: 0.95vw;
  }
}
[data-pages="professor_serch"] .block-serch .wrap-button {
  text-align: center;
}
[data-pages="professor_serch"] .block-serch .wrap-button .button {
  text-align: center;
  color: #fff;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button {
    border-radius: 20px;
    width: 180px;
    font-size: 15px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button {
    border-radius: 5.33vw;
    width: 38.67vw;
    font-size: 3.73vw;
    line-height: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button {
    width: 13.18vw;
    font-size: 1.1vw;
    line-height: 2.93vw;
  }
}
[data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
  background-color: $thema-purple;
}
@media (min-width: 768px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
    margin-right: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="professor_serch"] .block-serch .wrap-button .button-serch {
    margin-right: 2.93vw;
  }
}
[data-pages="professor_serch"] .block-serch .wrap-button .button-clear {
  background-color: #333;
  transition: 0.2s;
}
[data-pages="professor_serch"] .block-serch .wrap-button .button-clear:hover {
  opacity: 0.7;
}
[data-category="maintenance"] .header {
  z-index: -1;
  pointer-events: none;
}
[data-category="maintenance"] .footer {
  z-index: -1;
  pointer-events: none;
}
[data-category="maintenance"] .main {
  height: 70vh;
}
[data-category="maintenance"] article .mask {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: rgba(255,255,255,0.8);
  padding: 0;
  z-index: 99;
}
[data-category="maintenance"] article .container {
  position: relative;
  z-index: 999;
}
@media (min-width: 768px) {
  [data-category="maintenance"] article .container.-mid {
    max-width: 1000px;
  }
}
@media (max-width: 1365px) {
  [data-category="maintenance"] article .container.-mid {
    max-width: 73.21vw;
  }
}
[data-category="maintenance"] article .notice-wrap {
  border: 1px solid #000;
}
[data-category="maintenance"] article .-center {
  text-align: center;
}
[data-category="maintenance"] article .content-container .inquery-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
[data-category="maintenance"] article .content-container .inquery-content .margin-top-lv3 {
  margin-top: 29px;
}
@media (max-width: 1365px) {
  [data-category="maintenance"] article .content-container .inquery-content .margin-top-lv3 {
    margin-top: 2.12vw;
  }
}
[data-category="maintenance"] article .content-container .inquery-content .margin-top-little {
  margin-top: 2px;
}
@media (max-width: 1365px) {
  [data-category="maintenance"] article .content-container .inquery-content .margin-top-little {
    margin-top: 0.15vw;
  }
}
@media (min-width: 768px) {
  [data-category="maintenance"] [data-contact-list="index"] .inquiry {
    min-width: 310px;
  }
}
@media (max-width: 1365px) {
  [data-category="maintenance"] [data-contact-list="index"] .inquiry {
    min-width: 22.69vw;
  }
}
@media (max-width: 767px) {
  [data-category="maintenance"] [data-contact-list="index"] .inquiry {
    width: 100% !important;
  }
}
[data-pages="uitemplate nyushi_index"] .link-content {
  text-align: right;
}
[data-pages="uitemplate nyushi_index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="uitemplate nyushi_index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="uitemplate nyushi_index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@media (min-width: 768px) {
  [data-pages="uitemplate nyushi_index"] .nyushi-footer-inner.adjustment-width .buttonlink {
    width: 270px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate nyushi_index"] .nyushi-footer-inner.adjustment-width .buttonlink {
    width: 19.77vw;
  }
}
[data-pages="uitemplate sougousenbatsu_sengan"] .class {
  color: #00f;
}
@media (min-width: 768px) {
  [data-pages="uitemplate sougousenbatsu_sengan"] .ui-template .buttonlink {
    width: 540px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="uitemplate sougousenbatsu_sengan"] .ui-template .buttonlink {
    width: 39.53vw;
  }
}
[data-pages="uitemplate nyushi-mondai"] .table-nyushi-mondai {
  text-align: left;
}
[data-pages="uitemplate nyushi-mondai"] .table-nyushi-mondai tr td {
  padding-left: 1em;
}
[data-pages="uitemplate nyushi-mondai"] .table-nyushi-mondai .-w6em {
  width: 6em;
}
@media (min-width: 768px) {
  [data-category="others"] .page-header {
    background-image: url("../img/others/header_bg.jpg");
  }
}
@media (max-width: 767px) {
  [data-category="others"] .page-header {
    background-image: url("../img/others/header_bg@2x.jpg");
  }
}
@media (min-width: 768px) {
  [data-category="others"] [data-others="logo"] .per60 {
    width: 60%;
  }
}
@media (max-width: 767px) {
  [data-category="others"] [data-others="logo"] .img-logotype {
    width: 70%;
    margin: 0 auto;
  }
}
[data-category="professor"] [class*="u-professor_d-flex"] {
  display: flex;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_d-flex"][class*="__nosp"] {
    display: block;
  }
}
[data-category="professor"] [class*="u-professor_d-block"] {
  display: block;
}
[data-category="professor"] [class*="u-professor_d-inline-block"] {
  display: inline-block;
}
[data-category="professor"] [class*="u-professor_align-items-center"] {
  align-items: center;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_align-items-center"][class*="__nosp"] {
    align-items: inherit;
  }
}
[data-category="professor"] [class*="u-professor_pl-0_5"] {
  padding-left: 0.5em;
}
[data-category="professor"] [class*="u-professor_pl-4"] {
  padding-left: 4em;
}
[data-category="professor"] [class*="u-professor_pr-4"] {
  padding-right: 4em;
}
[data-category="professor"] [class*="u-professor_pt-1_6"] {
  padding-top: 1.6em;
}
[data-category="professor"] [class*="u-professor_pb-1_6"] {
  padding-bottom: 1.6em;
}
[data-category="professor"] [class*="u-professor_pt-custom"] {
  padding-top: 5.33vw !important;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pl"][class*="__nospPl"] {
    padding-left: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pl"][class*="__nopcPl"] {
    padding-left: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pr"][class*="__nospPr"] {
    padding-right: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pr"][class*="__nopcPr"] {
    padding-right: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pt"][class*="__nospPt"] {
    padding-top: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pt"][class*="__nopcPt"] {
    padding-top: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_pb"][class*="__nospPb"] {
    padding-bottom: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_pb"][class*="__nopcPb"] {
    padding-bottom: initial;
  }
}
[data-category="professor"] [class*="u-professor_mt-08"] {
  margin-top: 0.8em;
}
[data-category="professor"] [class*="u-professor_mb-08"] {
  margin-bottom: 0.8em;
}
[data-category="professor"] [class*="u-professor_mb-2"] {
  margin-bottom: 2em;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_mt"][class*="__nospMt"] {
    margin-top: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_mt"][class*="__nopcMt"] {
    margin-top: initial;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_mb"][class*="__nospMb"] {
    margin-bottom: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_mb"][class*="__nopcMb"] {
    margin-bottom: initial;
  }
}
[data-category="professor"] [class*="u-professor_color-purple"] {
  color: #601986;
}
[data-category="professor"] .u-professor_fs-md {
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-category="professor"] .u-professor_fs-md {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .u-professor_fs-md {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .u-professor_fs-md {
    font-size: 1.1vw;
  }
}
[data-category="professor"] .u-professor_fs-mds {
  letter-spacing: 0.05em;
  line-height: 1.2;
}
@media (min-width: 768px) {
  [data-category="professor"] .u-professor_fs-mds {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .u-professor_fs-mds {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-category="professor"] .u-professor_fs-mds {
    font-size: 0.95vw;
  }
}
[data-category="professor"] .u-professor_ff-roboto {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
[data-category="professor"] [class*="u-professor_w-10"] {
  width: 10%;
}
[data-category="professor"] [class*="u-professor_w-15"] {
  width: 15%;
}
[data-category="professor"] [class*="u-professor_w-20"] {
  width: 20%;
}
[data-category="professor"] [class*="u-professor_w-25"] {
  width: 25%;
}
[data-category="professor"] [class*="u-professor_w-30"] {
  width: 30%;
}
[data-category="professor"] [class*="u-professor_w-35"] {
  width: 35%;
}
[data-category="professor"] [class*="u-professor_w-40"] {
  width: 40%;
}
[data-category="professor"] [class*="u-professor_w-45"] {
  width: 45%;
}
[data-category="professor"] [class*="u-professor_w-50"] {
  width: 50%;
}
[data-category="professor"] [class*="u-professor_w-75"] {
  width: 75%;
}
[data-category="professor"] [class*="u-professor_w-95"] {
  width: 95%;
}
[data-category="professor"] [class*="u-professor_w-100"] {
  width: 100%;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_w-"][class*="__nospW"] {
    width: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_w-"][class*="__nopcW"] {
    width: initial;
  }
}
[data-category="professor"] [class*="u-professor_h-100"] {
  height: 100%;
}
[data-category="professor"] [class*="u-professor_h-50"] {
  height: 50%;
}
[data-category="professor"] [class*="u-professor_h-25"] {
  height: 25%;
}
@media (max-width: 767px) {
  [data-category="professor"] [class*="u-professor_h-"][class*="__nospH"] {
    height: initial;
  }
}
@media (min-width: 768px) {
  [data-category="professor"] [class*="u-professor_h-"][class*="__nopcH"] {
    height: initial;
  }
}
[data-category="professor"] .u-professor_area-image-block {
  min-height: 120px;
}
@media (max-width: 1365px) {
  [data-category="professor"] .u-professor_area-image-block {
    min-height: 8.78vw;
  }
}
@media (max-width: 767px) {
  [data-category="professor"] .u-professor_area-image-block {
    width: calc(100% - 26.67vw);
    min-height: 26.67vw;
    margin-left: 2.67vw;
  }
}
[data-category="professor"] .area-image {
  vertical-align: top !important;
}
[data-category="professor"] .area-image .area-image-block {
  width: 12rem !important;
  height: 12rem !important;
  position: relative;
}
@media (max-width: 767px) {
  [data-category="professor"] .area-image .area-image-block {
    width: 26.67vw !important;
    height: 26.67vw !important;
  }
}
[data-category="professor"] .area-image .area-image-block img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
[data-category="professor"] .pagenation a:focus {
  outline: none;
}
[data-pages="students-index"].content-for_menu {
  background-color: #f5f6f8 !important;
}
@media (min-width: 768px) {
  [data-pages="students-index"] .page-header {
    background-image: url("../img/for-students/students-index/header_bg-pc.jpg");
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .page-header {
    background-image: url("../img/for-students/students-index/header_bg-sp.jpg");
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .content-for_menu-inner {
    padding: 78px 0 62px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .content-for_menu-inner {
    padding: 40px 0 62px;
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_outer::after {
    bottom: 47%;
    left: 66%;
    transform: translate(-50%, 0);
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1:hover .wrap_outer::after {
    left: 67%;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-pc.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    min-height: 68px;
    width: 287px;
    padding-left: 72px;
    padding-top: 12px;
    background-position: center left;
    background-size: 78px 68px;
    bottom: 30%;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--1 .wrap_inner {
    padding-top: 68px;
    background-size: 53px 46px;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-note.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 98px;
    background-size: 50px 64px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--2 .wrap_inner {
    padding-top: 68px;
    background-size: 38px 46px;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-money.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 90px;
    background-size: 79px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--3 .wrap_inner {
    padding-top: 65px;
    background-size: 58px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-note.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 98px;
    background-size: 50px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--4 .wrap_inner {
    padding-top: 68px;
    background-size: 38px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-money.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 90px;
    background-size: 79px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--5 .wrap_inner {
    padding-top: 65px;
    background-size: 58px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-information.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 90px;
    background-size: 64px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--6 .wrap_inner {
    padding-top: 65px;
    background-size: 48px auto;
  }
}
[data-pages="students-index"] .list__for_menu .item__for_menu--7 .wrap_inner {
  background-image: url("../img/for-students/students-index/icon-webmail.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--7 .wrap_inner {
    padding-top: 90px;
    background-size: 116px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu .item__for_menu--7 .wrap_inner {
    padding-top: 65px;
    background-size: 71px auto;
  }
}
[data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--1 {
  background-image: url("../img/for-students/students-index/icon harassment.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--1 {
    margin-top: 5px;
    padding-top: 88px;
    background-size: 73px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--1 {
    margin-top: 52px;
    padding-top: 71px;
    background-size: 55px;
  }
}
[data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--2 {
  background-image: url("../img/for-students/students-index/icon bus.svg");
}
@media (min-width: 768px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--2 {
    margin-top: 18px;
    padding-top: 74px;
    background-size: 76px auto;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .list__for_menu_sab .item__for_menu_sab--2 {
    margin-top: 23px;
    padding-top: 52px;
    background-size: 57px auto;
  }
}
[data-pages="students-index"] .container-info {
  margin-top: 10px;
}
[data-pages="students-index"] .link-content {
  text-align: right;
}
[data-pages="students-index"] .link-content .link-container {
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  color: #601986;
  letter-spacing: 0.07em;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="students-index"] .link-content .link-container {
    font-size: 1.3rem;
  }
  [data-pages="students-index"] .link-content .link-container:hover::after {
    transform: translate3d(10px, 0, 0);
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .link-content .link-container {
    font-size: 4vw;
    border-bottom-width: 0.27vw;
  }
}
[data-pages="students-index"] .link-content .link-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #601986;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-pages="students-index"] .link-content .link-container::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: auto;
  border-color: transparent transparent transparent #601986;
}
@media (min-width: 768px) {
  [data-pages="students-index"] .link-content .link-container::after {
    border-width: 11px 0 0 11px;
    transition: transform 0.3s;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .link-content .link-container::after {
    border-width: 3.73vw 0vw 0vw 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="students-index"] .link-content .link-container::after {
    border-width: $size 0vw 0vw $size;
  }
}
@media (min-width: 768px) {
  [data-pages="students-index"] .link-content .link-container span {
    padding-right: 20px;
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="students-index"] .link-content .link-container span {
    padding-right: 5.33vw;
    padding-left: 1.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="students-index"] .link-content .link-container span {
    padding-right: 1.46vw;
    padding-left: 0.44vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="students-index"] .link-content .link-container {
    font-size: 0.95vw;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
[data-pages="teacher-education-sc"] .main {
  background-color: #fffae6;
}
[data-pages="teacher-education-sc"] .ui-template {
  padding-left: 0;
  padding-right: 0;
}
[data-pages="teacher-education-sc"] .ui-template ol {
  margin-left: 0;
}
[data-pages="teacher-education-sc"] .l-pagenav {
  position: sticky;
  top: 40px;
}
[data-pages="teacher-education-sc"] .c-pagenav {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  background: #fcf880;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-pagenav {
    font-weight: bold;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-pagenav {
    padding-top: 8vw;
    padding-right: 5.33vw;
    padding-left: 5.33vw;
    background: #fffae6;
  }
}
[data-pages="teacher-education-sc"] .c-pagenav .c-pagenav--open-button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%23000' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
}
[data-pages="teacher-education-sc"] .container {
  background: transparent;
}
[data-pages="teacher-education-sc"] .c-pagenav__text {
  color: #000;
}
[data-pages="teacher-education-sc"] .c-pagenav__wrap {
  background: #fcf880;
  max-width: 1186px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-pagenav__wrap {
    padding-right: 7rem;
    padding-left: 7rem;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-pagenav__wrap {
    background: #fcf880;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-pagenav__wrap {
    padding-right: 5.12vw;
    padding-left: 5.12vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-pagenav__wrap__list {
    background: #fcf880;
    width: 100%;
    margin: 0 auto;
  }
  [data-pages="teacher-education-sc"] .c-pagenav__wrap__list .c-pagenav__wrap__list {
    border-top: 1px solid #666;
  }
  [data-pages="teacher-education-sc"] .c-pagenav__wrap__list::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.174 13.929'%3E%3Cpath class='a' fill='%23000' d='M4600.89,575.236l-1.41-1.419,12.588-12.511,12.586,12.511-1.41,1.418-11.176-11.109Z' transform='translate(-4599.48 -561.307)'/%3E%3C/svg%3E");
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-pagenav__wrap__list::before {
    border-right: 1px solid #666;
    border-left: 1px solid #666;
  }
}
[data-pages="teacher-education-sc"] .footer {
  border-top-width: 0;
}
[data-pages="teacher-education-sc"] .page-header {
  display: none;
}
[data-pages="teacher-education-sc"] .toTop {
  display: none;
}
[data-pages="teacher-education-sc"] .font_34 {
  line-height: 1.7;
  letter-spacing: 0.05em;
  font-weight: 700;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_34 {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_34 {
    font-size: 5.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_34 {
    font-size: 2.49vw;
  }
}
[data-pages="teacher-education-sc"] .font_30 {
  line-height: 1.7;
  letter-spacing: 0.05em;
  font-weight: 700;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_30 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_30 {
    font-size: 5.6vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_30 {
    font-size: 2.2vw;
  }
}
[data-pages="teacher-education-sc"] .font_24 {
  line-height: 1.7;
  letter-spacing: 0.05em;
  font-weight: 700;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_24 {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_24 {
    font-size: 5.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_24 {
    font-size: 1.76vw;
  }
}
[data-pages="teacher-education-sc"] .font_20 {
  line-height: 1.7;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_20 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_20 {
    font-size: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_20 {
    font-size: 1.46vw;
  }
}
[data-pages="teacher-education-sc"] .font_18 {
  line-height: 1.7;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_18 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_18 {
    font-size: 4.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_18 {
    font-size: 1.32vw;
  }
}
[data-pages="teacher-education-sc"] .font_17 {
  line-height: 1.7;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_17 {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_17 {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_17 {
    font-size: 1.24vw;
  }
}
[data-pages="teacher-education-sc"] .font_16 {
  line-height: 1.7;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_16 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_16 {
    font-size: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_16 {
    font-size: 1.17vw;
  }
}
[data-pages="teacher-education-sc"] .font_15 {
  line-height: 1.933;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_15 {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_15 {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_15 {
    font-size: 1.1vw;
  }
}
[data-pages="teacher-education-sc"] .font_14 {
  line-height: 2.071;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_14 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_14 {
    font-size: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_14 {
    font-size: 1.02vw;
  }
}
[data-pages="teacher-education-sc"] .font_13 {
  line-height: 1.846;
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .font_13 {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .font_13 {
    font-size: 3.47vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .font_13 {
    font-size: 0.95vw;
  }
}
[data-pages="teacher-education-sc"] .font_roboto {
  font-family: 'Roboto', sans-serif;
}
[data-pages="teacher-education-sc"] .font_italic {
  font-style: italic;
}
[data-pages="teacher-education-sc"] .font_nowrap {
  white-space: nowrap;
}
[data-pages="teacher-education-sc"] .center {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .center_pc {
    text-align: center;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .center_sp {
    text-align: center;
  }
}
[data-pages="teacher-education-sc"] .left {
  text-align: left !important;
}
[data-pages="teacher-education-sc"] .container {
  background-color: #fffae6;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-weight: 500;
}
[data-pages="teacher-education-sc"] .container_limid {
  width: 100%;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .container_limid {
    background: #fcf880;
  }
}
[data-pages="teacher-education-sc"] .container_limid .container_limitedChild {
  max-width: 1186px;
  margin-right: auto;
  margin-left: auto;
}
[data-pages="teacher-education-sc"] .container_wide {
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .container_wide {
    width: 100%;
  }
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .container_wide {
    width: 90%;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .container_wide {
    width: 90%;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .container_wide_inner {
    padding-left: 5.333vw;
    padding-right: 5.333vw;
  }
}
[data-pages="teacher-education-sc"] .container_mid {
  max-width: 820px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .container_mid {
    width: 90%;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .container_sml {
    max-width: 390px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .container_sml {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
[data-pages="teacher-education-sc"] .bg_skew_border {
  background-color: #ccc;
  background-image: repeating-linear-gradient(-45deg, #fffae6, #fffae6 7px, transparent 0, transparent 14px);
  border-radius: 10px;
  transform: translate(10px, 10px);
}
[data-pages="teacher-education-sc"] .bg_skew_border_inner {
  border-radius: 10px;
  transform: translate(-10px, -10px);
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .bg_skew_border_padding {
    padding-right: 60px;
    padding-left: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .bg_skew_border_padding {
    padding-right: 4.39vw;
    padding-left: 4.39vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .bg_skew_border_padding_bottom {
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .bg_skew_border_padding_bottom {
    padding-bottom: 4.39vw;
  }
}
[data-pages="teacher-education-sc"] .strong {
  border-radius: 5px;
  padding: 1px 10px;
  display: inline-block;
}
[data-pages="teacher-education-sc"] .bg_color-yellow {
  background-color: #fcee21;
}
[data-pages="teacher-education-sc"] .bg_color-white {
  background-color: #fff;
}
[data-pages="teacher-education-sc"] .bg_color-gray {
  background-color: #f2f2f2;
}
[data-pages="teacher-education-sc"] .color_black {
  color: #000;
}
[data-pages="teacher-education-sc"] .color_white {
  color: #fff;
}
[data-pages="teacher-education-sc"] .color_blue {
  color: #29abe2;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .pd_60 {
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .pd_60 {
    padding-bottom: 4.39vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .pd_20 {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .pd_20 {
    padding: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .pd_20 {
    padding: 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .pd_20-noTop {
    padding: 0 20px 20px 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .pd_20-noTop {
    padding: 0vw 2.67vw 2.67vw 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .pd_20-noTop {
    padding: 0vw 1.46vw 1.46vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .pdt_120 {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .pdt_120 {
    padding-top: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .pdt_120 {
    padding-top: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .mgt_160 {
    margin-top: 160px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .mgt_160 {
    margin-top: 32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .mgt_160 {
    margin-top: 11.71vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .mgt_120 {
    margin-top: 120px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .mgt_120 {
    margin-top: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .mgt_120 {
    margin-top: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .mgt_100 {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .mgt_100 {
    margin-top: 13.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .mgt_100 {
    margin-top: 7.32vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .mgt_14 {
    margin-top: 14px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .mgt_14 {
    margin-top: 1.87vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .mgt_14 {
    margin-top: 1.02vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .mgt_8 {
    margin-top: 8px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .mgt_8 {
    margin-top: 1.07vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .mgt_8 {
    margin-top: 0.59vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .mgt_4 {
    margin-top: 4px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .mgt_4 {
    margin-top: 0.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .mgt_4 {
    margin-top: 0.29vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .pd_min {
    padding: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .pd_min {
    padding: 0.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .pd_min {
    padding: 0.44vw;
  }
}
[data-pages="teacher-education-sc"] .box_radius {
  border-radius: 10px;
}
[data-pages="teacher-education-sc"] .u-border {
  border-bottom: 1px solid #ccc;
}
[data-pages="teacher-education-sc"] .pr {
  position: relative;
}
[data-pages="teacher-education-sc"] .pa {
  position: absolute;
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .overScrollSP {
    overflow-x: scroll;
  }
}
[data-pages="teacher-education-sc"] .spWidth {
  width: 100%;
  height: auto;
}
[data-pages="teacher-education-sc"] .c-candidate_box {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-candidate_box {
    padding: 32px 32px 56px 32px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-candidate_box {
    padding: 4.27vw 4.27vw 10.67vw 4.27vw;
  }
}
[data-pages="teacher-education-sc"] .c-candidate_box .text {
  line-height: 1.3;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-candidate_box .number {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-candidate_box .number {
    font-size: 10.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-candidate_box .number {
    font-size: 4.1vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-candidate_box {
    padding: 2.34vw 2.34vw 4.1vw 2.34vw;
  }
}
[data-pages="teacher-education-sc"] .c-overlap {
  text-align: center;
}
[data-pages="teacher-education-sc"] .c-overlap .number {
  font-style: italic;
  color: #fff;
  line-height: 1;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-overlap .number {
    font-size: 86px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-overlap .number {
    font-size: 16vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-overlap .number {
    font-size: 6.3vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-overlap .head {
    margin-top: -42px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-overlap .head {
    margin-top: -8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-overlap .head {
    margin-top: -3.07vw;
  }
}
[data-pages="teacher-education-sc"] .definitionList {
  border-bottom: 1px solid #666;
  padding: 0 5.333vw 4.5vw;
  margin-bottom: 3.4vw;
}
[data-pages="teacher-education-sc"] .c-link {
  padding: 1px 0;
}
[data-pages="teacher-education-sc"] .c-link .link_arrow {
  display: inline-block;
  color: #000;
}
[data-pages="teacher-education-sc"] .c-link .link_arrow::after {
  content: '';
  display: inline-block;
  transition: all 0.2s;
  background-image: url("../img/career/teacher-education-sc/common/icon-arrow_black.svg");
  background-position: center right;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-link .link_arrow::after {
    width: 18px;
    height: 18px;
    padding-left: 8px;
    background-size: contatain;
    vertical-align: -3px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-link .link_arrow::after {
    width: 3.73vw;
    height: 3.73vw;
    background-size: contain;
    padding-left: 1.33vw;
    vertical-align: -0.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-link .link_arrow::after {
    width: 1.32vw;
    height: 1.32vw;
    background-size: contain;
    padding-left: 0.59vw;
    vertical-align: -0.22vw;
  }
}
[data-pages="teacher-education-sc"] .c-link .link_arrow:hover {
  color: #29abe2;
}
[data-pages="teacher-education-sc"] .c-link .link_arrow:hover::after {
  background-image: url("../img/career/teacher-education-sc/common/icon-arrow_blue.svg");
  background-repeat: no-repeat;
}
[data-pages="teacher-education-sc"] .c-link .link_word {
  display: inline-block;
  color: #29abe2;
}
[data-pages="teacher-education-sc"] .c-link .link_word::after {
  content: '';
  display: inline-block;
  transition: all 0.2s;
  background-image: url("../img/career/teacher-education-sc/common/icon-word.png");
  background-position: center right;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-link .link_word::after {
    width: 23px;
    height: 18px;
    padding-left: 8px;
    background-size: contain;
    vertical-align: -3px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-link .link_word::after {
    width: 6.8vw;
    height: 5.6vw;
    background-size: contain;
    padding-left: 1.33vw;
    vertical-align: -0.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .c-link .link_word::after {
    width: 1.68vw;
    height: 1.32vw;
    background-size: contain;
    padding-left: 0.59vw;
    vertical-align: -0.22vw;
  }
}
[data-pages="teacher-education-sc"] .c-link .link_word:hover {
  opacity: 0.6;
}
[data-pages="teacher-education-sc"] .c-buttonlink {
  display: inline-block;
  text-align: center;
  color: #fff;
  border: 1px solid #29abe2;
  background-color: #29abe2;
  background-image: url("../img/career/teacher-education-sc/common/icon-arrow-white.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  transition: all 0.2s;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-buttonlink {
    width: 100%;
    max-width: 370px;
    border-radius: 28px;
    padding: 13px;
    background-position: center right 40px;
  }
  [data-pages="teacher-education-sc"] .c-buttonlink:hover {
    color: #29abe2;
    background-color: #fff;
    background-image: url("../img/career/teacher-education-sc/common/icon-arrow_blue.svg");
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-buttonlink {
    width: 90%;
    padding: 2.67vw;
    border-radius: 8vw;
    background-position: center right 4vw;
  }
}
[data-pages="teacher-education-sc"] .icon-marker {
  position: relative;
}
[data-pages="teacher-education-sc"] .icon-marker::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  background: url("../img/career/teacher-education-sc/common/icon-marker.png") no-repeat center/contain;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .icon-marker::after {
    width: 200px;
    height: 28px;
    bottom: -22px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .icon-marker::after {
    bottom: -5vw;
    width: 90%;
    height: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .icon-marker::after {
    width: 14.64vw;
    height: 2.05vw;
    bottom: -1.61vw;
  }
}
[data-pages="teacher-education-sc"] .c-supportTable {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  table-layout: fixed;
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-supportTable {
    width: 830px;
  }
}
[data-pages="teacher-education-sc"] .c-supportTable .widthMin {
  width: 120px;
}
[data-pages="teacher-education-sc"] .c-supportTable th {
  font-weight: 700;
  border: 1px solid #666;
  vertical-align: middle;
  padding: 8px;
}
[data-pages="teacher-education-sc"] .c-supportTable th.center {
  padding: 0;
}
[data-pages="teacher-education-sc"] .c-supportTable td {
  border: 1px solid #666;
  vertical-align: middle;
  text-align: center;
}
[data-pages="teacher-education-sc"] .c-supportTable td:not(.bg_color-yellow) {
  background-color: #fffae6;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_blue {
  table-layout: auto;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_blue .tablehead {
  color: #fff;
  background-color: #4f81bd;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_blue .tableata {
  padding: 8px;
  background-color: #fff;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_blue .left {
  text-align: left;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_purple {
  table-layout: auto;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_purple .tableheading {
  color: #fff;
  background-color: #b2a1c7;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_purple .tablehead {
  padding: 8px;
  color: #333;
  background-color: #e5defc;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_purple .tableata {
  padding: 8px;
  background-color: #fff;
}
[data-pages="teacher-education-sc"] .c-supportTable.tableColor_purple .left {
  text-align: left;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .list__contact {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .list__contact {
    padding-bottom: 40px;
  }
}
[data-pages="teacher-education-sc"] .item__contact {
  text-align: center;
  box-sizing: content-box;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .item__contact {
    width: 320px;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .item__contact:first-of-type {
    padding-right: 60px;
    border-right: 1px dashed #000;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .item__contact:first-of-type {
    padding-bottom: 8vw;
    border-bottom: 1px dashed #000;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .item__contact:last-of-type {
    padding-left: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .item__contact:last-of-type {
    padding-top: 8vw;
  }
}
[data-pages="teacher-education-sc"] .item__contact .hukidashi {
  position: relative;
  line-height: 1.6;
  margin-bottom: 28px;
  border-radius: 10px;
  padding: 9px 14px 12px;
}
[data-pages="teacher-education-sc"] .item__contact .hukidashi::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 20px;
  height: 8px;
  transform: translateX(-50%);
  background-image: url("../img/career/teacher-education-sc/common/icon-hukidashi.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .item__contact .hukidashi {
    margin-bottom: 0.27vw;
  }
}
[data-pages="teacher-education-sc"] .item__contact .contact .tel {
  background-image: url("../img/career/teacher-education-sc/common/icon-tell.svg");
}
[data-pages="teacher-education-sc"] .item__contact .contact .mail {
  background-image: url("../img/career/teacher-education-sc/common/icon-mail.svg");
}
[data-pages="teacher-education-sc"] .item__contact .contact .contact_link {
  color: #000;
  letter-spacing: normal;
  background-position: center left;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .item__contact .contact .contact_link {
    padding-left: 30px;
    background-size: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .item__contact .contact .contact_link {
    padding-left: 8vw;
    background-size: 5.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .item__contact .contact .contact_link {
    padding-left: 2.2vw;
    background-size: 1.46vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .page_top img {
    width: 29.33vw;
    height: auto;
  }
}
[data-pages="teacher-education-sc"] .page_nav {
  padding: 96px 0 56px;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .page_nav {
    margin-top: -54px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .page_nav {
    margin-top: -14vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .item__pagenav {
    margin: 4px 8px;
    display: inline-block;
    text-align: center;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .item__pagenav {
    display: block;
    margin: 4px 0;
  }
}
[data-pages="teacher-education-sc"] .link__pagenav {
  color: #000;
  padding: 2px 6px;
}
[data-pages="teacher-education-sc"] .link__pagenav:hover {
  color: #fff;
  background-color: #29abe2;
}
[data-pages="teacher-education-sc"] .support_header {
  background-color: #fcee21;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 13px;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .support_header {
    background-image: url("../img/career/teacher-education-sc/common/header_stripe.png");
    height: 19rem;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .support_header {
    background-image: url("../img/career/teacher-education-sc/common/header_stripe_sp.png");
    height: 33.33vw;
  }
}
[data-pages="teacher-education-sc"] .support_header .support_header-inner {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .support_header .support_header-inner {
    padding-left: 12.3rem;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .support_header .support_header-inner {
    padding-left: 5.33vw;
  }
}
[data-pages="teacher-education-sc"] .support_header .support_header-inner .support_header-inner_illust {
  position: absolute;
  background-image: url("../img/career/teacher-education-sc/common/header-illust.png");
  background-size: 100% auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .support_header .support_header-inner .support_header-inner_illust {
    width: 25.4rem;
    height: 16.9rem;
    right: 63px;
    bottom: -13px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .support_header .support_header-inner .support_header-inner_illust {
    width: 26.13vw;
    height: 17.33vw;
    right: 0.81vw;
    bottom: -1.46vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .support_header .support_header-inner .support_header-inner_illust {
    width: 18.59vw;
    height: 12.37vw;
    right: 4.61vw;
    bottom: -0.95vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .support_header .support_header-inner {
    padding-left: 9vw;
  }
}
[data-pages="teacher-education-sc"] .support_header .support_page_title {
  display: block;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .support_header .support_page_title {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .support_header .support_page_title {
    font-size: 6.67vw;
    line-height: 1.3;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .support_header .support_page_title {
    font-size: 2.93vw;
  }
}
[data-pages="teacher-education-sc"] .support_header .support_page_title-en {
  margin-top: 12px;
  display: block;
  font-family: 'Roboto Condensed', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium', '游ゴシック', 'Yu Gothic Medium', 'YuGothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 18px;
  font-weight: 400;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .support_header .support_page_title-en {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .support_header .support_page_title-en {
    font-size: 3.47vw;
    line-height: 1.31;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .support_header .support_page_title-en {
    font-size: 1.32vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .support_header {
    height: 13.91vw;
  }
}
[data-pages="teacher-education-sc"] .js-pre {
  margin: 16px 16px 40px;
  border-radius: 5px;
  padding: 16px;
  background: #000;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.8461538462;
  overflow: auto;
}
[data-pages="teacher-education-sc"] .u-titleBig {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-titleBig {
    font-size: 3.4rem;
    line-height: 1.24;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-titleBig {
    font-size: 5.6vw;
    line-height: 1.52;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-titleBig {
    font-size: 2.49vw;
    line-height: 1.24;
  }
}
[data-pages="teacher-education-sc"] .u-title {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-title {
    font-size: 3rem;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-title {
    font-size: 5.6vw;
    line-height: 1.52;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-title {
    font-size: 2.2vw;
    line-height: 1.4;
  }
}
[data-pages="teacher-education-sc"] .u-titleS {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-titleS {
    font-size: 1.8rem;
    line-height: 1.61;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-titleS {
    font-size: 4.27vw;
    line-height: 1.63;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-titleS {
    font-size: 1.32vw;
    line-height: 1.61;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-titleS_en {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-titleS_en {
    font-size: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-titleS_en {
    font-size: 2.2vw;
  }
}
[data-pages="teacher-education-sc"] .u-title_border {
  position: relative;
}
[data-pages="teacher-education-sc"] .u-title_border::before {
  content: '';
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  display: block;
  background-color: #fcee21;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-title_border {
    font-size: 24px;
    padding-left: 15px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-title_border {
    font-size: 5.07vw;
    padding-left: 3.2vw;
    line-height: 1.37;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-title_border {
    font-size: 1.76vw;
    padding-left: 1.1vw;
  }
}
[data-pages="teacher-education-sc"] .u-titleRotate {
  display: inline-block;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  margin: auto;
  transform: rotate(-5deg);
  line-height: 1.5;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-titleRotate {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-titleRotate {
    font-size: 3.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-titleRotate {
    font-size: 1.17vw;
  }
}
[data-pages="teacher-education-sc"] .u-title3setPosition {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-title3setPosition {
    top: -59px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-title3setPosition {
    top: -10.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-title3setPosition {
    top: -4.1vw;
  }
}
[data-pages="teacher-education-sc"] .u-titleRowPosition {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-titleRowPosition {
    top: -22px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-titleRowPosition {
    top: -4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-titleRowPosition {
    top: -1.61vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-1rowPc {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-1rowPc:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 5.833333333333333%;
  }
  [data-pages="teacher-education-sc"] .u-1rowPc > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-1rowPc {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-1rowPc:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 5.833333333333333%;
  }
  [data-pages="teacher-education-sc"] .u-1rowPc > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-2rowPc {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-2rowPc:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 9.333333333333334%;
  }
  [data-pages="teacher-education-sc"] .u-2rowPc > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-2rowPc {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-2rowPc:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 9.333333333333334%;
  }
  [data-pages="teacher-education-sc"] .u-2rowPc > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-3rowPc {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-3rowPc:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 12.666666666666668%;
  }
  [data-pages="teacher-education-sc"] .u-3rowPc > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-3rowPc {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-3rowPc:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 12.666666666666668%;
  }
  [data-pages="teacher-education-sc"] .u-3rowPc > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-1rowSp {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-1rowSp:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 17.346938775510203%;
  }
  [data-pages="teacher-education-sc"] .u-1rowSp > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-2rowSp {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-2rowSp:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 27.380952380952383%;
  }
  [data-pages="teacher-education-sc"] .u-2rowSp > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-3rowSp {
    position: relative;
    height: auto;
  }
  [data-pages="teacher-education-sc"] .u-3rowSp:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 37.92517006802721%;
  }
  [data-pages="teacher-education-sc"] .u-3rowSp > .u-ratioContents {
    display: block;
    position: absolute;
  }
}
[data-pages="teacher-education-sc"] .u-lead {
  letter-spacing: 0.05em;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .u-lead {
    font-size: 1.5rem;
    line-height: 1.93;
  }
  [data-pages="teacher-education-sc"] .u-lead +.u-lead {
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .u-lead {
    font-size: 3.73vw;
    line-height: 1.86;
  }
  [data-pages="teacher-education-sc"] .u-lead +.u-lead {
    padding-top: 3.73vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] .u-lead {
    font-size: 1.1vw;
  }
  [data-pages="teacher-education-sc"] .u-lead +.u-lead {
    padding-top: 0.55vw;
  }
}
[data-pages="teacher-education-sc"] .c-row {
  display: flex;
  margin-top: calc(2rem * -1);
  margin-right: calc(2rem * -0.5);
  margin-left: calc(2rem * -0.5);
}
[data-pages="teacher-education-sc"] .c-row > * {
  padding-right: calc(2rem * 0.5);
  padding-left: calc(2rem * 0.5);
  margin-top: 2rem;
}
[data-pages="teacher-education-sc"] .c-row.c-row_noGutters {
  margin-top: calc(0rem * -1);
  margin-right: calc(0rem * -0.5);
  margin-left: calc(0rem * -0.5);
}
[data-pages="teacher-education-sc"] .c-row.c-row_noGutters > * {
  padding-right: calc(0rem * 0.5);
  padding-left: calc(0rem * 0.5);
  margin-top: 0;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .c-row {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-row {
    flex-direction: column;
  }
}
[data-pages="teacher-education-sc"] .c-row .c-col {
  flex: 1 0 0%;
}
[data-pages="teacher-education-sc"] .c-list li {
  list-style: initial;
  color: #29abe2;
  margin-left: 20px;
}
[data-pages="teacher-education-sc"] .c-list li span {
  color: #000;
}
[data-pages="teacher-education-sc"] .u-font-weight-bold {
  font-weight: 700;
}
[data-pages="teacher-education-sc"] .swiper {
  max-width: 630px;
  height: 100%;
  overflow: unset;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .swiper {
    width: 630px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .swiper {
    width: 100%;
  }
}
[data-pages="teacher-education-sc"] .swiper-slide {
  background: #fff;
  border-radius: 10px;
}
[data-pages="teacher-education-sc"] .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
[data-pages="teacher-education-sc"] .swiper-pagination {
  bottom: -30px !important;
}
[data-pages="teacher-education-sc"] .swiper-pagination .swiper-pagination-bullet {
  background: #fff;
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 0 10px !important;
}
[data-pages="teacher-education-sc"] .swiper-pagination .swiper-pagination-bullet-active {
  background: #000;
}
[data-pages="teacher-education-sc"] .swiper-button-prev,
[data-pages="teacher-education-sc"] .swiper-button-next {
  border-radius: 50%;
  background-color: #29abe2;
  top: 58%;
  transform-origin: center;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .swiper-button-prev,
  [data-pages="teacher-education-sc"] .swiper-button-next {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .swiper-button-prev,
  [data-pages="teacher-education-sc"] .swiper-button-next {
    width: 9.33vw;
    height: 9.33vw;
  }
}
[data-pages="teacher-education-sc"] .swiper-button-prev::after,
[data-pages="teacher-education-sc"] .swiper-button-next::after {
  font-weight: 700;
  color: #fff;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] .swiper-button-prev::after,
  [data-pages="teacher-education-sc"] .swiper-button-next::after {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .swiper-button-prev::after,
  [data-pages="teacher-education-sc"] .swiper-button-next::after {
    font-size: 4.27vw;
  }
}
[data-pages="teacher-education-sc"] .swiper-button-prev {
  left: -30px !important;
}
[data-pages="teacher-education-sc"] .swiper-button-prev::after {
  transform: rotate(180deg);
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .swiper-button-prev {
    left: -4.4vw !important;
  }
}
[data-pages="teacher-education-sc"] .swiper-button-next {
  right: -30px !important;
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .swiper-button-next {
    right: -4.4vw !important;
  }
}
[data-pages="teacher-education-sc"] .c-accordionToggle {
  cursor: pointer;
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] .c-accordionToggle {
    padding-right: 13.33vw;
  }
}
[data-pages="teacher-education-sc"] .c-accordionToggle::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  width: 20px;
  height: 4px;
/*縦線に*/
  transform: rotate(90deg);
  background: #000;
  transition: all 0.3s ease-in-out;
}
[data-pages="teacher-education-sc"] .c-accordionToggle::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
/*横線*/
  width: 20px;
  height: 4px;
  background: #000;
  transition: all 0.2s ease-in-out;
}
[data-pages="teacher-education-sc"] .c-accordionToggle.opend::before {
  transform: rotate(180deg);
}
[data-pages="teacher-education-sc"] .c-accordionToggle.opend::after {
  opacity: 0;
}
[data-pages="teacher-education-sc"] .c-accordionToggle + .answer {
  display: none;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
[data-pages="teacher-education-sc"] [data-support="index"].ui-template {
  padding-top: 0;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust .illust_inner {
  width: 100%;
  height: 100%;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_1 {
  width: 151px;
  height: 166px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_1 {
    width: 11.05vw;
    height: 12.15vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_1 {
    width: 34.67vw;
    height: 38.13vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_1 .illust_inner {
  background: url("../img/career/teacher-education-sc/index/illust-1.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_2 {
  width: 247px;
  height: 150px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_2 {
    width: 18.08vw;
    height: 10.98vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_2 {
    width: 55.6vw;
    height: 33.73vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_2 .illust_inner {
  background: url("../img/career/teacher-education-sc/index/illust-2.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_3 {
  width: 247px;
  height: 185px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_3 {
    width: 18.08vw;
    height: 13.54vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_3 {
    width: 53.33vw;
    height: 40vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_3 .illust_inner {
  background: url("../img/career/teacher-education-sc/index/illust-3.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_4 {
  width: 210px;
  height: 223px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_4 {
    width: 15.37vw;
    height: 16.33vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_4 {
    width: 48vw;
    height: 50.93vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_4 .illust_inner {
  background: url("../img/career/teacher-education-sc/index/illust-4.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_5 {
  width: 218px;
  height: 245px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_5 {
    width: 15.96vw;
    height: 17.94vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_5 {
    width: 40vw;
    height: 44.8vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_5 .illust_inner {
  background: url("../img/career/teacher-education-sc/index/illust-5.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_6 {
  width: 303px;
  height: 143px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_6 {
    width: 22.18vw;
    height: 10.47vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_6 {
    width: 53.33vw;
    height: 37.6vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust.illust_6 .illust_inner {
  background: url("../img/career/teacher-education-sc/index/illust-6.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust_1 {
  margin: auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_1 {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -81px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_1 {
    margin-top: -40px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_1 {
    bottom: -5.93vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust_2 {
  margin: auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_2 {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -71px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_2 {
    margin-top: -2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_2 {
    bottom: -5.2vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_3 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_3 {
    margin: auto;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_4 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_4 {
    margin: auto;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_5 {
    position: absolute;
    bottom: -62px;
    right: 44px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_5 {
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .illust_5 {
    bottom: -4.54vw;
    right: 3.22vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .illust_6 {
  margin: auto;
}
[data-pages="teacher-education-sc"] [data-support="index"] .box_heading {
  z-index: 100;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .box_heading {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .box_heading {
    padding-top: 60px;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .box_heading + .container_wide {
    margin-top: -30px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .box_heading + .container_wide {
    margin-top: -4.6vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-mv {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  background-image: url("../img/career/teacher-education-sc/index/mv_bg-stripe.png");
  background-position: center bottom 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv {
    padding-top: 2.67vw;
    padding-bottom: 20px;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-mv .wrap-mv_text {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .wrap-mv_text {
    top: 20px;
    background-color: #fcee21;
    border: 20px solid #fff;
    border-radius: 50%;
    width: 826px;
    height: 826px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .wrap-mv_text {
    top: 26%;
    width: 69%;
    height: 76%;
    margin: auto;
    z-index: 100;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-mv .mv_illust {
  transform: translateY(25px);
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-mv .image {
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .image {
    opacity: 0;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .image_1 {
    animation: change-img-anime_1 6s infinite;
  }
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .image_2 {
    animation: change-img-anime_2 6s infinite;
  }
@-moz-keyframes change-img-anime_1 {
    0% {
      opacity: 1;
      transform: rotate(0);
    }
    20% {
      opacity: 1;
      transform: rotate(0);
    }
    40% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    80% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    100% {
      opacity: 1;
      transform: rotate(-360deg);
    }
}
@-webkit-keyframes change-img-anime_1 {
    0% {
      opacity: 1;
      transform: rotate(0);
    }
    20% {
      opacity: 1;
      transform: rotate(0);
    }
    40% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    80% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    100% {
      opacity: 1;
      transform: rotate(-360deg);
    }
}
@-o-keyframes change-img-anime_1 {
    0% {
      opacity: 1;
      transform: rotate(0);
    }
    20% {
      opacity: 1;
      transform: rotate(0);
    }
    40% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    80% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    100% {
      opacity: 1;
      transform: rotate(-360deg);
    }
}
@keyframes change-img-anime_1 {
    0% {
      opacity: 1;
      transform: rotate(0);
    }
    20% {
      opacity: 1;
      transform: rotate(0);
    }
    40% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    80% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    100% {
      opacity: 1;
      transform: rotate(-360deg);
    }
}
@-moz-keyframes change-img-anime_2 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    20% {
      opacity: 0;
      transform: rotate(180deg);
    }
    40% {
      opacity: 1;
      transform: rotate(0);
    }
    80% {
      opacity: 1;
      transform: rotate(0);
    }
    100% {
      opacity: 0;
      transform: rotate(-180deg);
    }
}
@-webkit-keyframes change-img-anime_2 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    20% {
      opacity: 0;
      transform: rotate(180deg);
    }
    40% {
      opacity: 1;
      transform: rotate(0);
    }
    80% {
      opacity: 1;
      transform: rotate(0);
    }
    100% {
      opacity: 0;
      transform: rotate(-180deg);
    }
}
@-o-keyframes change-img-anime_2 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    20% {
      opacity: 0;
      transform: rotate(180deg);
    }
    40% {
      opacity: 1;
      transform: rotate(0);
    }
    80% {
      opacity: 1;
      transform: rotate(0);
    }
    100% {
      opacity: 0;
      transform: rotate(-180deg);
    }
}
@keyframes change-img-anime_2 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    20% {
      opacity: 0;
      transform: rotate(180deg);
    }
    40% {
      opacity: 1;
      transform: rotate(0);
    }
    80% {
      opacity: 1;
      transform: rotate(0);
    }
    100% {
      opacity: 0;
      transform: rotate(-180deg);
    }
}
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .image_1 {
    animation: fuwa-img-anime_1 6s infinite;
  }
  [data-pages="teacher-education-sc"] [data-support="index"] .section-mv .image_2 {
    top: 2.67vw;
    animation: fuwa-img-anime_2 6s infinite;
  }
@-moz-keyframes fuwa-img-anime_1 {
    0% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
}
@-webkit-keyframes fuwa-img-anime_1 {
    0% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
}
@-o-keyframes fuwa-img-anime_1 {
    0% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
}
@keyframes fuwa-img-anime_1 {
    0% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
}
@-moz-keyframes fuwa-img-anime_2 {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}
@-webkit-keyframes fuwa-img-anime_2 {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}
@-o-keyframes fuwa-img-anime_2 {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}
@keyframes fuwa-img-anime_2 {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .inner__info {
    display: flex;
    align-items: center;
    padding: 56px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .inner__info {
    padding-top: 32px;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .inner__info {
    padding: 4.1vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__info {
    margin-left: 96px;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__info .item__info:not(:first-of-type) {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__info .item__info:not(:first-of-type) {
    margin-top: 2.67vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .list__info .item__info .link__info {
  color: #333;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__info .item__info .link__info {
    display: flex;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__info .item__info .release_date__info {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__info .item__info .release_date__info {
    color: #b3b3b3;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-what .strong {
  margin: 0 6px;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__feature {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .c-text_point {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
}
[data-pages="teacher-education-sc"] [data-support="index"] .c-text_point .number {
  line-height: 1;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .c-text_point .number {
    font-size: 50px;
    margin-left: 6px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .c-text_point .number {
    font-size: 9.33vw;
    margin-left: 6px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .c-text_point .number {
    font-size: 3.66vw;
    margin-left: 0.44vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .contents__feature {
    margin-top: -26px;
    padding: 50px 30px;
    min-height: 334px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .contents__feature {
    margin-top: -5vw;
    padding: 8vw 5.33vw 9.33vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .contents__feature .text_1 {
  text-align: center;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .contents__feature .text_1 {
    padding: 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .contents__feature {
    padding: 3.66vw 2.2vw;
    margin-top: -1.9vw;
    min-height: 24.45vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .wrap_candidate {
  position: relative;
  margin-bottom: 28px;
}
[data-pages="teacher-education-sc"] [data-support="index"] .wrap_candidate .c-candidate_box {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .wrap_candidate .c-candidate_box {
    width: 260px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .wrap_candidate .c-candidate_box {
    width: 80%;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .wrap_candidate .c-candidate_box {
    width: 19.03vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .japan_map {
  text-align: right;
}
[data-pages="teacher-education-sc"] [data-support="index"] .list__candidate {
  margin-left: 0;
}
[data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:not([data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:first-of-type) {
  border-top: 1px dashed #000;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:not([data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:first-of-type) {
    padding-top: 36px;
    margin-top: 48px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:not([data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:first-of-type) {
    padding-top: 9.6vw;
    margin-top: 12.8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:not([data-pages="teacher-education-sc"] [data-support="index"] .item__candidate:first-of-type) {
    padding-top: 2.64vw;
    margin-top: 3.51vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .item__candidate .text {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__case {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__case .caseImg img {
    width: 30%;
    height: auto;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__case .item__case-content {
    padding: 27px 20px 0;
    margin-top: -20px;
    height: 100%;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__case .item__case-content {
    margin-top: -4.333vw;
    padding: 8vw 5.33vw 5.33vw 5.33vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .list__case .item__case-content p:first-of-type {
  min-height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .list__case .item__case-content {
    margin-top: -1.46vw;
    padding: 1.98vw 1.46vw 0vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-title3setPosition {
    top: -70px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-title3setPosition {
    top: -12.5vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-title3setPosition {
    top: -5.12vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-titleBig .font_big {
  line-height: 1;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-titleBig .font_big {
    font-size: 54px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-titleBig .font_big {
    font-size: 9.33vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-pillars .u-titleBig .font_big {
    font-size: 3.95vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .section-acquisition .font_big {
  line-height: 1;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-acquisition .font_big {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-acquisition .font_big {
    font-size: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="index"] .section-acquisition .font_big {
    font-size: 3.07vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="index"] .acquisition_status__pc table {
  width: 680px;
  table-layout: auto;
}
[data-pages="teacher-education-sc"] [data-support="index"] .acquisition_status__pc table th {
  vertical-align: middle;
  padding: 3px 20px;
}
[data-pages="teacher-education-sc"] [data-support="index"] .acquisition_status__pc table td {
  padding: 3px 20px;
}
[data-pages="teacher-education-sc"] [data-support="index"] .acquisition_status__pc table td:not(.bg_color-yellow) {
  background-color: #fffae6;
}
[data-pages="teacher-education-sc"] [data-support="index"] .acquisition_status__sp .faculty {
  margin-bottom: 3.7vw;
  padding: 1vw 5.333vw;
}
[data-pages="teacher-education-sc"] [data-support="index"] .total {
  padding: 8px 16px;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep-1 .illust {
    height: 162px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep-1 .illust {
    height: 42.4vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep-1 .illust {
    height: 11.86vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep-2 .illust {
    height: 178px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep-2 .illust {
    height: 46.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep-2 .illust {
    height: 13.03vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust {
  margin: 0 auto;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .illust {
    width: 210px;
  }
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust .illust_inner {
  width: 100%;
  height: 100%;
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust.illust_1 .illust_inner {
  background: url("../img/career/teacher-education-sc/contest/illust-1.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust.illust_2 .illust_inner {
  background: url("../img/career/teacher-education-sc/contest/illust-2.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust.illust_3 .illust_inner {
  background: url("../img/career/teacher-education-sc/contest/illust-3.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust.illust_4 .illust_inner {
  background: url("../img/career/teacher-education-sc/contest/illust-4.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust.illust_5 .illust_inner {
  background: url("../img/career/teacher-education-sc/contest/illust-5.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="contest"] .illust.illust_6 .illust_inner {
  background: url("../img/career/teacher-education-sc/contest/illust-6.png") no-repeat center/contain;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .illust {
    width: 15.37vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="contest"] .listStep {
  margin-left: 0;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep {
    display: flex;
    justify-content: space-between;
  }
}
[data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep {
    width: 30.675%;
    padding: 10px 20px 26px 20px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep {
    padding: 2.67vw 5.33vw 6.93vw 5.33vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep:not(:first-of-type) {
    margin-top: 16vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep:not(:first-of-type)::before {
  content: "";
  position: absolute;
  border-top: 4px solid #000;
  border-right: 4px solid #000;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep:not(:first-of-type)::before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    left: -32px;
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep:not(:first-of-type)::before {
    width: 6.67vw;
    height: 6.67vw;
    top: -16vw;
    left: 0;
    margin: auto;
    right: 0;
    transform: rotate(135deg);
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep:not(:first-of-type)::before {
    width: 1.32vw;
    height: 1.32vw;
    left: -2.34vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep .titleStep {
  position: absolute;
  text-align: center;
  top: -24px;
  left: 0;
  right: 0;
  letter-spacing: 0.05em;
  font-weight: 700;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep .titleStep {
    top: -15px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep .titleStep {
    top: -4vw;
    font-size: 8vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep .titleStep {
    top: -1.1vw;
    font-size: 2.2vw;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="contest"] .listStep .itemStep {
    padding: 0.73vw 1.46vw 1.9vw 1.46vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="recommendation"] .c-candidate_box {
    width: 284px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="recommendation"] .c-candidate_box {
    width: 90%;
  }
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust {
  margin: 0 auto 16px;
  width: 250px;
  height: 250px;
}
@media (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="volunteer"] .illust {
    width: 18.3vw;
    height: 18.3vw;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="volunteer"] .illust {
    width: 70.67vw;
    height: 70.67vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust .illust_inner {
  width: 100%;
  height: 100%;
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust.illust_1 .illust_inner {
  background: url("../img/career/teacher-education-sc/volunteer/illust-1.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust.illust_2 .illust_inner {
  background: url("../img/career/teacher-education-sc/volunteer/illust-2.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust.illust_3 .illust_inner {
  background: url("../img/career/teacher-education-sc/volunteer/illust-3.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust.illust_4 .illust_inner {
  background: url("../img/career/teacher-education-sc/volunteer/illust-4.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust.illust_5 .illust_inner {
  background: url("../img/career/teacher-education-sc/volunteer/illust-5.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="volunteer"] .illust.illust_6 .illust_inner {
  background: url("../img/career/teacher-education-sc/volunteer/illust-6.png") no-repeat center/contain;
}
[data-pages="teacher-education-sc"] [data-support="outline"] .listPprofile {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="outline"] .listPprofile .itemProfile {
    width: 25%;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="outline"] .listPprofile .itemProfile {
    width: 50%;
  }
}
[data-pages="teacher-education-sc"] [data-support="manabi-pj"] .container_wide_inner {
  padding-right: 1vw;
}
[data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust .illust_inner {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_1 {
    top: 10px;
    right: 80px;
    width: 145px;
    height: 103px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_1 {
    top: -8vw;
    right: 2.67vw;
    width: 19.33vw;
    height: 13.73vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_1 .illust_inner {
  background: url("../img/career/teacher-education-sc/manabi-pj/illust_1.png") no-repeat center/contain;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_1 {
    top: 0.73vw;
    right: 5.86vw;
    width: 10.61vw;
    height: 7.54vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_2 {
    top: 120px;
    left: 140px;
    width: 80px;
    height: 72px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_2 {
    top: -2.67vw;
    right: 5.33vw;
    width: 10.67vw;
    height: 9.6vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_2 .illust_inner {
  background: url("../img/career/teacher-education-sc/manabi-pj/illust_2.png") no-repeat center/contain;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_2 {
    top: 8.78vw;
    left: 10.25vw;
    width: 5.86vw;
    height: 5.27vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_3 {
    top: 100px;
    right: 100px;
    width: 154px;
    height: 176px;
  }
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_3 .illust_inner {
    background: url("../img/career/teacher-education-sc/manabi-pj/illust_3.png") no-repeat center/contain;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_3 {
    top: 7.32vw;
    right: 7.32vw;
    width: 11.27vw;
    height: 12.88vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_4 {
    top: 10px;
    left: 130px;
    width: 115px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_4 {
    top: -6.67vw;
    right: 1.33vw;
    width: 15.33vw;
    height: 16vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_4 .illust_inner {
  background: url("../img/career/teacher-education-sc/manabi-pj/illust_4.png") no-repeat center/contain;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_4 {
    top: 0.73vw;
    left: 9.52vw;
    width: 8.42vw;
    height: 8.78vw;
  }
}
@media (min-width: 768px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_5 {
    bottom: 10px;
    right: 100px;
    width: 144px;
    height: 138px;
  }
}
@media (max-width: 767px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_5 {
    top: -5.33vw;
    right: 1.33vw;
    width: 15.6vw;
    height: 14.93vw;
  }
}
[data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_5 .illust_inner {
  background: url("../img/career/teacher-education-sc/manabi-pj/illust_5.png") no-repeat center/contain;
}
@media (min-width: 768px) and (max-width: 1365px) {
  [data-pages="teacher-education-sc"] [data-support="manabi-pj"] .illust.illust_5 {
    bottom: 0.73vw;
    right: 7.32vw;
    width: 10.54vw;
    height: 10.1vw;
  }
}
@media (min-width: 768px) {
  [data-category="uv"] .page-header {
    background-image: url("../img/uv/header_bg.jpg");
    background-position: right top;
  }
}
@media (max-width: 767px) {
  [data-category="uv"] .page-header {
    background-image: url("../img/uv/header_bg@2x.jpg");
    background-position: right top;
  }
}
