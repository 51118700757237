[data-category="for-students"]
  .page-header
    +pc()
      background-image url('../img/for-students/header_bg.jpg')
    +sp()
      background-image url('../img/for-students/header_bg@2x.jpg')

  .-half-wrap
    +pc()
      margin-left 50%
  .-is-inline
    display inline-block

  // 盛岡大学聖陵同窓会だけに適用する場合はこちら
  [data-for-students="student-dp-uv"]
    //サンプルです
    .unique-wrap
      .sample-component
        +pc()
          margin-right 15px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(30)

  //科目ナンバリングについて
  //児童教育学科専門科目エリアのwidth調整
  .course-number_table
    tr
      &:nth-of-type(23)
        td
          &:nth-of-type(2)
            +pc()
              width 40%
            +sp()
              width 50%
