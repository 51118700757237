[data-category="about"]
  [data-about="sister_school"]
    // ここから書いてください
    // サンプルです
    +sp()
      padding-left 0
      padding-right 0
    .unique-wrap
      .sample-component
        +pc()
          margin-right 15px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(30)
    .nav-padding-sp
      +sp()
        padding-right vw($mgn-lv2)
        padding-left vw($mgn-lv2)

    .ui-template
      &.only-template
        padding 0

    .lr-padding
      +sp()
        padding-right vw(40)
        padding-left vw(40)

    .set-limit
      max-width 940px
      margin 0 auto
      +tab()
        max-width pcvw(940)
      +sp()
        max-width 100%

    p
      line-height 1.93

    .set-relative-camosun
      position relative
      // max-width pcvw(1186)
      margin 0 auto pcvw(70)
      height pcvw(443)
      +tab()
        height pcvw(443)
        margin-bottom pcvw(70)
      +sp()
        width vw(750)
        height vw(440)
        margin 0 auto vw(60)
      &:before
        position absolute
        bottom 0
        content ""
        display block
        height pcvw(220)
        width pcvw(595)
        background #cebad9
        +tab()
          height pcvw(220)
          width pcvw(755)
        +sp()
          height vw(220)
          width vw(480)

    .set-item-camosun-left
      position absolute
      z-index 1
      width pcvw(420)
      top 0
      left pcvw(60)
      +tab()
        width pcvw(420)
        left pcvw(220)
      +sp()
        left vw(40)
        width vw(370)

    .set-item-camosun-right
      position absolute
      width pcvw(830)
      top pcvw(93)
      right 0
      +tab()
        width pcvw(830)
        top pcvw(93)
      +sp()
        width vw(394)
        top vw(88)

    .set-relative-ningbo
      position relative
      // max-width 1186px
      margin 0 auto
      z-index 1
      height pcvw(443)
      +tab()
        // max-width pcvw(1186)
        height pcvw(443)
      +sp()
        height vw(440)
        margin 0 auto
      &:before
        position absolute
        bottom 0
        content ""
        display block
        height pcvw(220)
        width pcvw(595)
        background #cebad9
        +tab()
          height pcvw(220)
          width pcvw(755)
        +sp()
          bottom vw(-60)
          height vw(220)
          width vw(480)

    .set-item-ningbo-left
      position absolute
      z-index 1
      width pcvw(360)
      top 0
      left pcvw(60)
      +tab()
        width pcvw(360)
        left pcvw(220)
      +sp()
        left vw(60)
        width vw(420)

    .set-item-ningbo-right
      position absolute
      top pcvw(92)
      right 0
      width pcvw(830)
      +tab()
        top pcvw(92)
        width pcvw(830)
      +sp()
        top vw(150)
        width vw(394)

    .sister-margin-top
      +pc()
        margin-top pcvw(50)
      +sp()
        margin-top vw(105)
