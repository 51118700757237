/**
* バンくず
**/
.breadcrumbs
  &-container
    display flex
    align-items center
    background-color #f5f6f8
    +pc()
      height 40px
      +pcLiquid()
        height pcvw(@height)
    +sp()
      height vw(60)
      overflow-x scroll
      -ms-overflow-style none
      scrollbar-width none
      &::-webkit-scrollbar
        display none
  &-inner
    display flex
    align-items center
    +pc()
      padding 0 40px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(0 40)
  &-list
    font-family $NotoSans
    color $thema-text-black
    line-height 1.5
    display flex
    align-items center
    +pc()
      font-size 1.2rem
      +pcLiquid()
        font-size pcvw(12)
    +sp()
      font-size vw(24)
    &:first-of-type
      // ホームアイコン
      a
        background-repeat no-repeat
        background-size contain
        background-image url('../img/common/icon_home.svg')
        +pc()
          width 17px
          height 16px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(26)
          height vw(25)
    & + &
      span
      a
        // overflow hidden
        // text-overflow ellipsis
        // white-space nowrap
        white-space nowrap
        +pc()
          max-width 40em
      &::before
        content ''
        display inline-block
        background-size contain
        background-repeat no-repeat
        background-image url('../img/common/breadcrumbs_arrow.png')
        +pc()
          width 8px
          height 12px
          margin 0 15px -1px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
            margin pcvw(@margin)
        +sp()
          width vw(13)
          height vw(23)
          margin vw(0 30 -2)
      // テキストリンク
      a
        border-bottom-color #d7d7d9
        border-bottom-style solid
        +pc()
          border-bottom-width 2px
          +pcLiquid()
            border-bottom-width pcvw(@border-bottom-width)
        +sp()
          border-bottom-width vw(2)
    // ホームアイコン・テキストリンク
    a
      display inline-block
      color $thema-text-black
      +pc()
        hover-opacity()



/**
* ヘッダー
**/
.page-header
  display flex
  align-items center
  justify-content center
  background-repeat no-repeat
  background-size cover
  background-position center center
  +pc()
    height 200px
    +pcLiquid()
      height pcvw(@height)
  +sp()
    height vw(250)
.page-heading
  font-family $NotoSans
  font-weight 400
  color $thema-text-purple
  letter-spacing ls(100)
  +pc()
    font-size 4rem
    +pcLiquid()
      font-size pcvw(40)
  +sp()
    font-size vw(50)
  &-en
    font-family $Roboto
    color $thema-text-purple
    letter-spacing ls(100)
    display block
    text-align center
    +pc()
      font-size 1.6rem
      margin-top 20px
      +pcLiquid()
        font-size pcvw(16)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(26)
      margin-top vw(25)

/**
* 見つかりませんでした
**/
.notFind
  font-family $NotoSans
  font-weight 500
  letter-spacing ls(50)
  text-align center
  +pc()
    font-size 1.4rem
    line-height round(26 / 14, 2)
    +pcLiquid()
      font-size pcvw(14)
  +sp()
    font-size vw(28)
    line-height 2
