$thisPage = "history"
$bgPath = '../img/about/' + $thisPage + '/'
$color-aboutBasic = #601986

[data-pages={'"'+$thisPage+'"'}]
  &.pages
    overflow visible

  .footer
    border none

  .breadcrumbs-container
    position relative

  .page-header
    position relative
    +pc()
      background-image url($bgPath + 'header_bg-pc.jpg')
    +sp()
      background-image url($bgPath + 'header_bg-sp.jpg')

  .ui-template
    background none
    position relative
    +pc()
      padding-top 0

  .container-history
    background-color rgba(255, 255, 255, 0.9)
    +pc()
      padding 56px 76px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding-top vw(72)
      padding-right vw($mgn-lv2)
      padding-left vw($mgn-lv2)

  .heading.wrap-box
    max-width 748px
    width 100%
    +pc()
      display flex
      justify-content space-between

    .box-text

      .heading
        font-feature-settings "palt"
        line-height 1.4
        letter-spacing 0.1em
        +pc()
          font-size 30px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(42)

      .text
        +pc()
          margin-top 45px
          line-height 1.96
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(30)
          line-height: 1.9

    .box-image
      text-align center

      img
        +pc()
          margin-top 8px
          width 194px
          +pcLiquid()
            width pcvw(@width)
            height auto
        +sp()
          margin-top vw(48)
          width 63%
          height auto

      .figcaption
        font-weight 700
        letter-spacing 0.1em
        +pc()
          margin-top 24px
          font-size 15px
          +pcLiquid()
            margin-top pcvw(@margin-top)
            font-size pcvw(@font-size)
        +sp()
          margin-top vw(38)
          font-size vw(28)

  .list-history
    max-width 770px
    +pc()
      margin 100px auto 0
      +pcLiquid()
        margin pcvw(@margin)
    +sp()
      margin vw(114 auto 0)

  .item-history
    position relative
    width 100%
    height 100%
    border-left 1px solid $color-aboutBasic
    +pc()
      padding 20px 0 67px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(36 0 70)

    &::before
      position absolute
      content ""
      background-color $color-aboutBasic
      border-radius 50%
      +pc()
        top -3px
        left -6px
        width 11px
        height 11px
        +pcLiquid()
          top pcvw(@top)
          left pcvw(@left)
          width pcvw(@width)
          height pcvw(@height)
      +sp()
        top vw(-7)
        left vw(-12)
        width vw(22)
        height vw(22)

    &:last-of-type
      border-left none

    .age
      position relative
      font-family $Roboto
      letter-spacing 0.1em
      line-height 1
      color $color-aboutBasic
      +pc()
        top -35px
        padding 0 0 0 42px
        font-size 36px
        +pcLiquid()
          top pcvw(@top)
          padding pcvw(@padding)
          font-size pcvw(@font-size)
      +sp()
        top vw(-55)
        padding vw(0 0 0 40)
        font-size vw(48)

      &::before
        position absolute
        content ""
        width 4.4%
        height 1px
        top 0
        bottom 0
        left 0
        margin auto
        background-color $color-aboutBasic
        +sp()
          width 6%

      &::after
        position absolute
        content ""
        width 83%
        height 1px
        top 0
        bottom 0
        right 0
        margin auto
        background-color $color-aboutBasic
        +sp()
          width 71.5%

    .wrap-box
      +pc()
        display flex
        justify-content space-between
        padding 0 31px 0 44px
        margin-top -15px
        +pcLiquid()
          padding pcvw(@padding)
          margin-top pcvw(@margin-top)
      +sp()
        padding vw(0 8 0 48)
        margin-top vw(-30)

    .box-text
      max-width 472px

      .date
        line-height 1
        padding 0 0 1px 6px
        border-left 4px solid $color-aboutBasic
        +pc()
          margin-bottom 11px
          +pcLiquid()
            margin-bottom pcvw(@margin-bottom)
        +sp()
          margin-bottom vw(22)

      .date:nth-of-type(2),
      .date:nth-of-type(3)
        +pc()
          margin-top 32px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(50)

    .box-image
      +sp()
        margin-top vw(56)

      img
        +pc()
          width 200px
          +pcLiquid()
            width pcvw(@width)
            height auto
        +sp()
          width 91%
          height auto

    .box-image.box-image--2col
      +pc()
        display flex

      .box-image--2col-item:last-of-type
        +pc()
          margin-left 30px
          +pcLiquid()
            margin-left pcvw(@margin-left)
        +sp()
          margin-top vw(40)

  .item-history--image
    +pc()
      padding 28px 0 62px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(42 0 66)

    &::before
      +pc()
        top 6px
        +pcLiquid()
          top pcvw(@top)
      +sp()
        top vw(20)

    .wrap-box
      +pc()
        margin-top -30px
        +pcLiquid()
          margin-top pcvw(@margin-top)

    .age
      +pc()
        top -46px
        font-size 60px
        +pcLiquid()
          top pcvw(@top)
          font-size pcvw(@font-size)
      +sp()
        top vw(-52)
        font-size vw(80)

      &::after
        +pc()
          width 75.4%
        +sp()
          width 56%

  .container-history_bg
    position fixed
    height calc(100vh - 40px)
    width 3558px
    background-image url($bgPath + 'bg-history.jpg')
    background-size cover
    background-position top left
    +pc()
      left 180px
      top 40px
      +pcLiquid()
        left 0
        top pcvw(@top)
    +sp()
      left 0
      top vw(120)
