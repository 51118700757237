// ページネーション
.pagenation
  +pc()
    margin-top 60px
    +pcLiquid()
      margin-top pcvw(@margin-top)
  +sp()
    margin-top vw(80)
  &-container
    display flex
    justify-content center
  .page-number
    font-family $Roboto
    color $thema-bg-purple
    letter-spacing ls(100)
    display flex
    align-items center
    justify-content center
    background-color $white
    +pc()
      font-size 2rem
      width 50px
      height @width
      transition background-color .3s, color .3s
      margin 0 10px
      &:hover
        color $white
        background-color $thema-text-purple
      +pcLiquid()
        font-size pcvw(20)
        width pcvw(@width)
        height @width
        margin pcvw(@margin)
    +sp()
      font-size vw(36)
      width vw(90)
      height @width
      margin vw(0 20)
    // MTで生成された時、構造が変わったのでMT用に
    > a, > span
      display flex
      align-items center
      justify-content center
      width 100%
      height @width
    &.current
      color $white
      background-color $thema-text-purple
      // MTで生成された時、構造が変わったのでMT用に
      > a, > span
        color $white
        background-color $thema-text-purple
  .prev-btn
  .next-btn
    background-repeat no-repeat
    background-position center center
    background-image url('../img/common/pagenation_arrow.png')
    +pc()
      width 50px
      height @width
      background-size 19px 17px
      margin 0 10px
      hover-opacity()
      +pcLiquid()
        width pcvw(@width)
        height @width
        background-size pcvw(@background-size)
        margin pcvw(@margin)
    +sp()
      width vw(90)
      height @width
      background-size vw(30 27)
      margin vw(0 20)
  .prev-btn
    transform scaleX(-1)
