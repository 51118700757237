$commonImgPath = '../img/career/'
$careerLightBlue = #1A86B9

[data-category="career"]
  &.pages
    overflow unset // .pagenav の position sticky 対策

  .c-pagenav--open-button
    color $color-bungakubu
    &::before
      svgArrow($color-bungakubu)

  .page-header
    +pc()
      background-image url($commonImgPath + 'header_bg-pc.jpg')
    +sp()
      background-image url($commonImgPath + 'header_bg-sp.jpg')

  .color_purple
    color $thema-text-purple

  .color_green
    color $lightGreen

  .color_blue
    color $careerLightBlue

  .flexPC
    .box-img
      +pc()
        width 47.874%
    .box-text
      +pc()
        width 47.874%

  .list-where_work
    .item-where_work
      +pc()
        display flex
    .heading4
      display inline-block
      +pc()
        width 18%
        max-width 164px
        line-height 45px
        +pcLiquid()
          line-height pcvw(@line-height)
      +sp()
        margin-bottom vw(30)
    .buttonlink
      +pc()
        width 34.1%
        max-width 320px
      +sp()
        padding 4vw 5.33vw 4vw 18vw
      &:nth-of-type(1)
        +pc()
          margin-right $mgn-lv2
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(40)
        .inner
          background-image url($commonImgPath + 'icon-course.svg')
          background-repeat no-repeat
          +pc()
            background-size 20px auto
            background-position center left
            +pcLiquid()
              background-size pcvw(@background-size)
          +sp()
            background-position center left vw(24)
      &:nth-of-type(2)
        .inner
          background-image url($commonImgPath + 'icon-graduates.svg')
          background-repeat no-repeat
          +pc()
            background-size 31px auto
            background-position center left
            +pcLiquid()
              background-size pcvw(@background-size)
      .inner
        .text
          font-family $NotoSans
          +pc()
            padding-right 0
            text-align center
          +sp()
            padding-left vw(80)
            text-align left
      &.color_green
        background $lightGreen
        border 1px solid $lightGreen
        +pc()
          &:hover
            background $white
            border 1px solid $lightGreen
            .inner
              .text
                color $lightGreen
                &::before
                  background-color $lightGreen
                &::after
                  border-color transparent transparent transparent $lightGreen
      &.color_blue
        background $careerLightBlue
        border 1px solid $careerLightBlue
        +pc()
          &:hover
            background $white
            border 1px solid $careerLightBlue
            .inner
              .text
                color $careerLightBlue
                &::before
                  background-color $careerLightBlue
                &::after
                  border-color transparent transparent transparent $careerLightBlue

  .list-where_work
    .item-where_work
      .buttonlink
        &.color_purple
          &:nth-of-type(1)
            &:hover
              .inner
                background-image url($commonImgPath + 'icon-course-purple.svg')
          &:nth-of-type(2)
            &:hover
              .inner
                background-image url($commonImgPath + 'icon-graduates-purple.svg')
        &.color_green
          &:nth-of-type(1)
            &:hover
              .inner
                background-image url($commonImgPath + 'icon-course-green.svg')
          &:nth-of-type(2)
            &:hover
              .inner
                background-image url($commonImgPath + 'icon-graduates-green.svg')
        &.color_blue
          &:nth-of-type(1)
            &:hover
              .inner
                background-image url($commonImgPath + 'icon-course-blue.svg')
          &:nth-of-type(2)
            &:hover
              .inner
                background-image url($commonImgPath + 'icon-graduates-blue.svg')


  [data-career="employment-uv"],[data-career="employment-jc"]
    .box-img
      img
        +sp()
          width 100%
          height auto

  [data-career="career-index"]
    .heading3
      color $thema-text-purple
      padding-bottom 12px
      border-bottom 1px solid #ccc

    .link-content
      text-align right
      .link-container
        cmn-arrow-link($thema-text-purple)

    .box-img
      +pc()
        overflow hidden
        &:hover
          img
            transform scale(1.1)
          +.box-text
            .link-container::after
              transform translate3d(10px, 0, 0)
      img
        +pc()
          transition transform .5s
        +sp()
          width 100%
          height auto

