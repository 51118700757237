.modal
  &-overlay
    z-index 9990
    display none
    position fixed
    top 0
    left 0
    width 100%
    height 120%
    background-color rgba(0, 0, 0, .6)
  &-content
    width 80%
    max-width 960px
    margin 0
    padding 0
    position fixed
    display none
    z-index 9999
  &-inner
    position relative
    width 100%
    padding-top 56.25%
    overflow hidden

#ytPlayer
  position absolute
  top 0
  right 0
  width 100%
  height 100%

.modal-close-btn
  background-color transparent
  background-size contain
  background-repeat no-repeat
  background-image url('../img/common/close.svg')
  border 0
  padding 0
  position absolute
  cursor pointer
  color $white
  +pc()
    width 50px
    height @width
    top -70px
    right -70px
  +sp()
    width vw(30)
    height @width
    top vw(-40)
    right vw(-20)
  &:focus
    outline 0
  img
    +pc()
      width 50px
    +sp()
      width vw(60)

#ytPlayer
  position absolute
  top 0
  right 0
  width 100%
  height 100%

