[data-pages="founding-spirit"]
  .page-header
    +pc()
      background-image url('../img/about/founding-spirit/header_bg.jpg')
    +sp()
      background-image url('../img/about/founding-spirit/header_bg@2x.jpg')
  [data-about="founding-spirit"]
    .box-image
      text-align center

    [class^="heading"]
      &.withBorder
        // &:before
        //   content none
        &:after
          background #5f1985
          left 50%
          transform translateX(-50%)
          +pc()
            width 50px
            height 2px
            +pcLiquid()
              width pcvw(@width)
              height pcvw(@height)
          +sp()
            width vw(50)
            height vw(2)

    .base.paragraph:nth-of-type(1)
      text-align left

    .per30
      +pc()
        width 30%

    .per65
      +pc()
        width 65%

    .flex
      justify-content space-between
      +sp()
        flex-direction column

    // section 1
    .sec-1
      +sp()
        padding-bottom vw(100)
    .content-latest-container
      margin-bottom 0
      +pc()
        justify-content space-between
      +sp()
        display flex
        flex-direction column
    .information-contnt-block
      +pc()
        margin-left 0
      &:first-of-type
      &:last-of-type
        +pc()
          width percent(300, 858)
          margin-top 60px
          +pcLiquid()
            margin-top pcvw(@margin-top)
      &:first-of-type
        +sp()
          margin-top vw(60)
      &:last-of-type
        +sp()
          margin-top vw(40)
      &:nth-of-type(2)
        background-color $thema-bg-purple
        +pc()
          width percent(182, 858)
          padding 70px 0
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          width vw(200)
          order -1
          padding vw(60) 0
          margin 0 auto
        .heading2
          writing-mode vertical-rl
          color $white
          margin 0 auto

    // section 2
    .sec-2
      background-color $thema-bg-lightGrey
      +pc()
        margin-top -50px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      +sp()
        width 'calc(100% + %s)' % vw($mgn-lv2 * 2)
        margin-left vw($mgn-lv2 * -1)
        padding vw(120 $mgn-lv2)
      .heading2
        text-align center
        +sp()
          font-size vw(42)
          margin-bottom vw($mgn-lv4)
        &::before
          display none
      .block-department
        +sp()
          padding-right 0
          padding-left 0
      .paragraph.margin-lv1
        +sp()
          margin-bottom vw(50)

    // section 3
    .sec-3
      .heading2
        text-align center
        +sp()
          font-size vw(42)
          margin-bottom vw($mgn-lv4)
        &::before
          display none
      .heading3
        +pc()
          font-size 15px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          margin-bottom vw(50)
        &.withBlock:before
          +pc()
            height 14px
            margin-top 0
            +pcLiquid()
              height pcvw(@height)
        & + .paragraph
          +sp()
            margin-bottom vw(80)
      .paragraph:last-of-type
        +sp()
          margin-bottom 0
    .logo-universe img
      +pc()
        width 310px
        +pcLiquid()
          width pcvw(@width)
      +sp()
        width vw(425)
    .logo-college img
      +pc()
        width 318px
        +pcLiquid()
          width pcvw(@width)
      +sp()
        width vw(445)
