@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
$imgPath = '../img/career/teacher-education-sc/'

$colorBlue = #29ABE2
$colorYellow = #FCEE21

// aspect-ratio mixin
// 下層レイヤーに .u-ratioContents を定義してください。
aspect-ratio($width, $height)
  position relative
  height: auto
  &:before
    display: block
    content ""
    width 100%
    padding-top ($height / $width) * 100%
  & > .u-ratioContents
    display block
    position absolute

[data-pages="teacher-education-sc"]
  //@import "swiper/css/swiper.min.css"
  .main
    background-color #FFFAE6

  .ui-template
    // padding-top 0
    padding-left 0
    padding-right 0

    ol
      margin-left 0

  // page-nav
  .l-pagenav
    position sticky
    top 40px

  .c-pagenav
    font-family $NotoSans
    background #fcf880
    +pc()
      font-weight bold
    +sp()
      padding-top vw(60)
      padding-right vw(40)
      padding-left vw(40)
      background #fffae6

    .c-pagenav--open-button::before
      svgArrow(black)

  .container
    background transparent

  .c-pagenav__text
    color black

  .c-pagenav__wrap
    background #fcf880
    max-width 1186px
    margin-right auto
    margin-left auto
    +pc()
      padding-right 7rem
      padding-left 7rem
      +pcLiquid()
        padding-right pcvw(70)
        padding-left pcvw(70)
    +sp()
      background #fcf880

  .c-pagenav__wrap__list
    +sp()
      background #fcf880
      width 100%
      margin 0 auto
      .c-pagenav__wrap__list
        border-top 1px solid #666
      &::before
        svgArrow(black)

    &::before
      +pc()
        border-right 1px solid #666
        border-left 1px solid #666

  .footer
    border-top-width 0

  .page-header
    display none

  .toTop
    display none

  // Font
  .font_34
    line-height 1.7
    letter-spacing .05em
    font-weight 700
    +pc()
      font-size 34px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(42)

  .font_30
    line-height 1.7
    letter-spacing .05em
    font-weight 700
    +pc()
      font-size 30px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(42)

  .font_24
    line-height 1.7
    letter-spacing .05em
    font-weight 700
    +pc()
      font-size 24px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(38)

  .font_20
    line-height 1.7
    letter-spacing .05em
    +pc()
      font-size 20px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(34)

  .font_18
    line-height 1.7
    letter-spacing .05em
    +pc()
      font-size 18px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(34)

  .font_17
    line-height 1.7
    letter-spacing .05em
    +pc()
      font-size 17px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(32)

  .font_16
    line-height 1.7
    letter-spacing .05em
    +pc()
      font-size 16px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(32)

  .font_15
    line-height 1.933
    letter-spacing .05em
    +pc()
      font-size 15px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(28)

  .font_14
    line-height 2.071
    letter-spacing .05em
    +pc()
      font-size 14px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(28)

  .font_13
    line-height 1.846
    letter-spacing .05em
    +pc()
      font-size 13px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(26)

  .font_roboto
    font-family: 'Roboto', sans-serif;

  .font_italic
    font-style italic

  .font_nowrap
    white-space nowrap

  // align
  .center
    text-align center
  .center_pc
    +pc()
      text-align center
  .center_sp
    +sp()
      text-align center
  .left
    text-align left!important

  // container
  .container
    background-color #FFFAE6
    width 100%
    margin-right auto
    margin-left auto
    font-family $NotoSans
    font-weight 500

  // page-nav container
  .container_limid
    width 100%
    +pc()
      background #fcf880

    .container_limitedChild
      max-width 1186px
      margin-right auto
      margin-left auto

  .container_wide
    max-width 950px
    margin-right auto
    margin-left auto
    +pc()
      width 100%
    +pcLiquid()
      width 90%
    +sp()
      width 90%

  .container_wide_inner
    +sp()
      padding-left 5.333vw
      padding-right 5.333vw

  .container_mid
    max-width 820px
    margin-right auto
    margin-left auto
    +pcLiquid()
      width 90%

  .container_sml
    +pc()
      max-width 390px
      width 100%
    +sp()
      width 100%
      margin-right auto
      margin-left auto

  // Decoration
  .bg_skew_border
    background-color #ccc
    background-image repeating-linear-gradient(-45deg, #FFFAE6, #FFFAE6 7px, transparent 0, transparent 14px)
    border-radius 10px
    transform translate(10px, 10px)

  .bg_skew_border_inner
    border-radius 10px
    transform translate(-10px, -10px)

  .bg_skew_border_padding
    +pc()
      padding-right 60px
      padding-left 60px
      +pcLiquid()
        padding-right pcvw(60)
        padding-left pcvw(60)

  .bg_skew_border_padding_bottom
    +pc()
      padding-bottom 60px
      +pcLiquid()
        padding-bottom pcvw(60)

  .strong
    border-radius 5px
    padding 1px 10px
    display inline-block

  // Color
  .bg_color-yellow
    background-color $colorYellow

  .bg_color-white
    background-color #fff

  .bg_color-gray
    background-color #f2f2f2

  .color_black
    color black

  .color_white
    color #fff

  .color_blue
    color $colorBlue

  .pd_60
    +pc()
      padding-bottom 60px
      +pcLiquid()
        padding-bottom pcvw(60)

  .pd_20
    +pc()
      padding 20px
      +pcLiquid()
        padding pcvw(20)
    +sp()
      padding vw(20)

  .pd_20-noTop
    +pc()
      padding 0 20px 20px 20px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(0 20 20 20)

  .pdt_120
    +pc()
      padding-top 120px
      +pcLiquid()
        padding-top pcvw(@padding-top)
    +sp()
      padding-top vw(120)


  // margin
  .mgt_160
    +pc()
      margin-top 160px
      +pcLiquid()
        margin-top pcvw(@margin-top)
    +sp()
      margin-top vw(240)

  .mgt_120
    +pc()
      margin-top 120px
      +pcLiquid()
        margin-top pcvw(@margin-top)
    +sp()
      margin-top vw(120)

  .mgt_100
    +pc()
      margin-top 100px
      +pcLiquid()
        margin-top pcvw(@margin-top)
    +sp()
      margin-top vw(100)

  .mgt_14
    +pc()
      margin-top 14px
      +pcLiquid()
        margin-top pcvw(14)
    +sp()
      margin-top vw(14)

  .mgt_8
    +pc()
      margin-top 8px
      +pcLiquid()
        margin-top pcvw(8)
    +sp()
      margin-top vw(8)

  .mgt_4
    +pc()
      margin-top 4px
      +pcLiquid()
        margin-top pcvw(4)
    +sp()
      margin-top vw(4)

  .pd_min
    +pc()
      padding 6px
      +pcLiquid()
        padding pcvw(6)
    +sp()
      padding vw(6)

  // border-radius
  .box_radius
    border-radius 10px

  .u-border
    border-bottom 1px solid #ccc

  // position
  .pr
    position relative
  .pa
    position absolute

  // forSP overScrollSP
  .overScrollSP
    +sp()
      overflow-x scroll

  // forSP 画像
  .spWidth
    width 100%
    height auto

  // Component
  .c-candidate_box
    display inline-block
    +pc()
      padding 32px 32px 56px 32px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(32 32 80 32)

    .text
      line-height 1.3
      margin-bottom 12px

    .number
      +pc()
        font-size 56px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(80)

  .c-overlap
    text-align center
    .number
      font-style italic
      color #fff
      line-height 1
      +pc()
        font-size 86px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(120)
    .head
      +pc()
        margin-top -42px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      +sp()
        margin-top vw(-60)

  .definitionList
    border-bottom 1px solid #666
    padding 0 5.333vw 4.5vw
    margin-bottom 3.4vw

  .c-link
    padding 1px 0

    // arrowアイコン付きクラス
    .link_arrow
      display inline-block
      color black
      &::after
        content ''
        display inline-block
        transition all .2s
        background-image url($imgPath + 'common/icon-arrow_black.svg')
        background-position center right
        background-repeat no-repeat
        +pc()
          width 18px
          height 18px
          padding-left 8px
          background-size contatain
          vertical-align -3px
          +pcLiquid()
            width pcvw(18)
            height pcvw(18)
            background-size contain
            padding-left pcvw(8)
            vertical-align pcvw(-3)
        +sp()
          width vw(28)
          height vw(28)
          background-size contain
          padding-left vw(10)
          vertical-align vw(-3)
      &:hover
        color $colorBlue
        &::after
          background-image url($imgPath + 'common/icon-arrow_blue.svg')
          background-repeat no-repeat

    // docsアイコン付きクラス
    .link_word
      display inline-block
      color $colorBlue
      &::after
        content ''
        display inline-block
        transition all .2s
        background-image url($imgPath + 'common/icon-word.png')
        background-position center right
        background-repeat no-repeat
        +pc()
          width 23px
          height 18px
          padding-left 8px
          background-size contain
          vertical-align -3px
          +pcLiquid()
            width pcvw(23)
            height pcvw(18)
            background-size contain
            padding-left pcvw(8)
            vertical-align pcvw(-3)
        +sp()
          width vw(51)
          height vw(42)
          background-size contain
          padding-left vw(10)
          vertical-align vw(-5)
      &:hover
        opacity 0.6

  .c-buttonlink
    display inline-block
    text-align center
    color #fff
    border 1px solid $colorBlue
    background-color $colorBlue
    background-image url($imgPath + 'common/icon-arrow-white.svg')
    background-repeat no-repeat
    background-size 18px
    transition all .2s
    +pc()
      width 100%
      max-width 370px
      border-radius 28px
      padding 13px
      background-position center right 40px
      &:hover
        color $colorBlue
        background-color #fff
        background-image url($imgPath + 'common/icon-arrow_blue.svg')
    +sp()
      width 90%
      padding vw(20)
      border-radius 8vw
      background-position center right 4vw

  .icon-marker
    position relative
    &::after
      position absolute
      left 50%
      transform translateX(-50%)
      content ""
      background url($imgPath + 'common/icon-marker.png') no-repeat center / contain
      +pc()
        width 200px
        height 28px
        bottom -22px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
          bottom pcvw(@bottom)
      +sp()
        bottom -5vw
        width 90%
        height 8vw

  .c-supportTable
    width 100%
    margin-left auto
    margin-right auto
    border-collapse collapse
    table-layout fixed
    +sp()
      width 830px
    .widthMin
      width 120px
    th
      font-weight 700
      border 1px solid #666
      vertical-align middle
      padding 8px
      &.center
        padding 0
    td
      border 1px solid #666
      vertical-align middle
      text-align center
      &:not(.bg_color-yellow)
        background-color #FFFAE6

    &.tableColor_blue
      table-layout auto
      .tablehead
        color #fff
        background-color #4f81bd
      .tableata
        padding 8px
        background-color #fff
      .left
        text-align left

    &.tableColor_purple
      table-layout auto
      .tableheading
        color #fff
        background-color #B2A1C7
      .tablehead
        padding 8px
        color #333
        background-color #E5DEFC
      .tableata
        padding 8px
        background-color #fff
      .left
        text-align left

  // Contact
  .list__contact
    +pc()
      display flex
      justify-content center
      padding 0 20px
    +sp()
      padding-bottom 40px

  .item__contact
    text-align center
    box-sizing content-box
    +pc()
      width 320px
    &:first-of-type
      +pc()
        padding-right 60px
        border-right 1px dashed #000
      +sp()
        padding-bottom vw(60)
        border-bottom 1px dashed #000
    &:last-of-type
      +pc()
        padding-left 60px
      +sp()
        padding-top vw(60)

    .hukidashi
      position relative
      line-height 1.6
      margin-bottom 28px
      border-radius 10px
      padding 9px 14px 12px
      &::after
        content ""
        position absolute
        bottom -8px
        left 50%
        width 20px
        height 8px
        transform translateX(-50%)
        background-image url($imgPath + 'common/icon-hukidashi.svg')
        background-position bottom center
        background-repeat no-repeat
        background-size contain
      +sp()
        margin-bottom vw(2)

    .contact
      .tel
        background-image url($imgPath + 'common/icon-tell.svg')

      .mail
        background-image url($imgPath + 'common/icon-mail.svg')

      .contact_link
        color #000
        letter-spacing normal
        background-position center left
        background-repeat no-repeat
        +pc()
          padding-left 30px
          background-size 20px
          +pcLiquid()
            padding-left pcvw(@padding-left)
            background-size pcvw(@background-size)
        +sp()
          padding-left vw(60)
          background-size vw(40)

  // Page Footer
  .page_top
    +sp()
      img
        width vw(220)
        height auto

  .page_nav
    padding 96px 0 56px
    +pc()
      margin-top -54px
    +sp()
      margin-top -14vw

  .item__pagenav
    +pc()
      margin 4px 8px
      display inline-block
      text-align center
    +sp()
      display block
      margin 4px 0

  .link__pagenav
    color #000
    padding 2px 6px
    &:hover
      color #fff
      background-color $colorBlue

  // 下層header
  .support_header
    background-color $colorYellow
    background-position center
    background-repeat no-repeat
    background-size cover
    margin-bottom 13px

    +pc()
      background-image url($imgPath + 'common/header_stripe.png')
      height 19rem
      +pcLiquid()
        height pcvw(190)
    +sp()
      background-image url($imgPath + 'common/header_stripe_sp.png')
      height vw(250)

    .support_header-inner
      height 100%
      display flex
      align-items center
      position relative

      +pc()
        padding-left 12.3rem
        +pcLiquid()
          padding-left pcvw(123)
      +sp()
        padding-left vw(40)

      .support_header-inner_illust
        position absolute
        background-image url($imgPath + 'common/header-illust.png')
        background-size 100% auto

        +pc()
          width 25.4rem
          height 16.9rem
          right 63px
          bottom -13px
          +pcLiquid()
            width pcvw(254)
            height pcvw(169)
            right pcvw(63px)
            bottom pcvw(-13px)
        +sp()
          width vw(196)
          height vw(130)
          right pcvw(11px)
          bottom pcvw(-20px)

    .support_page_title
      display block
      +pc()
        font-size 4rem
        +pcLiquid()
          font-size pcvw(40)
      +sp()
        font-size vw(50)
        line-height round(65 / 50, 2)

    .support_page_title-en
      margin-top 12px
      display block
      font-family $Roboto
      font-size 18px
      font-weight 400
      +pc()
        font-size 1.8rem
        +pcLiquid()
          font-size pcvw(18)
      +sp()
        font-size vw(26)
        line-height round(34 / 26, 2)

  // debug
  .js-pre
    margin 16px 16px 40px
    border-radius 5px
    padding 16px
    background black
    color white
    font-size 1.3rem
    line-height 1.8461538462
    overflow auto

  // title
  .u-titleBig
    letter-spacing 0.05em
    +pc()
      font-size 3.4rem
      line-height round(42 / 34, 2)
      +pcLiquid()
        font-size pcvw(34)
        line-height round(42 / 34, 2)
    +sp()
      font-size vw(42)
      line-height round(64 / 42, 2)

  .u-title
    letter-spacing 0.05em
    +pc()
      font-size 3rem
      line-height round(42 / 30, 2)
      +pcLiquid()
        font-size pcvw(30)
        line-height round(42 / 30, 2)
    +sp()
      font-size vw(42)
      line-height round(64 / 42, 2)

  .u-titleS
    letter-spacing 0.05em
    +pc()
      font-size 1.8rem
      line-height round(29 / 18, 2)
      +pcLiquid()
        font-size pcvw(18)
        line-height round(29 / 18, 2)
    +sp()
      font-size vw(32)
      line-height round(52 / 32, 2)

  .u-titleS_en
    +pc()
      font-size 3rem
      +pcLiquid()
        font-size pcvw(30)
    +sp()
      font-size vw(60)

  .u-title_border
    position relative
    &::before
      content ''
      position absolute
      left 0
      width 6px
      height 100%
      display block
      background-color $colorYellow
    +pc()
      font-size 24px
      padding-left 15px
      line-height round(33.6 / 24, 2)
      +pcLiquid()
        font-size pcvw(24)
        padding-left pcvw(15)
    +sp()
      font-size vw(38)
      padding-left vw(24)
      line-height round(52 / 38, 2)

  .u-titleRotate
    display inline-block
    border-top 3px solid black
    border-bottom 3px solid black
    margin auto
    transform rotate(-5deg)
    line-height round(24 / 16, 2)
    +pc()
      font-size 16px
      +pcLiquid()
        font-size pcvw(16)
    +sp()
      font-size vw(24)

  // 3 title set
  .u-title3setPosition
    width 100%
    height 100%
    position absolute
    left: 50%;
    transform: translateX(-50%);
    +pc()
      top -59px
      +pcLiquid()
        top pcvw(-56)
    +sp()
      top vw(-81)

  // row title position
  .u-titleRowPosition
    position absolute
    left: 50%;
    transform: translateX(-50%);
    width 95%
    +pc()
      top -22px
      +pcLiquid()
        top pcvw(-22)
    +sp()
      top vw(-32)

  // position set aspect-ratio
  .u-1rowPc
    +pc()
      aspect-ratio(1200, 70) //42
      +pcLiquid()
        aspect-ratio(1200, 70)

  .u-2rowPc
    +pc()
      aspect-ratio(1200, 112)
      +pcLiquid()
        aspect-ratio(1200, 112)

  .u-3rowPc
    +pc()
      aspect-ratio(1200, 152)
      +pcLiquid()
        aspect-ratio(1200, 152)

  .u-1rowSp
    +sp()
      aspect-ratio(588, 102)

  .u-2rowSp
    +sp()
      aspect-ratio(588, 161)

  .u-3rowSp
    +sp()
      aspect-ratio(588, 223)

  // font
  .u-lead
    letter-spacing 0.05em
    +pc()
      font-size 1.5rem
      line-height round(29 / 15, 2)

      +.u-lead
        padding-top 15px

      +pcLiquid()
        font-size pcvw(15)
        +.u-lead
          padding-top pcvw(7.5)
    +sp()
      font-size vw(28)
      line-height round(52 / 28, 2)
      +.u-lead
        padding-top vw(28)


  // column
  .c-row
    display flex
    margin-top: calc(2rem * -1);
    margin-right: calc(2rem * -.5);
    margin-left: calc(2rem * -.5);

    & > *
      padding-right: calc(2rem * .5);
      padding-left: calc(2rem * .5);
      margin-top: 2rem;

    &.c-row_noGutters
      margin-top: calc(0rem * -1);
      margin-right: calc(0rem * -.5);
      margin-left: calc(0rem * -.5);
      > *
        padding-right: calc(0rem * .5);
        padding-left: calc(0rem * .5);
        margin-top: 0;

    +pc()
      flex-wrap wrap

    +sp()
      flex-direction column

    .c-col
      flex 1 0 0%

  // ul>li list
  .c-list
    li
      list-style initial
      color $colorBlue
      margin-left 20px
      span
        color black

  .u-font-weight-bold
    font-weight 700

  // swiper
  .swiper
    max-width 630px
    height 100%
    overflow unset
    +pc()
      width 630px
    +sp()
      width 100%


  .swiper-wrapper
    widows

  .swiper-slide
    // text-align center
    background #fff
    border-radius 10px
    // overflow hidden

  .swiper-slide img
    display block
    width 100%;
    height 100%
    object-fit cover
    border-radius 10px

  .swiper-pagination
    bottom -30px!important
    .swiper-pagination-bullet
      background $white
      width 14px
      height 14px
      opacity 1
      margin 0 10px!important
    .swiper-pagination-bullet-active
      background #000

  .swiper-button-prev,.swiper-button-next
    border-radius 50%
    background-color $colorBlue
    top 58%
    transform-origin center
    +pc()
      width 60px
      height 60px
    +sp()
      width vw(70)
      height vw(70)
    &::after
      font-weight 700
      color $white
      +pc()
        font-size 24px
      +sp()
        font-size vw(32)

  .swiper-button-prev
    left -30px!important
    &::after
      transform: rotate(180deg);
    +sp()
      left -4.4vw!important

  .swiper-button-next
    right -30px!important
    +sp()
      right -4.4vw!important

  // accordion
  .c-accordionToggle
    cursor pointer
    +sp()
      padding-right vw(100)

    &::before
      content ""
      position absolute
      top 50%
      right 25px
      width 20px
      height 4px
      /*縦線に*/
      transform rotate(90deg)
      background black
      transition all .3s ease-in-out
    &::after
        content ""
        position absolute
        top 50%
        right 25px
        /*横線*/
        width 20px
        height 4px
        background black
        transition all .2s ease-in-out
    &.opend
      &::before
        transform rotate(180deg)

      &::after
        opacity 0

      //+ .answer
      //  display block

    + .answer
      display none
