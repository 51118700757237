[data-category="houjin"]
  .page-header
    +pc()
      background-image url('../img/houjin/header_bg.jpg')
      background-position right top
    +sp()
      background-image url('../img/houjin/header_bg@2x.jpg')
      background-position right top

[data-pages="houjin-disclosure-172-2"]
  span.-is-ib
    display inline-block
    +sp()
      margin-top vw($mgn-sp-lv1)
