.scroll_anime{transition: all 1s ease .2s;}
.scroll_anime.fuwa{opacity:0; transform: scale(.95);}
.scroll_anime.fuwa.scroll__start{opacity:1;transform: scale(1)}
.scroll_anime.fuwa-up{transform: translateY(15px);opacity:0;}
.scroll_anime.fuwa-up.scroll__start{transform: translateY(0px);opacity:1;}
.scroll_anime.fuwa-down{transform: translateY(-15px);opacity:0;}
.scroll_anime.fuwa-down.scroll__start{transform: translateY(0px);opacity:1;}
.scroll_anime.fuwa-right{transform: translateX(-15px);opacity:0;}
.scroll_anime.fuwa-right.scroll__start{transform: translateX(0px);opacity:1;}
.scroll_anime.fuwa-left{transform: translateX(15px);opacity:0;}
.scroll_anime.fuwa-left.scroll__start{transform: translateX(0px);opacity:1;}
