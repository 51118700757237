[data-category="campus-life"]
  &:not([data-pages="circles"])
    .page-header
      +pc()
        background-image url('../img/campus-life/header_bg.jpg')
      +sp()
        background-image url('../img/campus-life/header_bg@2x.jpg')

  &[data-pages="campus-life-circles"]
    .page-header
      +pc()
        background-image url('../img/campus-life/circles/header_bg.jpg')
      +sp()
        background-image url('../img/campus-life/circles/header_bg@2x.jpg')


  [data-campus-life="index"]
    // お知らせ
    .content-latest-container
      +pc()
        display flex
      li
        +pc()
          width 'calc(33.33% - %spx)' % (60 / 3)
          +pcLiquid()
            width 'calc(33.33% - %s)' % pcvw(60 / 3)
        &.c-info_item
          +pc()
            padding-bottom 0
          a
            +pc()
              padding-bottom 0
        & + li
          +pc()
            margin-left 30px
            +pcLiquid()
              margin-left pcvw(@margin-left)
            &:first-of-type
              margin-left 0
          +sp()
            margin-top vw(80)
      .information-contnt-block
        a
          color $thema-text-black
    .info
      &-link-container
        display flex
        justify-content flex-end
        +pc()
          margin-top 10px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(20)
      &-text-link
        cmn-arrow-link($thema-text-purple)
    // 2カラム並び
    .flexPC.-wrap
      +pc()
        flex-wrap wrap
        .c-info_item:nth-of-type(n+3)
          margin-top 40px
          +pcLiquid()
            margin-top pcvw(@margin-top)
      .info
        &-link-container
          +pc()
            position absolute
            right 0
            bottom 0
            margin-top 0
    .c-info_item
      position relative
      +pc()
        width 'calc(50% - %s)' % ($mgn-lv2 / 2)
        +pcLiquid()
          width 'calc(50% - %s)' % pcvw($mgn-lv2 / 2)
      +sp()
        margin-bottom vw(84)
      a
        display block
        +pc()
          height 100%
          padding-bottom 35px
          +pcLiquid()
            padding-bottom pcvw(50)
          // &:hover
          //   .info_item--image
          //     img
          //       transform scale(1.1)
          //   .info-text-link::after
          //     transform translate3d(10px, 0, 0)
      .info_item--image
        position relative
        text-align center
        overflow hidden
        width 100%
        background-color $white
        +pc()
          height 280px
          +pcLiquid()
            height pcvw(@height)
      img
        width 100%
        height 100%
        +pc()
          transition transform .3s
      .info_item--text
        +pc()
          padding 15px 10px 0
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          padding vw(40 20 0)
      .info_item--title
        font-family $NotoSans
        font-weight 500
        letter-spacing ls(50)
        display -webkit-box
        overflow hidden
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        color $thema-text-black
        +pc()
          font-size 1.4rem
          line-height round(26 / 14, 2)
          +pcLiquid()
            font-size pcvw(14)
        +sp()
          font-size vw(28)
          line-height 2
         &:before
          content ""
          display inline-block
          background url('../img/common/textlink_arrow.png') no-repeat
          background-position center
          background-size contain
          +pc()
            width 8px
            height 12px
            margin-right 8px
            // +pcLiquid()
            //   width pcvw(@width)
            //   height pcvw(@height)
            //   margin-right pcvw(@margin-right)
          +sp()
            width vw(16)
            height vw(24)
            margin-right vw(10)
      .info_item--caption
        font-family $NotoSans
        font-weight 500
        letter-spacing ls(50)
        display -webkit-box
        overflow hidden
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        color $thema-text-black
        +pc()
          font-size 1.2rem
          line-height round(26 / 14, 2)
          margin-top 10px
          +pcLiquid()
            font-size pcvw(12)
            margin-top pcvw(@margin-top)
        +sp()
          font-size vw(24)
          line-height 2
          margin-top vw(30)

  [data-campus_life="sports"]
    .tab-container
      justify-content center
    .information-contnt-block-image,.withBlock
      +pc()
        margin-left 20px
        +pcLiquid()
          margin-left pcvw(@margin-left)
      +sp()
        margin-left vw(20)
    .content-latest-container
      display: flex
      +sp()
        flex-direction: column
    .information-contnt-block-inner
      padding 0


// 追加プロパティ
  .content-container-first
    display flex
    justify-content space-between
    +sp()
      flex-direction: column

  .content-container-second
    display flex
    justify-content space-between
    li
      width: 45%
      +sp()
        width: 100%;
    +sp()
      flex-direction: column
      width: 100%;

  .content-container-third
    display flex
    justify-content space-between
    li
      width: 45%
    +sp()
      flex-direction: column
