[data-modules="faculty"]
  // display
  [class*="u-professor_d-flex"]
    display flex
    &[class*="__nosp"]
      +sp()
        display block

  [class*="u-professor_d-block"]
    display block

  [class*="u-professor_d-inline-block"]
    display inline-block

  // flex
  [class*="u-professor_align-items-center"]
    align-items center
    &[class*="__nosp"]
      +sp()
        align-items inherit

  // padding
  [class*="u-professor_pl-0_5"]
    padding-left 0.5em
  [class*="u-professor_pl-4"]
    padding-left 4em
  [class*="u-professor_pr-4"]
    padding-right 4em
  [class*="u-professor_pt-1_6"]
    padding-top 1.6em
  [class*="u-professor_pb-1_6"]
    padding-bottom 1.6em
  [class*="u-professor_pt-custom"]
    padding-top 5.33vw !important

  // padding-resp
  [class*="u-professor_pl"]
    &[class*="__nospPl"]
      +sp()
        padding-left initial
    &[class*="__nopcPl"]
      +pc()
        padding-left initial
  [class*="u-professor_pr"]
    &[class*="__nospPr"]
      +sp()
        padding-right initial
    &[class*="__nopcPr"]
      +pc()
        padding-right initial
  [class*="u-professor_pt"]
    &[class*="__nospPt"]
      +sp()
        padding-top initial
    &[class*="__nopcPt"]
      +pc()
        padding-top initial
  [class*="u-professor_pb"]
    &[class*="__nospPb"]
      +sp()
        padding-bottom initial
    &[class*="__nopcPb"]
      +pc()
        padding-bottom initial

  // margin
  [class*="u-professor_mt-08"]
    margin-top 0.8em
  [class*="u-professor_mb-08"]
    margin-bottom 0.8em
  [class*="u-professor_mb-2"]
    margin-bottom 2em


  // margin-resp
  [class*="u-professor_mt"]
    &[class*="__nospMt"]
      +sp()
        margin-top initial
    &[class*="__nopcMt"]
      +pc()
        margin-top initial
  [class*="u-professor_mb"]
    &[class*="__nospMb"]
      +sp()
        margin-bottom initial
    &[class*="__nopcMb"]
      +pc()
        margin-bottom initial

  // color
  [class*="u-professor_color-purple"]
    color #601986

  // font
  .u-professor_fs-md
    line-height 1.2
    letter-spacing .05em
    +pc()
      //margin-bottom 8px
      font-size 15px
      +pcLiquid()
        //margin-bottom pcvw(@margin-bottom)
        font-size pcvw(@font-size)
    +sp()
      font-size vw(24)

  .u-professor_fs-mds
    letter-spacing .05em
    line-height 1.2
    +pc()
      //margin-top 8px
      font-size 13px
      +pcLiquid()
        //margin-top pcvw(@margin-top)
        font-size pcvw(@font-size)
    +sp()
      font-size vw(24)

  .u-professor_ff-roboto
    font-family $Roboto

  // width
  [class*="u-professor_w-10"]
    width 10%
  [class*="u-professor_w-15"]
    width 15%
  [class*="u-professor_w-20"]
    width 20%
  [class*="u-professor_w-25"]
    width 25%
  [class*="u-professor_w-30"]
    width 30%
  [class*="u-professor_w-35"]
    width 35%
  [class*="u-professor_w-40"]
    width 40%
  [class*="u-professor_w-45"]
    width 45%
  [class*="u-professor_w-50"]
    width 50%
  [class*="u-professor_w-75"]
    width 75%
  [class*="u-professor_w-95"]
    width 95%
  [class*="u-professor_w-100"]
    width 100%

  // width-resp
  [class*="u-professor_w-"]
    &[class*="__nospW"]
      +sp()
        width initial
    &[class*="__nopcW"]
      +pc()
        width initial

  // height
  [class*="u-professor_h-100"]
    height 100%
  [class*="u-professor_h-50"]
    height 50%
  [class*="u-professor_h-25"]
    height 25%

  // height-resp
  [class*="u-professor_h-"]
    &[class*="__nospH"]
      +sp()
        height initial
    &[class*="__nopcH"]
      +pc()
        height initial

  // unique
  .u-professor_area-image-block
    min-height 120px
    +pcLiquid()
      min-height pcvw(@min-height)
    +sp()
      width calc(100% - 26.67vw)
      min-height vw(200)
      margin-left vw(20)

  .area-image
    vertical-align top !important

    .area-image-block
      width 12rem !important
      height 12rem !important
      position relative

      +sp()
        width 26.67vw !important
        height 26.67vw !important

      img
        width 100% !important
        height 100% !important
        position absolute
        top 0
        left 0

  .pagenation a:focus
    outline none

  .list-teachers
    li
      width 100%
      background-color $white
      +pc()
        margin-bottom 20px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(40)
      a
        width 100%
        color $thema-text-black
        +pc()
          display table
          &:hover
            opacity .6
        +sp()
          display flex
          padding vw(20)
      .area-image,
      .area-text,
      .area-name,
      .area-specialty
        +pc()
          display table-cell
          vertical-align middle
      .area-text
        +sp()
          width vw(390)
          margin-left vw(40)
      .area-image
        position relative
        overflow hidden
        +pc()
          width 120px
          height 120px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(200)
          height vw(200)
        img
          width auto
          height auto
          max-width 100%
          max-height 100%
          position absolute
          transform translate(-50%, -50%)
          top 50%
          left 50%
      .area-name
        +pc()
          padding-left 40px
          width 320px
          +pcLiquid()
            padding-left pcvw(@padding-left)
            width pcvw(@width)
          +sp()
            margin-bottom vw(26)
        span
          display block
        .professor
          line-height 1.2
          letter-spacing .05em
          +pc()
            margin-bottom 8px
            font-size 15px
            +pcLiquid()
              margin-bottom pcvw(@margin-bottom)
              font-size pcvw(@font-size)
          +sp()
            font-size vw(24)
        .en
          font-family $Roboto
          letter-spacing .05em
          line-height 1.2
          +pc()
            margin-top 8px
            font-size 13px
            +pcLiquid()
              margin-top pcvw(@margin-top)
              font-size pcvw(@font-size)
          +sp()
            font-size vw(24)
      .area-specialty
        span
          &:nth-child(n+2)
            padding-left .5em
        +pc()
          padding-left 40px
          width 460px
          +pcLiquid()
            padding-left pcvw(@padding-left)
            width pcvw(@width)

  .block-teachers
    background-color $thema-bg-lightGrey
    +pc()
      padding 40px 0
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(80 40)
    .click_area
      +pc()
        width 100%
      +sp()
        width 50%
