[data-category="professor"]
  // display
  [class*="u-professor_d-flex"]
    display flex
    &[class*="__nosp"]
      +sp()
        display block

  [class*="u-professor_d-block"]
    display block

  [class*="u-professor_d-inline-block"]
    display inline-block

  // flex
  [class*="u-professor_align-items-center"]
    align-items center
    &[class*="__nosp"]
      +sp()
        align-items inherit

  // padding
  [class*="u-professor_pl-0_5"]
    padding-left 0.5em
  [class*="u-professor_pl-4"]
    padding-left 4em
  [class*="u-professor_pr-4"]
    padding-right 4em
  [class*="u-professor_pt-1_6"]
    padding-top 1.6em
  [class*="u-professor_pb-1_6"]
    padding-bottom 1.6em
  [class*="u-professor_pt-custom"]
    padding-top 5.33vw !important

  // padding-resp
  [class*="u-professor_pl"]
    &[class*="__nospPl"]
      +sp()
        padding-left initial
    &[class*="__nopcPl"]
      +pc()
        padding-left initial
  [class*="u-professor_pr"]
    &[class*="__nospPr"]
      +sp()
        padding-right initial
    &[class*="__nopcPr"]
      +pc()
        padding-right initial
  [class*="u-professor_pt"]
    &[class*="__nospPt"]
      +sp()
        padding-top initial
    &[class*="__nopcPt"]
      +pc()
        padding-top initial
  [class*="u-professor_pb"]
    &[class*="__nospPb"]
      +sp()
        padding-bottom initial
    &[class*="__nopcPb"]
      +pc()
        padding-bottom initial

  // margin
  [class*="u-professor_mt-08"]
    margin-top 0.8em
  [class*="u-professor_mb-08"]
    margin-bottom 0.8em
  [class*="u-professor_mb-2"]
    margin-bottom 2em


  // margin-resp
  [class*="u-professor_mt"]
    &[class*="__nospMt"]
      +sp()
        margin-top initial
    &[class*="__nopcMt"]
      +pc()
        margin-top initial
  [class*="u-professor_mb"]
    &[class*="__nospMb"]
      +sp()
        margin-bottom initial
    &[class*="__nopcMb"]
      +pc()
        margin-bottom initial

  // color
  [class*="u-professor_color-purple"]
    color #601986

  // font
  .u-professor_fs-md
    line-height 1.2
    letter-spacing .05em
    +pc()
      //margin-bottom 8px
      font-size 15px
      +pcLiquid()
        //margin-bottom pcvw(@margin-bottom)
        font-size pcvw(@font-size)
    +sp()
      font-size vw(24)

  .u-professor_fs-mds
    letter-spacing .05em
    line-height 1.2
    +pc()
      //margin-top 8px
      font-size 13px
      +pcLiquid()
        //margin-top pcvw(@margin-top)
        font-size pcvw(@font-size)
    +sp()
      font-size vw(24)

  .u-professor_ff-roboto
    font-family $Roboto

  // width
  [class*="u-professor_w-10"]
    width 10%
  [class*="u-professor_w-15"]
    width 15%
  [class*="u-professor_w-20"]
    width 20%
  [class*="u-professor_w-25"]
    width 25%
  [class*="u-professor_w-30"]
    width 30%
  [class*="u-professor_w-35"]
    width 35%
  [class*="u-professor_w-40"]
    width 40%
  [class*="u-professor_w-45"]
    width 45%
  [class*="u-professor_w-50"]
    width 50%
  [class*="u-professor_w-75"]
    width 75%
  [class*="u-professor_w-95"]
    width 95%
  [class*="u-professor_w-100"]
    width 100%

  // width-resp
  [class*="u-professor_w-"]
    &[class*="__nospW"]
      +sp()
        width initial
    &[class*="__nopcW"]
      +pc()
        width initial

  // height
  [class*="u-professor_h-100"]
    height 100%
  [class*="u-professor_h-50"]
    height 50%
  [class*="u-professor_h-25"]
    height 25%

  // height-resp
  [class*="u-professor_h-"]
    &[class*="__nospH"]
      +sp()
        height initial
    &[class*="__nopcH"]
      +pc()
        height initial

  // unique
  .u-professor_area-image-block
    min-height 120px
    +pcLiquid()
      min-height pcvw(@min-height)
    +sp()
      width calc(100% - 26.67vw)
      min-height vw(200)
      margin-left vw(20)

  .area-image
    vertical-align top !important

    .area-image-block
      width 12rem !important
      height 12rem !important
      position relative

      +sp()
        width 26.67vw !important
        height 26.67vw !important

      img
        width 100% !important
        height 100% !important
        position absolute
        top 0
        left 0

  .pagenation a:focus
    outline none
