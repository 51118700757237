$thisPage = "detail"

[data-pages={'"'+$thisPage+'"'}]
  /**
  * common
  **/

  // 外枠レイアウト
  .content
    +pc()
      padding 70px 0 165px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(60 0 260)
    &-section
      +pc()
        padding 0 50px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(0 $side-margin_sp)
    &-container
      +pc()
        maxWidth($width_pc01 px)
        +pcLiquid()
          max-width pcvw($width_pc01)

  .content
    background-color $white
    &-section
      +pc()
        padding 0 120px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(0 $side-margin_sp)
    &-container
      +pc()
        maxWidth($width_pc02 px)
        +pcLiquid()
          max-width pcvw($width_pc02)

    // ヘッダー
    &-header
      border-bottom-color $thema-text-purple
      border-bottom-style solid
      +pc()
        padding-bottom 25px
        border-bottom-width 4px
        margin-bottom 45px
        +pcLiquid()
          padding-bottom pcvw(@padding-bottom)
          border-bottom-width pcvw(@border-bottom-width)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        padding-bottom vw(35)
        border-bottom-width vw(6)
        margin-bottom vw(55)
      h1
        font-family $NotoSans
        font-weight 400
        color $thema-text-black
        letter-spacing ls(50)
        line-height round(59 / 42, 2)
        +pc()
          font-size 2.6rem
          margin-top 15px
          +pcLiquid()
            font-size pcvw(26)
            margin-top pcvw(15)
        +sp()
          font-size vw(42)
          margin-top vw(25)
      &-inner
        &.bottom
          +pc()
            display flex
            margin-top 20px
            +pcLiquid()
              margin-top pcvw(@margin-top)
          +sp()
            margin-top vw(30)
          > p + p
            +pc()
              margin-left 30px
              +pcLiquid()
                margin-left pcvw(@margin-left)
            +sp()
              margin-top vw(20) !important
      &-date
        @extend .information-contnt-block-date
        +pc()
          font-size 1.8rem
          +pcLiquid()
            font-size pcvw(18)
      &-category
        @extend .information-contnt-block-category
      // イベント詳細用
      &-place
        @extend .event-contnt-block-place
        margin-top 0 !important
      &-eventDate
        @extend .event-contnt-block-eventDate


  // コモンレイアウト
  .l
    &-container
      +pc()
        display flex
        flex-wrap wrap
        padding 15px 0
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(25 0)

      // カラム別レイアウト
      &.clm2
        +pc()
          justify-content space-between
        >
          div
          p
            +pc()
              width calc(50% - 10px)
              +pcLiquid()
                width 'calc(50% - %s)' % pcvw(10)
            &
              + div
              + p
                +sp()
                  margin-top vw(40)
            &:nth-child(n+3)
              +pc()
                margin-top 25px
                +pcLiquid()
                  margin-top pcvw(@margin-top)
        // 左右フリの順番入れ替え
        &.-is-reverse
          div
          p
            &:first-child
              order 1
      &.clm3
        +pc()
          justify-content space-between
        >
          div
          p
            +pc()
              width 'calc(33.33% - %spx)' % round(40 / 3, 0)
              +pcLiquid()
                width 'calc(33.33% - %s)' % pcvw(40 / 3)
            &
              + div
              + p
                +sp()
                  margin-top vw(40)
      &.clm4
        +pc()
          justify-content space-between
        >
          div
          p
            +pc()
              width 'calc(25% - %spx)' % round(42 / 4, 0)
              +pcLiquid()
                width 'calc(25% - %s)' % pcvw(42 / 3)
            &
              + div
              + p
                +sp()
                  margin-top vw(40)
    &-inner
      +pc()
        padding 15px 0
        +pcLiquid()
          padding pcvw(@padding)

    // 中見出し
    &-header
      border-bottom-color #cccccc
      border-bottom-style solid
      +pc()
        padding-bottom 18px
        border-bottom-width 2px
        margin 65px 0 30px
        +pcLiquid()
          padding-bottom pcvw(@padding-bottom)
          border-bottom-width pcvw(@border-bottom-width)
      +sp()
        padding-bottom vw(30)
        border-bottom-width vw(4)
        margin vw(75 0 15)
    &-heading
      font-family $NotoSans
      font-weight 400
      color $thema-text-black
      letter-spacing ls(50)
      +pc()
        font-size 2.2rem
        line-height round(29 / 22, 2)
        +pcLiquid()
          font-size pcvw(22)
      +sp()
        font-size vw(38)
        line-height round(53 / 38, 2)
    &-subheading
      font-family $NotoSans
      font-weight 400
      color $thema-text-black
      letter-spacing ls(50)
      border-left-color $thema-text-purple
      border-left-style solid
      +pc()
        font-size 1.8rem
        line-height 18px
        padding-bottom 2px
        padding-left 10px
        border-left-width 4px
        margin-bottom 20px
        +pcLiquid()
          font-size pcvw(18)
          line-height pcvw(@line-height)
          padding-bottom pcvw(@padding-bottom)
          padding-left pcvw(@padding-left)
          border-left-width pcvw(@border-left-width)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        font-size vw(34)
        line-height vw(36)
        padding-bottom vw(4)
        padding-left vw(20)
        border-left-width vw(8)
        margin-bottom vw(20)

    // 画像
    &-image
      width 100%
      text-align center
      img
        +sp()
          width 100%

    // テキスト
    &-pragraph
      font-family $NotoSans
      color $thema-text-black
      letter-spacing ls(50)
      +pc()
        font-size 1.5rem
        line-height round(29 / 15, 2)
        +pcLiquid()
          font-size pcvw(15)
      +sp()
        font-size vw(28)
        line-height round(52 / 28, 2)

    // リンク
    &-link
      font-family $NotoSans
      color $thema-text-purple
      letter-spacing ls(50)
      background-repeat no-repeat
      background-image url('../img/common/textlink_arrow.png')
      +pc()
        font-size 1.6rem
        line-height round(29 / 16, 2)
        background-size 9px 15px
        background-position left 7px
        padding-left 15px
        hover-opacity()
        +pcLiquid()
          font-size pcvw(16)
          background-size pcvw(@background-size)
          background-position pcvw(@background-position)
          padding-left pcvw(@padding-left)
      +sp()
        font-size vw(28)
        line-height round(50 / 28, 2)
        background-size vw(16 25)
        background-position left vw(10)
        padding-left vw(25)
      &[target=_blank]
        &::after
          content ''
          display inline-block
          background-repeat no-repeat
          background-size contain
          background-image url('../img/common/icon_tab.svg')
          +pc()
            width 17px
            height 16px
            margin-left 5px
            +pcLiquid()
              width pcvw(@width)
              height pcvw(@height)
              margin-left pcvw(@margin-left)
          +sp()
            width vw(30)
            height vw(29)
            margin-left vw(10)

  .back-link
    &-container
      text-align center
      +pc()
        margin-top 55px
        +pcLiquid()
          margin-top pcvw(@margin-top)
      +sp()
        margin-top vw(75)
    &-text
      cmn-arrow-link($thema-text-purple)
      font-family $NotoSans
      +pc()
        font-size 1.6rem
        +pcLiquid()
          font-size pcvw(16)
      +sp()
        font-size vw(28)

  // キャプション付き画像（MTテンプレ）
  .images-withCaption
    width 100%
    +pc()
      margin-bottom 40px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
    +sp()
      margin-bottom vw(40)
    &:not(._2col)
      .img
        +pc()
          max-width 600px
          margin-right auto
          margin-left auto
          +pcLiquid()
            max-width pcvw(@max-width)
    &._2col
      +pc()
        display flex
      .img
        &:nth-of-type(2)
          +pc()
            margin-left 20px
            +pcLiquid()
              margin-left pcvw(@margin-left)
          +sp()
            margin-top vw(20)
    figcaption
      letter-spacing  0.05em
      +pc()
        font-size 15px
        line-height 2
        +pcLiquid()
          font-size pcvw(15)
      +sp()
        font-size vw(28)
        line-height 1.85
