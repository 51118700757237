html
  font-size 62.5%
  font-family $deviceGothic
  font-weight 400
  color $thema-text-black

body
  position relative

.pages
  // overflow hidden
  position relative

.main
  position relative
  z-index 1
  // ヘッダーのPCレイアウト用
  +header-pc()
    margin-top 40px
    margin-left 180px
    +pcLiquid()
      margin-top pcvw(@margin-top)
      margin-left pcvw(@margin-left)
  // ヘッダーのSPレイアウト用
  +header-sp()
    margin-top vw(120)
    +pc()
      margin-top 80px

/**
* PC / SPでの表示・非表示
*/
br
img
  &.forPC
    +pc()
      display inline
    +sp()
      display none
  &.forTAB
    +pc()
      display none
    +tab()
      display inline
  &.forSP
    +pc()
      display none
    +sp()
      display inline
  &.forSP_small
    +pc()
      display none
    +sp_small()
      display inline

/**
* インデント調整
*/
.halfIndent
  display inline-block
  text-indent -.5em
.indent
  display inline-block
  text-indent -1em


.ojf-img
  object-fit cover
  object-position center
  font-family 'object-fit: cover; object-position: center;'

/**
* インビューアニメーション
*/
// .jsInview
//   transition transform .5s, opacity .5s
//   opacity 0
//   transform translate3d(0, 60px, 0)
//   &.is-active
//     opacity 1
//     transform translate3d(0, 0, 0)
