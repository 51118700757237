// view more などのアロー下線
cmn-arrow-link($color)
  font-family $Roboto
  color $color
  letter-spacing .07em
  position relative
  display inline-block
  +pc()
    font-size 1.3rem
    &:hover
      &::after
        transform translate3d(10px, 0, 0)
    +pcLiquid()
      font-size pcvw(13)
  +sp()
    font-size vw(30)
    border-bottom-width vw(2)
  &::before
    content ''
    display block
    width 100%
    height 1px
    background-color $color
    position absolute
    bottom 0
    right 0
  &::after
    content ''
    display block
    width 0
    height 0
    border-style solid
    margin-left auto
    border-color transparent transparent transparent $color
    +pc()
      border-width 11px 0 0 11px
      transition transform .3s
      +pcLiquid()
        border-width pcvw($size 0 0 $size)
    +sp()
      border-width vw(28 0 0 28)
  span
    +pc()
      padding-right 20px
      padding-left 6px
      +pcLiquid()
        padding-right pcvw(@padding-right)
        padding-left pcvw(@padding-left)
    +sp()
      padding-right vw(40)
      padding-left vw(10)

// ボタンリンクのアロー
button-arrow-link($color)
  // UIKIT用の変数、汎用クラスの読み込み
  @import './_uikitModules'

  display inline-block
  background $color
  border: 1px solid $color
  +pc()
    height 45px
    padding 0 $mgn-lv1_1
    transition background .3s
    +pcLiquid()
      height pcvw(@height)
      padding 0 pcvw($mgn-lv1_1)
    &:hover
      background #fff
      .text,
      .inner .text
        color $color
        &::before
          background-color $color
        &::after
          border-color transparent transparent transparent $color
          transform translate3d(6px, 0, 0)
  +sp()
    width 100%
    height auto
    padding vw(30) vw($mgn-lv2)
  .inner
    display flex
    align-items center
    width 100%
    height 100%
    .text
      display block
      width 100%
      color #fff
      font-family $Roboto
      letter-spacing .07em
      position relative
      +pc()
        padding-right 40px
        +pcLiquid()
          padding-right pcvw(@padding-right)
      +sp()
        display inline-block
        width 100%
        border-bottom-width vw(2)
        line-height 1.3
        padding-right vw($mgn-lv4)
        text-align center
      &::before
        content ''
        display block
        background-color #fff
        position absolute
        right 0
        +pc()
          width 20px
          height 1px
          bottom 4px
        +sp()
          width vw(43)
          height 2px
          top calc(50% + 1.56vw / 2)
          transform translateY(-50%)
      &::after
        content ''
        display block
        width 0
        height 0
        border-style solid
        margin-left auto
        border-color transparent transparent transparent #fff
        position absolute
        +pc()
          border-width 7px 0 0 7px
          transition transform .3s
          bottom 4px
          right -1px
          +pcLiquid()
            border-width pcvw($size 0 0 $size)
        +sp()
          border-width vw(14 0 0 16)
          top 50%
          transform translateY(-50%)
          right vw(-3)
      span
        +pc()
          padding-right 20px
          padding-left 6px
          +pcLiquid()
            padding-right pcvw(@padding-right)
            padding-left pcvw(@padding-left)
        +sp()
          padding-right vw(40)
          padding-left vw(10)

// カルーセルのボタン
carousel-button()
  background-image url('../img/common/carousel_btn.png')
  background-repeat no-repeat
  background-size contain
  margin-top 0
  border-radius 50%
  +pc()
    width 50px
    height @width
    box-shadow 0 10px 10px alpha($black, .1)
    +pcLiquid()
      width pcvw(@width)
      height @width
  +sp()
    width vw(60)
    height @width
    box-shadow vw(0 10px 10px) alpha($black, .1)
  // ↓ important 使いたくないから
  &.swiper-button-prev
    +sp()
      left vw(20)
  &.swiper-button-next
    +sp()
      right vw(20)

.swiper-button-prev
  transform scaleX(-1) translateY(-50%)
.swiper-button-next
  transform translateY(-50%)
