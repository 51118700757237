.event-contnt-block
  a
    display block
    height 100%
    color $thema-text-black
    background-color $white
    +pc()
      &:hover
        .event-contnt-block-image img
          transform scale(1.1)
  &-image
    +pc()
      height 220px
      overflow hidden
      +pcLiquid()
        height pcvw(@height)
    +sp()
      height vw(430)
    img
      width 100%
      height 100%
      +pc()
        transition transform .3s
  &-inner
    +pc()
      padding 30px 20px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(40)
  &-header
    &-inner
      display flex

  &-date
    font-family $Roboto
    color $thema-text-purple
    letter-spacing .15em
    position relative
    & + .event-contnt-block-date
      &::before
        content 'ー'
        display inline-block
        position relative
        top -1rem
        +pc()
          font-size 1.8rem
          margin 0 .5rem
          +pcLiquid()
            font-size pcvw(18)
        +sp()
          font-size vw(38)
          margin 0 1rem
    .month
      +pc()
        font-size 2.6rem
        +pcLiquid()
          font-size pcvw(24)
      +sp()
        font-size vw(46)
      &::after
        content '/'
        font-family $Roboto
        display inline-block
        margin-left .5rem
        +pc()
          font-size 2rem
          +pcLiquid()
            font-size pcvw(18)
        +sp()
          font-size vw(36)
    .day
      +pc()
        font-size 5rem
        +pcLiquid()
          font-size pcvw(46)
      +sp()
        font-size vw(104)
    .week
      font-family $NotoSans
      +pc()
        font-size 1.8rem
        +pcLiquid()
          font-size pcvw(16)
      +sp()
        font-size vw(34)

  .-is-comingsoon
    font-family $NotoSans
    +pc()
      font-size 1.8rem
      line-height round(50 / 18, 2)
      +pcLiquid()
        font-size pcvw(16)
    +sp()
      font-size vw(34)
      // line-height round(104 / 34, 2)
      line-height vw(80)

  &-heading
    font-family $NotoSans
    font-weight 500
    letter-spacing ls(50)
    +pc()
      font-size 1.4rem
      line-height 1.7
      margin-top 15px
      +pcLiquid()
        font-size pcvw(14)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(28)
      line-height 2
      margin-top vw(20)

  &-category
    display inline-block
    +pc()
      margin-top 45px
      +pcLiquid()
        margin-top pcvw(@margin-top)
    +sp()
      margin-top vw(30)
    span
      color $thema-text-purple
      letter-spacing ls(50)
      white-space nowrap
      display flex
      align-items center
      justify-content center
      border 1px solid $thema-text-purple
      +pc()
        font-size 1.2rem
        padding 3px 10px
        min-width 120px
        +pcLiquid()
          font-size pcvw(12)
          padding pcvw(@padding)
          min-width pcvw(120)
      +sp()
        font-size vw(24)
        padding vw(10)
        min-width vw(303)

  &-place
    font-family $NotoSans
    letter-spacing ls(50)
    display flex
    align-items center
    +pc()
      font-size 1.4rem
      margin-top 10px
      +pcLiquid()
        font-size pcvw(14)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(28)
      margin-top vw(20)
    &::before
      content ''
      display block
      background-image url('../img/common/place_pin.svg')
      background-repeat no-repeat
      background-size contain
      +pc()
        width 20px
        height 24px
        margin-right 10px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
          margin-right pcvw(@margin-right)
      +sp()
        width vw(35)
        height vw(41)
        margin-right vw(12)

  &-eventDate
    @extend .event-contnt-block-place
    margin-top 0 !important
    &::before
      background-image url('../img/common/icon_clock.svg')
      +pc()
        width 24px
        height @width
        +pcLiquid()
          width pcvw(@width)
          height @width
      +sp()
        width vw(46)
        height @width
