.information-contnt-block
  a
    color $thema-text-black
    +pc()
      &:hover
        .information-contnt-block-image img
          transform scale(1.1)
  &-image
    +pc()
      height 220px
      overflow hidden
      +pcLiquid()
        height pcvw(@height)
    +sp()
      height vw(520)
    img
      width 100%
      height 100%
      +pc()
        transition transform .3s
  &-inner
    +pc()
      padding 0 10px
      margin-top 15px
      +pcLiquid()
        padding pcvw(@padding)
        margin-top pcvw(@margin-top)
    +sp()
      padding vw(0 20)
      margin-top vw(40)
  &-date
    font-family $Roboto
    letter-spacing ls(50)
    display inline-block
    +pc()
      font-size 1.6rem
      +pcLiquid()
        font-size pcvw(16)
    +sp()
      font-size vw(32)
  &-category
    display inline-block
    +pc()
      margin-left 20px
      +pcLiquid()
        margin-left pcvw(@margin-left)
    +sp()
      margin-left vw(20)
    span
      color $thema-text-purple
      letter-spacing ls(50)
      white-space nowrap
      text-align center
      display inline-block
      border 1px solid $thema-text-purple
      +pc()
        font-size 1.2rem
        padding 3px 10px
        min-width 120px
        +pcLiquid()
          font-size pcvw(12)
          padding pcvw(@padding)
          min-width pcvw(120)
      +sp()
        font-size vw(24)
        padding vw(10)
        min-width vw(184)
  &-title
    font-family $NotoSans
    font-weight 500
    letter-spacing ls(50)
    display -webkit-box
    overflow hidden
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    +pc()
      font-size 1.4rem
      line-height round(26 / 14, 2)
      margin-top 10px
      +pcLiquid()
        font-size pcvw(14)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(28)
      line-height 2
      margin-top vw(30)
