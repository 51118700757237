[data-pages="english-lang"]
  .page-header
    +pc()
      background-image url('../img/english/header_bg-pc.jpg')
    +sp()
      background-image url('../img/english/header_bg-sp.jpg')
  .page-heading-en
    display none

  [data-english="index"]
    &.ui-template
      padding-top 0
      +sp()
        padding-left 0
        padding-right 0

    &.ui-template
      [class^="heading"]
        &.withBlock
          &:before
            height 100%

    .paragraph
      letter-spacing normal

    .container
      +sp()
        padding-right vw($mgn-lv2)
        padding-left vw($mgn-lv2)
        img
          width 100%
          height auto

    .content-key_visual
      background-color $thema-bg-lightGrey
      +pc()
        padding-top $mgn-lv4
        padding-bottom $mgn-lv3
      +sp()
        padding-top $mgn-lv2
        padding-bottom $mgn-lv2

      .kv-img
        +pc()
          margin-left 23px
          margin-bottom 23px
          +pcLiquid()
            margin-left pcvw(@margin-left)
            margin-bottom pcvw(@margin-bottom)
        +sp()
          margin-left vw(48)
          margin-bottom vw(36)

    .heading1
      color $thema-bg-purple
      font-family $Roboto
      +pc()
        font-size 35px
        margin-top -28px
        +pcLiquid()
          font-size pcvw(@font-size)
          margin-top pcvw(@margin-top)
      +sp()
        font-size vw(52)

      .text
        display inline-block
        background-color $white
        padding 0 5px
        +pc()
          margin-bottom 18px
          letter-spacing 0.05em
        +sp()
          margin-bottom vw(12)
          letter-spacing -0.06em

    .col_2
      .text-box
        +pc()
          width 66%
      .img-box
        +pc()
          width 31%

    .flexPC
      &:first-child
        margin-right 0

    .link-item
      .img
        +pc()
          width 450px
          height 300px
          overflow hidden
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        img
          +pc()
            transition transform .3s
      +pc()
        &:hover
          img
            transform scale(1.1)
          .link-box
            border-bottom 2px solid $thema-bg-purple
            opacity .7
      .link-box
        border-bottom 2px solid #ccc
        .text
          display block
          color $thema-text-black
          background-image url('../img/english/arrow.svg')
          background-position right bottom 20px
          background-repeat no-repeat
          background-size 21px 4px
