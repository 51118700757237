[data-pages="campus-life-committee"]
  .flex-reverse
    +pc()
      display flex
      justify-content space-between
      flex-direction row-reverse
      margin-right: 0
      .box
        width 450px
        margin-right: 0
        +pcLiquid()
          width pcvw(@width)
    +sp()
      flex-direction: column

  .flex-campus-life
    +pc()
      display flex
      justify-content space-between
      flex-direction inherit
      margin-right: 0
      .box
        width 450px
        margin-right: 0
        +pcLiquid()
          width pcvw(@width)
    +sp()
      flex-direction: column

  .snsbuttons > li
    +pc()
      margin-left: 100px

  .sns-wrap
    text-align right
    +pc()
      position absolute
      right 30px
      bottom 30px
      +pcLiquid()
        right pcvw(@right)
        bottom pcvw(@bottom)
    +sp()
      margin-top vw(30)

  /*=======================================
    club
  =======================================*/
  .heading-club
    display flex
    align-items center
    letter-spacing .03em
    +pc()
      font-size 21px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(38)
    &:before
      content ""
      display inline-block
      background url(../img/common/deco-club-heading.svg) no-repeat
      background-size contain
      +pc()
        width 4px
        height 24px
        margin-right 12px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
          margin-right pcvw(@margin-right)
      +sp()
        width vw(6)
        height vw(35)
        margin-right vw(18)


  .club
    &-list
      display flex
      flex-wrap wrap
      +pc()
        margin-top 50px
        padding-bottom 20px
        justify-content space-between
        +pcLiquid()
          margin-top pcvw(50)
          padding-bottom pcvw(@padding-bottom)
      +sp()
        margin-top vw(55)
        justify-content center
      li
        overflow hidden
        +pc()
          position relative
          background #f5f6f8
          border-radius 6px
          +pcLiquid()
            border-radius pcvw(6)
        +sp()
          border-radius vw(12)
        &:before
          +pc()
            content ""
            width 100%
            height 100%
            position absolute
            top 0
            left 0
            background #f5f6f8
            z-index -1
        &:nth-of-type(1)
          +pc()
            margin-right 30px
            +pcLiquid()
              margin-right pcvw(@margin-right)
        &:nth-of-type(-n+2)
          +pc()
            width calc(100% / 2 - 30px / 2)
            +pcLiquid()
              width calc(100% / 2 - 2.2vw / 2)
          .club-list-content
            +pc()
              height 250px
              min-height 200px
              padding 30px
              position relative
              +pcLiquid()
                height pcvw(@height)
                padding pcvw(@padding)
        &:nth-of-type(3),
        &:nth-of-type(4)
          +pc()
            margin-right 30px
            +pcLiquid()
              margin-right pcvw(@margin-right)
        &:nth-of-type(n+3)
          +pc()
            width calc(100% / 3 - 60px / 3)
            margin-top 40px
            +pcLiquid()
              margin-top pcvw(@margin-top)
          .club-list-content
            +pc()
              height 330px
              min-height 280px
              padding 30px
              +pcLiquid()
                height pcvw(@height)
                padding pcvw(@padding)
        &:not(:first-of-type)
          +sp()
            margin-top vw(60)
      &-thumb img
        width 100%
      &-content
        +sp()
          background #f5f6f8
          padding vw(40 40 35)
        &-copy
          font-weight 400
          line-height 2
          text-align justify
          +pc()
            font-size 14px
            margin-top 10px
            +pcLiquid()
              font-size pcvw(@font-size)
              margin-top pcvw(@margin-top)
          +sp()
            font-size vw(26)
            margin-top vw(20)
        .btn-more
          +pc()
            background #f5f6f8
            position absolute
            bottom 30px
            right 30px
            +pcLiquid()
              bottom pcvw(@bottom)
              right pcvw(@right)
          +sp()
            margin-top vw(25)
            margin-left auto
            background transparent

    &-table
      +pc()
        padding-top 60px
        +pcLiquid()
          padding-top pcvw(@padding-top)
      +sp()
        padding-top vw(100)
      &-list
        display flex
        flex-wrap wrap
        +pc()
          margin-top 10px
          +pcLiquid()
            margin-top vw(@margin-top)
        +sp()
          margin-top vw(60)
        li
          +pc()
            // width 258px
            margin-top 20px
            +pcLiquid()
              // width pcvw(@width)
              margin-top pcvw(@margin-top)
          +sp()
            width 100%
          &:not(:nth-of-type(4n+1))
            +pc()
              margin-left 20px
              +pcLiquid()
                margin-left pcvw(@margin-left)
          &:not(:first-of-type)
            +sp()
              margin-top vw(40)
          .cmn-btn
            +sp()
              width 100%
