[data-category="others"]
  .page-header
    +pc()
      background-image url('../img/others/header_bg.jpg')
    +sp()
      background-image url('../img/others/header_bg@2x.jpg')

  // 広報用のキャッチコピー・ロゴマーク・ロゴタイプページ
  [data-others="logo"]
    .per60
      +pc()
        width 60%
    .img-logotype
      +sp()
        width 70%
        margin 0 auto
