[data-category="english-lang"]
  .page-header
    +pc()
      background-image url('../img/english/header_bg.jpg')
    +sp()
      background-image url('../img/english/header_bg@2x.jpg')

  // english配下すべてに適用する場合はこちら
  .page-heading-en
    display none


  [data-english="career-support-center"]
    .flexPC.-col2.-reverse
      +pc()
        flex-direction row-reverse
    .withBorder.margin-lv1
      margin-bottom $mgn-lv1
      +pcLiquid()
        margin-bottom pcvw($mgn-lv1)
      +sp()
        margin-bottom vw($mgn-sp-lv1)
