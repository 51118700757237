// mixin
clearfix()
  &::after
    content ''
    display block
    clear both
    height 0
    visibility hidden

hover-opacity()
  opacity 1
  -ms-transition opacity .3s
  -webkit-transition opacity .3s
  transition opacity .3s
  &:hover
    opacity .6

hover_scale()
  transition transform .3s
  display inline-block
  transform perspective(0)
  &:hover
    transform scale(1.1)

maxWidth(value)
  max-width value
  margin-right auto
  margin-left auto

// プレイスホルダーのカラーを任意に変更
placeholder(color)
  &::-ms-input-placeholder
    color color
  &::-webkit-input-placeholder
    color color
  &::placeholder
    color color

// iOS で発生するinput タグの青線非表示
txtInput_option()
  -ms-appearance none
  -webkit-appearance none
  -moz-appearance none
  appearance none
  outline 0
  border-radius 0
  &::-ms-expand
    display none
