[data-category="campus-life"]
  [data-campus-life="map"]
    .campus
      &-img
        +pc()
          margin-top 50px
          +liquid()
            margin-top vwp(@margin-top)
        +sp()
          margin-top vw(60)
      &-place
        display flex
        justify-content space-between
        flex-wrap wrap
        +pc()
          margin-top 60px
          +liquid()
            margin-top vwp(@margin-top)
        +sp()
          margin-top vw(80)
      &-list
        overflow hidden
        +pc()
          width 340px
          border-radius 6px
          +liquid()
            width vwp(@width)
            border-radius vwp(@border-radius)
        +sp()
          width 100%
          border-radius vw(12)
        &:nth-of-type(n+4)
          +pc()
            margin-top 40px
            +liquid()
              margin-top vwp(@margin-top)
        &:not(:first-of-type)
          +sp()
            margin-top vw(30)
        &:nth-of-type(1) .campus-list-content-title:before
          background url(../img/campus-life/map/icon-campus-place_1.png) no-repeat
          background-size contain
        &:nth-of-type(2) .campus-list-content-title:before
          background url(../img/campus-life/map/icon-campus-place_2.png) no-repeat
          background-size contain
        &:nth-of-type(3) .campus-list-content-title:before
          background url(../img/campus-life/map/icon-campus-place_3.png) no-repeat
          background-size contain
        &:nth-of-type(4) .campus-list-content-title:before
          background url(../img/campus-life/map/icon-campus-place_4.png) no-repeat
          background-size contain
        &:nth-of-type(5) .campus-list-content-title:before
          background url(../img/campus-life/map/icon-campus-place_5.png) no-repeat
          background-size contain
        &:nth-of-type(6) .campus-list-content-title:before
          background url(../img/campus-life/map/icon-campus-place_6.png) no-repeat
          background-size contain
        &-thumb
          img
            width 100%
        &-content
          +pc()
            padding 20px 10px
            +liquid()
              padding vwp(@padding)
          +sp()
            padding vw(40 20)
          &-title
            color $base-color-purple
            font-weight 700
            position relative
            +pc()
              font-size 20px
              padding-left 52px
              +liquid()
                font-size vwp(@font-size)
                padding-left vwp(@padding-left)
            +sp()
              font-size vw(40)
              padding-left vw(105)
            &:before
              content ""
              position absolute
              left 0
              bottom 0
              +pc()
                width 50px
                height 62px
                +liquid()
                  width vwp(@width)
                  height vwp(@height)
              +sp()
                width vw(98)
                height vw(122)
          &-copy
            font-weight 400
            line-height 2
            +pc()
              font-size 16px
              margin-top 15px
              +liquid()
                font-size vwp(@font-size)
                margin-top vwp(@margin-top)
            +sp()
              font-size vw(26)
              margin-top vw(35)
