// 入試情報トップ
[data-pages="uitemplate nyushi_index"]
  .link-content
    text-align right
    .link-container
      cmn-arrow-link($thema-text-purple)
  .nyushi-footer-inner
    &.adjustment-width
      .buttonlink
        +pc()
          width 270px
          +pcLiquid()
            width pcvw(@width)

// 総合型選抜（専願制）専用サイト
[data-pages="uitemplate sougousenbatsu_sengan"]
  .class
    color blue
  .ui-template .buttonlink
    +pc()
      width 540px
      +pcLiquid()
        width pcvw(@width)

// 入学試験問題
[data-pages="uitemplate nyushi-mondai"]
  .table-nyushi-mondai
    text-align left
    tr
      td
        padding-left 1em
    .-w6em
      width 6em
