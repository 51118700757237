$thisPage = "about"
$bgPath = '../img/' + $thisPage + '/'

// 盛岡大学についてh1背景
[data-category="about"]
  .page-header
    +pc()
      background-image url('../img/about/header_bg.jpg')
      background-position right top
    +sp()
      background-image url('../img/about/header_bg@2x.jpg')
      background-position right top

[data-category="about"]
  &[data-pages="sister_school"]
  &[data-pages="camosun-2019"]
  &[data-pages="camosun-2018"]
  &[data-pages="nub-2018"]
    .page-header
      +pc()
        background-image url('../img/about/sister_school/header_bg.jpg')
      +sp()
        background-image url('../img/about/sister_school/header_bg@2x.jpg')
  &[data-pages="founding-spirit"]
    .page-header
      +pc()
        background-image url('../img/about/founding-spirit/header_bg.jpg')
      +sp()
        background-image url('../img/about/founding-spirit/header_bg@2x.jpg')
  &[data-pages="about-corporation"],
  &[data-pages="about-corporation-business-report"],
  &[data-pages="about-corporation-business-report-r1"],
  &[data-pages="about-corporation-business-report-h30"],
  &[data-pages="about-corporation-business-report-h29"],
  &[data-pages="about-regulations"],
  &[data-pages="about-regulations-action-plan"],
  &[data-pages="about-recruit"]
    .page-header
      +pc()
        background-image url('../img/about/corporation/header_bg.jpg')
        background-position left bottom
      +sp()
        background-image url('../img/about/corporation/header_bg@2x.jpg')

  &[data-pages="about-corporation"]
    .box
      div
        +pc()
          padding-left 1.5em
          text-indent -1.5em
        +sp()
          padding-left 2.5em
          text-indent -2.5em
        a
          line-height 1.4
  &[data-pages="university-policy"]
    .ui-template
      [class^="heading"].withBorder
        &::before
          background: $thema-bg-purple


// about配下の汎用クラスはここで定義する
[data-category={'"'+$thisPage+'"'}]

  .text-link
    $themeColor = $color-bungakubu
    cmn-arrow-link($themeColor)

  .block-page_index
    block-page_index($themeColor)
    .list-page_index
      a
        &::before
          background url('../img/common/textlink_arrow-' + 'purple' + '.svg') no-repeat


  /**
  * content
  **/
  .content
    // 新着3件のレイアウト
    &-latest-container
      +pc()
        display flex
        justify-content flex-start
        margin-bottom 50px
      +sp()
        margin-bottom vw(80)
      li
        +pc()
          width 'calc(33.3% - %spx)' % (60 / 3)
          +pcLiquid()
            width 'calc(33.3% - %s)' % pcvw(60 / 3)
        +sp()
          margin-top vw(80)
        +li
          +pc()
            margin-left 30px
            +pcLiquid()
              margin-left pcvw(@margin-left)
        &:nth-of-type(4n)
          +pc()
            margin-left 0

    // 3件目以降のニュース
    &-archive
      &-list
        & + li
          +pc()
            margin-top 20px
            +pcLiquid()
              margin-top pcvw(@margin-top)
          +sp()
            margin-top vw(40)
        a
          color $thema-text-black
          background-color $white
          +pc()
            display flex
            padding 35px 40px
            hover-opacity()
            +pcLiquid()
              padding pcvw(@padding)
          +sp()
            display block
            padding vw(40)
      &-header
        +pc()
          width percent(240, $width_pc01 - 80)
          flex-shrink 0
          +pcLiquid()
            width 30%
      &-date
        @extend .information-contnt-block-date
      &-category
        @extend .information-contnt-block-category
      &-title
        @extend .information-contnt-block-title
        +pc()
          font-size 1.6rem
          line-height round(26 / 16, 2)
          margin-top -.3em
          +pcLiquid()
            font-size pcvw(16)
        +sp()
          font-size vw(28)
          line-height 2
          margin-top vw(20)

  .ui-template [class^="heading"].withBorder:before
    background: #ccc;

  .color-purple
    color: #5f1985
    font-size: 13px

  .link-container
    +pc()
      margin-left: 68px


  /**
  * 広報誌「MoriDialog」
  **/
  [data-about="moridailog"]
    .heading2
      a
        +pc()
          transition opacity .2s
          &:hover
            opacity .6
      small
        font-size 0.8em
    .pdf-icon
      display inline-block
      width 21px
      height 20px
      background url('../img/common/icon_pdf.png') no-repeat
      background-position center
      background-size contain
      margin-bottom -1px
      +sp()
        width vw(41)
        height vw(40)
    .img
      a
        +pc()
          transition opacity .2s
          &:hover
            opacity .6
