// viewmore--back
cmn-arrow-link--back($color)
  font-family $Roboto
  color $color
  letter-spacing .07em
  position relative
  display inline-block
  +pc()
    font-size 1.6rem
    &:hover
      &::after
        transform translate3d(-10px, 0, 0)
    +pcLiquid()
      font-size pcvw(16)
  +sp()
    font-size vw(30)
    border-bottom-width vw(2)
  &::before
    content ''
    display block
    width 100%
    height 1px
    background-color $color
    position absolute
    bottom 0
    right 0
  &::after
    content ''
    display block
    width 0
    height 0
    border-style solid
    border-color transparent $color transparent transparent
    +pc()
      border-width 9px 9px 0 0
      transition transform .3s
      +pcLiquid()
        border-width pcvw($size 0 0 $size)
    +sp()
      border-width vw(20 20 0 0)
  span
    font-family $Roboto
    +pc()
      padding-right 6px
      padding-left 20px
      +pcLiquid()
        padding-right pcvw(@padding-right)
        padding-left pcvw(@padding-left)
    +sp()
      padding-right vw(12)
      padding-left vw(40)

.link-point
  display block
  border-bottom 1px solid $lightGray
  position relative
  cursor pointer
  margin-top 1em
  &.theme_color-purple
    border-bottom 1px solid $thema-text-purple
    &::after
      background url(../img/common/textlink_arrow-purple.svg) no-repeat
  +pc()
    transition border-bottom .2s
  span
    color $thema-text-black
    font-weight normal
    padding-left .5em
    +pc()
      transition color .2s
    &.theme_color-purple
      color $thema-text-purple
  &::after
    position absolute
    top: 50%
    transform translateY(-50%)
    right .5em
    content ''
    background url(../img/common/textlink_arrow-gray.svg) no-repeat
    +pc()
      width 8px
      height 12px
      transition background .2s
      transition top .25s
      +pcLiquid()
        width pcvw(@width)
        height pcvw(@height)
    +sp()
      width vw(16)
      height vw(24)
  &.under
    &::after
      transform translateY(-50%) rotate(90deg)
    +pc()
      &:hover
        opacity 1!important
        border-bottom 1px solid $thema-text-purple
        span
          color $thema-text-purple
        &::after
          top: 60%
          background url(../img/common/textlink_arrow-purple.svg) no-repeat
