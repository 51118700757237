/**
* filtering
**/
.filtering
  +pc()
    padding 40px 0 50px
    +pcLiquid()
      padding pcvw(@padding)
  +sp()
    padding vw(100 0)
  &-section
    +pc()
      padding 0 $side-margin_pc
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(0 $side-margin_sp)
    > h2
      font-family $NotoSans
      color $thema-text-black
      letter-spacing ls(50)
      text-align center
      +pc()
        font-size 1.8rem
        margin-bottom 5px
        +pcLiquid()
          font-size pcvw(18)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        font-size vw(32)
  &-container
    +pc()
      maxWidth(940px)
      +pcLiquid()
        max-width pcvw(940)
  &-inner
    +pc()
      display flex
      padding 25px 20px 5px // bottomは.filtering-select-box のmargin-bottom 20px分引いて、25-5=5px
      border-bottom 1px solid #cccccc
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(60 0 20)
      border-bottom vw(2) solid #cccccc
    &:last-of-type
      border-bottom 0
      +pc()
        margin-bottom 30px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(20)

  // 左の見出し
  &-type
    font-family $NotoSans
    font-weight bold
    letter-spacing ls(50)
    color $thema-text-black
    display flex
    align-items center
    +pc()
      font-size 1.6rem
      width 146px
      flex-shrink 0
      margin-bottom 20px // .filtering-select-box のmargin-bottom 20px分
      +pcLiquid()
        font-size pcvw(16)
        width pcvw(@width)
    +sp()
      font-size vw(30)
      margin-bottom vw(40)
    &::before
      content ''
      display block
      background-repeat no-repeat
      background-size contain
      +pc()
        margin-right 10px
        +pcLiquid()
          margin-right pcvw(@margin-right)
      +sp()
        margin-right vw(20)
    // ユニークスタイル
    &.-is-year
      &::before
        background-image url('../img/common/icon_calendar.svg')
        +pc()
          width 23px
          height 24px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(38)
          height vw(41)
    &.-is-person
      &::before
        background-image url('../img/common/icon_person.svg')
        +pc()
          width 24px
          height @width
          +pcLiquid()
            width pcvw(@width)
            height @width
        +sp()
          width vw(38)
          height @width
    &.-is-place
      &::before
        background-image url("../img/common/place_pin.svg")
        +pc()
          width 20px
          height 24px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(37)
          height vw(44)
    &.-is-category
      &::before
        background-image url('../img/common/icon_category.svg')
        +pc()
          width 21px
          height 24px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(39)
          height vw(45)
    &.-is-keyword
      margin-bottom 0
      &::before
        background-image url('../img/common/icon_search.svg')
        +pc()
          width 23px
          height 23px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(42)
          height vw(42)

  // 右のフィルタリングエリア
  &-select
    &-container
      display flex
      flex-wrap wrap
      &.-is-keyword
        width 100%
        align-items center
        .filtering-input-box
          width 100%
          +pc()
            padding-right 35px
            +pcLiquid()
              padding-right pcvw(@padding-right)
          input
            width 100%
            border-color #cccccc
            border-style solid
            border-radius 3px
            padding .2em .25em
            +sp()
              font-size vw(30)
              margin-top vw(20)
    &-box
      display flex
      align-items center
      +pc()
        padding-right 30px
        margin-bottom 20px
        +pcLiquid()
          padding-right pcvw(@padding-right)
          margin-bottom pcvw(@margin-bottom)
      +sp()
        padding-right vw(30)
        margin-bottom vw(40)
      input
        // ラジオボタン
        &[type=radio]
          display none
          & + label
            &::before
              border-radius 50%
          &:checked + label::before
            background-image url('../img/common/icon_check_radio.png')
            +pc()
              background-size 8px 8px
              +pcLiquid()
                background-size pcvw(@background-size)
            +sp()
              background-size vw(20 20)
        // チェックボックス
        &[type=checkbox]
          display none
          &:checked + label::after
            content ''
            display block
            background-repeat no-repeat
            background-size contain
            background-image url('../img/common/icon_check.png')
            position absolute
            top 0
            +pc()
              left 1px
              width 17px
              height 11px
              +pcLiquid()
                width pcvw(@width)
                height pcvw(@height)
            +sp()
              left vw(2)
              width vw(39)
              height vw(27)
      label
        font-family $NotoSans
        color $thema-text-black
        letter-spacing ls(50)
        display flex
        align-items center
        position relative
        +pc()
          font-size 1.4rem
          cursor pointer
          +pcLiquid()
            font-size pcvw(14)
        +sp()
          font-size vw(28)
        &::before
          content ''
          display block
          border 1px solid #707070
          box-sizing border-box
          background-repeat no-repeat
          background-position center center
          +pc()
            width 14px
            height @width
            margin-right 5px
            +pcLiquid()
              width pcvw(@width)
              height @width
              margin-right pcvw(@margin-right)
          +sp()
            width vw(36)
            height @width
            margin-right vw(10)

  .submit-button
    font-family $NotoSans
    color $white
    letter-spacing ls(50)
    display flex
    align-items center
    justify-content center
    background-color $thema-text-purple
    margin-left auto
    margin-right auto
    +pc()
      font-size 1.5rem
      width 180px
      height 40px
      border-radius 20px
      margin-top 10px
      hover-opacity()
      +pcLiquid()
        font-size pcvw(15)
        width pcvw(@width)
        height pcvw(@height)
        border-radius pcvw(@border-radius)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(30)
      width vw(500)
      height vw(100)
      border-radius vw(50)
      margin-top vw(40)
