/**
  base:

  .c-pagenav
    a.spOnly.js-accordionToggle.c-pagenav--pointer: span.c-pagenav__text menu-name
    ol.c-pagenav__wrap.answer
      li.c-pagenav__wrap__list: a(href="#"): span.c-pagenav__text label

 */

.c-pagenav
  background-color $thema-bg-lightGrey

  +pc()
    padding 10px 0
    -webkit-position sticky
    position sticky
    top 40px
    z-index 2

  .c-pagenav__wrap
    +pc()
      display flex !important // JS動作中にPCサイトに切り替わった場合に不具合が起きるため important指定する
      flex-wrap wrap
    a
      &.link-text
        &:before
          display none

        &[target="_blank"]:after
          +pc()
            width pcvw(15)
            height pcvw(14)
            margin-right 20px
            vertical-align sub

    +sp()
      display none

    .c-pagenav__wrap__list
      +pc()
        position relative
        margin 10px 0
        z-index 0

        // pc style border
        &::before
          content ''
          display block
          width calc(100% - 1px)
          height 100%
          position absolute
          top 0
          left 0
          border-right 1px solid #ccc
          border-left 1px solid #ccc
          z-index -1

      +sp()
        padding vw(30 40)
        border-top 1px solid #ccc
        position relative

        // sp style icon
        &::before
          content ''
          width vw(24)
          height vw(12)
          display block
          position absolute
          top 50%
          right vw(40)
          transform translateY(-50%) rotate(90deg)
          background-size vw(24 12)
          svgArrow($thema-text-purple)

          [data-faculty="bungakubu"] &
            svgArrow($color-bungakubu)

          [data-faculty="eiyokagakubu"] &
            svgArrow($color-eiyokagakubu)

          [data-faculty="jc"] &
            svgArrow($color-jc)

          [data-category="about"] &
            svgArrow($color-bungakubu)

  // open button (SP only)
  .c-pagenav--open-button
    cursor pointer
    padding vw(30 40)
    position relative
    color $thema-text-purple

    [data-faculty="bungakubu"] &
      color $color-bungakubu

    [data-faculty="eiyokagakubu"] &
      color $color-eiyokagakubu

    [data-faculty="jc"] &
      color $color-jc

    [data-category="about"] &
      color $color-bungakubu

    // override style
    .c-pagenav__text
      +sp()
        font-size vw(38)

    // icon
    &::before
      content ''
      width vw(24)
      height vw(12)
      display block
      position absolute
      top 50%
      right vw(40)
      transform translateY(-50%) scaleY(-1)
      background-size vw(24 12)
      svgArrow($thema-text-purple)
      transition transform 0.3s

      [data-faculty="bungakubu"] &
        svgArrow($color-bungakubu)

      [data-faculty="eiyokagakubu"] &
        svgArrow($color-eiyokagakubu)

      [data-faculty="jc"] &
        svgArrow($color-jc)

      [data-category="about"] &
        svgArrow($color-bungakubu)

    // icon anim
    &.opend
      &::before
        transform: scaleY(1)

  // text
  .c-pagenav__text
    color $thema-text-purple

    [data-faculty="bungakubu"] &
      color $color-bungakubu

    [data-faculty="eiyokagakubu"] &
      color $color-eiyokagakubu

    [data-faculty="jc"] &
      color $color-jc

    +pc()
      font-size 14px
      padding 0 20px
      display inline-block

    +pcLiquid()
      font-size pcvw(14)

    +sp()
      font-size vw(28)

    &.-expand_0
      padding-right 0


[data-faculty="bungakubu"],
[data-faculty="eiyokagakubu"],
[data-faculty="jc"]
  &.pages
    overflow visible



// SPフッターローカルナビ
.block-page_index
  background #000
  background-color $thema-bg-lightGrey
  +pc()
    padding 54px 70px 57px
    +pcLiquid()
      padding pcvw(@padding)
  +sp()
    padding vw(72 40)
  .heading4
    color $thema-text-purple
    +pc()
      margin-bottom 20px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
    +sp()
      margin-bottom vw(40)

  .list-page_index
    li
      +pc()
        display inline-block
      &:not(:last-of-type)
        +pc()
          margin-right 40px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(20)
      a
        font-weight normal
        color $color
        &.current
          border-bottom 1px solid $thema-text-purple
  .list-page_index
    a
      &::before
        background url('../img/common/textlink_arrow-purple.svg') no-repeat

