[data-category="career"]
  .page-header
    +pc()
      background-image url('../img/career/header_bg.jpg')
    +sp()
      background-image url('../img/career/header_bg@2x.jpg')



[data-pages="career-employment-center"]
  .link-content
    text-align right
    .link-container
      cmn-arrow-link($thema-text-purple)
