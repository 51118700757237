$thisPage = "event"
$bgPath = '../img/' + $thisPage + '/'

[data-pages={'"'+$thisPage+'"'}]

  /**
  * common
  **/
  .page-header
    +pc()
      background-image url($bgPath + 'header_bg.jpg')
    +sp()
      background-image url($bgPath + 'header_bg@2x.jpg')


  /**
  * content
  **/
  .content
    background-color $thema-bg-lightGrey
    +pc()
      padding 70px 0 165px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(100 0 250)
    &-section
      +pc()
        padding 0 50px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(0 $side-margin_sp)
    &-container
      +pc()
        maxWidth($width_pc01 px)
        +pcLiquid()
          max-width pcvw($width_pc01)

    // 新着3件のレイアウト
    &-latest-container
      +pc()
        display flex
        margin-bottom 50px
      +sp()
        margin-bottom vw(80)
      li
        +pc()
          width 'calc(33.33% - %spx)' % (60 / 3)
          +pcLiquid()
            width 'calc(33.33% - %s)' % pcvw(60 / 3)
        & + li
          +pc()
            margin-left 30px
            +pcLiquid()
              margin-left pcvw(@margin-left)
            &:first-of-type
              margin-left 0
          +sp()
            margin-top vw(80)

    // 3件目以降のニュース
    &-archive
      &-list
        & + li
          +pc()
            margin-top 20px
            +pcLiquid()
              margin-top pcvw(@margin-top)
          +sp()
            margin-top vw(40)
        a
          color $thema-text-black
          background-color $white
          +pc()
            display flex
            padding 30px 20px 30px 10px
            hover-opacity()
            +pcLiquid()
              padding pcvw(@padding)
          +sp()
            display block
            padding vw(40)
      &-header
        +pc()
          width 185px
          display flex
          justify-content center
          align-items center
          flex-shrink 0
          border-right 1px solid #cccccc
          margin-right 40px
          +pcLiquid()
            width pcvw(@width)
            margin-right pcvw(@margin-right)
        +sp()
          padding-bottom vw(30)
          border-bottom vw(4) solid #cccccc
        &-inner
          +sp()
            display flex
      &-date
        @extend .event-contnt-block-date
        & + .content-archive-date
          &::before
            position relative
            +pc()
              content '|'
              font-size 1.8rem
              text-align center
              display block
              margin .5rem auto
              +pcLiquid()
                font-size pcvw(18)
            +sp()
              content 'ー'
              font-size vw(38)
              display inline-block
              margin 0 1rem
              top -1rem
        .month
          +pc()
            font-size 3rem
            +pcLiquid()
              font-size pcvw(30)
          +sp()
            font-size vw(46)
          &::after
            +pc()
              font-size 2.4rem
              +pcLiquid()
                font-size pcvw(24)
            +sp()
              font-size vw(36)
        .day
          +pc()
            font-size 4rem
            +pcLiquid()
              font-size pcvw(40)
          +sp()
            font-size vw(104)
        .week
          font-family $NotoSans
          +pc()
            font-size 1.8rem
            +pcLiquid()
              font-size pcvw(18)
          +sp()
            font-size vw(34)
        .-is-comingsoon
          font-family $NotoSans
          +pc()
            font-size 1.8rem
            line-height round(40 / 18, 2)
            +pcLiquid()
              font-size pcvw(16)
          +sp()
            font-size vw(34)
            line-height round(104 / 34, 2)
      &-title
        @extend .event-contnt-block-heading
        +pc()
          font-size 1.6rem
          line-height round(26 / 16, 2)
          margin 0 0 20px
          +pcLiquid()
            font-size pcvw(16)
            margin pcvw(@margin)
        +sp()
          margin vw(30) 0 vw(40)
      &-data-inner
        +pc()
          display flex
          align-items center
      &-category
        @extend .event-contnt-block-category
        margin-top 0 !important
      &-place
        @extend .event-contnt-block-place
        +pc()
          margin-left 30px
          margin-top 0
          +pcLiquid()
            margin-left pcvw(@margin-left)
            margin-top 0
        +sp()
          margin-top vw(20)
