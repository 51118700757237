[data-category="faculty"]
  .page-header
    text-align center
  .page-heading
    &-faculty
      color $thema-text-purple
      letter-spacing ls(100)
      display block
      text-align center
      +pc()
        margin-bottom 20px
        font-size 2.4rem
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
          font-size pcvw(24)
      +sp()
        font-size vw(32)
        margin-bottom vw(25)
