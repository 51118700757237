// -------INDEX-------
// Margin
// Container
// Heading
// Font-size
// Paragraph
// Link
// Anchorlink
// Button
// Accordion
// Tab
// Table
// Order list
// 奨学金その他ページのプロセス
// バナー
// 入試情報
// お問い合わせ先
// 学生会館
// デジタルパンフレット
// 入試情報h1背景
// --------------------

.ui-template
// <article>に.ui-templateを付与して適用させる
  // UIKIT用の変数、汎用クラスの読み込み
  @import './_uikitModules'
  @import './_uikitFacultyModules'
  background $white
  *
    font-family $NotoSans
  +pc()
    padding-top $mgn-lv3
    padding-bottom 130px
    +pcLiquid()
      padding-top pcvw($mgn-lv3)
      padding-bottom pcvw(@padding-bottom)
  +sp()
    padding-top vw($mgn-lv3_1)
    padding-right vw($mgn-lv2)
    padding-left vw($mgn-lv2)
    padding-bottom vw(260)

  // Margin
  .margin
    // bottom
    &-lv1
      margin-bottom $mgn-lv1
      +pcLiquid()
        margin-bottom pcvw($mgn-lv1)
      +sp()
        margin-bottom vw($mgn-sp-lv1)
      &_1
        margin-bottom $mgn-lv1_1
        +pcLiquid()
          margin-bottom pcvw($mgn-lv1_1)
        +sp()
          margin-bottom vw($mgn-sp-lv1_1)
    &-lv2
      margin-bottom $mgn-lv2
      +pcLiquid()
        margin-bottom pcvw($mgn-lv2)
      +sp()
        margin-bottom vw($mgn-sp-lv2)
    &-lv3
      margin-bottom $mgn-lv3
      +pcLiquid()
        margin-bottom pcvw($mgn-lv3)
      +sp()
        margin-bottom vw($mgn-sp-lv3)
      &_1
        margin-bottom $mgn-lv3_1
        +pcLiquid()
          margin-bottom pcvw($mgn-lv3_1)
        +sp()
          margin-bottom vw($mgn-sp-lv3_1)
    &-lv4
      margin-bottom $mgn-lv4
      +pcLiquid()
        margin-bottom pcvw($mgn-lv4)
      +sp()
        margin-bottom vw($mgn-sp-lv4)
    &-lv5
      margin-bottom $mgn-lv5
      +pcLiquid()
        margin-bottom pcvw($mgn-lv5)
      +sp()
        margin-bottom vw($mgn-sp-lv5)
    &-lv6
      margin-bottom $mgn-lv6
      +pcLiquid()
        margin-bottom pcvw($mgn-lv6)
      +sp()
        margin-bottom vw($mgn-sp-lv6)

    // bottom( PC )
    &-lv1-pc
      +pc()
        margin-bottom $mgn-lv1
        +pcLiquid()
          margin-bottom pcvw($mgn-lv1)
      &_1
        +pc()
          margin-bottom $mgn-lv1_1
          +pcLiquid()
            margin-bottom pcvw($mgn-lv1_1)
    &-lv2-pc
      +pc()
        margin-bottom $mgn-lv2
        +pcLiquid()
          margin-bottom pcvw($mgn-lv2)
    &-lv3-pc
      +pc()
        margin-bottom $mgn-lv3
        +pcLiquid()
          margin-bottom pcvw($mgn-lv3)
      &_1
        +pc()
          margin-bottom $mgn-lv3_1
          +pcLiquid()
            margin-bottom pcvw($mgn-lv3_1)
    &-lv4-pc
      +pc()
        margin-bottom $mgn-lv4
        +pcLiquid()
          margin-bottom pcvw($mgn-lv4)
    &-lv5-pc
      +pc()
        margin-bottom $mgn-lv5
        +pcLiquid()
          margin-bottom pcvw($mgn-lv5)
    &-lv6-pc
      +pc()
        margin-bottom $mgn-lv6
        +pcLiquid()
          margin-bottom pcvw($mgn-lv6)

    //spで値を書き換える場合
    &-sp
      &-lv1
        +sp()
          margin-bottom vw($mgn-lv1)
      &-lv2
        +sp()
          margin-bottom vw($mgn-lv2)
      &-lv3
        +sp()
          margin-bottom vw($mgn-lv3)
      &-lv4
        +sp()
          margin-bottom vw($mgn-lv4)
      &-lv5
        +sp()
          margin-bottom vw($mgn-lv5)
      &-lv6
        +sp()
          margin-bottom vw($mgn-lv6)
    // right
    &-lv1-right
      margin-right $mgn-lv1
      +pcLiquid()
        margin-right pcvw($mgn-lv1)
      +sp()
        margin-right vw($mgn-lv1)
    &-lv2-right
      margin-right $mgn-lv2
      +pcLiquid()
        margin-right pcvw($mgn-lv2)
      +sp()
        margin-right vw($mgn-lv2)
    &-lv3-right
      margin-right $mgn-lv3
      +pcLiquid()
        margin-right pcvw($mgn-lv3)
      +sp()
        margin-right vw($mgn-lv3)
    &-lv4-right
      margin-right $mgn-lv4
      +pcLiquid()
        margin-right pcvw($mgn-lv4)
      +sp()
        margin-right vw($mgn-lv4)
    &-lv5-right
      margin-right $mgn-lv5
      +pcLiquid()
        margin-right pcvw($mgn-lv5)
      +sp()
        margin-right vw($mgn-lv5)
    &-lv6-right
      margin-right $mgn-lv6
      +pcLiquid()
        margin-right pcvw($mgn-lv6)
      +sp()
        margin-right vw($mgn-lv6)
    // right( PC )
    &-lv1-right-pc
      +pc()
        margin-right $mgn-lv1
        +pcLiquid()
          margin-right pcvw($mgn-lv1)
    &-lv2-right-pc
      +pc()
        margin-right $mgn-lv2
        +pcLiquid()
          margin-right pcvw($mgn-lv2)
    &-lv3-right-pc
      +pc()
        margin-right $mgn-lv3
        +pcLiquid()
          margin-right pcvw($mgn-lv3)
    &-lv4-right-pc
      +pc()
        margin-right $mgn-lv4
        +pcLiquid()
          margin-right pcvw($mgn-lv4)
    &-lv5-right-pc
      +pc()
        margin-right $mgn-lv5
        +pcLiquid()
          margin-right pcvw($mgn-lv5)
    &-lv6-right-pc
      +pc()
        margin-right $mgn-lv6
        +pcLiquid()
          margin-right pcvw($mgn-lv6)
    // left
    &-lv1-left
      margin-left $mgn-lv1
      +pcLiquid()
        margin-left pcvw($mgn-lv1)
      +sp()
        margin-left vw($mgn-lv1)
    &-lv2-left
      margin-left $mgn-lv2
      +pcLiquid()
        margin-left pcvw($mgn-lv2)
      +sp()
        margin-left vw($mgn-lv2)
    &-lv3-left
      margin-left $mgn-lv3
      +pcLiquid()
        margin-left pcvw($mgn-lv3)
      +sp()
        margin-left vw($mgn-lv3)
    &-lv4-left
      margin-left $mgn-lv4
      +pcLiquid()
        margin-left pcvw($mgn-lv4)
      +sp()
        margin-left vw($mgn-lv4)
    &-lv5-left
      margin-left $mgn-lv5
      +pcLiquid()
        margin-left pcvw($mgn-lv5)
      +sp()
        margin-left vw($mgn-lv5)
    &-lv6-left
      margin-left $mgn-lv6
      +pcLiquid()
        margin-left pcvw($mgn-lv6)
      +sp()
        margin-left vw($mgn-lv6)
    // left( PC )
    &-lv1-left-pc
      +pc()
        margin-left $mgn-lv1
        +pcLiquid()
          margin-left pcvw($mgn-lv1)
    &-lv2-left-pc
      +pc()
        margin-left $mgn-lv2
        +pcLiquid()
          margin-left pcvw($mgn-lv2)
    &-lv3-left-pc
      +pc()
        margin-left $mgn-lv3
        +pcLiquid()
          margin-left pcvw($mgn-lv3)
    &-lv4-left-pc
      +pc()
        margin-left $mgn-lv4
        +pcLiquid()
          margin-left pcvw($mgn-lv4)
    &-lv5-left-pc
      +pc()
        margin-left $mgn-lv5
        +pcLiquid()
          margin-left pcvw($mgn-lv5)
    &-lv6-left-pc
      +pc()
        margin-left $mgn-lv6
        +pcLiquid()
          margin-left pcvw($mgn-lv6)
    // top
    &-lv1-top
      margin-top $mgn-lv1
      +pcLiquid()
        margin-top pcvw($mgn-lv1)
      +sp()
        margin-top vw($mgn-lv1)
    &-lv2-top
      margin-top $mgn-lv2
      +pcLiquid()
        margin-top pcvw($mgn-lv2)
      +sp()
        margin-top vw($mgn-lv2)
    &-lv3-top
      margin-top $mgn-lv3
      +pcLiquid()
        margin-top pcvw($mgn-lv3)
      +sp()
        margin-top vw($mgn-lv3)
    &-lv4-top
      margin-top $mgn-lv4
      +pcLiquid()
        margin-top pcvw($mgn-lv4)
      +sp()
        margin-top vw($mgn-lv4)
    &-lv5-top
      margin-top $mgn-lv5
      +pcLiquid()
        margin-top pcvw($mgn-lv5)
      +sp()
        margin-top vw($mgn-lv5)
    &-lv6-top
      margin-top $mgn-lv6
      +pcLiquid()
        margin-top pcvw($mgn-lv6)
      +sp()
        margin-top vw($mgn-lv6)

  // Padding
  .padding
    // bottom
    &-lv1
      padding-bottom $mgn-lv1
      +pcLiquid()
        padding-bottom pcvw($mgn-lv1)
      +sp()
        padding-bottom vw($mgn-sp-lv1)
      &_1
        padding-bottom $mgn-lv1_1
        +pcLiquid()
          padding-bottom pcvw($mgn-lv1_1)
        +sp()
          padding-bottom vw($mgn-sp-lv1_1)
    &-lv2
      padding-bottom $mgn-lv2
      +pcLiquid()
        padding-bottom pcvw($mgn-lv2)
      +sp()
        padding-bottom vw($mgn-sp-lv2)
    &-lv3
      padding-bottom $mgn-lv3
      +pcLiquid()
        padding-bottom pcvw($mgn-lv3)
      +sp()
        padding-bottom vw($mgn-sp-lv3)
      &_1
        padding-bottom $mgn-lv3_1
        +pcLiquid()
          padding-bottom pcvw($mgn-lv3_1)
        +sp()
          padding-bottom vw($mgn-sp-lv3_1)
    &-lv4
      padding-bottom $mgn-lv4
      +pcLiquid()
        padding-bottom pcvw($mgn-lv4)
      +sp()
        padding-bottom vw($mgn-sp-lv4)
    &-lv5
      padding-bottom $mgn-lv5
      +pcLiquid()
        padding-bottom pcvw($mgn-lv5)
      +sp()
        padding-bottom vw($mgn-sp-lv5)
    &-lv6
      padding-bottom $mgn-lv6
      +pcLiquid()
        padding-bottom pcvw($mgn-lv6)
      +sp()
        padding-bottom vw($mgn-sp-lv6)
    // right
    &-lv1-right
      padding-right $mgn-lv1
      +pcLiquid()
        padding-right pcvw($mgn-lv1)
      +sp()
        padding-right vw($mgn-lv1)
    &-lv2-right
      padding-right $mgn-lv2
      +pcLiquid()
        padding-right pcvw($mgn-lv2)
      +sp()
        padding-right vw($mgn-lv2)
    &-lv3-right
      padding-right $mgn-lv3
      +pcLiquid()
        padding-right pcvw($mgn-lv3)
      +sp()
        padding-right vw($mgn-lv3)
    &-lv4-right
      padding-right $mgn-lv4
      +pcLiquid()
        padding-right pcvw($mgn-lv4)
      +sp()
        padding-right vw($mgn-lv4)
    &-lv5-right
      padding-right $mgn-lv5
      +pcLiquid()
        padding-right pcvw($mgn-lv5)
      +sp()
        padding-right vw($mgn-lv5)
    &-lv6-right
      padding-right $mgn-lv6
      +pcLiquid()
        padding-right pcvw($mgn-lv6)
      +sp()
        padding-right vw($mgn-lv6)
    // top
    &-lv1-top
      padding-top $mgn-lv1
      +pcLiquid()
        padding-top pcvw($mgn-lv1)
      +sp()
        padding-top vw($mgn-lv1)
    &-lv2-top
      padding-top $mgn-lv2
      +pcLiquid()
        padding-top pcvw($mgn-lv2)
      +sp()
        padding-top vw($mgn-lv2)
    &-lv3-top
      padding-top $mgn-lv3
      +pcLiquid()
        padding-top pcvw($mgn-lv3)
      +sp()
        padding-top vw($mgn-lv3)
    &-lv4-top
      padding-top $mgn-lv4
      +pcLiquid()
        padding-top pcvw($mgn-lv4)
      +sp()
        padding-top vw($mgn-lv4)
    &-lv5-top
      padding-top $mgn-lv5
      +pcLiquid()
        padding-top pcvw($mgn-lv5)
      +sp()
        padding-top vw($mgn-lv5)
    &-lv6-top
      padding-top $mgn-lv6
      +pcLiquid()
        padding-top pcvw($mgn-lv6)
      +sp()
        padding-top vw($mgn-lv6)

    //spで値を書き換える場合
    &-sp
      &-lv0
        +sp()
          padding-bottom vw(0)
      &-lv1
        +sp()
          padding-bottom vw($mgn-lv1)
      &-lv2
        +sp()
          padding-bottom vw($mgn-lv2)
      &-lv3
        +sp()
          padding-bottom vw($mgn-lv3)
      &-lv4
        +sp()
          padding-bottom vw($mgn-lv4)
      &-lv5
        +sp()
          padding-bottom vw($mgn-lv5)
      &-lv6
        +sp()
          padding-bottom vw($mgn-lv6)
      // top
      &-lv1-top
        +sp()
          padding-top vw($mgn-lv1)
      &-lv2-top
        +sp()
          padding-top vw($mgn-lv2)
      &-lv3-top
        +sp()
          padding-top vw($mgn-lv3)
      &-lv4-top
        +sp()
          padding-top vw($mgn-lv4)
      &-lv5-top
        +sp()
          padding-top vw($mgn-lv5)
      &-lv6-top
        +sp()
          padding-top vw($mgn-lv6)

  // Container
  .container
    margin-right auto
    margin-left auto
    &.-limit
      width 100%
      +pc()
        max-width $limit
        +pcLiquid()
          max-width pcvw($limit)
    &.-wide
      width 100%
      +pc()
        max-width $wide
        +pcLiquid()
          max-width pcvw($wide)
    &.-mid
      width 100%
      +pc()
        max-width $mid
        +pcLiquid()
          max-width pcvw($mid)
    &.-sml
      width 100%
      +pc()
        max-width $sml
        +pcLiquid()
          max-width pcvw($sml)
    &.-col2,
    &.-col3,
    &.-col4,
    &.-col6
      display flex
    &.-col2
      .box
        &:not(:last-of-type)
          +pc()
            margin-right $mgn-lv2
            +pcLiquid()
              margin-right pcvw($mgn-lv2)
          +sp()
            margin-right vw($mgn-lv2)
      .text
        width 70%
      .img
        width: 30%
    &.-col3
      &.-limit
        .box
          &:not(:last-of-type)
            +pc()
              margin-right $mgn-lv1_1
              +pcLiquid()
                  margin-right pcvw($mgn-lv1_1)
            +sp()
                margin-right vw($mgn-lv1_1)
      &.-wide
        .box
          &:not(:last-of-type)
            +pc()
              margin-right $mgn-lv1
              +pcLiquid()
                  margin-right pcvw($mgn-lv1)
            +sp()
                margin-right vw($mgn-lv1)
      .box
        width calc(100% / 3)
    &.-col4
      &.-wide
        .box
          &:not(:last-of-type)
            +pc()
              margin-right $mgn-lv1
              +pcLiquid()
                  margin-right pcvw($mgn-lv1)
            +sp()
                margin-right vw($mgn-lv1)
      .box
        width calc(100% / 4)
    &.-col6
      &.-wide
        .box
          &:not(:last-of-type)
            margin-right $mgn-lv1
      .box
        width calc(100% / 6)
  // Heading
  .page-title
    color $theme-color
    text-align center
    position relative
    +pc()
      margin-bottom 80px
      padding-bottom 25px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
        padding-bottom pcvw(@padding-bottom)
    +sp()
      margin-bottom vw(85)
      padding-bottom vw(40)
    &:after
      content ""
      display block
      background $theme-color
      @extend .abs_centerX
      bottom 0
      +pc()
        width 50px
        height 1px
      +sp()
        width vw(60)
        height 2px
  [class^="heading"]
    &:not(.bold)
      font-weight normal
    &.withBorder
      position relative
      +pc()
        margin-bottom $mgn-lv2
        padding-bottom $mgn-lv1
        +pcLiquid()
          margin-bottom pcvw($mgn-lv2)
          padding-bottom pcvw($mgn-lv1)
      +sp()
        margin-bottom vw($mgn-lv2)
        padding-bottom vw($mgn-lv1)
      &:before
        content ""
        display block
        height 2px
        background $theme-color
        position absolute
        left 0
        bottom 0
        z-index 1
        +pc()
          width 200px
          +pcLiquid()
            width pcvw(@width)
        +sp()
          width vw(215)
          height vw(4)

      &:after
        content ""
        display block
        width 100%
        height 2px
        background $border-grey
        position absolute
        left 0
        bottom 0
        +sp()
          height vw(4)
    &.withBlock
      position relative
      +pc()
        padding-left 16px
        +pcLiquid()
          padding-left pcvw(@padding-left)
      +sp()
          padding-left vw(25)
      &:before
        content ""
        display block
        background $theme-color
        @extend .abs_centerY
        left 0
        +pc()
          width 4px
          height 22px
          margin-top 2px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
            margin-top pcvw(@margin-top)
        +sp()
          width vw(6)
          height vw(36)
          margin-top vw(2)

  .heading1
    line-height 1.3
    letter-spacing 0.1em
    +pc()
      font-size 40px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(50)
  .heading2
    line-height 1.4
    letter-spacing 0.05em
    +pc()
      font-size 30px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(42)
  .heading3
    line-height 1.35
    letter-spacing 0.05em
    +pc()
      font-size 24px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(38)
  .heading4
    line-height 1.35
    letter-spacing 0.05em
    +pc()
      font-size 20px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(32)
    &.withIcon_PC
      &:before
        content ""
        display inline-block
        background url('../img/common/icon_pc.png') no-repeat
        background-position center
        background-size contain
        margin-top -0.1em
        vertical-align middle
        +pc()
          width 40px
          height 29px
          margin-right 10px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
            margin-right pvw(@margin-right)
        +sp()
          width vw(56)
          height vw(41)
          margin-right vw(10)
    &.withIcon_phone
      &:before
        content ""
        display inline-block
        background url('../img/common/icon_phone.png') no-repeat
        background-position center
        background-size contain
        margin-top -0.1em
        vertical-align middle
        +pc()
          width 26px
          height 30px
          margin-right 10px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
            margin-right pvw(@margin-right)
        +sp()
          width vw(35)
          height vw(41)
          margin-right vw(10)
    &.withIcon_hat
      &:before
        content ""
        display inline-block
        background url('../img/common/icon_hat.png') no-repeat
        background-position center
        background-size contain
        vertical-align middle
        +pc()
          width 44px
          height 31px
          margin-top -0.1em
          margin-right 10px
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
            margin-right pvw(@margin-right)
        +sp()
          width vw(58)
          height vw(41)
          margin-top -0.15em
          margin-right vw(10)
  .heading5
    line-height 1.37
    letter-spacing 0.05em
    +pc()
      font-size 16px
      +pcLiquid()
        font-size pcvw(@font-size)
    +sp()
      font-size vw(28)
  // Font-size
  .base
    font-size 15px
    +pcLiquid()
      font-size pcvw(15)
    +sp()
      font-size vw(28)
    &.-center
      text-align center
    &.-right
      text-align right
  .lead
    font-size 18px
    +pcLiquid()
      font-size pcvw(18)
    +sp()
      font-size vw(32)
    &.-center
      text-align center
    &.-right
      text-align right
  .small
    font-size 14px
    +pcLiquid()
      font-size pcvw(14)
    +sp()
      font-size vw(24)
    &.-center
      text-align center
    &.-right
      text-align right
  // Paragraph
  .paragraph
    &.base
      line-height 2
      letter-spacing  0.05em
      +sp()
        line-height 1.85
    &.lead
      line-height 2
      letter-spacing  0.05em
      +sp()
        line-height 1.87
    &.small
      line-height 1.7
      +sp()
        line-height 2
  // Link
  a
    color $thema-text-purple
    transition all .2s
    &[class^="link-"]
      font-weight bold
      +pc()
        &:hover
          opacity .6
    &.link-intext
      border-bottom 1px solid $thema-text-purple
      +pc()
        padding-bottom 2px
        +pcLiquid()
          padding-bottom pcvw(@padding-bottom)
        &:hover
          border-bottom 1px solid transparent
      +sp()
        border-bottom 2px solid $thema-text-purple
        padding-bottom vw(5)
    &.link-text
      +sp()
        line-height 1.4
      &:before
        content ""
        display inline-block
        width 8px
        height 12px
        background url('../img/common/textlink_arrow.png') no-repeat
        background-position center
        background-size contain
        margin-right 8px
        +sp()
          width vw(16)
          height vw(24)
          margin-right vw(10)
      &[target="_blank"]
        &:after
          content ""
          display inline-block
          width 15px
          height 14px
          background url('../img/common/icon_tab.svg') no-repeat
          background-position center
          background-size contain
          margin-left 8px
          +sp()
            width vw(30)
            height vw(29)
            margin-left vw(10)
    &.link-pdf
      span
        display inline-block
        vertical-align middle
      &.indent-adjust
        display block
        text-indent -1em
        padding-left 1em
      &:before
        content ""
        display inline-block
        width 8px
        height 12px
        background url('../img/common/textlink_arrow.png') no-repeat
        background-position center
        background-size contain
        margin-right 8px
        vertical-align middle
        +sp()
          width vw(16)
          height vw(24)
          margin-right vw(10)
      &:after
        content ""
        display inline-block
        width 19px
        height 18px
        background url('../img/common/icon_pdf.png') no-repeat
        background-position center
        background-size contain
        margin-left 8px
        vertical-align middle
        +sp()
          width vw(41)
          height vw(40)
          margin-left vw(10)
    &.link-word
      span
        display inline-block
        vertical-align middle
      &.indent-adjust
        display block
        text-indent -1em
        padding-left 1em
      &:before
        content ""
        display inline-block
        width 8px
        height 12px
        background url('../img/common/textlink_arrow.png') no-repeat
        background-position center
        background-size contain
        margin-right 8px
        vertical-align middle
        +sp()
          width vw(16)
          height vw(24)
          margin-right vw(10)
      &:after
        content ""
        display inline-block
        width 22px
        height 18px
        background url('../img/common/icon_word.svg') no-repeat
        background-position center
        background-size contain
        margin-left 8px
        vertical-align middle
        +sp()
          width vw(49)
          height vw(40)
          margin-left vw(10)
    &.link-excel
      span
        display inline-block
        vertical-align middle
      &.indent-adjust
        display block
        text-indent -1em
        padding-left 1em
      &:before
        content ""
        display inline-block
        width 8px
        height 12px
        background url('../img/common/textlink_arrow.png') no-repeat
        background-position center
        background-size contain
        margin-right 8px
        vertical-align middle
        +sp()
          width vw(16)
          height vw(24)
          margin-right vw(10)
      &:after
        content ""
        display inline-block
        width 22px
        height 18px
        background url('../img/common/icon_excel.svg') no-repeat
        background-position center
        background-size contain
        margin-left 8px
        vertical-align middle
        +sp()
          width vw(49)
          height vw(40)
          margin-left vw(10)
  // Anchorlink
  .anchorlinkL-container
    display flex
    +sp()
      flex-wrap wrap
    &._col6
      .anchorlink
        +pc()
          width calc(100% / 6)
    &._col5
      .anchorlink
        +pc()
          width calc(100% / 5)
    &._col4
      .anchorlink
        +pc()
          width calc(100% / 4)
    &._col3
      .anchorlink
        +pc()
          width calc(100% / 3)
    .anchorlink
      color $thema-text-purple
      line-height 1.2
      position relative
      text-align center
      +pc()
        height 60px
        font-size 15px
        +pcLiquid()
          height pcvw(@height)
          min-height 50px
          font-size pcvw(@font-size)
        &:before
          transition background-color .3s
        &:after
          transition background .3s, transform .3s
        &:hover
          &:before
            background-color $theme-color
          &:after
            background url('../img/common/textlink_arrow_downward.png') no-repeat
            background-position center
            background-size contain
            transform translate(-50%, 30%)
          span
            color $theme-color
      +sp()
        width 46.6%
        height vw(100)
        font-size vw(28)
        margin-bottom vw(40)
        // SPで2行になる場合
        &.line2-adjust
          +sp()
            height vw(115)
      &:not(:last-of-type)
        +pc()
          margin-right 20px
          +pcLiquid()
            margin-right pcvw(@margin-right)
      &:nth-of-type(odd)
        +sp()
          margin-right vw(40)
      &:before
        position absolute
        content ''
        width 100%
        height 1px
        background-color $border-grey
        @extend .abs_centerX
        bottom 0
        +sp()
          height 2px
      &:after
        content ""
        display block
        width 12px
        height 8px
        background url('../img/common/textlink_arrow_downward_grey.png') no-repeat
        background-position center
        background-size contain
        @extend .abs_centerX
        bottom 10px
        +sp()
          width vw(24)
          height vw(16)
          bottom vw(20)
      span
        display flex
        align-items center
        justify-content center
        width 100%
        color $black
        +pc()
          height 35px
          +pcLiquid()
            height pcvw(@height)
        +sp()
          height vw(60)
  .anchorlinkS-container
    display flex
    flex-wrap wrap
    justify-content center
    &.noflex
      display block
    .anchorlink
      color $black
      line-height 1.2
      text-align center
      +pc()
        font-size 15px
        margin-bottom 10px
        padding 4px
        +pcLiquid()
          font-size pcvw(15)
          margin-bottom pcvw(10)
        &:after
          content ""
          display inline-block
          width 12px
          height 8px
          background url('../img/common/textlink_arrow_downward_grey.png') no-repeat
          background-position center
          background-size contain
          margin-left 4px
          vertical-align middle
        &:hover
          background $theme-color
          color $white
          &:after
            background url('../img/common/textlink_arrow_downward_white.png') no-repeat
            background-position center
            background-size contain
      +sp()
        font-size vw(24)
        margin-bottom vw(60)
        &:after
          content ""
          display inline-block
          width vw(24)
          height vw(16)
          background url('../img/common/textlink_arrow_downward_grey.png') no-repeat
          background-position center
          background-size contain
          margin-left vw(7)
          vertical-align middle
      &.-left
        text-align left
      &:not(:last-of-type)
        +pc()
          margin-right 10px
          margin-left 10px
          +pcLiquid()
            margin-right pcvw(@margin-right)
            margin-left pcvw(@margin-left)
      // SP2列時 左側のアンカーリンクにmargin-right追加
      &.mgnR-sp
        +sp()
          margin-right vw($mgn-lv2)
      // 末尾が全角スペースの場合アイコン位置調整
      &.nospace
        +pc()
          &:after
            margin-left -6px
        +pcLiquid()
          &:after
            margin-left pcvw(-6)
        +sp()
          &:after
            margin-left vw(-5)

  // Button
  .buttonlink-box
    +pc()
      visibility hidden
      &.ready
        visibility visible
  .buttonlink
    // _link.styl line54 通常はテーマカラー（紫）
    button-arrow-link($theme-color)
    &.green
      button-arrow-link($green)
    &.blue
      button-arrow-link($blue)

  // Accordion Q&A
  .accordion-container
    .accordion
      background $bg-grey
      +pc()
        padding-top $mgn-lv2
        padding-right $mgn-lv2
        padding-left $mgn-lv2
        +pcLiquid()
          padding-top pcvw($mgn-lv2)
          padding-right pcvw($mgn-lv2)
          padding-left pcvw($mgn-lv2)
      +sp()
        padding-top vw($mgn-lv2)
        padding-right vw($mgn-lv2)
        padding-left vw($mgn-lv2)
      &:not(:last-of-type)
        +pc()
          margin-bottom $mgn-lv2
          +pcLiquid()
            margin-bottom pcvw($mgn-lv2)
        +sp()
          margin-bottom vw($mgn-lv2)
      .question,.answer
        p
          position relative
          +pc()
            padding-left $mgn-lv2
            padding-right $mgn-lv3
            +pcLiquid()
              padding-left pcvw($mgn-lv2)
              padding-right pcvw($mgn-lv3)
          +sp()
            padding-left vw($mgn-lv3)
            padding-right vw($mgn-lv3)
      .question
        cursor pointer
        position relative
        +pc()
          padding-bottom $mgn-lv2
          +pcLiquid()
            padding-bottom pcvw($mgn-lv2)
        +sp()
          padding-bottom vw($mgn-lv2)
        &:before,
        &:after
          content ""
          background $theme-color
          @extend .abs_centerY
          right 0
          +pc()
            width 20px
            height 3px
            margin-top -20px
            +pcLiquid()
              margin-top pcvw(-20)
          +sp()
            width vw(20)
            height vw(4)
            margin-top vw(-20)
        &:after
          transform translateY(-50%) rotate(90deg)
          transition .5s
        &.opend
          &:after
            transform translateY(-50%) rotate(0)
        p
          color $theme-color
          line-height 1.6
          &:before
            content "Q"
            display inline-block
            color $theme-color
            font-family $Roboto
            font-size 40px
            font-weight bold
            line-height 1
            position absolute
            left 0
            +pc()
              top -0.15em
              +pcLiquid()
                font-size pcvw(40)
            +sp()
              font-size vw(64)
              top -0.05em
      .answer
        display none
        border-top 1px solid $border-grey
        +pc()
          padding-bottom $mgn-lv2
          +pcLiquid()
            padding-bottom pcvw($mgn-lv2)
        +sp()
          padding-bottom vw($mgn-lv2)
        p
          line-height 1.9
          +pc()
            margin-top $mgn-lv2
            +pcLiquid()
              margin-top pcvw($mgn-lv2)
          +sp()
            margin-top vw($mgn-lv2)
            padding-right 0
          &:before
            content "A"
            display inline-block
            font-family $Roboto
            font-size 40px
            font-weight bold
            line-height 1
            position absolute
            left 0
            +pc()
              top -0.15em
              +pcLiquid()
                font-size pcvw(40)
            +sp()
              font-size vw(64)
              top -0.05em

  // Accordion Q&Aじゃない
  .accordion-container-basic
    .accordion
      +pc()
        padding-top $mgn-lv2
        padding-right $mgn-lv2
        padding-left $mgn-lv2
        +pcLiquid()
          padding-top pcvw($mgn-lv2)
          padding-right pcvw($mgn-lv2)
          padding-left pcvw($mgn-lv2)
      +sp()
        padding-top vw($mgn-lv2)
        padding-right vw($mgn-lv2)
        padding-left vw($mgn-lv2)
      .question
        p
          position relative
          +pc()
            padding-right $mgn-lv3
            +pcLiquid()
              padding-right pcvw($mgn-lv3)
          +sp()
            padding-right vw($mgn-lv3)
      .answer
        p
          position relative
      .question
        cursor pointer
        position relative
        +pc()
          padding-bottom $mgn-lv2
          +pcLiquid()
            padding-bottom pcvw($mgn-lv2)
        +sp()
          padding-bottom vw($mgn-lv2)
        &:before,
        &:after
          content ""
          background $theme-color
          @extend .abs_centerY
          right 0
          +pc()
            width 20px
            height 3px
            margin-top -20px
            +pcLiquid()
              margin-top pcvw(-20)
          +sp()
            width vw(20)
            height vw(4)
            margin-top vw(-20)
        &:after
          transform translateY(-50%) rotate(90deg)
          transition .5s
        &.opend
          &:after
            transform translateY(-50%) rotate(0)
        p
          color $theme-color
          line-height 1.6
      .answer
        display none
        border-top 1px solid $border-grey
        +pc()
          padding-bottom $mgn-lv2
          +pcLiquid()
            padding-bottom pcvw($mgn-lv2)
        +sp()
          padding-bottom vw($mgn-lv2)
        p
          line-height 1.9
          +sp()
            padding-right 0
          &:first-of-type
            +pc()
              margin-top $mgn-lv2
              +pcLiquid()
                margin-top pcvw($mgn-lv2)
            +sp()
              margin-top vw($mgn-lv2)

  // Tab
  .tab-container
    display flex
    .tab
      display flex
      align-items center
      justify-content center
      width 100%
      border-bottom 2px solid $border-grey
      &:not(.active)
        cursor pointer
      +pc()
        padding-bottom $mgn-lv1
        transition color .2s opacity .2s
        &:hover
          border-bottom 2px solid $theme-color
          color $theme-color
          opacity .8
        +pcLiquid()
          padding-bottom pcvw($mgn-lv1)
      +sp()
        padding-bottom vw($mgn-lv1)
      &.active
        color $theme-color
        +pc()
          border-bottom 2px solid $theme-color
          &:hover
            &:not(.active)
              border-bottom 2px solid $theme-color
              color $theme-color
              opacity .6
        +sp()
          border-bottom vw(4) solid $theme-color
      span
        display block
        +pc()
          font-size 16px
          padding-right $mgn-lv1
          padding-left $mgn-lv1
          +pcLiquid()
            font-size pcvw(@font-size)
            padding-right pcvw($mgn-lv1)
            padding-left pcvw($mgn-lv1)
        +sp()
          font-size vw(28)
          padding-right vw($mgn-lv1)
          padding-left vw($mgn-lv1)
  // Table
  .table-container
    +sp()
      &:not(.col2)
        overflow-y hidden
        overflow-x auto
        // スクロールバー非表示
        // -ms-overflow-style none
        // scrollbar-width none
        // &::-webkit-scrollbar
        //   display:none
        // スクロールバー表示
        // &::-webkit-scrollbar
        //   height 7px
        // &::-webkit-scrollbar-track
        //   margin 0 2px
        //   background #f5f6f8
        //   border-radius 3px
        // &::-webkit-scrollbar-thumb
        //   border-radius: 3px; background: #999
        // SPで横スクロール仕様にする
        .table
          max-width 1280px
          min-width vw(1280)
          // 長すぎるtable用
          &.expand
            min-width 1280px
      &.col2
        table
          width 100%
  .table
    border-top 1px solid $border-grey
    border-right 1px solid $border-grey
    border-left 1px solid $border-grey
    border-collapse collapse
    position relative
    // word-break break-all
    // word-wrap: break-all;
    // PCで table横幅 > content幅だった場合付与
    &.size-maximum
      +pc()
        width 100%
    // ユニークな構造のtableでborderが外れた場合
    &.border-additional
      &-top
        border-top 1px solid $border-grey
      &-right
        border-right 1px solid $border-grey
      &-bottom
        border-bottom 1px solid $border-grey
      &-left
        border-left 1px solid $border-grey
    // すべてのセル
    th,td
      vertical-align middle
      +sp()
        max-width vw(670)
    td
      text-align left
    tr
      border 1px solid $border-grey
      &.border-thicker
        &-bottom
          border-bottom 3px solid $border-grey
      // 先頭行以外
      &:not(.subject-col)
        th,td
          +pc()
            padding $mgn-lv1-adjust-base $mgn-lv1
            +pcLiquid()
              padding pcvw($mgn-lv1-adjust-base) pcvw($mgn-lv1)
          +sp()
            padding vw($mgn-lv1_1) vw($mgn-lv1_1)
        th
          border-right 1px solid $border-grey
          letter-spacing 0.07em
          text-align left
          &.-center
            text-align center
        td
          border-right 1px solid $border-grey
          text-align left
          &.-center
            text-align center
          // 令和4年1月4日(火)～セルのSP幅調整
          &.sp-minS
            +sp()
              min-width 150px
          &.sp-minM
            +sp()
              min-width 200px
    // 先頭行
    .subject-col
      th,td
        letter-spacing 0.07em
        +pc()
          padding $mgn-lv1 $mgn-lv1
          +pcLiquid()
            padding pcvw($mgn-lv1) pcvw($mgn-lv1)
        +sp()
          padding vw($mgn-lv1_1) vw($mgn-lv1_1)
      th
        line-height 1.2
        &:not(:last-child)
          border-right 1px solid $border-grey

  .tab-content-outer
    .tab-content
      &:not(.active)
        display none

  // Order list
  // 段落
  ol
    margin-left 1.5em
    li
      &::marker
        margin 0
      ol
        margin-left 1.5em
    .small
      &:not(.no-prefix)
        text-indent -1em
        padding-left 1em
        &:before
          content "※"

  .list
    &-num
      list-style-type decimal
      margin-left 2em
      li
        list-style decimal
    &-upperRoman
      list-style-type upper-roman
      li
        list-style upper-roman
      ol
        &.list-num
          li
            list-style decimal
    &-lowerRoman
      list-style-type lower-roman
      li
        list-style lower-roman
    &-annotation
      li
        padding-left 1em
        text-indent -1em
        &:before
          content: "※";
    &-withDot
      li
        position relative
        +pc()
          padding-left 17px
          +pcLiquid()
            padding-left pcvw(@padding-left)
        +sp()
          padding-left vw(27)
        &:before
          content ""
          display block
          background-color $green
          border-radius 50%
          position absolute
          left 0
          +pc()
            width 6px
            height 6px
            top 0.8em
            +pcLiquid()
              width pcvw(@width)
              height pcvw(@height)
          +sp()
            width vw(12)
            height vw(12)
            background-color $theme-color
            top 0.75em

  // ol > ul > liのstyle無効化
  ol
    ul
      list-style none
      li
        list-style none!important

  // .list-withDotのなかのlistでスタイル打ち消ししたいとき
  .no-dot
    &.no-padding
      li
        padding-left 0!important
    li
      &:before
      &:after
        content none

  // 奨学金その他ページのプロセス
  .process-conteiner
    +pc()
      display flex
      flex-wrap wrap
    .process
      background-color $bg-grey
      text-align center
      position relative
      +pc()
        width 219.9px
        height 210px
        padding-top 15px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
          padding-top pcvw(@padding-top)
      +sp()
        width 100%
        margin-right 0
        margin-bottom vw(50)
        padding-top vw(20)
        padding-bottom vw(50)
      &:not(:last-of-type)
        &:after
          content ""
          display inline-block
          width 0
          height 0
          border-style solid
          position absolute
          +pc()
            border-width 20px 0 20px 20px
            border-color transparent transparent transparent $green
            top 50%
            transform translateY(-50%)
            right -10px
            +pcLiquid()
              border-width pcvw(20) 0 pcvw(20) pcvw(20)
              right pcvw(@right)
          +sp()
            border-width: vw(40) vw(40) 0 vw(40);
            border-color: $green transparent transparent transparent;
            left 50%
            transform translateX(-50%)
            bottom vw(-20)
      .index
        line-height 1.3
        letter-spacing 0.06em
        font-family $Roboto
        +pc()
          font-size 40px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(87)
      .title
        line-height 1.3
        letter-spacing 0.06em
        +pc()
          font-size 24px
          margin-bottom 10px
          +pcLiquid()
            font-size pcvw(@font-size)
            margin-bottom pcvw(@margin-bottom)
        +sp()
          font-size vw(38)
      .desc
        line-height 1.84
        letter-spacing 0.06em
        +pc()
          font-size 13px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(28)

  // バナー /nyushi/shiryouseikyu/
  .bannerArea
    +pc()
      display flex
    .banner
      +pc()
        max-width 380px
        transition opacity .2s
        &:hover
          opacity .8
      +sp()
        width 100%
      &:first-of-type
        +pc()
          margin-right 40px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(40)


  // 入試情報 /nyushi/
  .subpages-container
    +pc()
      display flex
    .subpage-button
      +pc()
        width calc(100% / 3)
        height 60px
        +pcLiquid()
          height pcvw(@height)
      &:not(:last-of-type)
        +pc()
          margin-right $mgn-lv1
          +pcLiquid()
            margin-right pcvw($mgn-lv1)
        +sp()
          margin-bottom vw($mgn-lv1)
      .buttonlink
        width 100%
        +pc()
          height 60px
          +pcLiquid()
            height pcvw(@height)
        &.green
          background-color $green
          border-color $green
          +pc()
            &:hover
              background-color $white
              span
                color $green
          span
            text-align left
        &.blue
          background-color $blue
          border-color $blue
          +pc()
            &:hover
              background-color $white
              span
                color $blue
          span
            text-align left
        span
          text-align left
          +pc()
            font-size 16px
            +pcLiquid()
              font-size pcvw(@font-size)
  .anchorlinkL-container
    .anchorlink
      span
        flex-direction column
        .smaller
          display block
          +pc()
            font-size 13px
            margin-top 3px
            +pcLiquid()
              font-size pcvw(@font-size)
              margin-top pcvw(@margin-top)
          +sp()
            font-size vw(24)
            margin-top vw(5)
  .nyushi-footer
    +pc()
      display flex
    .nyushi-footer-inner
      +pc()
        width 450px
        +pcLiquid()
          width pcvw(@width)
      +sp()
        width 100%
      &:first-of-type
        +pc()
          margin-right $mgn-lv2
          +pcLiquid()
            margin-right pcvw($mgn-lv2)
        +sp()
          margin-bottom vw($mgn-lv4)
      .link-box
        +sp()
          margin-bottom vw(60)

  // お問い合わせ先 /nyushi/shiryouseikyu/
  .inquiry
    display inline-block
    padding $mgn-lv1
    &.-expand
      +pc()
        width 42.5%
      +sp()
        width 100%
    .title
      +pc()
        margin-bottom 10px
        +pcLiquid()
          margin-bottom pcvw(@margin-bottom)
      +sp()
        margin-bottom vw(20)

  // 学生会館 /nyushi/ryou_apart_geshuku/gakuseikaikan/
  .accomodation-images
    +pc()
      &:not(._case_single)
        display flex
        margin-bottom 50px
        +pcLiquid()
          margin-bottom pcvw(50)
      &._case_single
        .img
          +pc()
            width 450px
            +pcLiquid()
              width pcvw(@width)
    .img
      +sp()
        width 100%
        +sp()
          margin-bottom vw($mgn-lv4)
      &:first-of-type
        +pc()
          margin-right $mgn-lv2
          +pcLiquid()
            margin-right pcvw($mgn-lv2)
    .desc
      +pc()
        font-size 13px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(30)

  // デジタルパンフレット /nyushi/ryou_apart_geshuku/gakuseikaikan/
  .digital-panphlets_images
    +pc()
      display flex
      justify-content center
    .img
      +pc()
        width 300px
        +pcLiquid()
          width pcvw(@width)
      +sp()
        width 100%
        max-width vw(460)
        margin 0 auto
      &:first-of-type
        +pc()
          margin-right $mgn-lv2
          +pcLiquid()
            margin-right pcvw($mgn-lv2)
        +sp()
          margin-bottom vw($mgn-lv4)



  // 以下はUIKITページ用の個別style
  .sample-layout-forContainers
    [class^="container"]
      background-color #bfa3ce
      min-height 120px
    .container
      &.-col2,
      &.-col3,
      &.-col4,
      &.-col6
        background-color $white
        .box
          background-color #bfa3ce
          &.-half
            width 50%

// 入試情報h1背景
[data-pages="uitemplate nyushi_index"]
  .page-header
    +pc()
      background-image url('../img/nyushi/index/header_bg.jpg')
    +sp()
      background-image url('../img/nyushi/index/header_bg@2x.jpg')

.page-heading
  &:not(.longer)
    text-align center
  // h1が長いとき（mixin +subpage-template-case-longer）
  &.longer
    line-height 1.3
    text-align center
    +pc()
      font-size 30px
      +pcLiquid()
        font-size pcvw(30)
    +sp()
      font-size vw(30)

.content-navigation-bottom
  background-color $thema-bg-lightGrey
  +pc()
    padding 54px 70px 57px
    +pcLiquid()
      padding pcvw(@padding)
  +sp()
    padding vw(72 40)
  .heading4
    color $color
    +pc()
      margin-bottom 20px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
    +sp()
      margin-bottom vw(40)

  .list-page_index
    li
      +pc()
        display inline-block
      &:not(:last-of-type)
        +pc()
          margin-right 40px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(20)
      a
        font-weight normal
        color $color
        &.current
          border-bottom 1px solid $color
.add-back
  .link-container--back
    text-align center
    .text-link--back
      cmn-arrow-link--back($thema-text-purple)
.add-next
  .link-container--next
    text-align center
    .text-link--next
      cmn-arrow-link($thema-text-purple)
