@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
$imgPath = '../img/career/teacher-education-sc/'

$colorBlue = #29ABE2
$colorYellow = #FCEE21

[data-pages="teacher-education-sc"]

  [data-support="index"]
    &.ui-template
      padding-top 0

    // Illust
    .illust
      .illust_inner
        width 100%
        height 100%
      &.illust_1
        width 151px
        height 166px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        +sp()
          width vw(260)
          height vw(286)
        .illust_inner
          background url($imgPath + 'index/illust-1.png') no-repeat center / contain
      &.illust_2
        width 247px
        height 150px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        +sp()
          width vw(417)
          height vw(253)
        .illust_inner
          background url($imgPath + 'index/illust-2.png') no-repeat center / contain
      &.illust_3
        width 247px
        height 185px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        +sp()
          width vw(400)
          height vw(300)
        .illust_inner
          background url($imgPath + 'index/illust-3.png') no-repeat center / contain
      &.illust_4
        width 210px
        height 223px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        +sp()
          width vw(360)
          height vw(382)
        .illust_inner
          background url($imgPath + 'index/illust-4.png') no-repeat center / contain
      &.illust_5
        width 218px
        height 245px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        +sp()
          width vw(300)
          height vw(336)
        .illust_inner
          background url($imgPath + 'index/illust-5.png') no-repeat center / contain
      &.illust_6
        width 303px
        height 143px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
        +sp()
          width vw(400)
          height vw(282)
        .illust_inner
          background url($imgPath + 'index/illust-6.png') no-repeat center / contain

    .illust_1
      margin auto
      +pc()
        position absolute
        right 0
        left 0
        bottom -81px
        +pcLiquid()
          bottom pcvw(@bottom)
      +sp()
        margin-top -40px
    .illust_2
      margin auto
      +pc()
        position absolute
        right 0
        left 0
        bottom -71px
        +pcLiquid()
          bottom pcvw(@bottom)
      +sp()
        margin-top vw(-20)
    .illust_3
      +pc()
        position absolute
        bottom 0
        right 0
      +sp()
        margin auto
    .illust_4
      +pc()
        position absolute
        bottom 0
        right 0
      +sp()
        margin auto
    .illust_5
      +pc()
        position absolute
        bottom -62px
        right 44px
        +pcLiquid()
          bottom pcvw(@bottom)
          right pcvw(@right)
      +sp()
        margin auto
    .illust_6
      margin auto

    // style
    .box_heading
      z-index 100
      position relative
      text-align center
      +pc()
        padding-top 100px
      +sp()
        padding-top 60px

    .box_heading + .container_wide
      +pc()
        margin-top -30px
      +sp()
        margin-top -4.6vw

    // section-mv
    .section-mv
      position relative
      width 100%
      overflow hidden
      text-align center
      background-image url($imgPath + 'index/mv_bg-stripe.png')
      background-position center bottom 20px
      background-size cover
      background-repeat no-repeat
      background-color #fff
      +pc()
        padding-top 60px
        padding-bottom 20px
      +sp()
        padding-top vw(20)
        padding-bottom 20px

      .wrap-mv_text
        position absolute
        left 0
        right 0
        margin auto
        +pc()
          top 20px
          background-color $colorYellow
          border 20px solid #FFF
          border-radius 50%
          width 826px
          height 826px
        +sp()
          top 26%
          width 69%
          height 76%
          margin auto
          z-index 100

      .mv_illust
        transform translateY(25px)

      .image
        position absolute
        left 0
        top 0
        +pc()
          opacity 0

      +pc()
        .image_1
          animation change-img-anime_1 6s infinite
        .image_2
          animation change-img-anime_2 6s infinite

        // @keyframes change-img-anime_1 {
        //   0%{ opacity: 1; transform: rotate(0)}
        //   35%{ opacity: 0;}
        //   50%{ opacity: 0; transform: rotate(-180deg)}
        //   65%{ opacity: 1;}
        //   100%{ opacity: 1; transform: rotate(-360deg)}
        // }
        // @keyframes change-img-anime_2 {
        //   0%{ opacity: 0; transform: rotate(180deg)}
        //   35%{ opacity: 1;}
        //   50%{ opacity: 1; transform: rotate(0)}
        //   70%{ opacity: 0;}
        //   100%{ opacity: 0; transform: rotate(-180deg)}
        // }

        @keyframes change-img-anime_1 {
          0%{ opacity: 1; transform: rotate(0)}
          20%{ opacity: 1; transform: rotate(0)}
          40%{ opacity: 0; transform: rotate(-180deg)}
          80%{ opacity: 0; transform: rotate(-180deg)}
          100%{ opacity: 1; transform: rotate(-360deg)}
        }
        @keyframes change-img-anime_2 {
          0%{ opacity: 0; transform: rotate(180deg)}
          20%{ opacity: 0; transform: rotate(180deg)}
          40%{ opacity: 1; transform: rotate(0)}
          80%{ opacity: 1; transform: rotate(0)}
          100%{ opacity: 0; transform: rotate(-180deg)}
        }

      +sp()
        .image_1
          animation fuwa-img-anime_1 6s infinite
        .image_2
          top vw(20)
          animation fuwa-img-anime_2 6s infinite

        @keyframes fuwa-img-anime_1 {
          0%{ opacity: 1;}
          15%{ opacity: 0;}
          50%{ opacity: 0;}
          85%{ opacity: 1;}
          100%{ opacity: 1;}
        }
        @keyframes fuwa-img-anime_2 {
          0%{ opacity: 0;}
          35%{ opacity: 1;}
          50%{ opacity: 1;}
          65%{ opacity: 0;}
          100%{ opacity: 0;}
        }



    // section Info
    .inner__info
      +pc()
        display flex
        align-items center
        padding 56px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding-top 32px
        padding-bottom 20px

    .list__info
      +pc()
        margin-left 96px

      .item__info
        +pc()
          &:not(:first-of-type)
            margin-top 10px
        +sp()
          &:not(:first-of-type)
            margin-top vw(20)

        .link__info
          color #333
          +pc()
            display flex

        .release_date__info
          +pc()
            margin-right 40px
          +sp()
            color #B3B3B3

    .section-what
      .strong
        margin 0 6px

    // section-feature
    .list__feature
      +pc()
        display flex
        justify-content center
        gap 40px

    .c-text_point
      display flex
      align-items center
      justify-content center
      letter-spacing normal
      .number
        line-height 1
        +pc()
          font-size 50px
          margin-left 6px
          +pcLiquid()
            font-size pcvw(@font-size)
            margin-left pcvw(@margin-left)
        +sp()
          font-size vw(70)
          margin-left 6px

    .contents__feature
      +pc()
        margin-top -26px
        padding 50px 30px
        min-height 334px
        +pcLiquid()
          padding pcvw(@padding)
          margin-top pcvw(@margin-top)
          min-height pcvw(@min-height)
      +sp()
        margin-top -5vw
        padding vw(60 40 70)
      .text_1
        text-align center
        +pc()
          padding 0 10px

    .wrap_candidate
      position relative
      margin-bottom 28px

      .c-candidate_box
        display inline-block
        +pc()
          width 260px
          position absolute
          top 0
          left 0
          +pcLiquid()
            width pcvw(@width)
        +sp()
          width 80%
          margin-bottom 20px

    .japan_map
      text-align right

    .list__candidate
      margin-left: 0

    .item__candidate
      &:not(&:first-of-type)
        border-top 1px dashed #000
        +pc()
          padding-top 36px
          margin-top 48px
          +pcLiquid()
            padding-top pcvw(@padding-top)
            margin-top pcvw(@margin-top)
        +sp()
          padding-top vw(72)
          margin-top vw(96)

      .text
        +pc()
          max-width 500px

    .list__case
      +pc()
        display flex
        justify-content center
        gap 40px

      .caseImg
        img
          +sp()
            width 30%
            height auto

      .item__case-content
        +pc()
          padding 27px 20px 0
          margin-top -20px
          height 100%
          +pcLiquid()
            margin-top pcvw(@margin-top)
            padding pcvw(@padding)
        +sp()
          margin-top -4.333vw
          padding vw(60 40 40 40)

        p:first-of-type
          min-height 4em
          display flex
          align-items center
          justify-content center

    .section-pillars
      .u-title3setPosition
        +pc()
          top -70px
          +pcLiquid()
            top pcvw(@top)
        +sp()
          top -12.5vw
      .u-titleBig
        .font_big
          line-height 1
          +pc()
            font-size 54px
            +pcLiquid()
              font-size pcvw(@font-size)
          +sp()
            font-size vw(70)

    // section-acquisition
    .section-acquisition
      .font_big
        line-height 1
        +pc()
          font-size 42px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(64)

    .acquisition_status__pc
      table
        width 680px
        table-layout auto
        th
          vertical-align middle
          padding 3px 20px
        td
          padding 3px 20px
          &:not(.bg_color-yellow)
            background-color #FFFAE6

    .acquisition_status__sp
      .faculty
        margin-bottom 3.7vw
        padding 1vw 5.333vw

    .total
      padding 8px 16px
