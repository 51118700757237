$thisPage = "information"
$bgPath = '../img/' + $thisPage + '/'

[data-pages={'"'+$thisPage+'"'}]

  /**
  * common
  **/
  .page-header
    +pc()
      background-image url($bgPath + 'header_bg.jpg')
    +sp()
      background-image url($bgPath + 'header_bg@2x.jpg')

  .pagenation
    .prev-btn,
    .next-btn
      a
        cursor pointer
        display block
        width 100%
        height 100%

  /**
  * content
  **/
  .content
    background-color $thema-bg-lightGrey
    +pc()
      padding 70px 0 165px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(100 0 250)
    &-section
      +pc()
        padding 0 50px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(0 $side-margin_sp)
    &-container
      +pc()
        maxWidth($width_pc01 px)
        +pcLiquid()
          max-width pcvw($width_pc01)

    // 新着3件のレイアウト
    &-latest-container
      +pc()
        display flex
        margin-bottom 50px
      +sp()
        margin-bottom vw(80)
      li
        +pc()
          width 'calc(33.33% - %spx)' % (60 / 3)
          +pcLiquid()
            width 'calc(33.33% - %s)' % pcvw(60 / 3)
        & + li
          +pc()
            margin-left 30px
            +pcLiquid()
              margin-left pcvw(@margin-left)
            &:first-of-type
              margin-left 0
          +sp()
            margin-top vw(80)

    // 3件目以降のニュース
    &-archive
      &-list
        & + li
          +pc()
            margin-top 20px
            +pcLiquid()
              margin-top pcvw(@margin-top)
          +sp()
            margin-top vw(40)
        a
          color $thema-text-black
          background-color $white
          +pc()
            display flex
            padding 35px 40px
            hover-opacity()
            +pcLiquid()
              padding pcvw(@padding)
          +sp()
            display block
            padding vw(40)
      &-header
        +pc()
          width percent(240, $width_pc01 - 80)
          flex-shrink 0
          +pcLiquid()
            width 30%
      &-date
        @extend .information-contnt-block-date
      &-category
        @extend .information-contnt-block-category
      &-title
        @extend .information-contnt-block-title
        +pc()
          font-size 1.6rem
          line-height round(26 / 16, 2)
          margin-top -.3em
          +pcLiquid()
            font-size pcvw(16)
        +sp()
          font-size vw(28)
          line-height 2
          margin-top vw(20)
