[data-category="about"][data-pages="corporation-cathedral-tour"]
  .page-header
    +pc()
      background-image url('../img/about/corporation/cathedral-tour/header_bg.jpg')
    +sp()
      background-image url('../img/about/corporation/cathedral-tour/header_bg@2x.jpg')
  [data-about="corporation-cathedral-tour"]
    .flex
      +pc()
        gap 20px
        +pcLiquid()
          gap pcvw(@gap)
      +sp()
        justify-content space-between
      .box
        +pc()
          width calc(100% / 2 - 20px)
          +pcLiquid()
            width calc(100% / 2 - 1.46vw)
        +sp()
          width calc(100% / 2 - 2vw)
