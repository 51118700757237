[data-category="about"]
  [data-about="index"]
    .intro
      background-color $thema-bg-lightGrey
      +pc()
        padding 80px 0 170px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        width 'calc(100% + %s)' % vw($mgn-lv2 * 2)
        margin-left vw($mgn-lv2 * -1)
        padding vw(80 0 240)
      & + section
        +pc()
          margin-top -60px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(-120)
      .container
        +sp()
          padding vw(0 $mgn-lv2)
      &-bg
        background-image url('../img/about/intro-bg.png')
        background-repeat no-repeat
        background-size contain
        +pc()
          width 734px
          height 510px
          margin 0 auto
          +pcLiquid()
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          width vw(620)
          height vw(510)
          margin-left auto
      .heading2
        line-height 1
        +pc()
          margin-top -50px
          +pcLiquid()
            margin-top pcvw(@margin-top)

    // リンクセクション
    .flexPC
      // 画像あり2カラム並び
      &.-col2
        .box
          +pc()
            width 'calc(50% - %s)' % ($mgn-lv2 / 2)
          & + .box
            +sp()
              margin-top vw($mgn-lv4)
      // 画像なし4カラム並び
      &.-col4
        .box
          +pc()
            width 'calc(25% - %spx)' % ((40 * 3 / 4))
    a.box
      display block
      .info_item--image
        overflow hidden
      +pc()
        img
          transition transform .5s
        &:hover
          img
            transform scale(1.1)
          .text-link::after
            transform translate3d(3px, 0, 0)
    [class^="heading"]
      &::before
        display none
    .heading3
      font-weight 500
      color $thema-text-black
      display flex
      align-items center
      justify-content space-between
      margin-bottom 0 !important
      +sp()
        font-size vw(34)
        padding vw(40 0)
      &-inner
        > span
          font-family $Roboto
    // 矢印用
    .text-link
      +pc()
        width 24px
        +pcLiquid()
          width pcvw(@width)
      +sp()
        width vw(42)
      &::after
        position relative
        +pc()
          border-width 7px 0 0 7px
          transition transform .3s
          +pcLiquid()
            border-width pcvw(@border-width)
        +sp()
          border-width vw(14 0 0 16)
          right vw(-2)
    .heading5
      font-weight 500
      color #601986;
      display inline
      align-items center
      justify-content space-between
      margin-bottom 0 !important
      +pc()
        height 100%
      +sp()
        padding vw(40 0)
      &-inner
        > span
          font-family $Roboto
    .container
      &:not(.block-page_index)
        .heading5
          font-weight 500
          color $thema-text-black
          display flex
          align-items center
          justify-content space-between
          margin-bottom 0 !important
          +pc()
            height 100%
          +sp()
            padding vw(40 0)
          &-inner
            > span
              font-family $Roboto
