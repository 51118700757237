.container-info
  .heading
    text-align center

.list-info
  +pc()
    display flex
    justify-content space-between
    flex-wrap nowrap
  .information-contnt-block
    +pc()
      margin-bottom 50px
      width 340px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
        width pcvw(@width)
    +sp()
      margin-bottom vw(84)
  .information-contnt-block-date
    color $thema-text-black
  .information-contnt-block-title
    color $thema-text-black

.c-info_item
  +pc()
    margin-bottom 50px
    width 340px
    +pcLiquid()
      margin-bottom pcvw(@margin-bottom)
      width pcvw(@width)
  +sp()
    margin-bottom vw(84)
  a
    display block
    +pc()
      &:hover
        .info_item--image
          img
            transform translate(-50%, -50%) scale(1.1)
  .info_item--image
    position relative
    text-align center
    overflow hidden
    width 100%
    background-color $white
    +pc()
      height 220px
      +pcLiquid()
        height pcvw(@height)
    img
      +pc()
        width auto
        height auto
        max-width 100%
        max-height 100%
        position absolute
        transform translate(-50%, -50%)
        top 50%
        left 50%
        transition transform .3s
      +sp()
        width 100%
        height 100%
  .info_item--text
    +pc()
      padding 15px 10px 0
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(40 20 0)
  .info_item--date
    color $thema-text-black
    font-family $Roboto
    letter-spacing ls(50)
    display inline-block
    +pc()
      font-size 1.6rem
      +pcLiquid()
        font-size pcvw(16)
    +sp()
      font-size vw(32)
  .info_item--category
    display inline-block
    +pc()
      margin-left 20px
      +pcLiquid()
        margin-left pcvw(@margin-left)
    +sp()
      margin-left vw(20)
    span
      display inline-block
      text-align center
      white-space nowrap
      letter-spacing ls(50)
      color $thema-text-purple
      border 1px solid $thema-text-purple
      +pc()
        font-size 1.2rem
        padding 3px 10px
        min-width 120px
        +pcLiquid()
          font-size pcvw(12)
          padding pcvw(@padding)
          min-width pcvw(120)
      +sp()
        font-size vw(24)
        padding vw(10)
        min-width vw(184)
  .info_item--title
    font-family $NotoSans
    font-weight 500
    letter-spacing ls(50)
    display -webkit-box
    overflow hidden
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    color $thema-text-black
    +pc()
      font-size 1.4rem
      line-height round(26 / 14, 2)
      margin-top 10px
      +pcLiquid()
        font-size pcvw(14)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(28)
      line-height 2
      margin-top vw(30)
