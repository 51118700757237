// color
$theme-color = #5f1985
$theme-color-thin = #bfa3ce
$black = #333
$white = #fff
$bg-grey = #f5f6f8
$bg-theme-color-thin = #f4eef8
$border-grey = #ccc
$green = #9ec238
$blue = #1a86b9
// margin
$mgn-lv1 = 20px
$mgn-lv1_1 = 30px
$mgn-lv1_2 = 32px
$mgn-lv1_3 = 36px
$mgn-lv2 = 40px
$mgn-lv3 = 60px
$mgn-lv3_1 = 70px
$mgn-lv4 = 80px
$mgn-lv5 = 100px
$mgn-lv6 = 120px

// margin sp viewport
$mgn-sp-lv1 = $mgn-lv1 * 1.2
$mgn-sp-lv1_1 = $mgn-lv1_1 * 1.2
$mgn-sp-lv1_2 = $mgn-lv1_2 * 1.2
$mgn-sp-lv1_3 = $mgn-lv1_3 * 1.2
$mgn-sp-lv2 = $mgn-lv2 * 1.2
$mgn-sp-lv3 = $mgn-lv3 * 1.2
$mgn-sp-lv3_1 = $mgn-lv3_1 * 1.2
$mgn-sp-lv4 = $mgn-lv4 * 1.2
$mgn-sp-lv5 = $mgn-lv5 * 1.2
$mgn-sp-lv6 = $mgn-lv6 * 1.2


// margin (line-height調整用)
$mgn-lv1-adjust-base = 11px
// container width
$limit = 1186px
$wide = 1080px
$mid = 940px
$sml = 860px

$limit_sp = 670px
$mid_sp = 590px

// 汎用クラス
.pcOnly
  +pc()
    display block
  +sp()
    display none
.spOnly
  +pc()
    display none
  +sp()
    display block
.bg
  &-white
    background $white
  // テーブル背景色
  &-theme-thin
    background $bg-theme-color-thin
  &-grey
    background $bg-grey
  // ボタン
  &-green
    background $green
  &-blue
    background $blue
.color
  &-theme
    color $theme-color
.bold
  font-weight bold
.abs
  &_center
    position absolute
    top 50%
    left 50%
    transform translate(-50%,-50%)
  &_centerX
    position absolute
    left 50%
    transform translateX(-50%)
  &_centerY
    position absolute
    top 50%
    transform translateY(-50%)
.flex
  display flex
  &.-center
    align-items center
    justify-content center
  &.-centerY
    align-items center
  &.-centerX
    justify-content center
  &.-wrap
    flex-wrap wrap
  &.-col4
    &-to2
      .box
        +pc()
          width calc(100% / 4 - 20px)
          margin-right $mgn-lv1
          +pcLiquid()
            width calc(100% / 4 - 1.46vw)
            margin-right pcvw(@margin-right)
        +sp()
          width calc(100% / 2 - 1.335vw)
        &:nth-child(4n)
          +pc()
            margin-right 0
            +pcLiquid()
              margin-right 0
        &:nth-child(odd)
          +sp()
            margin-right vw($mgn-lv1)

  &PC
    +pc()
      display flex
      &.-center
        align-items center
        justify-content center
      &.-centerY
        align-items center
      &.-centerX
        justify-content center
      &.-between
        justify-content space-between
      &.-col2
        .box
          width 50%
          &[class*="per"]
            &:first-child
              margin-right $mgn-lv3
              +pcLiquid()
                margin-right pcvw(@margin-right)
      &[class^="-col"]
        &.per90
          width 90%
        &.per80
          width 80%
        &.per70
          width 70%
        &.per60
          width 60%
        &.per50
          width 50%
        &.per40
          width 40%
        &.per30
          width 30%
        &.per20
          width 20%
        &.per10
          width 10%
        &-center
          margin-right auto
          margin-left auto
.indentAdjust
  padding-left 1em
  text-indent -1em

.img
  img
    width 100%

// テーブルLB調整用
.nowrap
  white-space nowrap

// JSトリガー
.js-accordionTable
  cursor pointer

.js-toggleTable
  display none
