// 画像パス
$path-faculty = '../img/faculty/'
$path-faculty-top = $path-faculty + 'faculty-top/'
$path-english = $path-faculty + 'english/'
$path-japanese = $path-faculty + 'japanese/'
$path-society = $path-faculty + 'society/'
$path-childhood = $path-faculty + 'childhood/'
$path-nutrition = $path-faculty + 'nutrition/'
$path-early-childhood = $path-faculty + 'early-childhood/'

.theme_color-purple
  color $color-bungakubu
.theme_color-lightgreen
  color $color-eiyokagakubu
.theme_color-lightblue
  color $color-jc

[data-pages="faculty-top"]
  .page-header
    +pc()
      background-image url($path-faculty + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-faculty + 'header_bg-sp.jpg')
  // ui-template 打ち消し
  .ui-template
    padding-top 0
    +sp()
      padding-left 0
      padding-right 0

[data-pages="english"]
  .page-header
    +pc()
      background-image url($path-english + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-english + 'header_bg-sp.jpg')
  .c-department_top
    +pc()
      background-image url($path-english + 'top/main_image-pc.jpg')
    +sp()
      background-image url($path-english + 'top/main_image-sp.jpg')
  .career-graph
    .number-box
      +pc()
        top 29%
        left 40%
      +sp()
        top 28%
        left 36%
    img
      +pcLiquid()
        width pcvw(533)

[data-pages="japanese"]
  .page-header
    +pc()
      background-image url($path-japanese + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-japanese + 'header_bg-sp.jpg')
  .c-department_top
    +pc()
      background-image url($path-japanese + 'top/main_image-pc.jpg')
    +sp()
      background-image url($path-japanese + 'top/main_image-sp.jpg')
  .career-graph
    .number-box
      +pc()
        top 29%
        left 40%
      +sp()
        top 26%
        left 37%
    img
      +pcLiquid()
        width pcvw(521)

[data-pages="society"]
  .page-header
    +pc()
      background-image url($path-society + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-society + 'header_bg-sp.jpg')
  .c-department_top
    +pc()
      background-image url($path-society + 'top/main_image-pc.jpg')
    +sp()
      background-image url($path-society + 'top/main_image-sp.jpg')
  .career-graph
    .number-box
      +pc()
        top 28.5%
        left 38%
      +sp()
        top 27%
        left 36%
    img
      +pcLiquid()
        width pcvw(494)

[data-pages="childhood"]
  .page-header
    +pc()
      background-image url($path-childhood + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-childhood + 'header_bg-sp.jpg')
  .c-department_top
    +pc()
      background-image url($path-childhood + 'top/main_image-pc.jpg')
    +sp()
      background-image url($path-childhood + 'top/main_image-sp.jpg')
  .career-graph-1
    .number-box
      +pc()
        top 29%
        left 41%
      +sp()
        top 27%
        left 38%
    img
      +pcLiquid()
        width pcvw(506)
  .career-graph-2
    .number-box
      +pc()
        top 29%
        left 39%
      +sp()
        top 27%
        left 34%
    img
      +pcLiquid()
        width pcvw(495)

[data-pages="nutrition"]
  .page-header
    +pc()
      background-image url($path-nutrition + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-nutrition + 'header_bg-sp.jpg')
  .c-department_top
    +pc()
      background-image url($path-nutrition + 'top/main_image-pc.jpg')
    +sp()
      background-image url($path-nutrition + 'top/main_image-sp.jpg')
  .career-graph
    .number-box
      +pc()
        top 30%
        left 39%
      +sp()
        top 27%
        left 35%
    img
      +pcLiquid()
        width pcvw(501)

[data-pages="early-childhood"]
  .page-header
    +pc()
      background-image url($path-early-childhood + 'header_bg-pc.jpg')
    +sp()
      background-image url($path-early-childhood + 'header_bg-sp.jpg')
  .c-department_top
    +pc()
      background-image url($path-early-childhood + 'top/main_image-pc.jpg')
    +sp()
      background-image url($path-early-childhood + 'top/main_image-sp.jpg')
  .career-graph
    .number-box
      +pc()
        top 37%
        left 38%
      +sp()
        top 35%
        left 33%
    img
      +pcLiquid()
        width pcvw(544)

[data-faculty="bungakubu"]
  .ui-template
    .list-withDot
      li
        &::before
          background-color $color-bungakubu

  .c-department_top
    &::before
      +pc()
        background-image url($path-faculty + 'bg_color-purple-pc.svg')
      +sp()
        background-image url($path-faculty + 'bg_color-purple-sp.svg')
    &.return
      &::before
        +pc()
          background-image url($path-faculty + 'bg_color-purple-re-pc.svg')


[data-faculty="eiyokagakubu"]
  .page-heading
    color $color-eiyokagakubu
  .page-heading-en
    color $color-eiyokagakubu
  .page-heading-faculty
    color $color-eiyokagakubu
  .ui-template
    .list-withDot
      li
        &::before
          background-color $color-eiyokagakubu
    .withBlock
      &::before
        background-color $color-eiyokagakubu
    .withBorder
      &:before
        background $color-eiyokagakubu

  .c-department_top
    &::before
      +pc()
        background-image url($path-faculty + 'bg_color-lightgreen-pc.svg')
      +sp()
        background-image url($path-faculty + 'bg_color-lightgreen-sp.svg')


[data-faculty="jc"]
  .ui-template *
    font-family 'Kosugi Maru', sans-serif
    font-weight 400
  .page-heading
    font-family 'Kosugi Maru', sans-serif
    color $color-jc
  .page-heading-en
    font-family 'Kosugi Maru', sans-serif
    color $color-jc
  .page-heading-faculty
    font-family 'Kosugi Maru', sans-serif
    color $color-jc
  .ui-template
    .list-withDot
      li
        &::before
          background-color $color-jc
    .withBlock
      &::before
        background-color $color-jc
    .withBorder
      &:before
        background $color-jc

  .c-department_top
    &::before
      +pc()
        background-image url($path-faculty + 'bg_color-lightblue-pc.svg')
      +sp()
        background-image url($path-faculty + 'bg_color-lightblue-sp.svg')

[data-category="faculty"]

  [data-faculty="bungakubu"]
    $themeColor = $color-bungakubu
    $colorName = 'purple'
    .text-link
      cmn-arrow-link($themeColor)
    .faculty_heading
      color $themeColor
      &::after
        background $themeColor
    .department-block
      &:nth-of-type(1)
        +pc()
          background-image url($path-faculty-top + 'img-english-pc.jpg')
        +sp()
          background-image url($path-faculty-top + 'img-english-sp.jpg')
      &:nth-of-type(2)
        +pc()
          background-image url($path-faculty-top + 'img-japanese-pc.jpg')
        +sp()
          background-image url($path-faculty-top + 'img-japanese-sp.jpg')
      &:nth-of-type(3)
        +pc()
          background-image url($path-faculty-top + 'img-society-pc.jpg')
        +sp()
          background-image url($path-faculty-top + 'img-society-sp.jpg')
      &:nth-of-type(4)
        +pc()
          background-image url($path-faculty-top + 'img-childhood-pc.jpg')
        +sp()
          background-image url($path-faculty-top + 'img-childhood-sp.jpg')

    .department-heading-en
      color $themeColor
    .block-other_link
      .list-policy
        li
          color $themeColor
          &::before
            background-color $themeColor
    .department-block.position-left
      &::before
        +pc()
          background-image url($path-faculty-top + 'color-' + $colorName + '.svg')
        +sp()
          background-image url($path-faculty-top + 'color-' + $colorName + '-sp.svg')
    .department-block.position-right
      &::before
        +pc()
          background-image url($path-faculty-top + 'color-' + $colorName + '-re.svg')
        +sp()
          background-image url($path-faculty-top + 'color-' + $colorName + '-re-sp.svg')
    .list-learning_point
      list-learning_point($themeColor)
    .block-page_index
      block-page_index($themeColor)
      .list-page_index
        a
          &::before
            background url('../img/common/textlink_arrow-' + $colorName + '.svg') no-repeat
    .container-accordion--course
      container-accordion--course($themeColor)
    .list-voice
      .text-heading
        color $themeColor
      li
        &:nth-of-type(odd)
          .wrap-number
            +pc()
              .wrap-number-inner
                background url('../img/faculty/voice-number-' + $colorName + '.svg') no-repeat center / contain
        &:nth-of-type(even)
          .wrap-number
            +pc()
              .wrap-number-inner
                background url('../img/faculty/voice-number-' + $colorName + '-re.svg') no-repeat center / contain
        .wrap-number
          +sp()
            .wrap-number-inner
              background url('../img/faculty/voice-number-' + $colorName + '-under.svg') no-repeat center / contain

  [data-faculty="eiyokagakubu"]
    $themeColor = $color-eiyokagakubu
    $colorName = 'lightgreen'
    .withBorder::before
      background $themeColor
    .faculty_heading
      color $themeColor
      &::after
        background $themeColor
    .department-block
      +pc()
        background-image url($path-faculty-top + 'img-nutrition-pc.jpg')
      +sp()
        background-image url($path-faculty-top + 'img-nutrition-sp.jpg')
      &::before
        +pc()
          background-image url($path-faculty-top + 'color-' + $colorName + '.svg')
        +sp()
          background-image url($path-faculty-top + 'color-' + $colorName + '-sp.svg')
      .department-heading-en
        color $themeColor
    .text-link
      cmn-arrow-link($themeColor)
    .block-other_link
      .list-policy
        li
          color $themeColor
          &::before
            background-color $themeColor
    .list-learning_point
      list-learning_point($themeColor)
    .block-page_index
      block-page_index($themeColor)
      .list-page_index
        a
          &::before
            background url('../img/common/textlink_arrow-' + $colorName + '.svg') no-repeat
    .container-accordion--course
      container-accordion--course($themeColor)
    .list-voice
      .text-heading
        color $themeColor
      li
        &:nth-of-type(odd)
          .wrap-number
            +pc()
              .wrap-number-inner
                background url('../img/faculty/voice-number-' + $colorName + '.svg') no-repeat center / contain
        &:nth-of-type(even)
          .wrap-number
            +pc()
              .wrap-number-inner
                background url('../img/faculty/voice-number-' + $colorName + '-re.svg') no-repeat center / contain
        .wrap-number
          +sp()
            .wrap-number-inner
              background url('../img/faculty/voice-number-' + $colorName + '-under.svg') no-repeat center / contain

    .c-info_item
      .info_item--category
        span
          color #739c00
          border 1px solid #739c00

    .accordion-container
      .accordion
        .question
          p
            color $themeColor
            &:before
              color $themeColor
          &:before,
          &:after
            background $themeColor

  [data-faculty="jc"]
    $themeColor = $color-jc
    $colorName = 'lightblue'

    .withBorder::before
      background $color-jc

    .container-inner
      background-color $thema-bg-lightBlue

    .faculty_heading
      color $white
      &::after
        background $white

    .department-block
      +pc()
        background-image url($path-faculty-top + 'img-jc-1-pc.jpg')
      +sp()
        background-image url($path-faculty-top + 'img-jc-1-sp.jpg')
      &::before
        +pc()
          background-image url($path-faculty-top + 'color-blue.svg')
        +sp()
          background-image url($path-faculty-top + 'color-blue-sp.svg')
      .department-heading-en
        color $themeColor

    .text-link
      cmn-arrow-link($themeColor)

    .block-other_link
      .list-policy
        li
          color $themeColor
          &::before
            background-color $themeColor

    .list-learning_point
      list-learning_point($themeColor)

    .block-page_index
      block-page_index($themeColor)
      .list-page_index
        a
          &::before
            background url('../img/common/textlink_arrow-' + $colorName + '.svg') no-repeat

    .container-accordion--course
      container-accordion--course($themeColor)

    .list-voice
      .text-heading
        color $themeColor
      li
        &:nth-of-type(odd)
          .wrap-number
            +pc()
              .wrap-number-inner
                background url('../img/faculty/voice-number-' + $colorName + '.svg') no-repeat center / contain
        &:nth-of-type(even)
          .wrap-number
            +pc()
              .wrap-number-inner
                background url('../img/faculty/voice-number-' + $colorName + '-re.svg') no-repeat center / contain
        .wrap-number
          +sp()
            .wrap-number-inner
              background url('../img/faculty/voice-number-' + $colorName + '-under.svg') no-repeat center / contain

    .c-info_item
      .info_item--category
        span
          border 1px solid $themeColor
          color $themeColor

    .accordion-container
      .accordion
        .question
          p
            color $themeColor
            &:before
              color $themeColor
          &:before,
          &:after
            background $themeColor


    .list-learning_power
      li
        background-color $color-jc
        border-radius 24px
      .heading
        color $white
      .paragraph
        color $white
      .border
        &::before
          background $white
