
[data-category="maintenance"]

  // // contact-list配下すべてに適用する場合はこちら
  .header {
    z-index -1
    pointer-events none
  }
  .footer {
    z-index -1
    pointer-events none
  }
  .main {
    height 70vh
  }
  article
    .mask
      position fixed
      top 50%
      left 50%
      transform translate(-50%, -50%)
      width 100vw
      height 100vh
      background rgba(255,255,255,0.8)
      padding 0
      z-index 99
    .container
      position relative
      z-index 999
      &.-mid
        +pc()
          max-width 1000px
        +pcLiquid()
          max-width pcvw(1000)
    .notice-wrap
      border 1px solid #000
    .-center
      text-align center
    .content-container
      .inquery-content
        display flex
        flex-wrap wrap
        justify-content space-between
        .margin-top-lv3
          margin-top 29px
          +pcLiquid()
            margin-top pcvw(29)
        .margin-top-little
          margin-top 2px
          +pcLiquid()
            margin-top pcvw(2)


  // トップだけに適用する場合はこちら
  [data-contact-list="index"]
    .inquiry
      +pc()
        min-width 310px
      +pcLiquid()
        min-width pcvw(310)
      +sp()
        width 100% !important
