*
  box-sizing border-box
html
body
div
span
object
iframe
h1
h2
h3
h4
h5
h6
p
blockquote
pre
abbr
address
cite
code
del
dfn
em
img
ins
kbd
q
samp
small
strong
sub
sup
var
b
i
dl
dt
dd
ol
ul
li
fieldset
form
label
legend
table
caption
tbody
tfoot
thead
tr
th
td
article
aside
canvas
details
figcaption
figure
footer
header
hgroup
menu
nav
section
summary
time
mark
audio
video
  background transparent
  border 0
  font-size 100%
  margin 0
  outline 0
  padding 0
  vertical-align baseline

body
  line-height 1

article
aside
details
figcaption
figure
footer
header
hgroup
menu
nav
section
  display block
ul
li
  list-style none
blockquote
q
  quotes none
blockquote:before
blockquote:after
q:before
q:after
  content ''
  content none
a
  background transparent
  font-size 100%
  margin 0
  padding 0
  vertical-align baseline
  text-decoration none
img
  max-width 100%
  vertical-align bottom
  -webkit-backface-visibility hidden
  backface-visibility hidden
main
  display block
/* change colours to suit your needs */
ins
  background-color #ff9
  color #000
  text-decoration none
/* change colours to suit your needs */
mark
  background-color #ff9
  color #000
  font-style italic
  font-weight bold
del
  text-decoration line-through
abbr[title]
dfn[title]
  border-bottom 1px dotted
  cursor help
table
  border-collapse collapse
  border-spacing 0
/* change border colour to suit your needs */
hr
  border 0
  border-top 1px solid #ccc
  display block
  height 1px
  margin 1em 0
  padding 0
input
select
  vertical-align middle
  margin 0
  padding 0
h1
h2
h3
  text-transform initial

button
  font-size 100%
  padding 0
  border 0
  background-color transparent
  cursor pointer
  &:focus
    outline 0
