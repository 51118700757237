.c-page_footer_nav
  background-color #fff
  z-index 1000
  position relative
  +pc()
    padding 100px 0 160px
    +pcLiquid()
      padding pcvw(@padding)
  +sp()
    padding vw(160 0 240)

.c-page_footer_nav--inner
  max-width 1080px
  width 100%
  margin auto
  background-color #F5F6F8
  +pc()
    padding 54px 140px 40px 70px
    +pcLiquid()
      width 90%
      padding pcvw(@padding)
  +sp()
    width 89%
    padding vw(80 20 40 40)

  .c-page_footer_nav--heading
    color #601986
    font-weight 700
    letter-spacing 0.05em
    +pc()
      font-size 20px
      margin-bottom 32px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
        font-size pcvw(@font-size)
    +sp()
      margin-bottom vw(60)
      letter-spacing 0.04em
      font-size vw(40)

.c-list-page_footer_nav
  .c-item-page_footer_nav
    +pc()
      margin 0 36px 30px 0
      display inline-block
      +pcLiquid()
        margin pcvw(@margin)
    +sp()
      margin-bottom vw(35)

    &:before
      margin-right 5px
      content ""
      display inline-block
      background-image url('../img/common/arrow-page_footer_nav.svg')
      background-position center
      background-size contain
      background-repeat no-repeat
      +pc()
        width 8px
        height 13px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
      +sp()
        width vw(16)
        height vw(26)

    a
      letter-spacing 0.05em
      color #601986
      +pc()
        font-size 16px
        +pcLiquid()
          font-size pcvw(@font-size)
        &:hover
          text-decoration underline
      +sp()
        font-size vw(34)
        letter-spacing normal
      &.current
        border-bottom 1px solid #601986
