.footer
  position relative
  z-index 2
  background-color $thema-bg-beige
  border-top-color $white
  border-top-style solid
  +pc()
    border-top-width 5px
    +pcLiquid()
      border-top-width pcvw(@border-top-width)
  +sp()
    border-top-width vw(8)
  +header-pc()
    margin-left 180px
    +pcLiquid()
      margin-left pcvw(@margin-left)
  &-container
    +pc()
      display flex
      justify-content space-between
      flex-wrap wrap
      padding 40px 50px
      +pcLiquid()
        padding pcvw(@padding)
    +sp()
      padding vw(80 40 70)
  &-inner
    & + &
      +sp()
        margin-top vw(70)
  &-logo
    +pc()
      margin-bottom 30px
      +pcLiquid()
        margin-bottom pcvw(@margin-bottom)
    +sp()
      margin-bottom vw(30)
    img
      +pc()
        +pcLiquid()
          width pcvw(315)
          height pcvw(56)
      +sp()
        width vw(470)
        height auto
  &-siteInfo
    p
      font-family $NotoSans
      color $black
      letter-spacing ls(100)
      display flex
      align-items center
      +pc()
        font-size 1.4rem
        line-height round(24 / 14, 2)
        +pcLiquid()
          font-size pcvw(14)
      +sp()
        font-size vw(28)
        line-height round(48 / 28, 2)
      &::before
        content ''
        display inline-block
        background-size contain
        background-repeat no-repeat
        +pc()
          margin-right 5px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-right vw(10)
    a
      color $black
      +pc()
        hover-opacity()
  &-address::before
    background-image url('../img/common/icon_address.svg')
    +pc()
      width 11px
      height 16px
      +pcLiquid()
        width pcvw(@width)
        height pcvw(@height)
    +sp()
      width vw(21)
      height vw(29)
  &-tel::before
    background-image url('../img/common/icon_phone.svg')
    +pc()
      width 14px
      height 16px
      +pcLiquid()
        width pcvw(@width)
        height pcvw(@height)
    +sp()
      width vw(26)
      height vw(30)

  // 右側のナビゲーション
  &-navigation
    &-list
      font-family $NotoSans
      +pc()
        font-size 1.4rem
        +pcLiquid()
          font-size pcvw(14)
      +sp()
        font-size vw(28)
      & + &
        +pc()
          margin-top 20px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(35)
      &::before
        content 'ー'
        display inline-block
        margin-right .5em
      a
        color $thema-text-black
        +pc()
          hover-opacity()

  // コピーライト
  .copyright
    font-family $Roboto
    font-weight lighter
    color $thema-text-black
    letter-spacing ls(50)
    width 100%
    +pc()
      font-size 1.4rem
      margin-top 10px
      +pcLiquid()
        font-size pcvw(14)
        margin-top pcvw(@margin-top)
    +sp()
      font-size vw(24)
      margin-top vw(80)

// トップに戻るボタン
.toTop
  display flex
  align-items center
  justify-content center
  border-radius 50%
  background-color alpha($thema-bg-purple, .8)
  background-repeat no-repeat
  background-position center center
  background-image url('../img/common/totop_arrow.png')
  position absolute
  opacity 0
  +pc()
    width 60px
    height @width
    background-size 12px 8px
    top -90px
    right 50px
    transition  opacity .3s
    &:hover
      opacity .7
    +pcLiquid()
      width pcvw(@width)
      height @width
      background-size pcvw(@background-size)
      top pcvw(@top)
      right pcvw(@right)
  +sp()
    width vw(100)
    height @width
    background-size vw(26 17)
    top vw(-140)
    left 50%
    transform translateX(-50%)
  &.show
    opacity 1
  &.fixed
    +pc()
      position fixed
      top auto
      bottom 25px
      +pcLiquid()
        bottom pcvw(@bottom)

//- SPのみ表示　画面下部に固定
.fixed-navigation
  +header-pc()
    display none
  +header-sp()
    display block
    width 100%
    position fixed
    left 0
    bottom 0
    transition bottom .5s
    z-index 3
    &.hidden
      bottom -100%
  .inner
    display flex
    .fixed-elm
      width 50%
      &.is-requestDocuments
        background-color $green
        .icon
          +header-sp()
            width pcvw(47)
            height pcvw(34)
          +sp()
            width vw(47)
            height vw(34)
      &.is-webApplication
        background-color $blue
        .icon
          +header-sp()
            width pcvw(44)
            height pcvw(30)
          +sp()
            width vw(44)
            height vw(30)
      a
        display flex
        align-items center
        justify-content center
        +header-sp()
          padding pcvw(35) 0
        +sp()
          padding vw(35) 0
        .icon
          display block
          +header-sp()
            margin-right pcvw(15)
          +sp()
            margin-right vw(15)
        .txt
          display block
          color $white
          +header-sp()
            font-size pcvw(36)
          +sp()
            font-size vw(32)
