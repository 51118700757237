/**
* browser huck
**/
.ie11
  // トップの背景動画
  // object-fit のポリフィルすると動画が再生されなくなる
  [data-pages='top']
    .keyVisual-bg-video
      video
        width auto
        min-width 100%
        min-height 100%
        transform translate(-50%, -50%)
        top 50%
        left 50%

  .event-contnt-block
    &-date
      letter-spacing 0.1em
      & + .event-contnt-block-date
        &::before
          +pc()
            font-size 1.6rem
            +pcLiquid()
              font-size pcvw(16)
      .month
        +pc()
          font-size 2.4rem
          +pcLiquid()
            font-size pcvw(24)
        &::after
          +pc()
            font-size 1.8rem
            +pcLiquid()
              font-size pcvw(18)
      .day
        +pc()
          font-size 4.6rem
          +pcLiquid()
            font-size pcvw(46)
      .week
        +pc()
          font-size 1.6rem
          +pcLiquid()
            font-size pcvw(16)

  .content-archive-date
    writing-mode initial
