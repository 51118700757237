[data-pages="campus-life-event-calendar"]
  .set-item-campus-life
    width 452px
    +pcLiquid()
      width pcvw(452)
    +pc()
      width 452px
    +tab()
      width pcvw(452)
    +sp()
      width 100%

    .wrap-campus
      +pc()
        width pcvw(940)

  .adjust-text
    width 171px
    +pcLiquid()
      width pcvw(171)
    +pc()
      width 171px
    +tab()
      width pcvw(171)
    +sp()
      width vw(171)

  .img
    width 452px
    +pc()
      width pcvw(452)
    +tab()
      width pcvw(452)
    +sp()
      width vw(452)

  .withBlock.-spring:before
    background #ff93b3

  .list-withDot li.-spring:before
    background-color #ff93b3

  .withBlock.-summer:before
    background #92c1ea

  .list-withDot li.-summer:before
    background-color #92c1ea

  .withBlock.-autumn:before
    background #f87433

  .list-withDot li.-autumn:before
    background-color #f87433

  .withBlock.-winter:before
    background #8081b8

  .list-withDot li.-winter:before
    background-color #8081b8

