@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
$colorBlue = #29ABE2
$colorYellow = #FCEE21

[data-pages="teacher-education-sc"]

  [data-support="contest"]
    $imgPath = '../img/career/teacher-education-sc/contest/'

    // Illust
    .listStep-1
      .illust
        +pc()
          height 162px
          +pcLiquid()
            height pcvw(@height)
        +sp()
          height 42.4vw
    .listStep-2
      .illust
        +pc()
          height 178px
          +pcLiquid()
            height pcvw(@height)
        +sp()
          height 46.67vw

    .illust
      margin 0 auto
      +pc()
        width 210px
        +pcLiquid()
          width pcvw(@width)
      .illust_inner
        width 100%
        height 100%
      &.illust_1
        .illust_inner
          background url($imgPath + 'illust-1.png') no-repeat center / contain
      &.illust_2
        .illust_inner
          background url($imgPath + 'illust-2.png') no-repeat center / contain
      &.illust_3
        .illust_inner
          background url($imgPath + 'illust-3.png') no-repeat center / contain
      &.illust_4
        .illust_inner
          background url($imgPath + 'illust-4.png') no-repeat center / contain
      &.illust_5
        .illust_inner
          background url($imgPath + 'illust-5.png') no-repeat center / contain
      &.illust_6
        .illust_inner
          background url($imgPath + 'illust-6.png') no-repeat center / contain

    .listStep
      margin-left 0
      +pc()
        display flex
        justify-content space-between
      .itemStep
        position relative
        background-color $white
        border-radius 10px
        +pc()
          width 30.675%
          padding 10px 20px 26px 20px
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          padding vw(20 40 52 40)
        &:not(:first-of-type)
          +sp()
            margin-top vw(120)
          &::before
            content ""
            position absolute
            border-top 4px solid #000
            border-right 4px solid #000
            +pc()
              top 50%
              transform translateY(-50%) rotate(45deg)
              left -32px
              width 18px
              height 18px
              +pcLiquid()
                width pcvw(@width)
                height pcvw(@height)
                left pcvw(@left)
            +sp()
              width vw(50)
              height vw(50)
              top vw(-120)
              left 0
              margin auto
              right 0
              transform rotate(135deg)

        .titleStep
          position absolute
          text-align center
          top -24px
          left 0
          right 0
          letter-spacing .05em
          font-weight 700
          +pc()
            top -15px
            font-size 30px
            +pcLiquid()
              top pcvw(@top)
              font-size pcvw(@font-size)
          +sp()
            top vw(-30)
            font-size vw(60)


  [data-support="recommendation"]
    .c-candidate_box
      +pc()
        width 284px
      +sp()
        width 90%

  [data-support="volunteer"]
    $imgPath = '../img/career/teacher-education-sc/volunteer/'

    // Illust
    .illust
      margin 0 auto 16px
      width 250px
      height 250px
      +pcLiquid()
        width pcvw(@width)
        height pcvw(@height)
      +sp()
        width vw(530)
        height vw(530)
      .illust_inner
        width 100%
        height 100%
      &.illust_1
        .illust_inner
          background url($imgPath + 'illust-1.png') no-repeat center / contain
      &.illust_2
        .illust_inner
          background url($imgPath + 'illust-2.png') no-repeat center / contain
      &.illust_3
        .illust_inner
          background url($imgPath + 'illust-3.png') no-repeat center / contain
      &.illust_4
        .illust_inner
          background url($imgPath + 'illust-4.png') no-repeat center / contain
      &.illust_5
        .illust_inner
          background url($imgPath + 'illust-5.png') no-repeat center / contain
      &.illust_6
        .illust_inner
          background url($imgPath + 'illust-6.png') no-repeat center / contain

  [data-support="outline"]
    .listPprofile
      display flex
      flex-wrap wrap
      .itemProfile
        +pc()
          width 25%
        +sp()
          width 50%

  [data-support="manabi-pj"]
    $imgPath = '../img/career/teacher-education-sc/manabi-pj/'
    .container_wide_inner
      padding-right 1vw
    .illust
      .illust_inner
        width 100%
        height 100%
      &.illust_1
        +pc()
          top 10px
          right 80px
          width 145px
          height 103px
          +pcLiquid()
            top pcvw(@top)
            right pcvw(@right)
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          top vw(-60)
          right vw(20)
          width vw(145)
          height vw(103)
        .illust_inner
          background url($imgPath + 'illust_1.png') no-repeat center / contain
      &.illust_2
        +pc()
          top 120px
          left 140px
          width 80px
          height 72px
          +pcLiquid()
            top pcvw(@top)
            left pcvw(@left)
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          top vw(-20)
          right vw(40)
          width vw(80)
          height vw(72)
        .illust_inner
          background url($imgPath + 'illust_2.png') no-repeat center / contain
      &.illust_3
        +pc()
          top 100px
          right 100px
          width 154px
          height 176px
          +pcLiquid()
            top pcvw(@top)
            right pcvw(@right)
            width pcvw(@width)
            height pcvw(@height)
          .illust_inner
            background url($imgPath + 'illust_3.png') no-repeat center / contain
      &.illust_4
        +pc()
          top 10px
          left 130px
          width 115px
          height 120px
          +pcLiquid()
            top pcvw(@top)
            left pcvw(@left)
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          top vw(-50)
          right vw(10)
          width vw(115)
          height vw(120)
        .illust_inner
          background url($imgPath + 'illust_4.png') no-repeat center / contain
      &.illust_5
        +pc()
          bottom 10px
          right 100px
          width 144px
          height 138px
          +pcLiquid()
            bottom pcvw(@bottom)
            right pcvw(@right)
            width pcvw(@width)
            height pcvw(@height)
        +sp()
          top vw(-40)
          right vw(10)
          width vw(117)
          height vw(112)
        .illust_inner
          background url($imgPath + 'illust_5.png') no-repeat center / contain

