$thisCategory = "for-examinees"
$thisPage = "examinees-index"
$bgPath = '../img/' + $thisCategory + '/' +$thisPage + '/'

[data-pages={'"'+$thisPage+'"'}]
  .page-header
    +pc()
      background-image url($bgPath + 'header_bg-pc.jpg')
    +sp()
      background-image url($bgPath + 'header_bg-sp.jpg')

  .footer
    border-top-width 0

  .content-for_menu-inner
    padding 80px 0 42px
    +sp()
      padding 40px 0 100px

    .banner
      display block
      +pc()
        margin-bottom 40px
        transition opacity .2s ease-in-out
        &:hover
          opacity .6
          // box-shadow 1px 1px 8px rgba(0,0,0,.3)
      +sp()
        margin-bottom 18px

  .list__for_menu
    .item__for_menu--1
      .wrap_inner
        background-image url($bgPath + 'icon-pen.svg')
        +pc()
          padding-top 105px
          background-size 78px auto
        +sp()
          padding-top 73px
          background-size 54px auto

    .item__for_menu--2
      .wrap_inner
        background-image url($bgPath + 'icon-docx.svg')
        +pc()
          padding-top 115px
          background-size 58px auto
        +sp()
          padding-top 76px
          background-size 47px auto

    .item__for_menu--3
      .wrap_inner
        background-image url($bgPath + 'icon-parents.svg')
        +pc()
          padding-top 104px
          background-size 76px auto
        +sp()
          padding-top 76px
          background-size 57px auto

    .item__for_menu--4
      .wrap_inner
        background-image url($bgPath + 'icon-pc_sp.svg')
        +pc()
          padding-top 104px
          background-size 117px auto
        +sp()
          padding-top 67px
          background-size 80px auto

