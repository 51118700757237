[data-pages="circles"]
  // Common
  .page-header
    +pc()
      background-image url('../img/campus-life/circles/header_bg-pc.jpg')
    +sp()
      background-image url('../img/campus-life/circles/header_bg-sp.jpg')

  .list-circles_link
    +pc()
      display flex
      justify-content space-between
      flex-wrap wrap
      &::after
        display block
        content ""
        width 340px
        +pcLiquid()
          width pcvw(@width)

    .item-circle_data
      +pc()
        width 31.482%

    .item-circles_link
      position relative
      +pc()
        width 31.482%
        cursor pointer
        transition .2s ease-in-out
        &:hover
          opacity .7
      +sp()
        margin-bottom vw(60)

      .box-text
        text-align center
        width 100%
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        color #fff

        .circles_category
          font-weight 400
          +pc()
            font-size 32px
            +pcLiquid()
              font-size pcvw(@font-size)
          +sp()
            font-size vw(64)

        .circles_category-en
          font-weight 400
          margin-top 10px
          +pc()
            font-size 16px
            +pcLiquid()
              font-size pcvw(@font-size)
          +sp()
            font-size vw(32)

          &:after
            content ""
            display inline-block
            background-image url($commonImgPath + 'arrow.svg')
            background-size contain
            background-position center right
            background-repeat no-repeat
            +pc()
              padding-left 10px
              width 6px
              height 12px
            +sp()
              padding-left vw(20)
              width vw(12)
              height vw(23)

  // トップ
  [data-campus_life="circles"]
    +sp()
      .ui-template
        .container.-limit.block-circles_slider
          margin-right calc(50% - 50vw)
          margin-left calc(50% - 50vw)
          width 100vw
      img
        width 100%
        height auto

    .block-circles_slider
      position relative
      .box-text
        z-index 100
        text-align center
        width 100%
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        color #fff

        .slider_title
          font-family $Roboto
          font-style italic
          font-weight 600
          margin-bottom .1em
          +pc()
            font-size 96px
            +pcLiquid()
              font-size pcvw(@font-size)
          +sp()
            font-size vw(96)

        .slider_sub_title
          font-family $Roboto
          font-style italic
          font-weight 400
          line-height 1.2
          +pc()
            font-size 18px
            +pcLiquid()
              font-size pcvw(@font-size)
          +sp()
            font-size vw(24)

    .wrap-crossfade_slider
      position relative
      height  420px
      margin 0 auto
      padding 0 auto
      text-align  center
      +pcLiquid()
        height  pcvw(@height)
      +sp()
        height  83vw

      .list-crossfade_slider
        margin 0
        padding 0
        list-style none

        img
          width 100%
          margin 0 auto

        .item-crossfade_slider
          width 100%
          height 100%
          position absolute
          top 0
          left 0
          animation-iteration-count infinite
          animation-duration 21s
          &:nth-child(1)
            animation-name wrap-crossfade_slider
            animation-delay -1s
          &:nth-child(2)
            animation-name wrap-crossfade_slider
            animation-delay 6s
            opacity 0
          &:nth-child(3)
            animation-name wrap-crossfade_slider
            animation-delay 13s
            opacity 0

    @keyframes wrap-crossfade_slider{
      0%{
        opacity 0
      }
      4.76%{
        opacity 1
      }
      33.33%{
        opacity 1
      }
      42.85%{
        opacity 0
      }
      100%{
        opacity 0
      }
    }

  // 下層
  [data-campus_life="circles_subdir"]
    +sp()
      img
        width 100%
        height auto

    .tab-container
      .tab
        padding-bottom 0!important
        a
          display block
          height 100%
          width 100%
          text-align center
          padding-bottom 20px
          +sp()
            height auto
        span
          color $thema-text-black

      .tab.active
        padding-bottom 20px!important
        span
          color $thema-text-purple

    .js-modal_open
      cursor pointer
      +pc()
        &:hover
          opacity .7

    .js-modal_contents
      z-index 2001
      visibility hidden
      opacity 0
      position fixed
      top 50%
      left 50%
      transform translate(-50% , -50%)
      margin auto
      transition .3s opacity ease, 0s visibility 0s linear
      +pc()
        transform translate(-40%, -50%)
      +tab()
        transform translate(-50%, -50%)

      &.is-modalopen
        opacity 1
        visibility visible
        transition .3s opacity ease, 0s visibility 0s linear

    .modal_overlay
      z-index 2000
      visibility hidden
      opacity 0
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      background-color rgba(0, 0, 0, 0.55)
      backdrop-filter blur(10px)
      cursor pointer
      transition .3s opacity ease, 0s visibility 0s linear, backdrop-filter .2s

      &.is-modalopen
        visibility visible
        opacity 1
        transition .3s opacity ease , 0s visibility 0s linear

    .modal_close
      position absolute
      color #fff
      background-image url('../img/campus-life/circles/icon-close.svg')
      background-size 50% auto
      background-position center
      background-repeat no-repeat
      background-color #333
      border-radius 50%
      transition background-color .2s
      z-index 1000
      +pc()
        top  -20px
        right -20px
        width 40px
        height 40px
        &:hover
          background-color #999
      +sp()
        top  vw(-40)
        right vw(-40)
        width vw(80)
        height vw(80)

    .modal__contents_wrap
      background-color $white
      height 100%
      +pc()
        max-height 80vh
        width 860px
        +pcLiquid()
          width pcvw(@width)
          max-height 66vh
      +sp()
        max-height 68vh
        width 89vw

    .modal__container
      overflow-y auto
      height 100%
      +pc()
        max-height 73vh
        +pcLiquid()
          max-height 57vh
      +sp()
        max-height 60vh

    .modal__heading
      display flex
      justify-content space-between
      align-items center
      color $white
      background-color #b08cc3
      z-index 900
      +pc()
        padding 24px 40px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(24 40)

      .heading
        display inline-block
        +pc()
          font-size 26px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(30)

      .heading-en
        display inline-block
        font-family $Roboto
        +pc()
          font-size 18px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(30)

    .modal__content
      background-color $white
      +pc()
        display flex
        justify-content space-between
        padding 40px 40px 40px 40px
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding vw(40)

      .box-img
        +pc()
          width 56.25%
        +sp()
          margin-bottom vw(40)

        img
          width 100%
          height auto

      .box-text
        +pc()
          width 38%

      .with_Border
        border-bottom 1px solid #ccc
        +pc()
          padding-bottom $mgn-lv1
          +pcLiquid()
            padding-bottom pcvw(@padding-bottom)
        +sp()
          padding-bottom vw($mgn-lv1)
      [class^="heading"].withBlock
        &:before
          height 1em
          margin-top 1px

    .circle_name
      +pc()
        font-size 18px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(36)

    .modal__title
      +pc()
        font-size 18px
        +pcLiquid()
          font-size pcvw(@font-size)
      +sp()
        font-size vw(36)

    .modal__schedule
      display flex
      .dt
        white-space nowrap
        margin-right .3em

    .modal__sns
      background-color $white
      +pc()
        padding 0 0 40px 0
        +pcLiquid()
          padding pcvw(@padding)
      +sp()
        padding 0 0 vw(20) 0

    .list-sns
      width 100%
      margin auto
      display flex
      justify-content center
      align-items center

      .item-sns
        +pc()
          margin-right 35px
          +pcLiquid()
            margin-right pcvw(@margin-right)
          &:hover
            opacity .7
        +sp()
          margin-right vw(30)
          width vw(60)
        &:last-of-type
          margin-right 0
