[data-category="cooperation"]
  .box
    width auto
  .page-header
    +pc()
      background-image url('../img/cooperation/header_bg.jpg')
    +sp()
      background-image url('../img/cooperation/header_bg@2x.jpg')
  // cooperation配下すべてに適用する場合はこちら
  //サンプルです
  .unique-wrap
    .sample-component
      +pc()
        margin-right 15px
        +pcLiquid()
          margin-right pcvw(@margin-right)
      +sp()
        margin-bottom vw(30)

  // トップだけに適用する場合はこちら
  [data-cooperation="index"]
    // お知らせ
    .content-latest-container
      +pc()
        display flex
        margin-bottom 50px
      +sp()
        margin-bottom vw(80)
      li
        +pc()
          width 'calc(33.33% - %spx)' % (60 / 3)
          +pcLiquid()
            width 'calc(33.33% - %s)' % pcvw(60 / 3)
        & + li
          +pc()
            margin-left 30px
            +pcLiquid()
              margin-left pcvw(@margin-left)
            &:first-of-type
              margin-left 0
          +sp()
            margin-top vw(80)
        &.c-info_item
          margin-bottom 0
          a
            padding-bottom 0
      .information-contnt-block
        a
          color $thema-text-black
    .info
      &-link-container
        display flex
        justify-content flex-end
        +pc()
          margin-top 10px
          +pcLiquid()
            margin-top pcvw(@margin-top)
        +sp()
          margin-top vw(20)
      &-text-link
        cmn-arrow-link($thema-text-purple)
    // 地域支援活動
    .flexPC.-wrap
      +pc()
        flex-wrap wrap
      .info
        &-link-container
          +pc()
            position absolute
            right 0
            bottom 0
            margin-top 0
    .c-info_item
      position relative
      +pc()
        width 'calc(50% - %s)' % ($mgn-lv2 / 2)
        +pcLiquid()
          width 'calc(50% - %s)' % pcvw($mgn-lv2 / 2)
      +sp()
        margin-bottom vw(84)
      a
        display block
        +pc()
          height 100%
          padding-bottom 35px
          +pcLiquid()
            padding-bottom pcvw(50)
          &:hover
            .info-text-link::after
              transform translate3d(10px, 0, 0)
      .info_item--image
        position relative
        text-align center
        overflow hidden
        width 100%
        background-color $white
      img
        width 100%
        height 100%
        +pc()
          transition transform .3s
      .info_item--text
        +pc()
          padding 15px 10px 0
          +pcLiquid()
            padding pcvw(@padding)
        +sp()
          padding vw(40 20 0)
      .info_item--title
        font-family $NotoSans
        font-weight 500
        letter-spacing ls(50)
        display -webkit-box
        overflow hidden
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        color $thema-text-black
        +pc()
          font-size 1.4rem
          line-height round(26 / 14, 2)
          +pcLiquid()
            font-size pcvw(14)
        +sp()
          font-size vw(28)
          line-height 2
         &:before
          content ""
          display inline-block
          background url('../img/common/textlink_arrow.png') no-repeat
          background-position center
          background-size contain
          +pc()
            width 8px
            height 12px
            margin-right 8px
            // +pcLiquid()
            //   width pcvw(@width)
            //   height pcvw(@height)
            //   margin-right pcvw(@margin-right)
          +sp()
            width vw(16)
            height vw(24)
            margin-right vw(10)
      .info_item--caption
        font-family $NotoSans
        font-weight 500
        letter-spacing ls(50)
        display -webkit-box
        overflow hidden
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        color $thema-text-black
        +pc()
          font-size 1.2rem
          line-height round(26 / 14, 2)
          margin-top 10px
          +pcLiquid()
            font-size pcvw(12)
            margin-top pcvw(@margin-top)
        +sp()
          font-size vw(24)
          line-height 2
          margin-top vw(30)

  // 地域連携センターの役割だけに適用する場合はこちら
  [data-cooperation="center"]
    //サンプルです
    .unique-wrap
      .sample-component
        +pc()
          margin-right 15px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(30)
    .box-image
      position relative
      +pc()
        width 300px
        height 400px
        +pcLiquid()
          width pcvw(@width)
          height pcvw(@height)
      img
        width 100%
    .box-text
      +pc()
        width 600px
        +pcLiquid()
          width pcvw(@width)
    .c-dean_nameplate
      position absolute
      right 0
      background-color $white
      span
        display block
        line-height 1.2
        letter-spacing 0.05em
      +pc()
        bottom 20px
        padding 12px 20px
        +pcLiquid()
          bottom pcvw(@bottom)
          padding pcvw(@padding)
      +sp()
        bottom vw(40)
        padding vw(20 32)
      .job
        +pc()
          font-size 15px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(28)
      .name
        +pc()
          font-size 20px
          +pcLiquid()
            font-size pcvw(@font-size)
        +sp()
          font-size vw(36)

    .img-center
      text-align center
    .anchorlink
      &:not(:last-of-type)
        margin-right 0

  // 地域支援活動のご案内だけに適用する場合はこちら
  [data-cooperation="activities"]
    //サンプルです
    .unique-wrap
      .sample-component
        +pc()
          margin-right 15px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(30)

  // 支援協力研究者一覧だけに適用する場合はこちら
  [data-cooperation="collaborative"]
    //サンプルです
    .unique-wrap
      .sample-component
        +pc()
          margin-right 15px
          +pcLiquid()
            margin-right pcvw(@margin-right)
        +sp()
          margin-bottom vw(30)

