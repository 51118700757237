/*
 * media query block
 */
pcLiquid()
  @media (max-width ($design-size_pc - 1)px)
    {block}

pc()
  @media (min-width 768px)
    {block}

tab()
  @media (max-width 1100px)
    {block}

sp()
  @media (max-width 767px)
    {block}

sp-small()
  @media (max-width 450px)
    {block}

land()
	@media (orientation landscape)
		{block}

port()
	@media (orientation portrait)
		{block}


// ヘッダー用 media query
header-pc()
  @media (min-width 1100px)
    {block}
header-sp()
  @media (max-width 1099px)
    {block}


// block mixin
// large と liquidレイアウトをまとめて設定する
pcStyle(property, value)
	+large()
		{property} value px
	+liquid()
		{property} pcvw(value)
